import React from 'react';
import { ACLServiceAccountProfileContextGQLProvider } from './ACLServiceAccountProfileContextGQL';
import ACLServiceAccountProfileGQL from './ACLServiceAccountProfileGQL';
import { ErrorBoundary } from '@tecton';

const ACLServiceAccountProfileContainer = () => {
  return (
    <ErrorBoundary>
      <ACLServiceAccountProfileContextGQLProvider>
        <ACLServiceAccountProfileGQL />
      </ACLServiceAccountProfileContextGQLProvider>
    </ErrorBoundary>
  );
};

export default ACLServiceAccountProfileContainer;
