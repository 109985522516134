export default () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.6666 16H16C16.7363 16 17.3333 15.403 17.3333 14.6667V7.33333C17.3333 6.59695 16.7363 6 16 6H9.99996C9.26358 6 8.66663 6.59695 8.66663 7.33333V8.66667"
        stroke="#343741"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.2395 10.3134L13.0197 9.09364C12.7462 8.82015 12.3753 8.6665 11.9885 8.6665H7.99996C7.26358 8.6665 6.66663 9.26346 6.66663 9.99984V16.6665C6.66663 17.4029 7.26358 17.9998 7.99996 17.9998H13.3333C14.0697 17.9998 14.6666 17.4029 14.6666 16.6665V11.3446C14.6666 10.9579 14.513 10.5869 14.2395 10.3134Z"
        stroke="#343741"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.6666 11.6665H12.6666C12.1143 11.6665 11.6666 11.2188 11.6666 10.6665V8.6665"
        stroke="#343741"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
