import { EuiToolTip } from '@elastic/eui';
import styled from '@emotion/styled';
import React, { FC } from 'react';

interface TectonTooltipProps {
  title?: React.ReactNode;
  content: string;
  position?: 'bottom' | 'left' | 'top' | 'right';
  trigger: React.ReactNode;
}

const TooltipWrapper = styled.div`
  span {
    display: flex;
  }
`;

const TectonTooltip: FC<TectonTooltipProps> = ({ title, content, position = 'bottom', trigger }) => {
  const truncationPoint = 1024;
  if (content.length > truncationPoint) {
    content = content.substring(0, truncationPoint - 3) + '...';
  }

  return (
    <TooltipWrapper>
      <EuiToolTip position={position} title={title} content={content} data-testid="tooltip">
        <>{trigger}</>
      </EuiToolTip>
    </TooltipWrapper>
  );
};

export default TectonTooltip;
