import { EuiFlyout, useGeneratedHtmlId } from '@elastic/eui';
import styled from '@emotion/styled';
import { Button } from '@tecton/ComponentRedesign';
import React, { FC } from 'react';

interface FlyoutProps {
  title: React.ReactNode;
  children: React.ReactNode;
  type?: 'push' | 'overlay';
  cancel?: () => void;
  refresh?: () => void;
  save?: () => void;
  onClose?: () => void;
}

const FlyoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
`;

const FlyoutHeader = styled.div`
  padding: ${({ theme }) => theme.padding.xl};
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightShade};
  width: 100%;
  font-size: ${({ theme }) => theme.font.headingSizes[3]};
  line-height: ${({ theme }) => theme.font.headingLineHeights[3]};
  font-weight: ${({ theme }) => theme.font.weight.medium};
`;

const FlyoutContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  padding: ${({ theme }) => theme.padding.xl};
  overflow: auto;
`;

const FlyoutFooter = styled.div`
  background-color: ${({ theme }) => theme.colors.lightestShade};
  width: 100%;
  display: flex;
  padding: ${({ theme }) => theme.padding.l} ${({ theme }) => theme.padding.xl};
  align-items: flex-start;
  gap: ${({ theme }) => theme.padding.l};
  align-self: stretch;
`;

const FlyoutCancelContainer = styled.div``;

const FlyoutConfirmContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: ${({ theme }) => theme.padding.l};
  flex: 1 0 0;
`;

const TectonFlyout: FC<FlyoutProps> = ({
  title,
  children,
  type,
  cancel,
  refresh,
  save,
  onClose = () => {
    return;
  },
}) => {
  const pushedFlyoutTitleId = useGeneratedHtmlId({
    prefix: 'pushedFlyoutTitle',
  });

  return (
    <>
      <EuiFlyout
        onClose={onClose}
        data-testid="flyout"
        maskProps={{ headerZindexLocation: 'above' }}
        type={type}
        aria-labelledby={pushedFlyoutTitleId}
      >
        <FlyoutContainer>
          <FlyoutHeader>{title}</FlyoutHeader>
          <FlyoutContent>{children}</FlyoutContent>
          <FlyoutFooter>
            <FlyoutCancelContainer>
              {cancel && <Button label={'Cancel'} variant={'emptyAction'} onClick={cancel} />}
            </FlyoutCancelContainer>
            <FlyoutConfirmContainer>
              {refresh && <Button label={'Refresh'} variant={'primaryAction'} />}
              {save && <Button label={'Save'} variant={'emptyAction'} />}
            </FlyoutConfirmContainer>
          </FlyoutFooter>
        </FlyoutContainer>
      </EuiFlyout>
    </>
  );
};

export default TectonFlyout;
