import React from 'react';
import { FlexGroup, FlexItem } from '@tecton';

const ACLTableControlLayout = ({
  search,
  actionButtons,
}: {
  search: React.ReactNode;
  actionButtons: React.ReactNode;
}) => {
  return (
    <FlexGroup justifyContent="spaceBetween">
      <FlexItem style={{ flexBasis: '35%' }} grow={false}>
        {search}
      </FlexItem>
      <FlexItem grow={false}>{actionButtons}</FlexItem>
    </FlexGroup>
  );
};

export { ACLTableControlLayout };
