const OKTA_DOMAIN = 'login.tecton.ai';
const OKTA_CLIENT_ID = window._env_.OKTA_CLIENT_ID;
const OKTA_CALLBACK_PATH = '/app/okta/callback';
export const OKTA_CALLBACK_ROUTE = '/okta/callback';

const ISSUER = `https://${OKTA_DOMAIN}/oauth2/default`;
const REDIRECT_URI = `${window.location.origin}${OKTA_CALLBACK_PATH}`;
const SCOPES = 'openid profile email';

export const OKTA_CONFIG = {
  issuer: ISSUER,
  clientId: OKTA_CLIENT_ID,
  redirectUri: REDIRECT_URI,
  scopes: SCOPES.split(/\s+/),
  tokenManager: {
    expireEarlySeconds: 600, // Expire 10 minutes before token is expired. Usually the token expires every hour.
  },
};

function getOktaRemoteSessionLogoutUrl(idToken) {
  return `${ISSUER}/v1/logout?id_token_hint=${idToken}&post_logout_redirect_uri=${window.location.origin}/app`;
}

export const logout = async (authService) => {
  if (authService) {
    // Read idToken before local session is cleared
    const idToken = await authService.getIdToken();

    // Clear local session, based on https://developer.okta.com/docs/guides/sign-users-out/react/sign-out-of-your-app/
    await authService.tokenManager.clear();

    // Clear remote session
    if (idToken) {
      window.location.href = getOktaRemoteSessionLogoutUrl(idToken);
    } else {
      // When user clears localStorage, we need to send them to the login page.
      authService.signInWithRedirect({ originalUri: window.location.pathname.replace('/app', '') });
    }
  }
};
