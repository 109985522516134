import store from '../core/store.js';
import _ from 'lodash';

export const DEFAULT_WORKSPACE = 'prod';

export default class WorkspaceUtils {
  static getDefaultWorkspace() {
    return DEFAULT_WORKSPACE;
  }

  static isProdWorkspace(workspace) {
    return !!workspace && DEFAULT_WORKSPACE === workspace;
  }

  static selectWorkspace(state) {
    return state.workspace.workspace || WorkspaceUtils.selectedWorkspaceFromLocalStorage();
  }

  static selectedWorkspaceFromLocalStorage() {
    return localStorage.getItem('MOST_RECENTLY_VIEWED_WORKSPACE') === null
      ? false
      : localStorage.getItem('MOST_RECENTLY_VIEWED_WORKSPACE');
  }

  static setWorkspaceForLocalStorage(workspace) {
    localStorage.setItem('MOST_RECENTLY_VIEWED_WORKSPACE', workspace);
  }

  static addWorkspaceToRecent(workspace) {
    const pastRecentWorkspaces = this.getRecentWorkspaces();
    pastRecentWorkspaces.unshift(workspace);
    const deDupedWorkspaces = [...new Set(pastRecentWorkspaces)].slice(0, 10);
    localStorage.setItem('RECENT_WORKSPACES', JSON.stringify(deDupedWorkspaces));
  }

  static getRecentWorkspaces() {
    const recentWorkspaces = localStorage.getItem('RECENT_WORKSPACES');

    if (!recentWorkspaces) {
      return [];
    }

    try {
      return JSON.parse(recentWorkspaces);
    } catch {
      return [];
    }
  }

  static getWorkspaceFromStore() {
    const state = store.getState();
    return WorkspaceUtils.selectWorkspace(state);
  }

  static isValidWorkspace(workspace) {
    const regexp = '^[a-zA-Z0-9-_]+$';
    return workspace && workspace.search(regexp) !== -1;
  }

  static isDevelopmentWorkspace(listOfWorkspaces, workspace) {
    const matchWorkspace = _.filter(listOfWorkspaces.data.workspaces, (space) => {
      return space.name === workspace;
    });

    if (matchWorkspace.length == 0) {
      return false;
    }
    if (matchWorkspace[0].hasOwnProperty('capabilities') === false) {
      return false;
    }
    if (matchWorkspace[0].capabilities.hasOwnProperty('materializable') == false) {
      return false;
    }

    return matchWorkspace[0].capabilities.materializable;
  }
}
