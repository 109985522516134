import { EntityFCO, EntityFCOFields, FCOFields, FCOType } from '../../core/types/fcoTypes';
import { Entity } from '../../types/tecton_proto/data/entity';
import { Fco } from '../../types/tecton_proto/data/fco';
import { getSharedFCOProperties } from './fcoUtils';

export const transformEntityProtoToEntitiyFCO: (fco: Fco) => EntityFCO = (fco) => {
  const rawEntity: Entity = fco.entity!;
  const metadata = rawEntity.fco_metadata!;

  const asEntityFco: EntityFCO = {
    ...getSharedFCOProperties(metadata, rawEntity.entity_id),
    fcoType: FCOType.ENTITY,
    [EntityFCOFields.JOIN_KEYS]: rawEntity.join_keys_legacy || [],
    [EntityFCOFields.DEPENDENT_FEATURE_SERVICES]: [],
    [EntityFCOFields.DEPENDENT_FEATURE_VIEWS]: [],
    [FCOFields.PREVENT_DESTROY]: rawEntity.validation_args?.args?.prevent_destroy,
  };

  return asEntityFco;
};
