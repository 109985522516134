import { Button, ButtonEmpty, ComboBox, EuiComboBoxOptionOption, FormRow } from '@tecton';
import { TectonBasicModal, CalloutMessageType } from '@shared';
import WorkspaceRoleSelectorFormRow from '../WorkspaceRoleSelectorFormRow';
import { OptionsInfo } from '../../shared/RowSelectorFormRow';

const AddWorkspaceAccessModal = <T,>({
  workspaceId,
  allRoleRecords,
  principalType,
  principalsList,
  selectedPrincipal,
  setSelectedPrincipal,
  roleOptions,
  selectedRole,
  setSelectedRole,
  isFetching,
  isLoading,
  errorMessages,
  renderOption,
  cancel,
  saveChanges,
}: {
  workspaceId: string;
  allRoleRecords: OptionsInfo<string>[];
  principalType: 'User' | 'Service Account' | 'Group';
  principalsList: EuiComboBoxOptionOption<T>[];
  selectedPrincipal: EuiComboBoxOptionOption<T>[];
  setSelectedPrincipal: (selection: EuiComboBoxOptionOption<T>[]) => void;
  roleOptions: React.ComponentProps<typeof WorkspaceRoleSelectorFormRow>['roleOptions'];
  selectedRole: string | '';
  setSelectedRole: (role: string) => void;
  isFetching: boolean;
  isLoading: boolean;
  errorMessages: CalloutMessageType[] | undefined;
  renderOption?: any; // changed it to any since it gave an error. I think because we upgrade to the latest Typescript
  cancel: () => void;
  saveChanges: () => void;
}) => {
  const title = `Add ${principalType} to "${workspaceId}" Workspace`;

  const principalId = selectedPrincipal[0]?.label || `The new ${principalType.toLocaleLowerCase()}`;

  const onChange = (newSelectedPrincipal: EuiComboBoxOptionOption<T>[]) => {
    // We should only get back either 0 or 1 options.
    setSelectedPrincipal(newSelectedPrincipal);
  };

  return (
    <TectonBasicModal
      style={{ width: `80em` }}
      maxWidth={'80vw'}
      title={title}
      body={
        <div style={{ minWidth: `50em` }}>
          <FormRow label={`Select ${principalType}`} fullWidth>
            <ComboBox
              fullWidth
              aria-label={`Select ${principalType}`}
              placeholder={`Select a ${principalType}`}
              singleSelection={{ asPlainText: true }}
              options={principalsList}
              selectedOptions={selectedPrincipal}
              onChange={onChange}
              isLoading={isFetching}
              renderOption={renderOption}
            />
          </FormRow>
          <FormRow label="Select Role" fullWidth>
            <WorkspaceRoleSelectorFormRow
              userId={principalId}
              allRoleRecords={allRoleRecords}
              roleOptions={roleOptions}
              selectedRole={selectedRole}
              setSelectedRole={setSelectedRole}
            />
          </FormRow>
        </div>
      }
      actions={
        <>
          <ButtonEmpty onClick={cancel}>Cancel</ButtonEmpty>
          <Button
            fill
            isDisabled={selectedPrincipal.length !== 1 || selectedRole === ''}
            isLoading={isLoading}
            onClick={saveChanges}
          >
            Add
          </Button>
        </>
      }
      calloutMessages={errorMessages}
      onClose={cancel}
    />
  );
};

export default AddWorkspaceAccessModal;
