import _ from 'lodash';
import store from '../core/store';
import { getCurrentUserAction } from '../feature/auth/actions';
import MetadataService from '../service/MetadataService';

const sessionStartTimeMs = new Date().getTime();
let initialized = false;
let initializationStarted = false;
let userEmail = undefined;
let queuedEvents = [];

function initialize() {
  if (initializationStarted) {
    return;
  }

  const auth = store.getState().auth;
  if (auth.didFetchUser) {
    initializationStarted = true;
    if (auth.auth) {
      userEmail = _.get('auth', 'user.email');
      initialized = true;
    } else {
      userEmail = 'UNKNOWN';
      initialized = true;
    }
  } else {
    store.dispatch(getCurrentUserAction.request());
  }
}

function logEvent(eventName, errorMsg = '', params = {}) {
  queuedEvents.push(constructEvent(eventName, errorMsg, params));
  if (!initialized) {
    initialize();
    return;
  }
  MetadataService.sharedInstance().ingestAnalytics(queuedEvents);
  queuedEvents = [];
}

function constructEvent(eventName, error_message = '', params = {}) {
  return {
    user_id: userEmail,
    event_type: eventName,
    platform: 'Web',
    session_id: sessionStartTimeMs,
    timestamp: new Date().getTime(),
    event_properties: {
      params: params,
      error_message,
    },
  };
}

export { logEvent };
