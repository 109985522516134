import styled from '@emotion/styled';
import { FCOIconAndName, useTectonTheme } from '@tecton/ComponentRedesign';
import { ScaleTime } from 'd3-scale';
import React, { FC } from 'react';
import { FCOType } from '../../../../core/types/fcoTypes';
import { ServingStatusEvent, ServingStatusPanelTableDatum } from '../../../servingStatus/types';
import { TectonTimeSince } from '../utils';
import ServingStatusBar from './StatusBar';
import ServingStatusTooltip from './Tooltip';
import { Link } from 'react-router-dom';

interface ServingStatusTableRowProps {
  datum: ServingStatusPanelTableDatum;
  startDate: Date;
  xScale: ScaleTime<number, number, never>;
  rowIndex: number;
  maxWidth: number;
  highlightedBar:
    | { id: string; rowIndex: number; event?: ServingStatusEvent; streamingDatum?: ServingStatusPanelTableDatum }
    | undefined;
  setHighlightedBar: React.Dispatch<
    React.SetStateAction<
      | { id: string; rowIndex: number; event?: ServingStatusEvent; streamingDatum?: ServingStatusPanelTableDatum }
      | undefined
    >
  >;
  structuredDataForTable: ServingStatusPanelTableDatum[];
  workspace: string;
}

const FCOContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const StreamLabel = styled.div`
  padding: 0px 44px;
  font-size: ${({ theme }) => theme.font.fontSizes.xs};
  color: ${({ theme }) => theme.colors.subduedText};
`;

const LastProcessedCell = styled.div`
  display: flex;
  font-size: ${({ theme }) => theme.font.fontSizes.xs};
  padding: ${({ theme }) => theme.padding.m + ' ' + theme.padding.s};
  flex-direction: column;
  align-items: flex-start;
`;

export const GridRow = styled.div`
  display: grid;
  grid-template-columns: 260px 1fr 128px;
  overflow: visible;
  background-color: #fff;

  :last-of-type {
    border-bottom-left-radius: ${({ theme }) => theme.border.radius.medium};
    border-bottom-right-radius: ${({ theme }) => theme.border.radius.medium};
  }
`;

const ServingStatusTableRow: FC<ServingStatusTableRowProps> = ({
  datum,
  startDate,
  xScale,
  rowIndex,
  maxWidth,
  setHighlightedBar,
  structuredDataForTable,
  highlightedBar,
  workspace,
}) => {
  const { theme } = useTectonTheme();
  // const { workspace } = useUserSettings();

  const showStreaming =
    datum.streamingStartDate &&
    datum.streamingMostRecentDate &&
    datum.streamingStatus &&
    datum.streamingMostRecentDate.getTime() >= startDate.getTime();

  let streamingX = 0;
  let streamingWidth = 0;
  if (showStreaming) {
    streamingX = xScale(datum.streamingStartDate!) < 0 ? 0 : xScale(datum.streamingStartDate!);
    streamingWidth = xScale(datum.streamingMostRecentDate!) - streamingX;
  }

  return (
    <GridRow
      style={{
        // opacity: highlightedBar ? (highlightedBar.rowIndex === rowIndex ? 1 : 0.5) : 1,
        borderTop: rowIndex === 0 ? theme.border.thin : 'none',
        borderBottom: rowIndex === structuredDataForTable.length - 1 ? 'none' : theme.border.thin,
        zIndex: rowIndex,
      }}
    >
      <FCOContainer>
        <div>
          <Link to={`/repo/${workspace}/features/${datum.featureView.name}/dataflow`}>
            <FCOIconAndName type={FCOType.FEATURE_VIEW} name={datum.featureView.name} />
          </Link>
        </div>
        {datum.streamingStartDate && <StreamLabel>Streaming</StreamLabel>}
      </FCOContainer>
      <div style={{ zIndex: 3 }}>
        <svg width={maxWidth} height={datum.streamingMostRecentDate ? 70 : 35} style={{ overflow: 'visible' }}>
          <defs>
            <clipPath id="clipFrame">
              <rect width={maxWidth} height={70} x={0} y={0} />
            </clipPath>
          </defs>

          {datum.events.map((event, index) => {
            const id = `${datum.featureView.id}_${index}`;
            if (event.endDate.getTime() < startDate.getTime()) {
              return <></>; // Value is outside of range
            }

            const xValue = xScale(event.startDate) < 0 ? 0 : xScale(event.startDate);
            const width = xScale(event.endDate) - xValue;
            let tooltipX = xValue + width / 2;
            if (tooltipX > maxWidth) {
              const remainingWidth = maxWidth - xValue;
              tooltipX = xValue + remainingWidth / 2;
            }

            return (
              <>
                <g transform="translate(0,10)">
                  <ServingStatusBar
                    x={xValue}
                    width={width}
                    state={event.status}
                    deemphasized={highlightedBar ? (highlightedBar.id !== id ? true : false) : undefined}
                    onMouseEnter={() => {
                      setHighlightedBar({
                        id: id,
                        rowIndex: rowIndex,
                        event: event,
                      });
                    }}
                    onMouseLeave={() => {
                      setHighlightedBar(undefined);
                    }}
                  />
                  {highlightedBar && id === highlightedBar.id && (
                    <ServingStatusTooltip batchEvent={event} x={tooltipX} y={-74} />
                  )}
                </g>
              </>
            );
          })}
          {showStreaming && (
            <>
              <g transform="translate(0,40)">
                <ServingStatusBar
                  x={streamingX!}
                  width={streamingWidth!}
                  state={datum.streamingStatus!}
                  deemphasized={
                    highlightedBar
                      ? highlightedBar.id !== `${datum.featureView.id}_stream}`
                        ? true
                        : false
                      : undefined
                  }
                  onMouseEnter={() => {
                    setHighlightedBar({
                      id: `${datum.featureView.id}_stream}`,
                      rowIndex: rowIndex,
                      streamingDatum: datum,
                    });
                  }}
                  onMouseLeave={() => {
                    setHighlightedBar(undefined);
                  }}
                />
              </g>
              {highlightedBar && `${datum.featureView.id}_stream}` === highlightedBar.id && (
                <ServingStatusTooltip streamingDatum={datum} x={streamingX! + streamingWidth! / 2} y={-34} />
              )}
            </>
          )}
        </svg>
      </div>
      <LastProcessedCell>
        <div>{TectonTimeSince(datum.events[datum.events.length - 1].endDate)} </div>
        {datum.streamingMostRecentDate && (
          <div style={{ paddingTop: '12px' }}>{TectonTimeSince(datum.streamingMostRecentDate)}</div>
        )}
      </LastProcessedCell>
    </GridRow>
  );
};

export default ServingStatusTableRow;
