import React, { useContext, useState } from 'react';
import { EuiBasicTableColumn, Button, Panel, Spacer, DebouncedFieldSearch } from '@tecton';
import { ACLTableControlLayout } from '../acl/ACLLayout';
import ModifyWorkspaceRoleModal from '../acl/modals/ModifyWorkspaceRoleModal';
import TableActionButtonWithDisableMode from '../shared/TableActionButtonWithDisableMode';
import { ACLRoleSource, ACLRoleWithInheritance, getAssignedRole } from '../acl/types';
import AllRolesPopover from '../acl/AllRolesPopover';
import ColumnNameWithHelp from '../shared/ColumnNameWithHelp';
import GroupNameColumn from '../acl/GroupNameColumn';
import AddWorkspaceAccessModal from './modals/AddWorkspaceAccessModal';
import ACLTableRowPropsFn from '../acl/ACLTableRowPropsFn';
import { ConfigurationContext } from './ConfigurationContextProvider';
import { ACLGroupModal } from '../acl/aclUtils';
import { TectonBasicTable } from '../@shared';
import { UserSettingsContext } from '../context/UserSettingsContext';

export type GroupPermissionRow = {
  groupId: string;
  name: string;
  role: ACLRoleWithInheritance;
  hasPriority?: boolean;
  numberOfUsers: number;
  numberOfServiceAccounts: number;
};

const GroupsWithAccess = () => {
  const configurationContext = useContext(ConfigurationContext);
  const userSettingsContext = useContext(UserSettingsContext);
  const groups: GroupPermissionRow[] = configurationContext?.groupPermission ?? [];
  const [currentRole, setCurrentRole] = useState<undefined | string>(configurationContext?.selectedRole);
  const [selectedRoleSources, setSelectedRoleSources] = useState<ACLRoleSource[] | undefined>();
  const isAdmin = userSettingsContext?.isAdmin ?? false;

  let addUserModal;

  if (configurationContext.modal === ACLGroupModal.ADD_WORKSPACE) {
    const saveChanges = async () => {
      configurationContext?.addWorkspaceToGroup?.call(configurationContext);
    };

    addUserModal = (
      <AddWorkspaceAccessModal
        workspaceId={configurationContext?.workspace ?? ''}
        principalType={'Group'}
        principalsList={configurationContext?.candidateGroupsComboBoxOptions ?? []}
        selectedPrincipal={configurationContext?.selectedPrincipal ?? []}
        setSelectedPrincipal={(principal) => {
          const selectedPrincipal = principal?.map((p) => {
            return p as { label: string; groupId: string };
          });
          configurationContext?.updateSelectedPrincipal?.call(configurationContext, selectedPrincipal);
        }}
        roleOptions={configurationContext?.newServiceAccountRoleOptions ?? []}
        selectedRole={configurationContext?.selectedRole as string}
        setSelectedRole={(role) => {
          configurationContext?.updateSelectedRole?.call(configurationContext, role);
        }}
        isFetching={false}
        isLoading={configurationContext?.isLoading ?? false}
        errorMessages={configurationContext?.modalError ?? []}
        cancel={() => {
          configurationContext?.resetSelections?.call(configurationContext);
        }}
        saveChanges={saveChanges}
        allRoleRecords={configurationContext?.allServiceAccountRoleRecords ?? []}
      />
    );
  }

  let modifyUserModal;

  if (configurationContext?.modal === ACLGroupModal.MODIFY_WORKSPACE) {
    const confirmModifyRole = async () => {
      configurationContext?.confirmModifyRole?.call(configurationContext);
    };

    modifyUserModal = (
      <ModifyWorkspaceRoleModal
        workspaceId={configurationContext?.workspace ?? ''}
        roleOptions={configurationContext?.existingServiceAccountOptions ?? []}
        userId={configurationContext?.selectedPrincipal?.[0].label as string}
        cancel={() => {
          configurationContext?.resetSelections?.call(configurationContext);
          setSelectedRoleSources(undefined);
        }}
        confirmChanges={confirmModifyRole}
        currentRole={currentRole}
        currentSourcesOfRoles={selectedRoleSources}
        selectedRole={configurationContext?.selectedRole as string}
        setSelectedRole={(role) => {
          configurationContext?.updateSelectedRole?.call(configurationContext, role);
        }}
        isLoading={configurationContext?.isLoading ?? false}
        errorMessages={configurationContext?.modalError}
        allRoleRecords={configurationContext?.allServiceAccountRoleRecords ?? []}
      />
    );
  }

  const columns: EuiBasicTableColumn<GroupPermissionRow>[] = [
    {
      field: 'groupId',
      name: 'Group Name',
      render: (field: string, row: GroupPermissionRow) => {
        return userSettingsContext?.isAdmin ? (
          <GroupNameColumn groupId={row.groupId} groupName={row.name} />
        ) : (
          <>{row.name}</>
        );
      },
    },
    {
      field: 'role',
      name: (
        <ColumnNameWithHelp
          name={'Role'}
          helpContent="A group's role can be directly assigned or set via their default workspace role. If there are multiple roles, Tecton will use the highest role as the effective role, as shown in this column."
        />
      ),
      render: (role: ACLRoleWithInheritance, row: GroupPermissionRow) => {
        return <AllRolesPopover roleWithInheritance={role} isGroupTable={true} />;
      },
    },
    {
      field: 'numberOfUsers',
      name: '# of Users',
    },
    {
      name: 'Actions',
      actions: [
        {
          name: 'Edit',
          isPrimary: true,
          onClick: () => {},
          render: (row: GroupPermissionRow) => {
            return (
              <TableActionButtonWithDisableMode
                isAdmin={isAdmin}
                requiresAdmin
                iconType={'pencil'}
                isDisabled={!userSettingsContext?.isAdmin}
                enabledMessage={'Edit the role of this group'}
                disabledMessage={'Group is not a direct member of the group, thus their role cannot be modified here.'}
                onClick={() => {
                  const currentAssignedRole = getAssignedRole(row.role);
                  configurationContext?.updateSelectedRole?.call(configurationContext, currentAssignedRole);
                  const selectedPrincipal = {
                    label: row.name ?? '',
                    groupId: row.groupId ?? '',
                  };

                  setSelectedRoleSources(row.role?.roleSources ?? []);
                  configurationContext?.updateSelectedPrincipal?.call(configurationContext, [selectedPrincipal]);
                  configurationContext?.showModifyWorkspace?.call(configurationContext);
                }}
              />
            );
          },
        },
      ],
    },
  ];

  return (
    <Panel hasBorder={false} hasShadow={false} paddingSize="xl">
      <ACLTableControlLayout
        search={
          <DebouncedFieldSearch
            fullWidth
            placeholder="Search for Group"
            onChange={(e) => {
              configurationContext?.setGroupSearch?.call(configurationContext, e.target.value);
            }}
          />
        }
        actionButtons={
          <Button
            onClick={() => {
              configurationContext?.showAddWorkspace?.call(configurationContext);
            }}
            fill
            disabled={!userSettingsContext?.isAdmin}
          >
            Add Group
          </Button>
        }
      />
      <Spacer size="l" />
      <TectonBasicTable
        rowProps={ACLTableRowPropsFn}
        items={groups}
        columns={columns}
        pageIndex={0}
        pageSize={0}
        totalItemCount={0}
      />
      {addUserModal}
      {modifyUserModal}
    </Panel>
  );
};

export default GroupsWithAccess;
