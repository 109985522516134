export default () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="2.0835"
        y="2.08337"
        width="15.8333"
        height="15.8333"
        rx="3"
        stroke="#525866"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M5 5V15" stroke="#525866" stroke-width="1.5" stroke-linecap="round" />
    </svg>
  );
};
