import { CallOut, Spacer } from '@tecton';

import processMaterializationInfo from './processMaterializationInfo';
import React from 'react';
import { StateUpdatePlanSummaryDiff } from '../../types/tecton_proto/data/state_update';

const MaterializationSummaryCallout = ({
  hasBeenApplied,
  diffItems,
}: {
  hasBeenApplied: boolean;
  diffItems: StateUpdatePlanSummaryDiff[];
}) => {
  const { batchMaterialization, streamMaterialization, backfillJobs } = processMaterializationInfo(diffItems);

  const summaries: React.ReactNode[] = [];

  let backfillCount = 0;
  if (backfillJobs.length > 0) {
    backfillCount = backfillJobs.reduce<number>((memo, current) => {
      return memo + (current?.number_of_jobs ?? 0);
    }, 0);

    summaries.push(
      <React.Fragment key="backfill">
        {' '}
        <strong>{backfillCount}</strong> backfill job
        {backfillCount > 1 ? 's' : ''}
      </React.Fragment>
    );
  }

  if (batchMaterialization.length > 0) {
    summaries.push(
      <React.Fragment key="batch">
        {' '}
        <strong>{batchMaterialization.length}</strong> recurring batch job
        {batchMaterialization.length > 1 ? 's' : ''}
      </React.Fragment>
    );
  }

  if (streamMaterialization.length > 0) {
    summaries.push(
      <React.Fragment key="stream">
        {' '}
        <strong>{streamMaterialization.length}</strong> recurring stream job
        {streamMaterialization.length > 1 ? 's' : ''}
      </React.Fragment>
    );
  }

  if (summaries.length === 0) {
    return (
      <>
        <Spacer size="xl" />
      </>
    );
  }

  const concattedSummary = summaries.reduce<React.ReactNode[]>((memo, entry, i, array) => {
    if (array.length === 1) {
      return [entry];
    } else {
      const separator = (
        <React.Fragment key={`separator-${i}`}>{i === array.length - 2 ? ' and ' : ', '}</React.Fragment>
      );

      memo.push(entry);
      if (i < array.length - 1) {
        memo.push(separator);
      }

      return memo;
    }
  }, []);

  const summaryString = (
    <>
      Tecton {hasBeenApplied ? 'created' : 'will create'}
      {concattedSummary}
      {hasBeenApplied ? (
        <> when this plan was applied</>
      ) : (
        <>
          {' '}
          <em style={{ textDecoration: 'underline' }}>if this plan is applied</em>
        </>
      )}
      .
    </>
  );

  return (
    <>
      <Spacer size="m" />
      <CallOut
        size="m"
        title={summaryString}
        iconType={hasBeenApplied ? 'checkInCircleFilled' : 'indexEdit'}
        color={hasBeenApplied ? 'success' : 'primary'}
      />
      <Spacer size="m" />
    </>
  );
};

export default MaterializationSummaryCallout;
