import React from 'react';
import { Text, ButtonEmpty, Button, Code } from '@tecton';

import { TectonBasicModal, CalloutMessageType } from '@shared';

interface DeleteAttributeConfirmationModalProps {
  attributeValue: string;
  groupName: string;
  isLoading: boolean;
  errorMessages: CalloutMessageType[] | undefined;
  onClose: () => void;
  confirmChanges: () => void;
}

const DeleteAttributeConfirmationModal = ({
  attributeValue,
  groupName,
  isLoading,
  errorMessages,
  onClose,
  confirmChanges,
}: DeleteAttributeConfirmationModalProps) => {
  return (
    <TectonBasicModal
      title={<h4>Delete Identity Provider Attribute?</h4>}
      body={
        <Text>
          <p>
            Are you sure you want to delete <Code>{attributeValue}</Code> for the group '{groupName}'? Users with this
            attribute will no longer be automatically add to this group.
          </p>
        </Text>
      }
      actions={
        <>
          <ButtonEmpty onClick={onClose}>Cancel</ButtonEmpty>
          <Button isLoading={isLoading} onClick={confirmChanges} fill color="danger">
            Delete Attribute
          </Button>
        </>
      }
      calloutMessages={errorMessages}
      onClose={onClose}
    />
  );
};

export default DeleteAttributeConfirmationModal;
