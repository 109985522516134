import { rest } from 'msw';

let hasUpdateUserDeployment = true;

const mswGetUserDeploymentSettings = rest.post('/v1/metadata-service/get-user-deployment-settings', (req, res, ctx) => {
  if (typeof req.body !== 'string') {
    return res(
      ctx.status(500),
      ctx.json({
        success: false,
        message: 'No request body',
      })
    );
  }

  const userDeploymentResponse = {
    user_deployment_settings: {
      user_deployment_settings_version: 1,
      databricks_config: {
        workspace_url: 'https://tecton-dev-shared-databricks.cloud.databricks.com',
        api_token: { redacted_value: '********' },
        user_name: 'matt.murillo@tecton.ai',
      },
      user_spark_settings: {
        instance_profile_arn: 'arn:aws:iam::843850565897:instance-profile/tecton-dev-shared-emr-spark-role',
        spark_conf: {
          'spark.databricks.hive.metastore.glueCatalog.enabled': 'true',
          'spark.hadoop.hive.metastore.glue.catalogid': '472542229217',
        },
      },
      tenant_settings: {},
    },
  };

  if (!hasUpdateUserDeployment) {
    return res(
      ctx.status(200),
      ctx.json({
        databases: { user_deployment_settings: {} },
        success: true,
      })
    );
  }

  return res(ctx.status(200), ctx.json(userDeploymentResponse));
});

export default mswGetUserDeploymentSettings;
