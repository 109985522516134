import { useContext, useState } from 'react';
import { ButtonEmpty, ErrorBoundary, ErrorBoundaryVisual, GraphQLErrorDisplay, Panel, Spacer } from '@tecton';
import { matchPath, Navigate, Outlet, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import ProfileLayout from './ProfileLayout';
import { TectonBreadcrumbTitles, TectonTabs } from '@shared';
import ACLProfileDescriptionList, { ListTitleWithEditTrigger, editableItem } from './ACLProfileDescriptionList';
import ACLAccountStatusBadges from './ACLAccountStatusBadges';
import ACLAdminActionsList from './ACLAdminActionsList';
import ButtonRow from '../shared/ButtonRow';
import NoMatch from '../shared/NoMatch';
import DeleteConfirmationModal from './modals/DeleteConfirmationModal';
import { ACLPrincipalModal } from './aclUtils';
import { Routes as AppRoutes } from '../../core/routes';
import { useUserSettings } from '../context/UserSettingsContext';
import { ACLServiceAccountProfileContextGQL } from './ACLServiceAccountProfileContextGQL';
import ACLPrincipalWorkspacesGQL from './ACLPrincipalWorkspacesGQL';
import AccountTypeChangeModalGQL from './modals/AccountTypeChangeModalGQL';
import ACLAccountTypePopoverUserDetail from './ACLAccountTypePopoverUserDetail';
import FieldForm from './FieldForm';
import { AccountType } from './types';
import DeactivateConfirmationModal from './modals/DeactivateConfirmationModal';
import ReactivateConfirmationModal from './modals/ReactivateConfirmationModal';
import ACLPrincipalGroupsGQL from './ACLPrincipalGroupsGQL';
import { PrincipalType } from '../../types/tecton_proto/auth/principal';

const ACLServiceAccountProfileFrame = () => {
  const navigate = useNavigate();
  const serviceAccountProfileGQLContext = useContext(ACLServiceAccountProfileContextGQL);
  const location = useLocation();
  const { serviceAccount } = useParams();
  const { isAdmin } = useUserSettings();
  const initialName = serviceAccountProfileGQLContext?.serviceAccount?.name;
  const initialDescription = serviceAccountProfileGQLContext?.serviceAccount?.description;

  const [initialServiceName, setInitialServiceName] = useState(initialName);
  const [initialServiceDescription, setInitialDescription] = useState(initialDescription);
  const [isEditingName, setIsEditingName] = useState<boolean | undefined>(false);
  const [errorName, setErrorName] = useState<string>('');
  const [isEditingDescription, setIsEditingDescription] = useState<boolean | undefined>(false);
  const [isErrorDescription, setIsErrorDescription] = useState<boolean>(false);
  const [errorDescription, setErrorDescription] = useState<string>('');
  const [isErrorName, setIsErrorName] = useState<boolean>(false);
  // States for the page
  const [selectedAccountType, setSelectedAccountType] = useState<AccountType | undefined>(
    serviceAccountProfileGQLContext?.accountType?.assignedAccountType as AccountType
  );
  const [originalAccountType, setOriginalAccountType] = useState<AccountType | undefined>(
    serviceAccountProfileGQLContext?.accountType?.assignedAccountType as AccountType
  );

  // Handle Error here.
  if (serviceAccountProfileGQLContext?.error) {
    return (
      <>
        <GraphQLErrorDisplay
          errorTitle={<h2>Unable to load User</h2>}
          graphQLErrors={serviceAccountProfileGQLContext?.error?.response?.errors ?? []}
        />
      </>
    );
  }

  const onErrorDescription = (error: string) => {
    setErrorDescription(error);
    setIsErrorDescription(true);
  };

  const onSuccessDescription = () => {
    setIsEditingDescription(false);
    setErrorDescription('');
  };

  const onErrorName = (error: string) => {
    setErrorName(error);
    setIsErrorName(true);
  };

  const onSuccessName = () => {
    setIsEditingName(false);
    setErrorName('');
  };

  const saveDescription = (newDescription: string) => {
    serviceAccountProfileGQLContext?.updateDescription?.call(
      serviceAccountProfileGQLContext,
      newDescription,
      onSuccessDescription,
      onErrorDescription
    );
  };

  const onNameError = (newName: string) => {
    serviceAccountProfileGQLContext?.updateName?.call(
      serviceAccountProfileGQLContext,
      newName,
      onSuccessName,
      onErrorName
    );
  };

  const nameField = editableItem({
    title: 'Name',
    viewMode: <p>{initialName}</p>,
    editMode: (
      <FieldForm
        description={initialServiceName ?? ''}
        setDescription={setInitialServiceName}
        cancel={() => {
          setIsEditingName(false);
          setErrorName('');
          setInitialServiceName(initialName);
        }}
        isLoading={serviceAccountProfileGQLContext?.isModalLoading ?? false}
        isInvalid={isErrorName}
        isDisabled={false}
        errorMessages={[errorName]}
        saveDescription={onNameError}
      />
    ),
    editState: [isEditingName, setIsEditingName],
    isAdmin: (isAdmin || serviceAccountProfileGQLContext?.isOwner) ?? false,
  });

  const descriptionField = editableItem({
    title: 'Description',
    viewMode: <p style={{ overflowWrap: 'anywhere' }}>{initialServiceDescription}</p>,
    editMode: (
      <FieldForm
        description={initialServiceDescription ?? ''}
        setDescription={setInitialDescription}
        cancel={() => {
          setIsEditingDescription(false);
          setErrorDescription('');
          setInitialDescription(serviceAccountProfileGQLContext?.serviceAccount?.description);
        }}
        isLoading={serviceAccountProfileGQLContext?.isModalLoading ?? false}
        isInvalid={isErrorDescription ?? false}
        isDisabled={false}
        errorMessages={[errorDescription]}
        saveDescription={saveDescription}
      />
    ),
    editState: [isEditingDescription, setIsEditingDescription],
    isAdmin: (isAdmin || serviceAccountProfileGQLContext?.isOwner) ?? false,
  });

  let changeServiceAccountRoleModal;

  if (serviceAccountProfileGQLContext?.modal === ACLPrincipalModal.EDIT_ACCOUNT_TYPE) {
    const onConfirm = async () => {
      // Send the request
      serviceAccountProfileGQLContext?.updateAccountType?.call(serviceAccountProfileGQLContext, selectedAccountType);
      setOriginalAccountType(selectedAccountType);
    };

    const onCloseModal = () => {
      serviceAccountProfileGQLContext?.closeModal?.call(serviceAccountProfileGQLContext);
      setSelectedAccountType(originalAccountType);
    };

    // We need to check if the user has any admin types via group
    const hasOtherSourcesOfAccountType = serviceAccountProfileGQLContext?.accountType?.inheritedAccountTypes
      ? serviceAccountProfileGQLContext?.accountType?.inheritedAccountTypes?.length > 0
      : false;

    changeServiceAccountRoleModal = (
      <AccountTypeChangeModalGQL<AccountType>
        userId={serviceAccountProfileGQLContext?.serviceAccount?.name ?? ''}
        currentAccountType={originalAccountType}
        onClose={onCloseModal}
        confirmChange={onConfirm}
        isLoading={serviceAccountProfileGQLContext?.isModalLoading ?? false}
        selectedAccountType={selectedAccountType}
        setSelectedAccountType={(role: AccountType) => {
          setSelectedAccountType(role);
        }}
        errorMessages={serviceAccountProfileGQLContext?.errorMessages}
        hasOtherSourcesOfAccountType={hasOtherSourcesOfAccountType}
      />
    );
  }

  let deprovisionConfirmationModal;

  if (serviceAccountProfileGQLContext?.modal === ACLPrincipalModal.DEPROVISION) {
    const confirmChange = async () => {
      serviceAccountProfileGQLContext?.deprovision?.call(serviceAccountProfileGQLContext);
    };

    deprovisionConfirmationModal = (
      <DeactivateConfirmationModal
        principalId={serviceAccountProfileGQLContext?.serviceAccount?.name ?? ''}
        confirmChange={confirmChange}
        onClose={() => {
          serviceAccountProfileGQLContext.closeModal?.call(serviceAccountProfileGQLContext);
        }}
        isLoading={serviceAccountProfileGQLContext?.isModalLoading ?? false}
        errorMessages={serviceAccountProfileGQLContext?.modalError ?? []}
      />
    );
  }

  let reactivateConfirmationModal;
  if (serviceAccountProfileGQLContext?.modal === ACLPrincipalModal.REACTIVATE) {
    const confirmChange = async () => {
      serviceAccountProfileGQLContext?.reactivate?.call(serviceAccountProfileGQLContext);
    };

    reactivateConfirmationModal = (
      <ReactivateConfirmationModal
        principalId={serviceAccountProfileGQLContext?.serviceAccount?.name ?? ''}
        confirmChange={confirmChange}
        onClose={() => {
          serviceAccountProfileGQLContext.closeModal?.call(serviceAccountProfileGQLContext);
        }}
        isLoading={serviceAccountProfileGQLContext?.isModalLoading ?? false}
        errorMessages={serviceAccountProfileGQLContext?.modalError ?? []}
      />
    );
  }

  let deleteConfirmationModal;

  if (serviceAccountProfileGQLContext?.modal === ACLPrincipalModal.DELETE) {
    const confirmChange = async () => {
      serviceAccountProfileGQLContext?.deleteServiceAccount?.call(serviceAccountProfileGQLContext);
    };

    reactivateConfirmationModal = (
      <DeleteConfirmationModal
        principalId={serviceAccountProfileGQLContext?.serviceAccount?.name ?? ''}
        confirmChange={confirmChange}
        onClose={() => {
          serviceAccountProfileGQLContext.closeModal?.call(serviceAccountProfileGQLContext);
        }}
        isLoading={serviceAccountProfileGQLContext?.isModalLoading ?? false}
        errorMessages={serviceAccountProfileGQLContext?.modalError ?? []}
      />
    );
  }

  const adminActionItems =
    serviceAccountProfileGQLContext?.serviceAccount?.isActive === true
      ? [
          {
            title: 'Deactivate Service Account',
            description: (
              <>
                <p>This disables the Service Account but does not permanently delete it.</p>
                <Spacer size="s" />
                <ButtonRow>
                  <ButtonEmpty
                    onClick={() => {
                      serviceAccountProfileGQLContext?.updateModal?.call(
                        serviceAccountProfileGQLContext,
                        ACLPrincipalModal.DEPROVISION
                      );
                    }}
                    iconType="trash"
                    color="danger"
                    size="s"
                  >
                    Deactivate Service Account
                  </ButtonEmpty>
                </ButtonRow>
              </>
            ),
          },
        ]
      : [
          {
            title: 'Activate Service Account',
            description: (
              <>
                <p>
                  Activating a service account restores the account&rsquo;s access to what it was before deactivated.
                </p>
                <Spacer size="s" />
                <ButtonRow>
                  <ButtonEmpty
                    onClick={() => {
                      serviceAccountProfileGQLContext?.updateModal?.call(
                        serviceAccountProfileGQLContext,
                        ACLPrincipalModal.REACTIVATE
                      );
                    }}
                    iconType="check"
                    color="primary"
                    size="s"
                  >
                    Activate Service Account
                  </ButtonEmpty>
                </ButtonRow>
              </>
            ),
          },
          {
            title: 'Delete Service Account',
            description: (
              <>
                <p>Deleting a service account removes the account from Tecton permanently.</p>
                <Spacer size="s" />
                <ButtonRow>
                  <ButtonEmpty
                    onClick={() => {
                      serviceAccountProfileGQLContext?.updateModal?.call(
                        serviceAccountProfileGQLContext,
                        ACLPrincipalModal.DELETE
                      );
                    }}
                    iconType="trash"
                    color="danger"
                    size="s"
                  >
                    Delete Service Account
                  </ButtonEmpty>
                </ButtonRow>
              </>
            ),
          },
        ];

  const tabs = [
    {
      label: 'Workspaces',
      count: serviceAccountProfileGQLContext?.workspaceCount,
      isSelected: !!matchPath(
        {
          path: `${AppRoutes.accountsAndAccessServiceAccounts}/${serviceAccount}/workspaces`,
          end: true,
        },
        location.pathname
      ),
      onClick: () => {
        navigate('../workspaces/');
      },
    },
    {
      label: 'Group Memberships',
      count: serviceAccountProfileGQLContext?.assignedGroups?.length ?? 0,
      isSelected: !!matchPath(
        {
          path: `${AppRoutes.accountsAndAccessServiceAccounts}/${serviceAccount}/groups`,
          end: true,
        },
        location.pathname
      ),
      onClick: () => {
        navigate('../groups/');
      },
    },
  ];

  return (
    <>
      <ProfileLayout
        isLoading={serviceAccountProfileGQLContext?.isLoading}
        metadataColumn={
          <>
            <span style={{ overflowWrap: 'anywhere' }}>
              <TectonBreadcrumbTitles
                breadcrumbs={[
                  {
                    text: 'Accounts & Access',
                    onClick: () => {
                      navigate(`${AppRoutes.accountAndAccess}`);
                    },
                  },
                  {
                    text: 'Service Account',
                    onClick: () => {
                      navigate(`${AppRoutes.accountsAndAccessServiceAccounts}`);
                    },
                  },
                  {
                    text: (
                      <div
                        style={{
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          display: 'block',
                          width: '100%',
                          overflowWrap: 'anywhere',
                          textAlign: 'left',
                        }}
                      >
                        {serviceAccountProfileGQLContext?.serviceAccount?.name}
                      </div>
                    ),
                  },
                ]}
              />
            </span>

            <ACLProfileDescriptionList
              listItems={[
                { title: 'ID', description: serviceAccountProfileGQLContext?.serviceAccount?.id ?? '' },
                nameField,
                descriptionField,
                ...(isAdmin
                  ? [
                      {
                        title: (
                          <ListTitleWithEditTrigger
                            title={'Account Type'}
                            isEditing={false}
                            isAdmin={isAdmin ?? false}
                            toggleEditMode={() => {
                              serviceAccountProfileGQLContext?.updateModal?.call(
                                serviceAccountProfileGQLContext,
                                ACLPrincipalModal.EDIT_ACCOUNT_TYPE
                              );
                            }}
                          />
                        ),
                        description: serviceAccountProfileGQLContext?.accountType ? (
                          <ACLAccountTypePopoverUserDetail accountType={serviceAccountProfileGQLContext?.accountType} />
                        ) : (
                          <></>
                        ),
                      },
                    ]
                  : []),
                {
                  title: 'Status',
                  description: (
                    <ACLAccountStatusBadges
                      type={serviceAccountProfileGQLContext?.serviceAccount?.isActive ? 'active' : 'inactive'}
                    />
                  ),
                },
              ]}
            />
            <ACLAdminActionsList listItems={adminActionItems} />
            {changeServiceAccountRoleModal}
            {deprovisionConfirmationModal}
            {reactivateConfirmationModal}
            {deleteConfirmationModal}
          </>
        }
        contentColumn={
          <>
            <TectonTabs tabs={tabs} />
            <ErrorBoundary>
              <Outlet />
            </ErrorBoundary>
          </>
        }
      />
    </>
  );
};

const ACLServiceAccountProfileGQL = () => {
  const { serviceAccount: principalId } = useParams();
  const serviceAccountProfileGQLContext = useContext(ACLServiceAccountProfileContextGQL);

  return (
    <Panel hasBorder={false} hasShadow={false} paddingSize="xs" className="eui-yScroll">
      <Routes>
        <Route path="/" element={<ACLServiceAccountProfileFrame />}>
          <Route index element={<Navigate to="./workspaces" replace />} />
          <Route
            path="/workspaces"
            element={
              <ACLPrincipalWorkspacesGQL
                context={ACLServiceAccountProfileContextGQL}
                principalId={principalId ?? ''}
                principalName={serviceAccountProfileGQLContext?.serviceAccount?.name ?? ''}
                principalType={PrincipalType.PRINCIPAL_TYPE_SERVICE_ACCOUNT}
              />
            }
          />
          <Route
            path="/groups"
            element={
              <ACLPrincipalGroupsGQL
                context={ACLServiceAccountProfileContextGQL}
                principalId={principalId ?? ''}
                principalName={serviceAccountProfileGQLContext?.serviceAccount?.name ?? ''}
                principalType={PrincipalType.PRINCIPAL_TYPE_SERVICE_ACCOUNT}
              />
            }
          />
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </Panel>
  );
};

export default ACLServiceAccountProfileGQL;
