import React, { ReactNode, createContext, useContext, useState } from 'react';
import { ConfigurationUserModal } from './configuration-utils';
import { EuiComboBoxOptionOption } from '../@tecton';
import { useAssignRolesMutation } from '../../api/acl';
import { CalloutMessageType } from '@shared';
import { ToastContext, addToast } from '../@tecton/ToastContext';
import { logEvent } from '../../utils/analytics-utils';
import { useParams } from 'react-router';
import { PrincipalType } from '../../types/tecton_proto/auth/principal';
import { ResourceType } from '../../types/tecton_proto/auth/resource';
import { ConfigurationContext } from './ConfigurationContextProvider';
import { User } from '../../types/tecton_proto/data/user';
import { getLegacyRoleId } from '../acl/aclUtils';

export const UserAccessContext = createContext<any>(null);

const UserAccessContextProvider = ({ children }: { children: ReactNode }) => {
  const toastContext = useContext(ToastContext);
  const configurationContext = useContext(ConfigurationContext);
  const { workspace } = useParams();
  const updateWorkspaceToServiceAccountMutation = useAssignRolesMutation();

  const [selectedRole, setSelectedRole] = useState<string | ''>('');
  const [selectedPrincipal, setSelectedPrincipal] = useState<EuiComboBoxOptionOption<string>[]>([]);
  const [modal, setModal] = useState<ConfigurationUserModal | undefined>();
  const [modalError, setModalError] = useState<CalloutMessageType[] | undefined>();

  const onError = (error: any) => {
    const errorMessage = error?.response?.data?.message ?? error?.response?.statusText;

    setModalError([
      {
        title: String(errorMessage),
        color: 'danger',
        iconType: 'alert',
      },
    ]);
  };

  const removingRole = selectedRole === 'None';
  const role = getLegacyRoleId(configurationContext?.serviceAccountRoles ?? [], selectedRole);
  const roles = removingRole ? [] : [role];

  const assignUserToWorkspace = () => {
    const email = selectedPrincipal[0]?.label;
    const resource_id = workspace;
    const principal_type = PrincipalType.PRINCIPAL_TYPE_USER;

    const resource_type =
      resource_id === 'All Workspaces' ? ResourceType.RESOURCE_TYPE_ORGANIZATION : ResourceType.RESOURCE_TYPE_WORKSPACE;
    const clusterAdminInfo = configurationContext?.clusterAdminInfoData ?? [];
    const selectedUser = clusterAdminInfo.find((user: User) => {
      return user?.login_email === email;
    });

    const principal_id = selectedUser?.okta_id;

    const payload = {
      principal_id,
      roles,
      resource_id: workspace,
      principal_type,
      resource_type,
    };

    const title = 'User Added to Workspace';
    const text = `User ${email} added to workspace ${workspace} as ${roles.join('')} role.`;

    updateWorkspaceToServiceAccountMutation.mutate(payload, {
      onSuccess: () => {
        // Clean up default values

        setSelectedRole('');

        setModalError(undefined);
        setModal(undefined);

        // Send toaster to the user
        toastContext?.dispatchToast(
          addToast({
            title,
            text,
            color: 'success',
            iconType: 'lock',
          })
        );

        logEvent('User Added to Workspace', '', {
          principal_id,
          resource_id,
          principal_type,
          resource_type,
        });
      },
      onError,
    });
  };

  const updateUserRole = () => {
    const email = selectedPrincipal[0]?.label;
    const resource_id = workspace;
    const principal_type = PrincipalType.PRINCIPAL_TYPE_USER;
    const resource_type = ResourceType.RESOURCE_TYPE_WORKSPACE;
    const clusterAdminInfo = configurationContext?.clusterAdminInfoData ?? [];
    const selectedUser = clusterAdminInfo.find((user: User) => {
      return user?.login_email === email;
    });

    const principal_id = selectedUser?.okta_id;

    const payload = {
      principal_id,
      roles,
      resource_id: workspace,
      principal_type: PrincipalType.PRINCIPAL_TYPE_USER,
      resource_type: ResourceType.RESOURCE_TYPE_WORKSPACE,
    };

    const title = 'User Role Updated';
    const text = `User ${email} role updated to ${selectedRole} in workspace ${workspace}.`;

    updateWorkspaceToServiceAccountMutation.mutate(payload, {
      onSuccess: () => {
        // Clean up default values

        setSelectedRole('');
        setModalError(undefined);
        setModal(undefined);
        setSelectedPrincipal([]);

        // Send toaster to the user
        toastContext?.dispatchToast(
          addToast({
            title,
            text,
            color: 'success',
            iconType: 'lock',
          })
        );

        logEvent('User Role Updated', '', {
          principal_id,
          resource_id,
          principal_type,
          resource_type,
        });
      },
      onError,
    });
  };

  const isModalLoading = updateWorkspaceToServiceAccountMutation.isLoading;

  return (
    <UserAccessContext.Provider
      value={{
        modal,
        setModal,
        selectedPrincipal,
        setSelectedPrincipal,
        selectedRole,
        setSelectedRole,
        assignUserToWorkspace,
        updateUserRole,
        isModalLoading,
        modalError,
        setModalError,
      }}
    >
      {children}
    </UserAccessContext.Provider>
  );
};

export default UserAccessContextProvider;
