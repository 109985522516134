export default () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0626 3.77102C13.2016 4.91006 13.2016 6.75678 12.0626 7.89582C10.9235 9.03485 9.0768 9.03485 7.93777 7.89582C6.79874 6.75678 6.79874 4.91006 7.93777 3.77102C9.0768 2.63199 10.9235 2.63199 12.0626 3.77102"
        stroke="#525866"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.3335 15.4168V16.2502C3.3335 16.7102 3.70683 17.0835 4.16683 17.0835H15.8335C16.2935 17.0835 16.6668 16.7102 16.6668 16.2502V15.4168C16.6668 12.8952 13.3735 11.2568 10.0002 11.2568C6.62683 11.2568 3.3335 12.8952 3.3335 15.4168Z"
        stroke="#525866"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
