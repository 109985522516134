export default () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="28" height="28" rx="6" stroke="#EAECF0" />
      <rect width="28" height="28" rx="6" stroke="white" stroke-opacity="0.4" />
      <path
        d="M7.3335 19.8333L10.6668 16.5"
        stroke="#525866"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.6665 19V16.5H8.1665"
        stroke="#525866"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.1667 20.6666H19.8333C20.7538 20.6666 21.5 19.9204 21.5 18.9999V11.4499C21.5 10.5294 20.7538 9.78325 19.8333 9.78325H14.441C14.1651 9.78325 13.9071 9.64675 13.752 9.41869L12.5811 7.69781C12.426 7.46974 12.168 7.33324 11.8921 7.33325H8.16667C7.24619 7.33325 6.5 8.07944 6.5 8.99992V13.9999"
        stroke="#525866"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
