import { EuiDescriptionList } from '@elastic/eui';
import styled from '@emotion/styled';
import React, { FC } from 'react';

interface TectonDescriptionListProps {
  items: {
    title: NonNullable<React.ReactNode>;
    description: NonNullable<React.ReactNode>;
  }[];
  type?: 'responsiveColumn' | 'inline' | 'row' | 'column';
}

const StyledDescriptionList = styled(EuiDescriptionList)`
font-size: ${({ theme }) => theme.font.fontSizes.s}};

.euiDescriptionList__title {
  font-size: ${({ theme }) => theme.font.fontSizes.s};
  font-weight: ${({ theme }) => theme.font.weight.regular};
    line-height: ${({ theme }) => theme.font.lineHeights.s};
}

.euiDescriptionList__description {
  font-weight: ${({ theme }) => theme.font.weight.regular};
  overflow: hidden;
  text-overflow: ellipsis;
}
`;

const TectonDescriptionList: FC<TectonDescriptionListProps> = ({ items, type = 'responsiveColumn' }) => {
  return <StyledDescriptionList listItems={items} type={type} data-testid="description-list" />;
};

export default TectonDescriptionList;
