import React, { FC } from 'react';

interface EntityIconProps {
  newVersion?: boolean;
}

const EntityIcon: FC<EntityIconProps> = ({ newVersion }) => {
  return (
    <g>
      <rect
        opacity="0.6"
        width="12.0003"
        height="12.0003"
        transform="matrix(0.866044 -0.499967 0.866044 0.499967 2.00006 5.99982)"
        fill="#E6751A"
      />
      <rect
        opacity="0.4"
        width="12.0003"
        height="12.0003"
        transform="matrix(0.866025 0.5 7.53505e-05 1 2.00006 5.99982)"
        fill="#E6751A"
      />
      <rect
        opacity="0.3"
        width="12.0003"
        height="12.0003"
        transform="matrix(-0.866025 0.5 -7.53505e-05 1 22.7859 5.99982)"
        fill="#E6751A"
      />
      <rect
        opacity="0.8"
        width="6.01311"
        height="6.01311"
        transform="matrix(0.866044 -0.499967 0.866044 0.499967 7.18491 8.99347)"
        fill="#E6751A"
      />
      <rect
        opacity="0.6"
        width="6.01311"
        height="6.0131"
        transform="matrix(0.866026 0.5 7.53505e-05 1 7.18491 8.99347)"
        fill="#E6751A"
      />
      <rect
        opacity="0.4"
        width="6.01311"
        height="6.0131"
        transform="matrix(-0.866025 0.5 -7.53505e-05 1 17.6004 8.99347)"
        fill="#E6751A"
      />
    </g>
  );
};

export default EntityIcon;
