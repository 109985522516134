import React, { FC, useState } from 'react';

import EditableFieldEdit from './EditableFieldEdit';
import EditableField from './EditableField';
import { EditableFieldWrapper } from '../StyledComponents';
import { RequestCallbackType } from '../SharedComponentTypes';

interface EditableInlineFieldProps {
  fieldTitle: string;
  fieldName: string;
  fieldValue: string;
  onFieldSaveConfirm: (filedName: string, newValue: string, callback: RequestCallbackType) => void;
}

const EditableInlineField: FC<EditableInlineFieldProps> = ({
  fieldTitle,
  fieldName,
  fieldValue,
  onFieldSaveConfirm,
}) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);

  return (
    <>
      {isEditing ? (
        <EditableFieldEdit
          fieldTitle={fieldTitle}
          fieldName={fieldName}
          fieldValue={fieldValue}
          onFieldSaveConfirm={onFieldSaveConfirm}
          closeEditMode={() => {
            setIsEditing(false);
          }}
        />
      ) : (
        <EditableFieldWrapper
          onClick={() => {
            setIsEditing(true);
          }}
        >
          <EditableField fieldValue={fieldValue} />
        </EditableFieldWrapper>
      )}
    </>
  );
};

export default EditableInlineField;
