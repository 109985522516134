import { StateUpdatePlanSummary } from '../../types/tecton_proto/data/state_update';
import {
  GetStateUpdatePlanSummaryResponse,
  QueryStateUpdateResponseV2,
} from '../../types/tecton_proto/metadataservice/metadata_service';
import { timeFormat } from 'd3-time-format';

export const transformPlanSummary = (data: GetStateUpdatePlanSummaryResponse) => {
  const plan = data.plan;

  return {
    plan,
    ...(plan?.applied_at ? { appliedAt: timeFormat('%Y-%m-%d %H:%M:%S')(new Date(plan?.applied_at ?? '')) } : {}),
    ...(plan?.created_at ? { createdAt: timeFormat('%Y-%m-%d %H:%M:%S')(new Date(plan?.created_at ?? '')) } : {}),
    hasApplied: !!plan?.applied_by && !!plan?.applied_at, // has been applied
  };
};

export type StateUpdatePlanSummaryType = ReturnType<typeof transformPlanSummary>;

export const transformQueryStateUpdateResponse = (data: QueryStateUpdateResponseV2) => {
  return { ...data };
};
