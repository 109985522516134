import React from 'react';
import { IconTip } from '@tecton';

const WorkspaceIDColumn = ({
  workspaceId,
  hasPriority,
  principalType,
}: {
  workspaceId: string;
  hasPriority?: boolean;
  principalType: 'user' | 'service account' | 'group';
}) => {
  return (
    <span
      style={{
        fontWeight: hasPriority === true ? '600' : 'inherit',
      }}
      className="workspace-id"
    >
      {workspaceId}
      {workspaceId === 'All Workspaces' && (
        <IconTip
          content={`The ${principalType}'s role for all workspaces in Tecton. This will set the role in every workspace.`}
          position="top"
          type="iInCircle"
        />
      )}
    </span>
  );
};

export default WorkspaceIDColumn;
