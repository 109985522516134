import IdUtils from '../utils/id-utils';
import WorkspaceUtils from '../utils/workspace-utils';
import { FirstClassObjectTypes } from '../components/FirstClassObject';

const FORMATTED_MODES = {
  TRANSFORMATION_MODE_PYTHON: 'Python',
  TRANSFORMATION_MODE_PANDAS: 'Pandas',
  TRANSFORMATION_MODE_PYSPARK: 'PySpark',
  TRANSFORMATION_MODE_SPARK_SQL: 'Spark SQL',
  TRANSFORMATION_MODE_SNOWFLAKE_SQL: 'Snowflake SQL',
  TRANSFORMATION_MODE_SNOWPARK: 'Snowpark',
  TRANSFORMATION_MODE_ATHENA: 'Athena',
  TRANSFORMATION_MODE_UNSPECIFIED: 'Unknown',
};

export default class Transformation {
  #proto = null;

  constructor(proto) {
    this.#proto = proto;
  }

  get _proto() {
    return this.#proto;
  }

  get fcoType() {
    return FirstClassObjectTypes.TRANSFORMATION;
  }

  get id() {
    return IdUtils.toStringId(this._proto.transformation_id);
  }

  get name() {
    return this._proto.fco_metadata.name;
  }

  get description() {
    return this._proto.fco_metadata.description;
  }

  get tags() {
    return this._proto.fco_metadata.tags || {};
  }

  get createdAt() {
    return new Date(this._proto.fco_metadata.created_at);
  }

  get owner() {
    return this._proto.fco_metadata.owner;
  }

  get lastModifiedBy() {
    return this._proto.fco_metadata.last_modified_by;
  }

  get workspace() {
    return this._proto.fco_metadata.workspace || WorkspaceUtils.getDefaultWorkspace();
  }

  get sourceFileName() {
    return this._proto.fco_metadata.source_filename;
  }

  get mode() {
    const mode = this._proto.transformation_mode;
    if (mode == null || !FORMATTED_MODES.hasOwnProperty(mode)) {
      return mode;
    }
    return FORMATTED_MODES[mode];
  }

  get code() {
    return this._proto.user_function.body;
  }

  get fcoMetadata() {
    return this._proto.fco_metadata;
  }
}
