import React, { FC } from 'react';
import { FeatureViewFCOType } from '../../core/types/fcoTypes';

import { FeatureViewTypeBadge } from '@tecton';

interface PlanApplyFeatureViewBadgeProps {
  type: string;
}

const PlanApplyFeatureViewBadge: FC<PlanApplyFeatureViewBadgeProps> = ({ type }) => {
  // This file reflects FCOStrings.kt on the backend
  const fvTypeShortName: Record<string, FeatureViewFCOType> = {
    'Feature Table': FeatureViewFCOType.FEATURE_TABLE,
    'Batch Feature View': FeatureViewFCOType.BATCH,
    'Stream Feature View': FeatureViewFCOType.STREAM,
    'On-Demand Feature View': FeatureViewFCOType.REALTIME,
    'Stream Window Aggregate': FeatureViewFCOType.BATCH_WINDOW_AGGREGATE,
    'Batch Window Aggregate': FeatureViewFCOType.BATCH_WINDOW_AGGREGATE,
    'Stream Feature View with Push Source': FeatureViewFCOType.PUSH_WITH_BATCH,
  };
  const fcoType = fvTypeShortName[type];

  return <FeatureViewTypeBadge type={fcoType} />;
};

export default PlanApplyFeatureViewBadge;
