import { ButtonEmpty, Flyout } from '@tecton';
import { css } from '@emotion/css';
import React, { useMemo, useState } from 'react';

const ConfigInFlyoutDisplay = ({
  displayElement,
  triggerButton,
  flyoutContent,
  widthPercent = 50, // In the case of codeblock for json config, we need to be wider for code blocks since side by side code block looks better
}: {
  displayElement: React.ReactNode;
  triggerButton?: (openPopover: () => void) => NonNullable<React.ReactNode>;
  flyoutContent: React.ReactNode;
  widthPercent?: number;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const openFlyout = useMemo(() => {
    return () => {
      setIsOpen(true);
    };
  }, [setIsOpen]);
  const closeFlyout = useMemo(() => {
    return () => {
      setIsOpen(false);
    };
  }, [setIsOpen]);

  let trigger: NonNullable<React.ReactNode>;

  if (triggerButton) {
    trigger = triggerButton(openFlyout);
  } else {
    trigger = (
      <ButtonEmpty onClick={openFlyout} iconType="inspect" iconSide="right">
        View Details
      </ButtonEmpty>
    );
  }

  return (
    <span
      className={css`
        // Hacks to get the button
        // to align with the text
        // display: flex;

        .euiButtonEmpty {
          height: auto;
          display: inline;
          align-self: center;
          line-height: 1;
          text-decoration: underline;
          text-decoration-style: dotted;
        }

        .euiButtonContent {
          padding: 0;
        }
      `}
    >
      {displayElement}&ensp;{trigger}
      {isOpen && (
        <Flyout
          ownFocus
          onClose={closeFlyout}
          style={{ width: `${widthPercent}%` }}
          maskProps={{ headerZindexLocation: 'above' }}
        >
          {flyoutContent}
        </Flyout>
      )}
    </span>
  );
};

export default ConfigInFlyoutDisplay;
