import Pipeline, { InputNode } from '../../service/Pipeline';
import { Duration } from '../../types/google/protobuf/duration';
import { DataSourceNode } from '../../types/tecton_proto/args/pipeline';
import { UserDefinedFunction } from '../../types/tecton_proto/args/user_defined_function';
import { BatchComputeMode } from '../../types/tecton_proto/common/compute_mode';
import { FrameworkVersion } from '../../types/tecton_proto/common/framework_version';
import { BatchDataSource, SparkBatchDataSourceFunction } from '../../types/tecton_proto/data/batch_data_source';
import { FeatureSetItem } from '../../types/tecton_proto/data/feature_service';
import {
  Embedding,
  Inference,
  NewMaterializationParams,
  OnlineServingIndex,
} from '../../types/tecton_proto/data/feature_view';
import { FeatureViewServingStatusSummary } from '../../types/tecton_proto/data/serving_status';
import { StreamDataSource } from '../../types/tecton_proto/data/stream_data_source';
import { FcoType } from '../../types/tecton_proto/metadataservice/metadata_service';
import { ClusterConfigInterface } from '../../utils/feature-view/feature-view-util';

export interface WorkspaceFCOContainer {
  idToFcoMap: Record<string, FCO>;
  dataSources: DataSourceFCO[];
  dataSourcesNamesMap: Record<string, FCO>;
  entities: EntityFCO[];
  entitiesNamesMap: Record<string, FCO>;
  featureServices: FeatureServiceFCO[];
  featureServicesNamesMap: Record<string, FCO>;
  featureViews: FeatureViewFCO[];
  featureViewsNamesMap: Record<string, FCO>;
  transformations: TransformationFCO[];
  transformationsNamesMap: Record<string, FCO>;
}

// TODO: We need to use FcoType which is generated code.
// web-ui/src/types/tecton_proto/metadataservice/metadata_service.ts
export enum FCOType {
  DATA_SOURCE = 'dataSource',
  ENTITY = 'entity',
  FEATURE_VIEW = 'featureView',
  FEATURE_SERVICE = 'featureService',
  TRANSFORMATION = 'transformation',
  DATASET = 'dataset',
  UNKNOWN = 'unknown',
}

export const fcoTypeRecord: Record<FcoType, FCOType> = {
  [FcoType.FCO_TYPE_UNSPECIFIED]: FCOType.UNKNOWN,
  [FcoType.FCO_TYPE_VIRTUAL_DATA_SOURCE]: FCOType.DATA_SOURCE,
  [FcoType.FCO_TYPE_ENTITY]: FCOType.ENTITY,
  [FcoType.FCO_TYPE_TRANSFORMATION]: FCOType.TRANSFORMATION,
  [FcoType.FCO_TYPE_FEATURE_VIEW]: FCOType.FEATURE_VIEW,
  [FcoType.FCO_TYPE_FEATURE_SERVICE]: FCOType.FEATURE_SERVICE,
};

export const FCOTypeHumanReadable: Record<FCOType, string> = {
  [FCOType.DATA_SOURCE]: 'Data Source',
  [FCOType.ENTITY]: 'Entity',
  [FCOType.FEATURE_VIEW]: 'Feature View',
  [FCOType.FEATURE_SERVICE]: 'Feature Service',
  [FCOType.TRANSFORMATION]: 'Transformation',
  [FCOType.DATASET]: 'Dataset',
  [FCOType.UNKNOWN]: 'Unknown',
};

export const FCOTypeHumanReadablePlural: Record<FCOType, string> = {
  [FCOType.DATA_SOURCE]: 'Data Sources',
  [FCOType.ENTITY]: 'Entities',
  [FCOType.FEATURE_VIEW]: 'Feature Views',
  [FCOType.FEATURE_SERVICE]: 'Feature Services',
  [FCOType.TRANSFORMATION]: 'Transformations',
  [FCOType.DATASET]: 'Datasets',
  [FCOType.UNKNOWN]: 'Unknown',
};

export type FCOKeyValuePair = { name: string; value: string | undefined };

export interface FCOTag {
  key: string;
  value: string;
}

// Creating an Enum for these fields specifically so that the key: property in EUI Tables does not have to be referenced by string.
// While creating the tables, I found it annoying to have to cross-reference the field with the exact string name definition from the interface.
// There may be a more elegant way to do this, or we might decide that it isn't worth the trouble.
export enum FCOFields {
  ID = 'id',
  NAME = 'name',
  DESCRIPTION = 'description',
  TRUNCATED_DESCRIPTION = 'truncatedDescription',
  TAGS = 'tags',
  FCO_TYPE = 'fcoType',
  CREATED_DATE = 'createdDate',
  OWNER = 'owner',
  LAST_MODIFIED_BY = 'lastModifiedBy',
  LAST_MODIFIED_DATE = 'lastModifiedDate',
  WORKSPACE = 'workspace',
  SOURCE_FILE_NAME = 'sourceFileName',
  PREVENT_DESTROY = 'preventDestroy',
}

export type AnyFCO = FeatureViewFCO | FeatureServiceFCO | DataSourceFCO | EntityFCO | TransformationFCO | DatasetFCO;

export interface FCO {
  [FCOFields.ID]: string;
  [FCOFields.NAME]: string | undefined;
  [FCOFields.DESCRIPTION]: string | undefined;
  [FCOFields.TRUNCATED_DESCRIPTION]: string;
  [FCOFields.TAGS]: FCOTag[];
  [FCOFields.FCO_TYPE]: FCOType;
  [FCOFields.CREATED_DATE]: Date | undefined;
  [FCOFields.OWNER]: string | undefined;
  [FCOFields.LAST_MODIFIED_BY]: string | undefined;
  [FCOFields.LAST_MODIFIED_DATE]: Date | undefined;
  [FCOFields.WORKSPACE]: string | undefined;
  [FCOFields.SOURCE_FILE_NAME]: string | undefined;
  [FCOFields.PREVENT_DESTROY]: boolean | undefined;
}

export enum DataSourceFCOFields {
  NAME = 'name',
  SOURCE_TYPE = 'dataSourceType',
  BATCH_SOURCE = 'underlyingBatchSourceDataType',
  STREAM_SOURCE = 'underlyingStreamDataSourceType',
  DEPENDENT_TRANSFORMATIONS = 'dependentTransformations',
  DEPENDENT_FEATURE_VIEWS = 'dependentFeatureViews',
  DEPENDENT_FEATURE_SERVICES = 'dependentFeatureServices',
  BATCH_DATA_DELAY = 'batchDataDelay',
}

export interface DataSourceFCOColumn {
  name: string | undefined;
  type: string | undefined;
}

// TODO: We should use src/types/tecton_proto/common/data_source_type.ts
export enum DataSourceFCOType {
  STREAM = 'Stream',
  PUSH = 'Push',
  BATCH = 'Batch',
  UNKNOWN = 'Unknown',
}

export enum DataSourceFileFormat {
  JSON = 'JSON',
  PARQUET = 'Parquet',
  CSV = 'csv',
}

export enum BatchDataSourceType {
  S3 = 's3',
  SNOWFLAKE = 'snowflake',
  REDSHIFT = 'redshift',
  FILE = 'file',
  HIVE = 'hive',
  DATA_SOURCE_FUNCTION = 'data_source_function',
  UNITY = 'unity',
  UNKNOWN = 'unknown',
  PANDAS = 'pandas',
}

export enum StreamingDataSourceType {
  KINESIS = 'kinesis',
  KAFKA = 'kafka',
  DATA_SOURCE_FUNCTION = 'data source function',
  S3 = 's3',
  PUSH = 'push',
  UNKNOWN = 'unknown',
}

export enum OnlineStoreType {
  UNKNOWN = 'unknown',
  BIG_TABLE = 'BigTable',
  DYNAMO = 'DynamoDB',
  REDIS = 'Redis',
}

export enum OfflineStoreType {
  DELTA = 'Delta',
  PARQUET = 'Parquet',
  SUBDIRECTORY = 'Subdirectory',
  UNKNOWN = 'unknown',
}

// Common field between inference and embedding models
export interface Model {
  outputColumn: string;
  modelName: string;
}

export interface DataSourceFCO extends FCO {
  [DataSourceFCOFields.SOURCE_TYPE]: DataSourceFCOType;
  batchProperties: FCOKeyValuePair[] | undefined;
  batchFullyQualifiedName: string;
  streamProperties: FCOKeyValuePair[] | undefined;
  rawBatchTranslator: UserDefinedFunction | undefined; // TODO: Figure out which of these are actually optional
  rawStreamTranslator: UserDefinedFunction | undefined;
  batchDataSourceFunction: SparkBatchDataSourceFunction | undefined; // TODO: Find out this type
  streamFullyQualifiedName: string;
  columns: DataSourceFCOColumn[];
  tectonSchemeColumns: DataSourceFCOColumn[];
  batchDataSource: BatchDataSource | undefined;
  fileFormat: DataSourceFileFormat | undefined;
  streamDataSource: StreamDataSource | undefined;
  [DataSourceFCOFields.BATCH_DATA_DELAY]: Duration;
  [DataSourceFCOFields.BATCH_SOURCE]: BatchDataSourceType | undefined;
  [DataSourceFCOFields.STREAM_SOURCE]: StreamingDataSourceType | undefined;
  [DataSourceFCOFields.DEPENDENT_TRANSFORMATIONS]: string[];
  [DataSourceFCOFields.DEPENDENT_FEATURE_VIEWS]: string[];
  [DataSourceFCOFields.DEPENDENT_FEATURE_SERVICES]: string[];
}

export interface EntityFCO extends FCO {
  [EntityFCOFields.JOIN_KEYS]: string[];
  [EntityFCOFields.DEPENDENT_FEATURE_SERVICES]: string[];
  [EntityFCOFields.DEPENDENT_FEATURE_VIEWS]: string[];
}

export interface FeatureSetItemsFCO {
  featureViewId: string;
  joinConfigurationItems: any[];
  namespace: string;
  featureColumns: any[];
}

export enum FeatureServiceFCOFields {
  ALL_FEATURE_VIEWS = 'allFeatureViews',
  SHOULD_HIDE_MATERIALIZATION_STATUS = 'shouldHideMaterializationStatus',
  IS_ENABLED = 'isEnabled',
  IS_ONLINE_SERVING_ENABLED = 'isOnlineServingEnabled',
  FEATURE_PARAMETERS = 'featureParameters',
  FEATURE_SET = 'featureSet',
  DEPENDENT_DATA_SOURCES = 'dependentDataSources',
  DEPENDENT_FEATURE_VIEWS = 'dependentFeatureViews',
  FEATURE_SET_ITEMS = 'featureSetItem',
  REALTIME_ENVIRONMENT = 'realtimeEnvironment',
  IS_CACHING_ENABLED = 'isCachingEnabled',
}

export interface FeatureServiceFCO extends FCO {
  [FeatureServiceFCOFields.ALL_FEATURE_VIEWS]: string[];
  [FeatureServiceFCOFields.SHOULD_HIDE_MATERIALIZATION_STATUS]: boolean;
  [FeatureServiceFCOFields.IS_ENABLED]: boolean;
  [FeatureServiceFCOFields.IS_ONLINE_SERVING_ENABLED]: boolean;
  [FeatureServiceFCOFields.IS_CACHING_ENABLED]: boolean;
  [FeatureServiceFCOFields.FEATURE_PARAMETERS]: any[];
  [FeatureServiceFCOFields.FEATURE_SET]: any;
  [FeatureServiceFCOFields.DEPENDENT_DATA_SOURCES]: any[];
  [FeatureServiceFCOFields.DEPENDENT_FEATURE_VIEWS]: string[];
  [FeatureServiceFCOFields.FEATURE_SET_ITEMS]: FeatureSetItem[];
  [FeatureServiceFCOFields.REALTIME_ENVIRONMENT]: string | undefined;
}

export enum FeatureViewFCOType {
  STREAM = 'stream',
  BATCH = 'batch',
  STREAM_WINDOW_AGGREGATE = 'stream window aggregate',
  BATCH_WINDOW_AGGREGATE = 'batch window aggregate',
  REALTIME = 'realtime',
  FEATURE_TABLE = 'feature table',
  UNKNOWN = 'unknown',
  PUSH_WITH_BATCH = 'Stream Feature View with Push Source',
  PUSH_NO_BATCH = 'Push No Batch',
}

export interface FeatureForFCO {
  name: string;
  type: string | null;
  aggregateAttributes?: { key?: string; value?: string }[];
  modelName?: string;
}

export interface TemporalAggregateFeatureForFCO {
  name: string;
  aggregation: string;
  aggregationWindow: string;
}

export enum EntityFCOFields {
  JOIN_KEYS = 'joinKeys',
  DEPENDENT_FEATURE_VIEWS = 'dependentFeatureViews',
  DEPENDENT_FEATURE_SERVICES = 'dependentFeatureServices',
}

export enum FeatureViewFCOFields {
  ARCHIVED = 'archived',
  ENTITY_NAMES = 'entityNames',
  ENTITY_IDS = 'entityIds',
  JOIN_KEYS = 'joinKeys',
  TIME_KEY = 'timeKey',
  FEATURE_VIEW_TYPE = 'featureViewType',
  IS_STREAM_INGEST = 'isStreamIngest',
  IS_TEMPORAL = 'isTemporal',
  IS_EMBEDDING = 'isEmbedding',
  FRAMEWORK_VERSION = 'frameworkVersion',
  FW_VERSION = 'fwVersion',
  HAS_INCREMENTAL_BACKFILL = 'hasIncrementalBackfill',
  IS_STREAM = 'isStream',
  IS_PUSH = 'isPush',
  IS_CONTINUOUS = 'isContinuous',
  IS_TEMPORAL_AGGREGATE = 'isTemporalAggregate',
  EMBEDDINGS = 'embeddings',
  HAS_INFERENCES = 'hasInferences',
  INFERENCES = 'inferences',
  MODEL_MAP = 'modelMap', // both custom model (inference) and embedding model(embeddings)
  IS_REALTIME = 'isRealtime',
  IS_FEATURE_TABLE = 'isFeatureTable',
  MATERIALIZATION_PARAMS = 'materializationParams',
  BATCH_CLUSTER_CONFIG = 'batchClusterConfiguration',
  CAN_SHOW_SPARK_JSON = 'canShowSparkJson',
  STREAM_CLUSTER_CONFIG = 'streamClusterConfiguration',
  CAN_SHOW_STREAM_SPARK_JSON = 'canShowStreamSparkJson',
  MATERIALIZATION_INTERVAL_IN_SECONDS = 'materializationIntervalInSeconds',
  ONLINE_MATERIALIZATION_INTERVAL_IN_SECONDS = 'onlineMaterializationIntervalInSeconds',
  SERVING_TTL = 'servingTtl',
  MATERIALIZATION_MAX_SOURCE_DATA_DELAY_SECONDS = 'materializationMaxSourceDataDelaySeconds',
  MATERIALIZATION_START_TIMESTAMP = 'materializationStartTimestamp',
  ALL_TRANSFORMATIONS = 'allTransformations',
  ALL_ENTITIES = 'allEntities',
  FEATURES = 'features',
  MAX_AGGREGATION_INTERVAL_IN_SECONDS = 'maxAggregationIntervalInSeconds',
  FEATURE_MAP = 'featureMap',
  IS_MATERIALIZATION_ENABLED = 'isMaterializationEnabled',
  EXPECTED_FRESHNESS = 'expectedFreshness',
  IS_MONITORING_ENABLED = 'isMonitoringEnabled',
  ALERT_EMAIL = 'alertEmail',
  TEMPORAL_AGGREGATE_FEATURES = 'temporalAggregateFeatures',
  TEMPORAL_AGGREGATE_SLIDE_INTERVAL = 'temporalAggregateSlideInterval',
  TEMPORAL_AGGREGATE_SECONDARY_KEY = 'temporalAggregateSecondaryKey',
  IS_ONLINE_MATERIALIZATION_ENABLED = 'isOnlineMaterializationEnabled',
  IS_OFFLINE_MATERIALIZATION_ENABLED = 'isOfflineMaterializationEnabled',
  DATA_SOURCE_IDS = 'dataSourceIds',
  FEATURE_SERVICE_NAMESPACE = 'featureServiceNamespace',
  VIEW_SQL = 'viewSql',
  HAS_DEPENDENT_FEATURE_VIEWS = 'hasDependentFvs',
  DEPENDENT_FEATURE_VIEWS = 'dependentFeatureViews',
  BATCH_TRIGGER = 'batchTrigger',
  FEATURE_SERVICES = 'featureServices',
  ONLINE_SERVING_INDEX = 'onlineServingIndex',
  PIPELINE = 'pipeline',
  PIPELINE_TRANSFORMATION_DATA_SOURCE_INPUTS = 'pipelineTransformationDataSourceInputs',
  IS_CACHING_ENABLED = 'isCachingEnabled',
  CACHE_MAX_AGE_SECONDS = 'cacheMaxAgeSeconds',
  ON_DEMAND_REQUEST_DATA_SOURCE_KEYS = 'onDemandRequestDataSourceKeys',
  IS_DATA_QUALITY_ENABLED = 'isDataQualityEnabled',
  SKIP_DEFAULT_EXPECTATIONS = 'skipDefaultExpectations',
  MANUAL_TRIGGER_BACKFILL_END_TIMESTAMP = 'manualTriggerBackfillEndTimestamp',
  MAX_BACKFILL_INTERVAL = 'maxBackfillInterval',
  MATERIALIZATION_RUNTIME = 'materializationRuntime',
  ONLINE_BACKFILL_LOAD_TYPE = 'OnlineBackfillLoadType',
  JOB_ENVIRONMENT = 'jobEnvironment',
  BATCH_COMPACTION_ENABLED = 'batchCompactionEnabled',
  STREAM_TILING_ENABLED = 'streamTilingEnabledEnabled',
  REALTIME_ENVIRONMENTS = 'realtimeEnvironments',
  ONLINE_STORE_TYPE = 'onlineStoreType',
  OFFLINE_STORE_TYPE = 'offlineStoreType',
  PRIMARY_ENDPOINT = 'primaryEndpoint',
  PUBLISH_FULL_FEATURES = 'publishFullFeatures',
  PUBLISH_START_TIME = 'publishStartTime',
  BATCH_COMPUTE_MODE = 'BATCH_COMPUTE_MODE',
}

export interface FeatureViewFCO extends FCO {
  [FeatureViewFCOFields.ARCHIVED]: boolean;
  [FeatureViewFCOFields.ENTITY_NAMES]: string[];
  [FeatureViewFCOFields.ENTITY_IDS]: string[];
  [FeatureViewFCOFields.JOIN_KEYS]: any[]; // TODO: Figure out what these are in practice
  [FeatureViewFCOFields.TIME_KEY]: string | undefined;
  [FeatureViewFCOFields.FEATURE_VIEW_TYPE]: FeatureViewFCOType;
  [FeatureViewFCOFields.IS_TEMPORAL]: boolean;
  [FeatureViewFCOFields.IS_EMBEDDING]: boolean;
  [FeatureViewFCOFields.EMBEDDINGS]: Embedding[] | undefined;
  [FeatureViewFCOFields.HAS_INFERENCES]: boolean;
  [FeatureViewFCOFields.INFERENCES]: Inference[] | undefined;
  [FeatureViewFCOFields.MODEL_MAP]: Record<string, Model> | undefined;
  [FeatureViewFCOFields.FRAMEWORK_VERSION]: number | undefined;
  [FeatureViewFCOFields.FW_VERSION]: FrameworkVersion | undefined;
  [FeatureViewFCOFields.HAS_INCREMENTAL_BACKFILL]: boolean;
  [FeatureViewFCOFields.IS_CACHING_ENABLED]: boolean;
  [FeatureViewFCOFields.CACHE_MAX_AGE_SECONDS]: Duration | undefined;
  [FeatureViewFCOFields.IS_STREAM]: boolean;
  [FeatureViewFCOFields.IS_PUSH]: boolean;
  [FeatureViewFCOFields.IS_STREAM_INGEST]: boolean;
  [FeatureViewFCOFields.IS_CONTINUOUS]: boolean;
  [FeatureViewFCOFields.IS_TEMPORAL_AGGREGATE]: boolean;
  [FeatureViewFCOFields.IS_REALTIME]: boolean; // TODO: Why is this here if there's already the type?
  [FeatureViewFCOFields.IS_FEATURE_TABLE]: boolean; // TODO: Why is this here if there's already the type?
  [FeatureViewFCOFields.MATERIALIZATION_PARAMS]: NewMaterializationParams;
  [FeatureViewFCOFields.BATCH_CLUSTER_CONFIG]: ClusterConfigInterface | undefined;
  [FeatureViewFCOFields.CAN_SHOW_SPARK_JSON]: boolean;
  [FeatureViewFCOFields.STREAM_CLUSTER_CONFIG]: ClusterConfigInterface | undefined;
  [FeatureViewFCOFields.CAN_SHOW_STREAM_SPARK_JSON]: boolean;
  [FeatureViewFCOFields.MATERIALIZATION_INTERVAL_IN_SECONDS]: number;
  [FeatureViewFCOFields.ONLINE_MATERIALIZATION_INTERVAL_IN_SECONDS]: number | undefined;
  [FeatureViewFCOFields.SERVING_TTL]: number | undefined;
  [FeatureViewFCOFields.MATERIALIZATION_MAX_SOURCE_DATA_DELAY_SECONDS]: number;
  [FeatureViewFCOFields.MATERIALIZATION_START_TIMESTAMP]: string | undefined;
  [FeatureViewFCOFields.ALL_TRANSFORMATIONS]: string[];
  [FeatureViewFCOFields.ALL_ENTITIES]: string[];
  [FeatureViewFCOFields.FEATURES]: FeatureForFCO[];
  [FeatureViewFCOFields.MAX_AGGREGATION_INTERVAL_IN_SECONDS]: number | undefined;
  [FeatureViewFCOFields.FEATURE_MAP]: _.Dictionary<FeatureForFCO>;
  [FeatureViewFCOFields.IS_MATERIALIZATION_ENABLED]: boolean;
  [FeatureViewFCOFields.EXPECTED_FRESHNESS]: number | undefined;
  [FeatureViewFCOFields.IS_MONITORING_ENABLED]: boolean;
  [FeatureViewFCOFields.ALERT_EMAIL]: string | undefined;
  [FeatureViewFCOFields.TEMPORAL_AGGREGATE_FEATURES]: TemporalAggregateFeatureForFCO[];
  [FeatureViewFCOFields.TEMPORAL_AGGREGATE_SLIDE_INTERVAL]: string | undefined;
  [FeatureViewFCOFields.TEMPORAL_AGGREGATE_SECONDARY_KEY]: string | undefined;
  [FeatureViewFCOFields.IS_ONLINE_MATERIALIZATION_ENABLED]: boolean | undefined;
  [FeatureViewFCOFields.IS_OFFLINE_MATERIALIZATION_ENABLED]: boolean | undefined;
  [FeatureViewFCOFields.DATA_SOURCE_IDS]: string[];
  [FeatureViewFCOFields.FEATURE_SERVICE_NAMESPACE]: string | undefined;
  [FeatureViewFCOFields.VIEW_SQL]: string;
  [FeatureViewFCOFields.HAS_DEPENDENT_FEATURE_VIEWS]: boolean;
  [FeatureViewFCOFields.DEPENDENT_FEATURE_VIEWS]: InputNode[]; // TODO: This should be IDs? I.e., string[]
  [FeatureViewFCOFields.BATCH_TRIGGER]: string | undefined;
  [FeatureViewFCOFields.FEATURE_SERVICES]: string[];
  [FeatureViewFCOFields.ONLINE_SERVING_INDEX]: OnlineServingIndex | undefined;
  [FeatureViewFCOFields.PIPELINE]: Pipeline | undefined;
  [FeatureViewFCOFields.PIPELINE_TRANSFORMATION_DATA_SOURCE_INPUTS]: DataSourceNode[];
  [FeatureViewFCOFields.ON_DEMAND_REQUEST_DATA_SOURCE_KEYS]: string[];
  [FeatureViewFCOFields.IS_DATA_QUALITY_ENABLED]: boolean | undefined;
  [FeatureViewFCOFields.SKIP_DEFAULT_EXPECTATIONS]: boolean | undefined;
  [FeatureViewFCOFields.MANUAL_TRIGGER_BACKFILL_END_TIMESTAMP]: string | undefined;
  [FeatureViewFCOFields.MAX_BACKFILL_INTERVAL]: string | undefined;
  [FeatureViewFCOFields.MATERIALIZATION_RUNTIME]: string | undefined;
  [FeatureViewFCOFields.ONLINE_BACKFILL_LOAD_TYPE]: string | undefined;
  [FeatureViewFCOFields.JOB_ENVIRONMENT]: string | undefined;
  [FeatureViewFCOFields.BATCH_COMPACTION_ENABLED]: boolean;
  [FeatureViewFCOFields.STREAM_TILING_ENABLED]: boolean;
  [FeatureViewFCOFields.REALTIME_ENVIRONMENTS]: string[];
  [FeatureViewFCOFields.ONLINE_STORE_TYPE]: OnlineStoreType | undefined;
  [FeatureViewFCOFields.OFFLINE_STORE_TYPE]: OfflineStoreType | undefined;
  [FeatureViewFCOFields.PRIMARY_ENDPOINT]: string | undefined;
  [FeatureViewFCOFields.PUBLISH_FULL_FEATURES]: boolean | undefined;
  [FeatureViewFCOFields.PUBLISH_START_TIME]: Date | undefined;
  [FeatureViewFCOFields.BATCH_COMPUTE_MODE]: BatchComputeMode | undefined;
}

export const TransformationModeType = {
  TRANSFORMATION_MODE_PYTHON: 'Python',
  TRANSFORMATION_MODE_PANDAS: 'Pandas',
  TRANSFORMATION_MODE_PYSPARK: 'PySpark',
  TRANSFORMATION_MODE_SPARK_SQL: 'Spark SQL',
  TRANSFORMATION_MODE_SNOWFLAKE_SQL: 'Snowflake SQL',
  TRANSFORMATION_MODE_SNOWPARK: 'Snowpark',
  TRANSFORMATION_MODE_ATHENA: 'Athena',
  TRANSFORMATION_MODE_UNSPECIFIED: 'Unknown',
};

// TODO: We should be using TransformationMode generated code from transformation.ts
export enum TransformationFCOMode {
  TRANSFORMATION_MODE_UNSPECIFIED = 'Unknown',
  TRANSFORMATION_MODE_PYSPARK = 'PySpark',
  TRANSFORMATION_MODE_SPARK_SQL = 'Spark SQL',
  TRANSFORMATION_MODE_PANDAS = 'Pandas',
  TRANSFORMATION_MODE_SNOWFLAKE_SQL = 'Snowflake SQL',
  TRANSFORMATION_MODE_PYTHON = 'Python',
  TRANSFORMATION_MODE_SNOWPARK = 'Snowpark',
  TRANSFORMATION_MODE_BIGQUERY_SQL = 'Bigquery',
  TRANSFORMATION_MODE_NO_TRANSFORMATION = 'No Transformation',
}

export enum TransformationFCOFields {
  MODE = 'mode',
  CODE = 'code',
  DEPENDENT_FEATURE_VIEWS = 'dependentFeatureViews',
  DEPENDENT_FEATURE_SERVICES = 'dependentFeatureServices',
}

export interface TransformationFCO extends FCO {
  [TransformationFCOFields.MODE]: TransformationFCOMode | undefined; // TODO: This should be an enum
  [TransformationFCOFields.CODE]: string | undefined;
  [TransformationFCOFields.DEPENDENT_FEATURE_VIEWS]: string[];
  [TransformationFCOFields.DEPENDENT_FEATURE_SERVICES]: string[];
}

export interface FeatureViewInputDataSource extends DataSourceNode {
  id?: string;
  startTimeOffsetDisplay?: string;
}

export enum DatasetFCOFields {
  FEATURE_VIEW_ID = 'featureViewId',
  FEATURE_SERVICE_ID = 'featureServiceId',
  FEATURE_VIEW_NAME = 'featureViewName',
  FEATURE_SERVICE_NAME = 'featureServiceName',
  TYPE = 'type', // Logged or saved
  SCHEMA = 'schema',
  STATE_UPDATE_ENTRY_COMMIT_ID = 'stateUpdateEntryCommitId',
}

export enum DatasetFCOType {
  LOGGED = 'Logged Requests',
  SAVED = 'Saved Dataset',
  UNKNOWN = 'unknown',
}

export interface DatasetFCOSchemaItem {
  name: string;
  type: string;
}

export interface DatasetFCO extends FCO {
  [DatasetFCOFields.FEATURE_VIEW_ID]: string | undefined;
  [DatasetFCOFields.FEATURE_SERVICE_ID]: string | undefined;
  [DatasetFCOFields.FEATURE_VIEW_NAME]: string | undefined;
  [DatasetFCOFields.FEATURE_SERVICE_NAME]: string | undefined;
  [DatasetFCOFields.TYPE]: DatasetFCOType;
  [DatasetFCOFields.SCHEMA]: DatasetFCOSchemaItem[];
  [DatasetFCOFields.STATE_UPDATE_ENTRY_COMMIT_ID]: string | undefined;
}

// Creating this for backwards compatibility
export interface FeatureViewServingStatusSummaryWithId extends FeatureViewServingStatusSummary {
  id?: string;
}
