import React, { useEffect, useState } from 'react';
import { Button, ButtonEmpty, FlexGroup, FormRow, TextArea } from '@tecton';
import { MockMutationInterface } from '../shared/utils/useMockMutation';

const useDescriptionField = (
  initialDescription: string,
  { trigger, result }: MockMutationInterface<string, string>
) => {
  // Mutation State
  const { data, error, isLoading, reset } = result;

  // Form value state
  const [description, setDescription] = useState<string>(initialDescription);

  if (description === undefined) {
    setDescription(initialDescription);
  }

  // Derived State
  const isInvalid = !!error;
  const isDisabled = isLoading || isInvalid;
  const errorMessages: string[] = [];
  // Server-side Errors
  if (error) {
    errorMessages.push(error);
  }

  const [isEditing, setIsEditing] = useState<boolean | undefined>(false);

  useEffect(() => {
    // Upon success, switch back to display mode
    if (data && !error && isEditing === undefined) {
      setIsEditing(false);
      reset();
    }
  }, [data, setIsEditing, error, reset]);

  return {
    trigger,
    data,
    error,
    errorMessages,
    isLoading,
    isInvalid,
    isDisabled,
    isEditing,
    description,
    setDescription,
    setIsEditing,
    reset,
  };
};

const FieldForm = ({
  description,
  setDescription,
  isLoading,
  isInvalid,
  isDisabled,
  errorMessages,
  cancel,
  saveDescription,
}: {
  description: string;
  setDescription: (description: string) => void;
  isLoading: boolean;
  isInvalid: boolean;
  isDisabled: boolean;
  errorMessages: string[];
  cancel: () => void;
  saveDescription: (description: string) => void;
}) => {
  return (
    <>
      <FormRow isInvalid={isInvalid} error={errorMessages}>
        <TextArea
          isInvalid={isInvalid}
          placeholder="Placeholder text"
          aria-label="Use aria labels when no actual label is in use"
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Escape') {
              cancel();
            }
          }}
        />
      </FormRow>
      <FlexGroup justifyContent="flexEnd">
        <ButtonEmpty size="s" onClick={cancel}>
          Cancel
        </ButtonEmpty>
        <Button
          fill
          size="s"
          isDisabled={isDisabled}
          isLoading={isLoading}
          onClick={() => {
            // setIsEditing(false);
            saveDescription(description);
          }}
        >
          Save
        </Button>
      </FlexGroup>
    </>
  );
};

export default FieldForm;
export { useDescriptionField };
