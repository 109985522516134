import { rest } from 'msw';

const mswGetGlobalsForWebUI = rest.post('/v1/metadata-service/get-globals-for-web-ui', (req, res, ctx) => {
  /*
    // Simulate no access from backend.
    return res(
    ctx.status(403),
    ctx.json({ key_values: { CLUSTER_REGION: 'us-west-2', EXECUTION_ENVIRONMENT: 'DATABRICKS' } })
  );
  */
  return res(
    ctx.status(200),
    ctx.json({
      key_values: {
        CLUSTER_REGION: 'us-west-2',
        EXECUTION_ENVIRONMENT: 'DATABRICKS',
      },
    })
  );
});

export default mswGetGlobalsForWebUI;
