import { createContext, ReactNode, useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import {
  useAssignRolesMutation,
  useRemovePrincipalGroupMembersMutation,
  useAddPrincipalGroupMembersMutation,
  useDeleteUserMutation,
  useClusterUserActionMutation,
  useMutateServiceAccount,
  useUpdateAccountTypeMutation,
  useAssignRoleMutation,
  useDeleteServiceAccount,
} from '../../api/acl';

import { ACLPrincipalModal, getAssignedWorkspaceCount, getLegacyRoleIdGQL } from './aclUtils';

import { CalloutMessageType } from '@shared';
import { addToast, ToastContext } from '../@tecton/ToastContext';

import { AccountType, PrincipalProfileContextProp } from './types';
import { EuiComboBoxOptionOption } from '@tecton';
import { PrincipalType } from '../../types/tecton_proto/auth/principal';
import { ResourceType } from '../../types/tecton_proto/auth/resource';
import { logEvent } from '../../utils/analytics-utils';
import { Routes as AppRoutes } from '../../core/routes';

import { useGetACLServiceAccountDetails } from '../../api/queries/aclGraphql';
import { SelectableGroupOptionGQL } from './modals/UserAddToGroupModalGQL';
import { ServiceAccount } from '../../api/gql/graphql';
import { useUserSettings } from '../context/UserSettingsContext';
import { UpdateServiceAccountResponse } from '../../types/tecton_proto/metadataservice/metadata_service';
import ACLLoadingContent from './ACLLoadingContent';
import { getAllRolesRecordsGQL } from './util';

export interface ACLServiceAccountProfileContextProps extends PrincipalProfileContextProp {
  serviceAccount?: ServiceAccount;
  isOwner?: boolean;
  updateName?: (name: string, onSuccess: () => void, onErrorCallback: (error: string) => void) => void;
  updateDescription?: (description: string, onSuccess: () => void, onErrorCallback: (error: string) => void) => void;
  deleteServiceAccount?: () => void;
  deprovision?: () => void;
  reactivate?: () => void;
}

export const ACLServiceAccountProfileContextGQL = createContext<ACLServiceAccountProfileContextProps>({
  assignedGroupCount: 0,
});

export const ACLServiceAccountProfileContextGQLProvider = ({ children }: { children: ReactNode }) => {
  const { serviceAccount: serviceAccountParam } = useParams();
  const { user, isAdmin } = useUserSettings();
  const navigate = useNavigate();
  const toastContext = useContext(ToastContext);

  // Mutations
  const updateWorkspaceToUserMutation = useAssignRolesMutation();
  const deleteUserMutation = useDeleteUserMutation();
  const clusterUserActionMutation = useClusterUserActionMutation();
  const addPrincipalGroupMembersMutation = useAddPrincipalGroupMembersMutation();
  const mutateServiceAccount = useMutateServiceAccount();
  const updateAccountTypeToRegularMutation = useUpdateAccountTypeMutation();
  const assignRoleMutation = useAssignRoleMutation();
  const deleteServiceAccountMutation = useDeleteServiceAccount();

  const { data, isLoading, isRefetching, error } = useGetACLServiceAccountDetails({
    principalId: serviceAccountParam ?? '',
    oktaId: user?.sub ?? '',
    isAdmin: isAdmin ?? false,
  });

  const isOwner = data?.GetAuthorizedResources?.authorizedResources.includes(serviceAccountParam ?? '');

  const [serviceAccountWorkspaceAssignRoleSearchFilter, setServiceAccountWorkspaceAssignRoleSearchFilter] = useState<
    string | undefined
  >();

  const [modal, setModal] = useState<ACLPrincipalModal | undefined>();
  const [errorMessages, setErrorMessages] = useState<CalloutMessageType[] | undefined>();
  const [modalError, setModalError] = useState<CalloutMessageType[] | undefined>();
  const [selectedRole, setSelectedRole] = useState<string | undefined>();
  const [selectedWorkspace, setSelectedWorkspace] = useState<EuiComboBoxOptionOption<string>[]>();
  const [selectedAssignableGroups, setSelectedAssignableGroups] = useState<SelectableGroupOptionGQL[] | undefined>(
    undefined
  );
  const [groupFilter, setGroupFilter] = useState<string | undefined>(undefined);
  const removePrincipalGroupMembersMutation = useRemovePrincipalGroupMembersMutation();

  const serviceAccount = data?.GetServiceAccountDetails?.serviceAccountDetails?.serviceAccount;

  const allRoles = getAllRolesRecordsGQL(data?.GetRoles?.roles ?? []) ?? [];

  const onError = (error: any) => {
    const errorMessage = error?.response?.data?.message ?? error?.response?.statusText;

    setErrorMessages([
      {
        title: String(errorMessage),
        color: 'danger',
        iconType: 'alert',
      },
    ]);
  };

  const closeModal = () => {
    setModal(undefined);
    setSelectedAssignableGroups(undefined);
    setErrorMessages(undefined);
  };

  const confirmAddGroup = () => {
    const selectedGroup = selectedAssignableGroups?.filter((item: SelectableGroupOptionGQL) => {
      return item.checked === 'on';
    })[0];

    const groupID = `${selectedGroup?.group.id}`;

    const userMembersToAdd = [
      { principal_type: PrincipalType.PRINCIPAL_TYPE_SERVICE_ACCOUNT, principal_id: `${serviceAccount?.id}` },
    ];

    const payload = {
      principal_group_id: `${groupID}`,
      members: userMembersToAdd,
    };

    addPrincipalGroupMembersMutation.mutate(payload, {
      onSuccess: () => {
        const text = `${selectedGroup?.label}`;

        const serviceAccountText = `${serviceAccount?.name}`;
        toastContext?.dispatchToast(
          addToast({
            title: `${serviceAccountText} added to group`,
            text,
            iconType: 'lock',
            color: 'success',
          })
        );

        logEvent(`${serviceAccountText} added to group`, '', {
          groupID,
          text,
        });
        closeModal();
      },
      onError,
    });
  };

  const confirmRemoveGroup = (groupId: string, groupName: string) => {
    const payload = {
      id: `${groupId}`,
      members: [
        { principal_type: PrincipalType.PRINCIPAL_TYPE_SERVICE_ACCOUNT, principal_id: `${serviceAccount?.id}` },
      ],
    };
    removePrincipalGroupMembersMutation.mutate(payload, {
      onSuccess: () => {
        closeModal();
        const text = `Service Account ${serviceAccount?.name} has been removed from ${groupName}`;
        toastContext?.dispatchToast(
          addToast({
            title: 'Service Account removed from group',
            text,
            iconType: 'lock',
            color: 'success',
          })
        );

        logEvent('Service Account removed from group', '', {
          principal_id: `${serviceAccount?.id}`,
          text,
        });
      },

      onError,
    });
  };

  const updateModal = (userModal: ACLPrincipalModal) => {
    setModal(userModal);
  };

  const updateSelectedWorkspace = (ws: EuiComboBoxOptionOption<string>[]) => {
    setSelectedWorkspace(ws);
  };

  const updateSelectedRole = (role?: string) => {
    setSelectedRole(role);
  };

  const clearSelectedWorkspaces = () => {
    setSelectedWorkspace([]);
  };

  const updateSelectedAssignableGroups = (options: SelectableGroupOptionGQL[]) => {
    setSelectedAssignableGroups(options);
  };

  const updateGroupFilter = (searchText: string) => {
    setGroupFilter(searchText);
  };

  const onSuccessServiceAccountUpdate = (
    data: UpdateServiceAccountResponse,
    onSuccess: () => void,
    title: string,
    text: string
  ) => {
    setModal(undefined);
    setModalError(undefined);

    // Show the user a Toast for confirmation
    toastContext?.dispatchToast(
      addToast({
        title,
        text,
        iconType: 'lock',
        color: 'success',
      })
    );

    logEvent('Account & Access: Reactivate', '', {
      serviceAccountName: serviceAccount?.name,
      serviceAccountId: serviceAccount?.id,
    });

    onSuccess();
  };

  const updateName = (name: string, onSuccess: () => void, onErrorCallback: (error: string) => void) => {
    if (serviceAccount !== undefined) {
      const { id, isActive, description } = serviceAccount;
      const deprovisionServiceAccount = {
        id: id ?? '',
        isActive: isActive ?? false,
        description: description ?? '',
        name: name ?? '',
      };

      mutateServiceAccount.mutate(deprovisionServiceAccount, {
        onSuccess: (data: UpdateServiceAccountResponse) => {
          onSuccessServiceAccountUpdate(data, onSuccess, 'Service Account Name Updated to', `${name}`);
          logEvent('Account & Access: Update Name', '', {
            serviceAccountName: serviceAccount?.name,
            serviceAccountId: serviceAccount?.id,
            name,
          });
        },
        onError: (error: any) => {
          onError(error);
          onErrorCallback(error?.response?.data?.message);
        },
      });
    }
  };

  const updateDescription = (description: string, onSuccess: () => void, onErrorCallback: (error: string) => void) => {
    if (serviceAccount !== undefined) {
      const { id, isActive, name } = serviceAccount;
      const deprovisionServiceAccount = {
        id: id ?? '',
        isActive: isActive ?? false,
        description: description ?? '',
        name: name ?? '',
      };
      mutateServiceAccount.mutate(deprovisionServiceAccount, {
        onSuccess: (data: UpdateServiceAccountResponse) => {
          onSuccessServiceAccountUpdate(data, onSuccess, 'Service Account Description Updated to', `${description}`);
          logEvent('Account & Access: Update Description', '', {
            serviceAccountName: serviceAccount?.name,
            serviceAccountId: serviceAccount?.id,
            description,
          });
        },
        onError: (error: any) => {
          onError(error);
          onErrorCallback(error?.response?.data?.message);
        },
      });
    }
  };

  const updateAccountType = (selectedAccountType?: AccountType) => {
    const principal_id = serviceAccountParam;

    const payload = {
      assignments: [
        {
          principal_id,
          role: 'admin_role',
          principal_type: PrincipalType.PRINCIPAL_TYPE_SERVICE_ACCOUNT,
          resource_type: ResourceType.RESOURCE_TYPE_ORGANIZATION,
        },
      ],
    };

    const toastAndLog = (text?: AccountType) => {
      // Send toaster to the user
      toastContext?.dispatchToast(
        addToast({
          title: 'Account Type updated to',
          text,
          iconType: 'lock',
          color: 'success',
        })
      );

      closeModal();

      // hide the modal
      setModal(undefined);
      setModalError(undefined);

      logEvent('Account & Access: Update Service Account Type', '', {
        principal_id,
        role: text,
      });
    };

    if (selectedAccountType === 'Admin') {
      assignRoleMutation.mutate(payload, {
        onSuccess: () => {
          toastAndLog(selectedAccountType);
        },
        onError,
      });
    } else {
      // Delete role
      updateAccountTypeToRegularMutation.mutate(payload, {
        onSuccess: () => {
          toastAndLog(selectedAccountType);
        },
        onError,
      });
    }
  };

  const deleteServiceAccount = () => {
    if (serviceAccount !== undefined) {
      deleteServiceAccountMutation.mutate(
        { id: serviceAccount.id ?? '' },
        {
          onSuccess: () => {
            setModal(undefined);
            setModalError(undefined);
            navigate(`${AppRoutes.accountsAndAccessServiceAccounts}`);
            toastContext?.dispatchToast(
              addToast({
                title: 'Service Account Deleted',
                text: `The service account '${serviceAccount?.name}' has been deleted.`,
                iconType: 'lock',
                color: 'success',
              })
            );
            logEvent('Account & Access: Delete Service Account', '', {
              serviceAccountName: serviceAccount?.name,
              serviceAccountId: serviceAccount?.id,
            });
          },
          onError,
        }
      );
    }
  };

  // Deactivate a service account
  const deprovision = () => {
    if (serviceAccount !== undefined) {
      //deprovisionConfirmationModalState.setIsLoading(true);
      const { id, name, description } = serviceAccount;

      const deprovisionServiceAccount = {
        id: id ?? '',
        name: name ?? '',
        description: description ?? '',
        is_active: false,
      };

      mutateServiceAccount.mutate(deprovisionServiceAccount, {
        onSuccess: () => {
          closeModal();
          if (toastContext) {
            toastContext?.dispatchToast(
              addToast({
                title: 'Service Account Deactivated',
                text: `The service account ${serviceAccount?.name} has been deactivated.`,
                iconType: 'lock',
                color: 'success',
              })
            );
          }

          logEvent('Account & Access: Deprovision', '', {
            serviceAccountName: serviceAccount?.name,
            serviceAccountId: serviceAccount?.id,
          });
        },
        onError,
      });
    }
  };

  const reactivate = () => {
    if (serviceAccount !== undefined) {
      const { id, name, description } = serviceAccount;
      const deprovisionServiceAccount = {
        id: id ?? '',
        name: name ?? '',
        description: description ?? '',
        is_active: true,
      };

      mutateServiceAccount.mutate(deprovisionServiceAccount, {
        onSuccess: () => {
          // Update Service Account so UI get the latest state
          closeModal();

          // Show the Service Account a Toast for confirmation
          toastContext?.dispatchToast(
            addToast({
              title: 'Service Account Activated',
              text: `The service account '${serviceAccount?.name}' has been activated.`,
              iconType: 'lock',
              color: 'success',
            })
          );

          logEvent('Account & Access: Activate', '', {
            serviceAccountName: serviceAccount?.name,
            serviceAccountId: serviceAccount?.id,
          });
        },
        onError,
      });
    }
  };

  const editAssignedWorkspace = () => {
    /**
     *
     Handles both adding, modifying and removing.
     */
    const principal_id = serviceAccount?.id ?? '';
    const principal_type = PrincipalType.PRINCIPAL_TYPE_SERVICE_ACCOUNT;
    const resource_id = selectedWorkspace?.[0]?.label;
    const isAllWorkspaces = resource_id === 'All Workspaces';

    const resource_type = isAllWorkspaces
      ? ResourceType.RESOURCE_TYPE_ORGANIZATION
      : ResourceType.RESOURCE_TYPE_WORKSPACE;

    // selecting 'none' means we will remove it from the lists
    const removingRole = selectedRole === 'None';
    const role = getLegacyRoleIdGQL(data?.GetRoles?.roles ?? [], selectedRole ?? ''); // fix this.
    const roles = removingRole ? [] : [role ?? ''];

    // make sure account type is stays in place if there were admin before
    if (
      isAllWorkspaces &&
      data?.GetServiceAccountDetails?.serviceAccountDetails?.accountTypeWithInheritance?.effectiveAccountType ===
        'Admin'
    ) {
      roles.unshift('admin_role');
    }

    const title =
      ACLPrincipalModal.ADD_WORKSPACE === modal
        ? `Service Account added to workspace`
        : removingRole
        ? `Workspace removed from Service Account`
        : `Service Account`;

    const text =
      ACLPrincipalModal.ADD_WORKSPACE === modal
        ? `${serviceAccount?.id} has been added to ${resource_id} as ${selectedRole}.`
        : removingRole
        ? `Workspace ${resource_id} has been removed for ${serviceAccount?.id}`
        : `${serviceAccount?.id} has been granted ${selectedRole} role on ${resource_id}.`;

    const payload = {
      principal_id,
      roles,
      ...(!isAllWorkspaces && { resource_id }),
      principal_type,
      resource_type,
    };

    updateWorkspaceToUserMutation.mutate(payload, {
      onSuccess: () => {
        // Clean up default values
        clearSelectedWorkspaces();
        setSelectedRole(undefined);

        setModalError(undefined);
        setModal(undefined);

        // Send toaster to the user
        toastContext?.dispatchToast(
          addToast({
            title,
            text,
            iconType: 'lock',
            color: 'success',
          })
        );

        logEvent(title, '', {
          principal_id,
          roles: roles.join(','),
          resource_id,
          principal_type,
          resource_type,
        });
      },
      onError,
    });
  };

  const workspaceCount = getAssignedWorkspaceCount(
    data?.GetServiceAccountDetails?.serviceAccountDetails?.assignedWorkspacesWithRoles ?? []
  );

  const assignedWorkspacesWithRoles =
    data?.GetServiceAccountDetails?.serviceAccountDetails?.assignedWorkspacesWithRoles?.filter((workspace) => {
      if (!serviceAccountWorkspaceAssignRoleSearchFilter) {
        return true;
      }
      return workspace.workspaceId
        .toLowerCase()
        ?.includes(serviceAccountWorkspaceAssignRoleSearchFilter?.toLowerCase());
    });

  // If something is selected from a candidate, use it. This helps with check marks for the modal options
  const assignableGroups =
    selectedAssignableGroups ??
    data?.GetGroups?.assignableGroups?.map((group) => {
      // small transformation EUI dropdown select.
      return {
        label: group.name,
        group,
      };
    }) ??
    [];

  return (
    <ACLServiceAccountProfileContextGQL.Provider
      value={{
        serviceAccount: data?.GetServiceAccountDetails?.serviceAccountDetails?.serviceAccount,
        isOwner,
        accountType: data?.GetServiceAccountDetails?.serviceAccountDetails?.accountTypeWithInheritance,
        assignedWorkspacesWithRoles,
        assignableWorkspaces: data?.GetServiceAccountDetails?.serviceAccountDetails?.assignableWorkspaces,
        modal,
        modalError,
        errorMessages,
        selectedWorkspace,
        selectedRole,
        allRoles,
        addNewWorkspaceRoleOptionsById:
          data?.GetServiceAccountDetails?.serviceAccountDetails?.addNewWorkspaceRoleOptionsById,
        editWorkspaceRoleOptionsById:
          data?.GetServiceAccountDetails?.serviceAccountDetails?.editWorkspaceRoleOptionsById,
        assignableGroups,
        selectedAssignableGroups,
        assignedGroupCount: data?.GetGroups?.assignedGroups.length ?? 0,
        assignedGroups:
          data?.GetGroups?.assignedGroups?.filter((group) => {
            if (!groupFilter) {
              return true;
            }
            // search filter
            return group?.name.toLowerCase()?.includes(groupFilter.toLowerCase().trim());
          }) ?? [],
        workspaceCount,
        isModalLoading:
          deleteUserMutation.isLoading ||
          clusterUserActionMutation.isLoading ||
          updateWorkspaceToUserMutation.isLoading ||
          removePrincipalGroupMembersMutation.isLoading ||
          addPrincipalGroupMembersMutation?.isLoading ||
          mutateServiceAccount.isLoading ||
          assignRoleMutation.isLoading ||
          updateAccountTypeToRegularMutation.isLoading,
        isLoading,
        error,
        setModalError,
        editAssignedWorkspace,
        clearSelectedWorkspaces,
        setPrincipalWorkspaceAssignRoleSearchFilter: setServiceAccountWorkspaceAssignRoleSearchFilter,
        updateSelectedAssignableGroups,
        updateGroupFilter,
        confirmAddGroup,
        confirmRemoveGroup,
        updateSelectedWorkspace,
        updateSelectedRole,
        closeModal,
        updateModal,
        updateName,
        updateDescription,
        setErrorMessages,
        updateAccountType,
        deleteServiceAccount,
        deprovision,
        reactivate,
      }}
    >
      {/* This is needed so all values are available */}
      {isLoading || isRefetching ? <ACLLoadingContent showPanel /> : children}
    </ACLServiceAccountProfileContextGQL.Provider>
  );
};
