import React from 'react';
import {
  EuiListGroup,
  EuiListGroupItem,
  EuiPopover,
  EuiFlexGroup,
  EuiFlexItem,
  EuiNotificationBadge,
  EuiPopoverFooter,
  EuiButtonEmpty,
  EuiFilterButton,
  EuiIcon,
} from '@elastic/eui';
import { css, keyframes } from '@emotion/css';
import { useTectonTheme } from './TectonThemeProvider';

export type FilterSelector = {
  label: string;
  numFilters: number | undefined;
  hasActiveFilters: boolean;
  numActiveFilters: number | undefined;
  filterElement: React.ReactNode;
  hidden?: boolean;
};

type FilterLabelProps = Pick<FilterSelector, 'label' | 'numFilters' | 'hasActiveFilters' | 'numActiveFilters'>;

const activeFilterButtonCss = css`
  .euiNotificationBadge {
    color: white;
    background: #1f8acb;
  }
`;

interface ComboFilterDropdownProps {
  buttonLabel: string;
  filterTypes: Record<string, FilterSelector>;
  isOpen: boolean | undefined;
  selectedFilter: string | undefined;
  setIsOpen: (isOpen: boolean) => void;
  setSelectedFilter: (selectedFilter: string | undefined) => void;
  numberOfActiveFilters: number | undefined;
  clearAllFilterCallback?: () => void;
  clearAllText?: string;
  hideEmpty?: boolean;
  clearAllIsDisabled?: boolean;
}

const ComboFilterLabel = ({ label, numFilters, hasActiveFilters, numActiveFilters }: FilterLabelProps) => {
  return (
    <EuiFlexGroup alignItems="center" className={hasActiveFilters ? activeFilterButtonCss : undefined}>
      <EuiFlexItem data-testid={`jobs-filter-${label}`}>{label}</EuiFlexItem>
      <EuiFlexItem grow={false}>
        {hasActiveFilters ? (
          <EuiNotificationBadge data-testid={`jobs-filter-count-${label}`}>{numActiveFilters}</EuiNotificationBadge>
        ) : (
          numFilters && <EuiNotificationBadge color="subdued">{numFilters}</EuiNotificationBadge>
        )}
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

const bounce = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const filterButtonCustomPadding = css`
  .euiButtonContent {
    padding-left: 12px;
    padding-right: 12px;
  }
`;

const filterTypeCssClass = css`
  .euiListGroupItem__label {
    width: 100%;
    text-decoration: none;
  }

  .euiListGroupItem__button,
  .euiListGroupItem__button:hover {
    text-decoration: none;
  }
`;

const selectorPanelClass = css`
  animation: ${bounce} 0.4s ease;
  border-left: 1px solid #ddd;
  min-height: 400px;
`;

const ComboFilterDropdown = ({
  buttonLabel,
  filterTypes,
  numberOfActiveFilters,
  clearAllFilterCallback,
  isOpen,
  selectedFilter,
  setIsOpen,
  setSelectedFilter,
  hideEmpty = false,
  clearAllText = 'Clear All',
  clearAllIsDisabled = false,
}: ComboFilterDropdownProps) => {
  const { theme } = useTectonTheme();

  const isPanelOpen = !!selectedFilter;

  const filterKeys = Object.keys(filterTypes);
  const displayedFilterKeys = filterKeys.filter((key) => !filterTypes[key]?.hidden);

  return (
    <EuiPopover
      button={
        <EuiFilterButton
          iconType="arrowDown"
          onClick={() => setIsOpen(!isOpen)}
          isSelected={isOpen}
          hasActiveFilters={!!numberOfActiveFilters && numberOfActiveFilters > 0}
          numActiveFilters={numberOfActiveFilters}
          className={activeFilterButtonCss + ` ${filterButtonCustomPadding}`}
        >
          <EuiIcon type="controlsVertical" style={{ marginRight: theme.size.s }} />
          {buttonLabel}
        </EuiFilterButton>
      }
      data-testid="combo-filter-dropdown"
      isOpen={isOpen}
      closePopover={() => setIsOpen(false)}
      panelPaddingSize="none"
    >
      <div
        style={{
          display: 'grid',
          transition: 'width 0.2s ease-in-out',
          width: isPanelOpen ? '640px' : '240px',
          gridTemplateColumns: isPanelOpen ? '240px 400px' : '240px 0',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <EuiListGroup flush={false} bordered={false} gutterSize="s" size="m">
            {displayedFilterKeys.map((filterKey) => {
              const isActive = filterKey === selectedFilter;

              if (hideEmpty && filterTypes[filterKey].numFilters === 0) {
                return;
              }

              return (
                <EuiListGroupItem
                  key={filterKey}
                  isActive={isActive}
                  color={isActive ? 'primary' : 'text'}
                  onClick={() => {
                    setSelectedFilter(filterKey);
                  }}
                  className={filterTypeCssClass}
                  label={
                    <ComboFilterLabel
                      label={filterTypes[filterKey].label}
                      numActiveFilters={filterTypes[filterKey].numActiveFilters}
                      numFilters={filterTypes[filterKey].numFilters}
                      hasActiveFilters={filterTypes[filterKey].hasActiveFilters}
                    />
                  }
                />
              );
            })}
          </EuiListGroup>
          {clearAllFilterCallback && (
            <EuiPopoverFooter paddingSize="s">
              <EuiButtonEmpty size="s" onClick={clearAllFilterCallback} isDisabled={clearAllIsDisabled}>
                {clearAllText}
              </EuiButtonEmpty>
            </EuiPopoverFooter>
          )}
        </div>
        {isPanelOpen && selectedFilter && (
          <div className={selectorPanelClass}>{filterTypes[selectedFilter].filterElement}</div>
        )}
      </div>
    </EuiPopover>
  );
};

export default ComboFilterDropdown;
