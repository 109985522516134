import { OnboardingStatusEnum } from '../../core/config/useOnboardingFlagsHook';

/*
    This represents onboarding state referenced here
    https://www.notion.so/tecton/Activation-Event-Sequence-c9ef5c7cbd9546e8950edfde5820ddc7
*/
export enum OnboardingStateEnums {
  NEW_ACCOUNT = 'NEW_ACCOUNT', // Needs fill out the form to create data bricks cluster
  HAS_COMPLETED_GUIDED_ONBOARDING = 'HAS_COMPLETED_GUIDED_ONBOARDING', // Clicked on button
}

export enum OnboardingStepStatus {
  STATUS_UNKNOWN = 'STATUS_UNKNOWN',
  STATUS_INCOMPLETE = 'STATUS_INCOMPLETE',
  STATUS_COMPLETED = 'STATUS_COMPLETED',
}

export enum OnboardingGlobalState {
  UPDATE_ONBOARDING_STATE = 'UPDATE_ONBOARDING_STATE',
  HAS_REQUEST_GLOBAL_CONFIG = 'HAS_REQUEST_GLOBAL_CONFIG',
}

export interface OnboardingUpdateAction {
  type: typeof OnboardingGlobalState.UPDATE_ONBOARDING_STATE;
  onBoardingState: OnboardingStateEnums;
}

export type OnboardingActionTypes = OnboardingUpdateAction;

export interface Id {
  most_significant_bits: number;
  least_significant_bits: number;
}

export interface BasicInfo {
  name?: string;
  description?: string;
  owner?: string;
  family?: string;
  tags?: any;
}

interface DataSourceNode {
  virtual_data_source_id: Id;
  input_name: string;
}

interface PipelineNode {
  data_source_node: DataSourceNode;
}

interface Input {
  arg_name: string;
  node: PipelineNode;
}

interface TransformationNode {
  transformation_id: Id;
  inputs: Input[];
}

export interface Pipeline {
  root: TransformationNode;
}

export interface OnboardingStatusInterface {
  create_feature_view: OnboardingStatusEnum;
  finish_onboarding: OnboardingStatusEnum;
  setup_platform: OnboardingStatusEnum;
}
