import { Global } from '@emotion/react';
import styled from '@emotion/styled';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useTectonTheme } from './Theme/ThemeProvider';

import { Button, ButtonIcon, FlexGroupWrapper, FlexItem, Icon, IconType, LinkIcon } from '@tecton/ComponentRedesign';

// svg Icons
import { ReactComponent as ArrowTopRightIcon } from '@svg/arrow-top-right.svg';
import { ReactComponent as ExitIcon } from '@svg/exit.svg';
import { ReactComponent as RocketIcon } from '@svg/rocket.svg';
// import them as img so they get render in the button correctly
import CheckWithCircleIcon from '@svg/check-with-circle.svg';
import ErrorIcon from '@svg/error.svg';
import InformationIcon from '@svg/information.svg';
import MessageIcon from '@svg/message.svg';
import WarningIcon from '@svg/warning.svg';

// default height
const bannerHeight = 60;

export type TopBannerVariant = 'info' | 'success' | 'warning' | 'error';

const StyledBanner = styled.div<{ backgroundColor?: string; borderColor?: string }>`
  display: flex;
  height: 100%;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  border-radius: 0;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-bottom: 1px solid ${({ borderColor }) => borderColor};
  padding: ${({ theme }) => theme.padding.m} ${({ theme }) => theme.padding.xl};
  color: ${({ theme }) => theme.colors.text};
  justify-content: center;
  align-items: center;
`;

const BannerAnimationWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  animation-fill-mode: forwards;
  animation: expandBanner 2s ease-in-out forwards;
  @keyframes expandBanner {
    from {
      height: 0;
      overflow: hidden;
    }
    to {
      height: ${bannerHeight}px;
    }
  }
`;

const InfoWrapper = styled.div<{ color: string }>`
  display: flex;
  overflow: hidden;
  align-items: center;
  font-weight: 600;
  color: ${({ color }) => color};
  font-size: ${({ theme }) => theme.font.fontSizes.s};
  line-height: ${({ theme }) => theme.font.headingLineHeights[4]};
  gap: ${({ theme }) => theme.gap.s};
`;

const BannerIconText: FC<{ type: IconType; label: string; color: string }> = ({ type, label, color }) => {
  return (
    <InfoWrapper color={color}>
      <Icon type={type} />
      {label}
    </InfoWrapper>
  );
};

interface TopBannerProps {
  bannerLabel: string;
  description: string;
  primaryText?: string;
  primaryIcon?: IconType;
  primaryUrl?: string;
  secondaryText?: string;
  secondaryIcon?: IconType;
  secondaryUrl?: string;
  variant?: TopBannerVariant;
  height?: number;
  onCloseClicked?: VoidFunction;
}

interface VariantProps {
  textColor: string;
  backgroundColor: string;
  borderColor: string;
  iconType: IconType;
}

const TopBanner: FC<TopBannerProps> = ({
  bannerLabel,
  description,
  primaryText,
  primaryIcon = RocketIcon,
  primaryUrl,
  secondaryText,
  secondaryIcon = MessageIcon,
  secondaryUrl,
  variant = 'info',
  height = bannerHeight,
  onCloseClicked,
}) => {
  const { theme } = useTectonTheme();

  const variantRecord: Record<TopBannerVariant, VariantProps> = {
    info: {
      textColor: theme.colors.text,
      backgroundColor: theme.colors.body,
      borderColor: theme.colors.subduedBorder,
      iconType: InformationIcon,
    },
    success: {
      textColor: theme.colors.successText,
      backgroundColor: theme.colors.behindTextSuccess,
      borderColor: theme.colors.successBorder,
      iconType: CheckWithCircleIcon,
    },
    warning: {
      textColor: theme.colors.warningText,
      backgroundColor: theme.colors.warningBackground,
      borderColor: theme.colors.warningBorder,
      iconType: WarningIcon,
    },
    error: {
      textColor: theme.colors.error,
      backgroundColor: theme.colors.errorBackground,
      borderColor: theme.colors.errorBorder,
      iconType: ErrorIcon,
    },
  };

  const selectedVariant = variantRecord[variant];

  return (
    <>
      <Global
        styles={`
          body {
            position: absolute;
            padding-top: ${height}px;
          }
          `}
      />
      <BannerAnimationWrapper>
        <StyledBanner
          data-testid="top-banner"
          backgroundColor={selectedVariant.backgroundColor ?? ''}
          borderColor={selectedVariant.borderColor}
        >
          <FlexGroupWrapper justifyContent="spaceBetween" direction="row" alignItems="center">
            <FlexItem grow={true}>
              <FlexGroupWrapper gap="s" direction="row" alignItems="center">
                <FlexItem grow={false}>
                  {/*
                  Create another ticket if Phil respond that this is a reusable component
                https://linear.app/tecton/issue/UI-590/banner-component
                */}
                  <BannerIconText
                    color={selectedVariant.textColor}
                    type={selectedVariant.iconType}
                    label={bannerLabel}
                  />
                </FlexItem>
                <FlexItem grow={false}>
                  <div>{description}</div>
                </FlexItem>
                {secondaryText && secondaryUrl && (
                  <FlexItem grow={false}>
                    <LinkIcon linkName={secondaryText} href={secondaryUrl} iconType={secondaryIcon} target="_blank" />
                  </FlexItem>
                )}
                {primaryText && primaryUrl && (
                  <FlexItem grow={false}>
                    <Link to={primaryUrl} target="_blank">
                      <Button
                        variant="emptyAction"
                        iconLeft={primaryIcon}
                        label={
                          <>
                            {primaryText} <Icon type={ArrowTopRightIcon} />
                          </>
                        }
                      />
                    </Link>
                  </FlexItem>
                )}
              </FlexGroupWrapper>
            </FlexItem>
            <FlexItem grow={false}>
              <ButtonIcon
                variant="transparentAction"
                iconType={ExitIcon}
                size="xs"
                onClick={onCloseClicked}
                compressed
              />
            </FlexItem>
          </FlexGroupWrapper>
        </StyledBanner>
      </BannerAnimationWrapper>
    </>
  );
};

export default TopBanner;
