// Types from backend for Batch Materialization Monitoring
export enum MaterializationMetricTypes {
  COUNT_DISTINCT = 'COUNT_DISTINCT',
  COUNT_ROWS = 'COUNT_ROWS',
  COUNT_NULLS = 'COUNT_NULLS',
  COUNT_ZEROS = 'COUNT_ZEROS',
  AVG_VALUE = 'AVG_VALUE',
  MAX_VALUE = 'MAX_VALUE',
  MIN_VALUE = 'MIN_VALUE',
  VAR_SAMPLE = 'VAR_SAMPLE',
  STDDEV_SAMPLE = 'STDDEV_SAMPLE',
  AVG_LENGTH = 'AVG_LENGTH',
}

export const MaterializationMetricTypeNames: Record<MaterializationMetricTypes, string> = {
  [MaterializationMetricTypes.COUNT_DISTINCT]: 'Count Distinct',
  [MaterializationMetricTypes.COUNT_ROWS]: 'COUNT_ROWS',
  [MaterializationMetricTypes.COUNT_NULLS]: 'Null Values Percentage',
  [MaterializationMetricTypes.COUNT_ZEROS]: 'Zero Values Percentage',
  [MaterializationMetricTypes.AVG_VALUE]: 'AVG_VALUE',
  [MaterializationMetricTypes.MAX_VALUE]: 'MAX_VALUE',
  [MaterializationMetricTypes.MIN_VALUE]: 'MIN_VALUE',
  [MaterializationMetricTypes.VAR_SAMPLE]: 'VAR_SAMPLE',
  [MaterializationMetricTypes.STDDEV_SAMPLE]: 'STDDEV_SAMPLE',
  [MaterializationMetricTypes.AVG_LENGTH]: 'AVG_LENGTH',
};

export enum MaterializationMetricStatus {
  METRIC_STATUS_AVAILABLE = 'METRIC_STATUS_AVAILABLE',
  METRIC_STATUS_UNAVAILABLE = 'METRIC_STATUS_UNAVAILABLE',
  METRIC_STATUS_ERROR = 'METRIC_STATUS_ERROR',
  METRIC_STATUS_NO_MATERIALIZATION = 'METRIC_STATUS_NO_MATERIALIZATION',
}

export enum MaterializationMetricResolutionGroups {
  TIME_RESOLUTION_ONE_MINUTE = 'TIME_RESOLUTION_ONE_MINUTE',
  TIME_RESOLUTION_FIVE_MINUTES = 'TIME_RESOLUTION_FIVE_MINUTES',
  TIME_RESOLUTION_ONE_HOUR = 'TIME_RESOLUTION_ONE_HOUR',
  TIME_RESOLUTION_ONE_DAY = 'TIME_RESOLUTION_ONE_DAY',
  TIME_RESOLUTION_ONE_WEEK = 'TIME_RESOLUTION_ONE_WEEK',
  TIME_RESOLUTION_ONE_MONTH = 'TIME_RESOLUTION_ONE_MONTH',
  TIME_RESOLUTION_ONE_YEAR = 'TIME_RESOLUTION_ONE_YEAR',
}

export interface MaterializationMetricsDefaultValuesForPanel {
  rowsValue: number | undefined;
  rowsDelta: number | undefined;
  joinKeysValue: number | undefined;
  joinKeysDelta: number | undefined;
  mostRecentJob: MaterializationJob | undefined;
  features: { featureName: string; value: number | undefined; delta: number | undefined }[];
}

export interface MaterializationQueryFeatureMetricDatum {
  feature_name: string;
  value: string;
}

export interface MaterializationQueryMetricValuesDatum {
  metric_status: MaterializationMetricStatus;
  interval_start_time: string;
  materialization_run_id?: string;
  materialization_task_attempt_url?: string;
  metric_values: MaterializationQueryFeatureMetricDatum[];
}

export interface MaterializationQueryMetricResponse {
  aligned_end_time: string; // UTC-formatted string
  aligned_start_time: string; // UTC-formatted string
  column_names: string[]; // Names of the columns associated with the response?
  feature_view_name: string; // The name of the feature view
  metric_data: MaterializationQueryMetricValuesDatum[]; // The metrics data
  metric_data_point_interval: string; // What is the  format of this?
  metric_type: MaterializationMetricTypes; // This was passed in
  workspace: string;
}

// Generic interface for intervals between dates
export interface DateInterval {
  start: Date;
  end: Date;
}

// Models Batch Materialization Jobs
export interface MaterializationJob {
  range: DateInterval;
  id: string;
  events: MaterializationEvent[];
  url?: string;
  status: MaterializationMetricStatus;
}

// Models batch materialization events -- either a backfilled interval OR an incremental job
export interface MaterializationEvent {
  date: Date;
  value?: number;
  id?: string;
  url?: string;
  status: MaterializationMetricStatus;
}

export interface MaterializationQueryResult {
  apiResponse: MaterializationQueryMetricResponse;
  data: MaterializationEvent[];
}

export type FeatureAndMetricMap = Map<string, MaterializationMetricTypes[]>;

export interface MaterializationQueryIndividualFeature {
  name: string;
  data: MaterializationEvent[];
}

export interface MaterializationQueryFeatureResult {
  apiResponse: MaterializationQueryMetricResponse;
  features: MaterializationQueryIndividualFeature[];
}

export interface MaterializationPanelFeature {
  id: string;
  name: string;
  data: MaterializationEvent[];
}

export enum MaterializationMetricFeatureTypes {
  PERCENTAGE = 'PERCENTAGE',
  VALUE = 'VALUE',
}

export const MaterializationMetricFeatureTypeMap = new Map<
  MaterializationMetricTypes,
  MaterializationMetricFeatureTypes
>([
  [MaterializationMetricTypes.AVG_LENGTH, MaterializationMetricFeatureTypes.VALUE],
  [MaterializationMetricTypes.COUNT_NULLS, MaterializationMetricFeatureTypes.PERCENTAGE],
  [MaterializationMetricTypes.AVG_VALUE, MaterializationMetricFeatureTypes.VALUE],
  [MaterializationMetricTypes.COUNT_ZEROS, MaterializationMetricFeatureTypes.PERCENTAGE],
  [MaterializationMetricTypes.MAX_VALUE, MaterializationMetricFeatureTypes.VALUE],
  [MaterializationMetricTypes.MIN_VALUE, MaterializationMetricFeatureTypes.VALUE],
  [MaterializationMetricTypes.STDDEV_SAMPLE, MaterializationMetricFeatureTypes.VALUE],
  [MaterializationMetricTypes.VAR_SAMPLE, MaterializationMetricFeatureTypes.VALUE],
]);

export const dateTickIntervalsMap: Record<MaterializationMetricResolutionGroups, number> = {
  [MaterializationMetricResolutionGroups.TIME_RESOLUTION_ONE_MINUTE]: 6,
  [MaterializationMetricResolutionGroups.TIME_RESOLUTION_FIVE_MINUTES]: 6,
  [MaterializationMetricResolutionGroups.TIME_RESOLUTION_ONE_HOUR]: 6,
  [MaterializationMetricResolutionGroups.TIME_RESOLUTION_ONE_DAY]: 7,
  [MaterializationMetricResolutionGroups.TIME_RESOLUTION_ONE_WEEK]: 4,
  [MaterializationMetricResolutionGroups.TIME_RESOLUTION_ONE_MONTH]: 3,
  [MaterializationMetricResolutionGroups.TIME_RESOLUTION_ONE_YEAR]: 1,
};
