import { css } from '@emotion/css';
import React from 'react';

const ButtonRowCss = css`
  display: flex;
  justify-content: flex-end;
`;

const ButtonRow = ({ children, style }: { children: React.ReactNode; style?: React.CSSProperties }) => {
  return (
    <div style={style} className={ButtonRowCss}>
      {children}
    </div>
  );
};

export default ButtonRow;
