import React from 'react';
import { EmptyPrompt } from '@tecton';

const NoMatch = () => {
  return (
    <EmptyPrompt
      iconType="questionInCircle"
      title={<h2>Page Not Found</h2>}
      body={<p>Tony has not gotten around to mocking this up.</p>}
    />
  );
};

export default NoMatch;
