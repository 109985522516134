import { css } from '@emotion/css';
import React, { FC } from 'react';
import { useTectonTheme } from '../@tecton';

interface FCODetailsPageLayoutProps {
  children: React.ReactNode;
}

const FCODetailsPageLayout: FC<FCODetailsPageLayoutProps> = ({ children }) => {
  const { theme } = useTectonTheme();

  const grid = css`
    display: grid;
    grid-template-columns: 50% 50%;
    column-gap: ${theme.size.l};
    row-gap: ${theme.size.l};
    max-width: 100%;
  `;
  return <div className={grid}>{children}</div>;
};

export default FCODetailsPageLayout;
