export default () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.66667 17.5H13.3333C15.6345 17.5 17.5 15.6345 17.5 13.3333V6.66667C17.5 4.36548 15.6345 2.5 13.3333 2.5H6.66667C4.36548 2.5 2.5 4.36548 2.5 6.66667V13.3333C2.5 15.6345 4.36548 17.5 6.66667 17.5Z"
        stroke="#525866"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.6665 11.6663L8.384 9.51105C8.52155 9.33845 8.76983 9.30304 8.95014 9.43032L10.8745 10.7886C11.0548 10.9159 11.3031 10.8805 11.4406 10.7079L13.3332 8.33301"
        stroke="#525866"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
