import _ from 'lodash';
import { useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import MetadataService from '../../../service/MetadataService';

enum Actions {
  ACTION_LIST_DATABASES = 'ACTION_LIST_DATABASES',
  ACTION_LIST_TABLES = 'ACTION_LIST_TABLES',
  ACTION_GET_TABLE_SCHEMA = 'ACTION_GET_TABLE_SCHEMA',
}

const useHiveMetadataService = () => {
  const databasesMutation = useMutation(() => {
    const action = {
      action: Actions.ACTION_LIST_DATABASES,
    };
    return MetadataService.sharedInstance()
      .getHiveMetaData(action)
      .then((result) => {
        // Return tables
        if (result.success) {
          return _.get(result, 'databases.names');
        }
        // Error has happened, return the result and handle it downstream
        return result;
      });
  });

  const sendDatabaseListRequest = (callBack?: (response: any) => void) => {
    if (callBack) {
      databasesMutation.mutate(undefined, {
        onSuccess: callBack,
      });
    } else {
      databasesMutation.mutate();
    }
  };

  useEffect(() => {
    if (databasesMutation.isIdle) {
      // Run once, when the hook is loaded
      databasesMutation.mutate();
    }
  }, [databasesMutation]);

  return {
    databasesRequest: {
      sendRequest: sendDatabaseListRequest,
      isIdle: databasesMutation.isIdle,
      isLoading: databasesMutation.isLoading,
      isError: databasesMutation.isError,
      isSuccess: databasesMutation.isSuccess,
      data: databasesMutation.data,
    },
  };
};

export default useHiveMetadataService;
