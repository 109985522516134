const BASE_SIZE = 14;

export default {
  animation: {
    bounce: 'cubic-bezier(.34, 1.61, .7, 1)',
    extraFast: '90ms',
    extraSlow: '500ms',
    fast: '150ms',
    normal: '250ms',
    resistance: 'cubic-bezier(.694, .0482, .335, 1)',
    slow: '350ms',
  }, // Default values, currently unused
  base: BASE_SIZE, // Default value, needs to be reviews
  badgeRadius: '24px',
  border: {
    color: '#D3DAE6',
    editable: '2px dotted #D3DAE6',
    radius: {
      viewBoundaryTop: '16px 16px 0px 0px',
      viewBoundaryBottom: '0px 0px 16px 16px',
      large: '24px',
      medium: '8px',
      small: '6px',
    },
    thick: '2px solid #D3DAE6',
    thin: '1px solid #D3DAE6',
    width: { thin: '1px', thick: '2px' },
  }, // Default values, need to be reviewed
  breakpoint: {
    xl: 1200,
    l: 992,
    m: 768,
    s: 575,
    xs: 0,
  }, // Default values, currently unused
  categoryColorPairs: [
    {
      base: '#54B399',
      text: '#0F4B3A',
      behindText: '#E4F9F3',
    },
    {
      base: '#6092C0',
      text: '#163B5C',
      behindText: '#ECF6FA',
    },
    {
      base: '#D36086',
      text: '#62152E',
      behindText: '#FFF2F6',
    },
    {
      base: '#9170B8',
      text: '#63438A',
      behindText: '#F5EEFD',
    },
    {
      base: '#CA8EAE',
      text: '#8D647A',
      behindText: '#F5DBE9',
    },
    {
      base: '#D6BF57',
      text: '#68570D',
      behindText: '#FFFBEC',
    },
    {
      base: '#B9A888',
      text: '#7B705A',
      behindText: '#F6EDDB',
    },
    {
      base: '#DA8B45',
      text: '#996130',
      behindText: '#FBE8D7',
    },
    {
      base: '#AA6556',
      text: '#9A5B4E',
      behindText: '#F4D0C8',
    },
    {
      //error
      base: '#E7664C',
      text: '#792A1A',
      behindText: '#FFF0EC',
    },
  ],
  colors: {
    // Leaving in the explicit values here to ensure that we don't make inadvertent changes to the existing UI
    // Will remove once these are tested
    accent: '#5F57FF',
    // accentText: '', Not explicity defined: derived
    body: '#FAFAFB',
    danger: '#D30602',
    // dangerText: '', Not explicity defined: derived
    dataGridBorder: '#EDF0F5',
    darkShade: '#56708A',
    darkestShade: '#022638',
    disabled: '#ABB4C41A',
    disabledText: '#A2ABBA',
    emptyShade: '#FFFFFF',
    fullShade: '#323232',
    // ghost: '', Not explicity defined: derived
    // highlight: '', Not explicity defined: derived
    // ink: '', Not explicity defined: derived
    lightShade: '#D3DAE6',
    lightestShade: '#F1F4FA',
    // link: '', Not explicity defined: derived
    mediumShade: '#98A2B3',
    primary: '#D30602',
    // primaryText: '', Not explicity defined: derived
    // shadow: '', Not explicity defined: derived
    // subduedText: '', Not explicity defined: derived
    success: '#00A97C',
    // successText: '', Not explicity defined: derived
    successText: '#1C624E',
    successBorder: '#66B79E',
    text: '#525866',
    title: '#191A1E',
    warningText: '#55501E',
    warning: '#FFEED4',
    warningBorder: '#D4CB7B',
    warningBackground: '#FFFBEC',
    error: '#781D1D',
    errorBorder: '#DA625F',
    errorBackground: '#FFF0EC',
    subduedText: '#868c98',
    subduedBackground: '#FAFAFB',
    subduedBorder: '#9B9CA0',
    inputBorder: '#000b301a',
    backgroundPrimary: '#eaecf0',
    behindText: '#dbecf5',
    behindTextSuccess: '#E4F9F3',
    filledDefaultSecondaryHover: '#F2F4F6',
    textDefaultSecondary: '#52586',

    // These are copied over from light theme by never actually used currently
    // Only including them to ensure that this matches the Theme type and doesn't cause additional issues
    core: {
      emptyShade: 'var(--colors-base-white)',
      lightestShade: 'var(--colors-gray-light-mode-25)',
      lightShade: 'var(--colors-gray-light-mode-50)',
      mediumShade: 'var(--colors-gray-light-mode-400)',
      darkShade: 'var(--colors-gray-light-mode-500)',
      darkestShade: 'var(--colors-gray-light-mode-800)',
      fullShade: 'var(--colors-base-black)',
      primary: 'var(--colors-brand-500)',
      accent: 'var(--colors-night-blue-500)',
      success: 'var(--colors-success-500)',
      yellow: 'var(--colors-warning-500)',
      danger: 'var(--colors-error-500)',
    },

    _text: {
      text: 'var(--colors-gray-light-mode-600)',
      subduedText: 'var(--colors-gray-light-mode-500)',
      title: 'var(--colors-gray-light-mode-950)',
      primaryText: 'var(--colors-gray-light-mode-900)',
      successText: 'var(--colors-success-900)',
      accentText: 'var(--colors-pink-900)',
      warningText: 'var(--colors-warning-900)',
      dangerText: 'var(--colors-error-900)',
      invertedText: 'var(--colors-base-white)',
    },

    special: {
      backgroundColor: '#0077CC',
      body: 'var(--colors-night-blue-25)',
      highlight: 'var(--colors-warning-25)',
      disabled: 'var(--colors-gray-light-mode-500)',
      disabledText: 'var(--colors-gray-light-mode-500)',
      shadow: 'var(--colors-base-black)',
      ink: 'var(--colors-base-black)',
      ghost: 'var(--colors-base-white)',
      transparent: '#ffffff00',
    },

    borders: {
      default: 'var(--colors-gray-light-mode-50)',
      light: 'var(--colors-base-white)',
      dark: 'var(--colors-base-black)',
    },

    background: {
      plain: 'var(--colors-base-white)',
      subdued: 'var(--colors-gray-light-mode-25)',
      success: 'var(--colors-success-100)',
      warning: 'var(--colors-warning-50)',
      danger: 'var(--colors-error-25)',
      accent: 'var(--colors-night-blue-25)',
      primary: 'var(--colors-gray-light-mode-100)',
      modal: 'var(--colors-base-white)',
      inverted: 'var(--colors-base-black)',
      elements: 'var(--colors-gray-light-mode-50)',

      hover: {
        plain: 'rgba(255,255,255,0.2)',
        subdued: 'var(--colors-gray-light-mode-25)',
        primary: 'var(--colors-blue-mist-100)',
        success: 'var(--colors-success-100)',
        warning: 'var(--colors-warning-100)',
        danger: 'var(--colors-error-100)',
        accent: 'var(--colors-night-blue-100)',
      },

      button: {
        primary: 'var(--colors-gray-light-mode-25)',
        accent: 'var(--colors-night-blue-300)',
        danger: 'var(--colors-error-25)',
        ghost: 'var(--colors-gray-light-mode-800)',
        ghostFilled: 'var(--colors-gray-light-mode-200)',
        secondary: 'var(--colors-base-black)',
        Primary: 'var(--colors-base-white)',
        Primary_hover: 'var(--colors-gray-light-mode-100)',
        Secondary_hover: 'var(--colors-gray-light-mode-800)',
      },
    },

    overlay: {
      mask: 'rgba(25, 26, 30, 0.08)',
    },

    viz: {
      default: {
        success: 'var(--colors-success-500)',
        1: 'var(--colors-blue-mist-500)',
        2: 'var(--colors-rosé-500)',
        3: 'var(--colors-violet-500)',
        4: 'var(--colors-pink-500)',
        5: 'var(--colors-warning-500)',
        6: 'var(--colors-empire-blue-500)',
        7: 'var(--colors--blue-500)',
        8: 'var(--colors-error-500)',
        9: 'var(--colors-night-blue-500)',
      },
      text: {
        success: 'var(--colors-success-900)',
        1: 'var(--colors-blue-mist-900)',
        2: 'var(--colors-rosé-900)',
        3: 'var(--colors-violet-900)',
        4: 'var(--colors-pink-900)',
        5: 'var(--colors-warning-900)',
        6: 'var(--colors-empire-blue-900)',
        7: 'var(--colors--blue-900)',
        8: 'var(--colors-error-900)',
        9: 'var(--colors-night-blue-900)',
      },
      behindText: {
        success: 'var(--colors-success-300)',
        1: 'var(--colors-blue-mist-300)',
        2: 'var(--colors-rosé-300)',
        3: 'var(--colors-violet-300)',
        4: 'var(--colors-pink-300)',
        5: 'var(--colors-warning-300)',
        6: 'var(--colors-empire-blue-300)',
        7: 'var(--colors--blue-300)',
        8: 'var(--colors-error-300)',
        9: 'var(--colors-night-blue-300)',
      },
    },

    buttons: {
      text: {
        default: {
          primary: 'var(--colors-base-white)',
          secondary: 'var(--colors-gray-light-mode-600)',
          tertiary: 'var(--colors-gray-light-mode-600)',
          link: 'var(--colors-gray-light-mode-600)',
        },
        danger: {
          primary: 'var(--colors-base-white)',
          secondary: 'var(--colors-error-900)',
          tertiary: 'var(--colors-error-900)',
          link: 'var(--colors-error-950)',
        },
        disabled: {
          main: 'var(--colors-gray-light-mode-400)',
        },
      },
      stroke: {
        default: 'var(--colors-gray-light-mode-50)',
        danger: 'var(--colors-error-100)',
        disabled: 'var(--colors-gray-light-mode-50)',
      },
      filled: {
        disabled: {
          main: 'var(--colors-gray-light-mode-600)',
        },
        default: {
          primary: {
            normal: 'var(--colors-base-white)',
            hover: 'var(--colors-gray-light-mode-800)',
          },
          secondary: {
            normal: 'var(--colors-base-white)',
            hover: 'var(--colors-gray-light-mode-100)',
          },
          tertiary: {
            normal: 'var(--colors-base-white)',
            hover: 'var(--colors-gray-light-mode-100)',
          },
        },
        danger: {
          primary: {
            normal: 'var(--colors-error-600)',
            hover: 'var(--colors-error-700)',
          },
          secondary: {
            normal: 'var(--colors-base-transparent)',
            hover: 'var(--colors-error-25)',
          },
          tertiary: {
            normal: 'var(--colors-base-transparent)',
            hover: 'var(--colors-error-25)',
          },
        },
      },
    },

    forms: {
      background: 'var(--colors-gray-light-mode-25)',
      backgroundDisabled: 'var(--colors-gray-light-mode-50)',
      backgroundReadOnly: 'var(--colors-base-white)',
      borderColor: 'var(--colors-gray-light-mode-200)',
      borderOpaqueColor: 'var(--colors-gray-light-mode-200)',
      formInputLabelGroupBackground: 'var(--colors-gray-light-mode-100)',
      formInputGroupBorder: 'var(--colors-gray-light-mode-200)',
      switchOffColor: 'var(--colors-gray-light-mode-300)',
      switchOffBackground: 'var(--colors-gray-light-mode-200)',
      formDisabledIcon: 'var(--colors-gray-light-mode-600)',
      formControlBorder: 'var(--colors-gray-light-mode-300)',
      backgroundFocus: 'var(--colors-base-white)',
    },

    graphicsAndIllustrations: {
      success: 'var(--colors-success-500)',
      brand: 'var(--colors-brand-500)',
      plain: 'var(--colors-base-white)',
      transparent: 'var(--colors-base-transparent)',
      blanch: 'var(--colors-gray-light-mode-25)',
      lightGrey: 'var(--colors-gray-light-mode-200)',
      mediumGrey: 'var(--colors-gray-light-mode-300)',
      grey: 'var(--colors-gray-light-mode-400)',
      darkGrey: 'var(--colors-gray-light-mode-500)',
      darkestGrey: 'var(--colors-gray-light-mode-800)',
    },

    table: {
      hover: 'var(--colors-gray-light-mode-25)',
      selected: 'var(--colors-gray-light-mode-50)',
      header: 'var(--colors-gray-light-mode-25)',
    },

    tooltip: {
      background: 'var(--colors-gray-light-mode-800)',
      text: 'var(--colors-base-white)',
    },
  },
  customColorTokens: {
    dataGridBorder: '#EDF0F5',
  },
  FCOColors: {
    dataset: '#ED4491',
    source: '#4096C6',
    entity: '#FF7C33',
    transformation: '#5F57FF',
    featureView: '#00A97C',
    featureService: '#FFAE33',
    embedding: '#DFDEF8',
  },
  FCOTintColors: {
    // THESE ARE OLD AND DERIVED FROM the tint() function from EUI. Included only for temporary parity
    dataset: '#fce3ef',
    source: '#e2eff6',
    entity: '#ffebe0',
    transformation: '#e7e6ff',
    featureView: '#d9f2eb',
    featureService: '#fff3e0',
    embedding: '#e7e6ff',
  },

  focus: { color: 'currentColor', width: '2px' }, // Default value, currently unused
  font: {
    baseline: 4,
    body: {
      // scale: '"s"',
      // weight: '"regular"',
    },
    family: "'Inter', BlinkMacSystemFont, Helvetica, Arial, sans-serif",
    familyCode: "'Roboto Mono', Menlo, Courier, monospace",
    familySerif: 'Georgia, Times, Times New Roman, serif',
    featureSettings: "'calt' 1, 'kern' 1, 'liga' 1",
    headingSizes: {
      '1': '34px',
      '2': '23.625px',
      '3': '19.25px',
      '4': '14px',
      '5': '12.25px',
      '6': '10.5px',
    },
    headingLineHeights: {
      '1': '40px',
      '2': '28px',
      '3': '24px',
      '4': '20px',
      '5': '16px',
      '6': '16px',
    },
    fontSizes: {
      l: '34px',
      m: '16px',
      s: '14px',
      xs: '12px',
      xxs: '10.5px',
      xxxs: '9px',
    },
    lineHeights: {
      l: '38.85px',
      m: '24px',
      s: '20px',
      xs: '13.71px',
      xxs: '12px',
      xxxs: '10.285px',
    },
    lineHeightMultiplier: 1.25,
    scale: {
      l: 34 / BASE_SIZE, // should be 34px
      m: 24 / BASE_SIZE, // should be 26px
      s: 1, // should be 22px
      xs: 12 / BASE_SIZE, // should be 16px?
      xxs: 1, //
      xxxs: 1,
    },
    title: {
      //weight: '"bold"'
    },
    weight: { light: 300, regular: 400, medium: 500, semiBold: 600, bold: 700 },
  }, // Default values, need to review
  levels: {
    content: 0,
    flyout: 1000,
    header: 1000,
    mask: 6000,
    maskBelowHeader: 1000,
    menu: 2000,
    modal: 8000,
    navigation: 6000,
    toast: 9000,
  }, // z-indices: currently unused
  padding: {
    xxs: '2px',
    xs: '4px',
    s: '8px',
    m: '12px',
    l: '16px',
    xl: '24px',
    xxl: '48px',
  },
  spacing: {
    none: '0px',
    xxs: '2px',
    xs: '4px',
    sm: '6px',
    md: '8px',
    lg: '12px',
    xl: '16px',
    '2xl': '20px',
    '3xl': '24px',
    '4xl': '32px',
    '5xl': '40px',
  },
  gap: {
    none: 'none',
    xxs: '2px',
    xs: '4px',
    s: '8px',
    m: '12px',
    l: '16px',
    xl: '24px',
    xxl: '32px',
  },
  shadow: {
    xs: '0px 2.3px 2px 0px rgba(0, 0, 0, 0.02), 0px 0.8px 0.8px 0px rgba(0, 0, 0, 0.02);',
    s: '0px 4.5px 10px 0px rgba(0, 0, 0, 0.03), 0px 0.7px 1.4px 0px rgba(0, 0, 0, 0.05);',
    m: '0px 15px 15px -1px rgba(0, 0, 0, 0.02), 0px 5.7px 12px -1px rgba(0, 0, 0, 0.04), 0px 0.9px 4px -1px rgba(0, 0, 0, 0.06);',
    l: '0px 23px 35px 0px rgba(0, 0, 0, 0.04), 0px 8.4px 23px 0px rgba(0, 0, 0, 0.04);',
    xl: '0px 21.8px 43px 0px rgba(0, 0, 0, 0.05), 0px 2.7px 16px 0px rgba(0, 0, 0, 0.10);',
    xlReverse:
      '0px -21.8px 43px 0px rgba(0, 0, 0, 0.08), 0px -9.4px 24px 0px rgba(0, 0, 0, 0.09), 0px -2.7px 9px 0px rgba(0, 0, 0, 0.13);',
    flat: '0px 0px 17px 0px rgba(0, 0, 0, 0.03), 0px 0px 5px 0px rgba(0, 0, 0, 0.04), 0px 0px 2px 0px rgba(0, 0, 0, 0.04), 0px 0px 0.8px 0px rgba(0, 0, 0, 0.06);',
  },
  size: {
    base: `${BASE_SIZE}px`,
    l: '24px',
    m: '12px',
    s: '8px',
    xl: '32px',
    xs: '4px',
    xxl: '40px',
    xxs: '2px',
    xxxl: '48px',
    xxxxl: '64px',
  },
  text: {
    size: {
      h1: '32px',
      h2: '24px',
      h3: '20px',
      h4: '16px',
      h5: '14px',
      h6: '12px',
      p: '14px',
    },
    weight: {
      h1: 500,
      h2: 500,
      h3: 500,
      h4: 500,
      h5: 500,
      h6: 500,
      p: 400,
    },
    lineHeight: {
      h1: '40px',
      h2: '32px',
      h3: '24px',
      h4: '24px',
      h5: '20px',
      h6: '16px',
      p: '20px',
    },
  },
};
