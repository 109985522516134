import { css } from '@emotion/css';
import { useTectonTheme } from '../@tecton';

export const useTableStyle = () => {
  const { theme } = useTectonTheme();
  const tableStyleCss = css`
    .euiTableCellContent {
      font-size: 14px;
      white-space: nowrap;
      white-space-collapse: nowrap;
    }

    table {
      table-layout: fixed;
    }

    tr:hover td {
      background-color: ${theme.color.light.subtleHighlight};
    }

    table th:first-child,
    td:first-child {
      position: sticky;
      left: 0;
      background-color: white;
      z-index: 1;
    }
  `;

  return tableStyleCss;
};
