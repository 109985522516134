import { NotificationStoreInterface } from './types';
import { NotificationActionTypes } from './actions';

import { AnyAction } from 'redux';

const initialNotificationState: NotificationStoreInterface = { notifications: [], latest: null };
export const notificationReducer = (state = initialNotificationState, action: AnyAction) => {
  switch (action.type) {
    case NotificationActionTypes.SEND_NOTIFICATION:
      return {
        ...state,
        latest: action.payload,
        notifications: [...state.notifications, action.payload],
      };

    case NotificationActionTypes.EXPIRE_NOTIFICATION:
      return {
        ...state,
        latest: null,
      };
    default:
      return state;
  }
};
