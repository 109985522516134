export default class FcoLocator {
  private proto: any = null;

  constructor(proto: any) {
    this.proto = proto;
  }

  get _proto() {
    return this.proto;
  }

  get name() {
    return this._proto.name;
  }

  get workspace() {
    return this._proto.workspace;
  }

  static _wrapFcoLocator(proto: any) {
    return new FcoLocator(proto);
  }
}
