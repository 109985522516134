/* eslint-disable */

export const protobufPackage = "tecton_proto.common";

/** DataSourceType enumerates the supported types for a Data Source */
export enum DataSourceType {
  /** UNKNOWN - protolint:disable:this ENUM_FIELD_NAMES_ZERO_VALUE_END_WITH */
  UNKNOWN = "UNKNOWN",
  BATCH = "BATCH",
  STREAM_WITH_BATCH = "STREAM_WITH_BATCH",
  /** PUSH_NO_BATCH - PushSource without a batch_config */
  PUSH_NO_BATCH = "PUSH_NO_BATCH",
  /** PUSH_WITH_BATCH - PushSource with a batch_config */
  PUSH_WITH_BATCH = "PUSH_WITH_BATCH",
}
