import { Box, Paper } from '@mui/material';
import React, { FC, ReactNode } from 'react';
import IconButton from '@mui/material/IconButton';

import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import LoopIcon from '@mui/icons-material/Loop';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

import styles from './StatusProgress.module.css';
import { ProgressionStatusType } from './types';

const PROGRESSION_ICON_MAP: any = {
  SUCCESS: (
    <IconButton disabled={true} color="primary">
      <CheckIcon style={{ color: '#27AE60' }} />
    </IconButton>
  ),
  ERROR: (
    <IconButton disabled={true}>
      <ClearIcon style={{ color: '#DA4B35' }} />
    </IconButton>
  ),
  IN_PROGRESS: (
    <IconButton disabled={true}>
      <LoopIcon className={styles['status-progression-in-progress']} style={{ color: '#0f8aff' }} />
    </IconButton>
  ),
  SETTING_UP: (
    <IconButton disabled={true}>
      <LoopIcon style={{ color: '#8f9cac' }} />
    </IconButton>
  ),
  UNKNOWN: (
    <IconButton disabled={true}>
      <QuestionMarkIcon style={{ color: '#27AE60' }} />
    </IconButton>
  ),
};

export interface Progression {
  status: ProgressionStatusType;
  headerContent: ReactNode;
  bodyContent?: ReactNode;
}

export interface StatusProgressionInterface {
  statusProgressions: Progression[];
}

export const getIcon = (progressionStatus: ProgressionStatusType): JSX.Element => {
  return PROGRESSION_ICON_MAP[`${progressionStatus}`];
};

const StatusProgression: FC<StatusProgressionInterface> = ({ statusProgressions }) => {
  const body = statusProgressions.map((statusProgression: Progression, index: number) => {
    let rightSideContent = (
      <Box flex={1} alignContent={'center'} style={{ margin: 'auto', padding: 10 }}>
        {statusProgression.headerContent}
      </Box>
    );

    if (statusProgression.bodyContent) {
      rightSideContent = (
        <Box flex={1} alignContent={'center'} style={{ margin: 'auto', padding: 10 }}>
          {statusProgression.headerContent}
          {statusProgression.bodyContent}
        </Box>
      );
    }

    return (
      <Box display="flex" key={index} className={styles['status-progression-box']}>
        <Box flex={0} style={{ padding: 5 }}>
          {getIcon(statusProgression.status)}
        </Box>
        {rightSideContent}
      </Box>
    );
  });

  return <Paper variant="outlined">{body}</Paper>;
};

export { StatusProgression };
