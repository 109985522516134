import React from 'react';
import { ACLGroupProfileContextGQLProvider } from './ACLGroupProfileContextGQL';
import ACLGroupProfileGQL from './ACLGroupProfileGQL';

import { ErrorBoundary } from '@tecton';

const ACLGroupProfileContainer = () => {
  return (
    <ErrorBoundary>
      <ACLGroupProfileContextGQLProvider>
        <ACLGroupProfileGQL />
      </ACLGroupProfileContextGQLProvider>
    </ErrorBoundary>
  );
};

export default ACLGroupProfileContainer;
