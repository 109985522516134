export enum FeatureViewType {
  STREAM = 'STREAM',
  BATCH = 'BATCH',
  STREAM_WINDOW_AGGREGATE = 'STREAM_WINDOW_AGGREGATE',
  BATCH_WINDOW_AGGREGATE = 'BATCH_WINDOW_AGGREGATE',
  ON_DEMAND = 'ON_DEMAND',
  FEATURE_TABLE = 'FEATURE_TABLE',
  UNKNOWN = 'UNKNOWN',
  CUSTOM_BATCH = 'CUSTOM_BATCH',
}
