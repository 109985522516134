import React from 'react';
import { renderPlanActionTypeBadge } from './PlanActionTypeBadge';
import PlanChangesColumn from './PlanChangesColumn';
import { FcoPropertyDiff, StateUpdatePlanSummaryDiff } from '../../types/tecton_proto/data/state_update';
import { StateUpdatePlanSummaryType } from './PlanSummaryTransform';
import PlanFeatureServiceName from './PlanFeatureServiceName';
import { Table } from '@tecton';
import { useTableStyle } from '@shared';
import {
  TableCommonActionColumn,
  TableCommonNameColumn,
  TableCommonChangesColumn,
  TableCommonTypeColumn,
  scrollIdMap,
  sortByActionType,
  TableCommonMaterializationColumn,
} from './util';
import PlanCategoryAccordion from './PlanCategoryAccordion';

const PlanFSTable = ({ stateUpdatePlanSummary }: { stateUpdatePlanSummary?: StateUpdatePlanSummaryType }) => {
  const tableStyleCss = useTableStyle();

  const featureServices =
    stateUpdatePlanSummary?.plan?.diff_items
      ?.filter((item) => {
        return item.fco_type === 'Feature Service';
      })
      ?.sort(sortByActionType) ?? [];

  if (featureServices.length === 0) {
    return <></>;
  }

  return (
    <PlanCategoryAccordion id={scrollIdMap['Feature Service']} title="Feature Services">
      <Table
        className={tableStyleCss}
        items={featureServices}
        columns={[
          {
            ...TableCommonActionColumn,
            render: renderPlanActionTypeBadge,
          },
          {
            ...TableCommonNameColumn,
            render: (name: string, row: StateUpdatePlanSummaryDiff) => {
              return (
                <PlanFeatureServiceName workspace={stateUpdatePlanSummary?.plan?.workspace} stateUpdatePlanDiff={row} />
              );
            },
          },
          {
            ...TableCommonChangesColumn,
            render: (diffs: FcoPropertyDiff[], row: StateUpdatePlanSummaryDiff) => {
              return <PlanChangesColumn diffs={diffs} row={row} />;
            },
          },
          {
            ...{ ...TableCommonTypeColumn, name: '' },
            render: (fco_type: string) => {
              return <></>;
            },
          },
          {
            ...{ ...TableCommonMaterializationColumn, name: '' },
            render: (entry: StateUpdatePlanSummaryDiff) => {
              return <></>;
            },
          },
        ]}
      />
    </PlanCategoryAccordion>
  );
};

export default PlanFSTable;
