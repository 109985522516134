import { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import {
  useGetServiceAccountAssignedWorkspace,
  useServiceAccountsAssignedPrincipals,
  useGetServiceAccountByIds,
  useMutateServiceAccount,
  useDeleteServiceAccount,
  useAssignRolesMutation,
  useAssignRoleMutation,
  useUpdateAccountTypeMutation,
  useGetRoles,
  useGetGroups,
  useListPrincipalGroupsForPrincipal,
  useRemovePrincipalGroupMembersMutation,
  useAddPrincipalGroupMembersMutation,
  useGetAuthorizedResources,
} from '../../api/acl';
import { useListWorkspaces } from '../../api/workspaces';
import {
  ACLServiceAccountProfileModal,
  filterAssignedWorkspace,
  getAdminRoleAssignmentSources,
  getAssignedAccountType,
  getEffectiveAccountType,
  getInheritedAccountTypes,
  getLegacyRoleId,
  getResourceId as getResourceId,
  getWorkspaceCount,
  transformACLRole,
} from './aclUtils';

import {
  ListWorkspacesResponse,
  ServiceAccount,
  UpdateServiceAccountResponse,
} from '../../types/tecton_proto/metadataservice/metadata_service';

import { CalloutMessageType } from '@shared';
import { addToast, ToastContext } from '../@tecton/ToastContext';

import { ACLWorkspaceServiceRelationship } from './ACLServiceAccountWorkspaces';
import { AccountType, AccountTypeWithInheritance, ACLAccountStatuses } from './types';
import { EuiComboBoxOptionOption } from '@tecton';
import { PrincipalType } from '../../types/tecton_proto/auth/principal';
import { ResourceType } from '../../types/tecton_proto/auth/resource';
import { logEvent } from '../../utils/analytics-utils';
import { Routes as AppRoutes } from '../../core/routes';
import { FeatureFlags, useUserSettings } from '../context/UserSettingsContext';
import { getAllRolesRecords, getRoles } from './util';
import { LoadingIndicator } from '../LoadingIndicator';
import { SelectableGroupOption } from './modals/UserAddToGroupModal';
import {
  PrincipalGroupDetailed,
  PrincipalGroupForPrincipal,
} from '../../types/tecton_proto/principal/principal_service';

export const ACLServiceAccountProfileContext = createContext<any>(null);

export const ACLServiceAccountProfileContextProvider = ({ children }: { children: ReactNode }) => {
  const { serviceAccount: serviceAccountParam } = useParams();
  const navigate = useNavigate();
  const toastContext = useContext(ToastContext);
  const deleteServiceAccountMutation = useDeleteServiceAccount();
  const { data: rolesData, isLoading: isLoadingRolesData } = useGetRoles();

  const { featureEnabled } = useUserSettings();

  // Get a single service account in question from the params
  const { data: serviceAccountData, isLoading: serviceAccountIsLoading } = useGetServiceAccountByIds([
    serviceAccountParam as string,
  ]);

  const { data: groups, isLoading: isLoadingGroups } = useGetGroups({});

  // States we pass to the components
  const [serviceAccount, setServiceAccount] = useState<ServiceAccount | undefined>();
  const [serviceAccountDescription, setServiceAccountDescription] = useState<string | undefined>();
  const [serviceAccountName, setServiceAccountName] = useState<string | undefined>();
  const [accountType, setAccountType] = useState<AccountTypeWithInheritance | undefined>();
  const [status, setStatus] = useState<ACLAccountStatuses | undefined>();
  const [assignedWorkspaces, setAssignedWorkspaces] = useState<ACLWorkspaceServiceRelationship[] | undefined>();
  const [assignedWorkspaceFilter, setAssignedWorkspaceFilter] = useState<string | undefined>();
  const [errorMessages, setErrorMessages] = useState<CalloutMessageType[] | undefined>();
  const [selectedCandidateGroups, setSelectedCandidateGroups] = useState<SelectableGroupOption[] | undefined>(
    undefined
  );

  const [groupSearch, setGroupSearch] = useState<string | undefined>();

  const [selectedWorkspace, setSelectedWorkspace] = useState<
    EuiComboBoxOptionOption<string>[] | ACLWorkspaceServiceRelationship
  >([]);
  const [selectedRole, setSelectedRole] = useState<string>('');

  // determines which modal to show
  const [modal, setModal] = useState<ACLServiceAccountProfileModal | undefined>();
  const [modalError, setModalError] = useState<CalloutMessageType[] | undefined>();

  // Use to update service accounts: activate/deactivate
  const mutateServiceAccount = useMutateServiceAccount();
  const removePrincipalGroupMembersMutation = useRemovePrincipalGroupMembersMutation();
  const addPrincipalGroupMembersMutation = useAddPrincipalGroupMembersMutation();
  const updateWorkspaceToServiceAccountMutation = useAssignRolesMutation();
  const assignRoleMutation = useAssignRoleMutation();
  const updateAccountTypeToRegularMutation = useUpdateAccountTypeMutation();
  const { user } = useUserSettings();

  // We make this call just to see if our service account in question is an admin.
  // TODO: ask the backend folks to see if we can avoid this call
  const {
    data: adminRoleServiceAccounts,
    isLoading: adminRoleServiceAccountIsLoading,
    isSuccess: adminRoleServiceAccountIsSuccess,
    isRefetching: adminRoleServiceAccountIsRefetching,
  } = useServiceAccountsAssignedPrincipals(!!serviceAccountData);

  // This call gets the assigned workspace for the service account

  const {
    data: assignedWorkspacesData,
    isSuccess: assignedWorkspacesIsSuccess,
    isLoading: assignedWorkspacesIsLoading,
    isRefetching: assignedWorkspacesIsRefetching,
  } = useGetServiceAccountAssignedWorkspace(serviceAccountParam);

  // Group Membership
  const { data: listPrincipalGroupsData, isLoading: isLoadingListPrincipalGroups } = useListPrincipalGroupsForPrincipal(
    serviceAccount?.id as string,
    PrincipalType.PRINCIPAL_TYPE_SERVICE_ACCOUNT,
    true
  );

  const { data: principalAuthorizedResourceData } = useGetAuthorizedResources({
    resource_type: ResourceType.RESOURCE_TYPE_SERVICE_ACCOUNT,
    action: 'manage_service_account',
    principal_type: PrincipalType.PRINCIPAL_TYPE_USER,
    principal_id: user?.sub,
  });

  const authorizedResources = principalAuthorizedResourceData?.authorized_resources ?? [];
  const authorizedServiceAccounts = authorizedResources?.map((sa) => sa.resource_id);
  const isOwner = authorizedServiceAccounts?.includes(serviceAccountParam);

  const serviceAccountRoles = getRoles(rolesData?.roles, PrincipalType.PRINCIPAL_TYPE_SERVICE_ACCOUNT);
  const newServiceAccountRoleOptions = serviceAccountRoles.map((saRole) => {
    return saRole.id;
  });

  const serviceAccountRoleOptions = [...newServiceAccountRoleOptions, 'none'];

  const allRoleRecords = getAllRolesRecords(serviceAccountRoles);

  const { data: workspaces } = useListWorkspaces({
    select: (data: ListWorkspacesResponse) => data?.workspaces?.map((ws) => ws.name),
  });

  const assignments = assignedWorkspacesData?.assignments ?? [];
  const listWorkspaces = workspaces ?? [];

  const workspaceIds = assignments?.map((assignment) => {
    return assignment.resource_id;
  });
  const unassignedWorkspaces = listWorkspaces.filter((workspace) => {
    return !workspaceIds.includes(workspace);
  });

  const unAssignedWorkspaces = unassignedWorkspaces.map((workspace): { label: string } => {
    return {
      label: `${workspace}`,
    };
  });

  const onError = (error: any) => {
    const errorMessage = error?.response?.data?.message ?? error?.response?.statusText;

    setModalError([
      {
        title: String(errorMessage),
        color: 'danger',
        iconType: 'alert',
      },
    ]);
  };

  // Deactivate a service account
  const deprovision = () => {
    if (serviceAccount !== undefined) {
      //deprovisionConfirmationModalState.setIsLoading(true);
      const { id, name, description } = serviceAccount;

      const deprovisionServiceAccount = { id, name, description, is_active: false };
      mutateServiceAccount.mutate(deprovisionServiceAccount, {
        onSuccess: (data: UpdateServiceAccountResponse) => {
          setServiceAccount(data);
          setModal(undefined);
          setModalError(undefined);
          if (toastContext) {
            toastContext?.dispatchToast(
              addToast({
                title: 'Service Account Deactivated',
                text: `The service account ${serviceAccount?.name} has been deactivated.`,
                iconType: 'lock',
                color: 'success',
              })
            );
          }

          logEvent('Account & Access: Deprovision', '', {
            serviceAccountName: serviceAccount?.name,
            serviceAccountId: serviceAccount?.id,
          });
        },
        onError,
      });
    }
  };

  const reactivate = () => {
    if (serviceAccount !== undefined) {
      const { id, name, description } = serviceAccount;
      const deprovisionServiceAccount = { id, name, description, is_active: true };
      mutateServiceAccount.mutate(deprovisionServiceAccount, {
        onSuccess: (data: UpdateServiceAccountResponse) => {
          // Update Service Account so UI get the latest state
          setServiceAccount(data);
          setModal(undefined);
          setModalError(undefined);

          // Show the user a Toast for confirmation
          toastContext?.dispatchToast(
            addToast({
              title: 'Service Account Activated',
              text: `The service account '${serviceAccount?.name}' has been activated.`,
              iconType: 'lock',
              color: 'success',
            })
          );

          logEvent('Account & Access: Activate', '', {
            serviceAccountName: serviceAccount?.name,
            serviceAccountId: serviceAccount?.id,
          });
        },
        onError,
      });
    }
  };

  const onSuccessServiceAccountUpdate = (
    data: UpdateServiceAccountResponse,
    onSuccess: () => void,
    title: string,
    text: string
  ) => {
    // Update Service Account so UI get the latest state
    setServiceAccount(data);
    setServiceAccountName(data?.name);
    setModal(undefined);
    setModalError(undefined);

    // Show the user a Toast for confirmation
    toastContext?.dispatchToast(
      addToast({
        title,
        text,
        iconType: 'lock',
        color: 'success',
      })
    );

    logEvent('Account & Access: Reactivate', '', {
      serviceAccountName: serviceAccount?.name,
      serviceAccountId: serviceAccount?.id,
    });

    onSuccess();
  };

  const updateDescription = (description: string, onSuccess: () => void, onErrorCallback: (error: string) => void) => {
    if (serviceAccount !== undefined) {
      const { id, is_active, name } = serviceAccount;
      const deprovisionServiceAccount = { id, is_active, name, description };
      mutateServiceAccount.mutate(deprovisionServiceAccount, {
        onSuccess: (data: UpdateServiceAccountResponse) => {
          onSuccessServiceAccountUpdate(data, onSuccess, 'Service Account Description Updated to', `${description}`);
          logEvent('Account & Access: Update Description', '', {
            serviceAccountName: serviceAccount?.name,
            serviceAccountId: serviceAccount?.id,
            description,
          });
        },
        onError: (error: any) => {
          onError(error);
          onErrorCallback(error?.response?.data?.message);
        },
      });
    }
  };

  const updateName = (name: string, onSuccess: () => void, onErrorCallback: (error: string) => void) => {
    if (serviceAccount !== undefined) {
      const { id, is_active, description } = serviceAccount;
      const deprovisionServiceAccount = { id, is_active, description, name };
      mutateServiceAccount.mutate(deprovisionServiceAccount, {
        onSuccess: (data: UpdateServiceAccountResponse) => {
          onSuccessServiceAccountUpdate(data, onSuccess, 'Service Account Name Updated to', `${name}`);
          logEvent('Account & Access: Update Name', '', {
            serviceAccountName: serviceAccount?.name,
            serviceAccountId: serviceAccount?.id,
            name,
          });
        },
        onError: (error: any) => {
          onError(error);
          onErrorCallback(error?.response?.data?.message);
        },
      });
    }
  };

  const deleteServiceAccount = () => {
    if (serviceAccount !== undefined) {
      deleteServiceAccountMutation.mutate(
        { id: serviceAccount.id },
        {
          onSuccess: () => {
            setModal(undefined);
            setModalError(undefined);
            navigate(`${AppRoutes.accountsAndAccessServiceAccounts}`);
            toastContext?.dispatchToast(
              addToast({
                title: 'Service Account Deleted',
                text: `The service account '${serviceAccount?.name}' has been deleted.`,
                iconType: 'lock',
                color: 'success',
              })
            );
            logEvent('Account & Access: Delete Service Account', '', {
              serviceAccountName: serviceAccount?.name,
              serviceAccountId: serviceAccount?.id,
            });
          },
          onError,
        }
      );
    }
  };

  const editWorkspaceForServiceAccountRole = async (onSuccess: () => void) => {
    /**
     *
     Handles both adding, modifying and removing.
     */
    const principal_id = serviceAccountParam;
    const principal_type = PrincipalType.PRINCIPAL_TYPE_SERVICE_ACCOUNT;
    // selecting 'none' means we will remove it from the lists
    const removingRole = selectedRole === 'None';
    const resource_id = getResourceId(selectedWorkspace);
    const isAllWorkspaces = resource_id === 'All Workspaces';

    const resource_type = isAllWorkspaces
      ? ResourceType.RESOURCE_TYPE_ORGANIZATION
      : ResourceType.RESOURCE_TYPE_WORKSPACE;

    const role = getLegacyRoleId(serviceAccountRoles, selectedRole);
    const roles = removingRole ? [] : [role];

    // make sure account type is stays in place if there were admin before
    if (isAllWorkspaces && accountType?.effectiveAccountType === 'Admin') {
      roles.unshift('admin_role');
    }

    const title =
      ACLServiceAccountProfileModal.ADD_WORKSPACE === modal
        ? `Service Account added to Workspace`
        : removingRole
        ? `Service Account removed from Workspace`
        : `Service Account modified`;

    const text =
      ACLServiceAccountProfileModal.ADD_WORKSPACE === modal
        ? `${principal_id} has been added to ${resource_id} as ${selectedRole}.`
        : removingRole
        ? `${resource_id}`
        : `${principal_id} has been granted ${selectedRole} role on ${resource_id}.`;

    const payload = {
      principal_id,
      roles,
      ...(!isAllWorkspaces && { resource_id }),
      principal_type,
      resource_type,
    };

    updateWorkspaceToServiceAccountMutation.mutate(payload, {
      onSuccess: () => {
        // Clean up default values
        setSelectedWorkspace([]);
        setSelectedRole('');

        setModalError(undefined);
        setModal(undefined);

        // Send toaster to the user
        toastContext?.dispatchToast(
          addToast({
            title,
            text,
            iconType: 'lock',
            color: 'success',
          })
        );

        logEvent('Account & Access: Update Workspace Role', '', {
          principal_id,
          roles: roles.join(','),
          resource_id,
          principal_type,
          resource_type,
        });

        onSuccess();
      },
      onError,
    });
  };

  const updateServiceAccountType = (accountType: AccountType, onSuccessCallback: (type: AccountType) => void) => {
    const principal_id = serviceAccountParam;

    const payload = {
      assignments: [
        {
          principal_id,
          role: 'admin_role',
          principal_type: PrincipalType.PRINCIPAL_TYPE_SERVICE_ACCOUNT,
          resource_type: ResourceType.RESOURCE_TYPE_ORGANIZATION,
        },
      ],
    };

    const toastAndLog = (text: AccountType) => {
      // Send toaster to the user
      toastContext?.dispatchToast(
        addToast({
          title: 'Account Type updated to',
          text,
          iconType: 'lock',
          color: 'success',
        })
      );
      //setAccountType(text);
      onSuccessCallback(text);

      // hide the modal
      setModal(undefined);
      setModalError(undefined);

      logEvent('Account & Access: Update Service Account Type', '', {
        principal_id,
        role: text,
      });
    };

    if (accountType === 'Admin') {
      assignRoleMutation.mutate(payload, {
        onSuccess: () => {
          toastAndLog(accountType);
        },
        onError,
      });
    } else {
      // Delete role
      updateAccountTypeToRegularMutation.mutate(payload, {
        onSuccess: () => {
          toastAndLog(accountType);
        },
        onError,
      });
    }
  };

  const confirmRemoveServiceAccountFromGroup = (groupId: string, groupName: string) => {
    const payload = {
      id: `${groupId}`,
      members: [
        { principal_type: PrincipalType.PRINCIPAL_TYPE_SERVICE_ACCOUNT, principal_id: `${serviceAccount?.id}` },
      ],
    };
    removePrincipalGroupMembersMutation.mutate(payload, {
      onSuccess: () => {
        setModal(undefined);

        logEvent('Service Account removed from Group', '', {
          groupId,
          serviceAccountId: serviceAccount?.id,
        });

        toastContext?.dispatchToast(
          addToast({
            title: `Service Account "${serviceAccount?.name}" removed from Group`,
            text: groupName,
            iconType: 'lock',
            color: 'success',
          })
        );
      },
      onError,
    });
  };

  const confirmAddMembers = (principalGroupDetailed: PrincipalGroupDetailed) => {
    const selectedGroup = selectedCandidateGroups?.filter((item: SelectableGroupOption) => {
      return item.checked === 'on';
    })[0];

    const userMembersToAdd = [
      { principal_type: PrincipalType.PRINCIPAL_TYPE_SERVICE_ACCOUNT, principal_id: `${serviceAccount?.id}` },
    ];

    const payload = {
      principal_group_id: selectedGroup?.group?.groupId,
      members: userMembersToAdd,
    };

    addPrincipalGroupMembersMutation.mutate(payload, {
      onSuccess: () => {
        const text = `${selectedGroup?.label}`;
        const userText = `${serviceAccount?.name}`;
        logEvent(`${userText} added to group`, '', {
          groupId: principalGroupDetailed?.id,
          text,
        });
        toastContext?.dispatchToast(
          addToast({
            title: `${userText} added to group`,
            text,
            iconType: 'lock',
            color: 'success',
          })
        );
      },
      onError,
      onSettled: () => {
        setModal(undefined);
        setSelectedCandidateGroups(undefined);
      },
    });
  };

  useMemo(() => {
    if (serviceAccountData && assignedWorkspacesData && adminRoleServiceAccountIsSuccess) {
      const sa = Array.isArray(serviceAccountData?.service_accounts)
        ? serviceAccountData?.service_accounts[0]
        : undefined;

      const status = sa?.is_active ? 'active' : 'inactive';

      if (!serviceAccount) {
        setServiceAccount(sa);
      }

      if (serviceAccountDescription === undefined) {
        setServiceAccountDescription(sa?.description);
      }

      setServiceAccountName(sa?.name);
      setStatus(status);

      if (sa && adminRoleServiceAccounts?.assignments) {
        const assignments = adminRoleServiceAccounts?.assignments ?? [];
        const serviceAccountAssignment = assignments?.find((assignment) => {
          return assignment?.principal?.service_account?.id === sa?.id;
        });
        const adminRoleAssignmentSources = getAdminRoleAssignmentSources(serviceAccountAssignment?.role_assignments);
        const assignedAccountType: AccountType = getAssignedAccountType(serviceAccountAssignment?.role_assignments);
        const effectiveAccountType = getEffectiveAccountType(
          assignedAccountType === 'Admin',
          adminRoleAssignmentSources
        );

        const sortedInheritedAccountTypes = getInheritedAccountTypes(adminRoleAssignmentSources);
        const accountType = {
          effectiveAccountType: effectiveAccountType,
          assignedAccountType,
          inheritedAccountType: sortedInheritedAccountTypes,
        };

        setAccountType(accountType);
      }
    }
  }, [
    serviceAccountData,
    assignedWorkspacesIsSuccess,
    adminRoleServiceAccounts,
    adminRoleServiceAccountIsSuccess,
    adminRoleServiceAccountIsRefetching,
  ]);

  useMemo(() => {
    // When the user start typing in the workspace filter text field. We need ot update assignedWorkspaces
    if (assignedWorkspacesIsSuccess) {
      if (assignedWorkspacesData?.assignments) {
        const filteredAssignments = filterAssignedWorkspace(
          assignedWorkspaceFilter,
          assignedWorkspacesData?.assignments
        );

        const aclRoleWithInheritance = transformACLRole(filteredAssignments, serviceAccountRoles);
        setAssignedWorkspaces(aclRoleWithInheritance);
      }
    }
  }, [assignedWorkspacesData, assignedWorkspaceFilter, assignedWorkspacesIsRefetching, assignedWorkspacesIsSuccess]);

  const updateSelectedRole = (role: string) => {
    setSelectedRole(role);
  };

  // If something is selected from a candidate, use it. This helps with check marks for the modal options
  const candidateGroup =
    selectedCandidateGroups ??
    groups?.map((g) => {
      const { groupId: groupId, name, members_count: membersCount } = g;
      return {
        hasPriority: false,
        groupId,
        membersCount,
        label: name,
        group: g,
      };
    });

  const groupsTable =
    listPrincipalGroupsData?.groups
      ?.filter((group: PrincipalGroupForPrincipal) => {
        if (groupSearch) {
          return group?.group?.name?.toLowerCase().includes(groupSearch?.toLowerCase());
        }
        return true;
      })
      ?.map((i) => i.group) ?? []; // Needs to be filtered with candidate groups

  const workspaceCount = getWorkspaceCount(assignedWorkspaces ?? []);

  const isLoading =
    (isLoadingGroups && isLoadingListPrincipalGroups) ||
    (!serviceAccount && assignedWorkspaces === undefined) ||
    isLoadingRolesData ||
    assignedWorkspacesIsLoading ||
    adminRoleServiceAccountIsLoading;

  return (
    <ACLServiceAccountProfileContext.Provider
      value={{
        serviceAccount,
        serviceAccountName,
        serviceAccountDescription,
        accountType,
        setAccountType,
        serviceAccountIsLoading,
        status,
        assignedWorkspaces,
        setAssignedWorkspaceFilter,
        deprovision,
        reactivate,
        deleteServiceAccount,
        modal,
        setModal,
        setModalError,
        isModalLoading:
          mutateServiceAccount.isLoading ||
          updateWorkspaceToServiceAccountMutation.isLoading ||
          addPrincipalGroupMembersMutation.isLoading ||
          removePrincipalGroupMembersMutation?.isLoading,
        modalError,
        unAssignedWorkspaces,
        selectedWorkspace,
        setSelectedWorkspace,
        editWorkspaceForServiceAccountRole,
        selectedRole,
        setSelectedRole,
        setAssignedWorkspaces,
        updateServiceAccountType,
        updateDescription,
        updateName,
        updateSelectedRole,
        errorMessages,
        setErrorMessages,
        serviceAccountRoles,
        allRoleRecords,
        newServiceAccountRoleOptions,
        serviceAccountRoleOptions,
        candidateGroup,
        groups: groupsTable,
        serviceAccountGroups: listPrincipalGroupsData?.groups ?? [],
        selectedCandidateGroups,
        setSelectedCandidateGroups,
        confirmRemoveServiceAccountFromGroup,
        confirmAddMembers,
        groupSearch,
        setGroupSearch,
        workspaceCount,
        isOwner,
      }}
    >
      {isLoading ? <LoadingIndicator /> : children}
    </ACLServiceAccountProfileContext.Provider>
  );
};
