import React, { ReactNode } from 'react';
import { BasicTable, Code, Icon, useTectonTheme } from '@tecton';
import { css } from '@emotion/css';

import { replaceStringWithReactNode } from '../shared/utils/utils';
import RoleSelectorFormRow, { OptionsInfo } from '../shared/RowSelectorFormRow';
import { Permission } from '../../api/gql/graphql';

export const AccessTableGQL = ({ permissions }: { permissions: Permission[] }) => {
  const { theme } = useTectonTheme();
  const accessList = permissions.map((p) => {
    const TECTON_APPLY = 'tecton apply';
    const TECTON_PLAN = 'tecton plan';
    const description = p.description;
    let permission: string | ReactNode = description;
    const paddingCode = css`
      padding: 0px ${theme.size.xs} 0px ${theme.size.xs};
    `;

    // Replace tecton apply text with a code component for visual styling
    if (description?.includes(TECTON_APPLY)) {
      permission = replaceStringWithReactNode(
        description,
        TECTON_APPLY,
        <span className={paddingCode}>
          <Code>{`${TECTON_APPLY}`}</Code>
        </span>
      );
    }

    if (description?.includes(TECTON_PLAN)) {
      permission = replaceStringWithReactNode(
        description,
        TECTON_PLAN,
        <span className={paddingCode}>
          <Code>{`${TECTON_PLAN}`}</Code>
        </span>
      );
    }

    return {
      permission,
      hasAccess: p.isAuthorized,
    };
  });

  return (
    <BasicTable
      className={css`
        .euiTableHeaderCell > * {
          display: none;
        }
      `}
      items={accessList}
      columns={[
        {
          field: 'hasAccess',
          name: '',
          width: '2em',
          render: (field, row) => {
            return (
              <div style={{ width: '100%', textAlign: 'center' }}>
                {field ? (
                  <Icon type="checkInCircleFilled" color="success" aria-label="has access" />
                ) : (
                  <Icon type="minusInCircleFilled" color="danger" aria-label="no access" />
                )}
              </div>
            );
          },
        },
        {
          field: 'permission',
          name: 'Permission',
          render: (field: React.ReactNode, row) => field,
        },
      ]}
    />
  );
};

const WorkspaceRoleSelectorFormRowGQL = ({
  userId,
  allRoleRecords,
  currentRole,
  selectedRole,
  roleOptions,
  setSelectedRole,
}: {
  userId: string;
  allRoleRecords: OptionsInfo<string>[];
  currentRole?: string | undefined;
  selectedRole?: string | '';
  roleOptions?: string[] | undefined;
  setSelectedRole: (role: string | '') => void;
}) => {
  const roleRecords: OptionsInfo<string>[] = roleOptions
    ? allRoleRecords.filter((r) => {
        return roleOptions.indexOf(r.id) >= 0;
      })
    : allRoleRecords;

  return (
    <RoleSelectorFormRow
      noun="role"
      options={roleRecords}
      userId={userId}
      current={currentRole}
      selected={selectedRole}
      setSelected={setSelectedRole}
    />
  );
};

export default WorkspaceRoleSelectorFormRowGQL;
