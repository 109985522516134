import styled from '@emotion/styled';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

const Crumb = styled.div<{ variant?: BreadCrumbVariant }>`
  color: ${({ theme, variant }) => (variant === 'small' ? theme.colors.subduedText : theme.colors.title)};
  font-size: ${({ theme, variant }) => (variant === 'small' ? theme.font.fontSizes.xs : theme.font.fontSizes.s)};
  font-weight: ${({ theme, variant }) => (variant === 'small' ? theme.font.weight.regular : theme.font.weight.medium)};
  line-height: ${({ theme }) => theme.font.lineHeights.s};
  cursor: default;
  border-radius: ${({ theme }) => theme.border.radius.medium}; // custom per design spec

  padding: 0px ${({ theme, variant }) => (variant === 'small' ? '0px' : theme.border.radius.medium)};

  a:hover {
    text-decoration: none;
  }

  :hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.body};
    text-decoration: none;
    align-items: center;
    text-decoration: none !important;
  }

  div,
  a div, a {
      font-weight: ${({ theme }) => theme.font.weight.medium};
    ${({ theme, variant }) => {
      if (variant === 'small') return `color: ${theme.colors.subduedText}`;
    }}
  }}
`;

const BreadcrumbsWrapper = styled.div`
  display: flex;
  align-items: center;
  overflow: auto;
  white-space: nowrap;
`;

export type BreadCrumbVariant = 'standard' | 'small';

interface IntermediateBreadcrumbProps {
  label: React.ReactNode;
  url?: string;
  variant?: BreadCrumbVariant;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

export const IntermediateBreadcrumb: FC<IntermediateBreadcrumbProps> = ({ label, url, variant, onClick: onClick }) => {
  if (url) {
    return (
      <Link to={url} onClick={onClick}>
        <Crumb data-testid="crumb-middle" variant={variant}>
          {label}
        </Crumb>
      </Link>
    );
  }
  return (
    <Crumb data-testid="crumb-middle" variant={variant}>
      {label}
    </Crumb>
  );
};

export interface TectonBreadcrumbsProps {
  workspace?: React.ReactNode;
  variant?: BreadCrumbVariant;
  root?: string;
  crumbs: { label: React.ReactNode; url?: string }[];
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

export const Separator = () => {
  // Ultimately, this will need to come from the (to-be-finalized) icon library
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.33337 13.3337L11.6667 10.0003L8.33337 6.66699"
        stroke="#868C98"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
const TectonBreadcrumbs: FC<TectonBreadcrumbsProps> = ({ workspace, variant = 'standard', root, crumbs, onClick }) => {
  const renderedCrumbs = [
    crumbs.map((crumb, index) => {
      if (index !== crumbs.length - 1) {
        return (
          <>
            <IntermediateBreadcrumb label={crumb.label} url={crumb.url} variant={variant} onClick={onClick} />
            <Separator />
          </>
        );
      }

      return (
        <>
          {crumb.url ? (
            <>
              <Link to={crumb?.url} onClick={onClick}>
                <Crumb data-testid="crumb-current" variant={variant}>
                  {crumb.label}
                </Crumb>
              </Link>
            </>
          ) : (
            <>
              <Crumb data-testid="crumb-current" variant={variant}>
                {crumb.label}
              </Crumb>
            </>
          )}
        </>
      );
    }),
  ];

  return (
    <BreadcrumbsWrapper data-testid="view-breadcrumbs">
      {workspace && <Crumb variant={variant}>{workspace}</Crumb>}

      {root && <Crumb variant={variant}>{root}</Crumb>}

      {crumbs.length > 0 && (
        <>
          <Separator />
          {renderedCrumbs}
        </>
      )}
    </BreadcrumbsWrapper>
  );
};

export default TectonBreadcrumbs;
