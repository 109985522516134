/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC } from 'react';
import { AnyFCO, FCOType } from '../../../../core/types/fcoTypes';
import DataSourceIcon from '../../DataSourceIcon';
import DatasetIcon from '../../DatasetIcon';
import EntityIcon from '../../EntityIcon';
import FeatureServiceIcon from '../../FeatureServiceIcon';
import FeatureViewIcon from '../../FeatureViewIcon';
import TransformationIcon from '../../TransformationIcon';
import {
  CategoricalFilterProps,
  DateFilterProps,
  FilterCountsOptions,
  FilterPropsOptions,
  FilterableTableColumn,
  FilterableTableFilterType,
  FilterableTableSort,
  FilterableTableSortMap,
  NumericalFilterProps,
} from './types';
import { EuiBasicTableColumn } from '@elastic/eui';
import { castAsCategoryOptions, getActiveNumberOfOptionsForFilter, getTotalNumberOfOptionsForFilter } from './utils';
import { clone } from 'lodash';
import { FilterSelector } from './ComboFilterDropdown';
import { CategoricalSelectorHierarchicalOption } from '../../CategoricalSelectorHierarchical';
import CategoricalSelectorCheckList, { FilterOptionType } from './CategoricalSelectorChecklist';
import CategoricalSelectorHierarchical from './CategoricalSelectorHierarchical';
import DateSelectorFilter from './DateSelectorFilter';
import StatelessNumericSelector from './StatelessNumericSelector';
import { useTectonTheme } from '../Theme/ThemeProvider';
import { Tooltip } from '@tecton/ComponentRedesign';
import styled from '@emotion/styled';
import Icons, { IconTypes } from '../Icons/Icon';

export const typeIconMap: Record<FCOType, React.ReactNode> = {
  [FCOType.DATA_SOURCE]: Icons[IconTypes.SOURCES],
  [FCOType.ENTITY]: Icons[IconTypes.ENTITIES],
  [FCOType.TRANSFORMATION]: Icons[IconTypes.TRANSFORMATIONS],
  [FCOType.FEATURE_VIEW]: Icons[IconTypes.FEATURE_VIEWS],
  [FCOType.FEATURE_SERVICE]: Icons[IconTypes.FEATURE_SERVICE],
  [FCOType.DATASET]: Icons[IconTypes.DATASET],
  [FCOType.UNKNOWN]: <></>,
};

export const FilterForFCOListDateRangeSelector = (
  column: FilterableTableColumn,
  filterState: Record<string, FilterPropsOptions>,
  setFilterState: React.Dispatch<React.SetStateAction<Record<string, FilterPropsOptions>>>
) => {
  const dateRange = filterState[column.key] as [Date, Date];
  const filterProps = column?.filterProps as DateFilterProps;

  if (
    dateRange[0] === undefined ||
    dateRange[1] === undefined ||
    filterProps?.dateRange?.filter((i) => !!i).length < 2 // make sure filterProps?.dateRange is not null or undefined.
  ) {
    return <></>; // Prevents errors when no valid dates
  }

  const limitBounds = (column.filterProps as DateFilterProps).dateRange;
  if (limitBounds[0].getTime() === limitBounds[1].getTime()) {
    limitBounds[1] = new Date(limitBounds[1].getTime() + 8400); // Ensure that the dates aren't the exact same value
    // This would prevent the date ranges from displaying correctly
    // This may warrant further UX consideration
  }

  return (
    <DateSelectorFilter
      startDate={dateRange[0]}
      endDate={dateRange[1]}
      setStartDate={(date) => {
        setFilterState({
          ...filterState,
          [column.key]: [date, dateRange[1]],
        });
      }}
      setEndDate={(date) => {
        setFilterState({
          ...filterState,
          [column.key]: [dateRange[0], date],
        });
      }}
    />
  );
};

export const FilterForNumericSelector = (
  column: FilterableTableColumn,
  filterState: Record<string, FilterPropsOptions>,
  setFilterState: React.Dispatch<React.SetStateAction<Record<string, FilterPropsOptions>>>
) => {
  const asRange = (column.filterProps as NumericalFilterProps).selectorValue;
  return (
    <StatelessNumericSelector
      key={`selector_${column.key}`}
      label={column.label}
      step={1}
      min={asRange[0]}
      max={asRange[1]}
      selectorValue={filterState[column.key] as [number, number]}
      changeCallback={(newRange: [number, number]) => {
        setFilterState({
          ...filterState,
          [column.key]: newRange,
        });
      }}
    />
  );
};

export const FilterForTagsSelector = (
  column: FilterableTableColumn,
  filterState: Record<string, FilterPropsOptions>,
  setFilterState: React.Dispatch<React.SetStateAction<Record<string, FilterPropsOptions>>>
) => {
  const categoryItems = filterState[column.key] as CategoricalSelectorHierarchicalOption[];
  const changeCallback = (selectedOption: CategoricalSelectorHierarchicalOption) => {
    if (selectedOption.parent) {
      updateFilterStateForSelectedChild(column, filterState, setFilterState, selectedOption);
    } else {
      updateFilterStateForSelectedParent(column, filterState, setFilterState, selectedOption);
    }
  };
  const resetCallback = () => {
    resetHierarchicalFilters(column, filterState, setFilterState);
  };
  if (categoryItems) {
    return (
      <CategoricalSelectorHierarchical
        options={categoryItems}
        changeCallback={changeCallback}
        resetCallback={resetCallback}
        showIcons={false}
      />
    );
  }
  return <></>;
};

const TableColumnHeading: FC<{
  column: FilterableTableColumn;
  sortParams: any;
  setSortParams: any;
}> = ({ column, sortParams, setSortParams }) => {
  const { theme } = useTectonTheme();

  const StyledTrigger = styled.div`
    color: ${sortParams.key === column.key ? theme.colors.title : theme.colors.text};
    font-weight: ${sortParams.key === column.key ? theme.font.weight.semiBold : theme.font.weight.regular};
    display: flex;
    align-items: center;
    gap: ${theme.padding.s};

    ${sortParams.key !== column.key &&
    `:hover {
      font-weight: ${theme.font.weight.medium};
      color: ${theme.colors.title};
    }`}
  `;

  const indicatorArrowUp = (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16 14L12 10L8 14" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );

  const indicatorArrowDown = (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16 10L12 14L8 10" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );

  const indicatorArrow = sortParams.order === 'asc' ? indicatorArrowUp : indicatorArrowDown;

  const trigger = (
    <StyledTrigger
      onClick={() => {
        let sortOrder = 'asc';
        if (sortParams.key === column.key) {
          sortOrder = sortParams.order === 'asc' ? 'desc' : 'asc';
        }

        setSortParams({
          key: column.key,
          order: sortOrder,
          type: column.filterType ? FilterableTableSortMap[column.filterType] : undefined,
        });
      }}
    >
      <div>{column.label}</div>
      {sortParams.key === column.key && indicatorArrow}
    </StyledTrigger>
  );
  return <Tooltip content={column.tooltip ?? ''} trigger={<>{trigger}</>} />;
};

export const getFCOListColumns = (
  allColumns: FilterableTableColumn[],
  activeColumns: string[],
  sortParams: FilterableTableSort,
  setSortParams: React.Dispatch<React.SetStateAction<FilterableTableSort>>
) => {
  const columnsToDisplay = allColumns.filter((column: FilterableTableColumn) => {
    if (column.isAlwaysActive === true) {
      return true;
    }
    return activeColumns.includes(column.key);
  });

  const tableColumns: EuiBasicTableColumn<AnyFCO>[] = columnsToDisplay.map((column) => {
    return {
      name: <TableColumnHeading column={column} sortParams={sortParams} setSortParams={setSortParams} />,
      field: column.key,
      render: column.tableCellRender,
    };
  });

  return { tableColumns, allColumns };
};

export const FilterForCategoricalSelector = (
  column: FilterableTableColumn,
  filterState: Record<string, FilterPropsOptions>,
  setFilterState: React.Dispatch<React.SetStateAction<Record<string, FilterPropsOptions>>>
) => {
  const categoryItems = filterState[column.key];

  return (
    <CategoricalSelectorCheckList
      items={categoryItems as FilterOptionType[]}
      name={column.key}
      key={`selector_${column.key}`}
      searchable
      clearTextLabel="Reset"
      renderOption={(option) => {
        const rendered = (column.filterProps as CategoricalFilterProps).renderProps ? (
          (column.filterProps as CategoricalFilterProps).renderProps!(option, '')
        ) : (
          <>{option.label}</>
        );

        return rendered;
      }}
      showSelectAll={false}
      setItems={(items: FilterPropsOptions) => {
        setFilterState({
          ...filterState,
          [column.key]: items,
        });
      }}
    />
  );
};

export const FilterForFCOSelector = (
  column: FilterableTableColumn,
  filterState: Record<string, FilterPropsOptions>,
  setFilterState: React.Dispatch<React.SetStateAction<Record<string, FilterPropsOptions>>>
) => {
  const categoryItems = filterState[column.key];

  return (
    <CategoricalSelectorCheckList
      items={categoryItems as FilterOptionType[]}
      name={column.key}
      key={`selector_${column.key}`}
      searchable
      clearTextLabel="Reset"
      renderOption={(option) => {
        const rendered = (column.filterProps as CategoricalFilterProps).renderProps ? (
          (column.filterProps as CategoricalFilterProps).renderProps!(option, '')
        ) : (
          <>{option.label}</>
        );

        return rendered;
      }}
      showSelectAll={false}
      setItems={(items: FilterPropsOptions) => {
        setFilterState({
          ...filterState,
          [column.key]: items,
        });
      }}
    />
  );
};

export const FilterForCountsSelector = (
  column: FilterableTableColumn,
  filterState: Record<string, FilterPropsOptions>,
  setFilterState: React.Dispatch<React.SetStateAction<Record<string, FilterPropsOptions>>>
) => {
  const categoryItems = filterState[column.key];

  const options =
    categoryItems ??
    castAsCategoryOptions([
      FilterCountsOptions.ZERO,
      FilterCountsOptions.ONE,
      FilterCountsOptions.TWO,
      FilterCountsOptions.THREE_TO_FIVE,
      FilterCountsOptions.SIX_TO_TEN,
      FilterCountsOptions.MORE_THAN_TEN,
    ]);

  return (
    <CategoricalSelectorCheckList
      items={options as FilterOptionType[]}
      name={column.key}
      key={`selector_${column.key}`}
      searchable
      clearTextLabel="Reset"
      renderOption={(option) => {
        const rendered = (column.filterProps as CategoricalFilterProps).renderProps ? (
          (column.filterProps as CategoricalFilterProps).renderProps!(option, '')
        ) : (
          <>{option.label}</>
        );

        return rendered;
      }}
      showSelectAll={false}
      setItems={(items: FilterPropsOptions) => {
        setFilterState({
          ...filterState,
          [column.key]: items,
        });
      }}
    />
  );
};

export const FilterForBooleanSelector = (
  column: FilterableTableColumn,
  filterState: Record<string, FilterPropsOptions>,
  setFilterState: React.Dispatch<React.SetStateAction<Record<string, FilterPropsOptions>>>
) => {
  const categoryItems = filterState[column.key];

  return (
    <CategoricalSelectorCheckList
      items={categoryItems as FilterOptionType[]}
      name={column.key}
      key={`selector_${column.key}`}
      searchable
      clearTextLabel="Reset"
      renderOption={(option) => {
        const rendered = (column.filterProps as CategoricalFilterProps).renderProps ? (
          (column.filterProps as CategoricalFilterProps).renderProps!(option, '')
        ) : (
          <>{option.label}</>
        );

        return rendered;
      }}
      showSelectAll={false}
      setItems={(items: FilterPropsOptions) => {
        setFilterState({
          ...filterState,
          [column.key]: items,
        });
      }}
    />
  );
};

export const generateFilterElement: (
  column: FilterableTableColumn,
  filterState: Record<string, FilterPropsOptions>,
  setFilterState: React.Dispatch<React.SetStateAction<Record<string, FilterPropsOptions>>>
) => React.ReactNode = (column, filterState, setFilterState) => {
  switch (column.filterType) {
    case FilterableTableFilterType.CATEGORY:
      return FilterForCategoricalSelector(column, filterState, setFilterState);
    case FilterableTableFilterType.FCO:
      return FilterForFCOSelector(column, filterState, setFilterState);
    case FilterableTableFilterType.BOOLEAN:
      return FilterForBooleanSelector(column, filterState, setFilterState);
    case FilterableTableFilterType.DATE:
      return FilterForFCOListDateRangeSelector(column, filterState, setFilterState);
    case FilterableTableFilterType.NUMBER:
      return FilterForNumericSelector(column, filterState, setFilterState);
    case FilterableTableFilterType.TAGS:
      return FilterForTagsSelector(column, filterState, setFilterState);
    case FilterableTableFilterType.COUNT:
      return FilterForCountsSelector(column, filterState, setFilterState);
  }

  return <></>;
};

export const generatePseudoColumn = (column: FilterableTableColumn) => {
  const pseudoColumn = clone(column);
  pseudoColumn.label = '# of ' + pseudoColumn.label;

  pseudoColumn.key = pseudoColumn.key + '_COUNT';

  pseudoColumn.filterType = FilterableTableFilterType.COUNT;

  const pseudoCategoricalProps: CategoricalFilterProps = {
    options: castAsCategoryOptions(['Zero', 'Fewer than 2', 'Fewer than 5', 'Fewer than 10', '10 or more']),
  };
  pseudoColumn.filterProps = pseudoCategoricalProps;

  return pseudoColumn;
};

export const addCountsFilterPseudoColumnsToColumnsArray = (initialColumns: FilterableTableColumn[]) => {
  // Pseudo columns are necessary to include a filter by count element to the filters
  // They are pseudo columns because the context needs to treat them AS IF they were actual table columns, but they aren't actually visible in the table
  // This is necessary because this late design requirement breaks the 1:1 mapping between columns and filters

  const columns = clone(initialColumns);

  // Add pseudo columns for numeric counts of FCOs
  const columnsWithPseudoCounts = columns.filter(
    (column) =>
      (column.filterProps as CategoricalFilterProps) &&
      (column.filterProps as CategoricalFilterProps).allowsFilterByCount
  );

  const pseudoColumns = columnsWithPseudoCounts.map((column) => generatePseudoColumn(column));

  const insertIndices: number[] = pseudoColumns.map((column) => {
    const matchingKey = column.key.replace('_COUNT', '');
    return columns.map((column) => column.key).indexOf(matchingKey) ?? -1;
  });

  // Shuffle these back in place
  // We want to maintain the order of these columns, so we can't just concatenate here
  pseudoColumns.forEach((column, index) => {
    columns.splice(insertIndices[index] + index, 0, column);
  });

  return columns;
};

export const defineFilterTypes = (
  columns: FilterableTableColumn[],
  filterState: Record<string, FilterPropsOptions>,
  setFilterState: React.Dispatch<React.SetStateAction<Record<string, FilterPropsOptions>>>
) => {
  const filterTypes: Record<string, FilterSelector> = {};

  columns.forEach((column) => {
    if (!column.filterType) {
      return;
    }

    const currentColumnState = filterState[column.key];
    const numberOfActiveFilters = getActiveNumberOfOptionsForFilter(column, currentColumnState);
    const hasActiveFilters = numberOfActiveFilters !== undefined && numberOfActiveFilters !== 0;

    filterTypes[column.key] = {
      label: column.label,
      filterElement: generateFilterElement(column, filterState, setFilterState),
      numActiveFilters: numberOfActiveFilters,
      hasActiveFilters: hasActiveFilters,
      numFilters: getTotalNumberOfOptionsForFilter(column),
    };
  });

  return filterTypes;
};

export const updateFilterStateForSelectedChild = (
  column: FilterableTableColumn,
  filterState: Record<string, FilterPropsOptions>,
  setFilterState: React.Dispatch<React.SetStateAction<Record<string, FilterPropsOptions>>>,
  selectedOption: CategoricalSelectorHierarchicalOption
) => {
  let newState = clone(filterState[column.key]) as CategoricalSelectorHierarchicalOption[];
  const matchingIndex = newState.findIndex((item) => item.label === selectedOption.label && item.parent);
  newState[matchingIndex] = selectedOption;

  newState = setParentStateBasedOnChildren(selectedOption, newState);

  const totalActive = newState.filter((item) => item.checked === 'on').length;
  if (totalActive === 0) {
    resetHierarchicalFilters(column, filterState, setFilterState);
  } else {
    setFilterState({
      ...filterState,
      [column.key]: newState,
    });
  }
};

export const updateFilterStateForSelectedParent = (
  column: FilterableTableColumn,
  filterState: Record<string, FilterPropsOptions>,
  setFilterState: React.Dispatch<React.SetStateAction<Record<string, FilterPropsOptions>>>,
  selectedOption: CategoricalSelectorHierarchicalOption
) => {
  const newState = (clone(filterState[column.key]) as CategoricalSelectorHierarchicalOption[]).map((item) => {
    if (item.parent === selectedOption.label || (!item.parent && item.label === selectedOption.label)) {
      item.checked = selectedOption.checked;
      delete item.indeterminate;
    }
    return item;
  });

  setFilterState({
    ...filterState,
    [column.key]: newState,
  });
};

export const setParentStateBasedOnChildren = (
  selection: CategoricalSelectorHierarchicalOption,
  filterState: CategoricalSelectorHierarchicalOption[]
) => {
  const setOfAllChildValues = Array.from(
    new Set(filterState.filter((item) => item.parent === selection.parent).map((item) => item.checked))
  );

  const parentIsIndeterminate = setOfAllChildValues.length > 1;
  const parentIsOn = !parentIsIndeterminate && setOfAllChildValues.includes('on');

  const parentIndex = filterState.findIndex((item) => !item.parent && item.label === selection.parent);
  if (parentIndex === -1) {
    return filterState;
  }

  const newFilterState = clone(filterState);
  newFilterState[parentIndex].checked = parentIsOn ? 'on' : 'off';
  newFilterState[parentIndex].indeterminate = parentIsIndeterminate;

  return newFilterState;
};

export const resetHierarchicalFilters = (
  column: FilterableTableColumn,
  filterState: Record<string, FilterPropsOptions>,
  setFilterState: React.Dispatch<React.SetStateAction<Record<string, FilterPropsOptions>>>
) => {
  const newFilterState = clone(filterState[column.key]);

  setFilterState({
    ...filterState,
    [column.key]: (newFilterState as CategoricalSelectorHierarchicalOption[]).map((item) => {
      delete item.checked;
      delete item.indeterminate;
      return item;
    }),
  });
};
