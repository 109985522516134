import React, { FC } from 'react';
import { useTectonTheme } from '@tecton/ComponentRedesign';
import styled from '@emotion/styled';
import { EuiCheckbox } from '@elastic/eui';

interface TectonCheckboxProps {
  checked?: boolean;
  disabled?: boolean;
  setChecked?: React.Dispatch<React.SetStateAction<boolean>>;
  label: React.ReactNode;
  id: string;
  onClick?: () => void;
  indeterminate?: boolean;
}

const TectonCheckbox: FC<TectonCheckboxProps> = ({
  checked,
  label,
  id,
  disabled,
  setChecked,
  onClick,
  indeterminate,
}) => {
  const { theme } = useTectonTheme();

  let checkboxBackground = theme.colors.emptyShade;

  if (checked) {
    checkboxBackground = theme.colors.fullShade;
  }

  if (disabled && checked) {
    checkboxBackground = theme.colors.lightShade;
  }

  const StyledCheckbox = styled(EuiCheckbox)`
    .euiCheckbox__square {
      background-color: ${checkboxBackground} !important;
    }

    .euiCheckbox__input:indeterminate + .euiCheckbox__square {
      border: 2px solid ${theme.colors.fullShade} !important;
    }

    .euiCheckbox__label {
      font-size: ${theme.font.fontSizes.xs};
      color: ${theme.colors.text};
      user-select: none;
      cursor: pointer;
    }

    .euiSelectableListItem-isFocused:not([aria-disabled='true']),
    .euiSelectableListItem:hover:not([aria-disabled='true']) {
    }
  `;

  const ClicKWrap = styled.div`
    cursor: pointer !important;
    border-radius: ${theme.border.radius.medium};

    :hover {
      background-color: ${theme.colors.lightestShade};

      .euiCheckbox__square {
        outline: 2px solid #c7ceda;
      }

      .euiCheckbox__label {
        color: ${theme.colors.text};
        font-weight: ${theme.font.weight.medium};
      }
    }
  `;

  return (
    <ClicKWrap onClick={onClick} data-testid="checkbox">
      <StyledCheckbox
        id={id}
        checked={checked}
        disabled={disabled}
        onChange={(event) => {
          setChecked && setChecked(event.target.checked);
        }}
        label={label}
        indeterminate={indeterminate}
      />
    </ClicKWrap>
  );
};

export default TectonCheckbox;
