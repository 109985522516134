import { Fco } from '../../types/tecton_proto/data/fco';
import {
  TransformationFCO,
  FCOType,
  TransformationFCOFields,
  TransformationFCOMode,
  FCOFields,
} from '../../core/types/fcoTypes';
import { Transformation } from '../../types/tecton_proto/data/transformation';
import { getSharedFCOProperties } from './fcoUtils';

export const transformTransformationProtoToTransformationFCO: (fco: Fco) => TransformationFCO = (fco) => {
  const rawTransformation: Transformation = fco.transformation!;
  const metadata = rawTransformation.fco_metadata!;

  const asTransformationFCO: TransformationFCO = {
    ...getSharedFCOProperties(metadata, rawTransformation.transformation_id),
    fcoType: FCOType.TRANSFORMATION,
    [TransformationFCOFields.MODE]: getMode(rawTransformation),
    [TransformationFCOFields.CODE]: rawTransformation.user_function?.body,
    [TransformationFCOFields.DEPENDENT_FEATURE_VIEWS]: [],
    [TransformationFCOFields.DEPENDENT_FEATURE_SERVICES]: [],
    [FCOFields.PREVENT_DESTROY]: rawTransformation.validation_args?.args?.prevent_destroy,
  };

  return asTransformationFCO;
};

const getMode: (transformation: Transformation) => TransformationFCOMode | undefined = (transformation) => {
  const mode = transformation.transformation_mode;
  if (!mode) {
    return undefined;
  }

  return TransformationFCOMode[mode];
};
