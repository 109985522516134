import React from 'react';
import { Text, ButtonEmpty, Button } from '@tecton';
import { TectonBasicModal, CalloutMessageType } from '@shared';

interface ReactivateConfirmationModalProps {
  principalId: string | undefined;
  isLoading: boolean;
  errorMessages: CalloutMessageType[] | undefined;
  confirmChange: () => void;
  onClose: () => void;
}
const ReactivateConfirmationModal = ({
  principalId,
  isLoading,
  errorMessages,
  confirmChange,
  onClose,
}: ReactivateConfirmationModalProps) => {
  return (
    <TectonBasicModal
      title={<h4>Activate {principalId}?</h4>}
      body={
        <Text>
          <p>
            Are you sure you want to activate <strong>{principalId}</strong>?
          </p>
        </Text>
      }
      actions={
        <>
          <ButtonEmpty onClick={onClose}>Cancel</ButtonEmpty>
          <Button isLoading={isLoading} onClick={confirmChange} fill color="primary">
            Activate
          </Button>
        </>
      }
      calloutMessages={errorMessages}
      onClose={onClose}
    />
  );
};

export default ReactivateConfirmationModal;
