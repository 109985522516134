import { useEuiFontSize, useTectonTheme } from '@tecton';
import { css } from '@emotion/css';

const useTectonFCOEmptyPromptClass = () => {
  const { theme } = useTectonTheme();

  return css`
    .euiTitle {
      ${useEuiFontSize('l')};
      color: ${theme.colors.darkestShade};
    }

    p {
      color: ${theme.colors.mediumShade};
    }
  `;
};

export default useTectonFCOEmptyPromptClass;
