import { rest } from 'msw';

const mswGetHiveMetadata = rest.post('/v1/metadata-service/get-hive-metadata', (req, res, ctx) => {
  if (typeof req.body !== 'string') {
    return res(
      ctx.status(500),
      ctx.json({
        success: false,
        message: 'No request body',
      })
    );
  }

  const requestBody = JSON.parse(req.body);

  if (!requestBody.action) {
    return res(
      ctx.status(500),
      ctx.json({
        success: false,
        message: 'No action specified',
      })
    );
  }

  if (requestBody.action === 'ACTION_LIST_DATABASES') {
    return res(
      ctx.status(200),
      ctx.json({
        databases: { names: ['option-mock-1', 'hahaha-mock-2'] },
        success: true,
      })
    );
  }

  return res(
    ctx.status(500),
    ctx.json({
      success: false,
      message: `Unrecognized action ${requestBody.action}`,
    })
  );
});

export default mswGetHiveMetadata;
