import React from 'react';
import { EuiSelectableOption } from '@elastic/eui';
import { AnyFCO } from '../../../../core/types/fcoTypes';
import { ColumnsOptionType } from '../../../shared/ColumnDisplayControls';
import { FilterOptionType, CategoricalOptionInterface } from '../../CategoricalSelector';
import { FilterSelector } from './ComboFilterDropdown';

export enum FilterCountsOptions {
  ZERO = '0',
  ONE = '1',
  TWO = '2',
  THREE_TO_FIVE = '3 - 5',
  SIX_TO_TEN = '6 - 10',
  MORE_THAN_TEN = '> 10',
}

export interface FilterableSortableTableProps {
  columns: FilterableTableColumn[];
  emptyPrompt: React.ReactNode;
  idToFcoMap?: Record<string, AnyFCO>;
  itemIdToExpandedRowMap?: Record<string, React.ReactNode>;
  rowProps?: any;
  isExpandable?: boolean;
  itemId?: any;
  loadMoreCallback?: (filters: Record<string, FilterPropsOptions>) => void;
}

export interface FilterableSortableTableViewProps<T = any> {
  dateSelectors?: boolean;
  searchPlaceholder: string;
  columns: FilterableTableColumn[];
  items: T[];
  sortParams?: FilterableTableSort;
  emptyPrompt: React.ReactNode;
  idToFcoMap?: Record<string, AnyFCO>;
  itemIdToExpandedRowMap?: Record<string, React.ReactNode>;
  rowProps?: any;
  isExpandable?: boolean;
  itemId?: any;
  name: string;
  searchQueryKey?: string;
  filtersChangedCallback?: (filters: Record<string, FilterPropsOptions>) => void;
  loadMoreCallback?: (filters: Record<string, FilterPropsOptions>) => void;
  isLoading?: boolean;
}

export enum FilterableTableFilterType {
  NUMBER = 'number',
  CATEGORY = 'category',
  DATE = 'date',
  TAGS = 'tags',
  BOOLEAN = 'boolean',
  COUNT = 'count',
  FCO = 'fco',
}

export interface FilterableTableColumn {
  name: React.ReactNode;
  label: string;
  key: string;
  field?: string;
  isAlwaysActive?: boolean;
  isHiddenByDefault?: boolean;
  filterType?: FilterableTableFilterType;
  filterProps?: NumericalFilterProps | CategoricalFilterProps | DateFilterProps | TagFilterProps | BooleanFilterProps;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tableCellRender: (...args: any[]) => React.ReactNode;
  tooltip?: string;
  sortable?: boolean;
}

export interface NumericalFilterProps {
  selectorValue: number[];
  step: number;
}

export interface BooleanFilterProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  renderProps: (option: { label: any }) => JSX.Element;
}

export interface TagFilterProps {
  tags: Record<string, string[]>;
}

export interface CategoricalFilterProps {
  options: FilterOptionType[];
  renderProps?: (option: EuiSelectableOption<CategoricalOptionInterface>, searchValue: string) => React.ReactNode;
  isBoolean?: boolean;
  inlineFilterKey?: string;
  allowsFilterByCount?: boolean;
}

export interface DateFilterProps {
  dateRange: [Date, Date];
}

export interface TableControlsStateProps {
  isColumnControlsOpen: boolean;
  setIsColumnControlsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  columnOptions: ColumnsOptionType[];
  setColumnOptions: (options: ColumnsOptionType[]) => void;
  numberOfSelectedColumnsOptions: number;
  activeColumns: string[];
}

export interface TablePaginationProps {
  activePage: number;
  setActivePage: React.Dispatch<React.SetStateAction<number>>;
  numberOfPages: number;
  setNumberOfPages: React.Dispatch<React.SetStateAction<number>>;
  ITEMS_PER_PAGE: number;
  sortKey: string | undefined;
  setSortKey: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export interface FilterableSortableTableContextProps {
  controlsState: TableControlsStateProps;
  filterTypes: Record<string, FilterSelector>;
  filteredItems: AnyFCO[];
  filterState: Record<string, FilterPropsOptions>;
  setFilterState: React.Dispatch<React.SetStateAction<Record<string, FilterPropsOptions>>>;
  numberOfActiveFilters: number;
  resetAllFilters: () => void;
  resetActiveFilter: (key: string) => void;
  sortParams: FilterableTableSort;
  setSortParams: React.Dispatch<React.SetStateAction<FilterableTableSort>>;
  searchQueryKey: string;
  filtersChangedCallback?: () => void;
}

export interface TablePaginationState {
  activePage: number;
  numberOfPages: number;
  sortKey: string | undefined;
  ITEMS_PER_PAGE: number;
}

export interface FilterConditionItem {
  key: string;
  currentValue: [number, number] | string[] | [Date, Date] | undefined;
  possibleValues: string[] | [number, number] | [Date, Date];
  type: 'number' | 'category' | 'date' | 'string' | undefined;
}

export type FilterPropsOptions =
  | string
  | { label: string; checked?: string }[]
  | [Date, Date]
  | [number, number]
  | { label: string; indent: number; parent?: string; checked?: 'on' | 'off' }[]
  | undefined
  | { label: 'isTrue' | 'isFalse'; checked?: string }[];

export interface Tag {
  key: string;
  value: string;
}

export interface FilterValuesMapByFilterType {
  [FilterableTableFilterType.CATEGORY]: Record<string, string[] | undefined>;
  [FilterableTableFilterType.FCO]: Record<string, string[] | undefined>;
  [FilterableTableFilterType.COUNT]: Record<string, string[] | undefined>;
  [FilterableTableFilterType.DATE]: Record<string, Date[] | undefined>;
  [FilterableTableFilterType.NUMBER]: Record<string, number[] | undefined>;
  [FilterableTableFilterType.BOOLEAN]: Record<string, string[] | undefined>;
  [FilterableTableFilterType.TAGS]: Record<
    string,
    | {
        key: string;
        value: string;
      }[]
    | undefined
  >;
}

export type FilterableTableSortType = 'tags' | 'fcos' | 'date' | 'string' | 'number' | undefined;

export const FilterableTableSortMap: Record<FilterableTableFilterType, FilterableTableSortType> = {
  [FilterableTableFilterType.NUMBER]: 'number',
  [FilterableTableFilterType.CATEGORY]: 'string',
  [FilterableTableFilterType.DATE]: 'date',
  [FilterableTableFilterType.TAGS]: 'tags',
  [FilterableTableFilterType.BOOLEAN]: 'string',
  [FilterableTableFilterType.COUNT]: 'number',
  [FilterableTableFilterType.FCO]: 'fcos',
};

export interface FilterableTableSort {
  key: string | undefined;
  order: 'asc' | 'desc' | undefined;
  type: FilterableTableSortType;
}

export interface TagsFilterOption {
  label: string;
  checked: string;
  option: string;
  parent?: string;
}
