import { useEffect } from 'react';

// Define the hook with TypeScript
export const usePolling = (callback: () => void, interval = 1000): void => {
  useEffect(() => {
    const id = setInterval(callback, interval);

    return () => clearInterval(id); // Cleanup on component unmount
  }, [callback, interval]);
};

export default usePolling;
