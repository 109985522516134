import React, { FC, useContext, useEffect, useState } from 'react';
import { FilterableSortableTableViewProps, FilterableSortableTableProps } from './types';
import FCOListContextProvider, { FCOListContext } from './FCOListContextProvider';
import { useParams } from 'react-router';
import { getFCOListColumns } from './filterComponents';
import { Table, SearchInput, Loading, Button, FullRangePicker } from '@tecton/ComponentRedesign';
import styled from '@emotion/styled';
import ColumnDisplayControls from './ColumnDisplayControls';
import ComboFilterDropdown from './ComboFilterDropdown';
import { Moment } from 'moment-timezone';
import moment from 'moment-timezone';

interface FilterableSortableTableLayoutWrapperProps {
  children: React.ReactNode;
  searchPlaceholder: string;
  dateSelectors?: boolean;
}

export const TableHeaderFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.padding.s};
`;

export const TableSearchInputWrapper = styled.div`
  width: 100%;
`;

export const TableButtonFlex = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.padding.m};
`;

export const TableDatePickerWrapper = styled.div`
  display: flex;
  justify-items: flex-start;
  align-items: center;
  gap: ${({ theme }) => theme.padding.m};
`;

export const TableChangesLabelText = styled.div`
  color: ${({ theme }) => theme.colors.fullShade};
  font-size: ${({ theme }) => theme.font.fontSizes.xs};
  font-weight: ${({ theme }) => theme.font.weight.medium};
  line-height: 20px; /* 166.667% */
`;

export const TableLoadMoreFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${({ theme }) => theme.padding.xl};
`;

const FilterableSortableTableContainer: FC<FilterableSortableTableViewProps> = ({
  dateSelectors,
  searchPlaceholder,
  columns,
  items,
  emptyPrompt,
  idToFcoMap,
  itemIdToExpandedRowMap,
  rowProps,
  isExpandable,
  itemId,
  name,
  searchQueryKey = 'name',
  isLoading = false,
  filtersChangedCallback,
  loadMoreCallback,
}) => {
  return (
    <>
      <FCOListContextProvider
        columns={columns}
        items={items}
        name={name}
        searchQueryKey={searchQueryKey}
        filtersChangedCallback={filtersChangedCallback}
      >
        <FilterableSortableTableWrapper searchPlaceholder={searchPlaceholder} dateSelectors={dateSelectors}>
          {isLoading === true && <Loading />}

          {!isLoading && (
            <FilterableSortableTable
              columns={columns}
              emptyPrompt={emptyPrompt}
              idToFcoMap={idToFcoMap}
              itemIdToExpandedRowMap={itemIdToExpandedRowMap}
              rowProps={rowProps}
              isExpandable={isExpandable}
              itemId={itemId}
              loadMoreCallback={loadMoreCallback}
            />
          )}
        </FilterableSortableTableWrapper>
      </FCOListContextProvider>
    </>
  );
};

export const FilterableSortableTableWrapper: FC<FilterableSortableTableLayoutWrapperProps> = ({
  children,
  searchPlaceholder,
  dateSelectors,
}) => {
  const [areFiltersOpen, setAreFiltersOpen] = useState<boolean>(false);
  const [selectedFilter, setSelectedFilter] = useState<string | undefined>(undefined);

  const {
    filterTypes,
    filterState,
    setFilterState,
    numberOfActiveFilters,
    resetAllFilters,
    resetActiveFilter,
    controlsState: {
      isColumnControlsOpen,
      setIsColumnControlsOpen,
      columnOptions,
      numberOfSelectedColumnsOptions,
      setColumnOptions,
    },
  } = useContext(FCOListContext);
  const { workspace } = useParams();
  useEffect(() => {
    resetAllFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspace]);

  const resetButtonActive = numberOfActiveFilters > 0;
  const resetFiltersText = resetButtonActive ? `Reset All Filters (${numberOfActiveFilters})` : `Reset All Filters`;

  return (
    <>
      <TableHeaderFlex>
        <TableSearchInputWrapper>
          {!dateSelectors && (
            <SearchInput
              placeholder={searchPlaceholder}
              value={filterState.labelQuery as string}
              fullWidth
              onChange={(event) => {
                setFilterState({
                  ...filterState,
                  labelQuery: event.target.value.toLowerCase(),
                });
              }}
            />
          )}
          {dateSelectors && (
            <TableDatePickerWrapper>
              <TableChangesLabelText>Latest state changes between:</TableChangesLabelText>
              <FullRangePicker
                dateAndTime
                startDate={
                  filterState.lastTaskStateChange ? moment(filterState.lastTaskStateChange[0] as string) : moment()
                }
                setStartDate={(value: Moment) => {
                  setFilterState({
                    ...filterState,
                    lastTaskStateChange: [
                      value.toDate(),
                      filterState.lastTaskStateChange ? filterState.lastTaskStateChange[1] : new Date(),
                    ] as [Date, Date],
                  });
                }}
                endDate={
                  filterState.lastTaskStateChange ? moment(filterState.lastTaskStateChange[0] as string) : moment()
                }
                setEndDate={(value: Moment) => {
                  setFilterState({
                    ...filterState,
                    lastTaskStateChange: [
                      value.toDate(),
                      filterState.lastTaskStateChange ? filterState.lastTaskStateChange[1] : new Date(),
                    ] as [Date, Date],
                  });
                }}
              />
            </TableDatePickerWrapper>
          )}
        </TableSearchInputWrapper>
        <div>
          <TableButtonFlex>
            <ComboFilterDropdown
              buttonLabel={'Filters'}
              filterTypes={filterTypes}
              isOpen={areFiltersOpen}
              selectedFilter={selectedFilter}
              setIsOpen={setAreFiltersOpen}
              setSelectedFilter={setSelectedFilter}
              numberOfActiveFilters={numberOfActiveFilters}
              clearAllFilterCallback={resetAllFilters}
              clearAllText={resetFiltersText}
              clearAllIsDisabled={!resetButtonActive}
              resetActiveFilter={resetActiveFilter}
            />
            <ColumnDisplayControls
              isColumnControlsOpen={isColumnControlsOpen}
              setIsColumnControlsOpen={setIsColumnControlsOpen}
              columnOptions={columnOptions}
              numberOfSelectedColumnsOptions={numberOfSelectedColumnsOptions}
              setColumnOptions={setColumnOptions}
            />
          </TableButtonFlex>
        </div>
      </TableHeaderFlex>
      {children}
    </>
  );
};

export const FilterableSortableTable: FC<FilterableSortableTableProps> = ({
  columns,
  emptyPrompt,
  itemIdToExpandedRowMap,
  rowProps,
  isExpandable,
  itemId,
  loadMoreCallback,
}) => {
  const {
    filterState,
    filteredItems,
    controlsState: { activeColumns },
    sortParams,
    setSortParams,
  } = useContext(FCOListContext);

  const { tableColumns } = getFCOListColumns(columns, activeColumns, sortParams, setSortParams);

  return (
    <>
      <div style={{ width: '100%', overflow: 'auto' }}>
        <Table
          items={filteredItems}
          columns={tableColumns}
          emptyPrompt={emptyPrompt}
          itemIdToExpandedRowMap={itemIdToExpandedRowMap}
          rowProps={rowProps}
          isExpandable={isExpandable}
          itemId={itemId}
        />
        {loadMoreCallback && (
          <TableLoadMoreFlex>
            <Button
              label={'Load More'}
              variant={'emptyAction'}
              onClick={() => {
                loadMoreCallback(filterState);
              }}
            />
          </TableLoadMoreFlex>
        )}
      </div>
    </>
  );
};

export default FilterableSortableTableContainer;
