import _ from 'lodash';
import IdUtils from '../utils/id-utils';
import { Fco, FCO_TYPES } from './Fco';

export default class FcoContainer {
  #proto = null;

  constructor(proto) {
    this.#proto = proto;
  }

  get _proto() {
    return this.#proto;
  }

  get fcos() {
    const fcos = _.get(this._proto, 'fcos');
    if (!Array.isArray(fcos)) {
      return [];
    }
    return fcos.map((proto) => new Fco(proto));
  }

  get idToFcoMap() {
    return _.keyBy(this.fcos, 'id');
  }

  getSingleRootFco() {
    const root_ids = _.get(this._proto, 'root_ids');

    if (_.isEmpty(root_ids)) {
      return null;
    }
    if (root_ids.length !== 1) {
      throw new Error('Expected a single result but got ' + root_ids.length);
    }

    const id = IdUtils.toStringId(root_ids[0]);
    return this.idToFcoMap[id];
  }

  getAllInnerWrappedByType(fcoType) {
    return this.fcos
      .filter((fco) => {
        return fco.type === fcoType;
      })
      .map((fco) => {
        return fco.innerWrapped;
      });
  }

  get allEntities() {
    return this.getAllInnerWrappedByType(FCO_TYPES.ENTITY);
  }

  get allVirtualDataSources() {
    return this.getAllInnerWrappedByType(FCO_TYPES.DATA_SOURCE);
  }

  get allTransformation() {
    return this.getAllInnerWrappedByType(FCO_TYPES.TRANSFORMATION);
  }

  get allFeatureViews() {
    return this.getAllInnerWrappedByType(FCO_TYPES.FEATURE_VIEW);
  }

  get allFeatureServices() {
    return this.getAllInnerWrappedByType(FCO_TYPES.FEATURE_SERVICE);
  }
}
