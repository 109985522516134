import React from 'react';
import { Badge, transparentize, useTectonTheme } from '@tecton';
import { css } from '@emotion/css';
import { FeatureViewFCO } from '../../core/types/fcoTypes';

interface EnabledDisabledMaterializationBadgeProps {
  mode: 'online' | 'offline';
  fco?: FeatureViewFCO;
  value?: boolean;
}

const EnabledDisabledMaterializationBadge = ({ mode, fco, value }: EnabledDisabledMaterializationBadgeProps) => {
  const { theme } = useTectonTheme();

  const enabledCss = css`
    .euiBadge__text {
      color: ${theme.colors.primaryText};
    }

    .euiIcon path {
      stroke: ${theme.colors.primaryText};
    }
  `;

  const disabledCss = css`
    .euiBadge__text {
      color: ${theme.colors.disabled};
    }

    .euiIcon path {
      stroke: ${theme.colors.disabledText};
    }
  `;

  let enabled;
  if (fco) {
    enabled = mode === 'online' ? fco.isOnlineMaterializationEnabled : fco.isOfflineMaterializationEnabled;
  } else {
    enabled = value;
  }

  return (
    <Badge
      className={enabled ? enabledCss : disabledCss}
      color={enabled ? transparentize(theme.colors.primary, 0.2) : 'hollow'}
    >
      {enabled ? 'enabled' : 'disabled'}
    </Badge>
  );
};

export default EnabledDisabledMaterializationBadge;
