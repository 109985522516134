import React, { JSXElementConstructor, ReactElement, useState } from 'react';
import { css } from '@emotion/css';

import { Badge, ButtonIcon, EmptyPrompt, Panel, Popover, useEuiFontSize, useTectonTheme } from '@tecton';
import useTectonFCOEmptyPromptClass from './TectonFCOEmptyPromptClass';

const PanelHelp = ({ children }: { children: React.ReactNode }) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  return (
    <div>
      <Popover
        button={
          <ButtonIcon
            iconType="questionInCircle"
            color="text"
            onMouseOver={() => {
              setIsPopoverOpen(true);
            }}
            onMouseLeave={() => {
              setIsPopoverOpen(false);
            }}
          />
        }
        anchorPosition="downRight"
        isOpen={isPopoverOpen}
      >
        {children}
      </Popover>
    </div>
  );
};

export const PanelEmptyState = ({
  emptyPromptTitle,
  emptyPromptBody,
  emptyPromptComponent,
}: {
  emptyPromptTitle?: string;
  emptyPromptBody?: React.ReactNode;
  emptyPromptComponent?: React.ReactNode;
}) => {
  const TectonFCOEmptyPromptClass = useTectonFCOEmptyPromptClass();

  if (emptyPromptComponent) {
    return <>emptyPromptComponent</>;
  } else {
    if (!emptyPromptTitle || !emptyPromptBody) {
      throw new Error('Need basic empty prompt message');
    }

    return (
      <div className={TectonFCOEmptyPromptClass}>
        <EmptyPrompt title={<h3>{emptyPromptTitle}</h3>} body={<p>{emptyPromptBody}</p>} />
      </div>
    );
  }
};

interface FCOPanelProps {
  title: ReactElement<any, string | JSXElementConstructor<any>> | string;
  count?: number;
  noPadding?: boolean;
  children: React.ReactNode;
  helpContent?: React.ReactNode;
  showEmptyState?: boolean;
}

const FCOPanel = ({
  title,
  count,
  noPadding,
  children,
  helpContent,
  showEmptyState,
  emptyPromptTitle,
  emptyPromptBody,
  emptyPromptComponent,
}: FCOPanelProps & React.ComponentProps<typeof PanelEmptyState>) => {
  const { theme } = useTectonTheme();

  const FCOCardHeaderCss = css`
    background: ${theme.colors.lightestShade};
    padding: ${theme.size.m} ${theme.size.m} ${theme.size.m} ${theme.size.l};

    display: grid;
    grid-template-columns: 1fr auto;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      ${useEuiFontSize('m')};
      font-weight: 500;
      color: ${theme.colors.darkestShade};
    }
  `;

  const padding = noPadding ? 0 : `${theme.size.l} ${theme.size.l}`;

  return (
    <Panel hasBorder={true} hasShadow={false} paddingSize="none" style={{ height: '100%' }}>
      <div className={FCOCardHeaderCss}>
        {typeof title === 'string' ? (
          <h3 style={{ display: 'flex', alignItems: 'center', gap: '0.3em' }}>
            {title}{' '}
            {count && (
              <Badge
                color="#fff"
                className={css`
                  margin-left: '0.3em';
                  border: 1px solid ${theme.border.color};
                  font-weight: 500;
                `}
              >
                {count}
              </Badge>
            )}
          </h3>
        ) : (
          title
        )}
        {helpContent && <PanelHelp>{helpContent}</PanelHelp>}
      </div>
      <div
        style={{
          padding,
          height: `calc(100% - 2 * (${padding}))`,
        }}
      >
        {showEmptyState ? (
          <PanelEmptyState
            emptyPromptComponent={emptyPromptComponent}
            emptyPromptTitle={emptyPromptTitle}
            emptyPromptBody={emptyPromptBody}
          />
        ) : (
          children
        )}
      </div>
    </Panel>
  );
};

export default FCOPanel;
