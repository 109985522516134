import React from 'react';

function DatasetIcon() {
  return (
    <g>
      <circle cx="12" cy="12" r="3" fill="#D830F9" />
      <circle opacity="0.2" cx="21" cy="12" r="3" fill="#D830F9" />
      <ellipse opacity="0.6" cx="12" cy="2" rx="2" ry="2" fill="#D830F9" />
      <circle opacity="0.4" cx="12" cy="22" r="2" fill="#D830F9" />
      <ellipse opacity="0.6" cx="3" cy="12" rx="3" ry="3" fill="#D830F9" />
      <circle opacity="0.3" cx="3.5" cy="3.5" r="1.5" fill="#D830F9" />
      <ellipse opacity="0.3" cx="20.5" cy="3.5" rx="1.5" ry="1.5" fill="#D830F9" />
      <circle opacity="0.3" cx="20.5" cy="20.5" r="1.5" fill="#D830F9" />
      <circle opacity="0.3" cx="17" cy="17.0001" r="2" fill="#D830F9" />
      <ellipse opacity="0.6" cx="7.5" cy="16.5001" rx="1.5" ry="1.5" fill="#D830F9" />
      <circle opacity="0.6" cx="7" cy="7" r="2" fill="#D830F9" />
      <ellipse opacity="0.6" cx="16.5" cy="7.5" rx="1.5" ry="1.5" fill="#D830F9" />
      <ellipse opacity="0.3" cx="3.5" cy="20.5" rx="1.5" ry="1.5" fill="#D830F9" />
    </g>
  );
}

export default DatasetIcon;
