import styled from '@emotion/styled';
import React from 'react';
import { LoadingChart } from './_eui_components';

const ChartLoading = () => {
  const CenteredContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  `;

  return (
    <CenteredContent>
      <LoadingChart />
    </CenteredContent>
  );
};

export default ChartLoading;
