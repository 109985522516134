import { Routes } from '../../../core/routes';
import { OnboardingSidebarState } from '../../../utils/onboarding/onboarding-util';
import { OnboardingStateEnums } from '../types';

const onboardingStateToRoutesMapping = (state: OnboardingSidebarState) => {
  if (state.onBoardingState === OnboardingStateEnums.NEW_ACCOUNT) {
    return Routes.dataPlatformConfiguration;
  }
};

export default onboardingStateToRoutesMapping;
