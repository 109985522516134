import { Box, Typography } from '@mui/material';
import React from 'react';
import useAutoscroll from '../../../../feature/onboarding/useAutoscroll';
import { Task } from '../../../../service/DataPlatformSetupStatus';
import { getDataPlatformStatus } from '../../../../utils/onboarding/onboarding-util';
import { Progression, ProgressionStatusType, StatusProgression } from '../StatusProgression';

interface DatabricksConfigureStatusProgressionProps {
  dpoStatuses: Task[];
  databricksConfigureStatusProgressionRef: React.RefObject<HTMLElement>;
}

const DatabricksConfigureStatusProgression = ({
  dpoStatuses,
  databricksConfigureStatusProgressionRef,
}: DatabricksConfigureStatusProgressionProps) => {
  let dataPlatformSetupStatusesList: Progression[] = [];
  // We should never get and empty array from backend. So I'll use and empty array to detect if we've set the value or not.

  useAutoscroll(databricksConfigureStatusProgressionRef, true, 500);

  if (dpoStatuses.length > 0) {
    dataPlatformSetupStatusesList = dpoStatuses.map((task: Task) => {
      const status: ProgressionStatusType = getDataPlatformStatus(task.task_status);
      return {
        status: status,
        headerContent: (
          <Box>
            <Typography variant="body1">{task.task_display_name}</Typography>
          </Box>
        ),
        bodyContent: (
          <Box>
            <Typography variant="body1">{task.error_message}</Typography>
          </Box>
        ),
      };
    });
  }

  return (
    <Box ref={databricksConfigureStatusProgressionRef}>
      <StatusProgression statusProgressions={dataPlatformSetupStatusesList} />
      <Typography variant="body2" sx={{ mt: 1 }}>
        Cluster creation takes up to 10 minutes. Feel free to navigate away from this page.
      </Typography>
    </Box>
  );
};

export default DatabricksConfigureStatusProgression;
