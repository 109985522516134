import React from 'react';
import { ErrorBoundary, Panel } from '@tecton';
import PlanSummaryDetail from './PlanSummaryDetail';

const PlanSummaryDetailContainer = () => {
  return (
    <ErrorBoundary>
      <Panel hasBorder={false} hasShadow={false} paddingSize="none" className="eui-yScroll">
        <PlanSummaryDetail />
      </Panel>
    </ErrorBoundary>
  );
};

export default PlanSummaryDetailContainer;
