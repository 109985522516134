import React from 'react';
import { ButtonIcon, ToolTip } from '@tecton';
import { useUserSettings } from '../context/UserSettingsContext';

const TableActionButtonWithDisableMode = ({
  iconType,
  color,
  isDisabled,
  enabledMessage,
  disabledMessage,
  requiresAdmin,
  onClick,
}: {
  iconType: React.ComponentProps<typeof ButtonIcon>['iconType'];
  color?: React.ComponentProps<typeof ButtonIcon>['color'];
  isDisabled: boolean;
  enabledMessage: string;
  disabledMessage?: string;
  requiresAdmin?: boolean;
  onClick: () => void;
}) => {
  const { isAdmin } = useUserSettings();
  const satisfiesAdminRequirement = isAdmin || !requiresAdmin;

  const tooltipText = satisfiesAdminRequirement
    ? !isDisabled
      ? enabledMessage
      : disabledMessage
    : `${disabledMessage} - Administrator Privileges Required`;

  const isDisabledOrNotAllowed = isDisabled || !satisfiesAdminRequirement;

  return (
    <ToolTip
      position="top"
      content={tooltipText}
      anchorProps={{
        style: {
          cursor: isDisabledOrNotAllowed ? 'not-allowed' : 'pointer',
        },
      }}
    >
      <ButtonIcon
        color={color}
        iconType={iconType}
        isDisabled={isDisabledOrNotAllowed}
        onClick={onClick}
        aria-label={tooltipText}
      />
    </ToolTip>
  );
};

export default TableActionButtonWithDisableMode;
