import { EuiPopoverFooter } from '@elastic/eui';
import React from 'react';

const PopoverSelectorFooter = ({ children }: { children: React.ReactNode }) => {
  return (
    <EuiPopoverFooter paddingSize="s" style={{ display: 'flex', flexDirection: 'row-reverse', margin: 0 }}>
      {children}
    </EuiPopoverFooter>
  );
};

export default PopoverSelectorFooter;
