import React, { useContext } from 'react';
import { Routes, Route, Outlet, Navigate, useNavigate, useLocation, matchPath, useParams } from 'react-router-dom';

import TectonPageHeader from '../@shared/TectonPageHeader';
import NoMatch from '../shared/NoMatch';
import UsersWithAccess from './UsersWithPermissions';
import ServiceAccountsWithAccess from './ServiceAccountsWithAccess';
import { Panel } from '@tecton';
import { ConfigurationContext } from './ConfigurationContextProvider';
import { Routes as AppRoutes } from '../../core/routes';
import ServiceAccountAccessContextProvider from './ServiceAccountAccessContextProvider';
import GroupsWithAccess from './GroupsWithAccess';

const AccessIndex = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const configurationContext = useContext(ConfigurationContext);
  const { workspace } = useParams();

  const tabs = [
    {
      label: 'Users',
      count: configurationContext?.usersLength ?? 0,
      isSelected: !!matchPath(
        {
          path: `repo/${configurationContext?.workspace}${AppRoutes.configurationAccessUsers}`,
          end: true,
        },
        location.pathname
      ),
      onClick: () => {
        navigate('./users');
      },
      route: AppRoutes.configurationAccessUsers,
    },
    {
      label: 'Service Accounts',
      count: configurationContext?.serviceAccountLength,
      isSelected: !!matchPath(
        {
          path: `repo/${configurationContext?.workspace}${AppRoutes.configurationAccessServiceAccounts}`,
          end: true,
        },
        location.pathname
      ),
      onClick: () => {
        navigate('./service-accounts');
      },
      route: AppRoutes.configurationAccessServiceAccounts,
    },
    {
      label: 'Groups',
      count: configurationContext?.groupPermission?.length,
      isSelected: !!matchPath(
        {
          path: `repo/${workspace}${AppRoutes.configurationAccessGroups}`,
          end: true,
        },
        location.pathname
      ),
      onClick: () => {
        navigate('./groups');
      },
      route: AppRoutes.configurationAccessGroups,
    },
  ];

  return (
    <Panel hasBorder={false} hasShadow={false} paddingSize="xs" className="eui-yScroll">
      <Routes>
        <Route
          path="/"
          element={
            <>
              <TectonPageHeader
                tabs={tabs}
                breadcrumbs={[
                  {
                    text: <span>Permissions</span>,
                  },
                ]}
              />
              <Outlet />
            </>
          }
        >
          <Route index element={<Navigate to="./users" replace />} />
          <Route path="users" element={<UsersWithAccess />} />
          <Route
            path="service-accounts"
            element={
              <ServiceAccountAccessContextProvider>
                <ServiceAccountsWithAccess />
              </ServiceAccountAccessContextProvider>
            }
          />
          <Route path="groups" element={<GroupsWithAccess />} />
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </Panel>
  );
};

export default AccessIndex;
