export default () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.33329 13.9997V13.9997C6.07196 13.9997 6.67129 13.4011 6.67196 12.6624L6.67463 9.96639C7.31729 9.68505 7.89796 9.21439 8.28196 8.47705C8.89129 7.30772 8.77463 5.83839 7.94596 4.81305C6.59263 3.13905 4.06063 3.14305 2.71329 4.82505C1.88863 5.85372 1.77596 7.32372 2.39129 8.48972C2.77663 9.22039 3.35596 9.68972 3.99729 9.96905L3.99463 12.6591C3.99396 13.3991 4.59329 13.9997 5.33329 13.9997V13.9997Z"
        stroke="#868C98"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.51542 6.89745C5.51542 6.78879 5.42742 6.70145 5.31942 6.70145C5.21075 6.70212 5.12342 6.79012 5.12342 6.89812C5.12342 7.00679 5.21142 7.09412 5.31942 7.09412C5.42742 7.09412 5.51542 7.00612 5.51542 6.89745"
        stroke="#868C98"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.84192 3.75467C8.84192 2.78533 8.05659 2 7.08725 2V2C6.11859 2 5.33325 2.78533 5.33325 3.75467V6.70133"
        stroke="#868C98"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.59863 4.44441L7.89863 4.14441C8.4193 3.62374 9.2633 3.62374 9.78397 4.14441L13.6086 7.96907C14.1293 8.48974 14.1293 9.33374 13.6086 9.85441L11.844 11.6191C11.3233 12.1397 10.4793 12.1397 9.95863 11.6191L7.65663 9.31707"
        stroke="#868C98"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
