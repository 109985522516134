import React, { FC } from 'react';
import FCOCard from './FCOCard';
import { LoadingSpinner } from './_eui_components';
import styled from '@emotion/styled';
import { useTectonTheme } from './TectonThemeProvider';

interface CardLoadingProps {
  title: string;
}

const CardLoading: FC<CardLoadingProps> = ({ title }) => {
  const { theme } = useTectonTheme();

  const CenteredContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: ${theme.size.xl};
  `;
  return (
    <FCOCard title={title}>
      <CenteredContent>
        <LoadingSpinner size="xl" />
      </CenteredContent>
    </FCOCard>
  );
};
export default CardLoading;
