import React, { useContext } from 'react';
import { css } from '@emotion/css';
import ACLSectionSubhead from './ACLSectionSubhead';
import { DescriptionList, Panel, useTectonTheme } from '@tecton';
import { useUserSettings } from '../context/UserSettingsContext';
import { ACLServiceAccountProfileContext } from './ACLServiceAccountProfileContext';

const ACLAdminActionsList = ({
  listItems,
}: {
  listItems: React.ComponentProps<typeof DescriptionList>['listItems'];
}) => {
  const { theme } = useTectonTheme();

  const { isAdmin } = useUserSettings();
  const serviceAccountProfileContext = useContext(ACLServiceAccountProfileContext);

  if (!isAdmin && !serviceAccountProfileContext?.isOwner) {
    return <></>;
  }

  return (
    <Panel hasShadow={false} paddingSize="none">
      <ACLSectionSubhead size="xs">
        <h4>Admin Actions</h4>
      </ACLSectionSubhead>
      {listItems?.map((i) => {
        return (
          <div
            className={css`
              border: 1px solid ${theme.border.color};
              padding-top: 12px;
              padding-left: 12px;
              padding-right: 12px;
              font-size: 1rem;
              line-height: 1.5rem;
              margin-bottom: 16px;
            `}
          >
            <span
              className={css`
                font-weight: 700;
                margin-bottom: 16px;
              `}
            >
              {i?.title}
            </span>
            <p
              className={css`
                margin-bottom: 12px;
              `}
            >
              {i?.description}
            </p>
          </div>
        );
      })}
    </Panel>
  );
};

export default ACLAdminActionsList;
