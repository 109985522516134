import { configureStore } from '@reduxjs/toolkit';
import { composeWithDevTools } from 'redux-devtools-extension';
import { reducer as authReducer } from '../feature/auth/reducer';

import { reducer as featureFlagsReducer } from './feature-flag/reducer';
import { loadingReducer, workspaceReducer, pathStateReducer, applyLogStateReducer } from '../shared/reducer';
import { notificationReducer } from '../utils/notifications/reducer';
import { loadingBarReducer } from 'react-redux-loading-bar';
import { onBoardingReducer } from '../feature/onboarding/reducer';
import { configReducer } from '../core/config/reducer';
import { combineReducers } from 'redux-immer';
import produce, { enableMapSet } from 'immer'; //Return immutable states

enableMapSet();

const reducers = combineReducers(produce, {
  featureFlags: featureFlagsReducer,
  auth: authReducer,
  loading: loadingReducer,
  loadingBar: loadingBarReducer,
  workspace: workspaceReducer,
  pathState: pathStateReducer,
  notifications: notificationReducer,
  onBoarding: onBoardingReducer,
  applyLog: applyLogStateReducer,
  config: configReducer,
});

const store = configureStore(
  {
    reducer: reducers,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  },
  composeWithDevTools()
);

export default store;
