export default () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.8665 9.06699V12.0003C5.8665 12.7367 6.46346 13.3337 7.19984 13.3337H8.79984C9.53622 13.3337 10.1332 12.7367 10.1332 12.0003V9.06699L12.6665 7.16699C13.0862 6.85224 13.3332 6.35825 13.3332 5.83366V4.33366C13.3332 3.41318 12.587 2.66699 11.6665 2.66699H4.33317C3.4127 2.66699 2.6665 3.41318 2.6665 4.33366V5.83366C2.6665 6.35825 2.91349 6.85224 3.33317 7.16699L5.8665 9.06699Z"
        stroke="#525866"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
