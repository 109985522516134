import React from 'react';
// import themeStyles from '../core/theme/primary.module.scss';
import ReactLoading from 'react-loading';
import Title from '../utils/title-utils';
import styles from './styles.module.scss';

export const LoadingIndicator = (props) => {
  return (
    <div className={styles.loadingIndicatorContainer}>
      <ReactLoading type={'bars'} color={styles.loadingIndicatorColor} height={100} width={100} />
      {props.title ? <Title title={props.title} /> : null}
    </div>
  );
};
