import { flatten, intersection, intersectionWith, isEqual, uniqWith } from 'lodash';
import {
  FCOCategoricalFilterProps,
  FCODateFilterProps,
  FCOListFilterType,
  FCOListSort,
  FCOListViewColumn,
  FCONumericalFilterProps,
  FCOTagFilterProps,
  FilterCountsOptions,
  FilterPropsOptions,
  FilterValuesMapByFilterType,
  TagsFilterOption,
} from './types';
import { InternMap, extent, group } from 'd3-array';
import { AnyFCO, FCO } from '../../core/types/fcoTypes';
import { timeFormat } from 'd3-time-format';
import { FilterOptionType } from '../@tecton/CategoricalSelector';
import { CategoricalSelectorHierarchicalOption } from '../@tecton/CategoricalSelectorHierarchical';

export const reduceNumberOfActiveFilters = (
  activeFilterValuesByType: FilterValuesMapByFilterType,
  columns: InternMap<FCOListFilterType | undefined, FCOListViewColumn[]>
) => {
  let numberOfActiveFilters = 0;

  Object.entries(activeFilterValuesByType).forEach(([key, value]) => {
    switch (key) {
      case FCOListFilterType.CATEGORY:
        Object.entries(value).forEach(([_, filterConditions]) => {
          if (filterConditions as string[]) {
            numberOfActiveFilters += (filterConditions as string[]).length;
          }
        });
        break;
      case FCOListFilterType.BOOLEAN:
        Object.entries(value).forEach(([_, filterConditions]) => {
          if (filterConditions as string[]) {
            numberOfActiveFilters += (filterConditions as string[]).length;
          }
        });
        break;
      case FCOListFilterType.DATE:
        Object.entries(value).forEach(([key, filterConditions]) => {
          const defaultProps = columns.get(FCOListFilterType.DATE)?.find((column) => column.key === key)?.filterProps;
          const defaultRange = (defaultProps as FCODateFilterProps).dateRange;
          if (filterConditions && !isEqual(defaultRange, filterConditions)) {
            numberOfActiveFilters += 1;
          }
        });
        break;
      case FCOListFilterType.NUMBER:
        Object.entries(value).forEach(([key, filterConditions]) => {
          const defaultProps = columns.get(FCOListFilterType.NUMBER)?.find((column) => column.key === key)?.filterProps;
          const defaultRange = (defaultProps as FCONumericalFilterProps).selectorValue;
          if (filterConditions && !isEqual(defaultRange, filterConditions)) {
            numberOfActiveFilters += 1;
          }
        });
        break;
      case FCOListFilterType.TAGS:
        Object.entries(value).forEach(([_, count]) => {
          numberOfActiveFilters += (count as string[]).length;
        });

        break;
      case FCOListFilterType.COUNT:
        Object.entries(value).forEach(([_, filterConditions]) => {
          if ((filterConditions as string[]).length > 0) {
            numberOfActiveFilters += 1;
          }
        });
        break;
    }
  });

  return numberOfActiveFilters;
};

export const getTotalNumberOfOptionsForFilter = (column: FCOListViewColumn) => {
  if (!column.filterType) {
    return undefined;
  }

  switch (column.filterType!) {
    case FCOListFilterType.CATEGORY:
      // eslint-disable-next-line no-case-declarations
      const categoryCount = column.filterProps as FCOCategoricalFilterProps;
      if (categoryCount && categoryCount.options) {
        return categoryCount.options.length;
      }
      return undefined;
    case FCOListFilterType.BOOLEAN:
      return 2; // Always two options for boolean
    case FCOListFilterType.DATE:
      return undefined; // Don't show  counts for date filters per design
    case FCOListFilterType.NUMBER:
      return undefined; // Don't show tag counts for numbers per design
    case FCOListFilterType.TAGS:
      // eslint-disable-next-line no-case-declarations
      const getTagCount = column.filterProps as FCOTagFilterProps;

      // eslint-disable-next-line no-case-declarations
      let totalTags = 0;
      if (getTagCount) {
        Object.entries(getTagCount).forEach(([_, count]) => {
          // In case of multiple tag types
          Object.entries(count).forEach(([_, individualTagKey]) => {
            totalTags += (individualTagKey as string[]).length;
          });
        });
      }
      return totalTags;
  }
};

export const getActiveNumberOfOptionsForFilter = (column: FCOListViewColumn, columnFilterState: FilterPropsOptions) => {
  if (!column.filterType || !columnFilterState) {
    return undefined;
  }

  if (column.filterType === FCOListFilterType.NUMBER) {
    const defaultValue = (column.filterProps as FCONumericalFilterProps).selectorValue;
    return defaultValue && !isEqual(defaultValue, columnFilterState) ? 1 : 0;
  }

  if (column.filterType === FCOListFilterType.DATE) {
    const defaultValue = (column.filterProps as FCODateFilterProps).dateRange;
    return defaultValue && !isEqual(defaultValue, columnFilterState) ? 1 : 0;
  }

  if (column.filterType === FCOListFilterType.TAGS) {
    const parentsChecked = (columnFilterState as CategoricalSelectorHierarchicalOption[])
      .filter((item) => !item.parent && item.checked === 'on')
      .map((filteredItem) => filteredItem.label);

    const validChildrenToCount = (columnFilterState as CategoricalSelectorHierarchicalOption[]).filter(
      (item) => item.parent && !parentsChecked.includes(item.parent!) && item.checked === 'on'
    );

    return parentsChecked.length + validChildrenToCount.length;
  }

  return (columnFilterState as { checked?: string }[]).filter((item) => item.checked === 'on').length;
};

const applySortToTags = (allListItems: AnyFCO[], sortParams: FCOListSort) => {
  const firstWeight = sortParams.order === 'desc' ? 1 : -1;
  const secondWeight = sortParams.order === 'desc' ? -1 : 1;

  const sortKey = sortParams.key!;

  return allListItems.sort((a, b) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const aValue: any[] = (a as any)[sortKey];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const bValue: any[] = (b as any)[sortKey];

    return aValue.length > bValue.length ? firstWeight : secondWeight;
  });
};

const applySortToDate = (allListItems: AnyFCO[], sortParams: FCOListSort) => {
  const firstWeight = sortParams.order === 'desc' ? 1 : -1;
  const secondWeight = sortParams.order === 'desc' ? -1 : 1;

  const sortKey = sortParams.key!;

  return allListItems.sort((a, b) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const aValue = (a as any)[sortKey];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const bValue = (b as any)[sortKey];

    return aValue < bValue ? firstWeight : secondWeight;
  });
};

const applySortToFcos = (allListItems: AnyFCO[], sortParams: FCOListSort) => {
  const firstWeight = sortParams.order === 'desc' ? 1 : -1;
  const secondWeight = sortParams.order === 'desc' ? -1 : 1;

  const sortKey = sortParams.key!;

  return allListItems.sort((a, b) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const aValue = (a as any)[sortKey];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const bValue = (b as any)[sortKey];

    return aValue.length > bValue.length ? firstWeight : secondWeight;
  });
};

const applySortToString = (allListItems: AnyFCO[], sortParams: FCOListSort) => {
  const firstWeight = sortParams.order === 'desc' ? 1 : -1;
  const secondWeight = sortParams.order === 'desc' ? -1 : 1;

  const sortKey = sortParams.key!;

  return allListItems.sort((a, b) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let aValue = (a as any)[sortKey];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let bValue = (b as any)[sortKey];

    if (aValue === undefined) {
      aValue = '';
    }

    if (bValue === undefined) {
      bValue = '';
    }

    // Enabled / Disabled are passed as booleans, which aren't string-sortable
    if (typeof aValue === 'boolean') {
      aValue = aValue ? 'enabled' : 'disabled';
    }

    if (typeof bValue === 'boolean') {
      bValue = bValue ? 'enabled' : 'disabled';
    }

    // Join keys may be arrays, which is why I'm doing it this way
    if (Array.isArray(aValue)) {
      aValue = aValue[0].toLowerCase();
    } else {
      aValue = aValue.toLowerCase();
    }

    if (Array.isArray(bValue)) {
      bValue = bValue[0].toLowerCase();
    } else {
      bValue = bValue.toLowerCase();
    }

    return aValue < bValue ? firstWeight : secondWeight;
  });
};

const applySortToNumber = (allListItems: AnyFCO[], sortParams: FCOListSort) => {
  const firstWeight = sortParams.order === 'desc' ? 1 : -1;
  const secondWeight = sortParams.order === 'desc' ? -1 : 1;

  const sortKey = sortParams.key!;

  return allListItems.sort((a, b) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const aValue = (a as any)[sortKey];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const bValue = (b as any)[sortKey];

    return aValue > bValue ? firstWeight : secondWeight;
  });
};

export const applySortToItems = (allListItems: AnyFCO[], sortParams: FCOListSort | undefined) => {
  if (!sortParams) {
    return allListItems;
  }

  const sortKey = sortParams.key;

  if (!sortKey) {
    return allListItems;
  }

  switch (sortParams.type) {
    case 'tags':
      return applySortToTags(allListItems, sortParams);
    case 'date':
      return applySortToDate(allListItems, sortParams);
    case 'fcos':
      return applySortToFcos(allListItems, sortParams);
    case 'string':
      return applySortToString(allListItems, sortParams);
    case 'number':
      return applySortToNumber(allListItems, sortParams);
  }

  return allListItems;
};

// Functions for filtering FCO list items per column type
export const applyFilterToAllListItems = (
  allListItems: AnyFCO[],
  filterState: Record<string, FilterPropsOptions>,
  activeFilterValuesByType: FilterValuesMapByFilterType
) => {
  return allListItems.filter((item) => {
    if (
      item.name &&
      filterState.labelQuery &&
      !item.name.includes(filterState.labelQuery as string) &&
      !item.id.includes(filterState.labelQuery as string)
    ) {
      return false;
    }

    if (!filterItemsForCategoricalValues(activeFilterValuesByType[FCOListFilterType.CATEGORY], item)) {
      return false;
    }

    if (!filterItemsForBooleanValues(activeFilterValuesByType[FCOListFilterType.BOOLEAN], item)) {
      return false;
    }

    if (!filterItemsForTagValues(activeFilterValuesByType[FCOListFilterType.TAGS], item)) {
      return false;
    }

    if (!filterItemsForDateValues(activeFilterValuesByType[FCOListFilterType.DATE], item)) {
      return false;
    }

    if (!filterItemsForNumericValues(activeFilterValuesByType[FCOListFilterType.NUMBER], item)) {
      return false;
    }

    if (!filterItemsForCountValues(activeFilterValuesByType[FCOListFilterType.COUNT], item)) {
      return false;
    }

    return true;
  });
};

export const filterItemsForTagValues = (
  activeCategoriesMap: Record<string, { key: string; value: string }[] | undefined>,
  item: AnyFCO
) => {
  let isMatching = false;

  if (Object.entries(activeCategoriesMap).length === 0) {
    return true;
  }

  Object.entries(activeCategoriesMap).forEach(([key, filterValues]) => {
    if (key) {
      if (filterValues && filterValues.length > 0) {
        const itemTags = Object.entries(item).find(([itemKey]) => key === itemKey);
        if (!itemTags) {
          return false; // No tags for this item
        }
        const hasIntersection = intersectionWith(itemTags[1], filterValues, isEqual).length > 0;
        if (hasIntersection) {
          isMatching = true; // Item has a matching tag;
        }
      }
    }
  });

  return isMatching;
};

export const filterSingleItemForCategoricalValue = (currentFilter: string[], itemValues: string[]) => {
  const flattenedValues = flatten(itemValues);

  if (isEqual(currentFilter, [''])) {
    // This is the special case of selecting 'NO FEATURE SERVICES' or 'NO FCOS'.. That's the only time that the array would be ['']
    if (flattenedValues.length === 0) {
      return true;
    } else {
      return false;
    }
  }

  if (flattenedValues.length == 0) {
    return false;
  }

  return intersection(currentFilter, flattenedValues).length > 0;
};

export const filterSingleItemForCountValue = (currentFilter: string, itemValues: number) => {
  if (currentFilter === undefined) {
    return true;
  }

  switch (currentFilter) {
    case FilterCountsOptions.ZERO:
      return itemValues === 0;
    case FilterCountsOptions.ONE:
      return itemValues === 1;
    case FilterCountsOptions.TWO:
      return itemValues === 2;
    case FilterCountsOptions.THREE_TO_FIVE:
      return itemValues >= 3 && itemValues <= 5;
    case FilterCountsOptions.SIX_TO_TEN:
      return itemValues >= 6 && itemValues <= 10;
    case FilterCountsOptions.MORE_THAN_TEN:
      return itemValues > 10;
  }
};

export const filterItemsForCategoricalValues = (
  activeCategoriesMap: Record<string, string[] | undefined>,
  item: AnyFCO
) => {
  let showItem = true;

  Object.entries(activeCategoriesMap).forEach(([key, value]) => {
    if (value !== undefined) {
      if (value.length !== 0) {
        const matchingValue = Object.entries(item).find(([itemKey]) => key === itemKey) as string[];
        if (!filterSingleItemForCategoricalValue(value, [matchingValue[1]])) {
          showItem = false;
        }
      }
    }
  });

  return showItem;
};

export const filterItemsForDateValues = (activeDateFiltersMap: Record<string, Date[] | undefined>, item: AnyFCO) => {
  let isInRange = true;

  if (Object.entries(activeDateFiltersMap).length === 0) {
    return true;
  }

  Object.entries(activeDateFiltersMap).forEach(([key, dateRange]) => {
    if (dateRange) {
      const findDate = Object.entries(item).find(([itemKey]) => key === itemKey);
      const itemDate = findDate && findDate[1];
      if (itemDate && (itemDate < dateRange[0] || itemDate > dateRange[1])) {
        isInRange = false;
      }
    }
  });

  return isInRange;
};

export const filterItemsForNumericValues = (
  activeNumericalColumnsMap: Record<string, number[] | undefined>,
  item: AnyFCO
) => {
  let isInRange = true;

  if (Object.entries(activeNumericalColumnsMap).length === 0) {
    return true;
  }

  Object.entries(activeNumericalColumnsMap).forEach(([key, range]) => {
    if (range) {
      const actualValue = Object.entries(item).find(([itemKey]) => key === itemKey);

      if (actualValue && (actualValue[1] < range[0] || actualValue[1] > range[1])) {
        isInRange = false;
      }

      const mockValue = Object.entries(item).find(([itemKey]) => key.replace('_COUNT', '') === itemKey);
      if (mockValue && (mockValue[1].length < range[0] || mockValue[1].length > range[1])) {
        isInRange = false;
      }
    }
  });

  return isInRange;
};

export const filterItemsForCountValues = (activeCategoriesMap: Record<string, string[] | undefined>, item: AnyFCO) => {
  let showItem = true;

  Object.entries(activeCategoriesMap).forEach(([key, value]) => {
    if (value !== undefined) {
      const keyToUse = key.replace('_COUNT', '');
      let valueToUse = 0;

      try {
        if (Object.keys(item).includes(keyToUse)) {
          valueToUse = (item as any)[keyToUse].length ?? (item as any)[keyToUse]; // This can be an array for FCOs, or a value (for # of Features), so need to accommodate both
        }
      } catch {
        return true;
      }

      if (!filterSingleItemForCountValue(value[0], valueToUse)) {
        showItem = false;
      }
    }
  });

  return showItem;
};

export const filterItemsForBooleanValues = (
  activeBooleanValuesMap: Record<string, string[] | undefined>,
  item: AnyFCO
) => {
  let showItem = true;

  Object.entries(activeBooleanValuesMap).forEach(([key, value]) => {
    if (value !== undefined) {
      const itemValue = Object.entries(item).find(([itemKey]) => key === itemKey);
      if (itemValue && !filterSingleItemForBooleanValue(value, itemValue[1])) {
        showItem = false;
      }
    }
  });

  return showItem;
};

export const filterSingleItemForBooleanValue = (currentFilter: string[], itemValue: boolean) => {
  if (currentFilter.length === 0) {
    return true;
  }

  if (itemValue === true) {
    return currentFilter.includes('isTrue');
  } else {
    return currentFilter.includes('isFalse');
  }
};

// Functions for generating maps of filters by filter column type
export const generateActiveCategoriesMaps = (
  columnsByType: InternMap<FCOListFilterType | undefined, FCOListViewColumn[]>,
  filterState: Record<string, FilterPropsOptions>
) => {
  const valueMaps: FilterValuesMapByFilterType = {
    [FCOListFilterType.CATEGORY]: generateActiveCategoriesValuesMap(
      columnsByType.get(FCOListFilterType.CATEGORY),
      filterState
    ),
    [FCOListFilterType.DATE]: generateActiveDateCategoriesMap(columnsByType.get(FCOListFilterType.DATE), filterState),
    [FCOListFilterType.NUMBER]: generateNumericColumnsMap(columnsByType.get(FCOListFilterType.NUMBER), filterState),
    [FCOListFilterType.BOOLEAN]: generateBooleanColumnsMap(columnsByType.get(FCOListFilterType.BOOLEAN), filterState),
    [FCOListFilterType.TAGS]: generateTagsColumnsMap(columnsByType.get(FCOListFilterType.TAGS), filterState),
    [FCOListFilterType.COUNT]: generateCountColumnsMap(columnsByType.get(FCOListFilterType.COUNT), filterState),
  };
  return valueMaps;
};

export const generateActiveCategoriesValuesMap = (
  columns: FCOListViewColumn[] | undefined,
  filterState: Record<string, FilterPropsOptions>
) => {
  const activeCategoriesValuesMap: Record<string, string[] | undefined> = {};
  columns &&
    columns.map((column) => {
      const filteredItems = filterState[column.key];
      const asStringArray = filteredItems as { label: string; checked: string }[];

      if (asStringArray) {
        activeCategoriesValuesMap[column.key] = (asStringArray as { label: string; checked: string }[])
          .filter((option) => option.checked === 'on')
          .map((option) => option.label);
      }
    });

  return activeCategoriesValuesMap;
};

export const generateActiveDateCategoriesMap = (
  columns: FCOListViewColumn[] | undefined,
  filterState: Record<string, FilterPropsOptions>
) => {
  const activeDateCategoriesMap: Record<string, Date[] | undefined> = {};
  columns &&
    columns.map((column) => {
      activeDateCategoriesMap[column.key] = filterState[column.key] as Date[];
    });
  return activeDateCategoriesMap;
};

export const generateNumericColumnsMap = (
  columns: FCOListViewColumn[] | undefined,
  filterState: Record<string, FilterPropsOptions>
) => {
  const activeNumericColumnsMap: Record<string, number[] | undefined> = {};
  columns &&
    columns.map((column) => {
      activeNumericColumnsMap[column.key] = filterState[column.key] as number[];
    });
  return activeNumericColumnsMap;
};

export const generateBooleanColumnsMap = (
  columns: FCOListViewColumn[] | undefined,
  filterState: Record<string, FilterPropsOptions>
) => {
  const activeBooleanValuesMap: Record<string, string[] | undefined> = {};
  columns &&
    columns.map((column) => {
      const filteredItems = filterState[column.key];
      const asStringArray = filteredItems as { label: string; checked: string }[];
      if (asStringArray) {
        activeBooleanValuesMap[column.key] = (asStringArray as { label: string; checked: string }[])
          .filter((option) => option.checked === 'on')
          .map((option) => option.label);
      }
    });

  return activeBooleanValuesMap;
};

export const generateTagsColumnsMap = (
  columns: FCOListViewColumn[] | undefined,
  filterState: Record<string, FilterPropsOptions>
) => {
  const activeHierarchicalCategoriesValuesMap: Record<string, { key: string; value: string }[] | undefined> = {};
  columns &&
    columns.map((column) => {
      const filteredItems = filterState[column.key];
      const asStringArray = filteredItems as TagsFilterOption[];
      const isActive = asStringArray.filter((item: TagsFilterOption) => item.checked).length > 0;
      if (isActive) {
        activeHierarchicalCategoriesValuesMap[column.key] = asStringArray
          .filter((item) => item.checked === 'on' && item.parent)
          .map((item) => {
            return { key: item.parent!, value: item.label };
          });
      }
    });
  return activeHierarchicalCategoriesValuesMap;
};

export const generateCountColumnsMap = (
  columns: FCOListViewColumn[] | undefined,
  filterState: Record<string, FilterPropsOptions>
) => {
  const activeCategoriesValuesMap: Record<string, string[] | undefined> = {};
  columns &&
    columns.map((column) => {
      const filteredItems = filterState[column.key];
      const asStringArray = filteredItems as { label: string; checked: string }[];

      if (asStringArray) {
        activeCategoriesValuesMap[column.key] = (asStringArray as { label: string; checked: string }[])
          .filter((option) => option.checked === 'on')
          .map((option) => option.label);
      }
    });

  return activeCategoriesValuesMap;
};

export const timeFormatter = (date: Date) => {
  if (date.getTime() === 0) {
    return '-';
  }
  return timeFormat('%Y-%m-%d %H:%M')(date);
};

export const castAsCategoryOptions: (options: (string | boolean)[]) => FilterOptionType[] = (options) => {
  return options.map((optionString) => {
    return { label: optionString as string };
  });
};

export const castFCOsAsCategoryOptions: (fcos: AnyFCO[]) => FilterOptionType[] = (options) => {
  return options.map((fco) => {
    return { label: fco.id as string, searchableLabel: fco.name };
  });
};

export const createdDatesExtent: (fcos: FCO[]) => [Date, Date] = (fcos) => {
  const allDates = fcos.filter((fco) => fco.createdDate as Date).map((fco) => fco.createdDate as Date);
  const attemptExtent = extent(allDates);
  return attemptExtent as [Date, Date];
};

export const lastModifiedDatesExtent: (fcos: FCO[]) => [Date, Date] = (fcos) => {
  const allDates = fcos.filter((fco) => fco.lastModifiedDate as Date).map((fco) => fco.lastModifiedDate as Date);
  const attemptExtent = extent(allDates);
  return [(attemptExtent[0] as Date) ?? new Date(), (attemptExtent[1] as Date) ?? new Date()];
};

export const getUniqueTagsFromFcos: (fcos: FCO[]) => Record<string, string[]> = (fcos) => {
  const allTagPairs = fcos
    .filter((fco) => fco.tags.length > 0)
    .map((fco) => fco.tags)
    .flat();

  const allTagPairsUniq = uniqWith(allTagPairs, isEqual);
  const groupedByKey = group(allTagPairsUniq, (pair) => pair.key);
  const returnStructure: Record<string, string[]> = {};
  groupedByKey.forEach((keyValue) => {
    const key = keyValue[0].key;
    const allValues = keyValue.map((tag) => tag.value);
    returnStructure[key] = allValues;
  });

  return returnStructure;
};

export const generateInitialFilterStateFromColumns = (columns: FCOListViewColumn[]) => {
  const state: Record<string, FilterPropsOptions | undefined> = {
    labelQuery: '',
  };

  columns.forEach((column) => {
    if (column.filterType && column.filterProps) {
      const key = column.key;
      state[key] = undefined;
      switch (column.filterType) {
        case FCOListFilterType.CATEGORY:
          state[key] = undefined;
          if (column.filterProps as FCOCategoricalFilterProps) {
            state[key] = (column.filterProps as FCOCategoricalFilterProps).options;
            if ((column.filterProps as FCOCategoricalFilterProps).allowsFilterByCount) {
              state[key + '_COUNT'] = undefined;
            }
          }
          break;
        case FCOListFilterType.DATE:
          state[key] = (column.filterProps as FCODateFilterProps).dateRange;
          break;
        case FCOListFilterType.NUMBER:
          state[key] = undefined;
          break;
        case FCOListFilterType.BOOLEAN:
          state[key] = [{ label: 'isTrue' }, { label: 'isFalse' }];
          break;
        case FCOListFilterType.TAGS:
          state[key] = undefined;
          if (column.filterProps as FCOTagFilterProps) {
            const getItems: CategoricalSelectorHierarchicalOption[] = [];

            Object.entries((column.filterProps as FCOTagFilterProps).tags).forEach(([category, values]) => {
              getItems.push({
                label: category,
                indent: 0,
              });

              values.forEach((option) => {
                getItems.push({
                  label: option,
                  indent: 1,
                  parent: category,
                });
              });
            });
            state[key] = getItems;
          }

          break;
      }
    }
  });

  return state;
};
