import { OnboardingStateEnums, OnboardingGlobalState, OnboardingUpdateAction } from './types';

const updateOnboarding = (onBoardingState: OnboardingStateEnums): OnboardingUpdateAction => {
  return {
    type: OnboardingGlobalState.UPDATE_ONBOARDING_STATE,
    onBoardingState: onBoardingState,
  };
};

export { updateOnboarding };
