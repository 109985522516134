import styled from '@emotion/styled';
import React, { FC, useEffect, useRef } from 'react';
import { GridRow } from './TableRow';
import { useWindowSize } from './useWindowSize';

interface ServingStatusHeaderRowProps {
  setMaxWidth: React.Dispatch<React.SetStateAction<number>>;
}

const ChartTableHeader = styled.div`
  background-color: ${({ theme }) => theme.colors.lightestShade};
  display: flex;
  padding: ${({ theme }) => theme.padding.s} ${({ theme }) => theme.padding.l};
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  font-size: ${({ theme }) => theme.font.fontSizes.xs};
  border-bottom: ${({ theme }) => theme.border.thin};

  :first-of-type {
    border-top-left-radius: ${({ theme }) => theme.border.radius.medium};
    border-right: ${({ theme }) => theme.border.thin};
  }

  :last-of-type {
    border-top-right-radius: ${({ theme }) => theme.border.radius.medium};
    border-left: ${({ theme }) => theme.border.thin};
  }
`;

const ServingStatusHeaderRow: FC<ServingStatusHeaderRowProps> = ({ setMaxWidth }) => {
  const timelineHeaderCellRef = useRef<HTMLDivElement | null>(null);
  const windowSize = useWindowSize();

  useEffect(() => {
    if (timelineHeaderCellRef.current) {
      setMaxWidth(timelineHeaderCellRef.current.getBoundingClientRect().width);
    }
  }, [windowSize, setMaxWidth]);

  return (
    <GridRow>
      <ChartTableHeader>Feature View</ChartTableHeader>
      <ChartTableHeader ref={timelineHeaderCellRef}>Materialization Status</ChartTableHeader>
      <ChartTableHeader>Last Processed</ChartTableHeader>
    </GridRow>
  );
};

export default ServingStatusHeaderRow;
