import styled from '@emotion/styled';
import React, { FC } from 'react';
import { useTectonTheme } from '@tecton/ComponentRedesign';

interface CardRowLayoutProps {
  children: React.ReactNode;
}

export const TwoCardRow: FC<CardRowLayoutProps> = ({ children }) => {
  const { theme } = useTectonTheme();

  const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: ${theme.padding.l};
    width: 100%;
  `;

  return <Container data-testid="two-card-row">{children}</Container>;
};

export const FullWidthCard: FC<CardRowLayoutProps> = ({ children }) => {
  const { theme } = useTectonTheme();

  const Container = styled.div`
    margin-bottom: ${theme.padding.l};
  `;

  return <Container data-testid="full-width-card">{children}</Container>;
};

export const CardGridLayout: FC<CardRowLayoutProps> = ({ children }) => {
  const { theme } = useTectonTheme();

  const Container = styled.div`
    display: grid;
    gap: ${theme.padding.l};
  `;

  return <Container data-testid="card-grid-layout">{children}</Container>;
};
