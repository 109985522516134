import { Breadcrumbs, useEuiFontSize, useTectonTheme } from '@tecton';
import { css } from '@emotion/css';
import React from 'react';

interface TectonBreadcrumbTitlesProps {
  breadcrumbs: React.ComponentProps<typeof Breadcrumbs>['breadcrumbs'];
}

const TectonBreadcrumbTitles = ({ breadcrumbs }: TectonBreadcrumbTitlesProps) => {
  const { theme } = useTectonTheme();

  const TectonBreadcrumbsCss = css`
    .euiBreadcrumb {
      ${useEuiFontSize('l')}
    }

    .euiBreadcrumb:not(:last-of-type):after {
      block-size: ${theme.size.s};
      inline-size: 3px;
    }
  `;

  return <Breadcrumbs className={TectonBreadcrumbsCss} breadcrumbs={breadcrumbs} truncate={false} />;
};

export default TectonBreadcrumbTitles;
