import { hiddenEditLinkRowCss } from '../shared/HiddenEditCell';

const ACLTableRowPropsFn = (row: { hasPriority?: boolean }) => {
  return {
    style: {
      backgroundColor: row.hasPriority ? '#e6f1f7' : 'inherit',
      className: hiddenEditLinkRowCss,
    },
  };
};

export default ACLTableRowPropsFn;
