import React, { FC } from 'react';
import {
  Link,
  Text,
  FlexGroup,
  FlexItem,
  DataSourceIcon,
  EntityIcon,
  FeatureServiceIcon,
  TransformationIcon,
  useTectonTheme,
  DatasetIcon,
  ToolTip,
} from '@tecton';
import { css } from '@emotion/css';

import { useNavigate } from 'react-router-dom';
import FeatureViewIcon from './FeatureViewIcon';
import TableContentIconWrapper from './TableContentIconWrapper';
import { Routes } from '../../core/routes';
import { FCO, FCOFields, FCOType } from '../../core/types/fcoTypes';
import styled from '@emotion/styled';

interface FCONameDisplayWithIconAndTooltipProps {
  fco: FCO | undefined;
  isModified?: boolean;
  modifiedName?: string;
  modifiedType?: FCOType;
  filterCallback?: () => void;
  hideLink?: boolean;
  canWrap?: boolean;
  inline?: boolean;
}

const FCONameDisplayWithIconAndTooltip: FC<FCONameDisplayWithIconAndTooltipProps> = ({
  fco,
  modifiedName,
  modifiedType,
  filterCallback,
  hideLink = false,
  canWrap = false,
  inline = false,
}) => {
  const { theme } = useTectonTheme();

  const navigate = useNavigate();
  // We don't use useParams here so we can use this component outside on none workspace path like /jobs/jobId
  const workspace = fco?.[FCOFields.WORKSPACE];

  let truncateTextCss;

  if (!canWrap) {
    truncateTextCss = css`
      max-width: 21em;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `;
  } else {
    truncateTextCss = css`
      white-space: revert;
      overflow: hidden;
      word-break: break-all;
    `;
  }

  const typeIconMap: Record<FCOType, React.ReactNode> = {
    [FCOType.DATA_SOURCE]: <DataSourceIcon />,
    [FCOType.ENTITY]: <EntityIcon />,
    [FCOType.TRANSFORMATION]: <TransformationIcon />,
    [FCOType.FEATURE_VIEW]: <FeatureViewIcon />,
    [FCOType.FEATURE_SERVICE]: <FeatureServiceIcon />,
    [FCOType.DATASET]: <DatasetIcon />,
    [FCOType.UNKNOWN]: <></>,
  };

  const typeRouteMap: Record<FCOType, string> = {
    [FCOType.DATA_SOURCE]: Routes.dataSource,
    [FCOType.ENTITY]: Routes.entity,
    [FCOType.TRANSFORMATION]: Routes.transformation,
    [FCOType.FEATURE_VIEW]: Routes.featureView,
    [FCOType.FEATURE_SERVICE]: Routes.featureService,
    [FCOType.DATASET]: Routes.savedFeatureDataFrame,
    [FCOType.UNKNOWN]: '',
  };

  const StyledTooltip = styled(ToolTip)`
    background-color: white;
    max-width: none;
    cursor: pointer important;

    div {
      background-color: white;
      color: black;
    }

    padding: ${theme.size.m};
  `;

  const emptyContent = (
    <Text style={{ fontSize: '14px' }} size="s">
      <h4 style={{ wordBreak: 'break-word', fontSize: '14px' }}>{modifiedName}</h4>
      <p
        className={css`
          color: ${theme.colors.mediumShade};
          cursor: 'pointer';
        `}
      >
        <em>This object has been recreated or deleted.</em>
      </p>
    </Text>
  );

  if (fco === undefined) {
    return (
      <StyledTooltip content={emptyContent} delay="regular" display="block">
        <FlexGroup
          gutterSize="s"
          style={{
            filter: 'saturate(0)',
            color: theme.colors.disabledText,
          }}
        >
          <FlexItem grow={false} style={{ justifyContent: 'center' }}>
            <TableContentIconWrapper>{typeIconMap[modifiedType!]}</TableContentIconWrapper>
          </FlexItem>
          <FlexItem
            style={{
              flexDirection: 'row',
              alignContent: 'center',
            }}
          >
            <div>
              <span style={{ fontWeight: 'bold', cursor: 'default' }}>{modifiedName}</span>
            </div>
          </FlexItem>
        </FlexGroup>
      </StyledTooltip>
    );
  }

  const tooltipContent = (
    <div style={{ maxWidth: '40em' }}>
      <Text style={{ fontSize: '14px' }} size="s">
        <h4 style={{ wordBreak: 'break-word', fontSize: '14px' }}>{fco.name}</h4>
        {fco[FCOFields.TRUNCATED_DESCRIPTION] !== 'No description provided.' ? (
          <>{fco[FCOFields.TRUNCATED_DESCRIPTION]}</>
        ) : (
          <p
            className={css`
              color: ${theme.colors.mediumShade};
            `}
          >
            <em>{fco[FCOFields.TRUNCATED_DESCRIPTION]}</em>
          </p>
        )}
      </Text>
    </div>
  );

  const newRoute = typeRouteMap[fco.fcoType]
    .replace(':workspace', workspace as string)
    .replace(':name', fco.name as string)
    .replace('/*', ''); // TODO: Find existing util for this

  return (
    <StyledTooltip content={tooltipContent} delay="regular" display="block">
      <FlexGroup gutterSize="s" alignItems="center">
        <FlexItem grow={false} style={{ justifyContent: 'center' }}>
          <TableContentIconWrapper>{typeIconMap[fco.fcoType]}</TableContentIconWrapper>
        </FlexItem>
        <FlexItem
          style={{
            flexDirection: 'row',
            alignContent: 'center',
            whiteSpace: 'normal',
            textOverflow: 'clip',
          }}
        >
          {hideLink && fco.name}
          {!hideLink && (
            <Link
              className={truncateTextCss}
              onClick={() => {
                navigate(newRoute);
              }}
              data-route={newRoute}
            >
              {fco.name}
            </Link>
          )}
        </FlexItem>
      </FlexGroup>
    </StyledTooltip>
  );
};

export default FCONameDisplayWithIconAndTooltip;
