import { FlexItem, Title, useEuiFontSize, useEuiPaddingSize, useTectonTheme } from '@tecton';
import { css } from '@emotion/css';
import { fcoTransitionTypes, pluralizeFcoTitle } from './util';
import { scrollIntoViewByElementId } from '../../utils/scrollIntoViewCallback';
import { SmallKeyValueTable } from '@shared';
import CountWrapper from './CountWrapper';
import { FcoTransitionType, StateUpdatePlanSummaryDiff } from '../../types/tecton_proto/data/state_update';
import { matchPath, useLocation } from 'react-router';
import { Routes } from '../../core/routes';

const PlanItemCountsTable = ({
  scrollId,
  itemsByFco,
}: {
  scrollId: string;
  itemsByFco: {
    fco_type: string;
    items: StateUpdatePlanSummaryDiff[];
    items_by_status: {
      status: FcoTransitionType;
      items: StateUpdatePlanSummaryDiff[];
    }[];
  };
}) => {
  const { pathname } = useLocation();
  const { theme } = useTectonTheme();
  const routePattern = Routes.planSummary.replace('/*', '');
  const pathMatch = matchPath(routePattern, pathname);
  const canShowJumpToSection = routePattern === pathMatch?.pattern.path;

  const planColors = theme.color.light?.planFcoTransitionTypeColorPairs;

  const planActionClass: Record<string, string> = {
    CREATE: 'action-is-created',
    RECREATE: 'action-is-recreated',
    UPDATE: 'action-is-updated',
    UPGRADE: 'action-is-upgraded',
    DELETE: 'action-is-deleted',
  };

  const fcoChangeTableCss = css`
    .tecton-key-values-table {
      td,
      th {
        padding-top: 2px;
        padding-bottom: 2px;
        ${useEuiFontSize('s')};
        color: ${theme.colors.darkShade};
      }

      th {
        padding-left: ${useEuiPaddingSize('s')};
        text-transform: uppercase;
        font-weight: 500;

        .action-is-created {
          color: ${planColors.CREATE.textColor};
        }
      }

      td {
        text-align: right;
      }
    }

    .jump-to-section-link {
      ${useEuiFontSize('xxs')}
      float: right;
      position: absolute;
      right: 0;
      bottom: 0.2em;
      visibility: hidden;
      text-decoration: underline;
    }

    &:hover .jump-to-section-link {
      visibility: visible;
    }

    .euiTitle {
      color: ${theme.colors.fullShade};
      font-weight: 500;
      position: relative;
    }
  `;

  return (
    <FlexItem className={fcoChangeTableCss}>
      <Title size="xxs">
        <h4>
          {pluralizeFcoTitle(itemsByFco.fco_type)}
          {canShowJumpToSection && (
            // We only want to show this when we are on the Plan Summary tab
            <a
              onClick={(e) => {
                e.preventDefault();
                scrollIntoViewByElementId(scrollId);
              }}
              className="jump-to-section-link"
              href={`#${scrollId}`}
            >
              Jump to Section
            </a>
          )}
        </h4>
      </Title>
      <SmallKeyValueTable
        attributes={itemsByFco.items_by_status.map((itemsByStatus) => {
          return {
            key: (
              <span className={planActionClass[itemsByStatus.status]}>{fcoTransitionTypes[itemsByStatus.status]}</span>
            ),
            value: <CountWrapper count={itemsByStatus.items.length} type={itemsByStatus.status} />,
          };
        })}
      />
    </FlexItem>
  );
};

export default PlanItemCountsTable;
