import React, { useContext } from 'react';
import { removeToast, ToastContext } from './ToastContext';
import { GlobalToastList, Toast } from '@tecton';

const GlobalToast = () => {
  const toastContext = useContext(ToastContext);

  const removeToastCallback = (toast: Toast) => {
    toastContext?.dispatchToast(removeToast(toast));
  };

  return (
    <GlobalToastList toasts={toastContext?.toasts.toasts} dismissToast={removeToastCallback} toastLifeTimeMs={6000} />
  );
};

export default GlobalToast;
