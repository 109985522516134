import React from 'react';
import { NotificationBadge, Tab, Tabs, useTectonTheme } from '@tecton';
import { css } from '@emotion/css';
import tectonTheme from '../shared/tectonTheme';

type TectonTagProps = {
  route?: string | undefined;
  label: string | React.ReactNode;
  count?: number | string | undefined;
  isSelected?: boolean | undefined;
  onClick: () => void;
};

const TectonTabs = ({ tabs }: { tabs: TectonTagProps[] }) => {
  const { theme } = useTectonTheme();

  const TectonTabsCss = css`
    button {
      border-radius: 0;
    }

    button > span.euiTab__content {
      font-size: ${theme.font.scale.s * theme.base}px;
    }

    button[aria-selected='false'] > span.euiTab__content {
      color: ${theme.colors.mediumShade};
      font-weight: 600;
    }

    button[aria-selected='true'] {
      box-shadow: inset 0 -2px 0 ${tectonTheme.colors.tectonRed};

      > span.euiTab__content {
        color: ${theme.colors.darkestShade};
        font-weight: 700;
      }
    }
  `;

  return (
    <Tabs className={TectonTabsCss} data-testid="Tecton_Tabs">
      {tabs.map((tab, index) => {
        return (
          <Tab
            key={index}
            onClick={tab.onClick}
            isSelected={tab.isSelected}
            data-testid="Tecton_Tab"
            data-route={tab.route}
          >
            <span data-testid={`tab-name-${tab?.label?.toString()?.toLowerCase()?.replaceAll(' ', '-')}`}>
              {tab.label}{' '}
            </span>
            {tab.count !== undefined && (
              <NotificationBadge color="subdued" style={{ marginLeft: '0.4em', cursor: 'pointer' }}>
                {tab.count}
              </NotificationBadge>
            )}
          </Tab>
        );
      })}
    </Tabs>
  );
};

export default TectonTabs;
