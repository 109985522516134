export default () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.91178 1.40278L3.57844 3.18056C3.03399 3.36204 2.66675 3.87156 2.66675 4.44547V12.6657C2.66675 13.0942 2.87278 13.4967 3.22047 13.7473C3.56816 13.9979 4.01512 14.0661 4.42172 13.9306L9.75505 12.1528C10.2995 11.9713 10.6667 11.4618 10.6667 10.8879V2.66769C10.6667 2.2391 10.4607 1.83664 10.113 1.58604C9.76533 1.33543 9.31837 1.26725 8.91178 1.40278Z"
        stroke="#323232"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4 14H12C12.7364 14 13.3333 13.4031 13.3333 12.6667V4.66671C13.3333 3.93033 12.7364 3.33337 12 3.33337H10.6667"
        stroke="#525866"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
