import { EuiSelectableOption } from '@elastic/eui';
import styled from '@emotion/styled';
import React, { FC } from 'react';
import { useTectonTheme } from '@tecton/ComponentRedesign';
import { css } from '@emotion/css';
import { Selectable } from '../../_eui_components';

interface TectonSelectableProps {
  options: EuiSelectableOption[];
  width?: string | number;
  height?: string | number;
  changeCallback?: (options: any[]) => void;
  key: string;
  singleSelect?: boolean;
  searchable?: boolean;
  renderOption?: (item: any) => React.ReactNode;
}

const StyledSelectable = styled(Selectable)<{ width?: string | number; customHeight?: string | number }>`
  width: ${(props) => props.width || '480px'};
  height: ${(props) => props.customHeight || 'full'};

  .euiSelectableListItem {
    background-color: ${({ theme }) => theme.colors.emptyShade};
    color: ${({ theme }) => theme.colors.text};

    border-bottom: none;

    svg {
      stroke: currentColor;
    }

    :hover {
      background-color: ${({ theme }) => theme.colors.body};

      span {
        color: ${({ theme }) => theme.colors.fullShade};
        text-decoration: none !important;
      }
    }
  }

  .euiSelectableListItem-isFocused {
    background-color: ${({ theme }) => theme.colors.body};
    color: ${({ theme }) => theme.colors.fullShade};
  }

  input::selected {
    outline: none !important;
  }
`;

const TectonSelectable: FC<TectonSelectableProps> = ({
  options,
  width = '100%',
  height,
  changeCallback,
  singleSelect,
  key,
  searchable,
  renderOption = (item) => <>{item.label}</>,
}) => {
  const { theme } = useTectonTheme();
  const searchStyle = css`
    outline: none;
    transition: none;
    box-shadow: none;
    background-image: none;
    background-size: none;
    background-color: ${theme.colors.emptyShade};
    border: ${theme.border.thin};
  `;

  const searchProps = searchable
    ? {
        searchable,
        searchProps: {
          placeholder: 'Filter list',
          compressed: true,
          className: searchStyle,
        },
      }
    : {};

  return (
    <>
      <>
        <StyledSelectable
          options={options}
          {...searchProps}
          listProps={{
            onFocusBadge: false,
          }}
          key={key}
          singleSelection={singleSelect}
          onChange={(newOptions) => {
            changeCallback && changeCallback(newOptions);
          }}
          width={width}
          data-testid="selectable-input"
          height={'full'}
          customHeight={height}
          renderOption={renderOption}
        >
          {(list, search) => (
            <>
              {search}
              {list}
            </>
          )}
        </StyledSelectable>
      </>
    </>
  );
};

export default TectonSelectable;
