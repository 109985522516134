const scrollIntoViewCallbackGenerator = (elementId: string) => {
  return () => {
    const element = document.getElementById(elementId);

    element && element.scrollIntoView({ behavior: 'smooth' });
  };
};

const scrollIntoViewByElementId = (elementId: string) => {
  const element = document.getElementById(elementId);

  if (!element) {
    console.warn(`No element with id=${elementId} found`);
  } else {
    element.scrollIntoView({ behavior: 'smooth' });
  }
};

export { scrollIntoViewCallbackGenerator, scrollIntoViewByElementId };
