/* eslint-disable */

export const protobufPackage = "tecton_proto.auth";

export enum ResourceType {
  RESOURCE_TYPE_UNSPECIFIED = "RESOURCE_TYPE_UNSPECIFIED",
  RESOURCE_TYPE_USER = "RESOURCE_TYPE_USER",
  RESOURCE_TYPE_ORGANIZATION = "RESOURCE_TYPE_ORGANIZATION",
  RESOURCE_TYPE_WORKSPACE = "RESOURCE_TYPE_WORKSPACE",
  RESOURCE_TYPE_SERVICE_ACCOUNT = "RESOURCE_TYPE_SERVICE_ACCOUNT",
  RESOURCE_TYPE_PRINCIPAL_GROUP = "RESOURCE_TYPE_PRINCIPAL_GROUP",
  RESOURCE_TYPE_SECRET = "RESOURCE_TYPE_SECRET",
  RESOURCE_TYPE_ACCESS = "RESOURCE_TYPE_ACCESS",
  RESOURCE_TYPE_DEPLOYMENT_SETTINGS = "RESOURCE_TYPE_DEPLOYMENT_SETTINGS",
  RESOURCE_TYPE_DATASET = "RESOURCE_TYPE_DATASET",
  RESOURCE_TYPE_FEATURES = "RESOURCE_TYPE_FEATURES",
  RESOURCE_TYPE_CONFIG = "RESOURCE_TYPE_CONFIG",
  RESOURCE_TYPE_METRIC = "RESOURCE_TYPE_METRIC",
  RESOURCE_TYPE_DATA = "RESOURCE_TYPE_DATA",
  RESOURCE_TYPE_MATERIALIZATION_STATE = "RESOURCE_TYPE_MATERIALIZATION_STATE",
  RESOURCE_TYPE_MEMBERS = "RESOURCE_TYPE_MEMBERS",
  RESOURCE_TYPE_PRINCIPAL_GROUPS_MEMBERSHIPS = "RESOURCE_TYPE_PRINCIPAL_GROUPS_MEMBERSHIPS",
  RESOURCE_TYPE_PLATFORM = "RESOURCE_TYPE_PLATFORM",
  RESOURCE_TYPE_SECRET_SCOPE = "RESOURCE_TYPE_SECRET_SCOPE",
  RESOURCE_TYPE_CLIENT_SECRET = "RESOURCE_TYPE_CLIENT_SECRET",
}
