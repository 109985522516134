import { FeatureFlags } from '../../components/context/UserSettingsContext';

// TODO: Remove all these helper functions
export const hasFeatureFlagHasSeenOnboardingWelcomePage = (): boolean => {
  return localStorage.getItem(FeatureFlags.HAS_SEEN_ONBOARDING_WELCOME_PAGE) === 'true';
};

export const setFeatureFlagHasSeenOnboardingWelcomePage = () => {
  return localStorage.setItem(FeatureFlags.HAS_SEEN_ONBOARDING_WELCOME_PAGE, 'true');
};

export const canShowOnboarding = () => {
  return localStorage.getItem(FeatureFlags.SHOW_ACTIVATION_ONBOARDING) === 'true';
};

export const canShowNewFCOListPages = () => {
  return localStorage.getItem(FeatureFlags.SHOW_NEW_FCO_LISTS) === 'true';
};

export const canShowNewTopNav = () => {
  return localStorage.getItem(FeatureFlags.SHOW_NEW_TOP_NAV) === 'true';
};
