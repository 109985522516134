export enum ConfigState {
  HAS_REQUESTED_CONFIGURATION = 'HAS_REQUESTED_CONFIGURATION',
  HAS_REQUEST_ERRORS = 'HAS_REQUEST_ERRORS',
  IS_ONBOARDING_ENABLED = 'IS_ONBOARDING_ENABLED',
}

export interface ConfigUpdateAction {
  type: typeof ConfigState.HAS_REQUESTED_CONFIGURATION;
  data: any;
}

export interface ConfigUpdateErrorAction {
  type: typeof ConfigState.HAS_REQUEST_ERRORS;
  error: Error;
}

export interface ConfigUpdateIsOnboardingEnabled {
  type: typeof ConfigState.IS_ONBOARDING_ENABLED;
  isOnboardingEnabled: boolean;
}

export type ConfigActionTypes = ConfigUpdateAction | ConfigUpdateErrorAction | ConfigUpdateIsOnboardingEnabled;
