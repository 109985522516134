import { EuiBadge, tint } from '@elastic/eui';
import styled from '@emotion/styled';
import React, { FC } from 'react';
import { BadgeVariants, ColorPairProps } from './lib';
import { useTectonTheme, FlexGroup, FlexItem } from '@tecton/ComponentRedesign';

interface TagProps {
  label: string;
  variant?: BadgeVariants;
  icon?: React.ReactNode;
  onClick?: () => void;
}

const StyledTag = styled(EuiBadge)<{ background: string; foreground: string }>`
  padding: 2px 8px;
  border-radius: 6px;
  background: ${(props) =>
    props.background} !important; // Have to add !important here because otherwise EUI overrides the CSS with an HTML attribute
  color: ${(props) =>
    props.foreground} !important; // Have to add !important here because otherwise EUI overrides the CSS with an HTML attribute
`;

const StyledLabel = styled.div`
  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const StyledIconContainer = styled.div<{ background: string; foreground: string }>`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  :hover {
    cursor: pointer;
    background-color: ${(props) => tint(props.foreground, 0.875)};
  }

  path {
    stroke: ${(props) => props.foreground};
    fill: ${(props) => props.foreground};
  }
`;

const TagCancelIcon = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 5L11 11" stroke="#523771" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M5 11L11 5" stroke="#523771" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);

const Tag: FC<TagProps> = ({ label, variant = 'default', icon, onClick = () => {} }) => {
  const { theme } = useTectonTheme();

  const colorPairs: ColorPairProps = {
    default: { foreground: theme.colors.text, background: theme.colors.lightestShade },
    hollow: { foreground: theme.colors.text, background: theme.colors.emptyShade },
    custom: {
      foreground: theme.colors.text,
      background: theme.colors.lightestShade,
    },
    primary: {
      foreground: theme.categoryColorPairs[1].text, //Viz/Behind Text/_euiColorVis1_behindText
      background: theme.categoryColorPairs[1].behindText,
    },
    accent: {
      foreground: theme.categoryColorPairs[3].text,
      background: theme.categoryColorPairs[3].behindText,
    },
    success: {
      foreground: theme.categoryColorPairs[0].text,
      background: theme.categoryColorPairs[0].behindText,
    },
    danger: {
      foreground: theme.categoryColorPairs[9].text,
      background: theme.categoryColorPairs[9].behindText,
    },
    warning: {
      foreground: theme.categoryColorPairs[5].text,
      background: theme.categoryColorPairs[5].behindText,
    },
    disabled: {
      foreground: theme.colors.disabledText,
      background: tint(theme.colors.disabled, 0.9),
    },
  };

  const colorPair = colorPairs[variant];

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div onClick={onClick}>
      <StyledTag foreground={colorPair.foreground} background={colorPair.background} data-test-id={`tag-${label}`}>
        <FlexGroup responsive={false} gutterSize="xs">
          {icon && <FlexItem>{icon}</FlexItem>}
          <FlexItem>
            <StyledLabel>{label}</StyledLabel>
          </FlexItem>
          <FlexItem>
            <StyledIconContainer foreground={colorPair.foreground} background={colorPair.background}>
              {TagCancelIcon}
            </StyledIconContainer>
          </FlexItem>
        </FlexGroup>
      </StyledTag>
    </div>
  );
};

export default Tag;
