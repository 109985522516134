import React from 'react';
import { css } from '@emotion/css';
import { LoadingContent, useTectonTheme } from '../@tecton';
import ACLLoadingContent from './ACLLoadingContent';

const ProfileLayout = ({
  metadataColumn,
  contentColumn,
  isLoading = false,
}: {
  metadataColumn: React.ReactNode;
  contentColumn: React.ReactNode;
  isLoading?: boolean;
}) => {
  const { theme } = useTectonTheme();

  // Add horizontal line between every
  // metadata section
  const metadataColumnCss = css`
    > div {
      margin: ${theme.size.xl} 0;
    }

    > div:not(:first-of-type):before {
      content: '';
      display: block;
      margin: ${theme.size.xl} 0;
      border-top: 1px solid ${theme.border.color};
    }
  `;

  return isLoading ? (
    <ACLLoadingContent />
  ) : (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '32em 1fr',
        minHeight: 'calc(100vh - 80px)',
      }}
    >
      {isLoading ? (
        <LoadingContent />
      ) : (
        <>
          <div className={metadataColumnCss} style={{ padding: theme.size.xl }}>
            {metadataColumn}
          </div>
          <div
            style={{
              padding: theme.size.xl,
              borderLeft: `1px solid ${theme.border.color}`,
            }}
          >
            {contentColumn}
          </div>
        </>
      )}
    </div>
  );
};

export default ProfileLayout;
