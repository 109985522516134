import React, { ChangeEvent, useContext } from 'react';
import { EuiBasicTableColumn, Button, Link, EuiSelectableOption, Spacer, DebouncedFieldSearch } from '@tecton';

import { useNavigate, useParams } from 'react-router-dom';
import { TectonBasicTable } from '@shared';
import { ACLTableControlLayout } from './ACLLayout';
import TableActionButtonWithDisableMode from '../shared/TableActionButtonWithDisableMode';
import GroupAddUsersModal from './modals/GroupAddUsersModal';
import RemoveFromGroupConfirmationModal from './modals/RemoveFromGroupConfirmationModal';
import { ACLGroupModal } from './aclUtils';
import { useUserSettings } from '../context/UserSettingsContext';
import { ACLGroupProfileContextGQL } from './ACLGroupProfileContextGQL';
import { ServiceAccount } from '../../api/gql/graphql';

type assignableMemberOption = EuiSelectableOption<{
  data: {
    name: string;
    id: string;
  };
}>;

const ACLGroupServiceAccountsGQL = () => {
  const navigate = useNavigate();
  const groupProfileContext = useContext(ACLGroupProfileContextGQL);
  const { group } = useParams();
  const { isAdmin } = useUserSettings();

  if (!group) {
    throw new Error('Route has no "group" parameter.');
  }

  let addMembersModal;

  if (groupProfileContext?.modal === ACLGroupModal.ADD_MEMBER_TO_GROUP) {
    addMembersModal = (
      <GroupAddUsersModal
        principalType={'Service Accounts'}
        groupId={groupProfileContext?.group?.name ?? ''}
        options={groupProfileContext?.assignableServiceAccounts ?? []}
        setOptions={(options: assignableMemberOption[]) => {
          groupProfileContext?.updateServiceAccountCandidateMemberOptions?.call(groupProfileContext, options);
        }}
        optionsAreLoading={groupProfileContext?.isLoading ?? false}
        onClose={() => {
          groupProfileContext?.closeModal?.call(groupProfileContext);
        }}
        confirmChanges={() => {
          groupProfileContext?.confirmAddServiceAccountMembers?.call(groupProfileContext);
        }}
        isLoading={groupProfileContext?.isModalLoading ?? false}
        errorMessages={undefined}
      />
    );
  }

  // Remove User Confirmation
  let removeUserFromGroupModal;

  if (groupProfileContext?.modal === ACLGroupModal.REMOVE_MEMBER_FROM_GROUP) {
    removeUserFromGroupModal = (
      <RemoveFromGroupConfirmationModal
        principalId={groupProfileContext?.selectedServiceAccountToDelete?.name ?? ''}
        groupName={groupProfileContext?.group?.name ?? ''}
        isLoading={groupProfileContext?.isModalLoading ?? false}
        errorMessages={groupProfileContext?.errorMessages}
        onClose={() => {
          groupProfileContext?.closeModal?.call(groupProfileContext);
        }}
        confirmChanges={() => {
          groupProfileContext?.confirmRemoveServiceAccountFromGroup?.call(groupProfileContext);
        }}
      />
    );
  }

  const columns: EuiBasicTableColumn<ServiceAccount>[] = [
    {
      field: 'name',
      name: 'Name',
      render: (field: string, row: ServiceAccount) => {
        return (
          <Link
            onClick={() => {
              navigate(`/settings/accounts-and-access/service-accounts/${row.id}`);
            }}
          >
            {field}
          </Link>
        );
      },
    },
    {
      name: 'Actions',
      width: '5em',
      actions: [
        {
          name: 'Remove from Group',
          isPrimary: true,
          description: 'Remove this user from group',
          'data-test-subj': 'action-delete',
          render: (row: ServiceAccount) => {
            return (
              <TableActionButtonWithDisableMode
                isAdmin={isAdmin ?? false}
                requiresAdmin
                iconType={'trash'}
                color="danger"
                isDisabled={false}
                enabledMessage={'Remove this user from group'}
                disabledMessage={'You must be an admin to edit group membership'}
                onClick={() => {
                  groupProfileContext?.showServiceAccountDeleteModal?.call(groupProfileContext, row);
                }}
              />
            );
          },
        },
      ],
    },
  ];

  return (
    <div>
      <ACLTableControlLayout
        search={
          <DebouncedFieldSearch
            fullWidth
            placeholder="Search for Member"
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              groupProfileContext?.updateServiceAccountSearch?.call(groupProfileContext, event?.target?.value);
            }}
          />
        }
        actionButtons={
          <Button
            onClick={() => {
              groupProfileContext?.showAddMemberModal?.call(groupProfileContext);
            }}
            fill
            isDisabled={false}
            data-testid="add-service-account-to-group-button"
            title={isAdmin ? 'Add Service Account to Group' : 'Administrator Privileges Required'}
            disabled={!isAdmin}
          >
            Add Service Account to Group
          </Button>
        }
      />
      <Spacer size="l" />
      <TectonBasicTable
        items={groupProfileContext?.assignedServiceAccounts ?? []}
        columns={columns}
        pageIndex={0}
        pageSize={0}
        totalItemCount={0}
      />
      {addMembersModal}
      {removeUserFromGroupModal}
    </div>
  );
};

export default ACLGroupServiceAccountsGQL;
