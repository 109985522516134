import { EuiSwitch } from '@elastic/eui';
import styled from '@emotion/styled';
import React, { FC } from 'react';
import { useTectonTheme } from '@tecton/ComponentRedesign';

interface TectonSwitchProps {
  label: React.ReactNode;
  checked: boolean;
  disabled?: boolean;
  setChecked: React.Dispatch<React.SetStateAction<boolean>>;
  onChange?: VoidFunction;
}

const StyledSwitch = styled(EuiSwitch)`
  .euiSwitch__body {
    background-color: ${({ theme }) => theme.colors.fullShade};
  }

  height: 10px;
`;

const LabelWrapper = styled.div`
  font-size: ${({ theme }) => theme.font.fontSizes.xs};
  color: ${({ theme }) => theme.colors.text};
`;

const TectonSwitch: FC<TectonSwitchProps> = ({ label, checked, disabled, setChecked, onChange }) => {
  const { theme } = useTectonTheme();

  return (
    <StyledSwitch
      data-testid="switch-input"
      disabled={disabled}
      label={<LabelWrapper>{label}</LabelWrapper>}
      checked={checked}
      onChange={(event) => {
        setChecked(event.target.checked);
        onChange?.call(this);
      }}
      color={theme.colors.fullShade}
    />
  );
};

export default TectonSwitch;
TectonSwitch;
