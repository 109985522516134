import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { getFeatureFlagsAction } from './actions';

const useFeatureFlagsHook = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // In the future, when we actually implement feature flags properly,
    // config should be generated/fetched here, and then inserted
    // into Redux via `setFeatureFlags`

    const configs = {
      customer: window._env_.CUSTOMER,
      oktaEnabled: window._env_.OKTA_ENABLED === 'true' || window._env_.OKTA_ENABLED === true,
    };

    dispatch({
      type: getFeatureFlagsAction.success().type,
      payload: configs,
    });
  }, [dispatch]);
};

export { useFeatureFlagsHook };
