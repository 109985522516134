import React from 'react';
import _ from 'lodash';
import EnabledDisabled from '../../components/EnabledDisabled';
import { FeatureViewType } from '../../core/types/feature-view';
import Tags from '../../components/Tags';
import FeatureViewTypeView from '../../components/FeatureViewTypeView';
import {
  DefaultClusterConfig,
  ExistingClusterConfig,
  JsonClusterConfig,
  NewClusterConfig,
  RiftClusterConfig,
} from '../../types/tecton_proto/args/feature_view';

export const getFeatureViewTypes = () => {
  const featureViewTypes: OptionsDropdownItemInterface[] = [];

  const renderFvType = (option: OptionsDropdownItemInterface) => {
    return <FeatureViewTypeView featureType={option.value} />;
  };

  for (const type in FeatureViewType) {
    let value = type;

    switch (type) {
      case FeatureViewType.STREAM:
        value = 'Stream';
        break;
      case FeatureViewType.BATCH || FeatureViewType.CUSTOM_BATCH:
        value = 'Batch';
        break;
      case FeatureViewType.STREAM_WINDOW_AGGREGATE:
        value = 'Stream Window Aggregate';
        break;
      case FeatureViewType.BATCH_WINDOW_AGGREGATE:
        value = 'Batch Window Aggregate';
        break;
      case FeatureViewType.ON_DEMAND:
        value = 'On Demand';
        break;
      case FeatureViewType.FEATURE_TABLE:
        value = 'Feature Table';
        break;
      case FeatureViewType.UNKNOWN:
        value = 'Unknown';
        break;
      default:
        value = '';
    }

    if (value !== '') {
      featureViewTypes.push({
        type: type,
        value: value,
        group: 'Type',
        isSelected: false,
        renderLabel: renderFvType,
        attributePath: 'featureView.featureType',
      });
    }
  }
  return featureViewTypes;
};

export const getUsableFeatureViewTypes = () => {
  return _.filter(getFeatureViewTypes(), (featureType) => {
    return featureType.type !== 'UNKNOWN';
  });
};

export interface OptionsDropdownItemInterface {
  type: string;
  value: string;
  group: string;
  isSelected: boolean;
  renderLabel?: (option: OptionsDropdownItemInterface) => React.ReactNode;
  labelValue?: string;
  attributePath: string;
}

export interface OptionGroupItemInterface {
  groupLabel: string;
  values: OptionsDropdownItemInterface[];
}

const renderTags = (option: OptionsDropdownItemInterface) => {
  const tags = JSON.parse(option.value);
  return <Tags tags={tags} emptyTextValue={'No Tags Set'} />;
};

export const getTagsOptionsForFilterDropdown = (featureViews: any[]): OptionsDropdownItemInterface[] => {
  let tagLists: OptionsDropdownItemInterface[] = [];

  if (featureViews.length > 0) {
    // Create a entities list for feature views
    featureViews.forEach((fv: any) => {
      const tagsText = JSON.stringify(fv.featureView.tags);

      if (fv.featureView && fv.featureView.tags) {
        tagLists.push({
          type: 'tags',
          value: tagsText,
          group: 'Tags',
          isSelected: false,
          attributePath: 'featureView.tags',
          renderLabel: renderTags,
        });
      }
    });

    tagLists = _.uniqBy(tagLists, 'value');
  }

  return tagLists;
};

export const getEntitiesOptionsForFilterDropdown = (featureViews: any[]): OptionsDropdownItemInterface[] => {
  let entitiesList: OptionsDropdownItemInterface[] = [];

  if (featureViews.length > 0) {
    // Create a entities list for feature views
    featureViews.forEach((fv: any) => {
      if (fv.entities && Array.isArray(fv.entities)) {
        fv.entities.forEach((element: any) => {
          entitiesList.push({
            type: 'entities',
            value: element,
            group: 'Entities',
            isSelected: false,
            attributePath: 'entities',
          });
        });
      }
    });

    entitiesList = _.uniqBy(entitiesList, 'value');
  }

  return entitiesList;
};

export const getMaterializationOptionsForFilterDropdown = (): OptionsDropdownItemInterface[] => {
  let materializationLists: OptionsDropdownItemInterface[] = [];

  const renderMaterialization = (option: OptionsDropdownItemInterface) => {
    const isEnabled = option.value === 'true';
    return <EnabledDisabled isEnabled={isEnabled} prefix={option.labelValue} />;
  };

  materializationLists.push({
    type: 'materialization',
    value: 'true',
    group: 'Materialization',
    isSelected: false,
    renderLabel: renderMaterialization,
    labelValue: 'Online_',
    attributePath: 'materialization.online',
  });

  materializationLists.push({
    type: 'materialization',
    value: 'false',
    group: 'Materialization',
    isSelected: false,
    renderLabel: renderMaterialization,
    labelValue: 'Online_',
    attributePath: 'materialization.online',
  });
  materializationLists.push({
    type: 'materialization',
    value: 'true',
    group: 'Materialization',
    isSelected: false,
    renderLabel: renderMaterialization,
    labelValue: 'Offline_',
    attributePath: 'materialization.offline',
  });
  materializationLists.push({
    type: 'materialization',
    value: 'false',
    group: 'Materialization',
    isSelected: false,
    renderLabel: renderMaterialization,
    labelValue: 'Offline_',
    attributePath: 'materialization.offline',
  });

  return materializationLists;
};

export const groupOptions = (options: OptionsDropdownItemInterface[]): OptionGroupItemInterface[] => {
  const grouped = _.groupBy(options, 'group');
  const keys = Object.keys(grouped);
  const optionGroupItems: OptionGroupItemInterface[] = keys.map((key: string) => {
    const values: OptionsDropdownItemInterface[] = grouped[`${key}`];
    return { groupLabel: key, values: values };
  });

  return optionGroupItems;
};

export const getOwnersOptionsForFilterDropdown = (featureViews: any[]): OptionsDropdownItemInterface[] => {
  let ownersList: OptionsDropdownItemInterface[] = [];

  if (featureViews.length > 0) {
    // Create a owner list for feature views
    featureViews.forEach((fv: any) => {
      if (fv.owner) {
        ownersList.push({
          type: 'owner',
          value: fv.owner,
          group: 'Owner',
          isSelected: false,
          attributePath: 'owner',
        });
      }
    });

    ownersList = _.uniqBy(ownersList, 'value');
  }

  return ownersList;
};

export const getFilterSelection = (filterOptions: OptionGroupItemInterface[]): OptionGroupItemInterface[] => {
  const groups = filterOptions.map((group: OptionGroupItemInterface) => {
    const groupCopy = _.cloneDeep(group);
    const selectedValues = group.values.filter((i) => i.isSelected);
    groupCopy.values = selectedValues;
    return groupCopy;
  });
  return groups.filter((group) => group.values.length > 0);
};

export enum ClusterConfigurationAttributeType {
  KEY_VALUE,
  KEY_VALUE_WITH_TOOLTIP_DESCRIPTION,
  DATA_BRICKS_JSON,
  EMR_JSON,
  DATA_PROC_JSON,
  PYTHON_DEPENDENCIES,
  CONFIG_OBJECT_DISPLAY,
}

// Deprecate this interface once we refactor all the cluster configuration
export interface ClusterConfigurationInterface {
  propertyName: string;
  label: string;
  value: JsonClusterConfig | React.ReactNode | string | string[];
  attributeDescription?: string;
  size: number; // TODO: Remove this once the old FV UI is deleted
  attributeConfigurationType: ClusterConfigurationAttributeType; // Tells the UI on how to possible render this attribute
}

export enum ClusterConfigType {
  EXISTING_CLUSTER,
  NEW_DATABRICKS,
  NEW_EMR,
  IMPLICIT_CONFIG,
  JSON_DATABRICKS,
  JSON_EMR,
  JSON_DATAPROC,
  RIFT,
}

export interface ClusterConfigInterface {
  clusterConfig:
    | ExistingClusterConfig
    | NewClusterConfig
    | ExistingClusterConfig
    | DefaultClusterConfig
    | JsonClusterConfig
    | RiftClusterConfig;
  type: ClusterConfigType; // Tells the UI on how to possible render this attribute
}

export const getClusterConfigurationProperties = (
  configuration: any,
  type: string
): ClusterConfigurationInterface[] => {
  const keys: string[] = Object.keys(configuration);
  const PROPERTY_TO_VALUE_MAP: any = {
    instance_type: {
      label: 'Instance',
      size: 3,
    },
    instance_availability: {
      label: 'Instance Availability',
      size: 4,
    },
    number_of_workers: {
      label: 'Number of Workers',
      size: 3,
    },
    root_volume_size_in_gb: {
      label: 'Root Volume Size In GB',
      size: 6,
    },
    existing_cluster_id: {
      label: 'Existing Cluster ID',
      size: 6,
    },
    first_on_demand: {
      label: 'First On Demand',
      size: 6,
    },
  };

  const clusterConfigurationProperties = keys.map((key: string) => {
    // Set the key as default just incase we don't have a mapping for labels and size;
    let label: string = key;
    let size = 3;

    if (PROPERTY_TO_VALUE_MAP[`${key}`]) {
      // We find mapping value
      label = PROPERTY_TO_VALUE_MAP[`${key}`].label;
      size = PROPERTY_TO_VALUE_MAP[`${key}`].size;
    }

    return {
      propertyName: key,
      label: label,
      value: configuration[`${key}`],
      size: size,
      attributeConfigurationType: ClusterConfigurationAttributeType.KEY_VALUE,
    };
  });

  const typeConfiguration: ClusterConfigurationInterface = {
    propertyName: type,
    label: 'Type',
    value: type,
    size: 3,
    attributeConfigurationType: ClusterConfigurationAttributeType.KEY_VALUE,
  };

  clusterConfigurationProperties.unshift(typeConfiguration);

  return clusterConfigurationProperties;
};

export const getClusterConfiguration = (configuration: any): ClusterConfigurationInterface[] => {
  let clusterConfiguration: any[] = [];

  if (configuration.hasOwnProperty('new_databricks') && Object.keys(configuration.new_databricks).length > 0) {
    clusterConfiguration = getClusterConfigurationProperties(configuration.new_databricks, 'Databricks');
  } else if (
    configuration.hasOwnProperty('existing_cluster') &&
    Object.keys(configuration.existing_cluster).length > 0
  ) {
    clusterConfiguration = getClusterConfigurationProperties(configuration.existing_cluster, 'Existing Cluster');
  } else if (configuration.hasOwnProperty('new_emr') && Object.keys(configuration.new_emr).length > 0) {
    clusterConfiguration = getClusterConfigurationProperties(configuration.new_emr, 'EMR');
  }

  return clusterConfiguration;
};

export const canShowClusterConfiguration = (materializationParams: any) => {
  const batchMaterialization = _.get(materializationParams, 'batch_materialization');
  const streamMaterialization = _.get(materializationParams, 'stream_materialization');
  return !_.isEmpty(batchMaterialization) || !_.isEmpty(streamMaterialization);
};

export const pullArrayValueByProperty = (objects: any[], key: any): any => {
  // Will alter the original array by pulling out the found key
  const index = _.findIndex(objects, key);
  const value = objects.splice(index, index + 1);
  return value[0];
};

export enum DataTypeEnum {
  DATA_TYPE_UNKNOWN = 'DATA_TYPE_UNKNOWN',
  DATA_TYPE_INT32 = 'DATA_TYPE_INT32',
  DATA_TYPE_INT64 = 'DATA_TYPE_INT64',
  DATA_TYPE_FLOAT32 = 'DATA_TYPE_FLOAT32',
  DATA_TYPE_FLOAT64 = 'DATA_TYPE_FLOAT64',
  DATA_TYPE_STRING = 'DATA_TYPE_STRING',
  DATA_TYPE_BOOL = 'DATA_TYPE_BOOL',
  DATA_TYPE_TIMESTAMP = 'DATA_TYPE_TIMESTAMP',
  DATA_TYPE_ARRAY = 'DATA_TYPE_ARRAY',
  DATA_TYPE_STRUCT = 'DATA_TYPE_STRUCT',
}

export function dataTypeToString(dataType: any): string | null {
  switch (dataType.type) {
    case DataTypeEnum.DATA_TYPE_INT32: {
      return 'Int32';
    }
    case DataTypeEnum.DATA_TYPE_INT64: {
      return 'Int64';
    }
    case DataTypeEnum.DATA_TYPE_FLOAT32: {
      return 'Float32';
    }
    case DataTypeEnum.DATA_TYPE_FLOAT64: {
      return 'Float64';
    }
    case DataTypeEnum.DATA_TYPE_STRING: {
      return 'String';
    }
    case DataTypeEnum.DATA_TYPE_BOOL: {
      return 'Bool';
    }
    case DataTypeEnum.DATA_TYPE_TIMESTAMP: {
      return 'Timestamp';
    }
    case DataTypeEnum.DATA_TYPE_ARRAY: {
      return 'Array(' + dataTypeToString(dataType.array_element_type) + ')';
    }
    case DataTypeEnum.DATA_TYPE_STRUCT: {
      let fieldStrings: string[] = [];
      dataType.struct_fields.forEach((field: any) => {
        fieldStrings.push('"' + field.name + '":' + dataTypeToString(field.data_type));
      });
      return 'Struct(' + fieldStrings.join(', ') + ')';
    }
    default: {
      return null;
    }
  }
}
