export class ServiceAccount {
  private proto: any = null;

  constructor(proto: any) {
    this.proto = proto;
  }

  get _proto(): any {
    return this.proto;
  }

  get apiKey(): string {
    return this._proto.api_key;
  }

  get id(): string {
    return this._proto.id;
  }

  get name(): string {
    return this._proto.name;
  }

  get description(): string {
    return this._proto.description;
  }

  get isActive(): boolean {
    return this._proto.is_active;
  }

  get isAdmin(): boolean {
    return !!this._proto.is_admin;
  }
}
