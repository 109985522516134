import React, { FC, PropsWithChildren } from 'react';
import useGlobalState from '../../shared/useGlobalState';
import { logout } from '../../utils/okta';
import InitializationLoadingIndicator from './InitializationLoadingIndicator';
import useInitializeApplication from './useInitializeApplication';
import useRedirectToDashboardIfNeeded from './useWorkflowManager';

const InitializeApplication: FC<PropsWithChildren<{}>> = ({ children }) => {
  const { hasFinishedInitializing, hasInitializingError } = useInitializeApplication();
  const { auth } = useGlobalState();
  useRedirectToDashboardIfNeeded(hasFinishedInitializing); // handles /app or /apps and redirect to dashboard /app/repo/prod/home

  return (
    <>
      {hasInitializingError ? (
        <div id="content" className="overflow-y-scroll h-100 content-shadow" role="main">
          <div className="pa5">
            <div className="card mb3 pa3">
              <div className="pa3">
                <div className="f3 fw7 mb3">Error Initializing Application Data</div>
                <div className="f4 mb4">Please trying to login again. If this error continues, contact support.</div>
                <div className="f4 fw5 mb2">
                  <a
                    href="/"
                    onClick={() => {
                      logout(auth);
                    }}
                  >
                    Logout
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : !hasFinishedInitializing ? (
        // Show loading indicator while data is being loaded
        <InitializationLoadingIndicator />
      ) : (
        children
      )}
    </>
  );
};

export default InitializeApplication;
