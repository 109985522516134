import React, { FC } from 'react';
import { Icon, useTectonTheme } from '../@tecton';
import { FCOTag } from '../../core/types/fcoTypes';
import styled from '@emotion/styled';

interface TagBadgeProps {
  tag: FCOTag;
}

const TagBadge: FC<TagBadgeProps> = ({ tag }) => {
  const { theme } = useTectonTheme();

  const StyledSpan = styled.span`
    font-size: ${theme.base}px;
  `;

  return (
    <StyledSpan>
      <Icon type="tag" style={{ marginRight: theme.size.s }} />
      <span style={{ fontWeight: theme.font.weight.bold }}>{tag.key}</span>: {tag.value}
    </StyledSpan>
  );
};

export default TagBadge;
