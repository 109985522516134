import { _FontScaleOptions } from '@elastic/eui';
import { FCOType } from '../../../core/types/fcoTypes';

export type Workspace = { name: string; workspaceType: 'Live' | 'Development'; role?: string; isAccessible: boolean };
export type AvailableWorkspaces = {
  recent: Workspace[];
  live: Workspace[];
  development: Workspace[];
};

export const FCOTypeNameMap: Record<FCOType, string> = {
  [FCOType.DATASET]: 'Dataset',
  [FCOType.DATA_SOURCE]: 'Source',
  [FCOType.ENTITY]: 'Entity',
  [FCOType.FEATURE_SERVICE]: 'Feature Service',
  [FCOType.FEATURE_VIEW]: 'Feature View',
  [FCOType.TRANSFORMATION]: 'Transformation',
  [FCOType.UNKNOWN]: 'Unknown',
};

export const FCOTypeNameMapPlural: Record<FCOType, string> = {
  [FCOType.DATASET]: 'Datasets',
  [FCOType.DATA_SOURCE]: 'Sources',
  [FCOType.ENTITY]: 'Entities',
  [FCOType.FEATURE_SERVICE]: 'Feature Services',
  [FCOType.FEATURE_VIEW]: 'Feature Views',
  [FCOType.TRANSFORMATION]: 'Transformations',
  [FCOType.UNKNOWN]: 'Unknown',
};

export type SimpleRequestStatus = 'success' | 'failed';
export type RequestCallbackType = (status: SimpleRequestStatus) => void;
