import React from 'react';
import { useTectonTheme } from './TectonThemeProvider';
import { css } from '@emotion/css';

const EmptyValue = () => {
  const { theme } = useTectonTheme();

  const style = css`
    color: ${theme.color.light.mediumShade};
  `;

  return <span className={style}>n/a</span>;
};

export default EmptyValue;
