import { useQuery } from '@tanstack/react-query';
import { Metadata_Service } from '.';
import {
  GetOnboardingStatusRequest,
  GetOnboardingStatusResponse,
} from '../types/tecton_proto/metadataservice/metadata_service';

export const fetchGetOnboardingStatus = async (
  payload: GetOnboardingStatusRequest
): Promise<GetOnboardingStatusResponse> => {
  const response = await Metadata_Service.post('get-onboarding-status', payload);
  return response.data;
};

export const useGetOnboardingStatus = (workspace: string, options?: any) => {
  const payload = { workspace };
  const queryOptions = options ? { ...options } : {};

  return useQuery(
    ['get-onboarding-status', workspace],
    () => {
      return fetchGetOnboardingStatus(payload);
    },
    queryOptions
  );
};
