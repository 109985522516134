import React from 'react';
import { EuiPanel, EuiSplitPanel, useEuiFontSize, useEuiTheme } from '@elastic/eui';
import styled from '@emotion/styled';

interface EmbeddedDocsLayoutProps {
  mainColumn: React.ReactNode;
  docsColumn: React.ReactNode;
}

const EmbeddedDocsLayout = ({ mainColumn, docsColumn }: EmbeddedDocsLayoutProps) => {
  const { euiTheme } = useEuiTheme();
  const smallFontSize = useEuiFontSize('s');

  const DocsPanelWrapper = styled(EuiPanel)`
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: ${euiTheme.font.weight.medium};
      color: ${euiTheme.colors.darkestShade};
    }

    p,
    li {
      ${smallFontSize};
      color: ${euiTheme.colors.darkestShade};
    }

    .tecton-embedded-docs-pre-heading {
      line-height: 1;
      margin-block-end: 0.4rem;
      color: ${euiTheme.colors.mediumShade};
    }

    .euiText {
      .tecton-embedded-docs-pre-heading + h1,
      .tecton-embedded-docs-pre-heading + h2,
      .tecton-embedded-docs-pre-heading + h3,
      .tecton-embedded-docs-pre-heading + h4,
      .tecton-embedded-docs-pre-heading + h5,
      .tecton-embedded-docs-pre-heading + h6 {
        margin-block-start: 0;
      }
    }
  `;

  return (
    <EuiSplitPanel.Outer direction="row" hasShadow={false} css={{ height: '100%' }}>
      <EuiSplitPanel.Inner paddingSize="none" className="eui-yScroll">
        {mainColumn}
      </EuiSplitPanel.Inner>
      <EuiSplitPanel.Inner paddingSize="none" color="subdued" className="eui-yScroll" css={{ maxWidth: '50em' }}>
        <DocsPanelWrapper hasShadow={false} paddingSize="l" color="transparent">
          {docsColumn}
        </DocsPanelWrapper>
      </EuiSplitPanel.Inner>
    </EuiSplitPanel.Outer>
  );
};

export default EmbeddedDocsLayout;
