import React, { lazy, useEffect, useRef } from 'react';
import { generatePath, Navigate, Route, Routes } from 'react-router';
import { useLocation, matchPath } from 'react-router-dom';
import { Routes as AppRoutes } from '../routes';
import useGlobalState from '../../shared/useGlobalState';
import Settings from '../../feature/access-control/Settings';
import { FeatureFlags, useUserSettings } from '../../components/context/UserSettingsContext';
import useAppCuesInitializer from '../../components/AppCuesInitializer';
const DataPlatformConfigEdit = lazy(
  () => import('../../components/data-platform-configuration/DataPlatformConfigEdit')
);
const Debug = lazy(() => import('../../feature/debug/component/Debug'));
const ChoosePlatform = lazy(() => import('../../feature/onboarding/choose-platform/ChoosePlatform'));
const ConfigureDatabricksPage = lazy(() => import('../../feature/onboarding/configure/ConfigureDatabricksPage'));

const SetupInProgress = lazy(
  () => import('../../feature/onboarding/data-platform-setup/setup-in-progress/SetupInProgress')
);
const OnboardingRootRedirectPage = lazy(() => import('../../feature/onboarding/OnboardingRootRedirectPage'));

const Layout = lazy(() => import('../component/Layout'));
const WelcomePage = lazy(() => import('../../feature/onboarding/welcome/WelcomePage'));

const WorkspaceSelectionWorkflow = lazy(() => import('../component/workspace-selection/WorkspaceSelectionHandler'));
const PageNotFound = lazy(() => import('./PageNotFound'));
const Workspace = lazy(() => import('../component/WorkspaceOutlet'));
const Dashboard = lazy(() => import('../../feature/dashboard/component/Dashboard'));
const DataflowDashboard = lazy(() => import('../../feature/dashboard/component/DataflowDashboard'));
const ApplyLogPage = lazy(() => import('../../feature/dashboard/component/ApplyLogPage'));
const DataSources = lazy(() => import('../../feature/data-sources/component/DataSources'));
const VirtualDataSource = lazy(() => import('../../feature/data-sources/data-source/component/VirtualDataSource'));
const Entities = lazy(() => import('../../feature/entities/component/Entities'));
const FeatureViewDebug = lazy(() => import('../../feature/debug/component/FeatureViewDebug'));
const FeatureServiceDebug = lazy(() => import('../../feature/debug/component/FeatureServiceDebug'));
const Transformations = lazy(() => import('../../feature/transformations/component/Transformations'));
const Transformation = lazy(() => import('../../feature/transformations/transformation/component/Transformation'));
const Entity = lazy(() => import('../../feature/entities/entity/component/Entity'));
const Features = lazy(() => import('../../feature/feature-views/component/Features'));
const FeatureViews = lazy(() => import('../../feature/feature-views/component/FeatureViews'));
const FeatureMonitoringSummary = lazy(() => import('../../feature/feature-views/component/FeatureMonitoringSummary'));
const FeatureView = lazy(() => import('../../feature/feature-views/feature-view/component/FeatureView'));
const ServiceLevelObjectives = lazy(() => import('../../feature/feature-services/component/ServiceLevelObjectives'));
const OnlineStoreMonitoring = lazy(() => import('../../feature/feature-services/component/OnlineStoreMonitoring'));
const FeatureServices = lazy(() => import('../../feature/feature-services/component/FeatureServices'));
const FeatureService = lazy(() => import('../../feature/feature-services/feature-service/component/FeatureService'));
const Datasets = lazy(() => import('../../feature/datasets/component/Datasets'));
const Dataset = lazy(() => import('../../feature/datasets/dataset/component/Dataset'));

const ValidationsContainer = lazy(() => import('../../components/validations/ValidationsContainer'));

const Jobs = lazy(() => import('../../components/jobs/JobsContainer'));
const JobDetailsContainer = lazy(() => import('../../components/jobs/JobDetails'));
const ACLUsersContainer = lazy(() => import('../../components/acl/ACLUserTableContainer'));
const ACLServiceAccountsContainer = lazy(() => import('../../components/acl/ACLServiceAccountsTableContainer'));
const ACLGroupsContainer = lazy(() => import('../../components/acl/ACLGroupsContainer'));
const ACLUserProfileContainer = lazy(() => import('../../components/acl/ACLUserProfileContainer'));
const MonitoringContainer = lazy(() => import('../../components/monitoring/Monitoring'));

const FeatureViewsAndTablesContainer = lazy(() => import('../../components/featureViews/FeatureViewsList'));
const EntitiesListContainer = lazy(() => import('../../components/entities/EntitiesList'));
const DataSourcesListContainer = lazy(() => import('../../components/dataSources/DataSourcesList'));
const TransformationsListContainer = lazy(() => import('../../components/transformations/TransformationsList'));
const FeatureServicesListContainer = lazy(() => import('../../components/featureServices/FeatureServicesList'));
const DataSetsListContainer = lazy(() => import('../../components/dataSets/DataSetsList'));

import ACLServiceAccountProfileContainer from '../../components/acl/ACLServiceAccountProfileContainer';
import ConfigurationContainer from '../../components/workspace/ConfigurationContainer';

import { useIsValidationsEnabled } from '../../api/validation';
import { logEvent } from '../../utils/analytics-utils';
import ACLGroupProfileContainer from '../../components/acl/ACLGroupProfileContainer';
import PlanSummaryDetailContainer from '../../feature/plan-summary/PlanSummaryDetailContainer';

const TransformationContainer = lazy(() => import('../../components/transformations/Transformation'));
const EntityContainer = lazy(() => import('../../components/entities/Entity'));
const DataSourceContainer = lazy(() => import('../../components/dataSources/DataSource'));
const FeatureViewContainer = lazy(() => import('../../components/featureViews/FeatureView'));
const FeatureServiceContainer = lazy(() => import('../../components/featureServices/FeatureService'));
const DatasetContainer = lazy(() => import('../../components/dataSets/DataSet'));

const routesArray = Object.values(AppRoutes);
const routeMatch = (path: string): string | undefined => {
  return routesArray.find((route: any) => matchPath(route, path));
};

const generatePathToDashboard = (pathname: string) => {
  const result = matchPath(AppRoutes.workspaceHome, pathname);
  const workspace = result?.params.workspace || 'prod';

  return generatePath(AppRoutes.dashboard, { workspace });
};

const ApplicationRoutes = () => {
  const { isGuidedOnboardingEnabled } = useGlobalState();
  const isValidationsEnabled = useIsValidationsEnabled();
  const { featureEnabled } = useUserSettings();
  const previousPath = useRef<string | undefined>(undefined);

  const { pathname } = useLocation();

  useEffect(() => {
    // ONLY LOG WHEN BASE PATH CHANGES
    // WE CAN HANDLE CHANGING PARAMS SEPARATELY
    if (pathname && pathname !== previousPath.current) {
      const rt = routeMatch(pathname);
      logEvent('Viewed UI Route', undefined, { path: pathname, route: rt });
      previousPath.current = pathname;
    }
  }, [pathname]);

  // APP_CUES INIT FOR Community Cluster
  useAppCuesInitializer();

  return (
    <Routes>
      <Route path={AppRoutes.root} element={<Layout />}>
        {/* Workspace Routes */}
        <Route path="/repo" element={<Workspace />}>
          {featureEnabled(FeatureFlags.SHOW_NEW_WORKSPACE_HOME) ? (
            <>
              <Route path={AppRoutes.dashboard} element={<DataflowDashboard />} />
              <Route path={AppRoutes.planListIndex} element={<ApplyLogPage />} />
              <Route
                path={AppRoutes.workspaceHome}
                element={<Navigate to={generatePathToDashboard(pathname)} replace />}
              />
            </>
          ) : (
            <>
              <Route path={AppRoutes.dashboard} element={<Dashboard />} />
              <Route
                path={AppRoutes.workspaceHome}
                element={<Navigate to={generatePathToDashboard(pathname)} replace />}
              />
            </>
          )}

          {featureEnabled(FeatureFlags.SHOW_NEW_UI_FV_DETAILS) ? (
            <Route path={AppRoutes.dataSource} element={<DataSourceContainer />} />
          ) : (
            <Route path={AppRoutes.dataSource} element={<VirtualDataSource />} />
          )}

          {featureEnabled(FeatureFlags.SHOW_NEW_UI_FV_DETAILS) ? (
            <Route path={AppRoutes.transformation} element={<TransformationContainer />} />
          ) : (
            <Route path={AppRoutes.transformation} element={<Transformation />} />
          )}

          {featureEnabled(FeatureFlags.SHOW_NEW_UI_FV_DETAILS) ? (
            <Route path={AppRoutes.entity} element={<EntityContainer />} />
          ) : (
            <Route path={AppRoutes.entity} element={<Entity />} />
          )}

          <Route path={AppRoutes.features} element={<Features />} />
          {featureEnabled(FeatureFlags.SHOW_NEW_FCO_LISTS) ? (
            <>
              <Route path={AppRoutes.monitoring} element={<MonitoringContainer />} />
              <Route path={AppRoutes.featureViews} element={<FeatureViewsAndTablesContainer />} />
              <Route path={AppRoutes.entities} element={<EntitiesListContainer />} />
              <Route path={AppRoutes.dataSources} element={<DataSourcesListContainer />} />
              <Route path={AppRoutes.transformations} element={<TransformationsListContainer />} />
              <Route path={AppRoutes.featureServices} element={<FeatureServicesListContainer />} />
              <Route path={AppRoutes.savedFeatureDataFrames} element={<DataSetsListContainer />} />
            </>
          ) : (
            <>
              <Route path={AppRoutes.featureViews} element={<FeatureViews />} />
              <Route path={AppRoutes.entities} element={<Entities />} />
              <Route path={AppRoutes.dataSources} element={<DataSources />} />
              <Route path={AppRoutes.transformations} element={<Transformations />} />
              <Route path={AppRoutes.featureServices} element={<FeatureServices />} />
              <Route path={AppRoutes.savedFeatureDataFrames} element={<Datasets />} />
            </>
          )}

          {featureEnabled(FeatureFlags.SHOW_NEW_UI_FV_DETAILS) ? (
            <Route path={AppRoutes.featureView} element={<FeatureViewContainer />} />
          ) : (
            <Route path={AppRoutes.featureView} element={<FeatureView />} />
          )}

          <Route path={AppRoutes.feature} element={<FeatureView />} />
          <Route path={AppRoutes.featureMonitoringSummary} element={<FeatureMonitoringSummary />} />
          <Route path={AppRoutes.featuresByEntity} element={<Features />} />
          <Route path={AppRoutes.sloDashboard} element={<ServiceLevelObjectives />} />
          <Route path={AppRoutes.featureServerMonitoring} element={<OnlineStoreMonitoring />} />

          {featureEnabled(FeatureFlags.SHOW_NEW_UI_FV_DETAILS) ? (
            <Route path={AppRoutes.featureService} element={<FeatureServiceContainer />} />
          ) : (
            <Route path={AppRoutes.featureService} element={<FeatureService />} />
          )}

          {/* Has sub-routes /users /service-accounts */}
          {featureEnabled(FeatureFlags.SHOW_NEW_UI_FV_DETAILS) ? (
            <Route path={AppRoutes.savedFeatureDataFrame} element={<DatasetContainer />} />
          ) : (
            <Route path={AppRoutes.savedFeatureDataFrame} element={<Dataset />} />
          )}

          <Route path={AppRoutes.configurations} element={<ConfigurationContainer />} />
          {isValidationsEnabled && <Route path={AppRoutes.validations} element={<ValidationsContainer />} />}
        </Route>

        <Route path={AppRoutes.planSummary} element={<PlanSummaryDetailContainer />} />

        <Route path={AppRoutes.featureViewDebug} element={<FeatureViewDebug />} />
        <Route path={AppRoutes.featureServiceDebug} element={<FeatureServiceDebug />} />
        <Route path={AppRoutes.debug} element={<Debug />} />

        <Route path={AppRoutes.jobs} element={<Jobs />} />
        <Route path={AppRoutes.jobDetails} element={<JobDetailsContainer />} />

        {/* DPO */}
        <Route path={AppRoutes.dataPlatformConfiguration} element={<DataPlatformConfigEdit />} />

        {/* Guided onBoarding */}
        {isGuidedOnboardingEnabled && (
          <Route path={AppRoutes.onBoarding} element={<OnboardingRootRedirectPage />}>
            <Route path={AppRoutes.onBoardingChoosePlatform} element={<ChoosePlatform />} />
            <Route path={AppRoutes.onBoardingSetupInProgress} element={<SetupInProgress />} />
            <Route path={AppRoutes.onBoardingConfigureDatabricks} element={<ConfigureDatabricksPage />} />
            <Route path={AppRoutes.onBoardingWelcome} element={<WelcomePage />} />
          </Route>
        )}

        {/* ACL Routes EUI*/}
        <Route path={AppRoutes.settings} element={<Settings />}>
          <Route index element={<Navigate to={AppRoutes.accountsAndAccessUsers} replace />} />
          <Route
            path={AppRoutes.accountAndAccess}
            element={<Navigate to={AppRoutes.accountsAndAccessUsers} replace />}
          />
          <Route path={AppRoutes.accountsAndAccessUsers} element={<ACLUsersContainer />} />
          <Route path={AppRoutes.accountsAndAccessUserProfile} element={<ACLUserProfileContainer />} />
          <Route path={AppRoutes.accountsAndAccessGroups} element={<ACLGroupsContainer />} />
          <Route path={AppRoutes.accountsAndAccessGroupsProfile} element={<ACLGroupProfileContainer />} />

          <Route path={AppRoutes.accountsAndAccessServiceAccounts} element={<ACLServiceAccountsContainer />} />
          <Route
            path={AppRoutes.accountsAndAccessServiceAccountProfile}
            element={<ACLServiceAccountProfileContainer />}
          />
        </Route>

        <Route path={AppRoutes.selectWorkspace} element={<WorkspaceSelectionWorkflow />} />
        {/* Catch all Routes */}
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  );
};

export default ApplicationRoutes;
