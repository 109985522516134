import React from 'react';
import { EmptyPrompt, Button } from '@tecton';
import { css } from '@emotion/css';

const NotMemberOfAnyGroup = ({
  userId,
  isAdmin,
  addNewGroupCallback,
  buttonType,
}: {
  userId: string;
  isAdmin: boolean;
  addNewGroupCallback: () => void;
  buttonType: string;
}) => {
  return (
    <EmptyPrompt
      iconType="minusInCircle"
      hasBorder
      style={{
        width: `100%`,
        height: `100%`,
        maxWidth: 'unset',
      }}
      className={css`
        .euiEmptyPrompt__main {
          height: 100%;
        }
      `}
      actions={[
        <Button
          fill
          onClick={() => {
            addNewGroupCallback();
          }}
          disabled={!isAdmin}
        >
          Add {buttonType} to a Group
        </Button>,
      ]}
      titleSize="s"
      title={<h4>{userId} is not a member of any group.</h4>}
    />
  );
};

export default NotMemberOfAnyGroup;
