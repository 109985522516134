import React from 'react';
import { LoadingContent, PageBody, Panel, Spacer, useTectonTheme } from '@tecton';
import { ErrorBoundary } from '@sentry/react';
import { Outlet, Route, Routes, matchPath, useNavigate, useParams } from 'react-router';
import SummaryPanel from './SummaryPanel';
import PlanSummary from './PlanSummary';
import { useGetStateUpdatePlanSummary } from '../../api/plan-apply-log';
import { css } from '@emotion/css';
import CliOutput from './CliOutput';
import MaterializationSummaryCallout from './MaterializationSummaryCallout';
import { TectonTabs, TectonBreadcrumbTitles } from '@shared';
import { FeatureFlags, useUserSettings } from '../../components/context/UserSettingsContext';

enum PlanSummaryRoutes {
  CLI_OUTPUT = 'cli-output',
}

const PlanSummaryDetailFrame = () => {
  const { planId, workspace } = useParams();
  const { theme } = useTectonTheme();
  const { data, isLoading } = useGetStateUpdatePlanSummary(planId);
  const navigate = useNavigate();
  const { featureEnabled } = useUserSettings();

  const mainColumnCss = css`
    padding-left: calc(22em + ${theme.size.xl} + ${theme.size.l});
    padding-right: ${theme.size.xl};
    padding-bottom: 50vh;

    .euiTable {
      margin-bottom: ${theme.size.l};
    }

    .euiAccordion__triggerWrapper {
      padding: ${theme.size.s};
      border-bottom: 1px solid ${theme.border.color};
    }

    .euiFlexItem:first-of-type .euiAccordion__triggerWrapper {
      padding-top: 0;
    }
  `;

  const planBreadCrumbPath = featureEnabled(FeatureFlags.SHOW_NEW_WORKSPACE_HOME)
    ? `/repo/${workspace}/plan-summary`
    : `/repo/${workspace}`;

  const breadcrumbs = [
    {
      text: <>{workspace}</>,
    },
    {
      text: data?.hasApplied ? <>Apply Summary</> : <>Plan Summary</>,
      onClick: () => {
        navigate(planBreadCrumbPath);
      },
    },
    {
      text: planId,
    },
  ];

  const tabs = [
    {
      label: data?.hasApplied ? 'Apply Summary' : 'Plan Summary',
      isSelected: !!matchPath(
        {
          path: `app/${workspace}/plan-summary/${planId}`,
          end: true,
        },
        location.pathname
      ),
      onClick: () => {
        navigate(`./`); // index
      },
    },
    {
      label: 'CLI Output',
      isSelected: !!matchPath(
        {
          path: `app/${workspace}/plan-summary/${planId}/cli-output`,
          end: true,
        },
        location.pathname
      ),
      onClick: () => {
        navigate(`cli-output`);
      },
    },
  ];

  return (
    <>
      <PageBody paddingSize="xl">
        <ErrorBoundary>
          <TectonBreadcrumbTitles breadcrumbs={breadcrumbs} />
          {isLoading ? (
            <>
              <Spacer size="xl" />
              <LoadingContent />
            </>
          ) : (
            <>
              <MaterializationSummaryCallout
                diffItems={data?.plan?.diff_items ?? []}
                hasBeenApplied={data?.hasApplied ?? false}
              />

              <span>
                {/* span tag is required Left side summary man navigation.*/}
                <SummaryPanel stateUpdatePlanSummary={data} />
                <Panel className={mainColumnCss} hasBorder={false} hasShadow={false} paddingSize="none">
                  <TectonTabs tabs={tabs} />
                  <Spacer size="xl" />
                  <Outlet />
                </Panel>
              </span>
            </>
          )}
        </ErrorBoundary>
      </PageBody>
    </>
  );
};

const PlanSummaryDetail = () => {
  return (
    <Routes>
      <Route element={<PlanSummaryDetailFrame />}>
        <Route index element={<PlanSummary />} />
        <Route path={PlanSummaryRoutes.CLI_OUTPUT} element={<CliOutput />} />
      </Route>
    </Routes>
  );
};

export default PlanSummaryDetail;
