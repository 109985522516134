import { rest } from 'msw';
import { InternalSparkClusterStatusEnum } from '../../types/tecton_proto/data/internal_spark_cluster_status';
let Submitted = false;
let Num = 0;

const full_deployment_settings_mock = {
  user_deployment_settings: {
    user_deployment_settings_version: 1,
    databricks_config: {
      workspace_url: 'https://tecton-dev-shared-databricks.cloud.databricks.com',
      api_token: { redacted_value: '********' },
      user_name: 'bot@tecton.ai',
      user_display_name: 'Tecton Bot',
    },
    user_spark_settings: {
      instance_profile_arn: 'arn:aws:iam::843850565897:instance-profile/tecton-dev-shared-emr-spark-role',
      spark_conf: {
        'spark.databricks.hive.metastore.glueCatalog.enabled': 'true',
        'spark.hadoop.hive.metastore.glue.catalogid': '472542229217',
      },
    },
    tenant_settings: {},
  },
};

const empty_deployment_settings_mock = { user_deployment_settings: { databricks_config: {}, tenant_settings: {} } };
const cluster_status_mock = {
  status: {
    status: InternalSparkClusterStatusEnum.INTERNAL_SPARK_CLUSTER_STATUS_HEALTHY,
    cluster_url: 'https://tecton-production.cloud.databricks.com#setting/clusters/1013-055824-wzsfoekl/configuration',
  },
};
export const mockGetInteralSparkClusterStatus = rest.post(
  '/v1/metadata-service/get-internal-spark-cluster-status',
  (req, res, ctx) => {
    const out = {
      ...cluster_status_mock,
    };
    if (Submitted) {
      if (Num < 4) {
        out.status.status = InternalSparkClusterStatusEnum.INTERNAL_SPARK_CLUSTER_STATUS_CREATING_CLUSTER;
        // } else if (Num >= 4 && Num <= 7) {
        //   out.status.status = InternalSparkClusterStatusEnum.INTERNAL_SPARK_CLUSTER_STATUS_WAITING_FOR_CLUSTER_TO_START;
      } else if (Num >= 4) {
        out.status.status = InternalSparkClusterStatusEnum.INTERNAL_SPARK_CLUSTER_STATUS_UNHEALTHY;
      } else {
        out.status.status = InternalSparkClusterStatusEnum.INTERNAL_SPARK_CLUSTER_STATUS_HEALTHY;
      }
      console.log(Num);
      Num++;
    }
    return res(ctx.status(200), ctx.json(out));
  }
);
export const mockGetUserDeploymentSettings = rest.post(
  '/v1/metadata-service/get-user-deployment-settings',
  (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(full_deployment_settings_mock));
  }
);

export const mockGetOnboardingStatus = rest.post('/v1/metadata-service/get-onboarding-status', (req, res, ctx) => {
  return res(
    ctx.status(200),
    ctx.json({ setup_platform: 'ONBOARDING_STATUS_INCOMPLETE', finish_onboarding: 'ONBOARDING_STATUS_INCOMPLETE' })
  );
});

export const mockUpdateUserDeploymentSettings = rest.post(
  '/v1/metadata-service/update-user-deployment-settings',
  (req, res, ctx) => {
    Submitted = true;
    return res(ctx.status(200), ctx.json({ success: true, errorMessage: '' }));
  }
);
