import { NotificationInterface } from './types';

enum NotificationActionTypes {
  SEND_NOTIFICATION = 'SEND_NOTIFICATION',
  EXPIRE_NOTIFICATION = 'EXPIRE_NOTIFICATION',
}

const createNotification = (payload: NotificationInterface) => {
  return {
    type: NotificationActionTypes.SEND_NOTIFICATION,
    payload,
  };
};

const expireNotification = () => {
  return {
    type: NotificationActionTypes.EXPIRE_NOTIFICATION,
  };
};

export { createNotification, expireNotification, NotificationActionTypes };
