import { OnboardingStateEnums } from '../../feature/onboarding/types';

import { DataPlatformSetupTaskStatusEnum, Task } from '../../service/DataPlatformSetupStatus';
import { ProgressionStatusType } from '../../components/shared/components/StatusProgression';
import { OnBoardingReducerInterface } from '../../feature/onboarding/reducer';
import _ from 'lodash';
import { useDatabricksConfiguration } from '../../feature/data-platform-configuration/query';
import { configStateSelector } from '../config-utils';

export interface OnboardingSidebarState {
  label: string;
  isCompleted: boolean;
  isCurrentState: boolean;
  hasSidebarState: boolean; // Not all onBoarding State has a side navigation element
  onBoardingState: OnboardingStateEnums;
}

export const getDataPlatformStatus = (
  dataPlatformSetupTaskStatus: DataPlatformSetupTaskStatusEnum
): ProgressionStatusType => {
  const statusMap = {
    TASK_STATUS_SUCCEEDED: ProgressionStatusType.SUCCESS,
    TASK_STATUS_RUNNING: ProgressionStatusType.IN_PROGRESS,
    TASK_STATUS_FAILED: ProgressionStatusType.ERROR,
    TASK_STATUS_NOT_STARTED: ProgressionStatusType.IN_PROGRESS,
    TASK_STATUS_UNKNOWN: ProgressionStatusType.ERROR,
  };

  let status = statusMap[dataPlatformSetupTaskStatus];
  if (!status) {
    status = ProgressionStatusType.UNKNOWN;
  }
  return status;
};

export const findCompletedOnboardingStateCallback = (state: OnboardingSidebarState) => {
  return state.onBoardingState === OnboardingStateEnums.HAS_COMPLETED_GUIDED_ONBOARDING && state.isCompleted;
};

export const onboardingStateSelector = (state: any): OnBoardingReducerInterface => {
  return state.onBoarding;
};

export const shouldShowPlatformNavigationDuringOnboardingFlagSelector = (state: any): boolean => {
  const configState = configStateSelector(state);
  const onboarding = onboardingStateSelector(state);

  if (configState.isOnboardingEnabled && onboarding.onBoardingStates) {
    const hasPlatform = onboarding.onBoardingStates.find((entry) => {
      return entry.onBoardingState === OnboardingStateEnums.NEW_ACCOUNT;
    });
    return hasPlatform?.isCompleted === true; // Should hide navigation if create repo is not completed
  } else {
    return true;
  }
};

export const hasUserDeploymentConfiguration = (configurationState: any): boolean => {
  if (configurationState) {
    const rawUserDeploymentSetting: any = _.get(configurationState, '_proto');
    return rawUserDeploymentSetting && Object.keys(rawUserDeploymentSetting).length > 0;
  }

  return false;
};

export const canEnabledDpoClusterButton = (
  formik: any,
  isCredentialsFormDirty: boolean | undefined,
  configurationState: ReturnType<typeof useDatabricksConfiguration>,
  isValidatingDatabricksSettings: boolean,
  isDataPlatformSetupComplete: boolean,
  isGuidedOnboarding: boolean
): boolean => {
  if (isGuidedOnboarding) {
    if (isValidatingDatabricksSettings) {
      // Request is going out.
      return false;
    }

    if (isDataPlatformSetupComplete) {
      // Once polling stops, we enable the button.
      return true;
    }

    if (!hasUserDeploymentConfiguration(configurationState.data) && formik.isValid) {
      // Zero state No config and the form has valid values
      return true;
    }

    // We use the isCredentialsFormDirty instead of formik.dirty since anytime the user updates the form, we manually mark it dirty
    if (hasUserDeploymentConfiguration(configurationState.data) && isCredentialsFormDirty === true && formik.isValid) {
      // Has existing data, but have not touched the form.
      return true;
    }
  } else {
    return formik.isValid;
  }

  return false;
};

export const canShowDPOAccordion = (
  isGuidedOnboarding: boolean,
  dpoStatuses: Task[],
  isCredentialsFormDirty: boolean | undefined,
  errorMessage: string
) => {
  if (Array.isArray(dpoStatuses) && isGuidedOnboarding) {
    const statues = dpoStatuses.map((task) => {
      return task.task_status;
    });

    const isRunning = statues.includes(DataPlatformSetupTaskStatusEnum.TASK_STATUS_RUNNING);
    const allDone = statues.every((i) => {
      return i === DataPlatformSetupTaskStatusEnum.TASK_STATUS_SUCCEEDED;
    });

    if (isRunning || allDone) {
      return true;
    }
  }
  return isGuidedOnboarding && dpoStatuses.length > 0 && isCredentialsFormDirty === false && errorMessage === '';
};
