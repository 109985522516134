import React, { useContext } from 'react';
import { EuiBasicTableColumn, Button, Spacer, DebouncedFieldSearch } from '@tecton';

import { useParams } from 'react-router-dom';
import { TectonBasicTable } from '@shared';
import { ACLTableControlLayout } from './ACLLayout';
import ModifyWorkspaceRoleModal from './modals/ModifyWorkspaceRoleModal';
import UserProfileAddWorkspaceModal from './modals/UserProfileAddWorkspaceModal';
import ACLTableRowPropsFn from './ACLTableRowPropsFn';
import WorkspaceIDColumn from './WorkspaceIDColumn';
import { ACLGroupModal, getRoleNameGQL } from './aclUtils';
import { useUserSettings } from '../context/UserSettingsContext';
import { ACLGroupProfileContextGQL } from './ACLGroupProfileContextGQL';
import { AclGroupWorkspaceType } from '../../api/gql/graphql';

const ACLGroupWorkspacesGQL = () => {
  const groupProfileContext = useContext(ACLGroupProfileContextGQL);
  const { isAdmin } = useUserSettings();

  const { group } = useParams();
  if (!group) {
    throw new Error('No group ID in URL.');
  }
  const groupId = group;

  let addWorkspaceModal;

  if (groupProfileContext.modal === ACLGroupModal.ADD_WORKSPACE) {
    addWorkspaceModal = (
      <UserProfileAddWorkspaceModal
        userId={groupId}
        allRoleRecords={groupProfileContext?.allRoleRecords ?? []}
        optionsQuery={{
          options: groupProfileContext?.workspaceCandidates ?? [],
          isLoading: groupProfileContext?.isModalLoading ?? false,
          isError: groupProfileContext?.errorMessages !== undefined,
          isSuccess: true,
        }}
        selectedWorkspace={groupProfileContext?.selectedWorkspace ?? []}
        setSelectedWorkspace={(value) => {
          groupProfileContext?.updateSelectedWorkspace?.call(groupProfileContext, value);
        }}
        roleOptions={groupProfileContext?.existingGroupRoleOptions}
        selectedRole={groupProfileContext?.selectedRole ?? ''}
        setSelectedRole={(value) => {
          groupProfileContext?.updateSelectedRole?.call(groupProfileContext, value);
        }}
        isLoading={groupProfileContext?.isModalLoading ?? false}
        errorMessages={groupProfileContext?.errorMessages}
        onClose={() => {
          groupProfileContext?.closeModal?.call(groupProfileContext);
        }}
        confirmChange={() => {
          groupProfileContext?.addWorkspace?.call(groupProfileContext);
        }}
      />
    );
  }

  const triggerModifyWorkspaceModal = (row: AclGroupWorkspaceType) => {
    groupProfileContext?.showModifyWorkspaceModal?.call(groupProfileContext, row);
    const role = getRoleNameGQL(groupProfileContext?.groupRoles ?? [], row.role ?? '');
    groupProfileContext?.updateSelectedRole?.call(groupProfileContext, role);
  };

  let modifyWorkspaceModal;

  if (groupProfileContext?.modal === ACLGroupModal.MODIFY_WORKSPACE) {
    modifyWorkspaceModal = (
      <ModifyWorkspaceRoleModal
        userId={groupProfileContext?.group?.name ?? ''}
        allRoleRecords={groupProfileContext?.allRoleRecords ?? []}
        selectedRole={groupProfileContext?.selectedRole ?? ''}
        setSelectedRole={(role) => {
          groupProfileContext?.updateSelectedRole?.call(groupProfileContext, role);
        }}
        workspaceId={groupProfileContext?.selectedWorkspaceRole?.workspaceId ?? ''}
        currentRole={groupProfileContext?.selectedWorkspaceRole?.currentRole}
        roleOptions={groupProfileContext?.newGroupRoleOptions}
        cancel={() => {
          groupProfileContext?.closeModal?.call(groupProfileContext);
        }}
        isLoading={groupProfileContext?.isModalLoading ?? false}
        errorMessages={groupProfileContext?.errorMessages}
        confirmChanges={() => {
          groupProfileContext?.confirmModifyWorkspace?.call(groupProfileContext, () => {});
        }}
      />
    );
  }

  const columns: EuiBasicTableColumn<AclGroupWorkspaceType>[] = [
    {
      field: 'workspaceId',
      name: 'Workspace',
      render: (workspaceId: string, row: AclGroupWorkspaceType) => {
        return <WorkspaceIDColumn workspaceId={workspaceId} hasPriority={row.hasPriority} principalType={'user'} />;
      },
    },
    {
      field: 'role',
      name: 'Role of Group',
      render: (role: string) => {
        const roleName = role === 'none' ? 'None' : getRoleNameGQL(groupProfileContext?.groupRoles ?? [], role);
        return (
          <span style={{ textTransform: 'capitalize' }} className="workspace-role">
            {roleName}
          </span>
        );
      },
    },
    {
      name: 'Actions',
      width: '5em',
      actions: [
        {
          name: 'Edit Group Role',
          isPrimary: true,
          description: 'Change the role of this group in the workspace.',
          icon: 'pencil',
          type: 'icon',
          enabled: () => {
            return isAdmin ?? false;
          },
          onClick: (item) => {
            triggerModifyWorkspaceModal(item);
          },
          'data-test-subj': 'action-delete',
        },
      ],
    },
  ];

  return (
    <div>
      <ACLTableControlLayout
        search={
          <DebouncedFieldSearch
            fullWidth
            placeholder="Search for Workspace"
            onChange={(event) => {
              groupProfileContext.updateWorkspaceSearch?.call(groupProfileContext, event?.target?.value);
            }}
          />
        }
        actionButtons={
          <Button
            fill
            onClick={() => {
              groupProfileContext?.showAddWorkspaceModal?.call(groupProfileContext);
            }}
            data-testid="assign-workspace-access-button"
            title={isAdmin ? 'Assign Workspace Access' : 'Administrator Privileges Required'}
            isDisabled={!isAdmin}
          >
            Assign Workspace Access
          </Button>
        }
      />
      <Spacer size="l" />
      <TectonBasicTable
        items={groupProfileContext?.assignedWorkspaces ?? []}
        columns={columns}
        rowProps={ACLTableRowPropsFn}
        pageIndex={0}
        pageSize={0}
        totalItemCount={0}
      />
      {addWorkspaceModal}
      {modifyWorkspaceModal}
    </div>
  );
};

export default ACLGroupWorkspacesGQL;
