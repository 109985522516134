import { useQuery } from '@tanstack/react-query';
import {
  GetStateUpdatePlanSummaryRequest,
  QueryStateUpdateRequestV2,
  QueryStateUpdateResponseV2,
} from '../types/tecton_proto/metadataservice/metadata_service';
import { Metadata_Service } from '.';
import IdUtils from '../utils/id-utils';
import {
  StateUpdatePlanSummaryType,
  transformPlanSummary,
  transformQueryStateUpdateResponse,
} from '../feature/plan-summary/PlanSummaryTransform';

/*

const mockPlan = {
  plan: {
    diff_items: [
      {
        fco_type: 'Stream Data Source',
        type: 'UPDATE',
        transition_side_effects: {},
        diffs: [
          {
            property_name: 'tags[project]',
            val_existing: 'ads_click_prediction',
            val_declared: '',
            rendering_type: 'FCO_PROPERTY_RENDERING_TYPE_UNSPECIFIED',
          },
        ],
        name: 'ad_impressions_stream',
        description: '',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Batch Data Source',
        type: 'UPDATE',
        transition_side_effects: {},
        diffs: [
          {
            property_name: 'tags[project]',
            val_existing: 'ads_click_prediction',
            val_declared: '',
            rendering_type: 'FCO_PROPERTY_RENDERING_TYPE_UNSPECIFIED',
          },
        ],
        name: 'ad_impressions_batch',
        description: '',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Stream Data Source',
        type: 'RECREATE',
        transition_side_effects: {},
        diffs: [
          {
            property_name: 'tags[project]',
            val_existing: 'fraud',
            val_declared: '',
            rendering_type: 'FCO_PROPERTY_RENDERING_TYPE_UNSPECIFIED',
          },
          {
            property_name: 'tags[release]',
            val_existing: '',
            val_declared: 'production',
            rendering_type: 'FCO_PROPERTY_RENDERING_TYPE_UNSPECIFIED',
          },
          {
            property_name: 'owner',
            val_existing: 'vince@tecton.ai',
            val_declared: 'david@tecton.ai',
            rendering_type: 'FCO_PROPERTY_RENDERING_TYPE_UNSPECIFIED',
          },
          {
            property_name: 'hive_ds_config.datetime_partition_columns[0].column_name',
            val_existing: '',
            val_declared: 'partition_0',
            rendering_type: 'FCO_PROPERTY_RENDERING_TYPE_PLAIN_TEXT',
          },
          {
            property_name: 'hive_ds_config.datetime_partition_columns[0].datepart',
            val_existing: '',
            val_declared: 'year',
            rendering_type: 'FCO_PROPERTY_RENDERING_TYPE_PLAIN_TEXT',
          },
          {
            property_name: 'hive_ds_config.datetime_partition_columns[0].zero_padded',
            val_existing: '',
            val_declared: 'true',
            rendering_type: 'FCO_PROPERTY_RENDERING_TYPE_PLAIN_TEXT',
          },
          {
            property_name: 'hive_ds_config.datetime_partition_columns[1].column_name',
            val_existing: '',
            val_declared: 'partition_1',
            rendering_type: 'FCO_PROPERTY_RENDERING_TYPE_PLAIN_TEXT',
          },
          {
            property_name: 'hive_ds_config.datetime_partition_columns[1].datepart',
            val_existing: '',
            val_declared: 'month',
            rendering_type: 'FCO_PROPERTY_RENDERING_TYPE_PLAIN_TEXT',
          },
          {
            property_name: 'hive_ds_config.datetime_partition_columns[1].zero_padded',
            val_existing: '',
            val_declared: 'true',
            rendering_type: 'FCO_PROPERTY_RENDERING_TYPE_PLAIN_TEXT',
          },
          {
            property_name: 'hive_ds_config.datetime_partition_columns[2].column_name',
            val_existing: '',
            val_declared: 'partition_2',
            rendering_type: 'FCO_PROPERTY_RENDERING_TYPE_PLAIN_TEXT',
          },
          {
            property_name: 'hive_ds_config.datetime_partition_columns[2].datepart',
            val_existing: '',
            val_declared: 'day',
            rendering_type: 'FCO_PROPERTY_RENDERING_TYPE_PLAIN_TEXT',
          },
          {
            property_name: 'hive_ds_config.datetime_partition_columns[2].zero_padded',
            val_existing: '',
            val_declared: 'true',
            rendering_type: 'FCO_PROPERTY_RENDERING_TYPE_PLAIN_TEXT',
          },
          {
            property_name: 'hive_ds_config.common_args.data_delay',
            val_existing: '0s',
            val_declared: '1h',
            rendering_type: 'FCO_PROPERTY_RENDERING_TYPE_PLAIN_TEXT',
          },
          {
            property_name: 'kinesis_ds_config.post_processor.name',
            val_existing: 'translate_stream',
            val_declared: 'raw_data_deserialization',
            rendering_type: 'FCO_PROPERTY_RENDERING_TYPE_PLAIN_TEXT',
          },
          {
            property_name: 'kinesis_ds_config.post_processor.body',
            val_existing:
              'def translate_stream(df):\n    from pyspark.sql.functions import col, from_json, from_utc_timestamp, when\n    from pyspark.sql.types import (\n        StructType,\n        StructField,\n        StringType,\n        DoubleType,\n        TimestampType,\n        BooleanType,\n        IntegerType,\n        LongType\n    )\n    stream_schema = StructType(\n    [\n        StructField("user_id", StringType(), False),\n        StructField("transaction_id", StringType(), False),\n        StructField("category", StringType(), False),\n        StructField("amt", DoubleType(), False),\n        StructField("is_fraud", LongType(), False),\n        StructField("merchant", StringType(), False),\n        StructField("merch_lat", DoubleType(), False),\n        StructField("merch_long", DoubleType(), False),\n        StructField("timestamp", StringType(), False),\n    ]\n)\n    return (\n        df.selectExpr("cast (data as STRING) jsonData")\n        .select(from_json("jsonData", stream_schema).alias("payload"))\n        .select(\n            col("payload.user_id").alias("user_id"),\n            col("payload.transaction_id").alias("transaction_id"),\n            col("payload.category").alias("category"),\n            col("payload.amt").cast("double").alias("amt"),\n            col("payload.is_fraud").cast("long").alias("is_fraud"),\n            col("payload.merchant").alias("merchant"),\n            col("payload.merch_lat").cast("double").alias("merch_lat"),\n            col("payload.merch_long").cast("double").alias("merch_long"),\n            from_utc_timestamp("payload.timestamp", "UTC").alias("timestamp"),\n        )\n    )',
            val_declared:
              "def raw_data_deserialization(df):\n    from pyspark.sql.functions import col, from_json, from_utc_timestamp, when\n    from pyspark.sql.types import StructType, StructField, StringType, DoubleType, TimestampType, BooleanType, IntegerType\n    payload_schema = StructType([\n        StructField('user_id', StringType(), False),\n        StructField('transaction_id', StringType(), False),\n        StructField('category', StringType(), False),\n        StructField('amt', StringType(), False),\n        StructField('is_fraud', StringType(), False),\n        StructField('merchant', StringType(), False),\n        StructField('merch_lat', StringType(), False),\n        StructField('merch_long', StringType(), False),\n        StructField('timestamp', StringType(), False),\n    ])\n    return (\n        df.selectExpr('cast (data as STRING) jsonData')\n        .select(from_json('jsonData', payload_schema).alias('payload'))\n        .select(\n            col('payload.user_id').alias('user_id'),\n            col('payload.transaction_id').alias('transaction_id'),\n            col('payload.category').alias('category'),\n            col('payload.amt').cast('double').alias('amt'),\n            col('payload.is_fraud').cast('long').alias('is_fraud'),\n            col('payload.merchant').alias('merchant'),\n            col('payload.merch_lat').cast('double').alias('merch_lat'),\n            col('payload.merch_long').cast('double').alias('merch_long'),\n            from_utc_timestamp('payload.timestamp', 'UTC').alias('timestamp')\n        )\n    )",
            rendering_type: 'FCO_PROPERTY_RENDERING_TYPE_PYTHON',
          },
        ],
        name: 'transactions_stream',
        description: '',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Batch Data Source',
        type: 'RECREATE',
        transition_side_effects: {},
        diffs: [
          {
            property_name: 'description',
            val_existing: 'historical transaction data from payment provider',
            val_declared: '',
            rendering_type: 'FCO_PROPERTY_RENDERING_TYPE_UNSPECIFIED',
          },
          {
            property_name: 'tags[project]',
            val_existing: 'fraud',
            val_declared: '',
            rendering_type: 'FCO_PROPERTY_RENDERING_TYPE_UNSPECIFIED',
          },
          {
            property_name: 'tags[release]',
            val_existing: '',
            val_declared: 'production',
            rendering_type: 'FCO_PROPERTY_RENDERING_TYPE_UNSPECIFIED',
          },
          {
            property_name: 'owner',
            val_existing: 'vince@tecton.ai',
            val_declared: 'david@tecton.ai',
            rendering_type: 'FCO_PROPERTY_RENDERING_TYPE_UNSPECIFIED',
          },
          {
            property_name: 'hive_ds_config.datetime_partition_columns[0].column_name',
            val_existing: '',
            val_declared: 'partition_0',
            rendering_type: 'FCO_PROPERTY_RENDERING_TYPE_PLAIN_TEXT',
          },
          {
            property_name: 'hive_ds_config.datetime_partition_columns[0].datepart',
            val_existing: '',
            val_declared: 'year',
            rendering_type: 'FCO_PROPERTY_RENDERING_TYPE_PLAIN_TEXT',
          },
          {
            property_name: 'hive_ds_config.datetime_partition_columns[0].zero_padded',
            val_existing: '',
            val_declared: 'true',
            rendering_type: 'FCO_PROPERTY_RENDERING_TYPE_PLAIN_TEXT',
          },
          {
            property_name: 'hive_ds_config.datetime_partition_columns[1].column_name',
            val_existing: '',
            val_declared: 'partition_1',
            rendering_type: 'FCO_PROPERTY_RENDERING_TYPE_PLAIN_TEXT',
          },
          {
            property_name: 'hive_ds_config.datetime_partition_columns[1].datepart',
            val_existing: '',
            val_declared: 'month',
            rendering_type: 'FCO_PROPERTY_RENDERING_TYPE_PLAIN_TEXT',
          },
          {
            property_name: 'hive_ds_config.datetime_partition_columns[1].zero_padded',
            val_existing: '',
            val_declared: 'true',
            rendering_type: 'FCO_PROPERTY_RENDERING_TYPE_PLAIN_TEXT',
          },
          {
            property_name: 'hive_ds_config.datetime_partition_columns[2].column_name',
            val_existing: '',
            val_declared: 'partition_2',
            rendering_type: 'FCO_PROPERTY_RENDERING_TYPE_PLAIN_TEXT',
          },
          {
            property_name: 'hive_ds_config.datetime_partition_columns[2].datepart',
            val_existing: '',
            val_declared: 'day',
            rendering_type: 'FCO_PROPERTY_RENDERING_TYPE_PLAIN_TEXT',
          },
          {
            property_name: 'hive_ds_config.datetime_partition_columns[2].zero_padded',
            val_existing: '',
            val_declared: 'true',
            rendering_type: 'FCO_PROPERTY_RENDERING_TYPE_PLAIN_TEXT',
          },
          {
            property_name: 'hive_ds_config.common_args.data_delay',
            val_existing: '0s',
            val_declared: '1h',
            rendering_type: 'FCO_PROPERTY_RENDERING_TYPE_PLAIN_TEXT',
          },
        ],
        name: 'transactions_batch',
        description: 'historical transaction data from payment provider',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Batch Data Source',
        type: 'DELETE',
        transition_side_effects: {},
        name: 'ad_users_batch',
        description: '',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Batch Data Source',
        type: 'DELETE',
        transition_side_effects: {},
        name: 'orders_experiment',
        description: '',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Batch Data Source',
        type: 'DELETE',
        transition_side_effects: {},
        name: 'orders',
        description: '',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Entity',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'ad',
        description: 'An ad',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Entity',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'content',
        description: 'Content ID',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Entity',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'auction',
        description: 'Auction ID',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Entity',
        type: 'RECREATE',
        transition_side_effects: {},
        diffs: [
          {
            property_name: 'description',
            val_existing: 'An app user that is part of our push campaigns',
            val_declared: 'A user of the platform',
            rendering_type: 'FCO_PROPERTY_RENDERING_TYPE_UNSPECIFIED',
          },
          {
            property_name: 'tags[project]',
            val_existing: 'ads_click_prediction',
            val_declared: '',
            rendering_type: 'FCO_PROPERTY_RENDERING_TYPE_UNSPECIFIED',
          },
          {
            property_name: 'tags[release]',
            val_existing: '',
            val_declared: 'production',
            rendering_type: 'FCO_PROPERTY_RENDERING_TYPE_UNSPECIFIED',
          },
          {
            property_name: 'join_keys[0]',
            val_existing: 'user_uuid',
            val_declared: 'user_id',
            rendering_type: 'FCO_PROPERTY_RENDERING_TYPE_PLAIN_TEXT',
          },
        ],
        name: 'ads_user',
        description: 'An app user that is part of our push campaigns',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Entity',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'ContentKeyword',
        description: 'The keyword describing the content this ad is being placed alongside.',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Entity',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'fraud_user',
        description: 'A user of the platform',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Entity',
        type: 'UPDATE',
        transition_side_effects: {},
        diffs: [
          {
            property_name: 'description',
            val_existing: 'an online merchant selling goods',
            val_declared: 'A merchant',
            rendering_type: 'FCO_PROPERTY_RENDERING_TYPE_UNSPECIFIED',
          },
          {
            property_name: 'tags[project]',
            val_existing: 'fraud',
            val_declared: '',
            rendering_type: 'FCO_PROPERTY_RENDERING_TYPE_UNSPECIFIED',
          },
          {
            property_name: 'tags[release]',
            val_existing: '',
            val_declared: 'production',
            rendering_type: 'FCO_PROPERTY_RENDERING_TYPE_UNSPECIFIED',
          },
          {
            property_name: 'owner',
            val_existing: 'vince@tecton.ai',
            val_declared: 'david@tecton.ai',
            rendering_type: 'FCO_PROPERTY_RENDERING_TYPE_UNSPECIFIED',
          },
        ],
        name: 'merchant',
        description: 'an online merchant selling goods',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Entity',
        type: 'DELETE',
        transition_side_effects: {},
        name: 'Order',
        description: '',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Entity',
        type: 'DELETE',
        transition_side_effects: {},
        name: 'Customer',
        description: '',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Entity',
        type: 'DELETE',
        transition_side_effects: {},
        name: 'user',
        description: 'a user making am online transaction',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Entity',
        type: 'DELETE',
        transition_side_effects: {},
        name: 'transaction',
        description: 'a transaction between a user and a merchant',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Transformation',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'user_ad_embedding_similarity',
        description:
          'Computes the cosine similarity between a precomputed ad embedding and a precomputed user embedding.',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Transformation',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'user_query_embedding_similarity',
        description: 'Computes the cosine similarity between a query embedding and a precomputed user embedding.',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Transformation',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'content_keyword_click_counts',
        description: 'The count of ad impressions for a content_keyword',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Transformation',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'user_ad_impression_counts',
        description: 'The count of impressions between a given user and a given ad',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Transformation',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'user_click_counts',
        description: 'The count of ad clicks for a user',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Transformation',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'user_impression_counts',
        description: 'The count of ad impressions for a user',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Transformation',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'user_distinct_ad_count_7d',
        description: 'How many distinct advertisements a user has been shown in the last week',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Transformation',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'str_split',
        description: '',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Transformation',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'keyword_stats',
        description: '',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Transformation',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'user_transaction_amount_metrics',
        description: 'Transaction amount statistics and total over a series of time windows, updated every 10 minutes.',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Transformation',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'transaction_amount_is_higher_than_average',
        description: 'The transaction amount is higher than the 1 day average.',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Transformation',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'transaction_amount_is_high',
        description: 'The transaction amount is higher than $100.',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Transformation',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'user_home_location',
        description: 'User date of birth, entered at signup.',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Transformation',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'user_to_user_distance',
        description: "How far apart two users' home locations are.",
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Transformation',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'user_date_of_birth',
        description: 'User date of birth, entered at signup.',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Transformation',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'user_age',
        description: "The user's age in days.",
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Transformation',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'transaction_distance_from_home',
        description: "How far a transaction is from the user's home",
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Transformation',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'last_transaction_amount_pyspark',
        description: 'Last user transaction amount (stream calculated)',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Transformation',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'user_continuous_transaction_count',
        description: 'Number of transactions a user has made recently',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Transformation',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'user_recent_transactions',
        description: 'Most recent 10 transaction amounts of a user',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Transformation',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'last_transaction_amount_sql',
        description: 'Last user transaction amount (stream calculated)',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Transformation',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'user_merchant_transaction_counts',
        description: 'User transaction counts at specific merchants over a series of time windows, updated daily.',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Transformation',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'merchant_fraud_rate',
        description: 'The merchant fraud rate over series of time windows, updated daily.',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Transformation',
        type: 'UPDATE',
        transition_side_effects: {},
        diffs: [
          {
            property_name: 'tags[project]',
            val_existing: 'fraud',
            val_declared: '',
            rendering_type: 'FCO_PROPERTY_RENDERING_TYPE_UNSPECIFIED',
          },
        ],
        name: 'user_credit_card_issuer',
        description: 'User credit card issuer derived from the user credit card number.',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Transformation',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'transaction_user_is_adult',
        description: 'Whether the user performing the transaction is over 18 years old.',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Transformation',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'user_transaction_aggregates_by_category',
        description: 'User transaction aggregate metrics split by purchase category.',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Transformation',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'user_transaction_counts',
        description: 'User transaction totals over a series of time windows, updated daily.',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Transformation',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'user_distinct_merchant_transaction_count_30d',
        description: 'How many transactions the user has made to distinct merchants in the last 30 days.',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Transformation',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'last_transaction_amount',
        description: 'Last user transaction amount (batch calculated)',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Transformation',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'user_weekend_transaction_count_30d',
        description: 'How many weekend transactions the user has made in the last 30 days.',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Transformation',
        type: 'DELETE',
        transition_side_effects: {},
        name: 'user_impression_count',
        description: 'Number of impressions for a given user based on streaming impression data',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Transformation',
        type: 'DELETE',
        transition_side_effects: {},
        name: 'user_distinct_ads_count_7d',
        description: 'distinct number of ads seen by a user in the last 7 days',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Transformation',
        type: 'DELETE',
        transition_side_effects: {},
        name: 'user_days_since_signup',
        description: 'user age in days since signup, refreshed daily',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Transformation',
        type: 'DELETE',
        transition_side_effects: {},
        name: 'impression_date_amount_features',
        description: 'Attributes of the impression date',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Transformation',
        type: 'DELETE',
        transition_side_effects: {},
        name: 'timestamp_is_weekend',
        description: '',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Transformation',
        type: 'DELETE',
        transition_side_effects: {},
        name: 'order_dow',
        description: 'Day of the week when the order was made',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Transformation',
        type: 'DELETE',
        transition_side_effects: {},
        name: 'customer_first_order_date',
        description: '',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Transformation',
        type: 'DELETE',
        transition_side_effects: {},
        name: 'days_since_first_order',
        description: "Number of days between the current order and the customer's first order",
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Transformation',
        type: 'DELETE',
        transition_side_effects: {},
        name: 'customer_last_order_amount',
        description: '',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Transformation',
        type: 'DELETE',
        transition_side_effects: {},
        name: 'customer_revenue_lags',
        description: '',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Transformation',
        type: 'DELETE',
        transition_side_effects: {},
        name: 'customer_count_high_value_orders_10d',
        description: '',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Transformation',
        type: 'DELETE',
        transition_side_effects: {},
        name: 'user_count_transactions',
        description: '',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Transformation',
        type: 'DELETE',
        transition_side_effects: {},
        name: 'user_transaction_amt_sum_1h_10m',
        description: '',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Transformation',
        type: 'DELETE',
        transition_side_effects: {},
        name: 'user_last_transaction_amt',
        description: '',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Transformation',
        type: 'DELETE',
        transition_side_effects: {},
        name: 'user_count_distinct_merchants_30d',
        description: '',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Transformation',
        type: 'DELETE',
        transition_side_effects: {},
        name: 'first_transaction_occurence',
        description: '',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Transformation',
        type: 'DELETE',
        transition_side_effects: {},
        name: 'user_mean_transaction_amount',
        description: '',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Transformation',
        type: 'DELETE',
        transition_side_effects: {},
        name: 'user_large_transaction_count_10d',
        description: 'number of transactions over 100 dollars in the past 10 days',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Transformation',
        type: 'DELETE',
        transition_side_effects: {},
        name: 'first_transaction_occurence_v3',
        description: '',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Transformation',
        type: 'DELETE',
        transition_side_effects: {},
        name: 'merchant_fraud_features',
        description: 'Merchant fraud rates and totals over a series of time windows, updated daily',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Transformation',
        type: 'DELETE',
        transition_side_effects: {},
        name: 'transaction_date_amount_features',
        description: 'Attributes of the transaction date and transaction amount',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Feature Table',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'ad_embeddings',
        description: 'Precomputed ad embeddings pushed into Tecton.',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Feature Table',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'user_embeddings',
        description: 'Precomputed user embeddings pushed into Tecton.',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Stream Feature View',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'content_keyword_click_counts',
        description: 'The count of ad impressions for a content_keyword',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Stream Feature View',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'user_ad_impression_counts',
        description: 'The count of impressions between a given user and a given ad',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Stream Feature View',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'user_click_counts',
        description: 'The count of ad clicks for a user',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Stream Feature View',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'user_impression_counts',
        description: 'The count of ad impressions for a user',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Batch Feature View',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'user_distinct_ad_count_7d',
        description: 'How many distinct advertisements a user has been shown in the last week',
        materialization_info: {
          backfill_task_diffs: [
            {
              display_string:
                '10 Backfill jobs 2022-04-30 00:00:00 UTC to 2023-03-23 00:00:00 UTC writing to the Offline Store',
              feature_start_time: new Date('2022-04-30T00:00:00Z'),
              feature_end_time: new Date('2023-03-23T00:00:00Z'),
              number_of_jobs: 10,
              destination: 'MATERIALIZATION_TASK_DIFF_DESTINATION_OFFLINE',
            },
          ],
          batch_task_diff: {
            display_string: '1 Recurring Batch job scheduled every 1 day writing to the Offline Store',
            schedule_interval: '86400s',
            destination: 'MATERIALIZATION_TASK_DIFF_DESTINATION_OFFLINE',
          },
          stream_task_diff: {},
        },
      },
      {
        fco_type: 'Batch Feature View',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'auction_keywords',
        description: '',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Feature Table',
        type: 'RECREATE',
        transition_side_effects: {},
        diffs: [
          {
            property_name: 'Schema Changes',
            val_declared:
              '\n\n\u001b[0m    \u001b[31m- Column `credit_card_issuer`\u001b[0m\n\u001b[0m      type: String\n\u001b[0m\n\u001b[0m    \u001b[31m- Column `signup_timestamp`\u001b[0m\n\u001b[0m      type: Timestamp\n\u001b[0m\n\u001b[0m    \u001b[32m+ Column `timestamp`\u001b[0m\n\u001b[0m      type: Timestamp\n\u001b[0m\n\u001b[0m    \u001b[32m+ Column `user_login_count_30d`\u001b[0m\n\u001b[0m      type: Int64\n\u001b[0m\n\u001b[0m    \u001b[32m+ Column `user_login_count_7d`\u001b[0m\n\u001b[0m      type: Int64\n\u001b[0m\n\u001b[0m',
            rendering_type: 'FCO_PROPERTY_RENDERING_TYPE_ONLY_DECLARED',
          },
          {
            property_name: 'schema.fields[1].name',
            val_existing: 'signup_timestamp',
            val_declared: 'timestamp',
            rendering_type: 'FCO_PROPERTY_RENDERING_TYPE_PLAIN_TEXT',
          },
          {
            property_name: 'schema.fields[1].structfield_json',
            val_existing: '{"name": "signup_timestamp", "type": "timestamp", "nullable": true, "metadata": {}}',
            val_declared: '{"name": "timestamp", "type": "timestamp", "nullable": true, "metadata": {}}',
            rendering_type: 'FCO_PROPERTY_RENDERING_TYPE_PLAIN_TEXT',
          },
          {
            property_name: 'schema.fields[2].name',
            val_existing: 'credit_card_issuer',
            val_declared: 'user_login_count_7d',
            rendering_type: 'FCO_PROPERTY_RENDERING_TYPE_PLAIN_TEXT',
          },
          {
            property_name: 'schema.fields[2].structfield_json',
            val_existing: '{"name": "credit_card_issuer", "type": "string", "nullable": true, "metadata": {}}',
            val_declared: '{"name": "user_login_count_7d", "type": "long", "nullable": true, "metadata": {}}',
            rendering_type: 'FCO_PROPERTY_RENDERING_TYPE_PLAIN_TEXT',
          },
          {
            property_name: 'schema.fields[3].name',
            val_existing: '',
            val_declared: 'user_login_count_30d',
            rendering_type: 'FCO_PROPERTY_RENDERING_TYPE_PLAIN_TEXT',
          },
          {
            property_name: 'schema.fields[3].structfield_json',
            val_existing: '',
            val_declared: '{"name": "user_login_count_30d", "type": "long", "nullable": true, "metadata": {}}',
            rendering_type: 'FCO_PROPERTY_RENDERING_TYPE_PLAIN_TEXT',
          },
          {
            property_name: 'DependencyChanged(Entity)',
            val_declared: 'fraud_user',
            rendering_type: 'FCO_PROPERTY_RENDERING_TYPE_PLAIN_TEXT',
          },
        ],
        name: 'user_login_counts',
        description: 'User login counts over time.',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Stream Feature View',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'user_transaction_amount_metrics',
        description: 'Transaction amount statistics and total over a series of time windows, updated every 10 minutes.',
        materialization_info: {
          backfill_task_diffs: [
            {
              display_string:
                '10 Backfill jobs 2022-04-28 00:00:00 UTC to 2023-03-20 00:00:00 UTC writing to the Offline Store',
              feature_start_time: new Date('2022-04-28T00:00:00Z'),
              feature_end_time: new Date('2023-03-20T00:00:00Z'),
              number_of_jobs: 10,
              destination: 'MATERIALIZATION_TASK_DIFF_DESTINATION_OFFLINE',
            },
            {
              display_string:
                '1 Backfill job 2023-03-20 00:00:00 UTC to 2023-03-23 00:00:00 UTC writing to both the Online and Offline Store',
              feature_start_time: new Date('2023-03-20T00:00:00Z'),
              feature_end_time: new Date('2023-03-23T00:00:00Z'),
              number_of_jobs: 1,
              destination: 'MATERIALIZATION_TASK_DIFF_DESTINATION_ONLINE_AND_OFFLINE',
            },
          ],
          batch_task_diff: {
            display_string: '1 Recurring Batch job scheduled every 1 day writing to both the Online and Offline Store',
            schedule_interval: '86400s',
            destination: 'MATERIALIZATION_TASK_DIFF_DESTINATION_ONLINE_AND_OFFLINE',
          },
          stream_task_diff: {
            display_string: '1 Ongoing Streaming job writing to the Online Store',
          },
        },
      },
      {
        fco_type: 'Batch Feature View',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'user_home_location',
        description: 'User date of birth, entered at signup.',
        materialization_info: {
          backfill_task_diffs: [
            {
              display_string:
                '10 Backfill jobs 2016-12-31 00:00:00 UTC to 2023-03-23 00:00:00 UTC writing to the Online Store',
              feature_start_time: new Date('2016-12-31T00:00:00Z'),
              feature_end_time: new Date('2023-03-23T00:00:00Z'),
              number_of_jobs: 10,
              destination: 'MATERIALIZATION_TASK_DIFF_DESTINATION_ONLINE',
            },
          ],
          batch_task_diff: {
            display_string: '1 Recurring Batch job scheduled every 1 day writing to the Online Store',
            schedule_interval: '86400s',
            destination: 'MATERIALIZATION_TASK_DIFF_DESTINATION_ONLINE',
          },
          stream_task_diff: {},
        },
      },
      {
        fco_type: 'Batch Feature View',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'user_date_of_birth',
        description: 'User date of birth, entered at signup.',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Stream Feature View',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'last_transaction_amount_pyspark',
        description: 'Last user transaction amount (stream calculated)',
        materialization_info: {
          backfill_task_diffs: [
            {
              display_string:
                '10 Backfill jobs 2022-04-30 00:00:00 UTC to 2023-03-23 00:00:00 UTC writing to the Offline Store',
              feature_start_time: new Date('2022-04-30T00:00:00Z'),
              feature_end_time: new Date('2023-03-23T00:00:00Z'),
              number_of_jobs: 10,
              destination: 'MATERIALIZATION_TASK_DIFF_DESTINATION_OFFLINE',
            },
          ],
          batch_task_diff: {
            display_string: '1 Recurring Batch job scheduled every 1 day writing to the Offline Store',
            schedule_interval: '86400s',
            destination: 'MATERIALIZATION_TASK_DIFF_DESTINATION_OFFLINE',
          },
          stream_task_diff: {},
        },
      },
      {
        fco_type: 'Stream Feature View',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'user_continuous_transaction_count',
        description: 'Number of transactions a user has made recently',
        materialization_info: {
          backfill_task_diffs: [
            {
              display_string:
                '10 Backfill jobs 2022-04-30 00:00:00 UTC to 2023-03-23 00:00:00 UTC writing to the Offline Store',
              feature_start_time: new Date('2022-04-30T00:00:00Z'),
              feature_end_time: new Date('2023-03-23T00:00:00Z'),
              number_of_jobs: 10,
              destination: 'MATERIALIZATION_TASK_DIFF_DESTINATION_OFFLINE',
            },
          ],
          batch_task_diff: {
            display_string: '1 Recurring Batch job scheduled every 1 day writing to the Offline Store',
            schedule_interval: '86400s',
            destination: 'MATERIALIZATION_TASK_DIFF_DESTINATION_OFFLINE',
          },
          stream_task_diff: {},
        },
      },
      {
        fco_type: 'Stream Feature View',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'user_recent_transactions',
        description: 'Most recent 10 transaction amounts of a user',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Stream Feature View',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'last_transaction_amount_sql',
        description: 'Last user transaction amount (stream calculated)',
        materialization_info: {
          backfill_task_diffs: [
            {
              display_string:
                '10 Backfill jobs 2022-04-30 00:00:00 UTC to 2023-03-23 00:00:00 UTC writing to the Offline Store',
              feature_start_time: new Date('2022-04-30T00:00:00Z'),
              feature_end_time: new Date('2023-03-23T00:00:00Z'),
              number_of_jobs: 10,
              destination: 'MATERIALIZATION_TASK_DIFF_DESTINATION_OFFLINE',
            },
          ],
          batch_task_diff: {
            display_string: '1 Recurring Batch job scheduled every 1 day writing to the Offline Store',
            schedule_interval: '86400s',
            destination: 'MATERIALIZATION_TASK_DIFF_DESTINATION_OFFLINE',
          },
          stream_task_diff: {},
        },
      },
      {
        fco_type: 'Batch Feature View',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'user_merchant_transaction_counts',
        description: 'User transaction counts at specific merchants over a series of time windows, updated daily.',
        materialization_info: {
          backfill_task_diffs: [
            {
              display_string:
                '8 Backfill jobs 2022-01-31 00:00:00 UTC to 2022-12-23 00:00:00 UTC writing to the Offline Store',
              feature_start_time: new Date('2022-01-31T00:00:00Z'),
              feature_end_time: new Date('2022-12-23T00:00:00Z'),
              number_of_jobs: 8,
              destination: 'MATERIALIZATION_TASK_DIFF_DESTINATION_OFFLINE',
            },
            {
              display_string:
                '3 Backfill jobs 2022-12-23 00:00:00 UTC to 2023-03-23 00:00:00 UTC writing to both the Online and Offline Store',
              feature_start_time: new Date('2022-12-23T00:00:00Z'),
              feature_end_time: new Date('2023-03-23T00:00:00Z'),
              number_of_jobs: 3,
              destination: 'MATERIALIZATION_TASK_DIFF_DESTINATION_ONLINE_AND_OFFLINE',
            },
          ],
          batch_task_diff: {
            display_string: '1 Recurring Batch job scheduled every 1 day writing to both the Online and Offline Store',
            schedule_interval: '86400s',
            destination: 'MATERIALIZATION_TASK_DIFF_DESTINATION_ONLINE_AND_OFFLINE',
          },
          stream_task_diff: {},
        },
      },
      {
        fco_type: 'Batch Feature View',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'merchant_fraud_rate',
        description: 'The merchant fraud rate over series of time windows, updated daily.',
        materialization_info: {
          backfill_task_diffs: [
            {
              display_string:
                '8 Backfill jobs 2022-01-31 00:00:00 UTC to 2022-12-23 00:00:00 UTC writing to the Offline Store',
              feature_start_time: new Date('2022-01-31T00:00:00Z'),
              feature_end_time: new Date('2022-12-23T00:00:00Z'),
              number_of_jobs: 8,
              destination: 'MATERIALIZATION_TASK_DIFF_DESTINATION_OFFLINE',
            },
            {
              display_string:
                '3 Backfill jobs 2022-12-23 00:00:00 UTC to 2023-03-23 00:00:00 UTC writing to both the Online and Offline Store',
              feature_start_time: new Date('2022-12-23T00:00:00Z'),
              feature_end_time: new Date('2023-03-23T00:00:00Z'),
              number_of_jobs: 3,
              destination: 'MATERIALIZATION_TASK_DIFF_DESTINATION_ONLINE_AND_OFFLINE',
            },
          ],
          batch_task_diff: {
            display_string: '1 Recurring Batch job scheduled every 1 day writing to both the Online and Offline Store',
            schedule_interval: '86400s',
            destination: 'MATERIALIZATION_TASK_DIFF_DESTINATION_ONLINE_AND_OFFLINE',
          },
          stream_task_diff: {},
        },
      },
      {
        fco_type: 'Batch Feature View',
        type: 'RECREATE',
        transition_side_effects: {},
        diffs: [
          {
            property_name: 'tags[project]',
            val_existing: 'fraud',
            val_declared: '',
            rendering_type: 'FCO_PROPERTY_RENDERING_TYPE_UNSPECIFIED',
          },
          {
            property_name: 'online_enabled',
            val_existing: 'true',
            val_declared: 'false',
            rendering_type: 'FCO_PROPERTY_RENDERING_TYPE_UNSPECIFIED',
          },
          {
            property_name: 'offline_enabled',
            val_existing: 'true',
            val_declared: 'false',
            rendering_type: 'FCO_PROPERTY_RENDERING_TYPE_UNSPECIFIED',
          },
          {
            property_name: 'DependencyChanged(Entity)',
            val_declared: 'fraud_user',
            rendering_type: 'FCO_PROPERTY_RENDERING_TYPE_PLAIN_TEXT',
          },
        ],
        name: 'user_credit_card_issuer',
        description: 'User credit card issuer derived from the user credit card number.',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Batch Feature View',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'transaction_user_is_adult',
        description: 'Whether the user performing the transaction is over 18 years old.',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Batch Feature View',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'user_transaction_aggregates_by_category',
        description: 'User transaction aggregate metrics split by purchase category.',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Batch Feature View',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'user_transaction_counts',
        description: 'User transaction totals over a series of time windows, updated daily.',
        materialization_info: {
          backfill_task_diffs: [
            {
              display_string:
                '8 Backfill jobs 2022-01-31 00:00:00 UTC to 2022-12-23 00:00:00 UTC writing to the Offline Store',
              feature_start_time: new Date('2022-01-31T00:00:00Z'),
              feature_end_time: new Date('2022-12-23T00:00:00Z'),
              number_of_jobs: 8,
              destination: 'MATERIALIZATION_TASK_DIFF_DESTINATION_OFFLINE',
            },
            {
              display_string:
                '3 Backfill jobs 2022-12-23 00:00:00 UTC to 2023-03-23 00:00:00 UTC writing to both the Online and Offline Store',
              feature_start_time: new Date('2022-12-23T00:00:00Z'),
              feature_end_time: new Date('2023-03-23T00:00:00Z'),
              number_of_jobs: 3,
              destination: 'MATERIALIZATION_TASK_DIFF_DESTINATION_ONLINE_AND_OFFLINE',
            },
          ],
          batch_task_diff: {
            display_string: '1 Recurring Batch job scheduled every 1 day writing to both the Online and Offline Store',
            schedule_interval: '86400s',
            destination: 'MATERIALIZATION_TASK_DIFF_DESTINATION_ONLINE_AND_OFFLINE',
          },
          stream_task_diff: {},
        },
      },
      {
        fco_type: 'Batch Feature View',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'user_distinct_merchant_transaction_count_30d',
        description: 'How many transactions the user has made to distinct merchants in the last 30 days.',
        materialization_info: {
          backfill_task_diffs: [
            {
              display_string:
                '10 Backfill jobs 2022-03-31 00:00:00 UTC to 2023-03-21 00:00:00 UTC writing to the Offline Store',
              feature_start_time: new Date('2022-03-31T00:00:00Z'),
              feature_end_time: new Date('2023-03-21T00:00:00Z'),
              number_of_jobs: 10,
              destination: 'MATERIALIZATION_TASK_DIFF_DESTINATION_OFFLINE',
            },
            {
              display_string:
                '1 Backfill job 2023-03-21 00:00:00 UTC to 2023-03-23 00:00:00 UTC writing to both the Online and Offline Store',
              feature_start_time: new Date('2023-03-21T00:00:00Z'),
              feature_end_time: new Date('2023-03-23T00:00:00Z'),
              number_of_jobs: 1,
              destination: 'MATERIALIZATION_TASK_DIFF_DESTINATION_ONLINE_AND_OFFLINE',
            },
          ],
          batch_task_diff: {
            display_string: '1 Recurring Batch job scheduled every 1 day writing to both the Online and Offline Store',
            schedule_interval: '86400s',
            destination: 'MATERIALIZATION_TASK_DIFF_DESTINATION_ONLINE_AND_OFFLINE',
          },
          stream_task_diff: {},
        },
      },
      {
        fco_type: 'Batch Feature View',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'last_transaction_amount',
        description: 'Last user transaction amount (batch calculated)',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Batch Feature View',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'user_weekend_transaction_count_30d',
        description: 'How many weekend transactions the user has made in the last 30 days.',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Stream Feature View',
        type: 'DELETE',
        transition_side_effects: {},
        name: 'user_impression_count',
        description: 'Number of impressions for a given user based on streaming impression data',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Batch Feature View',
        type: 'DELETE',
        transition_side_effects: {},
        name: 'user_distinct_ads_count_7d',
        description: 'distinct number of ads seen by a user in the last 7 days',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Batch Feature View',
        type: 'DELETE',
        transition_side_effects: {},
        name: 'user_days_since_signup',
        description: 'user age in days since signup, refreshed daily',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Batch Feature View',
        type: 'DELETE',
        transition_side_effects: {},
        name: 'customer_first_order_date',
        description: '',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Batch Feature View',
        type: 'DELETE',
        transition_side_effects: {},
        name: 'count_weekend_orders',
        description: 'Count of orders made during a weekend',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Batch Feature View',
        type: 'DELETE',
        transition_side_effects: {},
        name: 'customer_last_order_amount',
        description: '',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Batch Feature View',
        type: 'DELETE',
        transition_side_effects: {},
        name: 'customer_revenue_lags',
        description: '',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Batch Feature View',
        type: 'DELETE',
        transition_side_effects: {},
        name: 'customer_count_high_value_orders_10d',
        description: '',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Stream Feature View',
        type: 'DELETE',
        transition_side_effects: {},
        name: 'user_count_transactions',
        description: '',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Stream Feature View',
        type: 'DELETE',
        transition_side_effects: {},
        name: 'user_transaction_amt_sum_1h_10m',
        description: '',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Stream Feature View',
        type: 'DELETE',
        transition_side_effects: {},
        name: 'user_last_transaction_amt',
        description: '',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Batch Feature View',
        type: 'DELETE',
        transition_side_effects: {},
        name: 'user_count_distinct_merchants_30d',
        description: '',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Batch Feature View',
        type: 'DELETE',
        transition_side_effects: {},
        name: 'user_first_transaction:v2',
        description: 'features related to a users first transaction',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Batch Feature View',
        type: 'DELETE',
        transition_side_effects: {},
        name: 'user_mean_transaction_amount',
        description: '',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Batch Feature View',
        type: 'DELETE',
        transition_side_effects: {},
        name: 'user_large_transaction_count_10d',
        description: 'number of transactions over 100 dollars in the past 10 days',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Batch Feature View',
        type: 'DELETE',
        transition_side_effects: {},
        name: 'user_first_transaction:v3',
        description: 'features related to a users first transaction',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Batch Feature View',
        type: 'DELETE',
        transition_side_effects: {},
        name: 'merchant_fraud_features',
        description: 'Merchant fraud rates and totals over a series of time windows, updated daily',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'On-Demand Feature View',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'user_ad_embedding_similarity',
        description:
          'Computes the cosine similarity between a precomputed ad embedding and a precomputed user embedding.',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'On-Demand Feature View',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'user_query_embedding_similarity',
        description: 'Computes the cosine similarity between a query embedding and a precomputed user embedding.',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'On-Demand Feature View',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'transaction_amount_is_higher_than_average',
        description: 'The transaction amount is higher than the 1 day average.',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'On-Demand Feature View',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'transaction_amount_is_high',
        description: 'The transaction amount is higher than $100.',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'On-Demand Feature View',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'user_to_user_distance',
        description: "How far apart two users' home locations are.",
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'On-Demand Feature View',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'user_age',
        description: "The user's age in days.",
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'On-Demand Feature View',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'transaction_distance_from_home',
        description: "How far a transaction is from the user's home",
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'On-Demand Feature View',
        type: 'DELETE',
        transition_side_effects: {},
        name: 'impression_date_amount_features',
        description: 'Attributes of the impression date',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'On-Demand Feature View',
        type: 'DELETE',
        transition_side_effects: {},
        name: 'order_dow',
        description: 'Day of the week when the order was made',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'On-Demand Feature View',
        type: 'DELETE',
        transition_side_effects: {},
        name: 'days_since_first_order',
        description: "Number of days between the current order and the customer's first order",
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'On-Demand Feature View',
        type: 'DELETE',
        transition_side_effects: {},
        name: 'transaction_date_amount_features',
        description: 'Attributes of the transaction date and transaction amount',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Feature Service',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'ad_ctr_feature_service',
        description: 'A FeatureService providing features for a model that predicts if a user will click an ad.',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Feature Service',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'fraud_detection_feature_service',
        description: '',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Feature Service',
        type: 'CREATE',
        transition_side_effects: {},
        name: 'continuous_feature_service',
        description: 'A FeatureService providing continuous features.',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Feature Service',
        type: 'DELETE',
        transition_side_effects: {},
        name: 'ads_service',
        description: 'A feature service providing features for the ads click prediction',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Feature Service',
        type: 'DELETE',
        transition_side_effects: {},
        name: 'order_service',
        description: 'A Feature Service used for supporting a next best action model',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
      {
        fco_type: 'Feature Service',
        type: 'DELETE',
        transition_side_effects: {},
        name: 'fraud_service',
        description: 'A feature service providing features for the transaction fraud prediction model',
        materialization_info: { batch_task_diff: {}, stream_task_diff: {} },
      },
    ],
    applied_by: 'vince@tecton.ai',
    created_by: 'vince@tecton.ai',
    applied_at: new Date('2023-03-23T17:31:08.111614Z'),
    created_at: new Date('2023-03-23T17:30:45.789278Z'),
    workspace: 'prod',
    sdk_version: '0.5.5',
  },
};

const fetchStateUpdatePlanSummary = async (data: GetStateUpdatePlanSummaryRequest): Promise<any> => {
  return mockPlan;
};

 */

const fetchStateUpdatePlanSummary = async (
  data: GetStateUpdatePlanSummaryRequest
): Promise<StateUpdatePlanSummaryType> => {
  const response = await Metadata_Service('get-state-update-plan-summary', {
    data,
    method: 'POST',
  });
  return response.data;
};

export const useGetStateUpdatePlanSummary = (planId: string | undefined) => {
  const stateUpdatePlanSummaryRequest: GetStateUpdatePlanSummaryRequest = { plan_id: IdUtils.fromStringId(planId) };

  return useQuery(
    ['get-state-update-plan-summary', planId],
    () => {
      return fetchStateUpdatePlanSummary(stateUpdatePlanSummaryRequest);
    },
    {
      select: (data) => transformPlanSummary(data),
      enabled: !!planId,
    }
  );
};

const fetchQueryStateUpdateResponse = async (data: QueryStateUpdateRequestV2): Promise<QueryStateUpdateResponseV2> => {
  const response = await Metadata_Service('query-state-update-response', {
    data,
    method: 'POST',
  });
  return response.data;
};

export const useGetQueryStateUpdateResponse = (state_id: string, workspace: string) => {
  const request: QueryStateUpdateRequestV2 = {
    state_id: IdUtils.fromStringId(state_id),
    workspace,
  };
  return useQuery(
    ['query-state-update-response', state_id, workspace],
    () => {
      return fetchQueryStateUpdateResponse(request);
    },
    {
      select: (data) => transformQueryStateUpdateResponse(data),
      enabled: !!state_id && !!workspace,
    }
  );
};
