export default () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.3332 4.66602V12.666C12.3332 13.4024 11.7362 13.9993 10.9998 13.9993H4.99984C4.26346 13.9993 3.6665 13.4024 3.6665 12.666V4.66602"
        stroke="#323232"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.3332 4.66732H2.6665"
        stroke="#323232"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M10 11.3333H6" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M5.6665 4.66667V4.33333C5.6665 3.04467 6.71117 2 7.99984 2V2C9.2885 2 10.3332 3.04467 10.3332 4.33333V4.66667"
        stroke="#323232"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
