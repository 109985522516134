import React, { FC, ReactNode } from 'react';
import styled from '@emotion/styled';
import { EuiFormRow } from '@elastic/eui';

interface TectonFormRowProps {
  children: React.ReactNode;
  error?: boolean;
  errorText?: React.ReactNode;
  message?: React.ReactNode;
  label: React.ReactNode;
  fullWidth?: boolean;
  isDisabled?: boolean;
  isInvalid?: boolean;
  onFocus?: () => void;
}

const LabelWrapper = styled.div<{ error: boolean }>`
  font-size: ${({ theme }) => theme.font.fontSizes.s};
  color: ${({ theme, error }) => (error ? theme.colors.dangerText : theme.colors.title)};
  font-weight: ${({ theme, error }) => (error ? theme.font.weight.medium : theme.font.weight.regular)};
  margin: 0;
  padding: 0;
  line-height: 1;
`;

const MessageWrapper = styled.div`
  font-size: ${({ theme }) => theme.font.fontSizes.s};
  color: ${({ theme }) => theme.colors.subduedText};
  padding-top: ${({ theme }) => theme.padding.xxs};
`;

const ErrorWrapper = styled.div`
  font-size: ${({ theme }) => theme.font.fontSizes.s};
  color: ${({ theme }) => theme.colors.dangerText};
  font-weight: ${({ theme }) => theme.font.weight.medium} !important;
  line-height: ${({ theme }) => theme.font.lineHeights.s};
  margin: 0;
  padding-top: ${({ theme }) => theme.padding.xs};
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.padding.xs};
  cursor: default;
`;

const TectonFormRow: FC<TectonFormRowProps> = ({
  children,
  label,
  error,
  errorText,
  message,
  fullWidth,
  isDisabled,
  isInvalid,
  onFocus,
}) => {
  const MessageDiv = (
    <FlexWrapper>
      {errorText && <ErrorWrapper>{errorText}</ErrorWrapper>}
      <MessageWrapper>{message}</MessageWrapper>
    </FlexWrapper>
  );

  return (
    <div>
      <EuiFormRow
        fullWidth={fullWidth}
        label={<LabelWrapper error={!!error}>{label}</LabelWrapper>}
        helpText={MessageDiv}
        isDisabled={isDisabled}
        isInvalid={isInvalid}
        onFocus={onFocus}
      >
        <>{children}</>
      </EuiFormRow>
    </div>
  );
};

export default TectonFormRow;
