import React from 'react';
import { Button, ButtonEmpty, FormRow, CallOut, Link, Spacer } from '@tecton';
import WorkspaceRoleSelectorFormRow from '../../workspace/WorkspaceRoleSelectorFormRow';
import { OptionsInfo } from '../../shared/RowSelectorFormRow';
import { ACLRoleSource } from '../types';
import { useNavigate } from 'react-router';
import { CalloutMessageType, TectonBasicModal } from '../../@shared';

// Simple Component to call out that a user might be part of a group that has roles which you can't change. We will just leave it on this file.
const OtherRoleSourcesCallout = ({ userId, roleSources }: { userId: string; roleSources: ACLRoleSource[] }) => {
  const navigate = useNavigate();

  const groupRoleSources = roleSources.filter((rs) => {
    return rs.type === 'via group';
  });

  if (groupRoleSources.length === 0) {
    return <></>;
  }

  const groupsList = groupRoleSources.map((rs, index) => {
    let separator: React.ReactNode = <></>;

    if (index > 0) {
      if (index === groupRoleSources.length - 1) {
        separator = <> and </>;
      } else {
        separator = <>, </>;
      }
    }

    return (
      <>
        {separator}
        <Link
          key={rs.groupId}
          onClick={() => {
            navigate(`/settings/accounts-and-access/groups/${rs.groupId}`);
          }}
        >
          {rs.groupName}
        </Link>
      </>
    );
  });

  const listOfGroups = <span> {groupsList}</span>;

  return (
    <>
      <CallOut size="m" iconType="alert" title={<>{userId} has other roles in this workspace.</>}>
        Other roles come via group membership in
        {listOfGroups}. Those cannot be changed in this modal. This modal can only change their directly assigned role.
      </CallOut>
      <Spacer size="s" />
    </>
  );
};

const ModifyWorkspaceRoleModal = ({
  userId,
  allRoleRecords,
  workspaceId,
  currentRole,
  selectedRole,
  setSelectedRole,
  roleOptions,
  currentSourcesOfRoles,
  isLoading,
  errorMessages,
  cancel,
  confirmChanges,
}: {
  userId: string;
  allRoleRecords: OptionsInfo<string>[];
  workspaceId: string;
  currentRole: string | undefined;
  selectedRole: string | '';
  setSelectedRole: (selectedRole: string) => void;
  roleOptions?: React.ComponentProps<typeof WorkspaceRoleSelectorFormRow>['roleOptions'];
  currentSourcesOfRoles?: ACLRoleSource[];
  isLoading: boolean;
  errorMessages: CalloutMessageType[] | undefined;
  cancel: () => void;
  confirmChanges: () => void;
}) => {
  const title = `Modify role of ${userId} in ${workspaceId}`;

  return (
    <TectonBasicModal
      style={{ width: `80em` }}
      maxWidth={'80vw'}
      title={title}
      body={
        <div style={{ minWidth: `50em` }}>
          {currentSourcesOfRoles && <OtherRoleSourcesCallout userId={userId} roleSources={currentSourcesOfRoles} />}
          <FormRow label="Select Role" fullWidth>
            <WorkspaceRoleSelectorFormRow
              userId={userId}
              allRoleRecords={allRoleRecords}
              roleOptions={roleOptions}
              currentRole={currentRole}
              selectedRole={selectedRole}
              setSelectedRole={setSelectedRole}
            />
          </FormRow>
        </div>
      }
      actions={
        <>
          <ButtonEmpty key="cancel" onClick={cancel}>
            Cancel
          </ButtonEmpty>
          <Button
            key="confirm"
            fill
            isDisabled={selectedRole === currentRole}
            isLoading={isLoading}
            onClick={confirmChanges}
          >
            Change access
          </Button>
        </>
      }
      calloutMessages={errorMessages}
      onClose={cancel}
    />
  );
};

export default ModifyWorkspaceRoleModal;
