import { createAction } from 'redux-actions';
import keyMirror from 'keymirror';

const WorkspaceActions = keyMirror({ setWorkspaceAction: null });
export const setWorkspaceAction = createAction(WorkspaceActions.setWorkspaceAction);

const PathStateActions = keyMirror({ setPathStateAction: null });
export const setPathStateAction = createAction(PathStateActions.setPathStateAction);

const ApplyLogStateActions = keyMirror({ setApplyLogAction: null });
export const setApplyLogAction = createAction(ApplyLogStateActions.setApplyLogAction);
