import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from './App';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import store from './core/store';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { browserTracingIntegration } from '@sentry/react';

import RedirectToBasePath from './core/initialization/RedirectToBasePath';

Sentry.init({
  dsn: 'https://7654d8de35fc47a4beebf91f9593ccba@o4504493647724544.ingest.sentry.io/4504845176012800',
  integrations: [browserTracingIntegration()],
  tracesSampleRate: 0.5,
});
// https://reactjs.org/blog/2022/03/08/react-18-upgrade-guide.html
const container = document.getElementById('root');
const root = createRoot(container!);
export const BASE_ROOT_PATH = 'app';

if (process.env.REACT_APP_USE_MSW === 'true') {
  const { worker } = require('./mocks/browser');
  worker.start({
    onUnhandledRequest: 'bypass',
  });
}

root.render(
  <>
    <BrowserRouter basename={`/${BASE_ROOT_PATH}`}>
      <Provider store={store}>
        <CookiesProvider>
          <App />
        </CookiesProvider>
      </Provider>
    </BrowserRouter>
    <BrowserRouter>
      <Routes>
        {/* Catch all routes that doesn't start with /app */}
        <Route path="*" element={<RedirectToBasePath />} />
      </Routes>
    </BrowserRouter>
  </>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
