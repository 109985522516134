import styled from '@emotion/styled';
import React, { FC } from 'react';

interface ApplicationLayoutProps {
  leftSideContent: React.ReactNode;
  rightSideContent: React.ReactNode;
}

const AppWrapper = styled.div`
  width: 100vw;
  overflow: hidden;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: auto 1fr;
  background-color: ${({ theme }) => theme.colors.body};
  gap: ${({ theme }) => theme.padding.m};
`;

const ApplicationLayout: FC<ApplicationLayoutProps> = ({ leftSideContent, rightSideContent }) => {
  return (
    <AppWrapper data-testid="tecton-application-layout">
      <div>{leftSideContent}</div>
      <div className="right-content">{rightSideContent}</div>
    </AppWrapper>
  );
};

export default ApplicationLayout;
