import styled from '@emotion/styled';
import React, { FC } from 'react';

interface CardProps {
  children: React.ReactNode;
  title?: React.ReactNode;
  noScroll?: boolean;
  centerBody?: boolean;
  flex?: boolean;
  showOverlay?: boolean; // Added this since in ACL a button is at the bottom and it's not clickable
}

const CardWrapper = styled.div<CardProps>`
  background-color: ${({ theme }) => theme.colors.emptyShade};
  padding: 0;
  border-radius: ${({ theme }) => theme.border.radius.medium};
  border: ${({ theme }) => theme.border.thin};
  ${({ noScroll }) => !noScroll && `max-height: 400px`};
  display: ${({ flex }) => (flex ? 'flex' : 'grid')};
  grid-template-rows: auto 1fr;
  // Only show the gap when we show the overlay
  ${({ theme, showOverlay }) => {
    return showOverlay ? 'gap:' + theme.padding.l + ';' : '';
  }};
  position: relative;
  height: 100%;
`;

const CardTitle = styled.div`
  margin: ${({ theme }) => theme.padding.xl};
  margin-bottom: ${({ theme }) => theme.padding.l};
  font-size: ${({ theme }) => theme.font.fontSizes.s};
  font-weight: ${({ theme }) => theme.font.weight.medium};
`;

const CardBody = styled.div<CardProps>`
  // Don't show scrollbars
  overflow: auto;
  margin: ${({ theme }) => `0 ${theme.padding.xl}`};
  // There should be a "clear" color token from themes
  // Scrollbar color should come from theme so dark mode is easier to support
  scrollbar-color: rgba(105, 112, 125, 0) rgba(0, 0, 0, 0);

  &:hover {
    // There should be a "clear" color token from themes
    // Scrollbar color should come from theme so dark mode is easier to support
    scrollbar-color: rgba(105, 112, 125, 0.5) rgba(0, 0, 0, 0);
  }
  // Make sure the content is center from the card horizontally and vertically

  padding-bottom: ${({ theme }) => theme.padding.xl};

  ${({ centerBody }) =>
    centerBody &&
    `
    display: flex;
    align-items: center;
    justify-content: center;`};
`;

const CardOverlay = styled.div`
  height: ${({ theme }) => theme.padding.xxl};
  z-index: 600;
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0.7),
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0)
  );
  border-radius: ${({ theme }) => theme.border.radius.medium};

  bottom: 0;
  left: 0;
  width: 100%;
  position: absolute;
`;

const TectonCard: FC<CardProps> = ({ children, title, noScroll, centerBody, flex = false, showOverlay = true }) => {
  return (
    <CardWrapper data-testid="card" flex={flex} noScroll={noScroll}>
      {title && <CardTitle>{title}</CardTitle>}
      <CardBody centerBody={centerBody}>{children}</CardBody>
      {showOverlay && <CardOverlay />}
    </CardWrapper>
  );
};

export default TectonCard;
