export default () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.99984 2V4" stroke="#525866" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M10.9998 2V4" stroke="#525866" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M14 6.66671H2" stroke="#525866" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <rect
        x="2"
        y="3"
        width="12"
        height="11"
        rx="3"
        stroke="#525866"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
