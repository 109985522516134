import React, { useState } from 'react';
import { Button, ButtonEmpty, Selectable, EuiSelectableOption, useTectonTheme } from '@tecton';

import { TectonBasicModal, CalloutMessageType } from '@shared';
import { css } from '@emotion/css';
import { Group } from '../../../api/gql/graphql';

export type SelectableGroupOptionGQL = EuiSelectableOption<{
  group: Group;
}>;

const useSelectableGroupOptions = (candidateGroups: SelectableGroupOptionGQL[]) => {
  return useState<SelectableGroupOptionGQL[]>(candidateGroups);
};

const UserAddToGroupModalGQL = ({
  principalId,
  options,
  setOptions,
  onClose,
  addPrincipleToGroup,
  isLoading,
  errorMessages,
}: {
  principalId: string;
  options: SelectableGroupOptionGQL[];
  setOptions: (options: SelectableGroupOptionGQL[]) => void;
  onClose: () => void;
  addPrincipleToGroup: () => void;
  isLoading: boolean;
  errorMessages: CalloutMessageType[] | undefined;
}) => {
  const { theme } = useTectonTheme();

  const title = `Add ${principalId} to a Group`;

  const selectedOptions = options.filter((o) => {
    return o.checked === 'on';
  });

  return (
    <TectonBasicModal
      style={{ width: `80em` }}
      maxWidth={'80vw'}
      title={title}
      onClose={onClose}
      body={
        <div>
          <Selectable
            className={css`
              .euiFormControlLayout {
                margin-bottom: ${theme.size.m};
              }

              .euiSelectableList {
                min-height: 180px;
              }
            `}
            listProps={{ bordered: true }}
            searchable
            options={options}
            singleSelection={true}
            searchProps={{
              placeholder: 'Search for a Group',
              'data-test-subj': 'selectableSearchHere',
            }}
            onChange={(newOptions) => {
              setOptions(newOptions);
            }}
          >
            {(list, search) => (
              <>
                {search}
                {list}
              </>
            )}
          </Selectable>
        </div>
      }
      actions={
        <>
          <ButtonEmpty key="cancel" onClick={onClose}>
            Cancel
          </ButtonEmpty>
          <Button
            key="confirm"
            fill
            isDisabled={selectedOptions.length < 1}
            isLoading={isLoading}
            onClick={addPrincipleToGroup}
          >
            Add to Group
          </Button>
        </>
      }
      calloutMessages={errorMessages}
    />
  );
};

export default UserAddToGroupModalGQL;
export { useSelectableGroupOptions };
