import React from 'react';
import { EuiComboBoxOptionOption, Button, FormRow, Spacer } from '@tecton';
import { TectonBasicModal, CalloutMessageType } from '@shared';
import WorkspaceSelectorComboBox from '../../shared/WorkspaceSelectorComboBox';
import { OptionsInfo } from '../../shared/RowSelectorFormRow';
import WorkspaceRoleSelectorFormRowGQL from '../../workspace/WorkspaceRoleSelectorFormRowGQL';

const UserProfileAddWorkspaceModalGQL = ({
  userId,
  allRoleRecords,
  optionsQuery,
  selectedWorkspace,
  setSelectedWorkspace,
  roleOptions,
  selectedRole,
  setSelectedRole,
  isLoading,
  errorMessages,
  onClose,
  confirmChange,
}: {
  userId: string;
  allRoleRecords: OptionsInfo<string>[];
  optionsQuery: {
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    options: EuiComboBoxOptionOption<string>[] | undefined;
  };
  selectedWorkspace: EuiComboBoxOptionOption<string>[];
  setSelectedWorkspace: (s: EuiComboBoxOptionOption<string>[]) => void;
  roleOptions?: React.ComponentProps<typeof WorkspaceRoleSelectorFormRowGQL>['roleOptions'];
  selectedRole: string | '';
  setSelectedRole: (r: string) => void;
  isLoading: boolean;
  errorMessages: CalloutMessageType[] | undefined;
  onClose: () => void;
  confirmChange: () => void;
}) => {
  const title = `Add ${userId} to a Workspace`;

  return (
    <TectonBasicModal
      style={{ width: `80em` }}
      maxWidth={'80vw'}
      title={title}
      body={
        <div style={{ minWidth: `50em` }}>
          <FormRow label="Select Workspace" fullWidth>
            <WorkspaceSelectorComboBox
              isLoading={optionsQuery.isLoading}
              options={optionsQuery.options || []}
              selectedWorkspace={selectedWorkspace}
              onChange={setSelectedWorkspace}
            />
          </FormRow>
          <Spacer size="xl" />
          <FormRow label="Select Role" fullWidth>
            <WorkspaceRoleSelectorFormRowGQL
              userId={userId}
              allRoleRecords={allRoleRecords}
              roleOptions={roleOptions}
              selectedRole={selectedRole}
              setSelectedRole={setSelectedRole}
            />
          </FormRow>
        </div>
      }
      actions={
        <>
          <Button key="cancel" onClick={onClose}>
            Cancel
          </Button>
          <Button
            key="confirm"
            fill
            isDisabled={selectedWorkspace.length !== 1 || selectedRole === undefined}
            isLoading={isLoading}
            onClick={confirmChange}
            data-testid={`add-workspace-button`}
          >
            Add {selectedWorkspace[0]?.label || 'Workspace'}
          </Button>
        </>
      }
      onClose={onClose}
      calloutMessages={errorMessages}
    />
  );
};

export default UserProfileAddWorkspaceModalGQL;
