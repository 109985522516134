/* eslint-disable react-hooks/exhaustive-deps */
import { scaleLinear } from 'd3-scale';
import React, { FC, useContext, useEffect, useRef, useState, MouseEvent, MouseEventHandler, useCallback } from 'react';
import { max } from 'd3-array';
import { DataQualityMonitoringContext } from './DataQualityMetricsContext';
import { MaterializationEvent } from './DataQualityMetricsTypes';

// This file contains all of the components required to render the column charts in DQM for Jobs, Row Counts, and individual metrics
interface DataQualityMonitoringColumnChartProps {
  data: MaterializationEvent[];
  height: number;
  color: string;
}

// Column charts for the batch materialization metrics panel
export const DataQualityMonitoringColumnChart: FC<DataQualityMonitoringColumnChartProps> = ({
  data,
  height,
  color,
}) => {
  const panelContext = useContext(DataQualityMonitoringContext);

  const [curtainWidthBefore, setCurtainWidthBefore] = useState<number>(0);
  const [curtainWidthAfter, setCurtainWidthAfter] = useState<number>(0);
  const [curtainXPositionAfter, setCurtainXPositionAfter] = useState<number>(0);

  const scaleMax = max(data.map((datum) => datum.value || 1));
  const scaleDomain = [0, scaleMax ? scaleMax : 1];
  const scaleRange = [0, height - 10];
  const valueScale = scaleLinear<number, number>().domain(scaleDomain).range(scaleRange).nice();

  const canvasWidth = -(panelContext.chartScale.range()[1] + panelContext.chartScale.range()[0]);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const instantiateContext = useCallback(() => {
    if (canvasRef.current) {
      const devicePixelRatio = 1;
      const canvas = canvasRef.current as HTMLCanvasElement;
      canvas.style.width = `${canvasWidth}px`;
      canvas.style.height = `${height}px`;

      canvas.width = Math.floor(canvasWidth * devicePixelRatio);
      canvas.height = Math.floor(height * devicePixelRatio);

      const context = canvas.getContext('2d');

      if (context) {
        const asImage = new Image();
        context.scale(devicePixelRatio, devicePixelRatio);
        const initialPaint = drawAllData(canvas, context);
        asImage.src = initialPaint;
        context.drawImage(asImage, 0, 0);
      }
    }
  }, []);

  useEffect(() => {
    instantiateContext();
  }, [instantiateContext]);

  const canvasCoordinateForDate = (date: Date) => panelContext.chartScale(date) + canvasWidth;

  const drawAllData = (canvas: HTMLCanvasElement, context: CanvasRenderingContext2D) => {
    drawBackground(context);

    panelContext.majorTicks.forEach((datum) => {
      drawOneTick(datum, context);
    });

    data.forEach((datum) => {
      drawOneDatum(datum, context);
    });

    return canvas.toDataURL();
  };

  const drawBackground = (context: CanvasRenderingContext2D) => {
    context.fillStyle = 'white';
    context.beginPath();
    context.fillRect(0, 0, canvasWidth, height);
  };

  const drawOneDatum = (datum: MaterializationEvent, context: CanvasRenderingContext2D) => {
    let columnHeight = valueScale(datum.value || 0);

    if (columnHeight > 0 && columnHeight < 1) {
      columnHeight = 1;
    }
    const yValue = height - columnHeight;

    const xCoordinate = canvasCoordinateForDate(datum.date);

    context.fillStyle = color;
    context.strokeStyle = 'white';
    context.lineWidth = 0.25;
    context.beginPath();
    context.rect(xCoordinate, yValue, panelContext.bandWidth, columnHeight);
    context.fill();
    context.stroke();
  };

  const drawOneTick = (tick: Date, canvasContext: CanvasRenderingContext2D) => {
    if (canvasContext) {
      canvasContext.strokeStyle = '#aaa';
      canvasContext.lineWidth = 1;

      const xCoordinate = canvasCoordinateForDate(tick);
      canvasContext.beginPath();
      canvasContext.moveTo(xCoordinate, -10);
      canvasContext.lineTo(xCoordinate, height);
      canvasContext.stroke();
    }
  };

  interface MouseEventWithParent extends MouseEvent {
    // Need to extent the MouseEvent here to allow access to the target element in the actual (not virtual) DOM
    target: HTMLCanvasElement;
  }

  const canvasMouseMove: MouseEventHandler = (event: MouseEventWithParent) => {
    // // Forcing access here is okay because the target will always have a parent div
    // const relativeXCoordinate =
    //   event.clientX - event.target.parentElement!.getBoundingClientRect().left - panelContext.bandWidth;
    // const distanceFromStart = -(panelContext.canvasTranslate - relativeXCoordinate);
    // const relativeCoordinate = panelContext.chartScale.range()[0] + distanceFromStart;
    // const roughDate = panelContext.chartScale.invert(relativeCoordinate);
    // const matchingDate = getNearestEventToDate(
    //   roughDate,
    //   data.map((datum) => datum.date)
    // );
    // const matchingIndex = getIndexForDate(
    //   matchingDate,
    //   data.map((datum) => datum.date)
    // );
    // if (matchingIndex) {
    //   panelContext.handleIndicesSelection([matchingIndex]);
    // }
    // const matchingEvent = getMatchingEventForDate(matchingDate, data);
    // if (matchingEvent) {
    //   panelContext.externalHighlightsWereSelected([matchingEvent]);
    // }
  };

  const canvasMouseOut = () => {
    // panelContext.externalHighlightsWereSelected(undefined);
    // panelContext.handleIndicesSelection(undefined);
  };

  const handleHighlightedIndicesChangedWithDivs = () => {
    if (panelContext.highlightedIndices) {
      const dataToHighlight = panelContext.highlightedIndices.map((index) => data[index]);
      if (dataToHighlight && dataToHighlight[0] !== undefined) {
        const beforeXCoordinate = canvasCoordinateForDate(dataToHighlight[0].date);
        setCurtainWidthBefore(beforeXCoordinate + panelContext.canvasTranslate);
        const afterXCoordinate =
          canvasCoordinateForDate(dataToHighlight[dataToHighlight.length - 1].date) + panelContext.bandWidth;
        setCurtainXPositionAfter(afterXCoordinate + panelContext.canvasTranslate);
        const finalWidth = panelContext.width - panelContext.canvasTranslate - afterXCoordinate;
        setCurtainWidthAfter(finalWidth);
      }
    } else {
      setCurtainWidthBefore(0);
      setCurtainWidthAfter(0);
      setCurtainXPositionAfter(0);
    }
  };

  useEffect(handleHighlightedIndicesChangedWithDivs, [
    panelContext.highlightedIndices,
    panelContext.highlightedEvents,
    panelContext.canvasTranslate,
    panelContext.bandWidth,
    panelContext.width,
    data,
    canvasCoordinateForDate,
  ]);

  if (!panelContext.isReady) {
    return <></>;
  }

  return (
    <div
      style={{
        width: panelContext.width,
        overflowX: 'hidden',
        overflowY: 'hidden',
        height: height,
        position: 'relative',
        textAlign: 'left',
      }}
    >
      <canvas
        style={{ transform: `translateX(${panelContext.canvasTranslate}px)` }}
        onMouseMove={canvasMouseMove}
        onMouseOut={canvasMouseOut}
        onBlur={canvasMouseOut}
        ref={canvasRef}
      />
      <div
        style={{
          height: height,
          width: curtainWidthBefore,
          backgroundColor: 'rgba(255,255,255,0.5)',
          position: 'absolute',
          left: '0',
          top: '0',
          pointerEvents: 'none',
        }}
      >
        {''}
      </div>
      <div
        style={{
          height: height,
          width: curtainWidthAfter,
          backgroundColor: 'rgba(255,255,255,0.5)',
          position: 'absolute',
          left: curtainXPositionAfter,
          top: '0',
          pointerEvents: 'none',
        }}
      >
        {''}
      </div>
    </div>
  );
};
