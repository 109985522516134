import React, { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import {
  useAssignRoleMutation,
  useAssignRolesMutation,
  useClusterAdminInfo,
  useGetAllAssignedPrincipals,
  useGetAllServiceAccounts,
  useGetGroups,
  useGetRoles,
} from '../../api/acl';

import {
  ACLGroupModal,
  getAclRoleSources,
  getLegacyRoleId,
  getUserACLRolesWithInheritance,
  getWorkspaceRole,
  sortRolesForACLRoleSource,
  sortRolesForAssignmentBasic,
} from '../acl/aclUtils';
import { EuiComboBoxOptionOption } from '../@tecton';

import _sortBy from 'lodash/sortBy';
import { ACLInheritanceEnum } from '../acl/types';
import { getAllRolesRecords, getRoles } from '../acl/util';
import { PrincipalType } from '../../types/tecton_proto/auth/principal';
import { AssignRolesRequest, RoleDefinition } from '../../types/tecton_proto/auth/authorization_service';
import { OptionsInfo } from '../shared/RowSelectorFormRow';
import { GetServiceAccountsResponse } from '../../types/tecton_proto/metadataservice/metadata_service';
import { User } from '../../types/tecton_proto/data/user';
import { UserPermissionRow } from './UsersWithPermissions';
import { ServiceAccountPermissionRow } from './ServiceAccountsWithAccess';
import { GroupPermissionRow } from './GroupsWithAccess';
import { ResourceType } from '../../types/tecton_proto/auth/resource';
import { logEvent } from '../../utils/analytics-utils';
import { addToast, ToastContext } from '../@tecton/ToastContext';
import { CalloutMessageType } from '../@shared';

export interface ConfigurationContextProps {
  isLoading?: boolean;
  workspace?: string;
  usersLength?: number;
  clusterAdminInfoData?: User[];
  serviceAccountLength?: number;
  users?: UserPermissionRow[];
  serviceAccounts?: ServiceAccountPermissionRow[];
  userSearch?: string | undefined;
  serviceAccountSearch?: string | undefined;
  groupSearch?: string | undefined;
  userCandidates?: EuiComboBoxOptionOption<string | undefined>[];
  serviceAccountCandidates?: EuiComboBoxOptionOption<string | undefined>[];
  getAllServiceAccountData?: GetServiceAccountsResponse;
  allUserRoleRecords?: OptionsInfo<string>[];
  allServiceAccountRoleRecords?: OptionsInfo<string>[];
  newUserRoleOptions?: string[];
  existingUserRoleOptions?: string[];
  existingServiceAccountOptions?: string[];
  newServiceAccountRoleOptions?: string[];
  userRoles?: RoleDefinition[];
  serviceAccountRoles?: RoleDefinition[];
  groupPermission?: GroupPermissionRow[];
  modal?: ACLGroupModal;
  modalError?: CalloutMessageType[] | undefined;
  selectedRole?: string;
  selectedPrincipal?: { label: string; groupId: string }[];
  candidateGroupsComboBoxOptions?: EuiComboBoxOptionOption<{ label: string; groupId: string }>[];
  updateSelectedRole?: (currentAssignedRole: string) => void;
  updateSelectedPrincipal?: (selectedPrincipal: { label: string; groupId: string }[]) => void;
  showModifyWorkspace?: VoidFunction;
  showAddWorkspace?: VoidFunction;
  setUserSearch?: (value: string | undefined) => void;
  setServiceAccountSearch?: (value: string | undefined) => void;
  setGroupSearch?: (value: string | undefined) => void;
  addWorkspaceToGroup?: () => void;
  resetSelections?: VoidFunction;
  confirmModifyRole?: VoidFunction;
}

export const ConfigurationContext = createContext<ConfigurationContextProps>({});

const ConfigurationContextProvider = ({ children }: { children: ReactNode }) => {
  const { workspace } = useParams();
  const toastContext = useContext(ToastContext);

  // Query to get the user information from okta users
  const { data: clusterAdminInfoData, isLoading: clusterAdminInfoIsLoading } = useClusterAdminInfo();
  const { data: getAllServiceAccountData, isLoading: getAllServiceAccountIsLoading } = useGetAllServiceAccounts();
  const { data: allGroupData, isLoading: allGroupDataIsLoading } = useGetGroups({});
  const { data: rolesData, isLoading: isLoadingGetRoles } = useGetRoles();

  const { data: assignedPrincipalsData, isLoading: assignedPrincipalsIsLoading } = useGetAllAssignedPrincipals(
    workspace as string,
    true
  );

  const allUsers =
    assignedPrincipalsData?.assignments?.filter((assignment) => {
      return !!assignment.principal?.user;
    }) ?? [];

  const allGroups =
    assignedPrincipalsData?.assignments?.filter((assignment) => {
      return !!assignment.principal?.group;
    }) ?? [];

  const allServiceAccounts =
    assignedPrincipalsData?.assignments?.filter((assignment) => {
      return !!assignment.principal?.service_account;
    }) ?? [];

  const assignedUsers = sortRolesForAssignmentBasic(allUsers, rolesData?.roles ?? []);
  const assignedGroups = sortRolesForAssignmentBasic(allGroups, rolesData?.roles ?? []);
  const assignedServiceAccounts = sortRolesForAssignmentBasic(allServiceAccounts, rolesData?.roles ?? []);

  const assignRoleMutation = useAssignRoleMutation();
  const updateWorkspaceToServiceAccountMutation = useAssignRolesMutation();

  // determines which modal to show
  const [modal, setModal] = useState<ACLGroupModal | undefined>();
  const [modalError, setModalError] = useState<CalloutMessageType[] | undefined>();
  const [userSearch, setUserSearch] = useState<string | undefined>();
  const [serviceAccountSearch, setServiceAccountSearch] = useState<string | undefined>();
  const [groupSearch, setGroupSearch] = useState<string | undefined>();
  const userRoles = getRoles(rolesData?.roles, PrincipalType.PRINCIPAL_TYPE_USER);
  const allUserRoleRecords = getAllRolesRecords(userRoles);
  const serviceAccountRoles = getRoles(rolesData?.roles, PrincipalType.PRINCIPAL_TYPE_SERVICE_ACCOUNT);
  const allServiceAccountRoleRecords = getAllRolesRecords(serviceAccountRoles);
  const [selectedRole, setSelectedRole] = useState<string | undefined>();
  const [selectedPrincipal, setSelectedPrincipal] = useState<{ label: string; groupId: string }[] | undefined>();

  const onError = (error: any) => {
    const errorMessage = error?.response?.data?.message ?? error?.response?.statusText;

    setModalError([
      {
        title: String(errorMessage),
        color: 'danger',
        iconType: 'alert',
      },
    ]);
  };

  const groupPermission: GroupPermissionRow[] =
    assignedGroups
      ?.filter((assignment) => {
        if (groupSearch) {
          // using the search filter
          return assignment?.principal?.group?.name?.toLowerCase().includes(groupSearch?.toLowerCase());
        }

        const group = allGroupData?.find((group) => {
          return group.groupId === assignment.principal?.group?.id;
        });

        return !!group;
      })
      ?.map((g) => {
        const group = allGroupData?.find((group) => {
          return group.groupId === g.principal?.group?.id;
        });

        const effectiveAccountType = getWorkspaceRole(g.role_assignments ?? [], serviceAccountRoles ?? []);
        const aclRolesSources = getAclRoleSources(g?.role_assignments ?? [], g.principal?.group, serviceAccountRoles);

        return {
          groupId: g.principal?.group?.id ?? '',
          name: g.principal?.group?.name ?? '',
          hasPriority: false,
          role: {
            effectiveWorkspaceRole: effectiveAccountType[0] ?? '',
            roleSources: aclRolesSources,
          },
          numberOfUsers: group?.members_count ?? 0,
          numberOfServiceAccounts: 0,
        };
      }) ?? [];

  const candidateGroups =
    allGroupData?.filter((group) => {
      const workspaceGroupIds = groupPermission?.map((g) => g.groupId);
      const canInclude = !workspaceGroupIds?.includes(group.groupId);
      return canInclude;
    }) ?? [];

  const candidateGroupsComboBoxOptions: EuiComboBoxOptionOption<{ label: string; groupId: string }>[] =
    candidateGroups?.map((group) => {
      return {
        label: group.name ?? '',
        groupId: group?.groupId,
      };
    }) ?? [];

  const newUserRoleOptions = userRoles.map((saRole) => {
    return saRole.id ?? '';
  });

  const existingServiceAccountOptions =
    allServiceAccountRoleRecords?.map((i) => {
      return i.id;
    }) ?? [];

  const newServiceAccountRoleOptions = existingServiceAccountOptions.filter((i) => {
    return i !== 'none';
  });

  const existingUserRoleOptions = [...newUserRoleOptions, 'none'];

  const userCandidates = useMemo(() => {
    let userCandidates: EuiComboBoxOptionOption<string | undefined>[] = [];
    if (clusterAdminInfoData && assignedUsers) {
      const clusterInfo = clusterAdminInfoData.filter((user) => {
        const emails = assignedUsers.map((u) => u.principal?.user?.login_email);
        return !emails.includes(user.login_email);
      });

      const candidates = clusterInfo?.map((u) => {
        return { label: u?.login_email } as EuiComboBoxOptionOption<string | undefined>;
      });

      userCandidates = candidates;
    }

    return _sortBy(userCandidates, ['label']);
  }, [clusterAdminInfoData, assignedUsers]);

  const serviceAccountCandidates = useMemo(() => {
    if (assignedServiceAccounts && Array.isArray(getAllServiceAccountData?.service_accounts)) {
      const assignments = assignedServiceAccounts ?? [];
      const saIds = assignments?.map((serviceAccount) => {
        return serviceAccount.principal?.service_account?.id;
      });

      const availableServiceAccount = getAllServiceAccountData?.service_accounts?.filter((sa) => {
        return !saIds?.includes(sa?.id);
      });

      const candidates = availableServiceAccount?.map((sa) => {
        return { label: sa.name } as EuiComboBoxOptionOption<string | undefined>;
      });

      return _sortBy(candidates, ['label']);
    }

    return [];
  }, [assignedServiceAccounts, getAllServiceAccountData]);

  // We need to use all Roles since user can be a consumer via the groups.
  const allRoles = getRoles(rolesData?.roles, PrincipalType.PRINCIPAL_TYPE_SERVICE_ACCOUNT);

  // Sort it
  const users = _sortBy(
    assignedUsers?.map((user) => {
      return getUserACLRolesWithInheritance(
        user?.principal?.user?.login_email,
        user?.role_assignments,
        ACLInheritanceEnum.USER,
        undefined,
        allRoles
      ) as UserPermissionRow;
    }) as UserPermissionRow[],
    ['userId']
  );

  const filteredUsers = useMemo(() => {
    return users?.filter((user) => {
      if (userSearch === '' || userSearch === undefined) {
        return true;
      }
      if (userSearch) {
        return user.userId?.includes(userSearch);
      }

      return false;
    });
  }, [userSearch, users]);

  // Sort it
  const serviceAccounts = _sortBy(
    assignedServiceAccounts?.map((serviceAccount) => {
      return getUserACLRolesWithInheritance(
        serviceAccount.principal?.service_account?.id,
        serviceAccount?.role_assignments,
        ACLInheritanceEnum.SERVICE,
        serviceAccount.principal?.service_account?.name,
        serviceAccountRoles
      ) as ServiceAccountPermissionRow;
    }) as ServiceAccountPermissionRow[],
    ['name']
  );

  const filteredServiceAccounts = useMemo(() => {
    const serviceAccountsFiltered = serviceAccounts
      ?.filter((sa) => {
        if (serviceAccountSearch === '' || serviceAccountSearch === undefined) {
          return true;
        }
        if (serviceAccountSearch) {
          const { name, serviceAccountId } = sa;
          return [name?.toLowerCase(), serviceAccountId?.toLowerCase()]
            .join('')
            .includes(serviceAccountSearch?.toLowerCase());
        }

        return false;
      })
      .map((sa) => {
        const roleSources = sortRolesForACLRoleSource(sa.role?.roleSources, serviceAccountRoles);
        const role = sa.role;
        const sa2 = {
          ...sa,
          role: {
            ...role,
            roleSources,
          },
        } as ServiceAccountPermissionRow;
        return sa2;
      });

    return serviceAccountsFiltered;
  }, [serviceAccountSearch, serviceAccounts]);

  const resetSelections = () => {
    setModal(undefined);
    setSelectedPrincipal(undefined);
    setSelectedRole(undefined);
  };

  const addWorkspaceToGroup = () => {
    const role = getLegacyRoleId(serviceAccountRoles, selectedRole as string);
    const groupName = selectedPrincipal?.[0].label;
    const principal_id = selectedPrincipal?.[0]?.groupId;
    const text = `${workspace} as ${role} role`;

    const payload: AssignRolesRequest = {
      assignments: [
        {
          principal_id,
          role,
          principal_type: PrincipalType.PRINCIPAL_TYPE_GROUP,
          resource_type: ResourceType.RESOURCE_TYPE_WORKSPACE,
          resource_id: workspace as string,
        },
      ],
    };
    assignRoleMutation.mutate(payload, {
      onSuccess: () => {
        toastContext?.dispatchToast(
          addToast({
            title: `Group ${groupName} added to`,
            text,
            iconType: 'lock',
            color: 'success',
          })
        );

        logEvent(`Group ${groupName} added to`, '', {
          principal_id,
          text,
        });

        resetSelections();
      },
      onError,
    });
  };

  const confirmModifyRole = () => {
    const role = getLegacyRoleId(serviceAccountRoles, selectedRole as string);

    const roles = role === 'none' ? [] : [role];
    const groupName = selectedPrincipal?.[0].label;
    const principal_id = selectedPrincipal?.[0]?.groupId;

    const payload = {
      principal_id,
      roles,
      principal_type: PrincipalType.PRINCIPAL_TYPE_GROUP,
      resource_type: ResourceType.RESOURCE_TYPE_WORKSPACE,
      resource_id: workspace as string,
    };

    updateWorkspaceToServiceAccountMutation.mutate(payload, {
      onSuccess: () => {
        toastContext?.dispatchToast(
          addToast({
            title: `Group ${groupName} role updated to`,
            role,
            iconType: 'lock',
            color: 'success',
          })
        );

        logEvent(`Group ${groupName} role updated`, '', {
          principal_id,
          role,
        });

        resetSelections();
      },
      onError,
    });
  };

  const updateSelectedRole = (currentAssignedRole?: string) => {
    setSelectedRole(currentAssignedRole);
  };

  const updateSelectedPrincipal = (selectedPrincipal: { label: string; groupId: string }[]) => {
    setSelectedPrincipal(selectedPrincipal);
  };

  const showModifyWorkspace = () => {
    setModal(ACLGroupModal.MODIFY_WORKSPACE);
  };

  const showAddWorkspace = () => {
    setModal(ACLGroupModal.ADD_WORKSPACE);
  };

  const isLoading =
    allGroupDataIsLoading ||
    clusterAdminInfoIsLoading ||
    getAllServiceAccountIsLoading ||
    assignedPrincipalsIsLoading ||
    isLoadingGetRoles;

  return (
    <ConfigurationContext.Provider
      value={{
        isLoading,
        workspace,
        usersLength: assignedUsers?.length,
        clusterAdminInfoData,
        serviceAccountLength: assignedServiceAccounts?.length,
        users: filteredUsers ?? [],
        serviceAccounts: filteredServiceAccounts ?? [],
        userSearch,
        serviceAccountSearch,
        userCandidates,
        serviceAccountCandidates,
        getAllServiceAccountData,
        allUserRoleRecords,
        allServiceAccountRoleRecords,
        newUserRoleOptions,
        existingUserRoleOptions,
        existingServiceAccountOptions,
        newServiceAccountRoleOptions,
        userRoles,
        serviceAccountRoles,
        groupPermission,
        modal,
        modalError,
        candidateGroupsComboBoxOptions,
        groupSearch,
        selectedRole,
        selectedPrincipal,
        updateSelectedRole,
        updateSelectedPrincipal,
        showModifyWorkspace,
        showAddWorkspace,
        setUserSearch,
        setServiceAccountSearch,
        setGroupSearch,
        addWorkspaceToGroup,
        resetSelections,
        confirmModifyRole,
      }}
    >
      {children}
    </ConfigurationContext.Provider>
  );
};

export default ConfigurationContextProvider;
