import React from 'react';
import { AttributesList, EmptyValue, Link, Monospace, Panel, Spacer, useTectonTheme } from '@tecton';
import { CardInnerTitle } from '@shared';
import { css } from '@emotion/css';

import { processDiffItems, scrollIdMap } from './util';

import PlanItemCountsTable from './PlanItemCountsTable';
import { StateUpdatePlanSummaryType } from './PlanSummaryTransform';
import { useParams } from 'react-router';

const SummaryPanel = ({ stateUpdatePlanSummary }: { stateUpdatePlanSummary?: StateUpdatePlanSummaryType }) => {
  const summaryColumnSize = `22em`;
  const { theme } = useTectonTheme();
  const { workspace } = useParams();

  const nestedGroup = processDiffItems(stateUpdatePlanSummary?.plan?.diff_items ?? []);

  const summaryPanelCss = css`
    position: sticky;
    width: ${summaryColumnSize};
    float: left;
    top: ${theme.size.xl};
    left: ${theme.size.xl};
    max-height: calc(100vh - ${theme.size.xl} * 5);
    overflow: auto;

    .tecton-key-values-table {
      width: 100%;

      th {
        width: 70%;
      }

      td {
        width: 30%;
        text-align: right;
      }
    }
  `;

  const listItems = [
    {
      title: 'Created by',
      description: stateUpdatePlanSummary?.plan?.created_by ? (
        <>{stateUpdatePlanSummary?.plan?.created_by}</>
      ) : (
        <EmptyValue />
      ),
    },
    {
      title: 'Created at',
      description: stateUpdatePlanSummary?.createdAt ? <>{stateUpdatePlanSummary?.createdAt}</> : <EmptyValue />,
    },

    {
      title: 'Applied by',
      description: stateUpdatePlanSummary?.hasApplied ? (
        <>{stateUpdatePlanSummary?.plan?.applied_by}</>
      ) : (
        <EmptyValue />
      ),
    },
    {
      title: 'Applied at',
      description: stateUpdatePlanSummary?.hasApplied ? <>{stateUpdatePlanSummary?.appliedAt}</> : <EmptyValue />,
    },

    {
      title: 'Workspace',
      description: stateUpdatePlanSummary?.plan?.workspace ? (
        <Link href={`/app/repo/${workspace}/home/`}>{stateUpdatePlanSummary?.plan?.workspace}</Link>
      ) : (
        <EmptyValue />
      ),
    },
    {
      title: 'SDK Version',
      description: stateUpdatePlanSummary?.plan?.sdk_version ? (
        <Monospace>{stateUpdatePlanSummary?.plan?.sdk_version}</Monospace>
      ) : (
        <EmptyValue />
      ),
    },
  ];

  if (!stateUpdatePlanSummary) {
    return <></>;
  }

  return (
    <Panel hasShadow={false} hasBorder className={summaryPanelCss + ' eui-scrollBar'}>
      <CardInnerTitle>
        <h3>Metadata</h3>
      </CardInnerTitle>
      <AttributesList compressed listItems={listItems} />
      <Spacer size={'xl'} />
      <CardInnerTitle>
        <h3>Object Changes</h3>
      </CardInnerTitle>
      {nestedGroup.map((itemsByFco) => {
        return (
          <PlanItemCountsTable
            key={itemsByFco.fco_type}
            scrollId={scrollIdMap[itemsByFco.fco_type] || ''}
            itemsByFco={itemsByFco}
          />
        );
      })}
    </Panel>
  );
};

export default SummaryPanel;
