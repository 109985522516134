import React from 'react';

export default function EnabledDisabled({ isEnabled, prefix }) {
  if (isEnabled) {
    return (
      <div className="status">
        <span className="status__text">{prefix}enabled</span>
      </div>
    );
  } else {
    return (
      <div className="status status--disabled">
        <span className="status__text">{prefix}disabled</span>
      </div>
    );
  }
}
