import { createAction } from 'redux-actions';
import { get } from 'lodash';
import keyMirror from 'keymirror';

export const NetworkActionType = keyMirror({
  REQUEST: null,
  SUCCESS: null,
  FAILURE: null,
});

export function createNetworkActions(name) {
  return {
    name: name.toUpperCase(),
    request: createAction(`${name.toUpperCase()}/${NetworkActionType.REQUEST}`, (payload) => payload),
    success: createAction(`${name.toUpperCase()}/${NetworkActionType.SUCCESS}`, (payload) => payload),
    failure: createAction(`${name.toUpperCase()}/${NetworkActionType.FAILURE}`, (payload) => payload),
  };
}

export function throwErrorIfExists(state, selectorName) {
  const error = get(state, selectorName, null);
  if (error !== null) {
    throw error;
  }
}
