import React, { createContext, ReactNode, useContext, useState, useEffect } from 'react';

export interface TimezoneContextProps {
  timezone: string;
  updateTimezone: (timezone: string) => void;
}

export interface TimezoneProviderContextProps {
  children: ReactNode;
}

export const TimezoneContext = createContext<TimezoneContextProps | undefined>(undefined);

export const TimezoneContextProvider: React.FC<TimezoneProviderContextProps> = ({ children }) => {
  const [timezone, setTimezone] = useState<string>('UTC');

  const updateTimezone = (newTimezone: string) => {
    localStorage.setItem('userTimezone', newTimezone);
    setTimezone(newTimezone);
  };

  // Load the user's timezone from local storage when the component mounts.
  useEffect(() => {
    const savedTimezone = localStorage.getItem('userTimezone');
    if (savedTimezone) {
      setTimezone(savedTimezone);
    }
  }, []);

  return <TimezoneContext.Provider value={{ timezone, updateTimezone }}>{children}</TimezoneContext.Provider>;
};

export const useTimezoneSettings = (): TimezoneContextProps => {
  const context = useContext(TimezoneContext);
  if (context === undefined) {
    throw new Error('useTimezoneSettings must be used within a TimezoneContextProvider');
  }
  return context;
};

export default TimezoneContextProvider;
