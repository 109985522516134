import React, { FC } from 'react';
import { Duration } from '../../../../types/google/protobuf/duration';
import { secondsToHumanFriendlyString } from '../../../../utils/misc-utils';
import { useTectonTheme, EmptyValue } from '@tecton/ComponentRedesign';
import styled from '@emotion/styled';

interface TimeIntervalDisplayProps {
  interval: Duration | number | undefined;
}

const TimeIntervalDisplay: FC<TimeIntervalDisplayProps> = ({ interval }) => {
  const { theme } = useTectonTheme();

  const Monospace = styled.div`
    font-family: ${theme.font.familyCode};
  `;

  if (!interval) {
    return <EmptyValue />;
  }

  if (typeof interval === 'string') {
    return <Monospace>{interval}</Monospace>;
  }

  if (!isNaN(interval as number)) {
    return <Monospace>{secondsToHumanFriendlyString(interval)}</Monospace>;
  }

  if (secondsToHumanFriendlyString((interval as Duration).seconds) === '') {
    return <EmptyValue />;
  }

  return (
    <Monospace data-testid="time-interval-display">
      {secondsToHumanFriendlyString((interval as Duration).seconds)}
    </Monospace>
  );
};

export default TimeIntervalDisplay;
