import React, { FC } from 'react';

import { DataSourceType } from '../../types/tecton_proto/common/data_source_type';
import DataSourceCommonTypeBadge from '../../components/@tecton/DataSourceCommonTypeBadge';

interface PlanApplyDataSourceBadgeProps {
  type: string;
}

const PlanApplyDataSourceBadge: FC<PlanApplyDataSourceBadgeProps> = ({ type }) => {
  // Keys can be found in FcoStrings.kt since n string can map to a given DataSourceType
  const dsTypeShortName: Record<string, DataSourceType> = {
    'Batch Data Source': DataSourceType.BATCH,
    'Stream Data Source': DataSourceType.STREAM_WITH_BATCH,
    PUSH_NO_BATCH: DataSourceType.PUSH_NO_BATCH,
    'Push Source': DataSourceType.PUSH_WITH_BATCH,
    'Stream Feature View': DataSourceType.STREAM_WITH_BATCH,
    'Stream Feature View with Push Source': DataSourceType.PUSH_WITH_BATCH,
    'Batch Feature View': DataSourceType.BATCH,
  };

  const dsType = dsTypeShortName[type];

  return <DataSourceCommonTypeBadge type={dsType} />;
};

export default PlanApplyDataSourceBadge;
