import _ from 'lodash';
import MaterializationAttemptStatus from './MaterializationAttemptStatus';

export default class MaterializationStatus {
  #proto = null;

  constructor(proto) {
    this.#proto = proto;
  }

  get _proto() {
    return this.#proto;
  }

  static _wrapMaterializationAttemptStatus(proto) {
    return new MaterializationAttemptStatus(proto);
  }

  get materializationAttemptStatuses() {
    if (_.isEmpty(this._proto.materialization_attempts)) {
      return [];
    }
    return this._proto.materialization_attempts.map((attempt) =>
      MaterializationStatus._wrapMaterializationAttemptStatus(attempt)
    );
  }
}
