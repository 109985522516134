import _ from 'lodash';
import IdUtils from '../utils/id-utils';
import WorkspaceUtils from '../utils/workspace-utils';

export default class SavedFeatureDataFrame {
  private proto: any = null;

  constructor(proto: any) {
    this.proto = proto;
  }

  get _proto() {
    return this.proto;
  }

  get id() {
    return IdUtils.toStringId(this._proto.saved_feature_dataframe_id);
  }

  get name() {
    return this._proto.info.name;
  }

  get createdAt() {
    return new Date(this._proto.info.created_at);
  }

  get featureViewId() {
    return IdUtils.toStringId(this._proto.feature_package_id);
  }

  get featureServiceId() {
    return IdUtils.toStringId(this._proto.feature_service_id);
  }

  get featureViewName() {
    return this._proto.feature_package_name;
  }

  get featureServiceName() {
    return this._proto.feature_service_name;
  }

  get stateUpdateEntryCommitId() {
    return this._proto.state_update_entry_commit_id;
  }

  get type() {
    if (this._proto.type === 'LOGGED') {
      return 'Logged Requests';
    } else {
      return 'Saved Dataset';
    }
  }

  get workspace() {
    return this._proto.info.workspace || WorkspaceUtils.getDefaultWorkspace();
  }

  get schema() {
    const fields = _.get(this._proto, 'schema.fields');

    if (fields && Array.isArray(fields)) {
      return fields.map((x: any) => {
        let rawSparkType = JSON.parse(x.structfield_json).type;
        // Simple types have string rawSparkType, which can be directly returned
        // But we need to handle complex types, such as array of strings properly
        // by converting rawSparkType to string
        if (typeof rawSparkType === 'object') {
          rawSparkType = rawSparkType.elementType + '_' + rawSparkType.type;
        }
        return {
          name: x.name,
          type: rawSparkType,
        };
      });
    }

    return null;
  }
}
