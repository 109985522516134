import React, { FC } from 'react';
import { RequestCallbackType } from '../../../redesign/acl/acl-types';

import {
  ContentVertical,
  HorizontalContent,
  TitleHorizontal,
  TitleVertical,
  TitledItemHorizontal,
  TitledItemVertical,
  TruncateHorizontal,
} from '../StyledComponents';

type ItemOrientation = 'vertical' | 'horizontal';

export interface TitledItemEditableProps {
  fieldTitle: string;
  fieldName: string;
  fieldValue: string;
  isEditable?: boolean;
  orientation?: ItemOrientation;
  onFieldSaveConfirm: (filedName: string, newValue: string, callback: RequestCallbackType) => void;
  renderField?: (
    fieldTitle: string,
    fieldName: string,
    fieldValue: string,
    onFieldSaveConfirm: (filedName: string, newValue: string, callback: RequestCallbackType) => void
  ) => void;
}

const HorizontalTitledItem: FC<TitledItemEditableProps> = ({
  fieldTitle,
  fieldName,
  fieldValue,
  isEditable,
  onFieldSaveConfirm,
  renderField,
}) => {
  return (
    <TitledItemHorizontal>
      {!isEditable && (
        <TitleHorizontal>
          <TruncateHorizontal>{fieldTitle}</TruncateHorizontal>
        </TitleHorizontal>
      )}
      {isEditable ? (
        <>{renderField?.call(this, fieldTitle, fieldName, fieldValue, onFieldSaveConfirm)}</>
      ) : (
        <>
          <HorizontalContent>{fieldValue}</HorizontalContent>
        </>
      )}
    </TitledItemHorizontal>
  );
};

const VerticalTitledItem: FC<TitledItemEditableProps> = ({
  fieldTitle,
  fieldName,
  fieldValue,
  isEditable,
  onFieldSaveConfirm,
  renderField,
}) => {
  return (
    <TitledItemVertical>
      {!isEditable && <TitleVertical>{fieldTitle}</TitleVertical>}
      {isEditable ? (
        <>
          <>{renderField?.call(this, fieldTitle, fieldName, fieldValue, onFieldSaveConfirm)}</>
        </>
      ) : (
        <>
          {renderField ? (
            renderField?.call(this, fieldTitle, fieldName, fieldValue, onFieldSaveConfirm)
          ) : (
            <ContentVertical>{fieldValue}</ContentVertical>
          )}
        </>
      )}
    </TitledItemVertical>
  );
};

const TitledItemEditable: FC<TitledItemEditableProps> = ({
  fieldTitle,
  fieldName,
  fieldValue,
  orientation = 'horizontal',
  isEditable = false,
  onFieldSaveConfirm,
  renderField,
}) => {
  if (orientation === 'horizontal') {
    return (
      <>
        <HorizontalTitledItem
          fieldTitle={fieldTitle}
          fieldName={fieldName}
          fieldValue={fieldValue}
          data-testid="titled-item"
          isEditable={isEditable}
          onFieldSaveConfirm={onFieldSaveConfirm}
          renderField={renderField}
        />
      </>
    );
  }
  return (
    <VerticalTitledItem
      fieldTitle={fieldTitle}
      fieldName={fieldName}
      fieldValue={fieldValue}
      data-testid="titled-item"
      isEditable={isEditable}
      onFieldSaveConfirm={onFieldSaveConfirm}
      renderField={renderField}
    />
  );
};

export default TitledItemEditable;
