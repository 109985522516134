import styled from '@emotion/styled';
import { FC } from 'react';
import { DateFormattingForTimeExtent } from '../utils';
import { GridRow } from './TableRow';

interface TectonServingStatusAxisLabelProps {
  date: Date;
  range: Date[];
}

const DatePill = styled.div`
  display: flex;
  height: 20px;
  padding: 0px ${({ theme }) => theme.padding.xs};
  margin: 16px 0px 8px 0px;
  align-items: center;
  gap: ${({ theme }) => theme.padding.s};
  font-size: ${({ theme }) => theme.font.fontSizes.xs};
  border: ${({ theme }) => theme.border.thin};
  border-radius: ${({ theme }) => theme.border.radius.large};
  box-shadow: ${({ theme }) => theme.shadow.s};
  cursor: default;
`;

export const TectonServingStatusAxisLabel: FC<TectonServingStatusAxisLabelProps> = ({ date, range }) => {
  const formatter = DateFormattingForTimeExtent((range[1].getTime() - range[0].getTime()) / 1000);
  return <DatePill>{formatter(date)}</DatePill>;
};

interface ServingStatusAxisRowProps {
  startDate: Date;
}

const ServingStatusAxisRow: FC<ServingStatusAxisRowProps> = ({ startDate }) => {
  const currentDate = new Date();

  return (
    <GridRow>
      <div></div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <TectonServingStatusAxisLabel date={startDate} range={[startDate, currentDate]} />
        </div>
        <div>
          <TectonServingStatusAxisLabel date={currentDate} range={[startDate, currentDate]} />
        </div>
      </div>
      <div></div>
    </GridRow>
  );
};

export default ServingStatusAxisRow;
