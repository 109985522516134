import mswGetDataPlatformSetupStatus from './endpoints/get-data-platform-setup-status';
import mswGetHiveMetadata from './endpoints/get-hive-metadata';
import mswGetUserDeploymentSettings from './endpoints/get-user-deployment-settings';
// import mswGetOnboardingStatus from './endpoints/get-onboarding-status';
import mswUpdateUserDeploymentSettings from './endpoints/update-user-deployment-settings';
import mswGetGlobalsForWebUI from './endpoints/get-globals-for-web-ui';
/*
To use Mock Service Workers in development, run:
$ yarn dev:mocks
*/

export const handlers = [
  mswGetGlobalsForWebUI,
  mswGetHiveMetadata,
  mswGetUserDeploymentSettings,
  mswGetDataPlatformSetupStatus,
  // mswGetOnboardingStatus,
  mswUpdateUserDeploymentSettings,
];
