import React, { useState } from 'react';
import { Modal, Switch, FlexItem, FlexGroup } from '@tecton/ComponentRedesign';
import { FeatureFlags, useUserSettings, featureFlagMap, HiddenModalFlags } from '../context/UserSettingsContext';
import { GlobalHotKeys } from 'react-hotkeys';

const saveFlags = (changedFlagsIds: Partial<Record<FeatureFlags, boolean>>) => {
  Object.entries(changedFlagsIds).forEach(([flag, bool]) => {
    if (bool) {
      window.localStorage.setItem(featureFlagMap[flag as FeatureFlags].id, 'true');
    } else {
      window.localStorage.setItem(featureFlagMap[flag as FeatureFlags].id, 'false');
    }
  });

  window.location.reload();
};

const FeatureFlagHotKey = ({ openModal }: { openModal: () => void }) => {
  const keyMap = {
    FEATURE_FLAG_MODAL: 'command+shift+0',
  };

  const handlers = {
    FEATURE_FLAG_MODAL: () => {
      openModal();
    },
  };

  return <GlobalHotKeys keyMap={keyMap} handlers={handlers} />;
};

const FeatureFlagModal = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [changes, setChanges] = useState<Partial<Record<FeatureFlags, boolean>>>({});
  const { featureEnabled } = useUserSettings();
  const closeModal = () => {
    setIsOpen(false);
  };

  const cancel = () => {
    setChanges({});
    closeModal();
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const visibleFlags = Object.values(FeatureFlags).filter((flag) => !HiddenModalFlags.has(flag));

  return (
    <>
      <FeatureFlagHotKey openModal={openModal} />
      {isOpen && (
        <Modal
          title="Feature Flags"
          onCancel={cancel}
          onConfirm={() => {
            saveFlags(changes);
          }}
          confirmLabel="Save Flags and Reload"
          cancelLabel="cancel"
          body={
            <>
              <p>These are flags for UI features that are being tested in Tecton Web UI. Proceed with caution.</p>
              <FlexGroup direction="column" gutterSize="m">
                {visibleFlags.map((flag: FeatureFlags) => {
                  const isChecked = Object.hasOwn(changes, flag) ? !!changes[flag] : featureEnabled(flag);
                  return (
                    <FlexItem grow={false}>
                      <Switch
                        label={flag}
                        checked={isChecked}
                        setChecked={() => {
                          setChanges({
                            ...changes,
                            [flag]: !isChecked,
                          });
                        }}
                      />
                    </FlexItem>
                  );
                })}
              </FlexGroup>
            </>
          }
        />
      )}
    </>
  );
};

export default FeatureFlagModal;
