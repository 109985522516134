import { useState, useContext } from 'react';
import { matchPath, Navigate, Outlet, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import { TectonBreadcrumbTitles, TectonTabs } from '@shared';
import { dayLevelDataFormat } from '../shared/utils/dateFormatting';
import ACLProfileDescriptionList, { editableItem, ListTitleWithEditTrigger } from './ACLProfileDescriptionList';
import ProfileLayout from './ProfileLayout';
import ACLAdminActionsList from './ACLAdminActionsList';
import ButtonRow from '../shared/ButtonRow';
import DeleteGroupConfirmationModal from './modals/DeleteGroupConfirmationModal';
import NoMatch from '../shared/NoMatch';

import { ButtonEmpty, ErrorBoundary, GraphQLErrorDisplay, IconTip, Panel, Spacer } from '@tecton';
import { ACLGroupModal } from './aclUtils';
import FieldForm from './FieldForm';
import { FeatureFlags, useUserSettings } from '../context/UserSettingsContext';
import { ACLGroupProfileContextGQL } from './ACLGroupProfileContextGQL';
import AccountTypeChangeModalGQL from './modals/AccountTypeChangeModalGQL';
import { AccountType } from '../../api/gql/graphql';
import ACLGroupWorkspacesGQL from './ACLGroupWorkspacesGQL';
import ACLGroupMembersGQL from './ACLGroupMembersGQL';
import ACLGroupServiceAccountsGQL from './ACLGroupServiceAccountsGQL';
import ACLGroupIdentityProviderAttributesGQL from './ACLGroupIdentityProviderAttributesGQL';

const ACLGroupProfileFrame = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { group } = useParams();
  const groupProfileContextGQL = useContext(ACLGroupProfileContextGQL);
  const { featureEnabled, isAdmin } = useUserSettings();

  const [initialName, setInitialName] = useState<string | undefined>(groupProfileContextGQL.group?.name ?? '');

  const [initialDescription, setInitialDescription] = useState<string | undefined>(
    groupProfileContextGQL.group?.description ?? ''
  );
  const [error, setError] = useState<string>('');
  const [isError, setIsError] = useState<boolean>(false);

  const onError = (error: any) => {
    setError(error?.response?.data?.message ?? '');
    setIsError(true);
  };

  const onSuccess = () => {
    setError('');
    setIsError(false);
  };

  if (groupProfileContextGQL?.error) {
    return (
      <>
        <GraphQLErrorDisplay
          errorTitle={<h2>Unable to load Group</h2>}
          graphQLErrors={groupProfileContextGQL?.error?.response?.errors ?? []}
        />
      </>
    );
  }

  if (!group) {
    throw new Error('No group ID in URL');
  }

  const descriptionField = editableItem({
    title: 'Description',
    viewMode: <p style={{ overflowWrap: 'anywhere' }}>{initialDescription}</p>,
    editMode: (
      <FieldForm
        description={initialDescription ?? ''}
        setDescription={(description) => {
          groupProfileContextGQL?.updateDescription?.call(groupProfileContextGQL, description);
          setInitialDescription(description);
        }}
        cancel={() => {
          groupProfileContextGQL?.updateIsDescriptionEditing?.call(groupProfileContextGQL, false);
          setInitialDescription(groupProfileContextGQL?.group?.description ?? '');
          setError('');
          setIsError(false);
        }}
        isLoading={groupProfileContextGQL?.isLoading ?? false}
        isInvalid={isError}
        isDisabled={false}
        errorMessages={[error]}
        saveDescription={(description: string) => {
          groupProfileContextGQL?.saveDescription?.call(groupProfileContextGQL, description, onSuccess, onError);
          setInitialDescription(description);
        }}
      />
    ),
    editState: [
      groupProfileContextGQL?.isDescriptionEditing,
      (isEditing: boolean) => {
        groupProfileContextGQL?.updateIsDescriptionEditing?.call(groupProfileContextGQL, isEditing);
      },
    ],
    isAdmin: isAdmin ?? false,
  });

  const nameField = editableItem({
    title: 'Name',
    viewMode: <p style={{ overflowWrap: 'anywhere' }}>{`${initialName}`}</p>,
    editMode: (
      <FieldForm
        description={initialName ?? ''}
        setDescription={setInitialName}
        cancel={() => {
          groupProfileContextGQL?.updateIsNameEditing?.call(groupProfileContextGQL, false);
          setInitialName(groupProfileContextGQL.group?.name);
        }}
        isLoading={groupProfileContextGQL?.isLoading ?? false}
        isInvalid={groupProfileContextGQL?.isNameInvalid ?? false}
        isDisabled={false}
        errorMessages={groupProfileContextGQL?.fieldFormErrors ?? []}
        saveDescription={(newName: string) => {
          groupProfileContextGQL?.saveName?.call(groupProfileContextGQL, newName);
          setInitialName(newName);
        }}
      />
    ),
    editState: [
      groupProfileContextGQL?.isNameEditing,
      (isEditing: boolean) => {
        groupProfileContextGQL?.updateIsNameEditing?.call(groupProfileContextGQL, isEditing);
      },
    ],
    isAdmin: isAdmin ?? false,
  });

  // Modify Account Type
  const [selectedAccountType, setSelectedAccountType] = useState<any>(
    groupProfileContextGQL?.group?.accountType as AccountType
  );

  let changeGroupAccountTypeModal;

  if (groupProfileContextGQL?.modal === ACLGroupModal.EDIT_GROUP_ACCOUNT_TYPE) {
    changeGroupAccountTypeModal = (
      <AccountTypeChangeModalGQL
        userId={groupProfileContextGQL?.group?.name ?? ''}
        currentAccountType={groupProfileContextGQL?.group?.accountType}
        onClose={() => {
          groupProfileContextGQL?.closeModal?.call(groupProfileContextGQL);
          setSelectedAccountType(groupProfileContextGQL?.group?.accountType as AccountType);
        }}
        confirmChange={(accountType) => {
          groupProfileContextGQL?.updateUserRole?.call(groupProfileContextGQL, accountType as AccountType);
        }}
        isLoading={groupProfileContextGQL?.isModalLoading as boolean}
        selectedAccountType={selectedAccountType}
        setSelectedAccountType={(type) => {
          setSelectedAccountType(type);
        }}
        errorMessages={groupProfileContextGQL.errorMessages}
      />
    );
  }

  let deleteGroupConfirmationModal;

  if (groupProfileContextGQL.modal === ACLGroupModal.DELETE_GROUP) {
    deleteGroupConfirmationModal = (
      <DeleteGroupConfirmationModal
        groupId={groupProfileContextGQL?.group?.name ?? ''}
        confirmChange={() => {
          groupProfileContextGQL?.deleteGroup?.call(groupProfileContextGQL);
        }}
        onClose={() => {
          groupProfileContextGQL?.closeModal?.call(groupProfileContextGQL);
        }}
        isLoading={groupProfileContextGQL?.isModalLoading ?? false}
        errorMessages={groupProfileContextGQL?.errorMessages}
      />
    );
  }

  return (
    <ProfileLayout
      metadataColumn={
        <>
          <TectonBreadcrumbTitles
            breadcrumbs={[
              {
                text: 'Accounts & Access',
                onClick: () => {
                  navigate('/settings/accounts-and-access/');
                },
              },
              {
                text: 'Group',
                onClick: () => {
                  navigate('/settings/accounts-and-access/groups/');
                },
              },
              {
                text: (
                  <div
                    style={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      display: 'block',
                      width: '100%',
                      overflowWrap: 'anywhere',
                      textAlign: 'left',
                    }}
                    title={groupProfileContextGQL?.group?.name}
                  >
                    {groupProfileContextGQL?.group?.name}
                  </div>
                ),
              },
            ]}
          />
          <span data-testid="group-profile-list">
            <ACLProfileDescriptionList
              listItems={[
                {
                  title: 'Group ID',
                  description: `${groupProfileContextGQL?.group?.id}`,
                },
                nameField,
                descriptionField,
                ...(isAdmin
                  ? [
                      {
                        title: (
                          <ListTitleWithEditTrigger
                            isAdmin={isAdmin ?? false}
                            title={
                              <>
                                Account Type{' '}
                                <IconTip content="This account type will be applied to all users and service accounts in this group. Be careful when assigning the Admin account type." />
                              </>
                            }
                            isEditing={false}
                            toggleEditMode={() => {
                              if (groupProfileContextGQL?.showGroupEditAccountTypeModal) {
                                groupProfileContextGQL?.showGroupEditAccountTypeModal();
                              }

                              setSelectedAccountType(selectedAccountType);
                            }}
                          />
                        ),
                        description: <span data-testid="group-role">{groupProfileContextGQL?.group?.accountType}</span>,
                      },
                    ]
                  : []),
                {
                  title: 'Group Created',
                  description: `${dayLevelDataFormat(
                    new Date(groupProfileContextGQL?.group?.createdAt ?? new Date())
                  )}`,
                },
              ]}
            />
          </span>

          {groupProfileContextGQL?.group?.isMembershipEditable && isAdmin && (
            <ACLGroupIdentityProviderAttributesGQL
              groupName={groupProfileContextGQL?.group?.name as string}
              attributes={(groupProfileContextGQL?.group?.idpMappingNames as string[]) ?? []}
            />
          )}

          <ACLAdminActionsList
            listItems={[
              {
                title: 'Delete Group',
                description: (
                  <>
                    <p>
                      Deleting a group will cause group members to lose access to Workspaces that were granted by this
                      group.
                    </p>
                    <Spacer size="s" />
                    <ButtonRow>
                      <ButtonEmpty
                        onClick={() => {
                          groupProfileContextGQL?.showDeleteGroupModal?.call(groupProfileContextGQL);
                        }}
                        iconType="trash"
                        color="danger"
                        size="s"
                      >
                        Delete Group
                      </ButtonEmpty>
                    </ButtonRow>
                  </>
                ),
              },
            ]}
          />
          {changeGroupAccountTypeModal}
          {deleteGroupConfirmationModal}
        </>
      }
      contentColumn={
        <>
          <TectonTabs
            tabs={[
              {
                label: 'Workspaces',
                count: groupProfileContextGQL?.workspaceMembersCount,
                isSelected: !!matchPath(
                  {
                    path: `settings/accounts-and-access/groups/${group}/workspaces`,
                    end: true,
                  },
                  location.pathname
                ),
                onClick: () => {
                  navigate('../workspaces/');
                },
              },
              {
                label: 'Users',
                count: groupProfileContextGQL?.userMembersCount,
                isSelected: !!matchPath(
                  {
                    path: `settings/accounts-and-access/groups/${group}/users`,
                    end: true,
                  },
                  location.pathname
                ),
                onClick: () => {
                  navigate('../users/');
                },
              },
              {
                label: 'Service Accounts',
                count: groupProfileContextGQL?.serviceAccountMembersCount,
                isSelected: !!matchPath(
                  {
                    path: `settings/accounts-and-access/groups/${group}/service-accounts`,
                    end: true,
                  },
                  location.pathname
                ),
                onClick: () => {
                  navigate('../service-accounts/');
                },
              },
            ]}
          />
          <Spacer size="l" />
          <ErrorBoundary>
            <Outlet />
          </ErrorBoundary>
        </>
      }
    />
  );
};

const ACLGroupProfileGQL = () => {
  return (
    <Panel hasBorder={false} hasShadow={false} paddingSize="xs" className="eui-yScroll">
      <Routes>
        <Route path="/" element={<ACLGroupProfileFrame />}>
          <Route index element={<Navigate to="./workspaces" replace />} />
          <Route path="/workspaces" element={<ACLGroupWorkspacesGQL />} />
          <Route path="/users" element={<ACLGroupMembersGQL />} />
          <Route path="/service-accounts" element={<ACLGroupServiceAccountsGQL />} />
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </Panel>
  );
};

export default ACLGroupProfileGQL;
