import { Link, Popover } from '@tecton';
import { css } from '@emotion/css';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AclRoleWithInheritance } from '../../api/gql/graphql';

export const RoleInheritanceList = ({ children }: { children: React.ReactNode }) => {
  return (
    <ul
      className={css`
        list-style: none;
        margin: 0;
        padding: 0 8px;

        li {
          padding: 8px 0;

          &:not(:last-of-type) {
            border-bottom: 1px solid #ddd;
          }
        }
      `}
    >
      {children}
    </ul>
  );
};

const AllRolesPopoverGQL = ({
  roleWithInheritance,
  isGroupTable = false /* Are we being displayed via groups table?*/,
}: {
  roleWithInheritance: AclRoleWithInheritance;
  isGroupTable?: boolean;
}) => {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const closePopover = () => {
    setIsOpen(false);
  };

  return (
    <Popover
      button={
        <Link
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          style={{ textTransform: 'capitalize' }}
        >
          {roleWithInheritance.effectiveWorkspaceRole}
        </Link>
      }
      panelPaddingSize="s"
      isOpen={isOpen}
      closePopover={closePopover}
    >
      <RoleInheritanceList>
        {roleWithInheritance.roleSources.length === 0 && <li>No role assigned{/* , nor from any groups */}.</li>}
        {roleWithInheritance.roleSources.map((roleSource, index) => {
          if (roleSource.type === 'direct') {
            return (
              <li>
                <strong style={{ textTransform: 'capitalize', fontWeight: 700 }}>{String(roleSource.role)}</strong> role
                assigned directly.
              </li>
            );
          }

          if (roleSource.type === 'via all workspaces role') {
            return (
              <li>
                <strong style={{ textTransform: 'capitalize', fontWeight: 700 }}>{String(roleSource.role)}</strong> role
                via All Workspaces role (
                {roleSource.groupName && !isGroupTable ? (
                  <>
                    <Link
                      onClick={() => {
                        navigate(`/settings/accounts-and-access/groups/${roleSource.groupId}`);
                      }}
                    >
                      {roleSource.groupName}
                    </Link>
                    {` `}group
                  </>
                ) : (
                  'directly'
                )}
                ).
              </li>
            );
          }

          if (roleSource.type === 'via group') {
            return (
              <li>
                <strong style={{ textTransform: 'capitalize', fontWeight: 700 }}>{String(roleSource.role)}</strong> role
                from{' '}
                <>
                  <Link
                    onClick={() => {
                      navigate(`/settings/accounts-and-access/groups/${roleSource.groupId}`);
                    }}
                  >
                    {roleSource.groupName}
                  </Link>
                  {` `}group
                </>
                .
              </li>
            );
          }

          throw new Error('Role source type does not match known types.');
        })}
      </RoleInheritanceList>
    </Popover>
  );
};

export default AllRolesPopoverGQL;
