import { EuiHealth } from '@elastic/eui';
import React, { FC } from 'react';
import { useTectonTheme } from '@tecton/ComponentRedesign';

export type HealthVariants = 'active' | 'healthy' | 'warning' | 'failure' | 'neutral';

export interface TectonHealthProps {
  variant: HealthVariants;
  label?: React.ReactNode;
}

const TectonHealth: FC<TectonHealthProps> = ({ variant, label }) => {
  const { theme } = useTectonTheme();

  const VariantDisplayPairs: Record<HealthVariants, { color: string; label: React.ReactNode }> = {
    active: { color: theme.colors.accent, label: label || 'Active' },
    healthy: { color: theme.colors.success, label: label || 'Healthy' },
    failure: { color: theme.colors.danger, label: label || 'Failed' },
    warning: { color: theme.colors.warning, label: label || 'Danger' },
    neutral: {
      color: theme.colors.mediumShade,
      label: label || 'Undefined',
    },
  };

  return (
    <EuiHealth color={VariantDisplayPairs[variant].color} data-testid="health-indicator">
      {VariantDisplayPairs[variant].label}
    </EuiHealth>
  );
};

export default TectonHealth;
