import React from 'react';

import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import UserAccessContextProvider from './UserAccessContextProvider';
import AccessIndex from './AccessIndex';
import NoMatch from '../shared/NoMatch';

const ConfigurationFrame = () => {
  return <Outlet />;
};

const ConfigurationIndex = () => {
  return (
    <Routes>
      <Route path="/" element={<ConfigurationFrame />}>
        <Route index element={<Navigate to="./access" />} />
        <Route
          path="access/*"
          element={
            <UserAccessContextProvider>
              <AccessIndex />
            </UserAccessContextProvider>
          }
        />
        <Route path="*" element={<NoMatch />} />
      </Route>
    </Routes>
  );
};

export default ConfigurationIndex;
