import React from 'react';
import { LoadingContent, Panel, useTectonTheme } from '../@tecton';

const ACLLoadingContent = ({ showPanel = false }: { showPanel?: boolean }) => {
  const { theme } = useTectonTheme();
  return showPanel ? (
    <Panel hasBorder={false} hasShadow={false} paddingSize="xs" className="eui-yScroll">
      <div style={{ padding: theme.size.xl }}>
        <LoadingContent />
      </div>
    </Panel>
  ) : (
    <div style={{ padding: theme.size.xl }}>
      <LoadingContent />
    </div>
  );
};

export default ACLLoadingContent;
