export default () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle
        cx="9.21552"
        cy="9.21503"
        r="5.88495"
        stroke="#525866"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.1392 17.1993C16.4321 17.4922 16.9069 17.4922 17.1998 17.1993C17.4927 16.9065 17.4927 16.4316 17.1998 16.1387L16.1392 17.1993ZM17.1998 16.1387L13.9068 12.8456L12.8461 13.9063L16.1392 17.1993L17.1998 16.1387Z"
        fill="#525866"
      />
    </svg>
  );
};
