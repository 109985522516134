import React, { FC } from 'react';
import { Icons, IconTypes, Badge } from '@tecton/ComponentRedesign';

export interface FCOTag {
  key: string;
  value: string;
}

interface TectonTagProps {
  tag: FCOTag;
}

const FCOTagBadge: FC<TectonTagProps> = ({ tag }) => {
  return <Badge size="m" icon={Icons[IconTypes.TAG]} label={tag.key} value={tag.value} variant="default" />;
};

export default FCOTagBadge;
