import { Link, Popover } from '@tecton';
import React, { useState } from 'react';

import { AccountTypeWithInheritance } from '../../api/gql/graphql';
import AccountTypeTableDisplayUserDetail from './AccountTypeTableDisplayUserDetail';

const ACLAccountTypePopoverUserDetail = ({ accountType }: { accountType: AccountTypeWithInheritance }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const closePopover = () => {
    setIsOpen(false);
  };

  return (
    <Popover
      button={
        <Link
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          style={{ textTransform: 'capitalize' }}
        >
          <span>{accountType?.effectiveAccountType}</span>
        </Link>
      }
      panelPaddingSize="s"
      isOpen={isOpen}
      closePopover={closePopover}
    >
      <AccountTypeTableDisplayUserDetail accountType={accountType} />
    </Popover>
  );
};

export default ACLAccountTypePopoverUserDetail;
