export const StormCloud = ({ width = 351, height = 351 }) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMaxYMid meet"
    >
      {/* Allow dynamic sizing */}
      <g transform={`scale(${width / 351})`}>
        <path d="M34.793 155.612V158.203" stroke="#D2D8DF" stroke-width="2.921" stroke-linecap="round" />
        <path d="M28.9766 157.016L31.0932 159.195" stroke="#D2D8DF" stroke-width="2.921" stroke-linecap="round" />
        <path d="M26.5 162.816H29.493" stroke="#D2D8DF" stroke-width="2.921" stroke-linecap="round" />
        <path d="M28.7324 168.72L30.8491 166.541" stroke="#D2D8DF" stroke-width="2.921" stroke-linecap="round" />
        <path d="M34.793 169.427V166.837" stroke="#D2D8DF" stroke-width="2.921" stroke-linecap="round" />
        <path d="M39.1097 167.701L37.3828 165.974" stroke="#D2D8DF" stroke-width="2.921" stroke-linecap="round" />
        <path d="M42.565 163.383H39.9746" stroke="#D2D8DF" stroke-width="2.921" stroke-linecap="round" />
        <path d="M40.8382 157.339L39.1113 159.066" stroke="#D2D8DF" stroke-width="2.921" stroke-linecap="round" />
        <path d="M173.809 42.5V44.2269" stroke="#D2D8DF" stroke-width="2.921" stroke-linecap="round" />
        <path d="M168.629 47.6807H170.356" stroke="#D2D8DF" stroke-width="2.921" stroke-linecap="round" />
        <path d="M173.809 52.8617V51.1348" stroke="#D2D8DF" stroke-width="2.921" stroke-linecap="round" />
        <path d="M178.127 47.6807H176.4" stroke="#D2D8DF" stroke-width="2.921" stroke-linecap="round" />
        <path d="M73.6484 115.029V117.62" stroke="#D2D8DF" stroke-width="2.921" stroke-linecap="round" />
        <path d="M65.8789 122.801H68.4693" stroke="#D2D8DF" stroke-width="2.921" stroke-linecap="round" />
        <path d="M73.6484 129.709V127.118" stroke="#D2D8DF" stroke-width="2.921" stroke-linecap="round" />
        <path d="M80.5571 122.801H77.9668" stroke="#D2D8DF" stroke-width="2.921" stroke-linecap="round" />
        <path
          d="M259.51 209.181C261.574 209.181 263.247 210.855 263.247 212.919C263.247 214.983 261.574 216.657 259.51 216.657C257.447 216.657 255.773 214.983 255.773 212.919"
          stroke="#D2D8DF"
          stroke-width="2.921"
          stroke-linecap="round"
        />
        <path
          d="M276.568 258.319C277.03 261.542 274.793 264.529 271.571 264.991C268.348 265.452 265.362 263.214 264.9 259.992C264.438 256.769 266.676 253.782 269.897 253.32"
          stroke="#D2D8DF"
          stroke-width="2.921"
          stroke-linecap="round"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M276.613 175.268C278.832 174.576 282.233 171.862 283.021 168.942C283.735 171.523 286.728 174.576 289.428 174.807C286.388 175.883 283.482 179.081 283.021 181.593C282.707 179.032 278.687 175.641 276.613 175.268Z"
          fill="#EBECEE"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M48.8438 208.029C49.9536 207.683 51.6535 206.326 52.0471 204.866C52.4041 206.156 53.9002 207.683 55.2505 207.798C53.7309 208.336 52.278 209.935 52.0471 211.191C51.8902 209.911 49.8803 208.215 48.8438 208.029Z"
          fill="#EBECEE"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M69.4399 87.916C69.4399 89.5174 68.1424 90.8145 66.5422 90.8145C64.9413 90.8145 63.6445 89.5174 63.6445 87.916C63.6445 86.3154 64.9413 85.0176 66.5422 85.0176C68.1424 85.0176 69.4399 86.3154 69.4399 87.916Z"
          fill="#D1D8DF"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M240.558 269.897C240.558 272.573 238.389 274.742 235.715 274.742C233.039 274.742 230.871 272.573 230.871 269.897C230.871 267.222 233.039 265.053 235.715 265.053C238.389 265.053 240.558 267.222 240.558 269.897Z"
          fill="#EBECEE"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M330.019 82.5733C330.019 84.696 328.299 86.4161 326.178 86.4161C324.056 86.4161 322.336 84.696 322.336 82.5733C322.336 80.4506 324.056 78.7305 326.178 78.7305C328.299 78.7305 330.019 80.4506 330.019 82.5733Z"
          fill="#EBECEE"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M123.593 262.048C123.593 263.386 122.509 264.47 121.171 264.47C119.834 264.47 118.75 263.386 118.75 262.048C118.75 260.709 119.834 259.625 121.171 259.625C122.509 259.625 123.593 260.709 123.593 262.048Z"
          stroke="#D2D8DF"
          stroke-width="2.921"
          stroke-linecap="round"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M122.155 69.2949C122.155 70.8963 120.857 72.1934 119.257 72.1934C117.657 72.1934 116.359 70.8963 116.359 69.2949C116.359 67.6943 117.657 66.3965 119.257 66.3965C120.857 66.3965 122.155 67.6943 122.155 69.2949Z"
          stroke="#D2D8DF"
          stroke-width="2.921"
          stroke-linecap="round"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M196.067 288.874C194.215 289.579 192.143 288.648 191.439 286.797C190.734 284.945 191.664 282.871 193.516 282.167C195.367 281.463 197.439 282.392 198.144 284.244C198.849 286.097 197.919 288.169 196.067 288.874Z"
          stroke="#D2D8DF"
          stroke-width="2.921"
          stroke-linecap="round"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M145.312 228.966H161.873L153.198 256.568L191.842 217.531H168.576L181.589 160.354L145.312 228.966Z"
          fill="#E0E2EE"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M244.38 143.005C241.502 140.959 238.284 139.362 234.825 138.318C232.237 137.537 230.188 135.433 229.634 132.787C224.926 110.317 205.004 93.4434 181.135 93.4434C166.801 93.4434 153.891 99.5309 144.842 109.261C143.112 111.122 140.49 111.888 137.996 111.408C136.044 111.032 134.029 110.835 131.965 110.835C117.036 110.835 104.509 121.105 101.05 134.964C100.479 137.255 98.8711 139.182 96.7145 140.142C85.1583 145.287 77.1035 156.872 77.1035 170.335C77.1035 188.528 91.8081 203.282 109.984 203.367C110.92 203.493 111.871 203.567 112.842 203.567H220.316C220.687 203.567 221.057 203.603 221.423 203.665C223.243 203.976 225.107 204.145 227.017 204.145C245.266 204.145 260.053 189.354 260.053 171.109C260.053 159.233 253.785 148.826 244.38 143.005Z"
          fill="url(#paint0_linear_461_236732)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M152.543 124.292C148.719 122.741 144.465 121.87 139.98 121.87C123.373 121.87 109.914 133.725 109.914 148.345C109.914 149.824 110.055 151.272 110.321 152.683C123.233 134.442 132.131 127.198 152.543 124.292Z"
          fill="#E0E2EE"
        />
        <defs>
          <linearGradient
            id="paint0_linear_461_236732"
            x1="83.4747"
            y1="202.834"
            x2="80.3668"
            y2="101.509"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#EDF0F6" />
            <stop offset="1" stop-color="#FCFDFF" />
          </linearGradient>
        </defs>
      </g>
    </svg>
  );
};

export const EmptyWallet = () => {
  return (
    <svg width="1050" height="1050" viewBox="0 0 1050 1050" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Type=Wallet is Empty">
        <g id="Group 26817">
          <path
            id="Fill 1"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M707.154 867.969C707.154 874.382 624.627 879.582 522.822 879.582C421.017 879.582 338.484 874.382 338.484 867.969C338.484 861.559 421.017 856.359 522.822 856.359C624.627 856.359 707.154 861.559 707.154 867.969Z"
            fill="#DCDCE2"
          />
          <path
            id="Stroke 3"
            d="M499.538 121.922C398.98 130.435 362.022 215.55 392.299 265.165C413.663 300.171 449.865 347.48 517.349 336.132C584.829 324.781 590.515 262.329 554.976 253.835C519.437 245.342 392.827 284.961 407.136 376.488C417.465 442.527 506.993 461.563 506.993 461.563"
            stroke="#DCDCE2"
            stroke-width="2"
            stroke-dasharray="7"
          />
          <path
            id="Fill 5"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M557.658 69.6699C557.658 69.6699 572.067 32.0295 573.586 17.387C574.385 9.73585 561.706 -11.5667 546.867 8.13816C532.028 27.843 538.798 52.0036 557.658 69.6699Z"
            fill="#DCDCE2"
          />
          <path
            id="Fill 7"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M506.528 28.6769C489.648 22.9684 482.006 27.4192 478.751 36.303C475.492 45.1898 480.04 62.1052 505.5 65.9612C530.963 69.8171 556.205 69.6692 556.205 69.6692C556.205 69.6692 523.408 34.3854 506.528 28.6769Z"
            fill="#DCDCE2"
          />
          <path
            id="Fill 9"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M554.799 77.1281C555.182 75.5547 555.689 74.0467 556.254 72.5728C553.137 73.0651 549.966 73.7565 546.775 74.6925C543.313 75.7055 540.082 77.0342 537.043 78.5564C535.724 81.0488 534.655 83.712 533.961 86.5715C529.684 104.175 539.782 121.918 556.749 127.728C558.587 127.364 560.433 126.943 562.282 126.402C567.449 124.889 572.215 122.886 576.524 120.564C559.955 113.294 550.418 95.1528 554.799 77.1281Z"
            fill="#DCDCE2"
          />
          <path
            id="Fill 11"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M530.573 86.3085C530.978 84.5694 531.528 82.9065 532.138 81.2816C517.459 90.6854 509.239 105.28 510.594 113.791C501.215 117.822 495.24 121.219 495.24 121.219C495.24 121.219 502.318 120.68 512.63 118.698C518.154 126.769 533.465 132.174 550.396 130.236C535.059 122.305 526.402 104.255 530.573 86.3085Z"
            fill="#DCDCE2"
          />
          <path
            id="Fill 13"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M578.732 93.0979C575.104 92.2238 572.855 88.5652 573.728 84.9009C574.599 81.2619 578.266 79.0067 581.891 79.8808C585.536 80.7604 587.763 84.4331 586.895 88.0749C586.019 91.7364 582.38 93.9747 578.732 93.0979ZM561.78 72.943C561.057 74.6225 560.443 76.3943 560.004 78.2294C556.156 94.3181 565.045 110.502 580.119 116.115C592.697 108.255 599.76 97.1261 596.895 87.4629C593.709 76.6237 579.06 71.0262 561.78 72.943Z"
            fill="#DCDCE2"
          />
          <path
            id="Fill 15"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M349.383 751.854H710.876C720.041 751.854 727.474 744.403 727.474 735.216V492.754C727.474 483.543 720.024 476.078 710.839 476.078H340.611C336.228 476.078 332.678 479.637 332.678 484.031V735.108C332.678 744.357 340.154 751.854 349.383 751.854Z"
            fill="#F1F2F7"
          />
          <path
            id="Fill 17"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M349.161 748.951H393.027C393.363 748.951 393.639 748.677 393.639 748.339V441.856C393.639 441.517 393.363 441.243 393.027 441.243H349.161C340.055 441.243 332.678 448.598 332.678 457.677V732.518C332.678 741.593 340.055 748.951 349.161 748.951Z"
            fill="#BEC0D6"
          />
          <path
            id="Fill 19"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M636.581 647.35H750.089C750.424 647.35 750.699 647.072 750.699 646.729V578.3C750.699 577.958 750.424 577.68 750.089 577.68H636.581C627.463 577.68 620.068 585.164 620.068 594.393V630.636C620.068 639.868 627.463 647.35 636.581 647.35Z"
            fill="url(#paint0_linear_461_233858)"
          />
          <path
            id="Fill 21"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M661.561 441.243H349.558V441.284C340.204 441.509 332.678 449.182 332.678 458.662C332.678 468.141 340.204 475.814 349.558 476.04V476.078H680.411C680.751 476.078 681.027 475.802 681.027 475.46V460.854C681.027 450.022 672.31 441.243 661.561 441.243Z"
            fill="#D8DBEA"
          />
          <path
            id="Fill 23"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M675.224 612.515C675.224 620.531 668.727 627.029 660.71 627.029C652.693 627.029 646.195 620.531 646.195 612.515C646.195 604.498 652.693 598 660.71 598C668.727 598 675.224 604.498 675.224 612.515Z"
            fill="#FEFEFF"
          />
          <path
            id="Stroke 25"
            d="M80.127 539.942V548.651"
            stroke="#D2D8DF"
            stroke-width="2.921"
            stroke-linecap="round"
          />
          <path
            id="Stroke 27"
            d="M62.707 548.65L68.5129 554.456"
            stroke="#D2D8DF"
            stroke-width="2.921"
            stroke-linecap="round"
          />
          <path id="Stroke 29" d="M54 566.068H62.7087" stroke="#D2D8DF" stroke-width="2.921" stroke-linecap="round" />
          <path
            id="Stroke 31"
            d="M62.707 583.485L68.5129 577.68"
            stroke="#D2D8DF"
            stroke-width="2.921"
            stroke-linecap="round"
          />
          <path
            id="Stroke 33"
            d="M80.127 592.194V583.485"
            stroke="#D2D8DF"
            stroke-width="2.921"
            stroke-linecap="round"
          />
          <path
            id="Stroke 35"
            d="M97.5461 583.485L91.7402 577.68"
            stroke="#D2D8DF"
            stroke-width="2.921"
            stroke-linecap="round"
          />
          <path
            id="Stroke 37"
            d="M106.25 566.068H97.541"
            stroke="#D2D8DF"
            stroke-width="2.921"
            stroke-linecap="round"
          />
          <path
            id="Stroke 39"
            d="M97.5461 548.65L91.7402 554.456"
            stroke="#D2D8DF"
            stroke-width="2.921"
            stroke-linecap="round"
          />
          <path
            id="Stroke 41"
            d="M527.174 182.883V188.689"
            stroke="#D2D8DF"
            stroke-width="2.921"
            stroke-linecap="round"
          />
          <path
            id="Stroke 43"
            d="M512.66 197.398H518.466"
            stroke="#D2D8DF"
            stroke-width="2.921"
            stroke-linecap="round"
          />
          <path
            id="Stroke 45"
            d="M527.174 214.816V209.01"
            stroke="#D2D8DF"
            stroke-width="2.921"
            stroke-linecap="round"
          />
          <path
            id="Stroke 47"
            d="M544.593 200.301H538.787"
            stroke="#D2D8DF"
            stroke-width="2.921"
            stroke-linecap="round"
          />
          <path
            id="Stroke 49"
            d="M207.855 412.214V420.922"
            stroke="#D2D8DF"
            stroke-width="2.921"
            stroke-linecap="round"
          />
          <path
            id="Stroke 51"
            d="M181.728 438.34H190.437"
            stroke="#D2D8DF"
            stroke-width="2.921"
            stroke-linecap="round"
          />
          <path
            id="Stroke 53"
            d="M204.949 461.563V452.854"
            stroke="#D2D8DF"
            stroke-width="2.921"
            stroke-linecap="round"
          />
          <path
            id="Stroke 55"
            d="M231.078 438.34H222.369"
            stroke="#D2D8DF"
            stroke-width="2.921"
            stroke-linecap="round"
          />
          <path
            id="Stroke 57"
            d="M800.048 717.019C806.461 717.019 811.66 722.218 811.66 728.632C811.66 735.044 806.461 740.243 800.048 740.243C793.636 740.243 788.437 735.044 788.437 728.632"
            stroke="#D2D8DF"
            stroke-width="2.921"
            stroke-linecap="round"
          />
          <path
            id="Stroke 59"
            d="M855.009 875.345C856.49 885.711 849.325 895.32 839.009 896.806C828.699 898.292 819.136 891.092 817.658 880.726C816.18 870.357 823.342 860.748 833.658 859.262"
            stroke="#D2D8DF"
            stroke-width="2.921"
            stroke-linecap="round"
          />
          <path
            id="Fill 61"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M855.205 606.707C862.243 604.481 873.029 595.766 875.527 586.388C877.79 594.678 887.28 604.481 895.846 605.226C886.206 608.686 876.989 618.96 875.527 627.029C874.53 618.803 861.784 607.906 855.205 606.707Z"
            fill="#EBECEF"
          />
          <path
            id="Fill 63"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M126.574 712.664C130.094 711.552 135.487 707.193 136.736 702.505C137.867 706.649 142.611 711.552 146.895 711.924C142.074 713.653 137.465 718.789 136.736 722.825C136.236 718.713 129.865 713.263 126.574 712.664Z"
            fill="#EBECEF"
          />
          <path
            id="Fill 65"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M190.439 328.029C190.439 332.841 186.539 336.738 181.73 336.738C176.919 336.738 173.021 332.841 173.021 328.029C173.021 323.218 176.919 319.32 181.73 319.32C186.539 319.32 190.439 323.218 190.439 328.029Z"
            fill="#D1D9E0"
          />
          <path
            id="Fill 69"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1026.48 310.61C1026.48 317.026 1021.28 322.223 1014.87 322.223C1008.45 322.223 1003.25 317.026 1003.25 310.61C1003.25 304.198 1008.45 299 1014.87 299C1021.28 299 1026.48 304.198 1026.48 310.61Z"
            fill="#EBECEF"
          />
          <path
            id="Stroke 73"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M358.808 267.068C358.808 271.88 354.908 275.777 350.099 275.777C345.288 275.777 341.391 271.88 341.391 267.068C341.391 262.256 345.288 258.359 350.099 258.359C354.908 258.359 358.808 262.256 358.808 267.068Z"
            stroke="#D2D8DF"
            stroke-width="2.921"
            stroke-linecap="round"
          />
          <path
            id="Stroke 75"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M223.595 817.86C217.603 820.139 210.898 817.13 208.619 811.138C206.336 805.145 209.348 798.441 215.34 796.161C221.333 793.878 228.037 796.891 230.317 802.883C232.597 808.875 229.587 815.58 223.595 817.86Z"
            stroke="#D2D8DF"
            stroke-width="2.921"
            stroke-linecap="round"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_461_233858"
          x1="685.384"
          y1="542.845"
          x2="627.526"
          y2="651.327"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF8960" />
          <stop offset="1" stop-color="#FF62A5" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const CardIllustration = () => {
  return (
    <svg width="350" height="350" viewBox="0 0 350 350" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="74" cy="130" r="5" fill="#EAEEF9" />
      <circle cx="64" cy="217" r="9" fill="#EAEEF9" />
      <circle cx="260" cy="123" r="6" fill="#EAEEF9" />
      <circle cx="165" cy="175" r="80" fill="#EAEEF9" />
      <g filter="url(#filter0_dd_461_233859)">
        <rect x="82" y="117" width="166" height="104" rx="6" fill="url(#paint0_linear_461_233859)" />
        <rect x="96.0703" y="141.186" width="29.0233" height="21.7674" rx="2" fill="#D5DDEA" />
        <circle cx="221.837" cy="148.442" r="12.093" fill="#989FB0" />
        <circle cx="207.325" cy="148.442" r="12.093" fill="#D5DDEA" />
        <path
          d="M96.0703 205.279C96.0703 203.943 97.1532 202.86 98.4889 202.86H122.675C124.011 202.86 125.094 203.943 125.094 205.279C125.094 206.615 124.011 207.698 122.675 207.698H98.4889C97.1532 207.698 96.0703 206.615 96.0703 205.279Z"
          fill="#D5DDEA"
        />
        <path
          d="M132.349 205.279C132.349 203.943 133.432 202.86 134.768 202.86H158.954C160.29 202.86 161.373 203.943 161.373 205.279C161.373 206.615 160.29 207.698 158.954 207.698H134.768C133.432 207.698 132.349 206.615 132.349 205.279Z"
          fill="#D5DDEA"
        />
        <path
          d="M168.628 205.279C168.628 203.943 169.711 202.86 171.047 202.86H195.233C196.569 202.86 197.652 203.943 197.652 205.279C197.652 206.615 196.569 207.698 195.233 207.698H171.047C169.711 207.698 168.628 206.615 168.628 205.279Z"
          fill="#D5DDEA"
        />
        <path
          d="M204.908 205.279C204.908 203.943 205.99 202.86 207.326 202.86H231.512C232.848 202.86 233.931 203.943 233.931 205.279C233.931 206.615 232.848 207.698 231.512 207.698H207.326C205.99 207.698 204.908 206.615 204.908 205.279Z"
          fill="#D5DDEA"
        />
      </g>
      <path
        d="M243.304 197C245.613 193 251.387 193 253.696 197L272.316 229.25C274.625 233.25 271.738 238.25 267.12 238.25H229.88C225.262 238.25 222.375 233.25 224.684 229.25L243.304 197Z"
        fill="url(#paint1_linear_461_233859)"
      />
      <path
        d="M246.256 229.25C246.256 228.889 246.327 228.531 246.465 228.198C246.603 227.864 246.806 227.561 247.061 227.305C247.317 227.05 247.62 226.847 247.953 226.709C248.287 226.571 248.645 226.5 249.006 226.5C249.367 226.5 249.725 226.571 250.058 226.709C250.392 226.847 250.695 227.05 250.95 227.305C251.206 227.561 251.408 227.864 251.547 228.198C251.685 228.531 251.756 228.889 251.756 229.25C251.756 229.979 251.466 230.679 250.95 231.194C250.435 231.71 249.735 232 249.006 232C248.277 232 247.577 231.71 247.061 231.194C246.546 230.679 246.256 229.979 246.256 229.25ZM246.525 212.736C246.489 212.389 246.525 212.039 246.633 211.707C246.741 211.375 246.917 211.069 247.15 210.81C247.384 210.551 247.669 210.343 247.988 210.201C248.307 210.06 248.651 209.986 249 209.986C249.349 209.986 249.694 210.06 250.013 210.201C250.332 210.343 250.617 210.551 250.85 210.81C251.084 211.069 251.26 211.375 251.368 211.707C251.475 212.039 251.512 212.389 251.475 212.736L250.513 222.38C250.474 222.754 250.298 223.101 250.018 223.352C249.739 223.604 249.376 223.743 249 223.743C248.624 223.743 248.262 223.604 247.982 223.352C247.703 223.101 247.527 222.754 247.488 222.38L246.525 212.736Z"
        fill="white"
      />
      <circle cx="264" cy="99" r="4" fill="#EAEEF9" />
      <defs>
        <filter
          id="filter0_dd_461_233859"
          x="62"
          y="117"
          width="206"
          height="144"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology radius="4" operator="erode" in="SourceAlpha" result="effect1_dropShadow_461_233859" />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_461_233859" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology radius="4" operator="erode" in="SourceAlpha" result="effect2_dropShadow_461_233859" />
          <feOffset dy="20" />
          <feGaussianBlur stdDeviation="12" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_461_233859" result="effect2_dropShadow_461_233859" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_461_233859" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_461_233859"
          x1="87.7809"
          y1="219.769"
          x2="84.758"
          y2="124.584"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#EDF0F6" />
          <stop offset="1" stop-color="#FCFDFF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_461_233859"
          x1="248.5"
          y1="154.5"
          x2="181.5"
          y2="221.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF8960" />
          <stop offset="1" stop-color="#FF62A5" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const DataflowIllustration = ({ width = 162, height = 162 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform={`scale(${width / 162})`}>
        <circle cx="75.6842" cy="74.0807" r="42.2623" fill="#EAEEF9" />
        <path
          d="M72.0938 74.6846V74.6846C74.198 74.6846 75.9038 76.3904 75.9038 78.4946V97.9993C75.9038 99.9905 77.5179 101.605 79.5091 101.605V101.605"
          stroke="#BEC0D6"
          stroke-width="1.05656"
        />
        <path d="M73.2617 74.6846H81.3805H91.5289" stroke="#BEC0D6" stroke-width="1.05656" />
        <path
          d="M72.2031 74.5205V74.5205C74.1345 74.5205 75.7003 72.9548 75.7003 71.0234V51.5834C75.7003 49.1691 77.6574 47.2119 80.0717 47.2119V47.2119"
          stroke="#BEC0D6"
          stroke-width="1.05656"
        />
        <path
          d="M142.169 35.9863H80.853C79.5988 35.9863 78.582 37.4007 78.582 39.1453V54.9403C78.582 56.685 79.5988 58.0993 80.853 58.0993H142.169C143.423 58.0993 144.44 56.685 144.44 54.9403V39.1453C144.44 37.4007 143.423 35.9863 142.169 35.9863Z"
          fill="url(#paint0_linear_4114_237943)"
        />
        <path
          d="M122.826 41.0459H106.588C105.554 41.0459 104.715 41.8881 104.715 42.927C104.715 43.9658 105.554 44.808 106.588 44.808H122.826C123.861 44.808 124.699 43.9658 124.699 42.927C124.699 41.8881 123.861 41.0459 122.826 41.0459Z"
          fill="#BEC0D6"
        />
        <path
          d="M134.067 49.1973H106.588C105.554 49.1973 104.715 50.0394 104.715 51.0783C104.715 52.1172 105.554 52.9594 106.588 52.9594H134.067C135.102 52.9594 135.941 52.1172 135.941 51.0783C135.941 50.0394 135.102 49.1973 134.067 49.1973Z"
          fill="#D5DDEA"
        />
        <path
          d="M91.8626 52.9603C95.1393 52.9603 97.7956 50.2934 97.7956 47.0036C97.7956 43.7138 95.1393 41.0469 91.8626 41.0469C88.586 41.0469 85.9297 43.7138 85.9297 47.0036C85.9297 50.2934 88.586 52.9603 91.8626 52.9603Z"
          fill="#D5DDEA"
        />
        <path
          d="M153.708 63.8682H92.3921C91.1378 63.8682 90.1211 65.2825 90.1211 67.0272V82.8221C90.1211 84.5668 91.1378 85.9811 92.3921 85.9811H153.708C154.963 85.9811 155.979 84.5668 155.979 82.8221V67.0272C155.979 65.2825 154.963 63.8682 153.708 63.8682Z"
          fill="url(#paint1_linear_4114_237943)"
        />
        <path
          d="M134.361 68.9277H118.124C117.089 68.9277 116.25 69.7699 116.25 70.8088C116.25 71.8477 117.089 72.6899 118.124 72.6899H134.361C135.396 72.6899 136.235 71.8477 136.235 70.8088C136.235 69.7699 135.396 68.9277 134.361 68.9277Z"
          fill="#BEC0D6"
        />
        <path
          d="M145.602 77.0791H118.124C117.089 77.0791 116.25 77.9213 116.25 78.9602C116.25 79.9991 117.089 80.8412 118.124 80.8412H145.602C146.637 80.8412 147.476 79.9991 147.476 78.9602C147.476 77.9213 146.637 77.0791 145.602 77.0791Z"
          fill="#D5DDEA"
        />
        <path
          d="M103.398 80.8411C106.674 80.8411 109.331 78.1742 109.331 74.8844C109.331 71.5946 106.674 68.9277 103.398 68.9277C100.121 68.9277 97.4648 71.5946 97.4648 74.8844C97.4648 78.1742 100.121 80.8411 103.398 80.8411Z"
          fill="#D5DDEA"
        />
        <path
          d="M142.169 90.3066H80.853C79.5988 90.3066 78.582 91.721 78.582 93.4656V109.261C78.582 111.005 79.5988 112.42 80.853 112.42H142.169C143.423 112.42 144.44 111.005 144.44 109.261V93.4656C144.44 91.721 143.423 90.3066 142.169 90.3066Z"
          fill="url(#paint2_linear_4114_237943)"
        />
        <path
          d="M122.826 95.3662H106.588C105.554 95.3662 104.715 96.2084 104.715 97.2473C104.715 98.2862 105.554 99.1283 106.588 99.1283H122.826C123.861 99.1283 124.699 98.2862 124.699 97.2473C124.699 96.2084 123.861 95.3662 122.826 95.3662Z"
          fill="#BEC0D6"
        />
        <path
          d="M134.067 103.518H106.588C105.554 103.518 104.715 104.36 104.715 105.399C104.715 106.438 105.554 107.28 106.588 107.28H134.067C135.102 107.28 135.941 106.438 135.941 105.399C135.941 104.36 135.102 103.518 134.067 103.518Z"
          fill="#D5DDEA"
        />
        <path
          d="M91.8626 107.28C95.1393 107.28 97.7956 104.613 97.7956 101.323C97.7956 98.0331 95.1393 95.3662 91.8626 95.3662C88.586 95.3662 85.9297 98.0331 85.9297 101.323C85.9297 104.613 88.586 107.28 91.8626 107.28Z"
          fill="#D5DDEA"
        />
        <path
          d="M70.0638 63.3877H8.74753C7.49331 63.3877 6.47656 64.802 6.47656 66.5467V82.3417C6.47656 84.0863 7.49331 85.5007 8.74753 85.5007H70.0638C71.318 85.5007 72.3347 84.0863 72.3347 82.3417V66.5467C72.3347 64.802 71.318 63.3877 70.0638 63.3877Z"
          fill="url(#paint3_linear_4114_237943)"
        />
        <path
          d="M50.7166 68.4463H34.479C33.4443 68.4463 32.6055 69.2885 32.6055 70.3274C32.6055 71.3662 33.4443 72.2084 34.479 72.2084H50.7166C51.7513 72.2084 52.5901 71.3662 52.5901 70.3274C52.5901 69.2885 51.7513 68.4463 50.7166 68.4463Z"
          fill="#BEC0D6"
        />
        <path
          d="M61.9579 76.5977H34.479C33.4443 76.5977 32.6055 77.4398 32.6055 78.4787C32.6055 79.5176 33.4443 80.3598 34.479 80.3598H61.9579C62.9927 80.3598 63.8315 79.5176 63.8315 78.4787C63.8315 77.4398 62.9927 76.5977 61.9579 76.5977Z"
          fill="#D5DDEA"
        />
        <path
          d="M19.7572 80.3597C23.0338 80.3597 25.6901 77.6928 25.6901 74.403C25.6901 71.1132 23.0338 68.4463 19.7572 68.4463C16.4805 68.4463 13.8242 71.1132 13.8242 74.403C13.8242 77.6928 16.4805 80.3597 19.7572 80.3597Z"
          fill="#D5DDEA"
        />
        <defs>
          <linearGradient
            id="paint0_linear_4114_237943"
            x1="80.8755"
            y1="57.8375"
            x2="87.2657"
            y2="38.2581"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#EDF0F6" />
            <stop offset="1" stop-color="#FCFDFF" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_4114_237943"
            x1="92.4146"
            y1="85.7194"
            x2="98.8048"
            y2="66.1399"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#EDF0F6" />
            <stop offset="1" stop-color="#FCFDFF" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_4114_237943"
            x1="80.8755"
            y1="112.158"
            x2="87.2657"
            y2="92.5784"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#EDF0F6" />
            <stop offset="1" stop-color="#FCFDFF" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_4114_237943"
            x1="8.77007"
            y1="85.2389"
            x2="15.1602"
            y2="65.6595"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#EDF0F6" />
            <stop offset="1" stop-color="#FCFDFF" />
          </linearGradient>
        </defs>
      </g>
    </svg>
  );
};

export const SearchIllustration = ({ width = 162, height = 163 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform={`scale(${width / 162})`}>
        <g clip-path="url(#clip0_461_235001)">
          <path d="M4.44604 82.8886V84.2771" stroke="#D2D8DF" stroke-width="1.35201" stroke-linecap="round" />
          <path d="M1.32715 83.6407L2.46181 84.8088" stroke="#D2D8DF" stroke-width="1.35201" stroke-linecap="round" />
          <path d="M0 86.75H1.6044" stroke="#D2D8DF" stroke-width="1.35201" stroke-linecap="round" />
          <path d="M1.19702 89.9146L2.33168 88.7466" stroke="#D2D8DF" stroke-width="1.35201" stroke-linecap="round" />
          <path d="M4.44604 90.2943V88.9057" stroke="#D2D8DF" stroke-width="1.35201" stroke-linecap="round" />
          <path d="M6.76019 89.3686L5.83447 88.4429" stroke="#D2D8DF" stroke-width="1.35201" stroke-linecap="round" />
          <path d="M8.61172 87.0543H7.22314" stroke="#D2D8DF" stroke-width="1.35201" stroke-linecap="round" />
          <path d="M7.68597 83.8143L6.76025 84.74" stroke="#D2D8DF" stroke-width="1.35201" stroke-linecap="round" />
          <path d="M78.9661 22.2543V23.18" stroke="#D2D8DF" stroke-width="1.35201" stroke-linecap="round" />
          <path d="M76.1887 25.0314H77.1144" stroke="#D2D8DF" stroke-width="1.35201" stroke-linecap="round" />
          <path d="M78.9661 27.8086V26.8829" stroke="#D2D8DF" stroke-width="1.35201" stroke-linecap="round" />
          <path d="M81.2802 25.0314H80.3545" stroke="#D2D8DF" stroke-width="1.35201" stroke-linecap="round" />
          <path d="M25.2744 61.1343V62.5229" stroke="#D2D8DF" stroke-width="1.35201" stroke-linecap="round" />
          <path d="M21.1089 65.3H22.4975" stroke="#D2D8DF" stroke-width="1.35201" stroke-linecap="round" />
          <path d="M25.2744 69.0029V67.6143" stroke="#D2D8DF" stroke-width="1.35201" stroke-linecap="round" />
          <path d="M28.9774 65.3H27.5889" stroke="#D2D8DF" stroke-width="1.35201" stroke-linecap="round" />
          <path
            d="M124.906 111.604C126.012 111.604 126.91 112.501 126.91 113.608C126.91 114.714 126.012 115.611 124.906 115.611C123.8 115.611 122.903 114.714 122.903 113.608"
            stroke="#D2D8DF"
            stroke-width="1.35201"
            stroke-linecap="round"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M134.074 93.4248C135.263 93.0538 137.087 91.5991 137.509 90.034C137.891 91.4175 139.496 93.0538 140.943 93.1776C139.314 93.7546 137.756 95.4687 137.509 96.8153C137.341 95.4427 135.186 93.6249 134.074 93.4248"
            fill="#EBECEE"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.9773 110.987C12.5722 110.801 13.4835 110.074 13.6945 109.291C13.8858 109.983 14.6878 110.801 15.4116 110.863C14.5971 111.151 13.8182 112.009 13.6945 112.682C13.6104 111.996 12.5329 111.086 11.9773 110.987"
            fill="#EBECEE"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M23.0175 46.5993C23.0175 47.4577 22.322 48.153 21.4642 48.153C20.606 48.153 19.9109 47.4577 19.9109 46.5993C19.9109 45.7412 20.606 45.0455 21.4642 45.0455C22.322 45.0455 23.0175 45.7412 23.0175 46.5993"
            fill="#D1D8DF"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M126.742 50.7712C126.742 52.2055 125.579 53.3681 124.145 53.3681C122.711 53.3681 121.549 52.2055 121.549 50.7712C121.549 49.337 122.711 48.1743 124.145 48.1743C125.579 48.1743 126.742 49.337 126.742 50.7712"
            fill="#EBECEE"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M52.0479 139.944C52.0479 140.661 51.4667 141.243 50.7496 141.243C50.0328 141.243 49.4517 140.661 49.4517 139.944C49.4517 139.227 50.0328 138.645 50.7496 138.645C51.4667 138.645 52.0479 139.227 52.0479 139.944Z"
            stroke="#D2D8DF"
            stroke-width="1.35201"
            stroke-linecap="round"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M51.2753 36.6176C51.2753 37.476 50.5798 38.1713 49.722 38.1713C48.8642 38.1713 48.1687 37.476 48.1687 36.6176C48.1687 35.7595 48.8642 35.0638 49.722 35.0638C50.5798 35.0638 51.2753 35.7595 51.2753 36.6176Z"
            stroke="#D2D8DF"
            stroke-width="1.35201"
            stroke-linecap="round"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M90.8973 154.324C89.9047 154.702 88.7939 154.203 88.4163 153.211C88.0386 152.218 88.5369 151.106 89.5299 150.729C90.5221 150.351 91.6329 150.85 92.0105 151.843C92.3886 152.835 91.8899 153.946 90.8973 154.324Z"
            stroke="#D2D8DF"
            stroke-width="1.35201"
            stroke-linecap="round"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M88.0798 118.041H50.0449C47.3678 118.041 45.1765 115.857 45.1765 113.188V59.284C45.1765 56.6146 47.3678 54.4315 50.0449 54.4315H88.0798C90.758 54.4315 92.9493 56.6146 92.9493 59.284V113.188C92.9493 115.857 90.758 118.041 88.0798 118.041"
            fill="#EAEEF9"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M83.9141 121.744H45.8792C43.2021 121.744 41.0107 119.56 41.0107 116.891V62.9869C41.0107 60.3174 43.2021 58.1343 45.8792 58.1343H83.9141C86.5922 58.1343 88.7836 60.3174 88.7836 62.9869V116.891C88.7836 119.56 86.5922 121.744 83.9141 121.744"
            fill="#D8DBEA"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M83.9141 121.744H45.8792C43.2021 121.744 41.0107 119.56 41.0107 116.891V62.9869C41.0107 60.3174 43.2021 58.1343 45.8792 58.1343H83.9141C86.5922 58.1343 88.7836 60.3174 88.7836 62.9869V116.891C88.7836 119.56 86.5922 121.744 83.9141 121.744"
            fill="url(#paint0_linear_461_235001)"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M78.4318 74.5571H50.3384C49.5361 74.5571 48.8794 73.9324 48.8794 73.1691C48.8794 72.4048 49.5361 71.78 50.3384 71.78H78.4318C79.2341 71.78 79.8908 72.4048 79.8908 73.1691C79.8908 73.9324 79.2341 74.5571 78.4318 74.5571"
            fill="#E0E2EE"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M78.4318 82.8886H50.3384C49.5361 82.8886 48.8794 82.2638 48.8794 81.5005C48.8794 80.7362 49.5361 80.1114 50.3384 80.1114H78.4318C79.2341 80.1114 79.8908 80.7362 79.8908 81.5005C79.8908 82.2638 79.2341 82.8886 78.4318 82.8886"
            fill="#E0E2EE"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M78.4318 91.22H50.3384C49.5361 91.22 48.8794 90.5952 48.8794 89.8319C48.8794 89.0687 49.5361 88.4429 50.3384 88.4429H78.4318C79.2341 88.4429 79.8908 89.0687 79.8908 89.8319C79.8908 90.5952 79.2341 91.22 78.4318 91.22"
            fill="#E0E2EE"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M78.4318 99.5514H50.3384C49.5361 99.5514 48.8794 98.9267 48.8794 98.1634C48.8794 97.3991 49.5361 96.7743 50.3384 96.7743H78.4318C79.2341 96.7743 79.8908 97.3991 79.8908 98.1634C79.8908 98.9267 79.2341 99.5514 78.4318 99.5514"
            fill="#E0E2EE"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M65.0027 107.883H50.3447C49.5389 107.883 48.8794 107.258 48.8794 106.495C48.8794 105.73 49.5389 105.106 50.3447 105.106H65.0027C65.8084 105.106 66.468 105.73 66.468 106.495C66.468 107.258 65.8084 107.883 65.0027 107.883"
            fill="#E0E2EE"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M94.5887 100.818C88.9711 106.435 80.2744 107.068 73.9607 102.732C73.1538 102.168 72.3858 101.534 71.6696 100.818C71.301 100.451 70.9535 100.071 70.626 99.6722C69.9711 98.8749 69.3974 98.0246 68.9274 97.146C68.0993 95.6621 67.5363 94.0874 67.2288 92.4598C66.237 87.3555 67.72 81.8614 71.6696 77.9136C75.6287 73.9541 81.1237 72.4808 86.2289 73.463C87.8556 73.7798 89.4317 74.3428 90.9159 75.1613C91.7958 75.6418 92.6345 76.2154 93.4331 76.8702C93.8313 77.1964 94.2106 77.545 94.5781 77.9136C95.2954 78.6297 95.9386 79.3964 96.4922 80.2043C100.831 86.5158 100.196 95.2122 94.5887 100.818"
            fill="white"
            fill-opacity="0.1"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M93.0169 99.712C87.1726 105.557 77.7014 105.556 71.856 99.712C66.0182 93.8742 66.0182 84.4019 71.8636 78.5576C77.7014 72.7198 87.1726 72.7198 93.0103 78.5576C98.8546 84.4019 98.8546 93.8742 93.0169 99.712M96.0163 75.5548C88.5147 68.0532 76.3584 68.0532 68.8567 75.5548C61.3561 83.0554 61.3496 95.2193 68.8502 102.721C75.6776 109.546 86.3788 110.162 93.9055 104.558C94.6406 104.01 95.3486 103.395 96.024 102.721C96.6982 102.046 97.3127 101.338 97.8597 100.603C103.464 93.0747 102.843 82.3812 96.0163 75.5548"
            fill="#E0E2EE"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M98.159 100.035L103.978 105.584L100.039 109.713L94.2211 104.165C94.9578 103.588 95.664 102.944 96.3377 102.24C97.0092 101.536 97.6188 100.798 98.159 100.035Z"
            fill="#E0E2EE"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M115.505 121.331L115.277 121.559C113.764 123.073 111.288 123.073 109.775 121.559L95.8115 107.596L101.542 101.866L115.505 115.829C117.018 117.342 117.018 119.818 115.505 121.331"
            fill="#D5DDEA"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M115.251 115.815L109.76 121.306L108.771 120.316L114.262 114.826L115.251 115.815Z"
            fill="#FEFEFF"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M101.829 102.241L96.1869 107.883L95.3486 107.045L100.99 101.403L101.829 102.241Z"
            fill="#FEFEFF"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M89.1479 76.5468L69.3823 96.3114C68.6379 94.9773 68.1317 93.5617 67.8564 92.0985L84.9347 75.02C86.397 75.3059 87.8137 75.8109 89.1479 76.5468"
            fill="white"
            fill-opacity="0.5"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M95.6279 81.2447L74.2591 101.403C73.4938 100.899 72.7655 100.332 72.0862 99.6913C71.7366 99.3614 71.4059 99.0231 71.0964 98.6659L92.7255 78.26C93.1042 78.553 93.464 78.8649 93.8125 79.1948C94.4917 79.8356 95.1028 80.5217 95.6279 81.2447"
            fill="white"
            fill-opacity="0.5"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_461_235001"
            x1="42.6744"
            y1="120.991"
            x2="38.7588"
            y2="62.9787"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#EDF0F6" />
            <stop offset="1" stop-color="#FCFDFF" />
          </linearGradient>
          <clipPath id="clip0_461_235001">
            <rect width="162" height="162" fill="white" transform="translate(0 0.5)" />
          </clipPath>
        </defs>
      </g>
    </svg>
  );
};
