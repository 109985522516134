import React from 'react';
import { FormRow, Button, ButtonEmpty, CallOut, Spacer } from '@tecton';

import { TectonBasicModal, CalloutMessageType } from '@shared';
import AccountTypeSelectorFormRowGQL from '../AccountTypeSelectorFormRowGQL';

const AccountTypeChangeModalGQL = <T extends string>({
  userId,
  currentAccountType,
  hasOtherSourcesOfAccountType,
  onClose,
  confirmChange,
  isLoading,
  selectedAccountType,
  setSelectedAccountType,
  errorMessages,
}: {
  userId?: string;
  currentAccountType: T | undefined;
  hasOtherSourcesOfAccountType?: boolean;
  onClose: () => void;
  confirmChange: (type: string | undefined) => void;
  isLoading: boolean;
  selectedAccountType: T | undefined;
  setSelectedAccountType: (role: T) => void;
  errorMessages: CalloutMessageType[] | undefined;
}) => {
  const title = `Modify Account Type of ${userId}`;

  return (
    <TectonBasicModal
      style={{ width: `80em` }}
      onClose={onClose}
      title={title}
      body={
        <div style={{ minWidth: `50em` }}>
          <FormRow label="Select Role" fullWidth>
            <AccountTypeSelectorFormRowGQL<T>
              userId={userId ?? ''}
              currentAccountType={currentAccountType}
              selectedAccountType={selectedAccountType}
              setSelectedAccountType={setSelectedAccountType}
            />
          </FormRow>
          {hasOtherSourcesOfAccountType && (
            <>
              <Spacer size="m" />
              <CallOut
                size="s"
                iconType="magnifyWithExclamation"
                title={`Note: ${userId} also inherits Admin privileges from groups. This modal only modifies their directly assigned account type.`}
              />
            </>
          )}
        </div>
      }
      actions={
        <>
          <ButtonEmpty key="cancel" onClick={onClose}>
            Cancel
          </ButtonEmpty>
          <Button
            key="confirm"
            fill
            isDisabled={selectedAccountType === currentAccountType}
            isLoading={isLoading}
            onClick={() => {
              confirmChange(selectedAccountType);
            }}
          >
            <span data-testid={`button-change-${selectedAccountType}`}>
              Change assigned Account Type {selectedAccountType && ` to ${selectedAccountType}`}
            </span>
          </Button>
        </>
      }
      calloutMessages={errorMessages}
    />
  );
};

export default AccountTypeChangeModalGQL;
