/* eslint-disable */
import { Id } from "../common/id";

export const protobufPackage = "tecton_proto.data";

export enum ServingState {
  SERVING_STATE_UNSPECIFIED = "SERVING_STATE_UNSPECIFIED",
  /** SERVING_STATE_DISABLED - Materialization is not enabled */
  SERVING_STATE_DISABLED = "SERVING_STATE_DISABLED",
  /** SERVING_STATE_NOT_ENOUGH_DATA - Feature aggregation range is larger then materialized data available */
  SERVING_STATE_NOT_ENOUGH_DATA = "SERVING_STATE_NOT_ENOUGH_DATA",
  /** SERVING_STATE_PENDING - Not ready due to one or more jobs not yet running */
  SERVING_STATE_PENDING = "SERVING_STATE_PENDING",
  /** SERVING_STATE_OK - Everything is okay */
  SERVING_STATE_OK = "SERVING_STATE_OK",
  /** SERVING_STATE_ERROR - Not ready due to one or more materialization failure */
  SERVING_STATE_ERROR = "SERVING_STATE_ERROR",
  /** SERVING_STATE_RUNNING - Materialization job is running */
  SERVING_STATE_RUNNING = "SERVING_STATE_RUNNING",
}

export interface ServingStatus {
  serving_state?:
    | ServingState
    | undefined;
  /** Error messages from the job(s) */
  errors?: string[] | undefined;
}

export interface StatusRange {
  begin_inclusive?: string | undefined;
  end_exclusive?: string | undefined;
  status?: ServingStatus | undefined;
}

export interface FeatureViewMaterializationRanges {
  feature_view_id?:
    | Id
    | undefined;
  /**
   * Sorted materialization status (OK/ERROR/PENDING) time ranges derived from
   * batch materialization tasks and by aggregating neighboring ranges when
   * possible.
   */
  batch_materialization_ranges?: StatusRange[] | undefined;
}

export interface ServingStatusSummary {
  /**
   * Sorted time ranges of serving status, starting from earliest
   * materialization `startTimestamp` and ending at latest materialized data
   * plus corresponding `slideInterval` across underlying FeatureViews.
   * Calculated only for the temporal FeatureViews.
   */
  offline_readiness_ranges?: StatusRange[] | undefined;
  online_readiness_ranges?:
    | StatusRange[]
    | undefined;
  /**
   * If the request was for the FeatureService, contains materialization status
   * ranges for each underlying FeatureView; otherwise, only for the requested
   * FeatureView.
   */
  batch_materialization_ranges?:
    | FeatureViewMaterializationRanges[]
    | undefined;
  /**
   * Start time from which we began ingesting data. Supposed to be event time,
   * but we are currently approximating using processing time.
   */
  streaming_start_time?:
    | string
    | undefined;
  /**
   * The status of streaming job. An error status here indicates that some
   * data could be being served and isn't, however the Feature Server
   * may still be successfully serving older data in this case. See
   * most_recent_ready_time to determine if anything is being served.
   */
  streaming_serving_status?:
    | ServingStatus
    | undefined;
  /**
   * The most recent ready time. This is what is being served by the
   * Feature Server. If it's unset, the FS is not serving anything.
   */
  most_recent_ready_time?:
    | string
    | undefined;
  /**
   * The wall clock time of the most recent successful batch materialization job
   * Currently the completion time of that job.
   */
  most_recent_batch_processing_time?: string | undefined;
}

export interface FeatureViewServingStatusSummary {
  feature_view_id?: Id | undefined;
  serving_status_summary?: ServingStatusSummary | undefined;
}

/** The combined Serving Status Summary for a feature service and all its FVs */
export interface FullFeatureServiceServingSummary {
  feature_service_serving_status_summary?: ServingStatusSummary | undefined;
  feature_view_serving_status_summaries?: FeatureViewServingStatusSummary[] | undefined;
}
