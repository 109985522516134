import React, { FC } from 'react';
import { Icon, FlexGroup, FlexItem } from '..';

// icons
import Edit from '@tecton/ComponentRedesign/svg/edit.svg';

interface EditableFieldReadProps {
  fieldValue: string;
}

const EditableField: FC<EditableFieldReadProps> = ({ fieldValue }) => {
  return (
    <>
      <FlexGroup justifyContent="spaceBetween" gutterSize="s">
        <FlexItem grow={true}>{fieldValue}</FlexItem>
        <FlexItem grow={false} style={{ alignSelf: 'center' }}>
          <Icon type={Edit} />
        </FlexItem>
      </FlexGroup>
    </>
  );
};

export default EditableField;
