import React from 'react';
import RoleSelectorFormRow, { OptionsInfo } from '../shared/RowSelectorFormRow';
import RoleTableDescription from './RoleTableDescription';

export interface RoleRowDescription {
  hasAccess: boolean;
  description: string;
}

const AccountTypeSelectorFormRowGQL = <T extends string>({
  userId,
  currentAccountType,
  selectedAccountType,
  setSelectedAccountType,
}: {
  userId: string;
  currentAccountType?: T | undefined;
  selectedAccountType?: T | '';
  setSelectedAccountType: (accountType: T) => void;
}) => {
  const adminTableValues: RoleRowDescription[] = [
    {
      hasAccess: true,
      description: 'Create a live workspace',
    },
    {
      hasAccess: true,
      description: ' Manage users',
    },
    {
      hasAccess: true,
      description: 'Modify roles',
    },
    {
      hasAccess: true,
      description: 'Modify Platform Configuration',
    },
    {
      hasAccess: true,
      description: 'Create Service Accounts',
    },
    {
      hasAccess: true,
      description: 'Create a dev workspace',
    },
  ];

  const regularValues: RoleRowDescription[] = [
    {
      hasAccess: false,
      description: 'Create a live workspace',
    },
    {
      hasAccess: false,
      description: ' Manage users',
    },
    {
      hasAccess: false,
      description: 'Modify roles',
    },
    {
      hasAccess: false,
      description: 'Modify Platform Configuration',
    },
    {
      hasAccess: true,
      description: 'Create Service Accounts',
    },
    {
      hasAccess: true,
      description: 'Create a dev workspace',
    },
  ];

  const roleRecords: OptionsInfo<T>[] = [
    {
      id: 'Admin',
      name: 'Admin',
      description: 'Can make sensitive infrastructure and access control changes.',
      detailDescription: () => <RoleTableDescription tableValues={adminTableValues} />,
    },
    {
      id: 'Regular',
      name: 'Regular',
      description: 'Can take actions based on workspace-level roles.',
      detailDescription: () => <RoleTableDescription tableValues={regularValues} />,
    },
  ];

  return (
    <RoleSelectorFormRow<T>
      noun="account type"
      options={roleRecords}
      userId={userId}
      current={currentAccountType}
      selected={selectedAccountType}
      setSelected={setSelectedAccountType}
    />
  );
};

export default AccountTypeSelectorFormRowGQL;
