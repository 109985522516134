import React, { FC, useState } from 'react';
import { EuiDatePickerRange } from '@elastic/eui';
import { Moment } from 'moment-timezone';
import { DatePicker } from '.';

interface DatePickerRangeProps {
  onChange: (startDate: Moment, endDate: Moment) => void;
  momentStartDate: Moment;
  minDate: Moment;
  maxDate: Moment;
  canShowYearDropdown: boolean;
  onBlur?: () => void;
}

export const DatePickerRange: FC<DatePickerRangeProps> = ({
  onChange,
  momentStartDate,
  minDate,
  maxDate,
  canShowYearDropdown = true,
  onBlur,
}) => {
  const [startDate, setStartDate] = useState(momentStartDate);
  const [endDate, setEndDate] = useState(maxDate);

  const isInvalid = startDate > endDate || startDate < minDate;

  return (
    <EuiDatePickerRange
      onBlur={onBlur}
      startDateControl={
        <DatePicker
          selected={startDate}
          onChange={(startDate: Moment) => {
            setStartDate(startDate);
            if (!isInvalid) {
              onChange(startDate, endDate);
            }
          }}
          showYearDropdown={canShowYearDropdown}
          startDate={startDate}
          endDate={endDate}
          minDate={minDate}
          maxDate={endDate}
          isInvalid={isInvalid}
          showTimeSelect
        />
      }
      endDateControl={
        <DatePicker
          selected={endDate}
          onChange={(endDate: Moment) => {
            setEndDate(endDate);
            if (!isInvalid) {
              onChange(startDate, endDate);
            }
          }}
          showYearDropdown={canShowYearDropdown}
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          maxDate={maxDate}
          isInvalid={isInvalid}
          showTimeSelect
        />
      }
    />
  );
};

export default DatePickerRange;
