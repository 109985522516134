import { Metadata_Service } from './';
import { useQuery } from '@tanstack/react-query';
import { ListWorkspacesResponse } from '../types/tecton_proto/metadataservice/metadata_service';

interface UseListWorkspacesOptions<T = ListWorkspacesResponse> {
  select?: (data: ListWorkspacesResponse) => T;
  onSuccess?: (data: T) => void;
}

const fetchWorkspaces = async (): Promise<ListWorkspacesResponse> => {
  const { data } = await Metadata_Service('list-workspaces', { method: 'POST' });
  return data;
};

export const useListWorkspaces = <T = ListWorkspacesResponse>(options: UseListWorkspacesOptions<T> = {}) => {
  return useQuery(['list-workspaces'], () => fetchWorkspaces(), options);
};
