import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useGetOnboardingStatus } from '../../api/onboarding';
import { useListWorkspaces } from '../../api/workspaces';
import { FeatureFlags } from '../../components/context/UserSettingsContext';
import { updateOnboarding } from '../../feature/onboarding/actions';
import { OnboardingStateEnums } from '../../feature/onboarding/types';
import { GetOnboardingStatusResponse } from '../../types/tecton_proto/metadataservice/metadata_service';
import { onboardingStateSelector } from '../../utils/onboarding/onboarding-util';
import WorkspaceUtils from '../../utils/workspace-utils';
import { canShowOnboarding } from '../feature-flag/feature-flag';
import { updateConfigUpdateIsOnboardingEnabled } from './actions';

export enum OnboardingStatusEnum {
  ONBOARDING_STATUS_UNSPECIFIED = 'ONBOARDING_STATUS_UNSPECIFIED',
  ONBOARDING_STATUS_INCOMPLETE = 'ONBOARDING_STATUS_INCOMPLETE',
  ONBOARDING_STATUS_COMPLETED = 'ONBOARDING_STATUS_COMPLETED',
}

const useOnboardingFlagsHook = (refetchStatusInterval?: number) => {
  const dispatch = useDispatch();
  const { data: workspacesData, isLoading: workspacesIsLoading } = useListWorkspaces();
  const navigate = useNavigate();
  let workspace = WorkspaceUtils.getWorkspaceFromStore();

  const isLocalStorageWorkspaceExists = !!workspacesData?.workspaces?.find((ws: any) => {
    return ws.name === workspace;
  });

  // Check to see if their local storage MOST_RECENTLY_VIEWED_WORKSPACE value has a valid workspace
  if (!isLocalStorageWorkspaceExists && !workspacesIsLoading) {
    // They don't have a valid workspace in local storage so we redirect them to /
    // Send them to the root since it redirects you correctly which considers's old/new UI home paths.
    navigate(`/`);
    // Update workspace local storage value
    WorkspaceUtils.setWorkspaceForLocalStorage('prod');
  }

  //const [hasDispatched, setHasDispatched] = useState<boolean>(false);
  const onboardingState = useSelector(onboardingStateSelector);
  const onSuccessCallback = (result: GetOnboardingStatusResponse) => {
    if (!onboardingState.hasLoadedOnboarding) {
      //setHasDispatched(true);

      // Check to see if we need to force a state for guided onboarding from localStorage
      const forceOnboardingValue = localStorage.getItem(FeatureFlags.FORCE_ONBOARDING_STATE_VALUE);

      const dispatchNewAccountOnboarding = () => {
        dispatch(updateOnboarding(OnboardingStateEnums.NEW_ACCOUNT));
      };

      const dispatchHasCompletedGuidedOnboarding = () => {
        dispatch(updateOnboarding(OnboardingStateEnums.HAS_COMPLETED_GUIDED_ONBOARDING));
      };

      const dispatchMap: any = {
        NEW_ACCOUNT: dispatchNewAccountOnboarding,
        HAS_COMPLETED_GUIDED_ONBOARDING: dispatchHasCompletedGuidedOnboarding,
      };

      // only ONBOARDING_STATUS_INCOMPLETE gets to dispatch or if we have a forceOnboardingValue
      if (result.finish_onboarding === OnboardingStatusEnum.ONBOARDING_STATUS_INCOMPLETE || forceOnboardingValue) {
        if (forceOnboardingValue) {
          const callBack = dispatchMap[`${forceOnboardingValue}`];
          if (callBack) {
            // call the dispatch
            callBack();
          }
        } else {
          if (result.setup_platform === OnboardingStatusEnum.ONBOARDING_STATUS_COMPLETED) {
            dispatchHasCompletedGuidedOnboarding();
          } else {
            dispatchNewAccountOnboarding();
          }
        }
      }

      // We check local storage if we want to force showing guided onboarding
      if (canShowOnboarding()) {
        // We use the override from local storage
        dispatch(updateConfigUpdateIsOnboardingEnabled(canShowOnboarding()));
      } else {
        // We didn't find anything in local storage, so we will use the backend result instead
        dispatch(
          updateConfigUpdateIsOnboardingEnabled(
            result.finish_onboarding === OnboardingStatusEnum.ONBOARDING_STATUS_INCOMPLETE
          )
        );
      }
    }
  };

  let queryOptions: any = {
    onSuccess: onSuccessCallback,
    enabled: isLocalStorageWorkspaceExists && !workspacesIsLoading, // make sure the workspace is valid before making this call
  };

  // Check to see if we need to refetch. Refetch will happen in the Create Repository section of the GO
  // Process. We wait for the user to do tecton apply
  if (refetchStatusInterval) {
    queryOptions = { ...queryOptions, refetchInterval: refetchStatusInterval };
  }

  workspace = 'prod';
  const state = useGetOnboardingStatus(workspace, queryOptions);

  if (state.error) {
    throw state.error;
  }
  return state;
};

export { useOnboardingFlagsHook };
