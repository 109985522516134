import React, { useEffect, useState } from 'react';
import { DataPlatformSetupTaskStatusEnum, Task } from '../../../../service/DataPlatformSetupStatus';
import UserDeploymentSettings from '../../../../service/UserDeploymentSettings';
import { useGetDataPlatformSetupStatus } from '../../../onboarding/configure/query';
import useHiveMetadataService from '../../../onboarding/datasource/useHiveMetadataService';
import useLogOnce from './useLogOnce';

const useValidateGlueIdIfNeeded = (
  setIsDataPlatformSetupComplete: React.Dispatch<React.SetStateAction<boolean>>,
  userDeploymentSettings: UserDeploymentSettings,
  dataPlatformSetupStatus: ReturnType<typeof useGetDataPlatformSetupStatus>
) => {
  const [dpoStatuses, setDpoStatuses] = useState<Task[]>([]);
  const allTaskSuccessLog = useLogOnce();
  const glueIdValidationFailedLog = useLogOnce();
  const { databasesRequest } = useHiveMetadataService();

  useEffect(() => {
    if (dataPlatformSetupStatus.data && dataPlatformSetupStatus.isSuccess) {
      // All tasks from accordion. This useEffect should get called for every poll
      const tasks: Task[] = dataPlatformSetupStatus?.data?.tasks;
      if (Array.isArray(tasks)) {
        const accordionTasks: Task[] = [...tasks];
        const areAllTaskSuccessState = tasks.every((task) => {
          // checking to see if all task are TASK_STATUS_SUCCEEDED
          return task.task_status === DataPlatformSetupTaskStatusEnum.TASK_STATUS_SUCCEEDED;
        });

        // No additional work here so just send the task
        if (!userDeploymentSettings.glueCatalogEnabled) {
          setDpoStatuses(accordionTasks);
          if (areAllTaskSuccessState) {
            setIsDataPlatformSetupComplete(true);
          }
        } else {
          // We need to add Glue Id. Status is running until success comes back
          let glueIDTask = {
            task_display_name: 'Validating AWS Glue Catalog (waiting for internal validation cluster)',
            task_status: DataPlatformSetupTaskStatusEnum.TASK_STATUS_RUNNING,
            error_message: '',
          };
          const taskWithHive: Task[] = [...accordionTasks, glueIDTask];
          setDpoStatuses(taskWithHive);

          if (areAllTaskSuccessState) {
            setIsDataPlatformSetupComplete(false);

            databasesRequest.sendRequest((response: any) => {
              if (Array.isArray(response)) {
                // Once the onSuccess comes back, we update the glueID status to success
                glueIDTask.task_status = DataPlatformSetupTaskStatusEnum.TASK_STATUS_SUCCEEDED;
                glueIDTask.task_display_name = 'Validated AWS Glue Catalog ID';
                const taskWithHive: Task[] = [...accordionTasks, glueIDTask];
                setDpoStatuses(taskWithHive);
                // This shows the next button for create repo
                setIsDataPlatformSetupComplete(true);
                // Log success task
                allTaskSuccessLog('DPO(with Glue): All tasks succeed');
              } else {
                // Glue validation failed
                glueIDTask.task_status = DataPlatformSetupTaskStatusEnum.TASK_STATUS_FAILED;
                glueIDTask.task_display_name = 'Validate AWS Glue Catalog ID';
                const taskWithHive: Task[] = [...accordionTasks, glueIDTask];
                setDpoStatuses(taskWithHive);
                setIsDataPlatformSetupComplete(false);

                // Log when glue validation failure
                glueIdValidationFailedLog(`DPO: Glue ID validation failed.`);
              }
            });
          } else {
            // We wait until all 4 validation from backend is finish.
            setDpoStatuses(taskWithHive);
          }
        }
      }
    }
  }, [dataPlatformSetupStatus.data]);

  return { dpoStatuses };
};

export default useValidateGlueIdIfNeeded;
