import React, { createContext, ReactNode, useReducer } from 'react';
import { GlobalToastList, Toast } from '@tecton';

// Would be nice to get get the type for dispatch too
export const ToastContext = createContext<{ toasts: Toast[]; dispatchToast: any } | any>({});

type ToastsList = NonNullable<React.ComponentProps<typeof GlobalToastList>['toasts']>;
type ToastWithoutId = Omit<Toast, 'id'>;

export interface ToastsState {
  toasts: ToastsList;
  id: number;
}

export enum ToastEnum {
  ADD_TOAST,
  REMOVE_TOAST,
}

export const addToast = (payload: ToastWithoutId) => {
  return {
    type: ToastEnum.ADD_TOAST,
    payload,
  };
};

export const removeToast = (payload: Toast) => {
  return {
    type: ToastEnum.REMOVE_TOAST,
    payload,
  };
};

const toastReducer = (state: ToastsState, action: { type: ToastEnum; payload: any }) => {
  switch (action.type) {
    case ToastEnum.ADD_TOAST: {
      const newToastState: ToastsState = {
        id: state.id + 1,
        toasts: state.toasts.slice(0),
      };

      if (action.payload) {
        newToastState.toasts.push({
          ...action.payload,
          id: String(newToastState.id),
        });
      }

      return newToastState;
    }
    case ToastEnum.REMOVE_TOAST: {
      return {
        ...state,
        toasts: state.toasts.filter((t: Toast) => {
          return t.id !== action.payload?.id;
        }),
      };
    }
    default:
      return state;
  }
};

export const ToastContextProvider = ({ children }: { children: ReactNode }) => {
  const initialState: ToastsState = {
    toasts: [],
    id: 0,
  };
  const [toasts, dispatchToast] = useReducer(toastReducer, initialState);
  return (
    <ToastContext.Provider
      value={{
        dispatchToast,
        toasts,
      }}
    >
      {children}
    </ToastContext.Provider>
  );
};
