import React, { FC } from 'react';

interface FeatureViewIconProps {
  newVersion?: boolean;
}

const FeatureViewIcon: FC<FeatureViewIconProps> = ({ newVersion }) => {
  return (
    <g>
      <path
        opacity="0.5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4.64392L0 11.5533L12 18.4626L24 11.5533L12 4.64392ZM13.8033 9.02056C14.7796 8.45694 14.7796 7.54312 13.8033 6.9795C12.827 6.41587 11.2441 6.41587 10.2678 6.9795C9.29146 7.54312 9.29146 8.45694 10.2678 9.02056C11.2441 9.58419 12.827 9.58419 13.8033 9.02056Z"
        fill="#39DAB1"
      />
      <path
        opacity="0.3"
        d="M10.8897 9.2832L0 15.5533L12 22.4626L24 15.5533L13.1341 9.29691C12.4271 9.49654 11.5908 9.49197 10.8897 9.2832Z"
        fill="#39DAB1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1.00006L0 7.90939L12 14.8187L24 7.90939L12 1.00006ZM13.8033 9.0205C14.7796 8.45688 14.7796 7.54306 13.8033 6.97944C12.827 6.41581 11.2441 6.41581 10.2678 6.97944C9.29146 7.54306 9.29146 8.45688 10.2678 9.0205C11.2441 9.58412 12.827 9.58412 13.8033 9.0205Z"
        fill="#39DAB1"
      />
    </g>
  );
};

export default FeatureViewIcon;
