import React from 'react';

import { CheckableCard, EmptyPrompt, FormFieldset, Text, useTectonTheme } from '@tecton';
import { css } from '@emotion/css';

type OptionsInfo<T extends string | number | symbol> = {
  id: T | string;
  name: string;
  description: string;
  detailDescription: (userId: string) => React.ReactNode;
};

const RoleSelectorFormRow = <T extends string | number | symbol>({
  noun,
  options,
  userId,
  current,
  selected,
  setSelected,
}: {
  noun: 'role' | 'account type';
  options: OptionsInfo<T>[];
  userId: string;
  current?: string | undefined;
  selected?: T | '';
  setSelected: (role: T) => void;
}) => {
  const { theme } = useTectonTheme();

  const selectedRecord = options.find((r) => {
    return r.name.toLowerCase() === selected?.toString().toLowerCase();
  });

  const selectedContent = selectedRecord ? (
    selectedRecord.detailDescription(userId)
  ) : (
    <EmptyPrompt
      iconType="minusInCircle"
      hasBorder
      style={{
        width: `100%`,
        height: `100%`,
        maxWidth: 'unset',
      }}
      className={css`
        .euiEmptyPrompt__main {
          height: 100%;
        }
      `}
      titleSize="s"
      title={<h4>No Role Selected</h4>}
    />
  );

  const roles: OptionsInfo<T>[] = Object.values(options);

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '400px 1fr',
        width: '100%',
        gap: theme.size.xl,
      }}
    >
      <FormFieldset
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: theme.size.s,
        }}
      >
        {roles.map((role: OptionsInfo<T>) => {
          const isCurrent = current?.toLowerCase() === role.name.toLowerCase() && current !== undefined;

          return (
            <CheckableCard
              key={String(role.id)}
              value={String(role.id)}
              checked={role.name === selected}
              onChange={() => {
                const roleName = role?.name as T;
                setSelected(roleName);
              }}
              id={`${String(role.id)}-role`}
              label={
                <Text size="s">
                  <h4 style={{ marginBottom: 0, marginTop: `-2px` }}>
                    {role.name} {isCurrent && `(currently assigned ${noun})`}
                  </h4>
                  <p>{role.description}</p>
                </Text>
              }
            />
          );
        })}
      </FormFieldset>
      <div>{selectedContent}</div>
    </div>
  );
};

export default RoleSelectorFormRow;
export type { OptionsInfo };
