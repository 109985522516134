import { useEffect, useState } from 'react';
import useGlobalState from '../../shared/useGlobalState';
import { logEvent } from '../../utils/analytics-utils';
import { useGlobalConfigs } from '../config/useGlobalConfig';
import { useOnboardingFlagsHook } from '../config/useOnboardingFlagsHook';
import useCallerIsAdmin from './useCallerIsAdmin';
/**
 *
 *  This is hook is responsible for initializing application data.
 *  Any data that the application needs before the application loads goes here.
 *  For example, global WebUI(backend call) configuration, Guided onboarding, workspace and ACL information.
 *  Tipically, data that is shared throughout the application.
 *  Once you get data from the backend, we use Redux to store our shared data.
 *  From there, use useGlobalState hook to get data the from Redux.
 */

const useInitializeApplication = () => {
  const [hasFinishedInitializing, setHasFinishedInitializing] = useState<boolean>(false);
  const [hasInitializingError, setHasInitializingError] = useState<boolean>(false);

  // Hooks to get data. The hooks below are wrappers for React-Query.
  // We don't have support none React-Query calls yet.
  const globalConfigState = useGlobalConfigs();
  const onboardingFlagsState = useOnboardingFlagsHook();
  const { clusterAdminInfoState } = useCallerIsAdmin();
  const { isGuidedOnboardingEnabled } = useGlobalState();

  // States to watch out for.
  // TODO: Do we need .data for checking? Let's find out later.
  const successStates = [
    globalConfigState.isSuccess,
    globalConfigState.data,
    onboardingFlagsState.isSuccess,
    onboardingFlagsState.data,
    clusterAdminInfoState.data,
    clusterAdminInfoState.isSuccess,
  ];

  const errorStates = [globalConfigState.isError, onboardingFlagsState.isError, clusterAdminInfoState.error];

  useEffect(() => {
    // Let's check to see if we loaded all the data
    if ([...successStates, isGuidedOnboardingEnabled !== null].every((value) => value)) {
      setHasFinishedInitializing(true);
      setHasInitializingError(false);
    }

    // If we get any errors from the list, we error out.
    if (errorStates.includes(true)) {
      setHasInitializingError(true);
      setHasFinishedInitializing(false);
      logEvent('WebUI Initialization failed');
    }
  }, [...successStates, ...errorStates, isGuidedOnboardingEnabled]);

  return { hasFinishedInitializing, hasInitializingError };
};

export default useInitializeApplication;
