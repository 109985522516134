import React from 'react';

function FeatureIcon() {
  return (
    <g>
      <rect
        opacity="0.44"
        x="2.83297"
        y="16.833"
        width="9.83405"
        height="3.33405"
        fill="#26D8EC"
        stroke="#26D8EC"
        strokeWidth="1.66595"
      />
      <rect
        opacity="0.8"
        x="2.83297"
        y="9.83291"
        width="18.3341"
        height="3.33405"
        fill="#26D8EC"
        stroke="#26D8EC"
        strokeWidth="1.66595"
      />
      <rect x="3.25" y="3.25" width="17.5" height="2.5" fill="#26D8EC" stroke="#26D8EC" strokeWidth="2.5" />
      <line x1="12.8329" y1="6.99994" x2="12.8329" y2="8.99994" stroke="#26D8EC" strokeWidth="1.66595" />
      <line x1="8.08297" y1="14" x2="8.08297" y2="16" stroke="#26D8EC" strokeWidth="1.66595" />
    </g>
  );
}

export default FeatureIcon;
