import React from 'react';

// This class uses the old React style because `componentDidCatch` is not supported by hooks.
export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    if (this.state.errorInfo) {
      var header;
      var content;
      if (this.state.error.isAuthError) {
        header = 'Authentication error';
        content = (
          <div>
            Please <a href="javascript:window.location.reload(true)">refresh</a> this page or re-login. If the problem
            persists, contact your Tecton Administrator or email{' '}
            <a href="mailto:support@tecton.ai">support@tecton.ai</a>.
          </div>
        );
      } else if (this.state.error.isInvalidWorkspaceError) {
        window.location = '/';
      } else {
        header = 'Something went wrong';
        content = (
          <div>
            Please <a href="javascript:window.location.reload(true)">refresh</a> this page or return{' '}
            <a href="/">home</a>. If the problem persists, contact your Tecton Administrator or email{' '}
            <a href="mailto:support@tecton.ai">support@tecton.ai</a>.
          </div>
        );
      }

      return (
        <div id="main" style={{ marginRight: '0' }}>
          <div id="content" className="content-shadow overflow-y-scroll h-100">
            <div className="content-head">
              <div>
                <div className="f3 fw7 mb3">{header}</div>
              </div>
            </div>
            <div className="pa5">
              <div className="f4 mb3">{content}</div>
            </div>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}
