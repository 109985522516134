export default () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.52883 10.413L10.4128 3.52898C10.6728 3.26898 11.0948 3.26898 11.3548 3.52898L12.4722 4.64632C12.7322 4.90632 12.7322 5.32832 12.4722 5.58832L5.5875 12.4717C5.46283 12.597 5.2935 12.667 5.11683 12.667H3.3335V10.8837C3.3335 10.707 3.4035 10.5377 3.52883 10.413Z"
        stroke="black"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.16699 4.77344L11.227 6.83344"
        stroke="black"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
