import { EmptyValue } from '../../components/@tecton';
import ConfigInFlyoutDisplay from '../../components/shared/ConfigInFlyoutDisplay';
import { FcoPropertyRenderingType } from '../../types/tecton_proto/args/diff_options';
import { FcoPropertyDiff, StateUpdatePlanSummaryDiff } from '../../types/tecton_proto/data/state_update';

import ChangedAttributeFlyoutContent from './ChangedAttributeFlyoutContent';

const PlanChangesColumn = ({
  diffs,
  row,
}: {
  diffs: FcoPropertyDiff[] | undefined;
  row: StateUpdatePlanSummaryDiff;
}) => {
  if (row.type === 'CREATE' || row.type === 'DELETE') {
    return <EmptyValue />;
  }

  if (!diffs) {
    return <span>none</span>;
  } else {
    const changesCount =
      diffs?.filter((diff) => diff.rendering_type !== FcoPropertyRenderingType.FCO_PROPERTY_RENDERING_TYPE_HIDDEN)
        ?.length ?? 0;
    return (
      <ConfigInFlyoutDisplay
        displayElement={
          <span>
            {changesCount} {changesCount > 1 ? <>Changes</> : <>Change</>}
          </span>
        }
        flyoutContent={<ChangedAttributeFlyoutContent fcoName={row.name} diffs={diffs} />}
      />
    );
  }
};

export default PlanChangesColumn;
