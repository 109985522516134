import React from 'react';
import { EuiButtonEmpty, EuiButtonEmptyProps } from '@elastic/eui';
import styled from '@emotion/styled';

type ButtonActionProps = EuiButtonEmptyProps & {
  buttonColor?: string;
  size?: 's' | 'm' | 'xs';
};

const ButtonWrapper = styled(EuiButtonEmpty)<ButtonActionProps>`
  color: ${({ buttonColor }) => buttonColor};
`;

export default ({ buttonColor, ...rest }: ButtonActionProps) => {
  return (
    <ButtonWrapper buttonColor={buttonColor} {...rest}>
      {rest.children}
    </ButtonWrapper>
  );
};
