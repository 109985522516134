import React, { Suspense } from 'react';
import '@elastic/eui/dist/eui_theme_light.css';
import { ThemeProvider } from '@mui/material';
import { MuiApplicationTheme } from './MuiTheme';
import InitializeApplication from './core/initialization/InitializeApplication';
import ApplicationRoutes from './core/initialization/ApplicationRoutes';
import ErrorBoundary from './components/ErrorBoundary';

import { ToastContextProvider } from './components/@tecton/ToastContext';
import GlobalToast from './components/@tecton/GlobalToast';
import { TectonThemeProvider } from './components/@tecton';
import styled from '@emotion/styled';

const Container = styled.div`
  background: #0c2636;
  display: flex;
  height: 100%;
  width: 100%;
`;
const WebUI = () => {
  return (
    <Container>
      <ErrorBoundary>
        <ToastContextProvider>
          <GlobalToast />
          <InitializeApplication>
            <ThemeProvider theme={MuiApplicationTheme}>
              <TectonThemeProvider>
                <Suspense>
                  <ApplicationRoutes />
                </Suspense>
              </TectonThemeProvider>
            </ThemeProvider>
          </InitializeApplication>
        </ToastContextProvider>
      </ErrorBoundary>
    </Container>
  );
};

export default WebUI;
