import React from 'react';

import { renderPlanActionTypeBadge } from './PlanActionTypeBadge';
import PlanChangesColumn from './PlanChangesColumn';
import { FcoPropertyDiff, StateUpdatePlanSummaryDiff } from '../../types/tecton_proto/data/state_update';
import { StateUpdatePlanSummaryType } from './PlanSummaryTransform';
import PlanEntityName from './PlanEntityName';

import { Table } from '@tecton';
import { useTableStyle } from '@shared';
import PlanCategoryAccordion from './PlanCategoryAccordion';
import {
  TableCommonActionColumn,
  TableCommonChangesColumn,
  TableCommonNameColumn,
  TableCommonTypeColumn,
  TableCommonMaterializationColumn,
  scrollIdMap,
  sortByActionType,
} from './util';

const PlanEntitiesTable = ({ stateUpdatePlanSummary }: { stateUpdatePlanSummary?: StateUpdatePlanSummaryType }) => {
  const tableStyleCss = useTableStyle();

  const entities =
    stateUpdatePlanSummary?.plan?.diff_items
      ?.filter((item) => {
        return item.fco_type === 'Entity';
      })
      ?.sort(sortByActionType) ?? [];

  if (entities.length === 0) {
    return <></>;
  }

  return (
    <PlanCategoryAccordion id={scrollIdMap['Entity']} title="Entities">
      <Table
        className={tableStyleCss}
        items={entities}
        columns={[
          {
            ...TableCommonActionColumn,
            render: renderPlanActionTypeBadge,
          },
          {
            ...TableCommonNameColumn,
            render: (name: string, row: StateUpdatePlanSummaryDiff) => {
              return <PlanEntityName workspace={stateUpdatePlanSummary?.plan?.workspace} stateUpdatePlanDiff={row} />;
            },
          },
          {
            ...TableCommonChangesColumn,
            render: (diffs: FcoPropertyDiff[], row: StateUpdatePlanSummaryDiff) => {
              return <PlanChangesColumn diffs={diffs} row={row} />;
            },
          },
          {
            ...{ ...TableCommonTypeColumn, name: '' },
            render: (fco_type: string) => {
              return <></>;
            },
          },
          {
            ...{ ...TableCommonMaterializationColumn, name: '' },
            render: (entry: StateUpdatePlanSummaryDiff) => {
              return <></>;
            },
          },
        ]}
      />
    </PlanCategoryAccordion>
  );
};

export default PlanEntitiesTable;
