import React from 'react';
import { Link } from 'react-router-dom';
import UrlUtils from '../utils/url-utils';
import { ReactComponent as EntityIcon } from '../assets/image/tecton-ui/icon/entity-color.svg';
import { ReactComponent as FeatureServiceIcon } from '../assets/image/tecton-ui/icon/services-color.svg';
import { ReactComponent as DataSourceIcon } from '../assets/image/tecton-ui/icon/data-sources-color.svg';
import { ReactComponent as FeatureViewIcon } from '../assets/image/tecton-ui/icon/feature-view-color.svg';
import { ReactComponent as FeatureIcon } from '../assets/image/tecton-ui/icon/feature-color.svg';
import { ReactComponent as TransformationIcon } from '../assets/image/tecton-ui/icon/transform-color.svg';
import { ReactComponent as DatasetIcon } from '../assets/image/tecton-ui/icon/datasets-color.svg';
import { ReactComponent as ArchivedFeatureServiceIcon } from '../assets/image/tecton-ui/icon/services-mono.svg';
import { ReactComponent as ArchivedFeatureViewIcon } from '../assets/image/tecton-ui/icon/feature-view-mono.svg';
import Tags from './Tags';

export const FirstClassObjectTypes = {
  ENTITY: 'entity',
  DATA_SOURCE: 'data_source',
  FEATURE_VIEW: 'feature_view',
  FEATURE: 'feature',
  FEATURE_SERVICE: 'feature_service',
  TRANSFORMATION: 'transformation',
  SAVED_FEATURE_DATAFRAME: 'saved_feature_dataframe',
  ARCHIVED_FEATURE_VIEW: 'archived_feature_view',
  ARCHIVED_FEATURE_SERVICE: 'archived_feature_service',
};

export const FirstClassObjectHumanReadable = {
  entity: 'Entity',
  data_source: 'Data Source',
  feature_view: 'Feature View',
  feature: 'Feature',
  feature_service: 'Feature Service',
  transformation: 'Transformation',
  saved_feature_dataframe: 'Dataset',
  archived_feature_view: 'Archived Feature View',
  archived_feature_service: 'Archived Feature Service',
};

export const FirstClassObjectIcon = ({ type }) => {
  switch (type) {
    case FirstClassObjectTypes.ENTITY:
      return <EntityIcon />;
    case FirstClassObjectTypes.DATA_SOURCE:
      return <DataSourceIcon />;
    case FirstClassObjectTypes.FEATURE_VIEW:
      return <FeatureViewIcon />;
    case FirstClassObjectTypes.FEATURE:
      return <FeatureIcon />;
    case FirstClassObjectTypes.FEATURE_SERVICE:
      return <FeatureServiceIcon />;
    case FirstClassObjectTypes.TRANSFORMATION:
      return <TransformationIcon />;
    case FirstClassObjectTypes.SAVED_FEATURE_DATAFRAME:
      return <DatasetIcon />; // TODO: use correct icon
    case FirstClassObjectTypes.ARCHIVED_FEATURE_VIEW:
      return <ArchivedFeatureViewIcon />;
    case FirstClassObjectTypes.ARCHIVED_FEATURE_SERVICE:
      return <ArchivedFeatureServiceIcon />;
    default:
      // eslint-disable-next-line no-throw-literal
      throw `unknown fco type: ${type}`;
  }
};

export const FirstClassObject = ({ name, itemTitle, description, tags, path, type, featureType, workspace }) => {
  var archived =
    type === FirstClassObjectTypes.ARCHIVED_FEATURE_VIEW || type === FirstClassObjectTypes.ARCHIVED_FEATURE_SERVICE;

  // itemTitle is an optional override of name for the text displayed
  itemTitle = itemTitle ? itemTitle : name;

  if (path) {
    const finalPath = UrlUtils.buildPath(path, {
      name: name,
      workspace: workspace,
      tab: '',
    });

    return (
      <Link className="flex items-center" to={finalPath}>
        <div>
          <FirstClassObjectIcon type={type} />
        </div>
        <div className="ml3">
          <div className="f5 fw6 owa">{itemTitle}</div>
          <div className="color-text-700">{description}</div>
          <div className="mt1 ">
            {featureType ? (
              <span key={featureType} className="f7 bw-1 b-solid pl1 pr1 nowrap dib mr1">
                {featureType}
              </span>
            ) : null}
            <Tags tags={tags} showPlaceholderWhenEmpty={false} />
          </div>
        </div>
      </Link>
    );
  } else {
    return (
      <div className="flex items-center">
        <div>
          <FirstClassObjectIcon type={type} />
        </div>
        <div className="ml3">
          <div className={'f5 fw6 ' + (archived ? 'color-text-500' : '')}>{itemTitle}</div>
          <div className={archived ? 'color-text-500' : 'color-text-700'}>{description}</div>
        </div>
      </div>
    );
  }
};
