import React, { FC } from 'react';
import Ansi from 'ansi-to-react';
import styled from '@emotion/styled';
import { useTectonTheme } from '@tecton';

interface CommandLineOutputProps {
  planOutputString: string;
  preWrap?: boolean;
}

const CommandLineOutput: FC<CommandLineOutputProps> = ({ planOutputString, preWrap }) => {
  const { theme } = useTectonTheme();

  const StyledPre = styled.pre<{ preWrap?: boolean }>`
    white-space: ${(props) => (props.preWrap !== false ? 'pre-wrap' : 'pre')};
    background: ${theme.colors.fullShade};
    color: ${theme.colors.lightestShade};
    font-family: ${theme.font.familyCode};
    padding: ${theme.size.m};
    line-height: 1.1;
    width: 100%;
  `;

  return (
    <StyledPre preWrap={preWrap} className={!preWrap ? 'eui-xScroll' : ''}>
      <Ansi>{planOutputString}</Ansi>
    </StyledPre>
  );
};

export default CommandLineOutput;
