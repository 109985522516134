import { handleActions } from 'redux-actions';
import { getCurrentUserAction, setAuthAction, setCallerIsAdmin } from './actions';

const state = {
  user: null,
  didFetchUser: false,
  auth: null,
};

export const reducer = handleActions(
  {
    [getCurrentUserAction.success]: (state, action) => {
      return {
        ...state,
        user: action.payload,
        didFetchUser: true,
      };
    },
    [getCurrentUserAction.failure]: (state, action) => {
      return {
        ...state,
        user: null,
        didFetchUser: true,
      };
    },
    [setAuthAction]: (state, action) => {
      return {
        ...state,
        auth: action.payload,
      };
    },
    [setCallerIsAdmin.success]: (state, action) => {
      const { user } = state;
      const copyUser = { ...user, isAdmin: action.payload.callerIsAdmin };

      return {
        ...state,
        user: copyUser,
      };
    },
  },
  state
);
