import React, { FC } from 'react';
import {
  AnyFCO,
  DataSourceFCO,
  DataSourceFCOFields,
  EntityFCO,
  EntityFCOFields,
  FCOFields,
  FCOType,
  FeatureServiceFCO,
  FeatureServiceFCOFields,
  FeatureViewFCO,
  FeatureViewFCOFields,
  TransformationFCO,
  TransformationFCOFields,
} from '../../core/types/fcoTypes';
// eslint-disable-next-line tecton/prevent-eui-import
import { EuiBasicTableColumn } from '@elastic/eui';
import { EmptyTab, PageLoading, Table } from '../@tecton';
import { FCO_TYPES } from '../../service/Fco';
import FCONameDisplayWithIconAndTooltip from '../@tecton/FCONameDisplayWithIconAndTooltip';
import FeatureViewTypeBadge from '../@tecton/FeatureViewTypeBadge';
import EnabledDisabledMaterializationBadge from '../@tecton/EnabledDisabledMaterializationBadge';
import { timeFormatter } from '../fcoListView/utils';
import FCOOwnerBadge from '../fcoListView/FCOOwnerBadge';

interface RelatedFeatureViewsProps {
  fco: AnyFCO;
  featureViews: FeatureViewFCO[];
}

const RelatedFeatureViews: FC<RelatedFeatureViewsProps> = ({ fco, featureViews }) => {
  const columns: EuiBasicTableColumn<{ fv: FeatureViewFCO }>[] = [
    {
      name: 'Feature View',
      field: 'fv',
      render: (fv: FeatureViewFCO) => {
        return <FCONameDisplayWithIconAndTooltip fco={fv} />;
      },
    },
    {
      name: 'Type',
      field: 'fv',
      render: (fv: FeatureViewFCO) => {
        return <FeatureViewTypeBadge type={fv[FeatureViewFCOFields.FEATURE_VIEW_TYPE]} />;
      },
    },
    {
      name: 'Online',
      field: 'fv',
      render: (fv: FeatureViewFCO) => {
        return (
          <EnabledDisabledMaterializationBadge
            mode={'online'}
            value={fv[FeatureViewFCOFields.IS_ONLINE_MATERIALIZATION_ENABLED]}
          />
        );
      },
    },
    {
      name: 'Offline',
      field: 'fv',
      render: (fv: FeatureViewFCO) => {
        return (
          <EnabledDisabledMaterializationBadge
            mode={'offline'}
            value={fv[FeatureViewFCOFields.IS_OFFLINE_MATERIALIZATION_ENABLED]}
          />
        );
      },
    },
    {
      name: 'Last Updated By',
      field: 'fv',
      render: (fv: FeatureViewFCO) => {
        return <>{fv[FCOFields.LAST_MODIFIED_BY]}</>;
      },
    },

    {
      name: 'Last Updated',
      field: 'fv',
      render: (fv: FeatureViewFCO) => {
        return <>{fv.createdDate ? timeFormatter(fv.createdDate!) : <></>}</>;
      },
    },

    {
      name: 'Owner',
      field: 'fv',
      render: (fv: FeatureViewFCO) => {
        return <FCOOwnerBadge fco={fv} />;
      },
    },
    {
      name: 'Created',
      field: 'fv',
      render: (fv: FeatureViewFCO) => {
        return <>{fv.createdDate ? timeFormatter(fv.createdDate!) : <></>}</>;
      },
    },
  ];

  let relatedFvIds: string[] = [];
  const items: { fv: FeatureViewFCO }[] = [];

  if (fco[FCOFields.FCO_TYPE] === FCOType.FEATURE_SERVICE) {
    relatedFvIds = (fco as FeatureServiceFCO)[FeatureServiceFCOFields.ALL_FEATURE_VIEWS];
  }

  if (fco[FCOFields.FCO_TYPE] === FCOType.TRANSFORMATION) {
    relatedFvIds = (fco as TransformationFCO)[TransformationFCOFields.DEPENDENT_FEATURE_VIEWS];
  }

  if (fco[FCOFields.FCO_TYPE] === FCOType.ENTITY) {
    relatedFvIds = (fco as EntityFCO)[EntityFCOFields.DEPENDENT_FEATURE_VIEWS];
  }

  if (fco[FCOFields.FCO_TYPE] === FCOType.DATA_SOURCE) {
    relatedFvIds = (fco as DataSourceFCO)[DataSourceFCOFields.DEPENDENT_FEATURE_VIEWS];
  }

  relatedFvIds.forEach((id) => {
    const matchingFV = featureViews.find((featureView) => featureView.id === id);
    if (matchingFV) {
      items.push({ fv: matchingFV });
    }
  });

  if (items.length === 0) {
    return <EmptyTab title={'No Feature Views'} message={<>{fco.name} is not used by any Feature Views.</>} />;
  }

  return <Table items={items} columns={columns} layout="auto" />;
};

interface RelatedFeatureServicesProps {
  fco: AnyFCO;
  featureServices: FeatureServiceFCO[];
}

const RelatedFeatureServices: FC<RelatedFeatureServicesProps> = ({ fco, featureServices }) => {
  const columns: EuiBasicTableColumn<{ fs: FeatureServiceFCO }>[] = [
    {
      name: 'Feature Service',
      field: 'fs',
      render: (fs: FeatureServiceFCO) => {
        return <FCONameDisplayWithIconAndTooltip fco={fs} />;
      },
    },
    {
      name: 'Last Updated By',
      field: 'fs',
      render: (fs: FeatureServiceFCO) => {
        return <>{fs[FCOFields.LAST_MODIFIED_BY]}</>;
      },
    },

    {
      name: 'Last Updated',
      field: 'fs',
      render: (fs: FeatureServiceFCO) => {
        return <>{fs.createdDate ? timeFormatter(fs.createdDate!) : <></>}</>;
      },
    },

    {
      name: 'Owner',
      field: 'fs',
      render: (fs: FeatureServiceFCO) => {
        return <FCOOwnerBadge fco={fs} />;
      },
    },
    {
      name: 'Created',
      field: 'fs',
      render: (fs: FeatureServiceFCO) => {
        return <>{fs.createdDate ? timeFormatter(fs.createdDate!) : <></>}</>;
      },
    },
  ];

  const items: { fs: FeatureServiceFCO }[] = [];

  (fco as FeatureViewFCO)[FeatureViewFCOFields.FEATURE_SERVICES].forEach((id) => {
    const matchingFS = featureServices.find((featureService) => featureService.id === id);
    if (matchingFS) {
      items.push({ fs: matchingFS });
    }
  });

  if (items.length === 0) {
    return <EmptyTab title={'No Feature Services'} message={<>{fco.name} is not used by any Feature Services.</>} />;
  }

  return <Table items={items} columns={columns} layout="auto" />;
};

interface FCODetailsRelatedFCOTabProps {
  fco: AnyFCO;
  featureServices: FeatureServiceFCO[];
  featureViews: FeatureViewFCO[];
  isLoading: boolean;
}

const FCODetailsRelatedFCOTab: FC<FCODetailsRelatedFCOTabProps> = ({
  fco,
  featureServices,
  featureViews,
  isLoading,
}) => {
  if (isLoading) {
    return <PageLoading />;
  }

  if (fco[FCOFields.FCO_TYPE] === FCO_TYPES.FEATURE_VIEW) {
    return <RelatedFeatureServices fco={fco} featureServices={featureServices} />;
  }

  return <RelatedFeatureViews fco={fco} featureViews={featureViews} />;
};

export default FCODetailsRelatedFCOTab;
