export default () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.41667 6.66675V13.3334"
        stroke="#525866"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <ellipse
        cx="14.5833"
        cy="4.58333"
        rx="2.08333"
        ry="2.08333"
        stroke="#525866"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <circle
        cx="5.41683"
        cy="15.4166"
        r="2.08333"
        stroke="#525866"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <ellipse
        cx="5.41683"
        cy="4.58333"
        rx="2.08333"
        ry="2.08333"
        stroke="#525866"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.5837 6.66675V6.66675C14.5837 8.5077 13.0913 10.0001 11.2503 10.0001H8.75033C6.90938 10.0001 5.41699 11.4925 5.41699 13.3334V13.3334"
        stroke="#525866"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
