import React from 'react';

import { renderPlanActionTypeBadge } from './PlanActionTypeBadge';
import PlanChangesColumn from './PlanChangesColumn';
import { FcoPropertyDiff, StateUpdatePlanSummaryDiff } from '../../types/tecton_proto/data/state_update';
import PlanDataSourceName from './PlanDataSourceName';
import { StateUpdatePlanSummaryType } from './PlanSummaryTransform';
import { Table } from '@tecton';
import { useTableStyle } from '@shared';
import PlanApplyDataSourceBadge from './PlanApplyDataSourceBadge';
import PlanCategoryAccordion from './PlanCategoryAccordion';
import {
  TableCommonActionColumn,
  TableCommonTypeColumn,
  TableCommonNameColumn,
  TableCommonChangesColumn,
  TableCommonMaterializationColumn,
  scrollIdMap,
  sortByActionType,
} from './util';

const PlanDataSourceTable = ({ stateUpdatePlanSummary }: { stateUpdatePlanSummary?: StateUpdatePlanSummaryType }) => {
  const tableStyleCss = useTableStyle();
  const DSKeys = ['Stream Data Source', 'Batch Data Source', 'Push Source'];

  const dataSources =
    stateUpdatePlanSummary?.plan?.diff_items
      ?.filter((item) => {
        return DSKeys.indexOf(item?.fco_type ?? '') >= 0;
      })
      ?.sort(sortByActionType) ?? [];

  if (dataSources.length === 0) {
    return <></>;
  }

  return (
    <PlanCategoryAccordion id={scrollIdMap['Data Source']} title="Data Sources">
      <Table
        className={tableStyleCss}
        items={dataSources}
        columns={[
          {
            ...TableCommonActionColumn,
            render: renderPlanActionTypeBadge,
          },
          {
            ...TableCommonNameColumn,

            render: (name: string, row: StateUpdatePlanSummaryDiff) => {
              return (
                <PlanDataSourceName workspace={stateUpdatePlanSummary?.plan?.workspace} stateUpdatePlanDiff={row} />
              );
            },
          },

          {
            ...TableCommonChangesColumn,
            render: (diffs: FcoPropertyDiff[], row: StateUpdatePlanSummaryDiff) => {
              return <PlanChangesColumn diffs={diffs} row={row} />;
            },
          },
          {
            ...TableCommonTypeColumn,
            render: (fco_type: string) => {
              return <PlanApplyDataSourceBadge type={fco_type} />;
            },
          },
          {
            ...{ ...TableCommonMaterializationColumn, name: '' },
            render: (entry: StateUpdatePlanSummaryDiff) => {
              return <></>;
            },
          },
        ]}
      />
    </PlanCategoryAccordion>
  );
};

export default PlanDataSourceTable;
