// ChartJS plugin registration
// TODO: Remove this file once we migrate out of Chart.js
import { Chart as ChartJS, Interaction, registerables } from 'chart.js';
import 'chartjs-adapter-moment';
import { CrosshairPlugin, Interpolate } from 'chartjs-plugin-crosshair';

// Fix redraw error.
const CustomCrosshairPlugin = function (plugin) {
  const originalAfterDraw = plugin.afterDraw;
  plugin.afterDraw = function (chart, easing) {
    if (chart && chart.crosshair) {
      originalAfterDraw.call(this, chart, easing);
    }
  };
  return plugin;
};

const useChartJsRegister = () => {
  // Register plugins to Chart.js
  ChartJS.register(...registerables, CustomCrosshairPlugin(CrosshairPlugin), {
    id: 'emptyChart',
    afterDraw: function (chart) {
      if (chart.data.datasets.every((item) => item === 0)) {
        const ctx = chart.ctx;
        const width = chart.width;
        const height = chart.height;
        //chart.clear();
        ctx.save();
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText('No data', width / 2, height / 2);
        ctx.restore();
      }
    },
  });
  Interaction.modes.interpolate = Interpolate;
};

export default useChartJsRegister;
