import React, { FC } from 'react';
import { FCOTag } from '../../../core/types/fcoTypes';

import { Icons, IconTypes, ItemSetPopover, FCOTagBadge } from '@tecton/ComponentRedesign';
interface RelatedTagsProps {
  tags: FCOTag[];
}

const RelatedTags: FC<RelatedTagsProps> = ({ tags }) => {
  const tagItems = tags.map((tag) => {
    return <FCOTagBadge tag={tag} />;
  });

  const icon = Icons[IconTypes.TAG];

  return <ItemSetPopover items={tagItems} icon={icon} />;
};

export default RelatedTags;
