import React, { useState } from 'react';
import { EuiButtonEmpty, EuiDualRange, EuiDualRangeProps, EuiFormRow, EuiPanel } from '@elastic/eui';
import PopoverSelectorFooter from '../shared/filter_selectors/PopoverSelectorFooter';
import _debounce from 'lodash/debounce';

export interface NumericSelectorProps {
  min?: number;
  max?: number;
  step?: number;
  label: string;
  selectorValue: [string | number, string | number];
  updateSelectorValue: (range: EuiDualRangeProps['value']) => void;
  reset: () => void;
}

const NumericSelector = ({
  label,
  min = 1,
  max = 10,
  step,
  selectorValue,
  updateSelectorValue,
  reset,
}: NumericSelectorProps) => {
  const [localValue, setLocalValue] = useState(selectorValue);

  const debounceUpdate = _debounce(updateSelectorValue, 500);

  const handleSliderChange = (values: [string | number, string | number]) => {
    setLocalValue(values);
    debounceUpdate(values);
  };
  return (
    <>
      <EuiPanel paddingSize="m" hasShadow={false}>
        <EuiFormRow label={label}>
          <EuiDualRange
            min={min}
            max={max}
            step={step || 1}
            value={localValue}
            onChange={handleSliderChange}
            showInput
            aria-label={label}
          />
        </EuiFormRow>
      </EuiPanel>
      <PopoverSelectorFooter>
        <EuiButtonEmpty size="s" onClick={reset}>
          Reset
        </EuiButtonEmpty>
      </PopoverSelectorFooter>
    </>
  );
};

export default NumericSelector;
