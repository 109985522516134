export default () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.7348 17.5001H8.67564C7.70064 17.5001 6.91064 16.7101 6.91064 15.7351V8.67589C6.91064 7.70089 7.70064 6.91089 8.67564 6.91089H15.7348C16.7098 6.91089 17.4998 7.70089 17.4998 8.67589V15.7351C17.4998 16.7101 16.7098 17.5001 15.7348 17.5001Z"
        stroke="#525866"
        stroke-width="1.5"
      />
      <path d="M2.5 7.79419C2.5 10.4167 4.40917 12.5884 6.91167 13.0092" stroke="#525866" stroke-width="1.5" />
      <path
        d="M13.0092 6.91167C12.5883 4.40917 10.4167 2.5 7.79417 2.5C4.87 2.5 2.5 4.87 2.5 7.79417"
        stroke="#525866"
        stroke-width="1.5"
      />
    </svg>
  );
};
