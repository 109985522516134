import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { Code, DescriptionList, FieldText, FormRow, Icon, Link, Spacer, Text, ButtonIcon } from '@tecton';
import { css } from '@emotion/css';

import ButtonRow from '../shared/ButtonRow';
import ACLSectionSubhead from './ACLSectionSubhead';
import DeleteAttributeConfirmationModal from './modals/DeleteAttributeConfirmationModal';
import { ACLGroupModal } from './aclUtils';
import { useUserSettings } from '../context/UserSettingsContext';
import { ACLGroupProfileContextGQL } from './ACLGroupProfileContextGQL';

const EditMode = ({
  initialValue,
  isSaving,
  cancel,
  saveChange,
}: {
  initialValue: string | null;
  isSaving: boolean;
  cancel: () => void;
  saveChange: (newValue: string) => void;
}) => {
  const [value, setValue] = useState<string | null>(initialValue);

  const isInvalid = value === ''; // Only invalid if it is an empty string. A fresh empty field would be null here.
  const isDisabled = isInvalid || isSaving || value === 'null';
  const reset = () => {};

  return (
    <FormRow isInvalid={isInvalid} error={[]}>
      <FieldText
        compressed
        value={value || ''}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          reset();
          setValue(e.target.value);
        }}
        onKeyUp={(ev) => {
          if (value && ev.key.toLowerCase() === 'enter') {
            saveChange(value);
          }
        }}
        isLoading={isSaving}
        isInvalid={isInvalid}
        append={[
          <ButtonIcon
            iconType="check"
            isDisabled={isDisabled}
            onClick={() => {
              if (value) {
                saveChange(value);
              } else {
                setValue('');
              }
            }}
            aria-label="Save"
          />,
          <ButtonIcon
            onClick={() => {
              cancel();
            }}
            aria-label="Cancel"
            iconType="cross"
            color="danger"
          />,
        ]}
      />
    </FormRow>
  );
};

interface ACLGroupIdentityProviderAttributesProps {
  groupName: string;
  attributes: string[];
}

type EditableAttributeType = {
  value: string | null;
  isEditing: boolean;
  isSaving: boolean;
};

const useAttributesEditState = (initialAttributeState: EditableAttributeType[]) => {
  const [entries, setEntries] = useState<EditableAttributeType[]>(initialAttributeState);

  const addEntry = () => {
    const newEntries = entries.slice(0);
    newEntries.push({
      value: null,
      isEditing: true,
      isSaving: false,
    });
    setEntries(newEntries);
  };

  const toggleEditMode = (key: number) => {
    const newEntries = entries.slice(0);

    if (newEntries[key]) {
      newEntries[key].isEditing = true;
      setEntries(newEntries);
    }
  };

  const setIsSaving = (key: number) => {
    const newEntries = entries.slice(0);

    if (newEntries[key]) {
      newEntries[key].isSaving = true;
      setEntries(newEntries);
    }
  };

  const cancel = (key: number) => {
    const entry = entries[key];

    if (!entry) {
      return;
    }

    const newEntries = entries.slice(0);

    if (entry.value !== null) {
      newEntries[key].isEditing = false;

      setEntries(newEntries);
    } else {
      newEntries.splice(key, 1);

      setEntries(newEntries);
    }
  };

  return {
    entries,
    setEntries,
    addEntry,
    setIsSaving,
    toggleEditMode,
    cancel,
  };
};

const ACLGroupIdentityProviderAttributesGQL = ({ groupName, attributes }: ACLGroupIdentityProviderAttributesProps) => {
  const { isAdmin } = useUserSettings();
  const groupProfileContext = useContext(ACLGroupProfileContextGQL);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [selectedAttributeSelected, setSelectedAttributeSelected] = useState<string | null>();

  const { entries, setEntries, addEntry, setIsSaving, toggleEditMode, cancel } = useAttributesEditState(
    attributes.map((a) => {
      return {
        value: a,
        isEditing: false,
        isSaving: false,
      };
    })
  );

  useEffect(() => {
    setEntries(
      attributes.map((a) => {
        return {
          value: a,
          isEditing: false,
          isSaving: false,
        };
      })
    );
  }, [attributes, setEntries, isUpdating]);

  const addAttributeButton = entries.map((i) => i.isEditing).includes(true) ? (
    <></>
  ) : (
    <Link onClick={addEntry} disabled={!isAdmin} title={isAdmin ? '' : 'Administrator Privileges Required'}>
      <Icon type="plusInCircleFilled" /> Add Attribute
    </Link>
  );

  let deleteAttributeConfirmationModal;

  if (groupProfileContext?.modal === ACLGroupModal.REMOVE_ATTRIBUTE) {
    const confirmChanges = async () => {
      groupProfileContext?.removeAttributes?.call(groupProfileContext, selectedAttributeSelected as string, () => {
        setIsUpdating(false);
      });
    };

    deleteAttributeConfirmationModal = (
      <DeleteAttributeConfirmationModal
        attributeValue={selectedAttributeSelected as string}
        groupName={groupName}
        onClose={() => {
          groupProfileContext?.closeModal?.call(groupProfileContext);
          setSelectedAttributeSelected(null);
        }}
        isLoading={groupProfileContext?.isModalLoading ?? false}
        errorMessages={groupProfileContext.errorMessages}
        confirmChanges={confirmChanges}
      />
    );
  }

  const noAttributes = (
    <Text size="s">
      <p>
        Tecton can automatically add users to groups based on their identity provider (IdP) attributes. There are no
        attributes set up for this group.{' '}
      </p>
      <ButtonRow>{addAttributeButton}</ButtonRow>
    </Text>
  );

  return (
    <div>
      <ACLSectionSubhead size="xs">
        <h4>Identity Provider Groups Mapping</h4>
      </ACLSectionSubhead>

      {entries.length > 0 ? (
        <>
          {attributes.length > 0 ? (
            <Text size="s">
              <p>
                The set of Identity Provider's SAML {`"`}groups{`"`} attribute values; if a user has any, then Tecton
                will automatically add them to this group. See the{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://docs.tecton.ai/docs/setting-up-tecton/administration-setup/user-management-and-access-controls/#user-management-using-an-identity-provider"
                >
                  documentation
                </a>{' '}
                for more details.
              </p>
            </Text>
          ) : (
            noAttributes
          )}

          <Spacer size="m" />
          <DescriptionList
            type="column"
            className={css`
              width: 100%;

              dt {
                flex-basis: 51%;
                flex-grow: 1;
                inline-size: max-content;
                padding-inline-end: 0;
              }

              dd {
                flex-grow: 0;
                inline-size: auto;
                padding-inline-start: 0;
              }
            `}
            listItems={entries.map((entry, index) => {
              return {
                title: !entry.isEditing ? (
                  <Code>{entry.value}</Code>
                ) : (
                  <EditMode
                    initialValue={entry.value}
                    cancel={() => {
                      cancel(index);
                    }}
                    isSaving={entry.isSaving}
                    saveChange={(newValue: string) => {
                      setIsSaving(index);
                      setIsUpdating(true);
                      if (entry.value === null) {
                        groupProfileContext?.addAttributes?.call(groupProfileContext, newValue, () => {
                          setIsUpdating(false);
                        });
                      } else {
                        groupProfileContext?.updateAttributes?.call(groupProfileContext, entry?.value, newValue, () => {
                          setIsUpdating(false);
                        });
                      }
                    }}
                  />
                ),
                description:
                  !entry.isEditing && isAdmin ? (
                    <>
                      <ButtonIcon
                        size="xs"
                        iconType="pencil"
                        onClick={() => {
                          toggleEditMode(index);
                        }}
                        aria-label="edit"
                      />
                      <ButtonIcon
                        size="xs"
                        iconType="trash"
                        color="danger"
                        onClick={() => {
                          groupProfileContext?.showDeleteAttributeModal?.call(groupProfileContext);
                          setSelectedAttributeSelected(entry.value);
                        }}
                        aria-label="delete"
                      />
                    </>
                  ) : (
                    <></>
                  ),
              };
            })}
          />
          <Spacer size="m" />
          {isAdmin && <ButtonRow>{addAttributeButton}</ButtonRow>}
          {deleteAttributeConfirmationModal}
        </>
      ) : (
        noAttributes
      )}
    </div>
  );
};

export default ACLGroupIdentityProviderAttributesGQL;
