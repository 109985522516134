import React, { ChangeEvent, useContext, useState } from 'react';
import { EuiBasicTableColumn, Button, DebouncedFieldSearch, Link, Panel, Spacer, LoadingContent } from '@tecton';
import { TectonBasicTable } from '@shared';
import AddWorkspaceAccessModal from './modals/AddWorkspaceAccessModal';
import { useNavigate, useParams } from 'react-router-dom';
import { ConfigurationUserModal } from './configuration-utils';
import { ConfigurationContext } from './ConfigurationContextProvider';
import { UserSettingsContext } from '../context/UserSettingsContext';
import { ACLRoleWithInheritance } from '../acl/types';
import ModifyWorkspaceRoleModal from '../acl/modals/ModifyWorkspaceRoleModal';
import ColumnNameWithHelp from '../shared/ColumnNameWithHelp';
import AllRolesPopover from '../acl/AllRolesPopover';
import TableActionButtonWithDisableMode from '../acl/TableActionButtonWithDisableMode';
import { ACLTableControlLayout } from '../acl/modals/ACLLayout';
import { UserAccessContext } from './UserAccessContextProvider';
import { Routes } from '../../core/routes';

export type UserPermissionRow = {
  userId: string;
  role: ACLRoleWithInheritance;
  group?: string;
};

const UsersWithPermissions = () => {
  const navigate = useNavigate();
  const configurationContext = useContext(ConfigurationContext);
  const userSettingsContext = useContext(UserSettingsContext);

  const userAccessContext = useContext(UserAccessContext);
  const { workspace } = useParams();

  let addUserModal;

  const [currentRole, setCurrentRole] = useState<string | undefined>(userAccessContext?.selectedRole);

  const hasWorkspaceManagerAccess = userSettingsContext?.hasWorkspaceManagerAccess;
  const isAdmin = userSettingsContext?.isAdmin;

  if (userAccessContext?.modal === ConfigurationUserModal.ADD_USER) {
    const saveChanges = async () => {
      userAccessContext?.assignUserToWorkspace();
    };

    addUserModal = (
      <AddWorkspaceAccessModal
        workspaceId={workspace ?? ''}
        allRoleRecords={configurationContext?.allUserRoleRecords ?? []}
        principalType={'User'}
        principalsList={configurationContext?.userCandidates ?? []}
        selectedPrincipal={userAccessContext?.selectedPrincipal}
        setSelectedPrincipal={userAccessContext?.setSelectedPrincipal}
        roleOptions={configurationContext?.newServiceAccountRoleOptions}
        selectedRole={userAccessContext?.selectedRole}
        setSelectedRole={userAccessContext?.setSelectedRole}
        isFetching={configurationContext?.isLoading ?? false}
        isLoading={userAccessContext?.isModalLoading}
        errorMessages={userAccessContext.modalError}
        saveChanges={saveChanges}
        cancel={() => {
          userAccessContext?.setModal(undefined);
        }}
      />
    );
  }

  let modifyUserModal;

  if (userAccessContext?.modal === ConfigurationUserModal.MODIFY_ACCESS) {
    const modifyUserRoleCallback = async () => {
      userAccessContext?.updateUserRole();
    };

    modifyUserModal = (
      <ModifyWorkspaceRoleModal
        workspaceId={workspace ?? ''}
        allRoleRecords={configurationContext?.allUserRoleRecords ?? []}
        userId={userAccessContext?.selectedPrincipal[0]?.label ?? ''}
        roleOptions={configurationContext?.existingServiceAccountOptions ?? []}
        cancel={() => {
          userAccessContext?.setModal(undefined);
          setCurrentRole(undefined);
        }}
        confirmChanges={modifyUserRoleCallback}
        currentRole={currentRole}
        selectedRole={userAccessContext?.selectedRole}
        setSelectedRole={userAccessContext?.setSelectedRole}
        isLoading={userAccessContext?.isModalLoading}
        errorMessages={userAccessContext?.modalError}
      />
    );
  }

  const columnsWithActionAccess: EuiBasicTableColumn<UserPermissionRow>[] = [
    {
      field: 'userId',
      name: 'Email',
      render: (field: string) => {
        const emailNode = isAdmin ? (
          <Link
            onClick={() => {
              navigate(`${Routes.accountsAndAccessUsers}/${field}`);
            }}
          >
            {field}
          </Link>
        ) : (
          <>{field}</>
        );
        return emailNode;
      },
    },
    {
      field: 'role',
      name: (
        <ColumnNameWithHelp
          name={'Role'}
          helpContent="A user's role can be directly assigned, inherited from a group, or set via their default workspace role. If there are multiple roles, Tecton will use the highest role as the effective role, as shown in this column."
        />
      ),

      render: (role: ACLRoleWithInheritance, row: UserPermissionRow) => {
        return <AllRolesPopover roleWithInheritance={role} />;
      },
    },
    {
      name: 'Actions',
      actions: [
        {
          name: 'Edit',
          isPrimary: true,
          onClick: () => {},
          render: (row: UserPermissionRow) => {
            return (
              <TableActionButtonWithDisableMode
                iconType={'pencil'}
                isDisabled={false}
                enabledMessage={'Edit the role of this user'}
                disabledMessage={'Insufficient permissions: must be admin or a workspace owner or to edit role.'}
                onClick={() => {
                  setCurrentRole(row.role.effectiveWorkspaceRole);
                  userAccessContext?.setSelectedRole(row.role.effectiveWorkspaceRole);
                  userAccessContext?.setSelectedPrincipal([{ label: row?.userId }]);
                  userAccessContext?.setModal(ConfigurationUserModal.MODIFY_ACCESS);
                }}
              />
            );
          },
        },
      ],
    },
  ];

  const columnsWithoutActionAccess: EuiBasicTableColumn<UserPermissionRow>[] = [
    {
      field: 'userId',
      name: 'Email',
      render: (field: string) => {
        const emailNode = isAdmin ? (
          <Link
            onClick={() => {
              navigate(`${Routes.accountsAndAccessUsers}/${field}`);
            }}
          >
            {field}
          </Link>
        ) : (
          <>{field}</>
        );
        return emailNode;
      },
    },
    {
      field: 'role',
      name: 'Role',
      render: (role: ACLRoleWithInheritance, row: UserPermissionRow) => {
        return role.effectiveWorkspaceRole;
      },
    },
    {
      name: 'Actions',
      actions: [
        {
          name: 'Edit',
          isPrimary: true,
          onClick: () => {},
          render: (row: UserPermissionRow) => {
            return (
              <TableActionButtonWithDisableMode
                iconType={'pencil'}
                isDisabled={!hasWorkspaceManagerAccess ?? true}
                enabledMessage={'Edit the role of this user'}
                disabledMessage={'Insufficient permissions: must be admin or a workspace owner or to edit role.'}
                onClick={() => {
                  setCurrentRole(row.role.effectiveWorkspaceRole);
                  userAccessContext?.setSelectedRole(row.role.effectiveWorkspaceRole);
                  userAccessContext?.setSelectedPrincipal([{ label: row?.userId }]);
                  userAccessContext?.setModal(ConfigurationUserModal.MODIFY_ACCESS);
                }}
              />
            );
          },
        },
      ],
    },
  ];

  const columns = hasWorkspaceManagerAccess ? columnsWithActionAccess : columnsWithoutActionAccess;

  const addUserButtonTitle = !hasWorkspaceManagerAccess
    ? `You must be an admin or have "owner" role to "${workspace}" workspace.`
    : '';

  const tableAndModals = (
    <>
      <TectonBasicTable
        items={configurationContext?.users ?? []}
        columns={columns}
        pageIndex={0}
        pageSize={0}
        totalItemCount={0}
      />
      {addUserModal}
      {modifyUserModal}
    </>
  );

  return (
    <Panel hasBorder={false} hasShadow={false} paddingSize="xl">
      <ACLTableControlLayout
        search={
          <DebouncedFieldSearch
            fullWidth
            placeholder="Search for User"
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              configurationContext?.setUserSearch?.call(configurationContext, event?.target?.value);
            }}
          />
        }
        actionButtons={
          <Button
            onClick={() => {
              userAccessContext?.setSelectedPrincipal([]);
              userAccessContext?.setSelectedRole('');
              userAccessContext?.setModal(ConfigurationUserModal.ADD_USER);
            }}
            fill
            disabled={!hasWorkspaceManagerAccess}
            title={addUserButtonTitle}
          >
            Add User
          </Button>
        }
      />
      <Spacer size="l" />
      {configurationContext?.isLoading ? <LoadingContent /> : tableAndModals}
    </Panel>
  );
};

export default UsersWithPermissions;
