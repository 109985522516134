import React, { Component, ErrorInfo, ReactNode } from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiIcon } from '@elastic/eui';
import { Text, Link, Card } from './_eui_components';
import ErrorBoundaryDefaultIcon from '../../assets/image/tecton-ui/ui-icon/error_boundary_default.svg';
import ErrorBoundaryXsIcon from '../../assets/image/tecton-ui/ui-icon/error_boundary_xs.svg';
import styled from '@emotion/styled';
import { useTectonTheme } from './TectonThemeProvider';

type BoundarySize = 's' | 'm' | 'l';

interface ErrorBoundaryProps {
  children?: ReactNode;
  size?: BoundarySize;
}

interface ErrorBoundaryVisualProps {
  size?: BoundarySize;
}

interface State {
  hasError: boolean;
}

const CardWrapper = styled(Card)`
  border-radius: 0;
  border-align: 'center';
  margin: auto;

  .euiCard__main {
    align-items: center;
  }
`;

export const ErrorBoundaryVisual = ({ size = 'm' }: ErrorBoundaryVisualProps) => {
  const { theme } = useTectonTheme();

  const Icon = size === 's' ? ErrorBoundaryXsIcon : ErrorBoundaryDefaultIcon;
  const layout = size === 's' ? 'horizontal' : 'vertical';
  return (
    <EuiFlexGroup alignItems="center" justifyContent="center" data-testid="error-boundary">
      <EuiFlexItem color="transparent">
        <CardWrapper
          layout={layout}
          icon={<EuiIcon size="original" type={Icon} />}
          display="transparent"
          titleSize="xs"
          title={
            <Text
              css={{
                fontWeight: theme.font.weight.semiBold,
              }}
              color="danger"
            >
              Unable to load
            </Text>
          }
          description={
            <Text
              css={{
                fontWeight: theme.font.weight.regular,
                marginBlockStart: '4px',
              }}
            >
              {size === 'l' ? (
                <>
                  Please{' '}
                  <Link
                    onClick={() => {
                      window.location.reload();
                    }}
                  >
                    refresh
                  </Link>{' '}
                  this page or return <Link href="/">home</Link>. If the problem persists, contact your Tecton
                  Administrator or email <Link href="mailto:support@tecton.ai">support@tecton.ai</Link>{' '}
                </>
              ) : (
                'There was an error. Contact your administrator for help'
              )}
              .
            </Text>
          }
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

class ErrorBoundary extends Component<ErrorBoundaryProps, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return <ErrorBoundaryVisual size={this.props.size} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
