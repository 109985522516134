import FcoLocator from './FcoLocator';
import MaterializationStatus from './MaterializationStatus';

export default class FeatureViewMaterializationStatus {
  private proto: any = null;

  constructor(proto: any) {
    this.proto = proto;
  }

  get _proto() {
    return this.proto;
  }

  get fcoLocator() {
    return new FcoLocator(this._proto.fco_locator);
  }

  get materializationStatus() {
    return new MaterializationStatus(this._proto.materialization_status);
  }
}
