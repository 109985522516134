import React, { useContext, useState } from 'react';
import { Button, Spacer, LoadingContent, DebouncedFieldSearch } from '@tecton';

import { ACLTableControlLayout } from './ACLLayout';
import RemoveFromGroupConfirmationModal from './modals/RemoveFromGroupConfirmationModal';
import { SelectableGroupOptionGQL } from './modals/UserAddToGroupModalGQL';
import NotMemberOfAnyGroup from './NotMemberOfAnyGroup';
import ACLTableRowPropsFn from './ACLTableRowPropsFn';
import GroupIDColumn from './GroupNameColumn';
import TableActionButtonWithDisableMode from '../shared/TableActionButtonWithDisableMode';

import { ACLPrincipalModal } from './aclUtils';
import { TectonBasicTable } from '../@shared';
import { useUserSettings } from '../context/UserSettingsContext';
import { ACLUserProfileContextProps } from './ACLUserProfileContextGQL';
import { Group } from '../../api/gql/graphql';
import UserAddToGroupModalGQL from './modals/UserAddToGroupModalGQL';
import { ACLContentParams } from './types';
import { ACLServiceAccountProfileContextProps } from './ACLServiceAccountProfileContextGQL';
import { PrincipalType } from '../../types/tecton_proto/auth/principal';

const ACLPrincipalGroupsGQL = <T extends ACLServiceAccountProfileContextProps | ACLUserProfileContextProps>({
  principalId,
  context,
  principalName,
  principalType,
}: ACLContentParams<T>) => {
  const principalProfileGQLContext = useContext(context);
  const [selectedGroup, setSelectedGroup] = useState<Group | undefined>();
  const { isAdmin } = useUserSettings();
  const principalTypeName = principalType === PrincipalType.PRINCIPAL_TYPE_USER ? 'User' : 'Service Account';

  let addGroupModal;

  if (principalProfileGQLContext?.modal === ACLPrincipalModal.ADD_GROUP) {
    addGroupModal = (
      <UserAddToGroupModalGQL
        principalId={principalName}
        options={principalProfileGQLContext?.assignableGroups ?? []}
        setOptions={(options: SelectableGroupOptionGQL[]) => {
          principalProfileGQLContext?.updateSelectedAssignableGroups?.call(principalProfileGQLContext, options);
        }}
        onClose={() => {
          principalProfileGQLContext?.closeModal?.call(principalProfileGQLContext);
        }}
        addPrincipleToGroup={() => {
          principalProfileGQLContext?.confirmAddGroup?.call(principalProfileGQLContext);
        }}
        isLoading={principalProfileGQLContext?.isModalLoading ?? false}
        errorMessages={principalProfileGQLContext?.errorMessages}
      />
    );
  }

  let removeGroupModal;

  if (principalProfileGQLContext.modal === ACLPrincipalModal.REMOVE_GROUP) {
    const confirmChanges = async () => {
      principalProfileGQLContext?.confirmRemoveGroup?.call(
        principalProfileGQLContext,
        selectedGroup?.id ?? '',
        selectedGroup?.name ?? ''
      );
    };

    removeGroupModal = (
      <RemoveFromGroupConfirmationModal
        principalId={principalName}
        groupName={selectedGroup?.name ?? ''}
        onClose={() => {
          principalProfileGQLContext?.closeModal?.call(principalProfileGQLContext);
        }}
        confirmChanges={confirmChanges}
        isLoading={principalProfileGQLContext?.isModalLoading ?? false}
        errorMessages={principalProfileGQLContext?.errorMessages}
      />
    );
  }

  return (
    <div>
      <Spacer size="l" />
      {principalProfileGQLContext?.isLoading && <LoadingContent />}
      {principalProfileGQLContext?.assignedGroupCount === 0 && (
        <NotMemberOfAnyGroup
          isAdmin={isAdmin ?? false}
          userId={principalId}
          addNewGroupCallback={() => {
            principalProfileGQLContext?.updateModal?.call(principalProfileGQLContext, ACLPrincipalModal.ADD_GROUP);
          }}
          buttonType={principalType === PrincipalType.PRINCIPAL_TYPE_SERVICE_ACCOUNT ? 'Service Account' : 'User'}
        />
      )}
      {principalProfileGQLContext?.assignedGroupCount > 0 && (
        <>
          <ACLTableControlLayout
            search={
              <DebouncedFieldSearch
                fullWidth
                placeholder="Search for Group"
                onChange={(event) => {
                  principalProfileGQLContext?.updateGroupFilter?.call(principalProfileGQLContext, event?.target?.value);
                }}
              />
            }
            actionButtons={
              <Button
                fill
                onClick={() => {
                  principalProfileGQLContext?.updateModal?.call(
                    principalProfileGQLContext,
                    ACLPrincipalModal.ADD_GROUP
                  );
                }}
                title={isAdmin ? `Add ${principalTypeName} to New Group` : `Administrator Privileges Required`}
                disabled={!isAdmin}
              >
                Add {principalTypeName} to New Group
              </Button>
            }
          />
          <Spacer size="l" />
          <TectonBasicTable
            items={principalProfileGQLContext?.assignedGroups ?? []}
            rowProps={ACLTableRowPropsFn}
            columns={[
              {
                field: 'name',
                name: 'Group',
                render: (_, row: Group) => {
                  return <GroupIDColumn groupId={row.id ?? ''} groupName={row.name} />;
                },
              },
              {
                name: 'Actions',
                width: '5em',
                actions: [
                  {
                    name: 'Remove from Group',
                    isPrimary: true,
                    description: `Remove this ${principalTypeName?.toLowerCase()} from group`,
                    color: 'danger',
                    render: (row: Group) => {
                      return (
                        <TableActionButtonWithDisableMode
                          isAdmin={isAdmin ?? false}
                          requiresAdmin
                          iconType={'trash'}
                          color="danger"
                          isDisabled={row.id === 'all-principals'}
                          enabledMessage={`Remove ${principalTypeName?.toLowerCase()} from this group`}
                          disabledMessage={`${principalTypeName} cannot be removed from the default 'All Principals' group.`}
                          onClick={() => {
                            principalProfileGQLContext?.updateModal?.call(
                              principalProfileGQLContext,
                              ACLPrincipalModal.REMOVE_GROUP
                            );
                            setSelectedGroup(row);
                          }}
                        />
                      );
                    },
                  },
                ],
              },
            ]}
            pageIndex={0}
            pageSize={0}
            totalItemCount={0}
          />
        </>
      )}
      {addGroupModal}
      {removeGroupModal}
    </div>
  );
};

export default ACLPrincipalGroupsGQL;
