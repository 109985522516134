import React from 'react';
import { EuiDualRange, EuiPanel } from '@elastic/eui';

export interface StatelessNumericSelectorProps {
  min?: number;
  max?: number;
  step?: number;
  label: string;
  selectorValue: [string | number, string | number];
  changeCallback: any;
}

const StatelessNumericSelector = ({
  label,
  min = 1,
  max = 10,
  step,
  selectorValue,
  changeCallback,
}: StatelessNumericSelectorProps) => {
  if (selectorValue === undefined) {
    selectorValue = [min, max];
  }
  return (
    <>
      <EuiPanel paddingSize="m" hasShadow={false}>
        {label}
        <EuiDualRange
          min={min}
          max={max}
          step={step || 1}
          value={selectorValue}
          onChange={changeCallback}
          showInput
          aria-label={label}
        />
      </EuiPanel>
    </>
  );
};

export default StatelessNumericSelector;
