/* eslint-disable */

export const protobufPackage = "tecton_proto.args";

export enum UpdateStrategy {
  /**
   * RECREATE - Any change in a field marked as RECREATE will trigger FCO recreation. This
   * is the default mode.
   */
  RECREATE = "RECREATE",
  /** INPLACE - Any change in a field marked as INPLACE will be applied in-place. */
  INPLACE = "INPLACE",
  /**
   * INPLACE_ON_ADD - Will behave as INPLACE update when the field is changed not set to set
   * (more specifically, protobuf API hasField() return false before and
   * true after). It will behave as RECREATE otherwise. Only used for
   * migrations.
   */
  INPLACE_ON_ADD = "INPLACE_ON_ADD",
  /**
   * ONE_WAY_INPLACE_ON_ADD - Same as INPLACE_ON_ADD, but throws an error (not a recreate) when changing
   * from set to not set. Only used for migrations.
   */
  ONE_WAY_INPLACE_ON_ADD = "ONE_WAY_INPLACE_ON_ADD",
  /**
   * INPLACE_ON_REMOVE - Will behave as INPLACE update when the field is changed from set to not set
   * (more specifically, protobuf API hasField() return true before and
   * false after). It will behave as RECREATE otherwise. Only used for
   * migrations.
   */
  INPLACE_ON_REMOVE = "INPLACE_ON_REMOVE",
  /**
   * PASSIVE - Any change in a field marked as PASSIVE doesn't trigger a diff, so it will
   * not be persisted if there are no other changes in diff.
   */
  PASSIVE = "PASSIVE",
  /**
   * RECREATE_UNLESS_SUPPRESSED - Changes that are safe enough to override recreates and force an in-place
   * update if the user computes the plan using the --suppress-recreates flag.
   *
   * Can be overridden by sub-fields with the INPLACE update strategy.
   */
  RECREATE_UNLESS_SUPPRESSED = "RECREATE_UNLESS_SUPPRESSED",
  /**
   * RECREATE_UNLESS_SUPPRESSED_INVALIDATE_CHECKPOINTS - Special case of RECREATE_UNLESS_SUPPRESSED where streaming checkpoints are
   * invalidated. Checkpoints will be cleared and the current running stream job
   * will be restarted.
   *
   * Can be overridden by sub-fields with updateStrategies:
   *  * INPLACE -> checkpoints not invalidated, stream not restarted
   *  * if using --suppress-recreates:
   *    * RECREATE_UNLESS_SUPPRESSED -> checkpoints not invalidated, stream not
   *    restarted
   *    * RECREATE_UNLESS_SUPPRESSED_RESTART_STREAM -> checkpoints still valid,
   *    but stream needs to be restarted
   */
  RECREATE_UNLESS_SUPPRESSED_INVALIDATE_CHECKPOINTS = "RECREATE_UNLESS_SUPPRESSED_INVALIDATE_CHECKPOINTS",
  /**
   * RECREATE_UNLESS_SUPPRESSED_RESTART_STREAM - Special case of RECREATE_UNLESS_SUPPRESSED where streaming checkpoints are
   * still valid but the current stream job still needs to be restarted to pick
   * up the new FV definition.
   */
  RECREATE_UNLESS_SUPPRESSED_RESTART_STREAM = "RECREATE_UNLESS_SUPPRESSED_RESTART_STREAM",
}

export enum FcoPropertyRenderingType {
  FCO_PROPERTY_RENDERING_TYPE_UNSPECIFIED = "FCO_PROPERTY_RENDERING_TYPE_UNSPECIFIED",
  FCO_PROPERTY_RENDERING_TYPE_PLAIN_TEXT = "FCO_PROPERTY_RENDERING_TYPE_PLAIN_TEXT",
  FCO_PROPERTY_RENDERING_TYPE_PYTHON = "FCO_PROPERTY_RENDERING_TYPE_PYTHON",
  FCO_PROPERTY_RENDERING_TYPE_SQL = "FCO_PROPERTY_RENDERING_TYPE_SQL",
  /** FCO_PROPERTY_RENDERING_TYPE_ONLY_DECLARED - Rendering Type when diff is combined into val_declared field */
  FCO_PROPERTY_RENDERING_TYPE_ONLY_DECLARED = "FCO_PROPERTY_RENDERING_TYPE_ONLY_DECLARED",
  FCO_PROPERTY_RENDERING_TYPE_HIDDEN = "FCO_PROPERTY_RENDERING_TYPE_HIDDEN",
  FCO_PROPERTY_RENDERING_TYPE_REDACTED = "FCO_PROPERTY_RENDERING_TYPE_REDACTED",
}

export enum CustomComparator {
  /** CUSTOM_COMPARATOR_UNSET - protolint:disable:this ENUM_FIELD_NAMES_ZERO_VALUE_END_WITH */
  CUSTOM_COMPARATOR_UNSET = "CUSTOM_COMPARATOR_UNSET",
  CUSTOM_COMPARATOR_AGGREGATION_NAME = "CUSTOM_COMPARATOR_AGGREGATION_NAME",
  CUSTOM_COMPARATOR_OPTION_VALUE_WITH_REDACTION = "CUSTOM_COMPARATOR_OPTION_VALUE_WITH_REDACTION",
  /**
   * CUSTOM_COMPARATOR_DISPLAY_NOTSET - In args diffing, when a field is not set, displays "<not set>" instead of
   * the default proto value. Example use case: the default behavior for the
   * field `data_quality_enabled` is `true`, however, the default differ will
   * display "false" which is inaccurate, thus "<not set>" is preferred here.
   */
  CUSTOM_COMPARATOR_DISPLAY_NOTSET = "CUSTOM_COMPARATOR_DISPLAY_NOTSET",
  CUSTOM_COMPARATOR_REQUEST_SOURCE = "CUSTOM_COMPARATOR_REQUEST_SOURCE",
  CUSTOM_COMPARATOR_STREAM_PROCESSING_MODE = "CUSTOM_COMPARATOR_STREAM_PROCESSING_MODE",
  /**
   * CUSTOM_COMPARATOR_DIFF_OVERRIDE_JOIN_KEYS_AS_MAP - Diff overrides the join keys as a map. If the mapping is unchanged, but the
   * order of ColumnPairs has changed, then treat diffs as an UPDATE.
   */
  CUSTOM_COMPARATOR_DIFF_OVERRIDE_JOIN_KEYS_AS_MAP = "CUSTOM_COMPARATOR_DIFF_OVERRIDE_JOIN_KEYS_AS_MAP",
  /**
   * CUSTOM_COMPARATOR_BATCH_SCHEDULE - Enable UPDATE to 1d batch schedule for continuous FV
   * Custom ticket: CS-3617
   * TODO(TEC-16192): remove custom comparator once customers migrated
   */
  CUSTOM_COMPARATOR_BATCH_SCHEDULE = "CUSTOM_COMPARATOR_BATCH_SCHEDULE",
  CUSTOM_COMPARATOR_TIME_WINDOW_LEGACY = "CUSTOM_COMPARATOR_TIME_WINDOW_LEGACY",
  CUSTOM_COMPARATOR_TIME_WINDOW = "CUSTOM_COMPARATOR_TIME_WINDOW",
  /** CUSTOM_COMPARATOR_ONLINE_OFFLINE_ENABLED - Feature View online/offline_enabled diff handling varies by feature view type. */
  CUSTOM_COMPARATOR_ONLINE_OFFLINE_ENABLED = "CUSTOM_COMPARATOR_ONLINE_OFFLINE_ENABLED",
  /** CUSTOM_COMPARATOR_OFFLINE_STORE - The offline store has some special rendering logic and is ignored in snowflake. */
  CUSTOM_COMPARATOR_OFFLINE_STORE = "CUSTOM_COMPARATOR_OFFLINE_STORE",
  CUSTOM_COMPARATOR_OFFLINE_STORE_LEGACY = "CUSTOM_COMPARATOR_OFFLINE_STORE_LEGACY",
  CUSTOM_COMPARATOR_UNITY_CATALOG_ACCESS_MODE = "CUSTOM_COMPARATOR_UNITY_CATALOG_ACCESS_MODE",
  CUSTOM_COMPARATOR_ENTITY_JOIN_KEYS = "CUSTOM_COMPARATOR_ENTITY_JOIN_KEYS",
  CUSTOM_COMPARATOR_FEATURE_PARAM_UPGRADE = "CUSTOM_COMPARATOR_FEATURE_PARAM_UPGRADE",
  CUSTOM_COMPARATOR_TIMESTAMP_FIELD = "CUSTOM_COMPARATOR_TIMESTAMP_FIELD",
  CUSTOM_COMPARATOR_FILTERED_SOURCE = "CUSTOM_COMPARATOR_FILTERED_SOURCE",
}

export interface FieldRenameConfig {
  /** The former name of the field. */
  former_name?:
    | string
    | undefined;
  /**
   * The cutover version of the rename. `former_name` will be displayed to users
   * when they are applying with an SDK version less than the cutover version.
   * For example if cutover_version = "0.6.0", 0.5 users will see the former
   * name when applying and 0.6 users will see the new name.
   *
   * Typically cutover_versions should be on minor/major version boundaries,
   * e.g. "0.6.0", and not patch or beta releases, e.g. "0.6.11" or "0.6.0b42".
   * It's usually okay if beta users get a slightly misnamed diff relative to
   * their exact beta SDK build.
   */
  cutover_version?: string | undefined;
}

/**
 * Note that we currently don't allow nested annotations. That is, this is not
 * supported:
 *
 * message Foo {
 *     optional string baz = 1 [(diff_options).update = ...];
 * }
 *
 * message Bar {
 *     optional Foo foo = 2 [(diff_options).update = ...]
 * }
 */
export interface DiffOptions {
  /** see the comment on UpdateStrategy message */
  update?:
    | UpdateStrategy
    | undefined;
  /**
   * If set to true, we'll hide the name of the field in the diff. That is, if
   * you have a nested proto struct like fieldA.fieldB.fieldC, and fieldB is
   * annotated with hide_path, it'll show up as "fieldA.fieldC" in the diff.
   */
  hide_path?:
    | boolean
    | undefined;
  /**
   * An option to specify how a field should be rendered in diffs. E.g. as plain
   * text or as a python or SQL code snippet. This is used when rendering diffs
   * in the Web UI.
   */
  rendering_type?:
    | FcoPropertyRenderingType
    | undefined;
  /**
   * Instead of using the default args diff comparator for this message type,
   * use a custom function. This enum should map to a Kotlin function in
   * ArgsDiff.kt.
   */
  custom_comparator?:
    | CustomComparator
    | undefined;
  /**
   * Used for renaming args fields so that the former name is rendered correctly
   * for older clients.
   */
  rename?: FieldRenameConfig | undefined;
}
