import { css } from '@emotion/css';
import { FcoTransitionType } from '../../types/tecton_proto/data/state_update';
import { useTectonTheme } from '../../components/@tecton';

const CountWrapper = ({ count, type }: { count: number; type: string }) => {
  const { theme } = useTectonTheme();
  const planColors = theme.color.light?.planFcoTransitionTypeColorPairs;

  const countWrapperCss = css`
    background: ${planColors[type as FcoTransitionType].backgroundColor};
    color: ${planColors[type as FcoTransitionType].textColor};
    font-weight: 500;
    padding: 0px 4px;
    border-radius: 5px;
  `;

  return <span className={countWrapperCss}>{count}</span>;
};

export default CountWrapper;
