import _ from 'lodash';
import { AccessControlsWorkspace } from '../feature/access-control/types';

export default class Workspace implements AccessControlsWorkspace {
  private proto: any = null;

  constructor(proto: any) {
    this.proto = proto;
  }

  get _proto() {
    return this.proto;
  }

  get name(): string {
    return this._proto.name;
  }

  get capabilities(): { materializable: boolean } {
    return _.get(this._proto, 'capabilities');
  }

  get isMaterializable() {
    return _.get(this._proto, 'capabilities.materializable');
  }

  get resource_id() {
    return this._proto.name;
  }
}
