import { updateObjectProperties } from '../../utils/object-util';
import {
  ConfigActionTypes,
  ConfigState,
  ConfigUpdateAction,
  ConfigUpdateErrorAction,
  ConfigUpdateIsOnboardingEnabled,
} from './types';

export interface ConfigStateInterface {
  hasRequestedConfiguration: boolean;
  hasErrors: boolean;
  config: { CLUSTER_REGION?: string; SNOWFLAKE_ACCOUNT_IDENTIFIER?: string };
  error?: any;
  isOnboardingEnabled: boolean | null;
}

const configStateInitialState: ConfigStateInterface = {
  hasRequestedConfiguration: false,
  hasErrors: false,
  config: {},
  isOnboardingEnabled: null, // when the value is null, it has not yet been set via local storage or backend values
};

const updateConfigState = (state: ConfigStateInterface, action: ConfigUpdateAction): Readonly<ConfigStateInterface> => {
  const configStateInitialState: Readonly<ConfigStateInterface> = {
    hasRequestedConfiguration: true,
    hasErrors: false,
    config: { ...action.data },
    isOnboardingEnabled: state.isOnboardingEnabled,
  };
  return updateObjectProperties(state, configStateInitialState);
};

const updateConfigHasErrors = (
  state: ConfigStateInterface,
  action: ConfigUpdateErrorAction
): Readonly<ConfigStateInterface> => {
  const configStateInitialState: Readonly<ConfigStateInterface> = {
    hasRequestedConfiguration: false,
    hasErrors: true,
    config: {},
    error: action.error,
    isOnboardingEnabled: state.isOnboardingEnabled,
  };
  return updateObjectProperties(state, configStateInitialState);
};

const updateConfigIsOnboardingEnabled = (
  state: ConfigStateInterface,
  action: ConfigUpdateIsOnboardingEnabled
): Readonly<ConfigStateInterface> => {
  const configStateInitialState: Readonly<ConfigStateInterface> = {
    hasRequestedConfiguration: state.hasRequestedConfiguration,
    hasErrors: state.hasErrors,
    config: state.config,
    isOnboardingEnabled: action.isOnboardingEnabled,
  };

  return updateObjectProperties(state, configStateInitialState);
};

export const configReducer = (state: ConfigStateInterface = configStateInitialState, action: ConfigActionTypes) => {
  switch (action.type) {
    case ConfigState.HAS_REQUESTED_CONFIGURATION: {
      return updateConfigState(state, action);
    }
    case ConfigState.HAS_REQUEST_ERRORS: {
      return updateConfigHasErrors(state, action);
    }
    case ConfigState.IS_ONBOARDING_ENABLED: {
      return updateConfigIsOnboardingEnabled(state, action);
    }
    default: {
      return state;
    }
  }
};
