import { OnboardingStatusInterface } from '../feature/onboarding/types';

export default class OnboardingStatus {
  private proto: any = null;

  constructor(proto: OnboardingStatusInterface) {
    this.proto = proto;
  }

  get _proto() {
    return this.proto;
  }

  get createFeatureView() {
    return this._proto?.create_feature_view;
  }

  get finishOnboarding() {
    return this._proto?.finish_onboarding;
  }

  get setupPlatform() {
    return this._proto?.setup_platform;
  }
}
