import React, { FC, PropsWithChildren } from 'react';
import { Route, Routes, useNavigate } from 'react-router';
import { OKTA_CALLBACK_ROUTE } from '../../utils/okta';
import { LoginCallback, Security } from '@okta/okta-react';
import useOktaHook from './useOktaHook';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import useGlobalState from '../../shared/useGlobalState';
import { logEvent } from '../../utils/analytics-utils';
import _ from 'lodash';

export interface oktaAuthProps {
  oktaAuth: OktaAuth;
}

interface AuthenticatorProps extends oktaAuthProps {
  oktaEnabled: boolean;
}

const Authenticator: FC<PropsWithChildren<AuthenticatorProps>> = ({ oktaAuth, oktaEnabled = true, children }) => {
  const { loggedInUser } = useGlobalState();
  const navigate = useNavigate();
  useOktaHook(oktaAuth);

  const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
    // A hack to avoid double "/app/app" on login. There must be a better way to do this.
    // TODO: Use a catch all route and check to see if /app is in the start of the URL
    if (originalUri && originalUri.startsWith('/app')) {
      originalUri = originalUri.replace(/^\/app/, '');
    }

    // Make sure we don't navigate to /okta/callback to avoid infinite loop
    if (`/okta/callback` === originalUri) {
      navigate('/app');
    } else {
      // Deep linking
      let url = toRelativeUrl(originalUri || '/', window.location.origin);
      navigate(url);
    }
  };

  const LoginCallbackWrapper = () => {
    return (
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <LoginCallback
          errorComponent={(error: any) => {
            const errorMessage = _.get(error, 'error.message');
            const errorCode = _.get(error, 'error.errorCode');

            // Notify the use via alert.
            // TODO: Show this error to the user in a better way
            if (errorCode === 'access_denied') {
              // Add to the message
              alert(
                `Your account does not have access to this cluster (${window.location.origin}). Please ask your team administrator to add your account to this Tecton cluster. If you believe this is an error, you can contact your Tecton Support at support@tecton.ai.\n\r Once you click 'ok' you will be redirected back to the login page.`
              );
            } else {
              alert(errorMessage);
            }

            // Log to Amplitude
            logEvent(`WebUI: Okta Error`, errorMessage);

            oktaAuth.signOut();
            return <></>;
          }}
        />
      </Security>
    );
  };

  return (
    <>
      {/* Adding a condition for loggedInUser to prevent LoginCallbackWrapper from getting called. Looking at the console, it gets called as you click on different parts of the application. */}
      {oktaEnabled && !loggedInUser && (
        <Routes>
          <Route path={OKTA_CALLBACK_ROUTE} element={<LoginCallbackWrapper />} />
        </Routes>
      )}
      {/* We want to support none okta workflow */}
      {(loggedInUser || !oktaEnabled) && children}
    </>
  );
};

export default Authenticator;
