import {
  GetAssignedPrincipalsResponse,
  GetAuthorizedResourcesRequest,
  GetAuthorizedResourcesResponse,
} from '../types/tecton_proto/auth/authorization_service';
import { ApiService } from './ApiService';

export class AuthorizationError extends Error {}

declare global {
  interface Window {
    _env_: any;
  }
}

export default class AuthorizationService extends ApiService {
  static sharedService() {
    return service;
  }

  async getAssignedPrincipals(payload: any): Promise<GetAssignedPrincipalsResponse | AuthorizationError> {
    const response = await this._fetchAndCheckStatus('/v1/authorization-service/get-assigned-principals', payload);
    const responseJson = await response.json();

    try {
      responseJson as GetAssignedPrincipalsResponse;
    } catch {
      return new AuthorizationError();
    }

    if (responseJson.assignments === undefined) {
      responseJson.assignments = [];
    }

    return responseJson as GetAssignedPrincipalsResponse;
  }
}

export const service = new AuthorizationService();
