import React from 'react';
import { Text, ButtonEmpty, Button } from '@tecton';

import { TectonBasicModal, CalloutMessageType } from '@shared';

interface DeleteConfirmationModalProps {
  principalId: string | undefined;
  isLoading: boolean;
  errorMessages: CalloutMessageType[] | undefined;
  confirmChange: () => void;
  onClose: () => void;
}
const DeleteConfirmationModal = ({
  principalId,
  isLoading,
  errorMessages,
  confirmChange,
  onClose,
}: DeleteConfirmationModalProps) => {
  return (
    <TectonBasicModal
      title={<h4>Delete {principalId}?</h4>}
      body={
        <Text>
          <p>
            Are you sure you want to delete <strong>{principalId}</strong>?
          </p>
        </Text>
      }
      actions={
        <>
          <ButtonEmpty onClick={onClose}>Cancel</ButtonEmpty>
          <Button isLoading={isLoading} onClick={confirmChange} fill color="danger">
            <span data-testid="modal-delete-button">Delete</span>
          </Button>
        </>
      }
      calloutMessages={errorMessages}
      onClose={onClose}
    />
  );
};

export default DeleteConfirmationModal;
