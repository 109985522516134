/* eslint-disable */

export const protobufPackage = "tecton_proto.auth";

export enum PrincipalType {
  PRINCIPAL_TYPE_UNSPECIFIED = "PRINCIPAL_TYPE_UNSPECIFIED",
  PRINCIPAL_TYPE_USER = "PRINCIPAL_TYPE_USER",
  PRINCIPAL_TYPE_SERVICE_ACCOUNT = "PRINCIPAL_TYPE_SERVICE_ACCOUNT",
  PRINCIPAL_TYPE_GROUP = "PRINCIPAL_TYPE_GROUP",
  PRINCIPAL_TYPE_WORKSPACE = "PRINCIPAL_TYPE_WORKSPACE",
}

/** Object for persisting Principal information */
export interface Principal {
  principal_type?: PrincipalType | undefined;
  id?: string | undefined;
}

/**
 * Object for returning Principal Information in APIs
 * Contains extra info like user emails
 */
export interface PrincipalBasic {
  user?: UserBasic | undefined;
  service_account?: ServiceAccountBasic | undefined;
  group?: GroupBasic | undefined;
  workspace?: WorkspaceBasic | undefined;
}

export interface ServiceAccountBasic {
  id?: string | undefined;
  name?: string | undefined;
  description?: string | undefined;
  is_active?: boolean | undefined;
  creator?: Principal | undefined;
  owner?: PrincipalBasic | undefined;
}

/** This is a subset of `data.User` proto. */
export interface UserBasic {
  okta_id?: string | undefined;
  first_name?: string | undefined;
  last_name?: string | undefined;
  login_email?: string | undefined;
}

export interface GroupBasic {
  id?: string | undefined;
  name?: string | undefined;
}

export interface WorkspaceBasic {
  name?: string | undefined;
}
