import React from 'react';

import { ACLAccountStatuses } from './types';
import { Badge } from '@tecton';

const aclAccountStatusesAttributes: Record<ACLAccountStatuses, { fill: string; text: string; label?: string }> =
  Object.freeze({
    active: {
      fill: '#A1DDFF',
      text: '#063F60',
    },
    provisioned: {
      fill: '#FFE9B0',
      text: '#7A1D00',
    },
    deprovisioned: {
      fill: '#C8EBF5',
      text: '#073171',
    },
    inactive: {
      fill: '#E0E5EE',
      text: '#343741',
    },
    staged: {
      fill: '#D1D9FF',
      text: '#0C2B99',
    },
    recovery: {
      fill: '#EFDAFF',
      text: '#200039',
    },
    locked_out: {
      fill: '#FFDAF0',
      text: '#500026',
      label: 'locked out',
    },
  });

const ACLAccountStatusBadges = ({ type, dataTestId }: { type: ACLAccountStatuses; dataTestId?: string }) => {
  let label: any = type;
  let fill = 'E0E5EE';
  let text = '#343741';

  if (aclAccountStatusesAttributes[type]) {
    label = aclAccountStatusesAttributes[type]?.label ?? type;
    fill = aclAccountStatusesAttributes[type]?.fill ?? fill;
    text = aclAccountStatusesAttributes[type]?.text ?? text;
  }

  return (
    <Badge color={fill}>
      <span style={{ color: text }} data-testid={`status-${dataTestId}`}>
        {label}
      </span>
    </Badge>
  );
};

export default ACLAccountStatusBadges;
