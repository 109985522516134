import React, { FC } from 'react';
import styled from '@emotion/styled';
import WorkspaceSelector from './WorkspaceSelector';
import { Callout, FlexGroupWrapper, FlexItem, ViewLayout } from '@tecton/ComponentRedesign';

interface NoAccessToWorkspaceProps {
  workspace: string;
  isAdmin: boolean;
}

const Title = styled.div`
  padding: 24px 0px;
  font-size: ${({ theme }) => theme.font.headingSizes['3']};
  color: ${({ theme }) => theme.colors.title};
  font-weight: ${({ theme }) => theme.font.weight.medium};
  line-height: ${({ theme }) => theme.font.headingLineHeights['3']};
`;

export const NoAccessToWorkspace: FC<NoAccessToWorkspaceProps> = ({ workspace, isAdmin }) => {
  const body = (
    <>
      <FlexGroupWrapper direction="column" gap="l">
        <FlexItem>
          <Title>Workspaces</Title>
        </FlexItem>
        <FlexItem>
          <Callout title={`You Need Access to '${workspace}'`} mode="warning">
            {!isAdmin && `To get access, please contact your Tecton Admin.`}
          </Callout>
        </FlexItem>
        <FlexItem>
          <WorkspaceSelector />
        </FlexItem>
      </FlexGroupWrapper>
    </>
  );
  return (
    <>
      <ViewLayout header={<></>} body={body} />
    </>
  );
};

export default NoAccessToWorkspace;
