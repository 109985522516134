import styled from '@emotion/styled';
import React, { FC } from 'react';
import { SecondsSince } from '../utils';
import { getEarliestStartDate } from './utils';
import { useTectonTheme, TimePeriodSelector, TimePeriodSelectorOptions } from '@tecton/ComponentRedesign';
import moment from 'moment-timezone';
import { ServingStatusPanelTableDatum } from '../../../servingStatus/types';

interface ServingStatusTitleAndHeaderProps {
  disabled?: boolean;
  timeSelectorOption: TimePeriodSelectorOptions;
  setTimeSelectorOption?: React.Dispatch<React.SetStateAction<TimePeriodSelectorOptions>>;
  setStartDate?: React.Dispatch<React.SetStateAction<Date>>;
  structuredDataForTable?: ServingStatusPanelTableDatum[];
  hideTitle?: boolean;
}

const ServingStatusTitleAndSelector: FC<ServingStatusTitleAndHeaderProps> = ({
  disabled,
  timeSelectorOption,
  setTimeSelectorOption,
  setStartDate,
  structuredDataForTable,
  hideTitle,
}) => {
  const { theme } = useTectonTheme();

  const TopRow = styled.div`
    margin: 16px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `;

  const CardTitle = styled.div`
    font-size: ${theme.font.fontSizes.s};
    color: ${theme.colors.title};
    font-weight: ${theme.font.weight.medium};
  `;

  if (disabled) {
    return (
      <TopRow>
        {hideTitle && <div></div>}
        {!hideTitle && <CardTitle>Materialization Status</CardTitle>}
        <TimePeriodSelector value={timeSelectorOption} disabled />
      </TopRow>
    );
  }

  return (
    <TopRow>
      {hideTitle && <></>}
      {!hideTitle && <CardTitle>Materialization Status</CardTitle>}
      <TimePeriodSelector
        value={timeSelectorOption}
        onChange={(option) => {
          if (option.value) {
            const newEarliestDateForchart = moment().add(-option.value, 'seconds');
            setStartDate && setStartDate(newEarliestDateForchart.toDate());
          } else {
            setStartDate && setStartDate(getEarliestStartDate(structuredDataForTable ?? []));
          }
          setTimeSelectorOption && setTimeSelectorOption(option);
        }}
        maxValue={SecondsSince(getEarliestStartDate(structuredDataForTable ?? [])) ?? moment()}
      />
    </TopRow>
  );
};

export default ServingStatusTitleAndSelector;
