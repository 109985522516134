import { createNetworkActions } from '../../utils/actions-utils';
import keyMirror from 'keymirror';
import { createAction } from 'redux-actions';

const AuthActions = keyMirror({
  getCurrentUser: null,
  setAuth: null,
  setCallerIsAdmin: null,
});

export const getCurrentUserAction = createNetworkActions(AuthActions.getCurrentUser);
export const setAuthAction = createAction(AuthActions.setAuth);
export const setCallerIsAdmin = createNetworkActions(AuthActions.setCallerIsAdmin);
