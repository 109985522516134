// Does not belong in components
import React, { FC } from 'react';
import { CardGridLayout, FullWidthCard, Card, EmptyPrompt, ErrorPromptButtons } from '@tecton/ComponentRedesign';
import { DataQualityMonitoringContextProvider } from '../../redesign/featureViews/DataQualityMetrics/DataQualityMetricsContext';
import { DataQualityMetricSelector } from '../../redesign/featureViews/DataQualityMetrics/DataQualityMetricSelector';
import { FeatureViewFCO } from '../../../core/types/fcoTypes';
import {
  DataQualityMonitoringFeatureRows,
  DataQualityMonitoringLegend,
  DataQualityMonitoringPanelViewLevelMetrics,
  DataQualityMonitoringScrubberDiv,
  DataQualityMonitoringStatusRow,
} from '../../redesign/featureViews/DataQualityMetrics/DataQualityMetrics';
import { MaterializationEvent } from '../../redesign/featureViews/DataQualityMetrics/DataQualityMetricsTypes';

interface DataQualityMonitoringProps {
  featureView: FeatureViewFCO;
  rowCountsData?: any;
  joinKeysData?: any;
  featureData?: any;
  featureMetricsTypesData?: any;
  isLoading?: boolean;
  isError?: boolean;
  isNotReady?: boolean;
  isNotAvailable?: boolean;
}

const DataQualityMonitoring: FC<DataQualityMonitoringProps> = ({
  featureView,
  isLoading,
  isError,
  isNotReady,
  isNotAvailable,
  rowCountsData,
  joinKeysData,
  featureData,
  featureMetricsTypesData,
}) => {
  if (isLoading) {
    return (
      <CardGridLayout>
        <FullWidthCard>
          <Card title={'Materialization Coverage'}>
            <EmptyPrompt
              title={<>Materialization Loading</>}
              body={undefined}
              orientation="vertical"
              variant="loading"
            />
          </Card>
        </FullWidthCard>
        <FullWidthCard>
          <Card title={'Feature-Level Metrics'}>
            <EmptyPrompt
              title={<>Materialization Loading</>}
              body={undefined}
              orientation="vertical"
              variant="loading"
            />
          </Card>
        </FullWidthCard>
      </CardGridLayout>
    );
  }

  if (isError) {
    return (
      <EmptyPrompt
        variant="storm"
        title={<>Data Quality Metrics Error</>}
        body={<p>We encountered an error while loading Data Quality Metrics for this Feature View.</p>}
        actions={<ErrorPromptButtons />}
      />
    );
  }

  if (isNotReady) {
    return (
      <EmptyPrompt
        title={<>Data Quality Metrics Not Ready</>}
        body={
          <p>
            Data Quality Metrics have been enabled for this Feature View, but they yet ready to view. Please check back
            later once the Feature View has materialized.
          </p>
        }
        actions={<ErrorPromptButtons />}
      />
    );
  }

  if (isNotAvailable) {
    return (
      <EmptyPrompt
        title={<>Data Quality Metrics Not Available</>}
        body={<p>Data Quality Metrics are not enabled for this Feature View.</p>}
        actions={<ErrorPromptButtons />}
      />
    );
  }

  return (
    <DataQualityMonitoringContextProvider
      featureView={featureView}
      rowCountsData={rowCountsData}
      joinKeysData={joinKeysData}
      featureData={featureData}
      featureMetricsTypesData={featureMetricsTypesData}
    >
      <CardGridLayout>
        <FullWidthCard>
          <Card title={'Materialization Coverage'} noScroll data-testid="dqm-card">
            <h4>Coverage Overview</h4>
            <DataQualityMonitoringStatusRow data-testid="dqm-status-row" />
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 17em',
                alignItems: 'center',
              }}
            >
              <DataQualityMonitoringScrubberDiv />
              <DataQualityMonitoringLegend />
            </div>
            <h4>Feature-Level Metrics</h4>
            <DataQualityMonitoringPanelViewLevelMetrics data-testid="dqm-feature-view-metrics" />
          </Card>
        </FullWidthCard>
        <FullWidthCard>
          <Card title={'Feature-Level Metrics'} noScroll>
            <DataQualityMetricSelector />
            <DataQualityMonitoringFeatureRows />
          </Card>
        </FullWidthCard>
      </CardGridLayout>
    </DataQualityMonitoringContextProvider>
  );
};

export default DataQualityMonitoring;
