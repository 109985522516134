import React from 'react';
import { LoadingIndicator } from '../../components/LoadingIndicator';

const InitializationLoadingIndicator = () => {
  return (
    <div id="main">
      <LoadingIndicator title="Home" />
    </div>
  );
};

export default InitializationLoadingIndicator;
