import { Link } from '@tecton';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Routes as AppRoutes } from '../../core/routes';
import { css } from '@emotion/css';
import { RoleInheritanceList } from './AllRolesPopover';
import { AccountTypeWithInheritance } from '../../api/gql/graphql';

const AccountTypeTableDisplayUserDetail = ({ accountType }: { accountType: AccountTypeWithInheritance }) => {
  const navigate = useNavigate();

  const groupElaborations = accountType?.inheritedAccountTypes?.map((inheritedAccountType) => {
    const groupElaboration = (
      <li>
        {' '}
        <span
          className={css`
            font-weight: 700;
            margin-bottom: 16px;
          `}
        >
          Admin
          <span
            style={{
              overflowWrap: 'anywhere',
            }}
          ></span>
        </span>{' '}
        role via membership in group (
        <Link
          onClick={() => {
            if (!accountType?.inheritedAccountTypes || !accountType?.inheritedAccountTypes[0]) {
              throw new Error("No groups defined in 'inheritedAccountType' - this should not happen.");
            }

            navigate(`${AppRoutes.accountsAndAccessGroups}/${accountType?.inheritedAccountTypes[0].groupId}`);
          }}
        >
          {inheritedAccountType.groupName}
        </Link>
        ).
      </li>
    );

    return groupElaboration;
  });

  if (accountType?.effectiveAccountType === accountType?.assignedAccountType) {
    groupElaborations?.push(
      <li>
        <span
          className={css`
            font-weight: 700;
            margin-bottom: 16px;
          `}
        >
          {accountType.effectiveAccountType}
          <span
            style={{
              overflowWrap: 'anywhere',
            }}
          ></span>
        </span>{' '}
        role assigned directly.
      </li>
    );
  }

  return <RoleInheritanceList>{groupElaborations}</RoleInheritanceList>;
};

export default AccountTypeTableDisplayUserDetail;
