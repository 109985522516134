import React from 'react';
import { BasicTable, Icon } from '@tecton';
import { RoleRowDescription } from './AccountTypeSelectorFormRow';
import { css } from '@emotion/css';

const RoleTableDescription = ({ tableValues }: { tableValues: RoleRowDescription[] }) => {
  return (
    <div style={{ width: '100%', textAlign: 'left' }}>
      <BasicTable
        className={css`
          .euiTableHeaderCell > * {
            display: none;
          }
        `}
        items={tableValues}
        columns={[
          {
            field: 'hasAccess',
            name: '',
            width: '2em',
            render: (field: RoleRowDescription) => {
              return (
                <div style={{ width: '100%', textAlign: 'center' }}>
                  {field ? (
                    <Icon type="checkInCircleFilled" color="success" aria-label="has access" />
                  ) : (
                    <Icon type="minusInCircleFilled" color="danger" aria-label="no access" />
                  )}
                </div>
              );
            },
          },
          {
            field: 'description',
            name: 'description',
            render: (field: React.ReactNode) => field,
          },
        ]}
      />
    </div>
  );
};

export default RoleTableDescription;
