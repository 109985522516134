import { useEffect } from 'react';
import { init, FullStory } from '@fullstory/browser';
import { useUserSettings } from './context/UserSettingsContext';

const FullStoryInit = () => {
  const { isPublicFreeTrial, user } = useUserSettings();

  useEffect(() => {
    if (isPublicFreeTrial && user) {
      const { preferred_username, email, name, email_verified, tecton_admin } = user as any;

      init({ orgId: 'o-1X8WZH-na1' });
      FullStory('setIdentity', {
        uid: email,
        properties: {
          displayName: preferred_username,
          name,
          email_verified,
          tecton_admin,
        },
      });
    }
  }, [isPublicFreeTrial, user]);

  return null;
};

export default FullStoryInit;
