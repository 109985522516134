import React, { FC } from 'react';

import { EmptyPrompt, FlexGroupWrapper, FlexItem, Monospace, ViewLayout } from '@tecton/ComponentRedesign';
import { WorkspacesContent } from './StyledComponent';

export const NoWorkspaceAssigned: FC = () => {
  const body = (
    <>
      <FlexGroupWrapper direction="column" justifyContent="center" alignItems="center" gap="m">
        <FlexItem>
          Your account lacks workspace access. Contact your Tecton Administrator for access or, if authorized, use the
          Tecton CLI to create your own Workspace.
        </FlexItem>
        <FlexItem>
          <Monospace>$ tecton workspace create [my-workspace]</Monospace>
        </FlexItem>
      </FlexGroupWrapper>
    </>
  );

  return (
    <>
      <ViewLayout
        header={<></>}
        body={
          <WorkspacesContent>
            <FlexGroupWrapper direction="column" justifyContent="center" alignItems="center">
              <FlexItem>
                <EmptyPrompt variant="storm" title={'No Access to Workspaces'} body={body} />
              </FlexItem>
            </FlexGroupWrapper>
          </WorkspacesContent>
        }
      />
    </>
  );
};

export default NoWorkspaceAssigned;
