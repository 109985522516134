import React, { useState } from 'react';
import { Highlight, useTectonTheme } from '@tecton';
import { EuiComboBoxOptionOption, ComboBox } from '@tecton';
import { css } from '@emotion/css';

const useWorkspaceSelectorComboBoxState = (initialSelection: EuiComboBoxOptionOption<string>[]) => {
  return useState<EuiComboBoxOptionOption<string>[]>(initialSelection);
};

const WorkspaceSelectorComboBox = ({
  options,
  isLoading,
  selectedWorkspace,
  onChange,
}: {
  options: EuiComboBoxOptionOption<string>[];
  isLoading: boolean;
  selectedWorkspace: EuiComboBoxOptionOption<string>[];
  onChange: (selectedWorkspace: EuiComboBoxOptionOption<string>[]) => void;
}) => {
  const { theme } = useTectonTheme();

  return (
    <ComboBox
      className={css`
        cursor: pointer;
      `}
      isLoading={isLoading}
      fullWidth
      aria-label="Select Workspace"
      placeholder="Select a Workspace"
      singleSelection={{ asPlainText: true }}
      options={options}
      selectedOptions={selectedWorkspace}
      rowHeight={40}
      data-testid="select-workspace-combobox"
      renderOption={(option, searchValue) => {
        const { label } = option;

        return (
          <>
            <div
              className={css`
                font-size: ${theme.font.scale.m * theme.base}px;
                line-height: ${32}px;
              `}
            >
              <Highlight search={searchValue}>{label}</Highlight>
            </div>
          </>
        );
      }}
      onChange={onChange}
    />
  );
};

export default WorkspaceSelectorComboBox;
export { useWorkspaceSelectorComboBoxState };
