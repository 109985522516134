import styled from '@emotion/styled';

const CenterContentLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: ${({ theme }) => theme.padding.xl};
  height: 100%;
  width: 100%;
  text-align: center; /* Center text inside FlexItems */
`;

export default CenterContentLayout;
