import _ from 'lodash';
import IdUtils from '../utils/id-utils';
import Entity from './Entity';
import VirtualDataSource from './VirtualDataSource';
import Transformation from './TransformationNew';
import FeatureView from './FeatureView';
import FeatureService from './FeatureService';

// TODO: refactor duplication with some similar enums/consts in
// components/FirstClassObject.js and service/Pipeline.js

export const FCO_TYPES = {
  ENTITY: 'entity',
  DATA_SOURCE: 'data_source',
  TRANSFORMATION: 'transformation',
  FEATURE_VIEW: 'featureView',
  FEATURE_SERVICE: 'featureService',
};

const FCO_ID_FIELD_NAMES = {
  [FCO_TYPES.ENTITY]: 'entity_id',
  [FCO_TYPES.DATA_SOURCE]: 'virtual_data_source_id',
  [FCO_TYPES.TRANSFORMATION]: 'transformation_id',
  [FCO_TYPES.FEATURE_VIEW]: 'feature_view_id',
  [FCO_TYPES.FEATURE_SERVICE]: 'feature_service_id',
};

const FCO_INNER_FIELD_NAMES = {
  [FCO_TYPES.ENTITY]: 'entity',
  [FCO_TYPES.DATA_SOURCE]: 'virtual_data_source',
  [FCO_TYPES.TRANSFORMATION]: 'transformation',
  [FCO_TYPES.FEATURE_VIEW]: 'feature_view',
  [FCO_TYPES.FEATURE_SERVICE]: 'feature_service',
};

const FCO_WRAPPER_CLASSES = {
  [FCO_TYPES.ENTITY]: Entity,
  [FCO_TYPES.DATA_SOURCE]: VirtualDataSource,
  [FCO_TYPES.TRANSFORMATION]: Transformation,
  [FCO_TYPES.FEATURE_VIEW]: FeatureView,
  [FCO_TYPES.FEATURE_SERVICE]: FeatureService,
};

export class Fco {
  private proto: any = null;
  private type: any = null;

  constructor(proto: any) {
    this.proto = proto;
    if (this._proto.entity) {
      this.type = FCO_TYPES.ENTITY;
    } else if (this._proto.virtual_data_source) {
      this.type = FCO_TYPES.DATA_SOURCE;
    } else if (this._proto.transformation) {
      this.type = FCO_TYPES.TRANSFORMATION;
    } else if (this._proto.feature_view) {
      this.type = FCO_TYPES.FEATURE_VIEW;
    } else if (this._proto.feature_service) {
      this.type = FCO_TYPES.FEATURE_SERVICE;
    } else {
      throw new Error('Unknown type');
    }
  }

  get _proto() {
    return this.proto;
  }

  get fcoType() {
    return this.type;
  }

  get innerProto() {
    return this._proto[FCO_INNER_FIELD_NAMES[this.type]];
  }

  get id() {
    return IdUtils.toStringId(_.get(this.innerProto, FCO_ID_FIELD_NAMES[this.type]));
  }

  get innerWrapped() {
    return new FCO_WRAPPER_CLASSES[this.type](this.innerProto);
  }
}
