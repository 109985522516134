import React from 'react';

const TableContentIconWrapper = ({
  children,
  style,
  inline = false,
  viewBox = '0 0 24 24',
}: {
  children: React.ReactNode;
  style?: React.CSSProperties;
  inline?: boolean;
  viewBox?: string;
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox={viewBox}
      style={{ ...style, verticalAlign: 'middle', display: inline ? 'inline-block' : 'block' }}
    >
      {children}
    </svg>
  );
};

export default TableContentIconWrapper;
