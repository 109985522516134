export default () => {
  return (
    <svg width="30" height="22" viewBox="0 0 30 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1910_1375)">
        <path
          d="M5.99549 6.84057L8.81292 8.46688L6.31104 12.7887L15.4114 18.0334C17.9188 19.4797 18.6007 18.0278 20.0827 15.1522L20.1615 15.0059L20.1728 15.0116L20.2517 14.8765L23.0241 16.4747L21.1814 19.6654C19.3501 22.2596 17.0623 22.6929 13.9462 20.9597L13.7772 20.864L4.67693 15.6193L2.81743 18.8382L0 17.2119L5.99549 6.84057ZM8.81856 2.33866C10.6499 -0.261199 12.9376 -0.694509 16.0538 1.04435L16.2228 1.14002L25.3231 6.38475L27.1825 3.16589L30 4.7922L24.0045 15.1634L21.187 13.5372L23.6889 9.21533L14.5886 3.9706C12.0812 2.52436 11.3993 3.97623 9.91734 6.85183L9.8385 6.99814L9.82716 6.99251L9.74831 7.12757L6.97596 5.52938L8.81856 2.33866Z"
          fill="#D30602"
          stroke-width={0}
        />
      </g>
      <defs>
        <clipPath id="clip0_1910_1375">
          <rect width="30.0001" height="22" fill="white" stroke-width={0} />
        </clipPath>
      </defs>
    </svg>
  );
};
