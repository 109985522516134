export default () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.9167 13.75H7.08333C6.62333 13.75 6.25 13.3767 6.25 12.9167V7.08333C6.25 6.62333 6.62333 6.25 7.08333 6.25H12.9167C13.3767 6.25 13.75 6.62333 13.75 7.08333V12.9167C13.75 13.3767 13.3767 13.75 12.9167 13.75Z"
        stroke="#525866"
        stroke-width="1.5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.6667 10H10.8333C10.3733 10 10 9.62667 10 9.16667V3.33333C10 2.87333 10.3733 2.5 10.8333 2.5H16.6667C17.1267 2.5 17.5 2.87333 17.5 3.33333V9.16667C17.5 9.62667 17.1267 10 16.6667 10Z"
        stroke="#525866"
        stroke-width="1.5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.16667 17.5H3.33333C2.87333 17.5 2.5 17.1267 2.5 16.6667V10.8333C2.5 10.3733 2.87333 10 3.33333 10H9.16667C9.62667 10 10 10.3733 10 10.8333V16.6667C10 17.1267 9.62667 17.5 9.16667 17.5Z"
        stroke="#525866"
        stroke-width="1.5"
      />
    </svg>
  );
};
