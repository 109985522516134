export default () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.27034 15.3952C7.98904 15.677 7.60732 15.8354 7.20919 15.8356C6.81107 15.8358 6.42918 15.6778 6.14759 15.3963L2.93661 12.1871C2.65503 11.9057 2.49683 11.5238 2.49683 11.1257C2.49683 10.7276 2.65503 10.3458 2.93661 10.0644L8.35137 4.65211C8.66405 4.3396 9.08803 4.16406 9.5301 4.16406H12.501C13.4218 4.16406 14.1683 4.91057 14.1683 5.83142V8.79806C14.1683 9.23982 13.993 9.66352 13.6809 9.97613L8.27034 15.3952Z"
        stroke="#525866"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.503 6.66504V9.83086C17.503 10.2731 17.3274 10.6972 17.0147 11.0099L12.2942 15.7304"
        stroke="#525866"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
