import React, { ReactNode, createContext, useContext, useState } from 'react';
import {
  ACLGroupModal,
  getRoleName,
  getAssignableWorkspaceGQL,
  getWorkspaceMemberCountGQL,
  getWorkspaceMembersGQL,
  getLegacyRoleIdGQL,
  filterServiceAccounts,
} from './aclUtils';
import { AccountType, CandidateMemberOption } from './types';
import { CalloutMessageType } from '@shared';
import {
  useAddPrincipalGroupMembersMutation,
  useAssignRoleMutation,
  useAssignRolesMutation,
  useDeleteGroupMutation,
  useRemovePrincipalGroupMembersMutation,
  useUpdateAccountTypeMutation,
  useUpdatePrincipalGroupMutation,
} from '../../api/acl';
import { useNavigate, useParams } from 'react-router';
import { ToastContext, addToast } from '../@tecton/ToastContext';
import { logEvent } from '../../utils/analytics-utils';
import { EuiComboBoxOptionOption } from '../@tecton';
import { Routes as AppRoutes } from '../../core/routes';
import _sortBy from 'lodash/sortBy';
import { PrincipalType } from '../../types/tecton_proto/auth/principal';

import { ResourceType } from '../../types/tecton_proto/auth/resource';
import { AssignRolesRequest } from '../../types/tecton_proto/auth/authorization_service';
import { getAllRolesRecordsGQL, getRolesGQL } from './util';
import { OptionsInfo } from '../shared/RowSelectorFormRow';
import { useGetACLGroupsDetails } from '../../api/queries/aclGraphql';
import ACLLoadingContent from './ACLLoadingContent';
import { AclGroupWorkspaceType, Group, RoleDefinition, ServiceAccount, User } from '../../api/gql/graphql';

export interface ACLGroupProfileContextPropsGQL {
  isLoading?: boolean;
  isModalLoading?: boolean;
  errorMessages?: CalloutMessageType[];
  assignedWorkspaces?: AclGroupWorkspaceType[];
  group?: Group;
  selectedWorkspaceRole?: WorkspaceRole;
  modal?: ACLGroupModal;
  assignableServiceAccounts?: CandidateMemberOption[];
  description?: string;
  isDescriptionInvalid?: boolean;
  isDescriptionEditing?: boolean;
  selectedWorkspace?: EuiComboBoxOptionOption<string>[];
  name?: string;
  isNameInvalid?: boolean;
  isNameEditing?: boolean;
  fieldFormErrors?: string[];
  selectedUserToDelete?: User;
  selectedServiceAccountToDelete?: ServiceAccount;
  assignedUsers?: User[];
  assignableUsers?: CandidateMemberOption[];
  assignedServiceAccounts?: ServiceAccount[];
  workspaceCandidates?: EuiComboBoxOptionOption<string>[];
  userSearch?: string;
  serviceAccountSearch?: string;
  userMembersCount?: number;
  serviceAccountMembersCount?: number;
  workspaceMembersCount?: string;
  selectedRole?: string | '';
  allRoleRecords?: OptionsInfo<string>[];
  groupRoles?: RoleDefinition[];
  existingGroupRoleOptions?: string[];
  newGroupRoleOptions?: string[];
  isFieldLoading?: boolean;
  error?: any;
  showSelectedUserToDeleteModal?: (user: User) => void;
  showServiceAccountDeleteModal?: (serviceAccount: ServiceAccount) => void;
  updateCandidateMembers?: (options: CandidateMemberOption[]) => void;
  updateIsDescriptionEditing?: (isEditing: boolean) => void;
  updateIsDescriptionInvalid?: (isValid: boolean) => void;
  updateDescription?: (description: string) => void;
  saveDescription?: (description: string, onSuccess: () => void, onErrorCallBack: (error: any) => void) => void;
  updateIsNameEditing?: (isEditing: boolean) => void;
  updateIsNameInvalid?: (isValid: boolean) => void;
  updateName?: (name: string) => void;
  updateSelectedWorkspace?: (value: EuiComboBoxOptionOption<string>[]) => void;
  saveName?: (newName: string) => void;
  closeModal?: () => void;
  showGroupEditAccountTypeModal?: () => void;
  showAddWorkspaceModal?: () => void;
  showModifyWorkspaceModal?: (workspaceRole: AclGroupWorkspaceType) => void;
  showDeleteModal?: () => void;
  showAddMemberModal?: () => void;
  showDeleteGroupModal?: () => void;
  addWorkspace?: () => void;
  confirmModifyWorkspace?: (callback: () => void) => void;
  updateUserRole?: (accountType: AccountType) => void;
  confirmAddMembers?: () => void;
  confirmAddServiceAccountMembers?: () => void;
  updateServiceAccountCandidateMemberOptions?: (options: CandidateMemberOption[]) => void;
  confirmRemoveUserFromGroup?: () => void;
  confirmRemoveServiceAccountFromGroup?: () => void;
  deleteGroup?: () => void;
  updateUserSearch?: (value: string) => void;
  updateServiceAccountSearch?: (value: string) => void;
  updateWorkspaceSearch?: (value: string) => void;
  updateSelectedRole?: (selectedRole: string) => void;
  updateAttributes?: (original: string, newValue: string, callBack: VoidFunction) => void;
  addAttributes?: (newValue: string, callBack: VoidFunction) => void;
  removeAttributes?: (newValue: string, callBack: VoidFunction) => void;
  showDeleteAttributeModal?: VoidFunction;
}

export const ACLGroupProfileContextGQL = createContext<ACLGroupProfileContextPropsGQL>({});

export interface WorkspaceRole {
  workspaceId: string;
  currentRole: string;
}

export const ACLGroupProfileContextGQLProvider = ({ children }: { children: ReactNode }) => {
  const { group: groupID } = useParams();
  const toastContext = useContext(ToastContext);
  const navigate = useNavigate();

  const deleteGroupMutation = useDeleteGroupMutation();
  const updatePrincipalGroupMutation = useUpdatePrincipalGroupMutation();
  const addPrincipalGroupMembersMutation = useAddPrincipalGroupMembersMutation();
  const removePrincipalGroupMembersMutation = useRemovePrincipalGroupMembersMutation();
  const assignRoleMutation = useAssignRoleMutation();
  const assignRolesMutation = useAssignRolesMutation();
  const updateAccountTypeToRegularMutation = useUpdateAccountTypeMutation();

  const { data, isLoading, error } = useGetACLGroupsDetails(groupID ?? '');

  const groupRoles = getRolesGQL(data?.roles, PrincipalType.PRINCIPAL_TYPE_SERVICE_ACCOUNT);
  const allRoleRecords = getAllRolesRecordsGQL(groupRoles);
  const newGroupRoleOptions = allRoleRecords.map((saRole) => {
    return saRole.id;
  });

  const existingGroupRoleOptions = newGroupRoleOptions.filter((i) => i !== 'none');

  const [modal, setModal] = useState<ACLGroupModal | undefined>();
  const [modalError, setModalError] = useState<CalloutMessageType[] | undefined>();
  const [errorMessages, setErrorMessages] = useState<CalloutMessageType[] | undefined>();

  const [selectedWorkspaceRole, setSelectedWorkspaceRole] = useState<WorkspaceRole | undefined>();
  const [selectedWorkspace, setSelectedWorkspace] = useState<EuiComboBoxOptionOption<string>[] | undefined>();
  const [selectedRole, setSelectedRole] = useState<string | undefined>();

  const [assignableUserSelected, setAssignableUserSelected] = useState<CandidateMemberOption[] | undefined>();
  const [selectedUserToDelete, setUserToDelete] = useState<User | undefined>();
  const [selectedServiceAccountToDelete, setSelectedServiceAccountToDelete] = useState<ServiceAccount | undefined>();
  const [serviceAccountAssignableOption, setServiceAccountAssignableOption] = useState<
    CandidateMemberOption[] | undefined
  >();

  // Profile description
  const [description, setDescription] = useState<string | undefined>();
  const [isDescriptionInvalid, setIsDescriptionInvalid] = useState<boolean | undefined>(false);
  const [isDescriptionEditing, setIsDescriptionEditing] = useState<boolean | undefined>(false);

  // Profile Name
  const [name, setName] = useState<string | undefined>();
  const [isNameInvalid, setIsNameInvalid] = useState<boolean | undefined>(false);
  const [isNameEditing, setIsNameEditing] = useState<boolean | undefined>(false);

  const [fieldFormErrors, setFieldFormErrors] = useState<string[]>([]);

  const [userSearch, setUserSearch] = useState<string | undefined>();
  const [serviceAccountSearch, setServiceAccountSearch] = useState<string | undefined>();
  const [workspaceSearch, setWorkspaceSearch] = useState<string | undefined>();

  // Add Workspace
  const showGroupEditAccountTypeModal = () => {
    setModal(ACLGroupModal.EDIT_GROUP_ACCOUNT_TYPE);
  };

  const showAddWorkspaceModal = () => {
    setModal(ACLGroupModal.ADD_WORKSPACE);
  };

  const showModifyWorkspaceModal = (row: AclGroupWorkspaceType) => {
    const workspaceRole = {
      workspaceId: row.workspaceId,
      currentRole: getRoleName(groupRoles ?? [], row.role ?? ''),
    };
    setModal(ACLGroupModal.MODIFY_WORKSPACE);
    setSelectedWorkspaceRole(workspaceRole);
  };

  const confirmModifyWorkspace = (onSuccess: () => void) => {
    const principal_id = groupID;
    const resource_id = selectedWorkspaceRole?.workspaceId ?? '';
    const principal_type = PrincipalType.PRINCIPAL_TYPE_GROUP;
    const role = getLegacyRoleIdGQL(groupRoles, selectedRole ?? '');
    const roles = role === 'none' ? [] : [role];
    const isAllWorkspaces = resource_id === 'All Workspaces';

    // make sure account type is stays in place if there were admin before

    const resource_type = isAllWorkspaces
      ? ResourceType.RESOURCE_TYPE_ORGANIZATION
      : ResourceType.RESOURCE_TYPE_WORKSPACE;

    const payload = {
      principal_id,
      roles,
      ...(!isAllWorkspaces && { resource_id }),
      principal_type,
      resource_type,
    };

    assignRolesMutation.mutate(payload, {
      onSuccess: () => {
        // Clean up default values
        setSelectedRole(undefined);
        setSelectedWorkspaceRole(undefined);
        setModal(undefined);

        const title = role === 'none' ? `Workspace removed from group` : `Workspace updated from groups`;

        const text =
          role === 'none'
            ? `Workspace "${resource_id}" removed from "${data?.group?.name}" group`
            : `"${data?.group?.name}" group has been granted ${selectedRole} role for workspace "${resource_id}".`;

        // Send toaster to the service account
        toastContext?.dispatchToast(
          addToast({
            title,
            text,
            color: 'success',
            iconType: 'lock',
          })
        );

        logEvent(`Group workspace ${role === 'none' ? 'removed' : 'updated'} role to ${role}`, '', {
          groupID,
          resource_id,
          principal_type,
          resource_type,
          role,
        });

        onSuccess();
      },
      onError,
    });
  };

  const showSelectedUserToDeleteModal = (user: User) => {
    showDeleteModal();
    setUserToDelete(user);
  };

  const showServiceAccountDeleteModal = (serviceAccount: ServiceAccount) => {
    showDeleteModal();
    setSelectedServiceAccountToDelete(serviceAccount);
  };

  const showDeleteModal = () => {
    setModal(ACLGroupModal.REMOVE_MEMBER_FROM_GROUP);
  };

  const showAddMemberModal = () => {
    setModal(ACLGroupModal.ADD_MEMBER_TO_GROUP);
  };

  const showDeleteGroupModal = () => {
    setModal(ACLGroupModal.DELETE_GROUP);
  };

  const closeModal = () => {
    setModal(undefined);
    setErrorMessages(undefined);
  };

  const showDeleteAttributeModal = () => {
    setModal(ACLGroupModal.REMOVE_ATTRIBUTE);
  };

  const onError = (error: any) => {
    const errorMessage = error?.response?.data?.message ? error?.response?.data?.message : error?.response?.statusText;
    setErrorMessages([
      {
        title: String(errorMessage),
        color: 'danger',
        iconType: 'alert',
      },
    ]);
  };

  const updateCandidateMembers = (options: CandidateMemberOption[]) => {
    setAssignableUserSelected(options);
  };

  const updateServiceAccountCandidateMemberOptions = (options: CandidateMemberOption[]) => {
    setServiceAccountAssignableOption(options);
  };

  const addWorkspace = () => {
    const role = getLegacyRoleIdGQL(groupRoles, selectedRole ?? '');

    const payload: AssignRolesRequest = {
      assignments: [
        {
          principal_id: groupID,
          role,
          resource_id: selectedWorkspace?.[0]?.label ?? '',
          principal_type: PrincipalType.PRINCIPAL_TYPE_GROUP,
          resource_type: ResourceType.RESOURCE_TYPE_WORKSPACE,
        },
      ],
    };

    assignRoleMutation.mutate(payload, {
      onSuccess: () => {
        setErrorMessages(undefined);
        setSelectedRole(undefined);
        setSelectedWorkspace(undefined);
        closeModal();
      },
      onError,
    });
  };

  const confirmAddMembers = () => {
    const selectedUserMember = assignableUserSelected?.filter((item) => {
      return item.checked === 'on';
    });

    const userMembersToAdd =
      selectedUserMember?.map((item) => {
        return { principal_type: PrincipalType.PRINCIPAL_TYPE_USER, principal_id: `${item?.data.id}` };
      }) ?? [];

    const payload = {
      principal_group_id: `${groupID}`,
      members: userMembersToAdd,
    };

    addPrincipalGroupMembersMutation.mutate(payload, {
      onSuccess: () => {
        const text = `${selectedUserMember?.map((i) => `"${i.label}"`)?.join('\n')} added to group  "${
          data?.group?.name
        }"`;

        const userText = userMembersToAdd?.length > 1 ? 'Users' : 'User';
        toastContext?.dispatchToast(
          addToast({
            title: `${userText} added to group`,
            text,
            iconType: 'lock',
            color: 'success',
          })
        );

        logEvent(`${userText} added to group`, '', {
          groupID,
          text,
        });
        closeModal();
      },
      onError,
      onSettled: () => {
        setAssignableUserSelected(undefined);
      },
    });
  };

  const confirmAddServiceAccountMembers = () => {
    const selectedServiceAccountMember = serviceAccountAssignableOption?.filter((item) => {
      return item.checked === 'on';
    });

    const servicerAccountMembersToAdd =
      selectedServiceAccountMember?.map((item) => {
        return { principal_type: PrincipalType.PRINCIPAL_TYPE_SERVICE_ACCOUNT, principal_id: `${item?.data.id}` };
      }) ?? [];

    const payload = {
      principal_group_id: `${groupID}`,
      members: servicerAccountMembersToAdd,
    };

    addPrincipalGroupMembersMutation.mutate(payload, {
      onSuccess: () => {
        cleanUpStates();
        const text = `Service accounts ${selectedServiceAccountMember
          ?.map((sa) => {
            return sa?.label;
          })
          .join(' , ')}`;
        toastContext?.dispatchToast(
          addToast({
            title: 'Services account added to group',
            text,
            iconType: 'lock',
            color: 'success',
          })
        );

        logEvent('Service account added to group', '', {
          groupName: data?.group?.name,
          groupID,
          text,
        });
      },
    });
  };

  const updateDescription = (description: string) => {
    setDescription(description);
  };

  const updateName = (name: string) => {
    setName(name);
  };

  const updateIsDescriptionInvalid = (isDescriptionInvalid: boolean) => {
    setIsDescriptionInvalid(isDescriptionInvalid);
  };

  const updateIsDescriptionEditing = (isEditing: boolean) => {
    setIsDescriptionEditing(isEditing);
  };

  const cleanUpStates = () => {
    setIsNameEditing(undefined);
    setErrorMessages(undefined);
    setIsNameInvalid(false);
    setUserToDelete(undefined);
    setServiceAccountAssignableOption(undefined);
    closeModal();
  };

  const saveDescription = (description: string, onSuccess: () => void, onErrorCallBack: (e: any) => void) => {
    const updateObject = {
      ...data?.group,
      description,
    };

    updatePrincipalGroupMutation.mutate(updateObject, {
      onSuccess: () => {
        const text = `Group description updated to "${description}"`;
        toastContext?.dispatchToast(
          addToast({
            title: 'Group Description Updated',
            text,
            iconType: 'lock',
            color: 'success',
          })
        );

        logEvent('Group Name Updated', '', {
          groupID,
          text,
        });
        closeModal();
        cleanUpStates();
        setIsDescriptionEditing(undefined);
        onSuccess();
      },
      onError: (error: any) => {
        onErrorCallBack(error);
        onError(error);
      },
    });
  };

  const updateAttributes = (originalValue: string, newValue: string, callBack: VoidFunction) => {
    const originalAttributes = (data?.group?.idpMappingNames as any[]) ?? [];
    const index = originalAttributes.indexOf(originalValue);
    originalAttributes.splice(index, index + 1, newValue);

    const updateObject = {
      name: data?.group?.name,
      description: data?.group?.description ?? '',
      id: data?.group?.id,
      idp_mapping_names: [...originalAttributes],
    };
    updatePrincipalGroupMutation.mutate(updateObject, {
      onSuccess: () => {
        const text = `From ${originalValue} to ${newValue}`;
        toastContext?.dispatchToast(
          addToast({
            title: 'Identity Provider Attributes Updated',
            text,
            iconType: 'lock',
            color: 'success',
          })
        );

        logEvent('Identity Provider Attributes Updated', '', {
          groupName: data?.group?.name,
          groupID,
          text,
        });
        closeModal();
        cleanUpStates();
        setIsDescriptionEditing(undefined);
        callBack();
      },
      onError,
    });
  };

  const addAttributes = (newValue: string, callBack: VoidFunction) => {
    const originalAttributes = (data?.group?.idpMappingNames as any[]) ?? [];

    const updateObject = {
      name: data?.group?.name,
      id: data?.group?.id,
      description: data?.group?.description ?? '',
      idp_mapping_names: [...originalAttributes, newValue],
    };
    updatePrincipalGroupMutation.mutate(updateObject, {
      onSuccess: () => {
        const text = `${newValue}`;
        toastContext?.dispatchToast(
          addToast({
            title: `Identity Provider Attributes Added`,
            text,
            iconType: 'lock',
            color: 'success',
          })
        );

        logEvent('Identity Provider Attributes Added', '', {
          groupName: data?.group?.name,
          groupID,
          text,
        });
        closeModal();
        cleanUpStates();
        setIsDescriptionEditing(undefined);
        callBack();
      },
      onError,
    });
  };

  const removeAttributes = (newValue: string, callBack: VoidFunction) => {
    const originalAttributes = (data?.group?.idpMappingNames as any[]) ?? [];
    const index = originalAttributes.indexOf(newValue);
    originalAttributes.splice(index, index + 1);

    const updateObject = {
      name: data?.group?.name,
      id: data?.group?.id,
      description: data?.group?.description ?? '',
      idp_mapping_names: [...originalAttributes],
    };
    updatePrincipalGroupMutation.mutate(updateObject, {
      onSuccess: () => {
        const text = `${newValue}`;
        toastContext?.dispatchToast(
          addToast({
            title: 'Identity Provider Attributes Removed',
            text,
            iconType: 'lock',
            color: 'success',
          })
        );

        logEvent('Identity Provider Attributes Removed', '', {
          groupName: data?.group?.name,
          groupID,
          text,
        });
        closeModal();
        cleanUpStates();
        setIsDescriptionEditing(undefined);
        callBack();
      },
      onError,
    });
  };

  const updateIsNameInvalid = (isNameInvalid: boolean) => {
    setIsNameInvalid(isNameInvalid);
  };

  const updateIsNameEditing = (isEditing: boolean) => {
    setIsNameEditing(isEditing);
    if (!isEditing) {
      setIsNameInvalid(false);
      setFieldFormErrors([]);
    }
  };

  const saveName = (name: string) => {
    const idpNames = data?.group?.idpMappingNames?.map((name) => name ?? '') ?? [];

    const updateObject = {
      id: data?.group?.id,
      description: data?.group?.description ?? '',
      idp_mapping_names: idpNames,
      name,
    };
    updatePrincipalGroupMutation.mutate(updateObject, {
      onSuccess: () => {
        const text = `Group name updated to "${name}"`;
        toastContext?.dispatchToast(
          addToast({
            title: 'Group Name Updated',
            text,
            iconType: 'lock',
            color: 'success',
          })
        );

        logEvent('Group Name Updated', '', {
          groupID,
          text,
        });
        closeModal();
        cleanUpStates();
      },
      onError: (error: any) => {
        setIsNameInvalid(true);
        setFieldFormErrors([`${error?.response?.data?.message}`]);
      },
    });
  };

  const toastAndLogGroupUpdate = (text: AccountType, principal_id: string) => {
    // Send toaster to the user
    toastContext?.dispatchToast(
      addToast({
        title: 'Group Type updated to',
        text,
        iconType: 'lock',
        color: 'success',
      })
    );

    // hide the modal
    setModal(undefined);

    logEvent('Account & Access: Edit GroupType', '', {
      principal_id,
      text,
    });
    setModalError(undefined);
  };

  const updateUserRole = (accountType: AccountType) => {
    const principal_id = groupID as string;
    const role = 'admin_role';
    const principal_type = PrincipalType.PRINCIPAL_TYPE_GROUP;
    const resource_type = ResourceType.RESOURCE_TYPE_ORGANIZATION;

    const assignAsAdmin = accountType === 'Admin';

    const payload: AssignRolesRequest = {
      assignments: [
        {
          principal_id,
          role,
          principal_type,
          resource_type,
        },
      ],
    };

    if (assignAsAdmin) {
      // Turn into admin
      assignRoleMutation.mutate(payload, {
        onSuccess: () => {
          setModal(undefined);
          toastAndLogGroupUpdate('Admin', principal_id);
        },
      });
    } else {
      // Turn into Regular
      updateAccountTypeToRegularMutation.mutate(payload, {
        onSuccess: () => {
          toastAndLogGroupUpdate('Regular', principal_id);
          setModal(undefined);
        },
      });
    }
  };

  const confirmRemoveUserFromGroup = () => {
    const payload = {
      id: `${groupID}`,
      members: [{ principal_type: PrincipalType.PRINCIPAL_TYPE_USER, principal_id: `${selectedUserToDelete?.oktaId}` }],
    };

    const text = `User ${selectedUserToDelete?.loginEmail} has been removed from ${data?.group?.name}`;
    removePrincipalGroupMembersMutation.mutate(payload, {
      onSuccess: () => {
        cleanUpStates();
        toastContext?.dispatchToast(
          addToast({
            title: 'User removed from group',
            text,
            iconType: 'lock',
            color: 'success',
          })
        );

        logEvent('User removed from group', '', {
          principal_id: `${selectedUserToDelete?.oktaId}`,
          text,
        });
      },

      onError,
    });
  };

  const confirmRemoveServiceAccountFromGroup = () => {
    const payload = {
      id: `${groupID}`,
      members: [
        {
          principal_type: PrincipalType.PRINCIPAL_TYPE_SERVICE_ACCOUNT,
          principal_id: `${selectedServiceAccountToDelete?.id}`,
        },
      ],
    };
    removePrincipalGroupMembersMutation.mutate(payload, {
      onSuccess: () => {
        cleanUpStates();

        const text = `Service Account ${selectedServiceAccountToDelete?.name} has been removed from ${data?.group?.name}`;
        toastContext?.dispatchToast(
          addToast({
            title: 'Service Account removed from group',
            text,
            iconType: 'lock',
            color: 'success',
          })
        );

        logEvent('User removed from group', '', {
          principal_id: `${selectedUserToDelete?.oktaId}`,
          text,
        });
      },

      onError,
    });
  };

  const deleteGroup = () => {
    const principal_id = groupID ?? '';
    const text = `Group "${data?.group?.name}" has been deleted.`;

    deleteGroupMutation.mutate(
      { ids: [principal_id] },
      {
        onSuccess: () => {
          toastContext?.dispatchToast(
            addToast({
              title: 'Group Deleted',
              text,
              iconType: 'lock',
              color: 'success',
            })
          );

          logEvent('Group Deleted', '', {
            principal_id,
            text,
          });
          closeModal();
          navigate(`${AppRoutes.accountsAndAccessGroups}`);
        },
        onError,
      }
    );
  };

  const updateUserSearch = (value: string) => {
    setUserSearch(value);
  };

  const updateServiceAccountSearch = (value: string) => {
    setServiceAccountSearch(value);
  };

  const updateWorkspaceSearch = (value: string) => {
    setWorkspaceSearch(value);
  };

  const updateSelectedWorkspace = (value: EuiComboBoxOptionOption<string>[]) => {
    setSelectedWorkspace(value);
  };

  const updateSelectedRole = (selectedRole: string) => {
    setSelectedRole(selectedRole);
  };

  const assignedUsers: User[] = !!userSearch
    ? data?.assignedUsers?.filter((user) => {
        return user?.loginEmail?.toLowerCase().includes(userSearch?.toLowerCase() ?? '');
      }) ?? []
    : data?.assignedUsers ?? [];

  const assignableUsers: CandidateMemberOption[] =
    assignableUserSelected !== undefined
      ? assignableUserSelected
      : data?.assignableUsers?.map((user) => {
          return {
            label: user.loginEmail ?? '',
            data: {
              id: user.oktaId ?? '',
              name: user.loginEmail ?? '',
            },
          };
        }) ?? [];

  const assignedServiceAccounts: ServiceAccount[] = filterServiceAccounts(
    serviceAccountSearch ?? '',
    data?.assignedServiceAccounts
  );

  // For dropdowns
  const assignableServiceAccounts =
    serviceAccountAssignableOption !== undefined
      ? serviceAccountAssignableOption
      : data?.assignableServiceAccounts?.map((serviceAccount) => {
          return {
            label: serviceAccount.name ?? '',
            data: {
              id: serviceAccount?.id ?? '',
              name: serviceAccount?.name ?? '',
            },
          };
        }) ?? [];

  const assignedWorkspaces = getWorkspaceMembersGQL(workspaceSearch ?? '', data?.assignedWorkspaces ?? []);

  const workspaceCandidates: EuiComboBoxOptionOption<string>[] = getAssignableWorkspaceGQL(
    assignedWorkspaces,
    data?.assignableWorkspaces ?? []
  );
  const workspaceMembersCount = getWorkspaceMemberCountGQL(data?.assignedWorkspaces ?? []);

  return (
    <ACLGroupProfileContextGQL.Provider
      value={{
        isLoading,
        isModalLoading:
          removePrincipalGroupMembersMutation.isLoading ||
          addPrincipalGroupMembersMutation.isLoading ||
          assignRolesMutation.isLoading ||
          updateAccountTypeToRegularMutation.isLoading ||
          assignRoleMutation.isLoading ||
          updatePrincipalGroupMutation.isLoading ||
          deleteGroupMutation.isLoading,
        isFieldLoading: updatePrincipalGroupMutation?.isLoading ?? false,
        errorMessages,
        workspaceCandidates, // For the drop down selector in the modal
        assignedUsers,
        assignableUsers,
        assignableServiceAccounts,
        assignedServiceAccounts,
        userMembersCount: data?.membersCount.user,
        serviceAccountMembersCount: data?.membersCount?.serviceAccount,
        group: data?.group,
        assignedWorkspaces,
        selectedWorkspaceRole,
        modal,
        description,
        isDescriptionInvalid,
        isDescriptionEditing,
        name,
        isNameInvalid,
        isNameEditing,
        fieldFormErrors,
        selectedUserToDelete,
        selectedServiceAccountToDelete,
        userSearch,
        serviceAccountSearch,
        workspaceMembersCount,
        selectedWorkspace,
        selectedRole,
        allRoleRecords,
        groupRoles,
        existingGroupRoleOptions,
        newGroupRoleOptions,
        error,
        updateIsDescriptionInvalid,
        updateIsDescriptionEditing,
        updateDescription,
        updateSelectedWorkspace,
        updateSelectedRole,
        updateName,
        showSelectedUserToDeleteModal,
        showServiceAccountDeleteModal,
        showDeleteGroupModal,
        saveDescription,
        updateIsNameInvalid,
        updateIsNameEditing,
        saveName,
        updateCandidateMembers,
        closeModal,
        showGroupEditAccountTypeModal,
        showAddWorkspaceModal,
        showModifyWorkspaceModal,
        showDeleteModal,
        showAddMemberModal,
        addWorkspace,
        confirmModifyWorkspace,
        updateUserRole,
        confirmAddMembers,
        confirmAddServiceAccountMembers,
        updateServiceAccountCandidateMemberOptions,
        confirmRemoveUserFromGroup,
        confirmRemoveServiceAccountFromGroup,
        deleteGroup,
        updateUserSearch,
        updateServiceAccountSearch,
        updateWorkspaceSearch,
        updateAttributes,
        addAttributes,
        removeAttributes,
        showDeleteAttributeModal,
      }}
    >
      {/* We wait for all data need for the children to avoid using useState. By using react-query data directly, we avoid using useState hook*/}
      {isLoading ? <ACLLoadingContent showPanel /> : children}
    </ACLGroupProfileContextGQL.Provider>
  );
};
