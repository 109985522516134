import { GraphQLError } from 'graphql';
import React, { FC, ReactElement } from 'react';
import { EmptyPrompt } from './_eui_components';

interface GraphQLErrorProps {
  errorTitle: ReactElement<any>;
  graphQLErrors: GraphQLError[];
}

// TODO: We need design to review this component
const GraphQLErrorDisplay: FC<GraphQLErrorProps> = ({ errorTitle, graphQLErrors }) => {
  const errors = graphQLErrors?.map((e) => e.message).join(',');
  return <EmptyPrompt title={errorTitle} body={<p>{errors}</p>} />;
};

export default GraphQLErrorDisplay;
