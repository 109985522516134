import { sortBy } from 'lodash';
import { FeatureViewFCO, FeatureViewServingStatusSummaryWithId } from '../../../../core/types/fcoTypes';
import { FeatureViewServingStatusSummary, ServingState } from '../../../../types/tecton_proto/data/serving_status';
import { ServingStatusEvent, ServingStatusPanelTableDatum } from '../../../servingStatus/types';
import { extent } from 'd3-array';

export const restructureEvents = (data: FeatureViewServingStatusSummary): ServingStatusEvent[] => {
  const extractRanges =
    data.serving_status_summary &&
    data.serving_status_summary.batch_materialization_ranges &&
    data.serving_status_summary.batch_materialization_ranges.length > 0
      ? data.serving_status_summary.batch_materialization_ranges[0].batch_materialization_ranges
      : [];

  const eventsArray: ServingStatusEvent[] = [];

  extractRanges?.map((rangeDatum) => {
    const event: ServingStatusEvent = {
      startDate: new Date(rangeDatum.begin_inclusive as string),
      endDate: new Date(rangeDatum.end_exclusive as string),
      status: rangeDatum.status?.serving_state as ServingState,
    };

    eventsArray.push(event);
  });

  return eventsArray;
};

export const checkForStreamingStatus = (data: FeatureViewServingStatusSummary) => {
  if (data.serving_status_summary?.streaming_serving_status === undefined) {
    return { streamingStatus: undefined, streamingStartDate: undefined, streamingMostRecentDate: undefined };
  }

  return {
    streamingStatus: data.serving_status_summary?.streaming_serving_status.serving_state,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    streamingStartDate: new Date(data.serving_status_summary!.streaming_start_time!),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    streamingMostRecentDate: new Date(),
  };
};

export const restructureFeatureViews = (
  featureViews: FeatureViewFCO[],
  attemptStatuses: FeatureViewServingStatusSummaryWithId[],
  workspace: string
): ServingStatusPanelTableDatum[] => {
  const structuredDataForTable: any[] = [];
  const filteredFeatureViews: FeatureViewFCO[] = sortBy(
    featureViews.filter((featureView) => !featureView.isRealtime),
    (featureView) => featureView.name
  );

  filteredFeatureViews.forEach((featureView) => {
    const matchingSummary = attemptStatuses.find((attempt) => attempt.id === featureView.id);

    if (matchingSummary) {
      const events = restructureEvents(matchingSummary);
      const { streamingStatus, streamingStartDate, streamingMostRecentDate } = checkForStreamingStatus(matchingSummary);
      const mostRecentProcessedTime = matchingSummary.serving_status_summary?.most_recent_batch_processing_time
        ? new Date(matchingSummary.serving_status_summary?.most_recent_batch_processing_time)
        : undefined;

      structuredDataForTable.push({
        featureView: featureView,
        summary: matchingSummary,
        workspace: workspace,
        events: events,
        streamingStatus: streamingStatus,
        streamingStartDate: streamingStartDate,
        streamingMostRecentDate: streamingMostRecentDate,
        mostRecentProcessedTime: mostRecentProcessedTime,
      });
    }
  });

  return structuredDataForTable;
};

export const getEarliestStartDate = (data: ServingStatusPanelTableDatum[]) => {
  const allStartDates = data.map((datum) => datum.events.map((event) => event.startDate)).flat();

  return extent(allStartDates)[0] as Date;
};
