import React, { useEffect } from 'react';

const doScroll = (ref: React.RefObject<HTMLElement>, condition: boolean) => {
  if (ref && ref.current && condition) {
    ref.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  }
};

const useAutoscroll = (ref: React.RefObject<HTMLElement>, condition: boolean, timeoutSecond: number = 0) => {
  useEffect(() => {
    if (timeoutSecond) {
      setTimeout(() => {
        doScroll(ref, condition);
      }, timeoutSecond);
    } else {
      doScroll(ref, condition);
    }
  }, [condition, ref, timeoutSecond]);

  return () => {
    doScroll(ref, condition);
  };
};

export default useAutoscroll;
