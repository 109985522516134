import _ from 'lodash';

export enum DataPlatformSetupTaskStatusEnum {
  TASK_STATUS_UNKNOWN = 'TASK_STATUS_UNKNOWN',
  TASK_STATUS_NOT_STARTED = 'TASK_STATUS_NOT_STARTED',
  TASK_STATUS_RUNNING = 'TASK_STATUS_RUNNING',
  TASK_STATUS_SUCCEEDED = 'TASK_STATUS_SUCCEEDED',
  TASK_STATUS_FAILED = 'TASK_STATUS_FAILED',
}

export interface Task {
  task_display_name: string;
  task_status: DataPlatformSetupTaskStatusEnum;
  error_message: string;
}

export default class DataPlatformSetupStatus {
  private proto: any = null;

  constructor(proto: any) {
    this.proto = proto;
  }

  get _proto() {
    return this.proto;
  }

  get setupCompleted() {
    return _.get(this._proto, 'setupCompleted');
  }

  get tasks(): Task[] {
    return _.get(this._proto, 'tasks');
  }
}
