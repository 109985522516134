import colorVariables from './colorVariables.json';
import { V1ThemeType } from './emotion';

const sizes = {
  '0': 0,
  '0․5': 2,
  '1': 4,
  '1.5': 6,
  '2': 8,
  '3': 12,
  '4': 16,
  '5': 20,
  '8': 24,
  '6': 32,
  '10': 40,
  '12': 48,
  '16': 64,
  '20': 80,
  '24': 96,
  '32': 128,
  '40': 160,
  '48': 192,
  '56': 224,
  '64': 256,
  '80': 320,
  '96': 384,
  '120': 480,
  '140': 560,
  '160': 640,
  '180': 720,
  '192': 768,
  '256': 1024,
  '320': 1280,
  '360': 1440,
  '400': 1600,
  '480': 1920,
};

export const themeV1: (theme: 'light' | 'dark') => { v1: V1ThemeType } = (theme) => {
  return {
    v1: {
      size: sizes,
      radius: {
        '0': '0px',
        '2': '2px',
        '4': '4px',
        '6': '6px',
        '8': '8px',
        '10': '10px',
        '12': '12px',
        '16': '16px',
        '20': '20px',
        '24': '24px',
        '9999': '9999px',
      },
      shadow: {
        xs: `0px 2px 4px 0px ${colorVariables.color[theme]['core-background-shadow-light']}, 0px 1px 4px 0px ${colorVariables.color[theme]['core-background-shadow-light']}`,
        s: `0px 4px 4px 0px ${colorVariables.color[theme]['core-background-shadow-light']}, 0px 4px 8px 0px ${colorVariables.color[theme]['core-background-shadow-light']}`,
        m: `0px 4px 4px 0px ${colorVariables.color[theme]['core-background-shadow-light']}, 0px 8px 14px -2px ${colorVariables.color[theme]['core-background-shadow-light']}`,
        l: `0px 4px 4px 0px ${colorVariables.color[theme]['core-background-shadow-light']}, 0px 16px 24px -4px ${colorVariables.color[theme]['core-background-shadow-light']}`,
        xl: `0px 4px 4px 0px ${colorVariables.color[theme]['core-background-shadow-light']}, 0px 16px 44px -8px ${colorVariables.color[theme]['core-background-shadow-light']}`,
        flat: `0px 0px 0.8px 0px ${colorVariables.color[theme]['core-background-shadow-light']}, 0px 0px 2px 0px #0D0E0F28, 0px 0px 5px 0px #0D0E0F28, 0px 0px 17px 0px ${colorVariables.color[theme]['core-background-shadow-dark']}`,
      },
      colors: {
        text: {
          title: colorVariables.color[theme]['base-gray-950'],
          text: colorVariables.color[theme]['base-gray-900'],
          subduedText: colorVariables.color[theme]['base-gray-800'],
          disabledText: colorVariables.color[theme]['base-gray-600'],
          shortcutTextPrimary: colorVariables.color[theme]['base-gray-25'],
          invertedText: colorVariables.color[theme]['base-absolute-0'],
          disabledInvertedText: colorVariables.color[theme]['base-alpha-500'],
          successText: colorVariables.color[theme]['base-green-900'],
          warningText: colorVariables.color[theme]['base-yellow-900'],
          accentText: colorVariables.color[theme]['base-purple-900'],
          dangerText: colorVariables.color[theme]['base-red-900'],
          disabledDangerText: colorVariables.color[theme]['base-red-700'],
          link: colorVariables.color[theme]['base-absolute-1000'],
        },
        background: {
          empty: colorVariables.color[theme]['base-absolute-0'],
          default: colorVariables.color[theme]['base-gray-25'],
          hover: colorVariables.color[theme]['base-gray-50'],
          disabled: colorVariables.color[theme]['base-gray-100'],
          active: colorVariables.color[theme]['base-gray-200'],
          activeFocus: colorVariables.color[theme]['base-gray-300'],
          success: colorVariables.color[theme]['base-green-100'],
          accent: colorVariables.color[theme]['base-purple-25'],
          warning: colorVariables.color[theme]['base-yellow-50'],
          danger: colorVariables.color[theme]['base-red-25'],
          dangerPrimary: colorVariables.color[theme]['base-red-800'],
          dangerPrimaryHover: colorVariables.color[theme]['base-red-900'],
          secondary: colorVariables.color[theme]['base-gray-900'],
          primary: colorVariables.color[theme]['base-absolute-1000'],
          mask: colorVariables.color[theme]['base-alpha-inverse-600'],
          shadowDark: colorVariables.color[theme]['base-alpha-inverse-25'],
          shadowLight: colorVariables.color[theme]['base-alpha-inverse-200'],
        },
        icon: {
          primary: colorVariables.color[theme]['base-gray-950'],
          secondary: colorVariables.color[theme]['base-gray-800'],
          disabled: colorVariables.color[theme]['base-gray-600'],
          warning: colorVariables.color[theme]['base-yellow-800'],
          danger: colorVariables.color[theme]['base-red-800'],
          success: colorVariables.color[theme]['base-green-800'],
          accent: colorVariables.color[theme]['base-blue-800'],
          blueMist: colorVariables.color[theme]['base-blue-mist-800'],
          rose: colorVariables.color[theme]['base-rose-800'],
          violet: colorVariables.color[theme]['base-violet-800'],
          pink: colorVariables.color[theme]['base-pink-800'],
          orange: colorVariables.color[theme]['base-orange-800'],
        },
        border: {
          plain: colorVariables.color[theme]['base-absolute-0'],
          default: colorVariables.color[theme]['base-gray-200'],
          focus: colorVariables.color[theme]['base-gray-400'],
          warning: colorVariables.color[theme]['base-yellow-100'],
          danger: colorVariables.color[theme]['base-red-50'],
          dangerFocus: colorVariables.color[theme]['base-red-800'],
          secondary: colorVariables.color[theme]['base-gray-900'],
          primary: colorVariables.color[theme]['base-absolute-1000'],
        },
        viz: {
          '1-blueMist': {
            default: colorVariables.color[theme]['core-viz-1-blue-mist-default'],
            text: colorVariables.color[theme]['core-viz-1-blue-mist-text'],
            behindText: colorVariables.color[theme]['core-viz-1-blue-mist-behind-text'],
          },
          '2-rose': {
            default: colorVariables.color[theme]['core-viz-2-rose-default'],
            text: colorVariables.color[theme]['core-viz-2-rose-text'],
            behindText: colorVariables.color[theme]['core-viz-2-rose-behind-text'],
          },
          '3-violet': {
            default: colorVariables.color[theme]['core-viz-3-violet-default'],
            text: colorVariables.color[theme]['core-viz-3-violet-text'],
            behindText: colorVariables.color[theme]['core-viz-3-violet-behind-text'],
          },
          '4-pink': {
            default: colorVariables.color[theme]['core-viz-4-pink-default'],
            text: colorVariables.color[theme]['core-viz-4-pink-text'],
            behindText: colorVariables.color[theme]['core-viz-4-pink-behind-text'],
          },
          '5-yellow': {
            default: colorVariables.color[theme]['core-viz-5-yellow-default'],
            text: colorVariables.color[theme]['core-viz-5-yellow-text'],
            behindText: colorVariables.color[theme]['core-viz-5-yellow-behind-text'],
          },
          '6-orange': {
            default: colorVariables.color[theme]['core-viz-6-orange-default'],
            text: colorVariables.color[theme]['core-viz-6-orange-text'],
            behindText: colorVariables.color[theme]['core-viz-6-orange-behind-text'],
          },
          '7-blue': {
            default: colorVariables.color[theme]['core-viz-7-blue-default'],
            text: colorVariables.color[theme]['core-viz-7-blue-text'],
            behindText: colorVariables.color[theme]['core-viz-7-blue-behind-text'],
          },
          '8-red': {
            default: colorVariables.color[theme]['core-viz-8-red-default'],
            text: colorVariables.color[theme]['core-viz-8-red-text'],
            behindText: colorVariables.color[theme]['core-viz-8-red-behind-text'],
          },
          '9-purple': {
            default: colorVariables.color[theme]['core-viz-9-purple-default'],
            text: colorVariables.color[theme]['core-viz-9-purple-text'],
            behindText: colorVariables.color[theme]['core-viz-9-purple-behind-text'],
          },
          '10-green': {
            default: colorVariables.color[theme]['core-viz-10-green-default'],
            text: colorVariables.color[theme]['core-viz-10-green-text'],
            behindText: colorVariables.color[theme]['core-viz-10-green-behind-text'],
          },
        },
      },
    },
  };
};
