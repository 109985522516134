import React from 'react';
import { FlexItem, Accordion, Title } from '@tecton';

const PlanCategoryAccordion = ({ id, title, children }: { id: string; title: string; children: React.ReactNode }) => {
  return (
    <FlexItem id={id}>
      <Accordion
        id={id}
        buttonContent={
          <Title size="xs">
            <h4>{title}</h4>
          </Title>
        }
        initialIsOpen={true}
        arrowDisplay="right"
      >
        {children}
      </Accordion>
    </FlexItem>
  );
};

export default PlanCategoryAccordion;
