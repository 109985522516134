import React, { FC } from 'react';
import { Badge, useTectonTheme } from '.';
import { DataSourceType } from '../../types/tecton_proto/common/data_source_type';
import { ColorPair } from './TectonThemeProvider';
import { css } from '@emotion/css';

interface DataSourceCommonTypeBadgeProps {
  type: DataSourceType;
}

const DataSourceCommonTypeBadge: FC<DataSourceCommonTypeBadgeProps> = ({ type }) => {
  const { theme } = useTectonTheme();
  const categoryColors = theme.color.light.semanticColorPair;

  const typeLabelMap: Record<DataSourceType, string> = {
    [DataSourceType.BATCH]: 'Batch',
    [DataSourceType.STREAM_WITH_BATCH]: 'Stream',
    [DataSourceType.PUSH_NO_BATCH]: 'Ingest',
    [DataSourceType.PUSH_WITH_BATCH]: 'Ingest',
    [DataSourceType.UNKNOWN]: 'Unknown',
  };

  const dataSourceTypeColorPairs: Record<DataSourceType, ColorPair> = {
    [DataSourceType.BATCH]: categoryColors.NEUTRAL,
    [DataSourceType.PUSH_NO_BATCH]: categoryColors.DEFAULT,
    [DataSourceType.PUSH_WITH_BATCH]: categoryColors.DANGER,
    [DataSourceType.STREAM_WITH_BATCH]: categoryColors.WARNING,
    [DataSourceType.UNKNOWN]: categoryColors.NEUTRAL,
  };

  const colorPair = dataSourceTypeColorPairs[type];

  if (type) {
    return (
      <Badge color={colorPair.background}>
        {<span style={{ color: colorPair.foreground }}>{typeLabelMap[type]}</span>}
      </Badge>
    );
  }

  if (type) {
    return (
      <div style={{ pointerEvents: 'none' }}>
        <Badge color={colorPair.background}>
          {<span style={{ color: colorPair.background }}>{typeLabelMap[type]}</span>}
        </Badge>
      </div>
    );
  }

  return <></>;
};

export default DataSourceCommonTypeBadge;
