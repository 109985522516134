import { AnyFCO } from '../../core/types/fcoTypes';
import { CategoricalOptionInterface, FilterOptionType } from '../@tecton/CategoricalSelector';
import React from 'react';
import { FilterSelector } from '../@tecton/ComboFilterDropdown';
import { ColumnsOptionType } from '../shared/ColumnDisplayControls';
import { EuiSelectableOption } from '../@tecton';

export enum FilterCountsOptions {
  ZERO = '0',
  ONE = '1',
  TWO = '2',
  THREE_TO_FIVE = '3 - 5',
  SIX_TO_TEN = '6 - 10',
  MORE_THAN_TEN = '> 10',
}

export interface FCOListViewProps {
  title: string;
  searchPlaceholder: string;
  tabs?: React.ReactNode;
  columns: FCOListViewColumn[];
  items: AnyFCO[];
  children: React.ReactNode;
  sortParams?: FCOListSort;
  subtitle: React.ReactNode;
}

export enum FCOListFilterType {
  NUMBER = 'number',
  CATEGORY = 'category',
  DATE = 'date',
  TAGS = 'tags',
  BOOLEAN = 'boolean',
  COUNT = 'count',
}

export interface FCOListViewColumn {
  name: React.ReactNode;
  label: string;
  key: string;
  field?: string;
  isAlwaysActive?: boolean;
  isHiddenByDefault?: boolean;
  filterType?: FCOListFilterType;
  filterProps?:
    | FCONumericalFilterProps
    | FCOCategoricalFilterProps
    | FCODateFilterProps
    | FCOTagFilterProps
    | FCOBooleanFilterProps;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tableCellRender: (...args: any[]) => React.ReactNode;
}

export interface FCOBooleanFilterProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  renderProps: (option: { label: any }) => JSX.Element;
}

export interface FCOTagFilterProps {
  tags: Record<string, string[]>;
}

export interface FCONumericalFilterProps {
  step: number;
  selectorValue: [number, number];
}

export interface FCOCategoricalFilterProps {
  options: FilterOptionType[];
  renderProps?: (option: EuiSelectableOption<CategoricalOptionInterface>, searchValue: string) => React.ReactNode;
  isBoolean?: boolean;
  inlineFilterKey?: string;
  allowsFilterByCount?: boolean;
}

export interface FCODateFilterProps {
  dateRange: [Date, Date];
}

export interface FCOControlsStateProps {
  isColumnControlsOpen: boolean;
  setIsColumnControlsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  columnOptions: ColumnsOptionType[];
  setColumnOptions: (options: ColumnsOptionType[]) => void;
  numberOfSelectedColumnsOptions: number;
  activeColumns: string[];
}

export interface FCOTablePaginationProps {
  activePage: number;
  setActivePage: React.Dispatch<React.SetStateAction<number>>;
  numberOfPages: number;
  setNumberOfPages: React.Dispatch<React.SetStateAction<number>>;
  ITEMS_PER_PAGE: number;
  sortKey: string | undefined;
  setSortKey: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export interface FCOContextProps {
  controlsState: FCOControlsStateProps;
  filterTypes: Record<string, FilterSelector>;
  filteredItems: AnyFCO[];
  filterState: Record<string, FilterPropsOptions>;
  setFilterState: React.Dispatch<React.SetStateAction<Record<string, FilterPropsOptions>>>;
  numberOfActiveFilters: number;
  resetAllFilters: () => void;
}

export interface FCOTablePaginationState {
  activePage: number;
  numberOfPages: number;
  sortKey: string | undefined;
  ITEMS_PER_PAGE: number;
}

export interface FCOFilterConditionItem {
  key: string;
  currentValue: [number, number] | string[] | [Date, Date] | undefined;
  possibleValues: string[] | [number, number] | [Date, Date];
  type: 'number' | 'category' | 'date' | 'string' | undefined;
}

export type FilterPropsOptions =
  | string
  | { label: string; checked?: string }[]
  | [Date, Date]
  | [number, number]
  | { label: string; indent: number; parent?: string; checked?: 'on' | 'off' }[]
  | undefined
  | { label: 'isTrue' | 'isFalse'; checked?: string }[];

export interface Tag {
  key: string;
  value: string;
}

export interface FilterValuesMapByFilterType {
  [FCOListFilterType.CATEGORY]: Record<string, string[] | undefined>;
  [FCOListFilterType.COUNT]: Record<string, string[] | undefined>;
  [FCOListFilterType.DATE]: Record<string, Date[] | undefined>;
  [FCOListFilterType.NUMBER]: Record<string, number[] | undefined>;
  [FCOListFilterType.BOOLEAN]: Record<string, string[] | undefined>;
  [FCOListFilterType.TAGS]: Record<
    string,
    | {
        key: string;
        value: string;
      }[]
    | undefined
  >;
}

export type FCOListSortType = 'tags' | 'fcos' | 'date' | 'string' | 'number' | undefined;

export interface FCOListSort {
  key: string | undefined;
  order: 'asc' | 'desc' | undefined;
  type: FCOListSortType;
}

export interface TagsFilterOption {
  label: string;
  checked: string;
  option: string;
  parent?: string;
}
