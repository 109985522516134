import { useContext, useState } from 'react';
import { Spacer, DebouncedFieldSearch, Button, EuiComboBoxOptionOption } from '@tecton';
import { TectonBasicTable } from '@shared';

import { ACLTableControlLayout } from './ACLLayout';
import WorkspaceIDColumn from './WorkspaceIDColumn';
import ACLTableRowPropsFn from './ACLTableRowPropsFn';

import ColumnNameWithHelp from '../shared/ColumnNameWithHelp';
import TableActionButtonWithDisableMode from '../shared/TableActionButtonWithDisableMode';
import { useUserSettings } from '../context/UserSettingsContext';

import { AclRoleSource, AclRoleWithInheritance, AclWorkspaceServiceRelationship } from '../../api/gql/graphql';
import AllRolesPopoverGQL from './AllRolesPopoverGQL';
import ModifyWorkspaceRoleModalGQL from './modals/ModifyWorkspaceRoleModalGQL';
import { ACLServiceAccountProfileContextProps } from './ACLServiceAccountProfileContextGQL';
import { ACLUserProfileContextProps } from './ACLUserProfileContextGQL';
import { ACLPrincipalModal } from './aclUtils';
import { ACLContentParams } from './types';
import { PrincipalType } from '../../types/tecton_proto/auth/principal';
import UserProfileAddWorkspaceModalGQL from './modals/UserProfileAddWorkspaceModalGQL';

const ACLPrincipalWorkspacesGQL = <T extends ACLServiceAccountProfileContextProps | ACLUserProfileContextProps>({
  context,
  principalName,
  principalType,
}: ACLContentParams<T>) => {
  const principalProfileGQLContext = useContext(context);
  const [currentRole, setCurrentRole] = useState<string | undefined>(principalProfileGQLContext?.selectedRole);
  const [roleSources, setRoleSources] = useState<AclRoleSource[] | undefined>();
  const { isAdmin } = useUserSettings();

  const principalTypeName = principalType === PrincipalType.PRINCIPAL_TYPE_SERVICE_ACCOUNT ? 'service account' : 'user';

  let addWorkspaceModal;

  if (principalProfileGQLContext.modal === ACLPrincipalModal.ADD_WORKSPACE) {
    const addWorkspace = async () => {
      principalProfileGQLContext?.editAssignedWorkspace?.call(principalProfileGQLContext);
    };

    addWorkspaceModal = (
      <UserProfileAddWorkspaceModalGQL
        userId={principalName}
        allRoleRecords={principalProfileGQLContext?.allRoles ?? []}
        optionsQuery={{
          options: principalProfileGQLContext?.assignableWorkspaces,
          isLoading: principalProfileGQLContext?.isModalLoading ?? false,
          isError: false, // Fix this
          isSuccess: false, // Fix this
        }}
        selectedWorkspace={principalProfileGQLContext?.selectedWorkspace ?? []}
        setSelectedWorkspace={(ws: EuiComboBoxOptionOption<string>[]) => {
          principalProfileGQLContext?.updateSelectedWorkspace?.call(principalProfileGQLContext, ws);
        }}
        roleOptions={principalProfileGQLContext?.addNewWorkspaceRoleOptionsById ?? []}
        selectedRole={principalProfileGQLContext?.selectedRole ?? ''}
        setSelectedRole={(role: string) => {
          principalProfileGQLContext?.updateSelectedRole?.call(principalProfileGQLContext, role);
        }}
        isLoading={principalProfileGQLContext?.isModalLoading ?? false}
        errorMessages={principalProfileGQLContext?.errorMessages}
        onClose={() => {
          principalProfileGQLContext?.closeModal?.call(principalProfileGQLContext);
          principalProfileGQLContext?.clearSelectedWorkspaces?.call(principalProfileGQLContext);
          setCurrentRole(undefined);
          principalProfileGQLContext?.updateSelectedRole?.call(principalProfileGQLContext, undefined);
        }}
        confirmChange={addWorkspace}
      />
    );
  }

  let modifyWorkspaceModal;

  if (principalProfileGQLContext.modal === ACLPrincipalModal.MODIFY_ACCESS) {
    const confirmChanges = async () => {
      principalProfileGQLContext?.editAssignedWorkspace?.call(principalProfileGQLContext);
      setCurrentRole(undefined);
    };

    modifyWorkspaceModal = (
      <ModifyWorkspaceRoleModalGQL
        userId={principalName}
        allRoleRecords={principalProfileGQLContext?.allRoles ?? []}
        selectedRole={principalProfileGQLContext?.selectedRole ?? ''}
        setSelectedRole={(selectedRole: string) => {
          principalProfileGQLContext?.updateSelectedRole?.call(principalProfileGQLContext, selectedRole);
        }}
        workspaceId={principalProfileGQLContext?.selectedWorkspace?.[0].label ?? ''}
        currentRole={currentRole}
        roleOptions={principalProfileGQLContext?.editWorkspaceRoleOptionsById ?? []}
        currentSourcesOfRoles={roleSources}
        cancel={() => {
          principalProfileGQLContext?.closeModal?.call(principalProfileGQLContext);
          principalProfileGQLContext?.updateSelectedRole?.call(principalProfileGQLContext, undefined);
          principalProfileGQLContext?.clearSelectedWorkspaces?.call(principalProfileGQLContext);
          setCurrentRole(undefined);
          setRoleSources(undefined);
        }}
        isLoading={principalProfileGQLContext?.isModalLoading ?? false}
        errorMessages={principalProfileGQLContext?.errorMessages}
        confirmChanges={confirmChanges}
      />
    );
  }

  return (
    <div>
      <Spacer size="l" />
      {principalProfileGQLContext?.assignableWorkspaces && (
        <>
          <ACLTableControlLayout
            search={
              <DebouncedFieldSearch
                fullWidth
                placeholder="Search for Workspace"
                onChange={(event) => {
                  principalProfileGQLContext?.setPrincipalWorkspaceAssignRoleSearchFilter?.call(
                    principalProfileGQLContext,
                    event?.target?.value
                  );
                }}
              />
            }
            actionButtons={
              <Button
                fill
                onClick={() => {
                  principalProfileGQLContext?.updateModal?.call(
                    principalProfileGQLContext,
                    ACLPrincipalModal.ADD_WORKSPACE
                  );
                }}
                disabled={!isAdmin}
                title={isAdmin ? 'Assign Workspace Access' : 'Administrator Privileges Required'}
              >
                Assign Workspace Access
              </Button>
            }
          />
          <Spacer size="l" />
          <TectonBasicTable
            items={principalProfileGQLContext?.assignedWorkspacesWithRoles ?? []}
            rowProps={ACLTableRowPropsFn}
            columns={[
              {
                field: 'workspaceId',
                name: 'Workspace',
                render: (workspaceId: string, row: AclWorkspaceServiceRelationship) => {
                  return (
                    <WorkspaceIDColumn
                      workspaceId={workspaceId}
                      hasPriority={row.hasPriority}
                      principalType={principalTypeName}
                    />
                  );
                },
              },
              {
                field: 'role',
                name: (
                  <ColumnNameWithHelp
                    name={'Role'}
                    helpContent={`A ${principalTypeName}'s role can be directly assigned, inherited from a group, or set via their default workspace role. If there are multiple roles, Tecton will use the highest role as the effective role, as shown in this column.`}
                  />
                ),
                render: (role: AclRoleWithInheritance) => {
                  return <AllRolesPopoverGQL roleWithInheritance={role} />;
                },
              },
              {
                name: 'Actions',
                width: '5em',
                actions: [
                  {
                    name: 'Edit',
                    isPrimary: true,
                    render: (row: AclWorkspaceServiceRelationship) => {
                      return (
                        <TableActionButtonWithDisableMode
                          isAdmin={isAdmin ?? false}
                          requiresAdmin
                          iconType={'pencil'}
                          isDisabled={false}
                          enabledMessage={`Edit role of ${principalName} in this workspace`}
                          disabledMessage={'Role is inherited from group and cannot be modified here'}
                          onClick={() => {
                            principalProfileGQLContext?.setErrorMessages?.call(principalProfileGQLContext, undefined);
                            principalProfileGQLContext?.updateModal?.call(
                              principalProfileGQLContext,
                              ACLPrincipalModal.MODIFY_ACCESS
                            );

                            const selectedWorkspace = [{ label: row.workspaceId }];

                            principalProfileGQLContext?.updateSelectedWorkspace?.call(
                              principalProfileGQLContext,
                              selectedWorkspace
                            );

                            principalProfileGQLContext?.updateSelectedRole?.call(
                              principalProfileGQLContext,
                              row?.role?.effectiveWorkspaceRole
                            );

                            setCurrentRole(row?.role?.effectiveWorkspaceRole);
                            setRoleSources(row?.role?.roleSources);
                          }}
                        />
                      );
                    },
                  },
                ],
              },
            ]}
            pageIndex={0}
            pageSize={0}
            totalItemCount={0}
          />
        </>
      )}
      {addWorkspaceModal}
      {modifyWorkspaceModal}
    </div>
  );
};

export default ACLPrincipalWorkspacesGQL;
