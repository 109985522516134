import { extent } from 'd3-array';
import { ScaleTime, scaleTime } from 'd3-scale';
import React, { useState, FC, createContext } from 'react';

import {
  FeatureAndMetricMap,
  MaterializationEvent,
  MaterializationJob,
  MaterializationMetricResolutionGroups,
  MaterializationMetricTypes,
  MaterializationQueryFeatureResult,
  dateTickIntervalsMap,
  MaterializationQueryMetricResponse,
} from './DataQualityMetricsTypes';
import {
  rollUpJobs,
  parseMetricTime,
  determineTimeResolution,
  getDateTicks,
  getNearestEventToDate,
} from './DataQualityMetricsUtils';
import { isEqual } from 'lodash';
import { FeatureViewFCO } from '../../../../core/types/fcoTypes';
import { determineMaxNumberOfEventsToDisplayInUi } from '../../../../feature/feature-views/feature-view/component/BatchMaterializationQualityMonitoringPanel/utils';

// Manages internal state for the Data Quality Monitoring panel
export interface DataQualityMonitoringContextProps {
  allDates: Date[];
  bandWidth: number;
  canvasTranslate: number;
  chartScale: ScaleTime<number, number, never>;
  createdDate: Date;
  dateLabelInterval: number;
  dataAreTruncated: boolean;
  dateTicks: Date[];
  endDate: Date | undefined;
  externalHighlightsWereSelected: (events: MaterializationEvent[] | undefined) => void;
  externalHandleDrag: (attemptedStartDate: Date) => void;
  featureMetrics: MaterializationQueryFeatureResult | undefined;
  featureMetricsTypes: FeatureAndMetricMap | undefined;
  fullChartWidth: number;
  handleIndicesSelection: (indices: number[] | undefined) => void;
  highlightedEvents?: MaterializationEvent[];
  highlightedIndices?: number[];
  isReady: boolean;
  jobs: MaterializationJob[];
  joinKeys: MaterializationEvent[];
  majorTicks: Date[];
  rowCountsData: MaterializationEvent[];
  startDate: Date | undefined;
  showTime: boolean;
  timeResolution: number | undefined;
  timeComparisonBasis: MaterializationMetricResolutionGroups;
  transform: string;
  usableWidth: number | undefined;
  width: number;
  windowSize: number;
  selectedMetric: MaterializationMetricTypes;
  setWindowStartDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
  setWindowEndDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
  setCanvasTranslate: React.Dispatch<React.SetStateAction<number>>;
  setSelectedMetric: React.Dispatch<React.SetStateAction<MaterializationMetricTypes>>;
  setTransform: React.Dispatch<React.SetStateAction<string>>;
  setHighlightedEvents: React.Dispatch<React.SetStateAction<MaterializationEvent[] | undefined>>;
  setHighlightedIndices: React.Dispatch<React.SetStateAction<number[] | undefined>>;
  setWindowSize: React.Dispatch<React.SetStateAction<number>>;
  setChartColumnWidth: React.Dispatch<React.SetStateAction<number>>;
  setUsableWidth: React.Dispatch<React.SetStateAction<number | undefined>>;
  tableWidthWasSet: (usableWidth: number) => void;
  chartColumnWidth: number;
}

const setStatePlaceHolder = () => {
  return;
};

const defaultValue: DataQualityMonitoringContextProps = {
  allDates: [],
  bandWidth: 0,
  canvasTranslate: 0,
  chartScale: scaleTime(),
  createdDate: new Date(),
  dateLabelInterval: 0,
  dateTicks: [],
  dataAreTruncated: false,
  endDate: new Date(),
  externalHighlightsWereSelected: () => {
    return;
  },
  externalHandleDrag: () => {
    return;
  },
  featureMetrics: undefined,
  featureMetricsTypes: undefined,
  fullChartWidth: 0,
  handleIndicesSelection: () => {
    return;
  },
  isReady: false,
  jobs: [],
  joinKeys: [],
  majorTicks: [],
  rowCountsData: [],
  startDate: undefined,
  showTime: false,
  timeResolution: undefined,
  timeComparisonBasis: MaterializationMetricResolutionGroups.TIME_RESOLUTION_ONE_MINUTE,
  transform: '',
  usableWidth: undefined,
  width: 0,
  windowSize: 0,
  selectedMetric: MaterializationMetricTypes.COUNT_DISTINCT,
  setWindowStartDate: setStatePlaceHolder,
  setWindowEndDate: setStatePlaceHolder,
  setCanvasTranslate: setStatePlaceHolder,
  setSelectedMetric: setStatePlaceHolder,
  setTransform: setStatePlaceHolder,
  setHighlightedEvents: setStatePlaceHolder,
  setHighlightedIndices: setStatePlaceHolder,
  setWindowSize: setStatePlaceHolder,
  setChartColumnWidth: setStatePlaceHolder,
  setUsableWidth: setStatePlaceHolder,
  tableWidthWasSet: () => {
    return;
  },
  chartColumnWidth: 0,
};

export const DataQualityMonitoringContext = createContext<DataQualityMonitoringContextProps>(defaultValue);

interface DataQualityMonitoringContextProviderProps {
  children: React.ReactNode;
  featureView: FeatureViewFCO;
  rowCountsData: any;
  joinKeysData: any;
  featureData: any;
  featureMetricsTypesData: any;
}

const bandWidth = 8;

export const DataQualityMonitoringContextProvider: FC<DataQualityMonitoringContextProviderProps> = ({
  children,
  featureView,
  rowCountsData,
  joinKeysData,
  featureData,
  featureMetricsTypesData,
}) => {
  const [chartColumnWidth, setChartColumnWidth] = useState(0);
  const [windowSize, setWindowSize] = useState(60);
  const [highlightedEvents, setHighlightedEvents] = useState<MaterializationEvent[] | undefined>(undefined);
  const [highlightedIndices, setHighlightedIndices] = useState<number[] | undefined>(undefined);
  const [canvasTranslate, setCanvasTranslate] = useState(0);
  const [selectedMetric, setSelectedMetric] = useState(MaterializationMetricTypes.COUNT_NULLS);
  const [transform, setTransform] = useState(`translate(0,0)`);
  const [usableWidth, setUsableWidth] = useState<undefined | number>(undefined);
  const [windowStartDate, setWindowStartDate] = useState<Date | undefined>(undefined);
  const [windowEndDate, setWindowEndDate] = useState<Date | undefined>(undefined);

  const createdDate = featureView.createdDate!;

  const metricResponse: MaterializationQueryMetricResponse = {
    aligned_end_time: '',
    aligned_start_time: '',
    column_names: [],
    feature_view_name: '',
    metric_data: [],
    metric_data_point_interval: '',
    metric_type: MaterializationMetricTypes.COUNT_DISTINCT,
    workspace: '',
  };

  const context: DataQualityMonitoringContextProps = {
    allDates: [],
    bandWidth: bandWidth,
    canvasTranslate: canvasTranslate,
    chartScale: scaleTime(),
    createdDate: createdDate,
    dataAreTruncated: false,
    dateTicks: [],
    dateLabelInterval: 1,
    endDate: windowEndDate,
    featureMetrics: featureData,
    featureMetricsTypes: undefined,
    fullChartWidth: 0,
    highlightedEvents: highlightedEvents,
    highlightedIndices: highlightedIndices,
    isReady: false,
    joinKeys: [],
    jobs: [],
    majorTicks: [],
    rowCountsData: [],
    selectedMetric: selectedMetric,
    showTime: true,
    startDate: windowStartDate,
    timeComparisonBasis: MaterializationMetricResolutionGroups.TIME_RESOLUTION_ONE_WEEK,
    timeResolution: undefined,
    transform: transform,
    usableWidth: usableWidth,
    width: chartColumnWidth,
    windowSize: windowSize,
    setWindowEndDate: setWindowEndDate,
    setWindowStartDate: setWindowStartDate,
    setCanvasTranslate: setCanvasTranslate,
    setTransform: setTransform,
    setHighlightedEvents: setHighlightedEvents,
    setHighlightedIndices: setHighlightedIndices,
    setWindowSize: setWindowSize,
    setChartColumnWidth: setChartColumnWidth,
    setUsableWidth: setUsableWidth,
    setSelectedMetric: setSelectedMetric,
    chartColumnWidth: chartColumnWidth,
    tableWidthWasSet: (usableWidth: number) => {
      runLayout(context, usableWidth);
    },
    handleIndicesSelection: (indices: number[] | undefined) => {
      indicesSelected(indices, context);
    },
    externalHighlightsWereSelected: (events: MaterializationEvent[] | undefined) => {
      highlightsWereSelected(events, context);
    },
    externalHandleDrag: (attemptedStartDate: Date) => {
      handleDrag(attemptedStartDate, context);
    },
  };

  if (rowCountsData && joinKeysData && featureData) {
    context.rowCountsData = rowCountsData.data;
    context.joinKeys = joinKeysData.data;

    const jobs = rollUpJobs(rowCountsData);

    context.jobs = jobs;
    context.featureMetricsTypes = featureMetricsTypesData;

    let allDates: Date[] = [];
    context.chartScale = scaleTime();
    let fullChartWidth = 0;

    context.timeResolution = rowCountsData && parseMetricTime(rowCountsData.apiResponse.metric_data_point_interval);
    context.showTime = context.timeResolution ? context.timeResolution < 86400000 : false;

    context.timeComparisonBasis = determineTimeResolution(context.timeResolution ?? 0);

    allDates = rowCountsData && context.rowCountsData.map((datum: MaterializationEvent) => datum.date);
    context.allDates = allDates;
    fullChartWidth = context.rowCountsData ? bandWidth * context.rowCountsData.length : 0;

    const scaleRange = [-fullChartWidth, -bandWidth];
    context.chartScale.domain(extent(allDates as Date[]) as [Date, Date]).range(scaleRange);

    context.dateTicks = getDateTicks(context.timeComparisonBasis, allDates);

    context.dateLabelInterval = dateTickIntervalsMap[context.timeComparisonBasis];
    context.majorTicks = context.dateTicks.filter((_, index) => index % context.dateLabelInterval === 0);

    if (determineMaxNumberOfEventsToDisplayInUi(context.timeResolution as number) === context.rowCountsData.length) {
      context.dataAreTruncated = true;
    }

    context.isReady = true;
  }

  return <DataQualityMonitoringContext.Provider value={context}>{children}</DataQualityMonitoringContext.Provider>;
};

const runLayout = (context: DataQualityMonitoringContextProps, usableWidth: number) => {
  if (!context.rowCountsData || !context.allDates) {
    return;
  }

  const correctSize = Math.floor(usableWidth / context.bandWidth);

  let endDate;
  if (context.endDate === undefined) {
    const allDates: Date[] = context.rowCountsData.map((datum: MaterializationEvent) => datum.date);

    endDate = allDates[allDates.length - 1];
  } else {
    endDate = context.endDate;
  }

  const allTimes = context.allDates.map((date) => date.getTime());
  const endIndex = allTimes.indexOf(endDate.getTime());
  const getStartIndex = endIndex - correctSize;
  const startIndex = getStartIndex <= 0 ? 0 : getStartIndex;
  const startDate = context.allDates[startIndex];

  context.setWindowStartDate(startDate);
  context.setChartColumnWidth(usableWidth);
  context.setWindowSize(Math.floor(context.chartColumnWidth / context.bandWidth));
  const correctedTransform = `translate(${-context.chartScale(startDate) - context.bandWidth},0)`;
  context.setCanvasTranslate(
    -context.chartScale(startDate) - context.bandWidth + context.chartScale.range()[0] + context.chartScale.range()[1]
  );
  context.setTransform(correctedTransform);
  context.setWindowEndDate(endDate);
  context.setUsableWidth(usableWidth);
};

const handleDrag = (attemptedStartDate: Date, props: DataQualityMonitoringContextProps) => {
  if (props.usableWidth) {
    const correctSize = Math.floor(props.usableWidth / props.bandWidth);
    if (!props.rowCountsData) {
      return;
    }

    const dates = props.rowCountsData.map((datum) => datum.date);
    const roundedStart = getNearestEventToDate(attemptedStartDate, dates);
    const roundedStartTime = roundedStart.getTime();
    const allTimes = dates.map((date) => date.getTime()).sort();
    const startIndex = allTimes.indexOf(roundedStartTime);

    if (startIndex + correctSize >= allTimes.length) {
      // Jump to start if we're passed it
      const startDate = props.allDates[props.allDates.length - correctSize - 1];
      handleDrag(startDate, props);

      return;
    }

    props.setWindowStartDate(roundedStart);
    props.setWindowEndDate(new Date(allTimes[startIndex + correctSize]));

    const correctedTransform = `translate(${-props.chartScale(roundedStart) - props.bandWidth},0)`;
    props.setCanvasTranslate(
      -props.chartScale(roundedStart) - props.bandWidth + props.chartScale.range()[0] + props.chartScale.range()[1]
    );
    props.setTransform(correctedTransform);
  }
};

const indicesSelected = (indices: number[] | undefined, props: DataQualityMonitoringContextProps) => {
  if (isEqual(indices, props.highlightedIndices)) {
    return;
  }

  props.setHighlightedIndices(indices);
};

const highlightsWereSelected = (
  events: MaterializationEvent[] | undefined,
  props: DataQualityMonitoringContextProps
) => {
  // Only update highlighted indices if there's a chang
  if (isEqual(events, props.highlightedEvents)) {
    return;
  }

  props.setHighlightedEvents(events);
};
