import { FC } from 'react';
import { Badge } from '@tecton/ComponentRedesign';

interface EnabledDisabledBadgeProps {
  enabled: boolean;
}

const EnabledDisabledBadge: FC<EnabledDisabledBadgeProps> = ({ enabled }) => {
  if (enabled) {
    return <Badge variant={'custom'} label={'Enabled'} />;
  }

  return <Badge variant={'hollow'} data-testid="enabled-disabled-badge" label={'Disabled'} />;
};

export default EnabledDisabledBadge;
