import MetadataService from '../service/MetadataService';
import { useQuery } from '@tanstack/react-query';

export function useWorkspaceList(enabled = true) {
  const state = useQuery(
    ['workspaceList'],
    () => {
      const data = MetadataService.sharedInstance().getAvailableWorkspaces();
      return data;
    },
    {
      enabled,
    }
  );
  if (state.error) {
    throw state.error;
  }
  return state;
}

export function useWorkspace(workspace) {
  const state = useQuery(['workspace', workspace], () => {
    const data = MetadataService.sharedInstance().getWorkspace(workspace);
    return data;
  });
  if (state.error) {
    throw state.error;
  }
  return state;
}
