import styled from '@emotion/styled';
import React, { FC } from 'react';

import { ReactComponent as LogoMark } from './BrandAssets/Logomark.svg';
import { ReactComponent as Logotype } from './BrandAssets/Logotype.svg';

type BrandImageSize = 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxxl';
type BrandImageType = 'mark' | 'type';
type BrandColor = 'brand' | 'full' | 'half';

interface BrandIconProps {
  size?: BrandImageSize;
  type?: BrandImageType;
  color?: BrandColor;
}

const wordmarkWidthMap: Record<BrandImageSize, string> = {
  xs: '48px', // TODO: These appear to be related to the size primitive
  s: '96px',
  m: '180px',
  l: '300px',
  xl: '600px',
  xxxl: '1166px',
};

const logoWidthMap: Record<BrandImageSize, string> = {
  xs: '48px',
  s: '96px',
  m: '180px',
  l: '300px',
  xl: '512px',
  xxxl: '1200px',
};

const Container = styled.div<BrandIconProps>`
  width: ${({ size, type }) => {
    return type === 'mark' ? wordmarkWidthMap[size!] : logoWidthMap[size!];
  }};

  overflow: visible;
  display: inline-block;

  path {
    stroke: none;
    fill: ${({ color, theme }) => {
      const colorTypeMap: Record<BrandColor, string> = {
        brand: theme.colors.primary,
        full: theme.colors.fullShade,
        half: theme['v1'].colors.text.disabledText,
      };

      return colorTypeMap[color!];
    }};
  }
`;

const BrandImage: FC<BrandIconProps> = ({ size = 'm', type = 'mark', color = 'brand' }) => {
  return (
    <Container size={size} type={type} color={color}>
      {type === 'mark' ? <LogoMark /> : <Logotype />}
    </Container>
  );
};

export default BrandImage;
