/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useQuery, useInfiniteQuery } from '@tanstack/react-query';
import { uniq } from 'lodash';
import { Metadata_Service } from '.';
import { ExpectationResultEnum, ResultSummary } from '../types/tecton_proto/dataobs/validation';
import {
  GetFeatureValidationResultRequest,
  GetFeatureValidationResultResponse,
  GetFeatureValidationSummaryRequest,
  GetFeatureValidationSummaryResponse,
  GetMetricAndExpectationDefinitionRequest,
  GetMetricAndExpectationDefinitionResponse,
} from '../types/tecton_proto/metadataservice/metadata_service';
import {
  ValidationRecord,
  ValidationsFilterState,
  ValidationsMetricsExpectation,
  ValidationSummaryRecordDatum,
  ValidationSummaryRecords,
} from '../components/validations/types';
import useGlobalState from '../shared/useGlobalState';

export const useIsValidationsEnabled = () => {
  // This hook isn't strictly necessary for now, since we're only checking localStorage.
  // But including it to fill it in when the logic for showing / hiding validations gets more complex
  const { config } = useGlobalState();

  if (config.DATA_OBSERVABILITY_VALIDATION_ENABLED === 'true') {
    return true;
  }
};

const fetchValidations = async (
  args: GetFeatureValidationResultRequest
): Promise<GetFeatureValidationResultResponse> => {
  const response = await Metadata_Service('get-feature-validation-result', { data: args, method: 'POST' });
  return response.data;
};

export const useValidationsDataQuery = (
  workspace: string,
  filterState: ValidationsFilterState,
  keepPreviousData: boolean
) => {
  return useInfiniteQuery(
    ['get-feature-validation-result', workspace, filterState],
    ({ pageParam = '', signal }) =>
      fetchValidations({
        workspace: workspace,
        validation_start_time: filterState.startDate.toISOString(),
        validation_end_time: filterState.endDate.toISOString(),
        filter_feature_view_names: filterState.featureViews,
        filter_expectation_names: filterState.expectations,
        filter_result_types: filterState.statuses,
        pagination: {
          page_token: pageParam || '',
          per_page: 25,
        },
      }),
    {
      refetchOnWindowFocus: false,
      keepPreviousData,
      getNextPageParam: (lastPage) => {
        return lastPage?.pagination?.next_page_token;
      },
      select: (data) => {
        const validationRecords = data.pages.flatMap((page) =>
          (page.results ?? []).map((datum) => {
            const asValidationRecord: ValidationRecord = {
              id: datum.result_id!.most_significant_bits! + '_' + datum.result_id!.least_significant_bits!,
              status: datum.result!,
              validationTime: new Date(datum.validation_time!),
              featureViewName: datum.feature_view_name!,
              featureIntervalStartTime: new Date(datum.feature_interval_start_time!),
              featureIntervalEndTime: datum.feature_interval_end_time
                ? new Date(datum.feature_interval_end_time)
                : undefined,
              recordType: datum.metric_expectation_metadata ? 'metric' : 'feature',
              expectationName: '',
            };

            if (datum.metric_expectation_metadata) {
              asValidationRecord.expectationName = datum.metric_expectation_metadata.expectation!.display_name!;

              const inputMetrics: { metric: string; value: number }[] = [];

              datum.metric_expectation_metadata.param_values?.forEach((parameterDatum) => {
                const match = datum.metric_expectation_metadata?.expectation?.input_metrics?.find(
                  (input) => input.column_name === parameterDatum.metric_name
                )?.column_name;
                inputMetrics.push({
                  value: +parameterDatum.actual_value!,
                  metric: match!,
                });
              });

              const metricMetadata: ValidationsMetricsExpectation = {
                name: datum.metric_expectation_metadata.expectation!.display_name!,
                alertMessage: datum.metric_expectation_metadata.alert_msg,
                inputMetrics: inputMetrics ? inputMetrics : [],
                lastUpdateTime: new Date(datum.metric_expectation_metadata.expectation!.last_update_time!),
                createdTime: new Date(datum.metric_expectation_metadata.expectation!.creation_time!),
                expression: datum.metric_expectation_metadata!.expectation!,
              };

              asValidationRecord.metricExpectationMetadata = metricMetadata;
            }

            return asValidationRecord;
          })
        );

        return {
          pageParams: data.pageParams,
          pages: validationRecords,
        };
      },
      notifyOnChangeProps: ['data', 'error', 'isFetching', 'isLoading', 'isFetchingNextPage', 'hasNextPage'],
    }
  );
};

const fetchValidationsSummary = async (
  args: GetFeatureValidationSummaryRequest
): Promise<GetFeatureValidationSummaryResponse> => {
  const response = await Metadata_Service('get-feature-validation-summary', { data: args, method: 'POST' });
  return response.data;
};

// MOVE TO UTILS?
const castApiResultsAsValidationSummaryRecordDatum: (
  results: ResultSummary | undefined
) => ValidationSummaryRecordDatum = (results) => {
  return {
    [ExpectationResultEnum.RESULT_PASSED]: results?.passed ?? 0,
    [ExpectationResultEnum.RESULT_FAILED]: results?.failed ?? 0,
    [ExpectationResultEnum.RESULT_UNKNOWN]: results?.unknown ?? 0,
    [ExpectationResultEnum.RESULT_ERROR]: results?.error ?? 0,
    total: results ? Array.from(Object.values(results)).reduce((a, b) => a + b) : 0,
  };
};

const extractExpectationsFromSummaryResults: (data: GetFeatureValidationSummaryResponse) => (string | undefined)[] = (
  data
) => {
  return uniq(
    data.workspace_summary?.feature_view_summary
      ?.map((summary) => {
        return summary.expectation_summary?.map((expectationSummary) => expectationSummary.expectation_name);
      })
      .flat()
  );
};

export const useValidationsSummaryQuery = (workspace: string, startDate: Date, endDate: Date) => {
  return useQuery(
    ['get-feature-validation-summary', workspace, startDate, endDate],
    () =>
      fetchValidationsSummary({
        workspace: workspace,
        validation_start_time: startDate.toISOString(),
        validation_end_time: endDate.toISOString(),
      }),
    {
      select: (data) => {
        const toReturn: ValidationSummaryRecords = {
          workspace: workspace,
          workspaceResults: castApiResultsAsValidationSummaryRecordDatum(data.workspace_summary?.summary),
          featureViews: data.workspace_summary!.feature_view_summary!.map((fv) => {
            return {
              name: fv.feature_view_name!,
              results: castApiResultsAsValidationSummaryRecordDatum(fv.summary),
            };
          }),
          expectations: (extractExpectationsFromSummaryResults(data) as string[]) ?? [],
        };

        return toReturn;
      },
    }
  );
};

const fetchMetricAndExpectationDefinition = async (
  args: GetMetricAndExpectationDefinitionRequest
): Promise<GetMetricAndExpectationDefinitionResponse> => {
  // Don't request from the backend unless there's a valid feature view passed in
  if (args.feature_view_name === undefined) {
    return {};
  }
  const response = await Metadata_Service('get-metric-and-expectation-definition', { data: args, method: 'POST' });
  return response.data;
};

export const useExpectationDefinitionQuery = (workspace: string, featureView: string | undefined) => {
  return useQuery(['get-metric-and-expectation-definition', workspace, featureView], () =>
    fetchMetricAndExpectationDefinition({
      workspace: workspace,
      feature_view_name: featureView,
    })
  );
};
