import React, { FC } from 'react';
import { FCO, FCOFields } from '../../core/types/fcoTypes';
import { AttributesList, DateDisplay, FCOCard, Monospace } from '../@tecton';
import FCOOwnerBadge from '../fcoListView/FCOOwnerBadge';
import EnabledDisabledMaterializationBadge from '../@tecton/EnabledDisabledMaterializationBadge';

interface FCODetailsMetadataCardProps {
  fco: FCO;
}

const FCODetailsMetadataCard: FC<FCODetailsMetadataCardProps> = ({ fco }) => {
  const list = [
    {
      title: <>ID</>,
      description: <>{fco[FCOFields.ID]}</>,
    },

    {
      title: <>Owner</>,
      description: <FCOOwnerBadge fco={fco} />,
    },
    {
      title: <>Created</>,
      description: <DateDisplay date={fco[FCOFields.CREATED_DATE]} />,
    },
    {
      title: <>Last Updated By</>,
      description: <>{fco[FCOFields.LAST_MODIFIED_BY]}</>,
    },
    {
      title: <>Last Updated</>,
      description: <DateDisplay date={fco[FCOFields.LAST_MODIFIED_DATE]} />,
    },
    {
      title: <>Defined In</>,
      description: <Monospace>{fco[FCOFields.SOURCE_FILE_NAME]}</Monospace>,
    },
    {
      title: <>Prevent Destroy</>,
      description: <EnabledDisabledMaterializationBadge mode={'online'} value={fco[FCOFields.PREVENT_DESTROY]} />,
    },
  ];

  return (
    <FCOCard title="Metadata">
      <AttributesList compressed={false} listItems={list} />
    </FCOCard>
  );
};

export default FCODetailsMetadataCard;
