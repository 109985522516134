import { ACLWorkspaceServiceRelationship } from './ACLServiceAccountWorkspaces';
import {
  ACLGroupMemberType,
  ACLGroupServiceAccountType,
  ACLGroupWorkspaceType,
  ACLInheritanceEnum,
  ACLRoleSource,
  AccountType,
  CandidateMemberOption,
  InheritedAccountType,
  ACLServiceAccountTableItem,
  ACLWorkspaceMembershipType,
  ACLGroupTableItem,
} from './types';
import {
  AssignmentBasicV2,
  ListAssignedPrincipalsResponse,
  RoleDefinition,
} from '../../types/tecton_proto/auth/authorization_service';
import { ResourceType } from '../../types/tecton_proto/auth/resource';
import {
  ResourceAndRoleAssignmentsV2,
  RoleAssignmentSource,
  RoleAssignmentSummary,
  RoleAssignmentType,
} from '../../types/tecton_proto/auth/resource_role_assignments';
import _compact from 'lodash/compact';
import { ServiceAccount } from '../../types/tecton_proto/metadataservice/metadata_service';
import { User } from '../../types/tecton_proto/data/user';
import _sortBy from 'lodash/sortBy';
import { PrincipalGroupMember } from '../../types/tecton_proto/principal/principal_service';
import { Workspace } from '../../types/tecton_proto/data/workspace';
import { EuiComboBoxOptionOption } from '../@tecton';
import compact from 'lodash/compact';
import { GroupBasic } from '../../types/tecton_proto/auth/principal';
import sortBy from 'lodash/sortBy';
import {
  AclGroupWorkspaceType,
  AclWorkspaceServiceRelationship as AclWorkspaceServiceRelationshipGQL,
  RoleDefinition as RoleDefinitionGQL,
  ServiceAccount as ServiceAccountGQL,
} from '../../api/gql/graphql';

export const transformUsers = (data: ListAssignedPrincipalsResponse): (AssignmentBasicV2 | undefined)[] | undefined => {
  const userAccountBasic = data.assignments?.filter((assignmentBasic: AssignmentBasicV2) => {
    return !!assignmentBasic?.principal?.user;
  });

  return userAccountBasic;
};

// TODO: remove this function once we migrate over to GQL
export const getLegacyRoleId = (roleDefinition: RoleDefinition[], role: string): string => {
  if (role.toLowerCase() === 'none') {
    return 'none';
  }
  return (
    roleDefinition.find((roleDef) => {
      return role === roleDef.name;
    })?.legacy_id ?? 'none'
  );
};

// IU had to make a copy of this since I wanted to use the RoleDefinitionGQL which comes from GQL
export const getLegacyRoleIdGQL = (roleDefinition: RoleDefinitionGQL[], role: string): string => {
  if (role.toLowerCase() === 'none') {
    return 'none';
  }
  const value = roleDefinition.find((roleDef) => {
    return role === roleDef.name;
  })?.legacyId;

  return value ?? 'none';
};

export const getRoleName = (roleDefinition: RoleDefinition[], role: string): string => {
  return (
    roleDefinition.find((roleDef) => {
      return role === roleDef.legacy_id;
    })?.name ?? ''
  );
};

export const getRoleNameGQL = (roleDefinitions: RoleDefinitionGQL[], role: string): string => {
  const roleDefinition = roleDefinitions.find((roleDef) => {
    return role === roleDef.legacyId;
  });

  return roleDefinition?.name ?? 'None';
};

const getRoleGroupName = (
  roleAssignmentSources?: RoleAssignmentSource[],
  assignment_type?: RoleAssignmentType
): RoleAssignmentSource | undefined => {
  return roleAssignmentSources?.find((r) => {
    return r.assignment_type === assignment_type;
  });
};

const getProfileRoleSources = (
  grantedRoles?: (RoleAssignmentSummary | undefined)[],
  resourceType?: ResourceType,
  roleDefinitions?: RoleDefinition[]
): ACLRoleSource[] => {
  const sortedGrantedRole =
    grantedRoles?.map((roleAssignmentSummary) => {
      const assignmentType = roleAssignmentSummary?.role_assignment_sources?.[0].assignment_type;
      const type =
        resourceType === ResourceType.RESOURCE_TYPE_ORGANIZATION
          ? 'via all workspaces role'
          : assignmentType === RoleAssignmentType.ROLE_ASSIGNMENT_TYPE_DIRECT
          ? 'direct'
          : 'via group';

      const role = getRoleName(roleDefinitions ?? [], roleAssignmentSummary?.role ?? '');
      const roleAssignmentSource = getRoleGroupName(
        roleAssignmentSummary?.role_assignment_sources ?? [],
        RoleAssignmentType.ROLE_ASSIGNMENT_TYPE_FROM_PRINCIPAL_GROUP
      );

      return {
        role,
        type,
        groupName: roleAssignmentSource?.principal_group_name ?? '',
        groupId: roleAssignmentSource?.principal_group_id,
      } as ACLRoleSource;
    }) ?? [];

  return sortRolesForACLRoleSource(sortedGrantedRole, roleDefinitions ?? []);
};

export const transformACLRole = (
  assignments: ResourceAndRoleAssignmentsV2[],
  roleDefinitions: RoleDefinition[]
): ACLWorkspaceServiceRelationship[] => {
  const foundAllWorkspace = assignments?.find((assignment) => {
    return assignment.resource_type === ResourceType.RESOURCE_TYPE_ORGANIZATION;
  });

  const rolesGrantedSorted = sortRolesForRoleAssignmentSummary(foundAllWorkspace?.roles_granted ?? [], roleDefinitions);
  const allWorkspaceEffectiveRole = foundAllWorkspace
    ? getRoleName(roleDefinitions, rolesGrantedSorted?.[0] ?? '')
    : 'none';

  const allWorkspace = assignments.find((assignment) => {
    return assignment.resource_type === ResourceType.RESOURCE_TYPE_ORGANIZATION;
  });

  let allWorkspaceRoles: ACLRoleSource[] = [];

  if (allWorkspace) {
    allWorkspaceRoles = getProfileRoleSources(
      allWorkspace?.roles_granted,
      ResourceType.RESOURCE_TYPE_WORKSPACE,
      roleDefinitions
    );
  }
  const roleSources = allWorkspaceRoles;

  const roles: ACLWorkspaceServiceRelationship[] = [
    {
      workspaceId: 'All Workspaces',
      hasPriority: true,
      role: {
        effectiveWorkspaceRole: allWorkspaceEffectiveRole,
        roleSources,
      },
    },
  ];

  const aclRoleWithInheritance: ACLWorkspaceServiceRelationship[] = assignments
    .filter((assignment) => {
      return assignment.resource_type === ResourceType.RESOURCE_TYPE_WORKSPACE;
    })
    .map((workspaceAssignment: ResourceAndRoleAssignmentsV2) => {
      const roleSources: ACLRoleSource[] = getProfileRoleSources(
        workspaceAssignment?.roles_granted,
        ResourceType.RESOURCE_TYPE_WORKSPACE,
        roleDefinitions
      );

      return {
        workspaceId: `${workspaceAssignment.resource_id}`,
        hasPriority: false,
        role: {
          effectiveWorkspaceRole: roleSources?.[0]?.role ?? 'via group',
          roleSources,
        },
      } as ACLWorkspaceServiceRelationship;
    });

  return [...roles, ...aclRoleWithInheritance] as ACLWorkspaceServiceRelationship[];
};

export const filterAssignedWorkspace = (
  filterText: string | undefined,
  workspaces: ResourceAndRoleAssignmentsV2[]
): ResourceAndRoleAssignmentsV2[] => {
  if (filterText === undefined) {
    return workspaces;
  }

  let filteredWorkspaces: ResourceAndRoleAssignmentsV2[] = [];

  if (Array.isArray(workspaces)) {
    filteredWorkspaces = workspaces.filter((workspace: ResourceAndRoleAssignmentsV2) => {
      return workspace.resource_id?.toLowerCase().includes(filterText?.toLowerCase());
    });
  }

  return filteredWorkspaces;
};

export const filterServiceAccount = (
  filterText: string | undefined,
  serviceAccounts: ACLServiceAccountTableItem[] | undefined
): ACLServiceAccountTableItem[] | undefined => {
  if (filterText === undefined) {
    return serviceAccounts;
  }

  let filteredServiceAccounts: ACLServiceAccountTableItem[] = [];

  if (Array.isArray(serviceAccounts)) {
    filteredServiceAccounts = serviceAccounts.filter((serviceAccount: ACLServiceAccountTableItem) => {
      const { name, description, id } = serviceAccount;
      return [id?.toLowerCase(), name?.toLowerCase(), description?.toLowerCase()]
        .join('')
        .includes(filterText?.toLowerCase());
    });
  }

  return filteredServiceAccounts;
};

// since selectedWorkspace can be type  EuiComboBoxOptionOption<string>[] or ACLWorkspaceServiceRelationship
export const getResourceId = (selectedWorkspace: any): string => {
  return selectedWorkspace?.workspaceId ? selectedWorkspace?.workspaceId : selectedWorkspace[0]?.label;
};

// Remove ACLServiceAccountProfileModal once we migrate to GQL
export enum ACLServiceAccountProfileModal {
  DEPROVISION,
  REACTIVATE,
  DELETE,
  ADD_WORKSPACE,
  MODIFY_ACCESS,
  MODIFY_ACCOUNT_TYPE,
  ADD_SERVICE_ACCOUNT_TO_GROUP,
  REMOVE_SERVICE_ACCOUNT_TO_GROUP,
}

// We consolidate the enums here
export enum ACLPrincipalModal {
  EDIT_ACCOUNT_TYPE,
  DELETE,
  ADD_WORKSPACE,
  MODIFY_ACCESS,
  ADD_GROUP,
  REMOVE_GROUP,
  UNLOCK_USER,
  DEPROVISION,
  REACTIVATE,
}

// Remove ACLUserModal once we migrate to GQL
export enum ACLUserModal {
  INVITE_USER,
  DELETE,
  EDIT_USER_TYPE,
  ADD_WORKSPACE,
  MODIFY_ACCESS,
  UNLOCK_USER,
  ADD_USER_TO_GROUP,
  REMOVE_USER_FROM_GROUP,
}

export enum ACLGroupModal {
  ADD_WORKSPACE,
  MODIFY_WORKSPACE,
  CREATE_GROUP,
  DELETE_GROUP,
  EDIT_GROUP_ACCOUNT_TYPE,
  ADD_MEMBER_TO_GROUP,
  REMOVE_MEMBER_FROM_GROUP,
  ADD_ATTRIBUTE,
  REMOVE_ATTRIBUTE,
  UPDATE_ATTRIBUTE,
}

export enum UserStatus {
  LOCKED_OUT = 'LOCKED_OUT',
  ACTIVE = 'ACTIVE',
}

export const getUserACLRolesWithInheritance = (
  id?: string,
  resourceAndRoleAssignments?: ResourceAndRoleAssignmentsV2[],
  aclInheritanceType?: ACLInheritanceEnum,
  serviceAccountName?: string,
  roleDefinitions?: RoleDefinition[]
) => {
  const workspaceRoleSources: ACLRoleSource[] = getRoleSources(resourceAndRoleAssignments ?? [], roleDefinitions);
  // Now that we have both the workspace and organization resource type, we will combine the two so it show up as a list
  const roleSources = sortRolesForACLRoleSource([...workspaceRoleSources], roleDefinitions ?? []);

  const role = roleSources?.[0]?.role ?? '';

  // Users get a different payload
  if (aclInheritanceType === ACLInheritanceEnum.USER) {
    return {
      userId: id,
      role: {
        effectiveWorkspaceRole: role ?? 'via group',
        roleSources,
      },
    };
  }

  // Service gets a different payload
  return {
    serviceAccountId: id,
    name: serviceAccountName,
    role: {
      effectiveWorkspaceRole: role ?? 'via group',
      roleSources,
    },
  };
};

export const getRolesGranted = (
  resourceAndRoleAssignments?: ResourceAndRoleAssignmentsV2[],
  resourceType?: ResourceType
) => {
  // compact make sure we don't get empty values
  return (
    _compact(
      resourceAndRoleAssignments
        ?.filter((assignment) => {
          return assignment.resource_type === resourceType;
        })
        .map((assignment: ResourceAndRoleAssignmentsV2) => {
          return assignment?.roles_granted;
        })
        .flat()
    ) ?? []
  );
};

const getRoleSources = (
  resourceAndRoleAssignments: ResourceAndRoleAssignmentsV2[],
  roleDefinitions?: RoleDefinition[]
): ACLRoleSource[] => {
  const alcRoleSources: RoleDefinition[] =
    resourceAndRoleAssignments
      ?.map((resourceAndRoleAssignment: ResourceAndRoleAssignmentsV2) => {
        return (resourceAndRoleAssignment?.roles_granted
          ?.map((rolesGranted) => {
            const isOrganization = resourceAndRoleAssignment?.resource_type === ResourceType.RESOURCE_TYPE_ORGANIZATION;
            return (
              rolesGranted?.role_assignment_sources
                ?.map((roleAssignSource) => {
                  const role = getRoleName(roleDefinitions ?? [], rolesGranted?.role ?? '') ?? '';
                  const type: ACLWorkspaceMembershipType = isOrganization
                    ? 'via all workspaces role'
                    : roleAssignSource.assignment_type === RoleAssignmentType.ROLE_ASSIGNMENT_TYPE_FROM_PRINCIPAL_GROUP
                    ? 'via group'
                    : 'direct';

                  return {
                    role,
                    type,
                    groupName: roleAssignSource?.principal_group_name,
                    groupId: roleAssignSource?.principal_group_id,
                  };
                })
                ?.flat() ?? []
            );
          })
          ?.flat() ?? []) as RoleDefinition[];
      })
      ?.flat() ?? [];

  return alcRoleSources as ACLRoleSource[];
};

export const getUserMembers = (searchFilter: string, members?: PrincipalGroupMember[]): ACLGroupMemberType[] => {
  return (
    members
      ?.filter((i) => {
        const isUser = !!i?.principal?.user;
        if (isUser && searchFilter === '') {
          return true;
        }
        return (
          isUser &&
          searchFilter !== '' &&
          !!i?.principal?.user?.login_email?.toLowerCase().includes(searchFilter.toLowerCase() ?? '')
        );
      })
      ?.map((i) => {
        const membershipType = i.is_from_idp_mapping ? 'via IdP' : 'manual';
        return {
          id: i?.principal?.user?.login_email ?? '',
          membershipType,
          oktId: i.principal?.user?.okta_id,
        };
      }) ?? []
  );
};

export const getServiceAccountMembers = (
  serviceAccountSearch: string,
  members?: PrincipalGroupMember[]
): ACLGroupServiceAccountType[] => {
  return (
    members
      ?.filter((i) => {
        const isServiceAccount = !!i?.principal?.service_account;
        if (isServiceAccount && serviceAccountSearch === '') {
          return true;
        }
        return (
          isServiceAccount &&
          serviceAccountSearch !== '' &&
          !!i?.principal?.service_account?.name?.toLowerCase().includes(serviceAccountSearch.toLowerCase())
        );
      })
      .map((i) => {
        return {
          name: i?.principal?.service_account?.name ?? '',
          serviceAccountId: i.principal?.service_account?.id ?? '',
        };
      }) ?? []
  );
};

export const filterServiceAccounts = (
  serviceAccountSearch: string,
  members?: ServiceAccountGQL[]
): ServiceAccountGQL[] => {
  return (
    members?.filter((serviceAccount) => {
      if (serviceAccountSearch === '') {
        return true;
      }
      return (
        serviceAccountSearch !== '' &&
        !!serviceAccount?.name?.toLowerCase().includes(serviceAccountSearch.toLowerCase())
      );
    }) ?? []
  );
};

export const getWorkspaceMembers = (
  searchWorkspace: string,
  workspaceMembers?: ACLGroupWorkspaceType[]
): ACLGroupWorkspaceType[] => {
  return (
    workspaceMembers
      ?.filter((i) => {
        if (searchWorkspace === '') {
          return true;
        }

        return i?.workspaceId?.toLowerCase().includes(searchWorkspace.toLowerCase());
      })
      .map((i) => {
        const role = i.role;
        return {
          workspaceId: i?.workspaceId ?? '',
          hasPriority: i?.hasPriority ?? false,
          role,
        };
      }) ?? []
  );
};

export const getWorkspaceMembersGQL = (
  searchWorkspace: string,
  workspaceMembers?: AclGroupWorkspaceType[]
): AclGroupWorkspaceType[] => {
  return (
    workspaceMembers
      ?.filter((i) => {
        if (searchWorkspace === '') {
          return true;
        }
        return i?.workspaceId?.toLowerCase().includes(searchWorkspace.toLowerCase());
      })
      ?.map((i) => {
        return i;
      }) ?? []
  );
};

export const getUserCandidateMembers = (
  userMembers: ACLGroupMemberType[],
  userData?: User[]
): CandidateMemberOption[] => {
  const userCandidates =
    userData
      ?.filter((i) => {
        const userEmails = userMembers?.map((i) => i.id) ?? [];
        return !userEmails?.includes(i?.login_email ?? '') ?? false;
      })
      ?.map((i) => {
        return {
          label: `${i.login_email} ${!!i?.first_name || !!i?.last_name ? '(' : ''} ${i.first_name} ${i.last_name} ${
            !!i?.first_name || !!i?.last_name ? ')' : ''
          }`,
          data: {
            name: `${i.first_name} ${i.last_name}`,
            id: `${i.okta_id}`,
          },
        };
      }) ?? [];
  return _sortBy(userCandidates, ['label']);
};

export const getServiceAccountCandidateMembers = (
  serviceAccountMembers: ACLGroupServiceAccountType[],
  service_accounts?: ServiceAccount[]
): CandidateMemberOption[] => {
  const serviceAccounts =
    service_accounts
      ?.filter((i) => {
        const serviceAccount = serviceAccountMembers?.map((x) => x.serviceAccountId);
        return !serviceAccount.includes(i.id ?? '');
      })
      .map((i) => {
        const { name, id } = i;
        return {
          label: name ?? '',
          data: {
            id: id ?? '',
            name: name ?? '',
          },
        };
      }) ?? [];
  return _sortBy(serviceAccounts, ['label']);
};

export const getWorkspaceCandidate = (
  workspaceMembers: ACLGroupWorkspaceType[],
  workspaces: Workspace[]
): EuiComboBoxOptionOption<string>[] => {
  const wsList =
    workspaces
      ?.filter((i) => {
        const workspaceIds = workspaceMembers?.map((x) => x.workspaceId);
        return !workspaceIds.includes(i.name ?? '');
      })
      .map((i) => {
        const { name } = i;
        return {
          label: name ?? '',
        };
      }) ?? [];

  return _sortBy(wsList, ['label']);
};

export const getAssignableWorkspaceGQL = (
  workspaceMembers: AclGroupWorkspaceType[],
  workspaces: string[]
): EuiComboBoxOptionOption<string>[] => {
  const wsList =
    workspaces
      ?.filter((workspaceId) => {
        const workspaceIds = workspaceMembers?.map((workspaceMember) => workspaceMember.workspaceId);
        return !workspaceIds.includes(workspaceId ?? '');
      })
      .map((workspaceId) => {
        return {
          label: workspaceId ?? '',
        };
      }) ?? [];

  return _sortBy(wsList, ['label']);
};

export const getMembersCount = (members: PrincipalGroupMember[] = []): Record<string, number> => {
  return members.reduce(
    (counts, member) => {
      if (member?.principal?.user) {
        counts.user += 1;
      } else if (member?.principal?.service_account) {
        counts.service_account += 1;
      } else if (member?.principal?.group) {
        counts.group += 1;
      }
      return counts;
    },
    { user: 0, service_account: 0, group: 0 }
  );
};

export const getEffectiveAccountType = (
  isUserAdmin: boolean,
  adminRoleAssignments?: RoleAssignmentSource[]
): AccountType => {
  if (isUserAdmin) {
    return 'Admin';
  }
  return adminRoleAssignments && adminRoleAssignments?.length > 0 ? 'Admin' : 'Regular';
};

export const getAdminRoleAssignmentSources = (
  role_assignments?: ResourceAndRoleAssignmentsV2[]
): RoleAssignmentSource[] => {
  const adminRoleAssignmentSources =
    role_assignments
      ?.filter((roleAssignment) => {
        const isOrganization = roleAssignment.resource_type === ResourceType.RESOURCE_TYPE_ORGANIZATION;
        const hasAdminRole = roleAssignment?.roles_granted?.find((roleGranted) => {
          return roleGranted?.role === 'admin_role';
        });

        return isOrganization && hasAdminRole;
      })
      .map((roleAssignment) => {
        const rolesGranted =
          roleAssignment.roles_granted?.map((roleGranted) => {
            const sources = roleGranted?.role_assignment_sources?.filter((roleAssignmentSources) => {
              return (
                roleAssignmentSources?.assignment_type === RoleAssignmentType.ROLE_ASSIGNMENT_TYPE_FROM_PRINCIPAL_GROUP
              );
            });
            if (roleGranted.role?.includes('admin_role') && sources && sources?.length > 0) {
              return sources;
            }
            return undefined;
          }) ?? [];

        return rolesGranted.flat();
      })
      .flat() ?? [];
  return compact(adminRoleAssignmentSources);
};

export const getInheritedAccountTypes = (roleAssignment: RoleAssignmentSource[]): InheritedAccountType[] => {
  const sortedInheritedAccountTypes =
    roleAssignment.map((roleAssignment) => {
      return {
        groupName: roleAssignment?.principal_group_name as string,
        groupId: roleAssignment?.principal_group_id as string,
        accountType: roleAssignment?.assignment_type as string,
      };
    }) ?? [];

  return _sortBy(sortedInheritedAccountTypes, ['groupName']) as InheritedAccountType[];
};

export const getAssignedAccountType = (role_assignments?: ResourceAndRoleAssignmentsV2[]): AccountType => {
  const hasDirectAdmin = role_assignments?.filter((roleAssignment) => {
    const isOrganization = roleAssignment.resource_type === ResourceType.RESOURCE_TYPE_ORGANIZATION;
    const hasRoleAssignmentTypeDirect = roleAssignment?.roles_granted?.find((roleGranted) => {
      const directRole = roleGranted?.role_assignment_sources?.find((r) => {
        return r.assignment_type === RoleAssignmentType.ROLE_ASSIGNMENT_TYPE_DIRECT;
      });
      return roleGranted?.role === 'admin_role' && directRole;
    });
    return isOrganization && hasRoleAssignmentTypeDirect;
  });

  return hasDirectAdmin && hasDirectAdmin.length > 0 ? 'Admin' : 'Regular';
};

export const getWorkspaceRole = (
  roleAssignment: ResourceAndRoleAssignmentsV2[],
  roleDefinition: RoleDefinition[]
): string[] => {
  const roles =
    roleAssignment
      .map((roleAssignment) => {
        const roles =
          roleAssignment?.roles_granted?.map((roleGranted) => {
            return roleGranted?.role;
          }) ?? [];
        return roles
          ?.map((r) => {
            const foundRole = roleDefinition?.find((role) => {
              return r === role.legacy_id;
            });

            return foundRole?.name ?? 'Viewer';
          })
          .flat();
      })
      .flat() ?? [];

  return compact(roles);
};

export const getAclRoleSources = (
  roleAssignment: ResourceAndRoleAssignmentsV2[],
  groupBasic: GroupBasic | undefined,
  roleDefinition: RoleDefinition[]
): ACLRoleSource[] => {
  const alcRoleSources =
    roleAssignment
      ?.map((g) => {
        return (
          g?.roles_granted?.map((roleGranted) => {
            const type = getAclWorkspaceMemberShipType(g);

            const roleName = roleDefinition.find((roleDefinition) => {
              return roleDefinition?.legacy_id === roleGranted?.role;
            });

            return {
              type,
              role: roleName?.name,
              groupId: groupBasic?.id,
              groupName: groupBasic?.name,
            };
          }) ?? []
        );
      })
      ?.flat() ?? [];

  return alcRoleSources as ACLRoleSource[];
};

export const formatServiceAccounts = (
  serviceAccounts?: ServiceAccount[],
  assignmentBasics?: AssignmentBasicV2[],
  authorizedResources?: string[]
): ACLServiceAccountTableItem[] => {
  const assignments = assignmentBasics ?? [];

  // let's add accountType for each Service account as a
  const aclServiceAccounts: ACLServiceAccountTableItem[] =
    serviceAccounts?.map((serviceAccount: ServiceAccount) => {
      const serviceAccountAssignment = assignments?.find((assignment) => {
        return assignment?.principal?.service_account?.id === serviceAccount.id;
      });

      const adminRoleAssignmentSources = getAdminRoleAssignmentSources(serviceAccountAssignment?.role_assignments);
      const assignedAccountType: AccountType = getAssignedAccountType(serviceAccountAssignment?.role_assignments);
      const effectiveAccountType = getEffectiveAccountType(assignedAccountType === 'Admin', adminRoleAssignmentSources);
      const sortedInheritedAccountTypes = getInheritedAccountTypes(adminRoleAssignmentSources);

      const accountType = {
        effectiveAccountType: effectiveAccountType,
        assignedAccountType,
        inheritedAccountType: sortedInheritedAccountTypes,
      };

      return {
        ...serviceAccount,
        accountType,
        isOwner: authorizedResources?.includes(serviceAccount?.id ?? ''), // We check to see if they are owners of this service account
      };
    }) ?? [];

  return sortBy(aclServiceAccounts, ['name']);
};

export const getWorkspaceMemberCount = (workspaceMembers: ACLGroupWorkspaceType[]): string | undefined => {
  // When "All Workspaces" is set, we just say "all" instead of show the count number on the tab
  const isWorkspaceSet = workspaceMembers?.find((workspaceMember) => {
    return workspaceMember.hasPriority && workspaceMember.role !== 'none';
  });
  return `${isWorkspaceSet ? 'all' : workspaceMembers?.length - 1 ?? 0}`;
};

export const getWorkspaceMemberCountGQL = (workspaceMembers: AclGroupWorkspaceType[]): string | undefined => {
  // When "All Workspaces" is set, we just say "all" instead of show the count number on the tab
  const isWorkspaceSet = workspaceMembers?.find((workspaceMember) => {
    return workspaceMember.hasPriority && workspaceMember.role !== 'none';
  });
  return `${isWorkspaceSet ? 'all' : workspaceMembers?.length - 1 ?? 0}`;
};

export const getAclWorkspaceMemberShipType = (
  resourceAndRoleAssignments: ResourceAndRoleAssignmentsV2
): ACLWorkspaceMembershipType => {
  let type: ACLWorkspaceMembershipType =
    resourceAndRoleAssignments?.resource_type === ResourceType.RESOURCE_TYPE_ORGANIZATION
      ? 'via all workspaces role'
      : resourceAndRoleAssignments?.resource_type === ResourceType.RESOURCE_TYPE_WORKSPACE
      ? 'direct'
      : 'via group';

  if (
    resourceAndRoleAssignments?.resource_type === ResourceType.RESOURCE_TYPE_ORGANIZATION &&
    resourceAndRoleAssignments?.resource_id
  ) {
    type = 'via group';
  }

  return type;
};

export const formatGroup = (workspaceMembers: ACLGroupTableItem[], memberOfGroupIds: string[]): ACLGroupTableItem[] => {
  // When "All Workspaces" is set, we just say "all" instead of show the count number on the tab
  const formattedGroupCounts =
    workspaceMembers?.map((ws) => {
      const workspace_count = getWorkspaceMemberCount(ws.workspaces);
      return {
        ...ws,
        workspace_count,
        isMember: memberOfGroupIds.includes(ws?.groupId),
      };
    }) ?? [];

  return formattedGroupCounts;
};

export const getWorkspaceCount = (aclWorkspacesRelationShips: ACLWorkspaceServiceRelationship[]): string => {
  const isWorkspaceSet = !!aclWorkspacesRelationShips?.find((ws: ACLWorkspaceServiceRelationship) => {
    if (ws.hasPriority && ws?.role?.effectiveWorkspaceRole !== 'none') {
      return true;
    }
  });

  const workspaceCount = isWorkspaceSet
    ? 'all'
    : aclWorkspacesRelationShips?.filter((ws: ACLWorkspaceServiceRelationship) => {
        if (ws.hasPriority && ws?.role?.effectiveWorkspaceRole === 'none') {
          return false;
        }

        return true;
      }).length;

  return `${workspaceCount}`;
};

export const getAssignedWorkspaceCount = (aclWorkspacesRelationShips: AclWorkspaceServiceRelationshipGQL[]): string => {
  const isWorkspaceSet = !!aclWorkspacesRelationShips?.find((ws: AclWorkspaceServiceRelationshipGQL) => {
    if (ws.hasPriority && ws?.role?.effectiveWorkspaceRole !== 'none') {
      return true;
    }
  });

  const workspaceCount = isWorkspaceSet
    ? 'all'
    : aclWorkspacesRelationShips?.filter((ws: AclWorkspaceServiceRelationshipGQL) => {
        if (ws.hasPriority && ws?.role?.effectiveWorkspaceRole === 'none') {
          return false;
        }

        return true;
      }).length;

  return `${workspaceCount}`;
};

export const sortRolesForAssignmentBasic = (
  assignments: AssignmentBasicV2[],
  rolesData: RoleDefinition[]
): AssignmentBasicV2[] => {
  return (
    assignments?.map((assignment) => {
      const sortedRoleAssignments =
        assignment?.role_assignments?.sort((a: ResourceAndRoleAssignmentsV2, b: ResourceAndRoleAssignmentsV2) => {
          const roleMap = rolesData?.map((r, index) => {
            return {
              name: r.legacy_id,
              index,
            };
          });

          const aRoles = a?.roles_granted?.map((r) => r.role);
          const bRoles = b?.roles_granted?.map((r) => r.role);

          const aRole = aRoles?.[0] as string;
          const bRole = bRoles?.[0] as string;

          const value1 = roleMap.find((r) => r.name === aRole)?.index as number;
          const value2 = roleMap.find((r) => r.name === bRole)?.index as number;
          return value1 < value2 ? -1 : value1 > value2 ? 1 : 0;
        }) ?? [];

      assignment.role_assignments = sortedRoleAssignments;
      return assignment;
    }) ?? []
  );
};

export const sortRolesForRoleAssignmentSummary = (
  roleAssignments: RoleAssignmentSummary[],
  rolesData: RoleDefinition[]
): string[] => {
  const roles =
    roleAssignments
      ?.map((ra) => ra.role ?? 'none')
      ?.sort((a: string, b: string) => {
        const roleMap = rolesData?.map((r, index) => {
          return {
            name: r.legacy_id,
            index,
          };
        });
        const value1 = roleMap.find((r) => r.name === a)?.index as number;
        const value2 = roleMap.find((r) => r.name === b)?.index as number;
        return value1 < value2 ? -1 : value1 > value2 ? 1 : 0;
      }) ?? [];

  return roles;
};

export const sortRolesForACLRoleSource = (
  roleAssignments: ACLRoleSource[],
  rolesData: RoleDefinition[]
): ACLRoleSource[] => {
  const roles =
    roleAssignments?.sort((a: ACLRoleSource, b: ACLRoleSource) => {
      const roleMap = rolesData?.map((r, index) => {
        return {
          name: r.legacy_id,
          index,
        };
      });
      const value1 = roleMap.find((r) => r.name === a.role)?.index as number;
      const value2 = roleMap.find((r) => r.name === b.role)?.index as number;
      return value1 < value2 ? -1 : value1 > value2 ? 1 : 0;
    }) ?? [];

  return roles;
};
