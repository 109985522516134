import bigInt from 'big-integer';
export default class IdUtils {
  static generateStringId() {
    const randomHexNumber =
      Math.random().toString(16).substring(2, 12) +
      Math.random().toString(16).substring(2, 12) +
      Math.random().toString(16).substring(2, 14);
    // Pad since some random numbers are "round" enough and come out as shorter strings
    return randomHexNumber.padStart(32, '0');
  }

  static toHex32Bit(numberString) {
    return ('0000000000000000' + bigInt(numberString).toString(16)).substr(-16);
  }

  static toStringId(id) {
    if (typeof id === 'string') return id;

    return (
      this.toHex32Bit(id?.most_significant_bits ?? id?.mostSignificantBits) +
      this.toHex32Bit(id?.least_significant_bits ?? id?.leastSignificantBits)
    );
  }

  static fromStringId(stringId) {
    return {
      most_significant_bits: bigInt(stringId.substring(0, 16), 16).toString(),
      least_significant_bits: bigInt(stringId.substring(16), 16).toString(),
    };
  }
}
