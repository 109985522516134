import { css } from '@emotion/css';
import React, { FC } from 'react';
import { useTectonTheme } from './TectonThemeProvider';
import { useEuiFontSize } from '@elastic/eui';
import Panel from './Layouts/Panel';
import Badge from '@tecton/Badge';

interface FCOCardProps {
  title: string;
  children: React.ReactNode;
  tooltipText?: string;
  badgeNumber?: number;
}

const FCOCard: FC<FCOCardProps> = ({ title, children, badgeNumber }) => {
  // This is the base FCO panel / card
  // Murillo already created it. I'm just restructuring the code here.

  const { theme } = useTectonTheme();

  // TODO: 10/4/2023: Use EuiFontSize is wrong. Fix that

  const CardHeaderCss = css`
    background: ${theme.colors.lightestShade};
    padding: ${theme.size.m} ${theme.size.m} ${theme.size.m} ${theme.size.l};
    display: grid;
    grid-template-columns: 1fr auto;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      ${useEuiFontSize('m')};
      font-weight: 500;
      color: ${theme.colors.darkestShade};
    }
  `;

  // const padding = noPadding ? 0 : `${theme.size.l} ${theme.size.l}`;
  const padding = theme.size.l;

  return (
    <Panel hasBorder={true} paddingSize="none">
      <div className={CardHeaderCss}>
        {title}
        {badgeNumber && (
          <>
            <Badge>{badgeNumber}</Badge>
          </>
        )}
      </div>
      <div
        style={{
          padding,
          height: `calc(100% - 2 * (${padding}))`,
        }}
      >
        {children}
      </div>
    </Panel>
  );
};

export default FCOCard;
