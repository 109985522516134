import React, { ChangeEvent, useCallback } from 'react';
import _debounce from 'lodash/debounce';

import { EuiFieldSearchProps, FieldSearch } from '@tecton';

export interface FieldSearchProps extends EuiFieldSearchProps {
  debounceMilliseconds?: number;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const DebouncedFieldSearch = ({ debounceMilliseconds = 250, onChange, ...rest }: FieldSearchProps) => {
  const callOnchange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(event);
      }
    },
    [onChange]
  );

  const debouncedCallBack = _debounce(callOnchange, debounceMilliseconds);

  return (
    <FieldSearch
      {...rest}
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        debouncedCallBack(event);
      }}
    />
  );
};

export default DebouncedFieldSearch;
