import React from 'react';
import { StateUpdatePlanSummaryDiff } from '../../types/tecton_proto/data/state_update';
import FCONameDisplayWithIconAndTooltip from '../../components/@tecton/FCONameDisplayWithIconAndTooltip';

import { FCOType } from '../../core/types/fcoTypes';
import { useGetFeatureServiceByName } from '../../api/fcos';

const PlanFeatureServiceName = ({
  workspace,
  stateUpdatePlanDiff,
}: {
  workspace?: string;
  stateUpdatePlanDiff: StateUpdatePlanSummaryDiff;
}) => {
  const fco = useGetFeatureServiceByName(stateUpdatePlanDiff.name ?? '', workspace ?? '');
  return (
    <FCONameDisplayWithIconAndTooltip
      fco={fco}
      modifiedName={stateUpdatePlanDiff?.name}
      modifiedType={FCOType.FEATURE_SERVICE}
    />
  );
};

export default PlanFeatureServiceName;
