import React, { FC, useState } from 'react';
import _debounce from 'lodash/debounce';

import { Button, FlexGroup, FlexItem, TextArea } from '@tecton/ComponentRedesign';
import { RequestCallbackType } from '../SharedComponentTypes';

interface EditableFieldEditProps {
  fieldTitle: string;
  fieldName: string;
  fieldValue: string;
  onFieldSaveConfirm?: (filedName: string, newValue: string, callback: RequestCallbackType) => void;
  closeEditMode: () => void;
}

const EditableFieldEdit: FC<EditableFieldEditProps> = ({
  fieldName,
  fieldValue,
  onFieldSaveConfirm,
  closeEditMode,
}) => {
  const [originalValue] = useState<string>(fieldValue);
  const [inputValue, setInputValue] = useState<string>(fieldValue);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  return (
    <>
      <FlexGroup direction="column" gutterSize={`m`}>
        <FlexItem>
          <TextArea
            value={inputValue}
            fullWidth
            onChange={(e) => {
              setInputValue(e.target.value);
            }}
            rows={1}
          />
        </FlexItem>
        <FlexItem>
          <FlexGroup direction="row" justifyContent="flexEnd" alignItems="flexEnd" gutterSize="m" wrap>
            <FlexItem grow={false}>
              <Button
                label={'Cancel'}
                onClick={() => {
                  setInputValue(originalValue);
                  closeEditMode();
                }}
                variant={'emptyAction'}
              />
            </FlexItem>
            <FlexItem grow={false}>
              <Button
                label={'save'}
                onClick={() => {
                  setIsLoading(true);
                  onFieldSaveConfirm?.call(this, fieldName, inputValue ?? '', (status) => {
                    if (status === 'success') {
                      setIsLoading(false);
                      closeEditMode();
                    }
                  });
                }}
                variant={originalValue === inputValue ? 'disabledAction' : 'primaryAction'}
                isLoading={isLoading ?? false}
              />
            </FlexItem>
          </FlexGroup>
        </FlexItem>
      </FlexGroup>
    </>
  );
};

export default EditableFieldEdit;
