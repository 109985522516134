export default () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.9165 12.195L10.4165 13.6383C10.1587 13.7872 9.841 13.7872 9.58317 13.6383L7.08317 12.195C6.82534 12.0461 6.66651 11.771 6.6665 11.4733V8.52665C6.66651 8.22893 6.82534 7.95384 7.08317 7.80498L9.58317 6.36165C9.841 6.21278 10.1587 6.21278 10.4165 6.36165L12.9165 7.80498C13.1743 7.95384 13.3332 8.22893 13.3332 8.52665V11.4733C13.3332 11.771 13.1743 12.0461 12.9165 12.195Z"
        stroke="#525866"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.1573 14.6338L10.8795 17.6809C10.3352 17.9951 9.66461 17.9951 9.1203 17.6809L3.84252 14.6338C3.2982 14.3195 2.96289 13.7387 2.96289 13.1102V6.88961C2.96289 6.26109 3.2982 5.68031 3.84252 5.36605L9.1203 2.31895C9.66461 2.00469 10.3352 2.00469 10.8795 2.31895L16.1573 5.36605C16.7016 5.68031 17.037 6.26109 17.037 6.88961V13.1102C17.037 13.7387 16.7016 14.3195 16.1573 14.6338V14.6338Z"
        stroke="#525866"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.2217 8.11011L10.4167 9.72962C10.1588 9.87844 9.84117 9.87844 9.58332 9.72962L6.77832 8.11011"
        stroke="#525866"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.0002 13.7499V9.84033"
        stroke="#525866"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
