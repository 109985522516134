import React, { FC } from 'react';
import { FCO, FCOFields } from '../../core/types/fcoTypes';
import { FCOCard, Icon, Spacer, useTectonTheme, EmptyCard } from '../@tecton';
import TagBadge from './TagBadge';

interface FCODetailsTagsCardProps {
  fco: FCO;
}

const FCODetailsTagsCard: FC<FCODetailsTagsCardProps> = ({ fco }) => {
  const { theme } = useTectonTheme();

  const listItems = fco[FCOFields.TAGS].map((tag) => {
    return {
      title: (
        <>
          <Icon type="tag" style={{ marginRight: theme.size.s }} />
          {tag.key}
        </>
      ),
      description: tag.value,
    };
  });

  if (listItems.length === 0) {
    return <EmptyCard title={'Tags'} content={`No tags configured for ${fco.name}.`} />;
  }

  return (
    <FCOCard title="Tags">
      {fco[FCOFields.TAGS].map((tag) => {
        return (
          <>
            <TagBadge tag={tag} />
            <Spacer size="s" />
          </>
        );
      })}
    </FCOCard>
  );
};

export default FCODetailsTagsCard;
