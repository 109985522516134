import styled from '@emotion/styled';
import { FC } from 'react';
import LinkIcon from './LinkIcon';

import { Tooltip } from '@tecton/ComponentRedesign';

import BookIcon from '@svg/book.svg';

export interface TectonSubtitleProps {
  description?: string;
  learnMoreUrl?: string;
}

const DescriptionBar = styled.div`
  display: flex;
  gap: 8px;
  background-color: ${({ theme }) => theme.colors.emptyShade};
  padding: 0;
`;

const DescriptionStyle = styled.div`
  color: ${({ theme }) => theme.colors.subduedText};
  //  limit text to two lines
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
`;

const TectonSubtitle: FC<TectonSubtitleProps> = ({ description, learnMoreUrl }) => {
  return (
    <DescriptionBar data-testid="subtitle">
      <Tooltip
        content={`${description}`}
        trigger={
          <>
            <DescriptionStyle>{description}</DescriptionStyle>
          </>
        }
      />

      {learnMoreUrl && (
        <>
          <LinkIcon linkName={'Learn More'} href={learnMoreUrl} iconType={BookIcon} target="_blank" />
        </>
      )}
    </DescriptionBar>
  );
};

export default TectonSubtitle;
