export default () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.1681 17.5031H5.8313C3.98959 17.5031 2.49658 16.0101 2.49658 14.1683V7.4989C2.49658 6.57805 3.24308 5.83154 4.16394 5.83154H15.8355C16.7563 5.83154 17.5028 6.57805 17.5028 7.4989V14.1683C17.5028 16.0101 16.0098 17.5031 14.1681 17.5031Z"
        stroke="#525866"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.66504 5.83155V4.16419C6.66504 3.24333 7.41154 2.49683 8.3324 2.49683H11.6671C12.588 2.49683 13.3345 3.24333 13.3345 4.16419V5.83155"
        stroke="#525866"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <rect
        x="8.33252"
        y="9.16626"
        width="3.33472"
        height="2.50104"
        rx="0.5"
        stroke="#525866"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.667 10.4169H14.5849C16.1964 10.4169 17.5028 9.11053 17.5028 7.49902V7.49902"
        stroke="#525866"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.33235 10.4169H5.41446C3.80296 10.4169 2.49658 9.11053 2.49658 7.49902V7.49902"
        stroke="#525866"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
