import { DataSourceFCO, FCOFields, FCOTag, FeatureViewFCO } from '../../core/types/fcoTypes';
import { Duration } from '../../types/google/protobuf/duration';
import { Id } from '../../types/tecton_proto/common/id';
import { FcoMetadata } from '../../types/tecton_proto/data/fco_metadata';
import { Aggregate } from '../../types/tecton_proto/data/feature_view';
import IdUtils from '../../utils/id-utils';
import { durationToSeconds, secondsToHumanFriendlyString } from '../../utils/misc-utils';

const reshapeTags: (rawTags: { [key: string]: string } | undefined) => FCOTag[] = (rawTags) => {
  if (!rawTags) {
    return [];
  }

  return Object.entries(rawTags).map((tagEntry) => {
    return {
      key: tagEntry[0],
      value: tagEntry[1],
    };
  });
};

export const truncateDescription = (description: string | undefined) => {
  if (description === undefined || description.match(/^ *$/) !== null) {
    return 'No description provided.';
  }

  if (description && description.length > 297) {
    return `${description.substring(0, 297)}...`;
  }

  return description;
};

export const getSharedFCOProperties = (metadata: FcoMetadata, id: Id | undefined) => {
  return {
    name: metadata.name ?? undefined,
    id: IdUtils.toStringId(id),
    workspace: metadata.workspace ?? undefined,
    sourceFileName: metadata.source_filename ?? undefined,
    description: metadata.description ?? undefined,
    [FCOFields.TRUNCATED_DESCRIPTION]: truncateDescription(metadata?.description),
    tags: reshapeTags(metadata.tags),
    createdDate: metadata.created_at ? new Date(metadata.created_at) : undefined,
    owner: handleOwnerOptions(metadata.owner),
    lastModifiedBy: metadata.last_modified_by ?? undefined,
    lastModifiedDate: metadata.last_updated_at ? new Date(metadata.last_updated_at) : undefined,
    framework_version: metadata.framework_version,
    frameworkVersionNumber: parseInt(metadata.framework_version?.replace('FWV', '') ?? ''),
  };
};

const handleOwnerOptions = (owner: string | undefined) => {
  if (owner === undefined || owner === '') {
    return 'n/a';
  } else {
    return owner;
  }
};

export interface DataDelay {
  dataDelay?: Duration | undefined;
  dataDelayDisplay?: string;
}

export const getDataDelayOrScheduleOffset = (
  dataSourceFco: DataSourceFCO,
  relatedFeatureView: FeatureViewFCO
): DataDelay => {
  /*
  Data delay (aka schedule_offset) is can be set in two places for version 4

  https://docs.tecton.ai/docs/troubleshooting/incorrect-get-historical-features-results#schedule_offset-or-data_delay-used

  So we need to find it in two places for for version 4.
*/

  const frameworkVersion = relatedFeatureView.frameworkVersion ?? 3;

  if (frameworkVersion < 4) {
    // So if frameworkVersion is less than 4, we need to get the data_data (aka schedule_offset) in the featureView pipeline
    const dataSourceName = dataSourceFco.name;
    const fvPipelineDataSource = relatedFeatureView.pipelineTransformationDataSourceInputs.find((i) => {
      i.input_name === dataSourceName;
    });

    const dataDelay = fvPipelineDataSource?.start_time_offset;

    const dataDelayDisplay = `FilteredSource(start_time_offset=${secondsToHumanFriendlyString(
      durationToSeconds(dataDelay)
    )})`;

    return { dataDelay, dataDelayDisplay };
  } else if (frameworkVersion > 4) {
    // So if frameworkVersion is less than 4, we need to get the data_data (aka schedule_offset) in the featureView pipeline
    const dataDelay = dataSourceFco?.batchDataSource?.data_delay;
    const dataDelayDisplay = `FilteredSource(data_delay=${secondsToHumanFriendlyString(durationToSeconds(dataDelay))})`;

    return { dataDelay, dataDelayDisplay };
  } else if (frameworkVersion === 4) {
    // We have to find it in two places.
    // https://docs.tecton.ai/docs/troubleshooting/incorrect-get-historical-features-results#schedule_offset-or-data_delay-used
    const dataSourceName = dataSourceFco.name;
    const fvPipelineDataSource = relatedFeatureView.pipelineTransformationDataSourceInputs.find((i) => {
      i.input_name === dataSourceName;
    });
    const dataDelay = fvPipelineDataSource?.start_time_offset ?? dataSourceFco.batchDataSource?.data_delay;

    let dataDelayDisplay = `FilteredSource(start_time_offset=${secondsToHumanFriendlyString(
      durationToSeconds(dataDelay)
    )})`;

    if (dataSourceFco.batchDataSource?.data_delay) {
      dataDelayDisplay = `FilteredSource(data_Delay=${secondsToHumanFriendlyString(durationToSeconds(dataDelay))})`;
    }

    return { dataDelay: dataDelay, dataDelayDisplay };
  }

  return {};
};

export const getAggregateFeatureWindow = (aggregationFeature: Aggregate): number => {
  // https://docs.tecton.ai/docs/defining-features/feature-views/aggregation-engine/aggregation-windows
  // According to the documentation, aggregationFeature.window "deprecate_after=0.7: window is only used by legacy SDKs"
  if (aggregationFeature?.time_window) {
    const windowEnd = aggregationFeature.time_window?.relative_time_window?.window_end ?? '0s';
    const windowStart = aggregationFeature.time_window?.relative_time_window?.window_start ?? '0s';
    const durationInSeconds = durationToSeconds(windowEnd) - durationToSeconds(windowStart);
    return durationInSeconds;
  }

  return durationToSeconds(aggregationFeature.window ?? '0s');
};
