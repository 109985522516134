export default () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="2"
        y="2"
        width="12"
        height="12"
        rx="2"
        stroke="#525866"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M9.99984 14V2" stroke="#525866" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M5.99984 14V2" stroke="#525866" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};
