import React from 'react';
import { IconTip } from '@tecton';

const ColumnNameWithHelp = ({ name, helpContent }: { name: React.ReactNode; helpContent: string }) => {
  return (
    <>
      {name} <IconTip content={helpContent} position="top" />
    </>
  );
};

export default ColumnNameWithHelp;
