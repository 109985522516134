import * as React from 'react';
import styled from '@emotion/styled';
import { EuiToolTip } from '@elastic/eui';
import { css } from '@emotion/css';
import { useHandleResize } from '../hooks/useHandleResize';

const ChildWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledTooltip = styled(EuiToolTip)`
  font-size: ${({ theme }) => theme.font.fontSizes.s};
  line-height: ${({ theme }) => theme.font.lineHeights.s};

  .euiToolTip__title {
    border: none;
  }
`;

const StyledAnchor = css`
  &.euiToolTipAnchor {
    display: block;
  }
`;

export interface TruncateTextProps {
  children: React.ReactNode;
  position?: 'bottom' | 'left' | 'top' | 'right';
  tooltipContent?: string;
}

export const TruncateText: React.FC<TruncateTextProps> = ({ children, position = 'bottom', tooltipContent = '' }) => {
  const childRef = React.useRef<HTMLDivElement>(null);
  const [isTruncated, setIsTruncated] = React.useState(false);

  const recalculateIsTruncated = React.useCallback(() => {
    if (childRef.current) {
      const { scrollWidth, clientWidth } = childRef.current;
      setIsTruncated(scrollWidth > clientWidth);
    }
  }, []);

  React.useLayoutEffect(() => {
    recalculateIsTruncated();
  }, [recalculateIsTruncated, children]);

  useHandleResize(recalculateIsTruncated);

  const childContent = <ChildWrapper ref={childRef}>{children}</ChildWrapper>;
  const content = tooltipContent ? tooltipContent : children;

  return isTruncated ? (
    <StyledTooltip anchorClassName={StyledAnchor} position={position} content={content} data-testid="ellipsis-tooltip">
      {childContent}
    </StyledTooltip>
  ) : (
    <>{childContent}</>
  );
};

export default TruncateText;
