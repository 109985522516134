import React, { FC } from 'react';
import styled from '@emotion/styled';

interface AsymmetricBodyLayoutProps {
  leftSide: React.ReactNode;
  rightSide: React.ReactNode;
}

const BodyWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: ${({ theme }) => theme.padding.l};
  height: 100%;
  overflow: hidden;
`;

const ColumnScroll = styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;
  gap: ${({ theme }) => theme.padding.xl};
`;

const AsymmetricBodyLayout: FC<AsymmetricBodyLayoutProps> = ({ rightSide, leftSide }) => {
  return (
    <BodyWrapper data-testid="body-layout-wrapper">
      <ColumnScroll data-testid="body-layout-left-side">{leftSide}</ColumnScroll>
      <ColumnScroll data-testid="body-layout-right-side">{rightSide}</ColumnScroll>
    </BodyWrapper>
  );
};

export default AsymmetricBodyLayout;
