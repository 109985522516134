import React from 'react';
import { StateUpdatePlanSummaryDiff } from '../../types/tecton_proto/data/state_update';
import FCONameDisplayWithIconAndTooltip from '../../components/@tecton/FCONameDisplayWithIconAndTooltip';
import { FCOType } from '../../core/types/fcoTypes';
import { useGetDataSourceByName } from '../../api/fcos';

const PlanDataSourceName = ({
  workspace,
  stateUpdatePlanDiff,
}: {
  workspace?: string;
  stateUpdatePlanDiff: StateUpdatePlanSummaryDiff;
}) => {
  const fco = useGetDataSourceByName(stateUpdatePlanDiff.name ?? '', workspace ?? '');

  return (
    <FCONameDisplayWithIconAndTooltip
      fco={fco}
      modifiedName={stateUpdatePlanDiff?.name}
      modifiedType={FCOType.DATA_SOURCE}
    />
  );
};

export default PlanDataSourceName;
