import { Title, useTectonTheme } from '@tecton';
import { css } from '@emotion/css';

const ACLSectionSubhead = (props: React.ComponentProps<typeof Title>) => {
  const { theme } = useTectonTheme();

  return (
    <Title
      {...props}
      className={
        props.className
          ? props.className + ' '
          : '' +
            css`
              font-weight: 500;
              margin: ${theme.size.s} 0;
            `
      }
    />
  );
};

export default ACLSectionSubhead;
