import { PopoverFooter } from '@tecton';
import React from 'react';

const PopoverSelectorFooter = ({ children }: { children: React.ReactNode }) => {
  return (
    <PopoverFooter paddingSize="s" style={{ display: 'flex', flexDirection: 'row-reverse' }}>
      {children}
    </PopoverFooter>
  );
};

export default PopoverSelectorFooter;
