import {
  MaterializationMetricResolutionGroups,
  MaterializationMetricsDefaultValuesForPanel,
  MaterializationMetricStatus,
  MaterializationMetricTypes,
  MaterializationQueryMetricResponse,
} from './apiTypes';
import {
  FeatureAndMetricMap,
  MaterializationEvent,
  MaterializationJob,
  MaterializationQueryFeatureResult,
  MaterializationQueryIndividualFeature,
  MaterializationQueryResult,
} from './types';
import _, { clone, isEqual } from 'lodash';
import { BatchMaterializationMonitoringPanelContextProps } from './context';
import { extent, group, mean } from 'd3-array';
import { utcFormat } from 'd3-time-format';
import { ScaleTime } from 'd3-scale';
import { utcDays, utcHours, utcMonths, utcSundays, utcYears } from 'd3-time';
import { GetMetricAndExpectationDefinitionResponse } from '../../../../../types/tecton_proto/metadataservice/metadata_service';

export const timeResolutionWindowMap = new Map<MaterializationMetricResolutionGroups, number>([
  [MaterializationMetricResolutionGroups.TIME_RESOLUTION_ONE_MINUTE, 1],
  [MaterializationMetricResolutionGroups.TIME_RESOLUTION_FIVE_MINUTES, 5],
  [MaterializationMetricResolutionGroups.TIME_RESOLUTION_ONE_HOUR, 60],
  [MaterializationMetricResolutionGroups.TIME_RESOLUTION_ONE_DAY, 24],
  [MaterializationMetricResolutionGroups.TIME_RESOLUTION_ONE_WEEK, 7],
  [MaterializationMetricResolutionGroups.TIME_RESOLUTION_ONE_MONTH, 30],
  [MaterializationMetricResolutionGroups.TIME_RESOLUTION_ONE_YEAR, 365],
]);

export const determineMaxNumberOfEventsToDisplayInUi = (resolution: number) => {
  if (resolution <= 300000) {
    return 4032; // Five minute-intervals show two weeks' worth of data
  }

  if (resolution <= 900000) {
    return 5840; // Fifteen minute intervals show two months' worth of data
  }

  if (resolution <= 86400000) {
    return 4380; // Hourly intervals show six months' worth of data
  }

  return 1825; // Daily intervals show five years' worth of data
};

// Calculates the recent mean values for the delta column in materialization quality panels.
export const calculateAllRecentMeans = (
  panelContext: BatchMaterializationMonitoringPanelContextProps,
  indices: number[]
) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const windowSize = timeResolutionWindowMap.get(panelContext.timeComparisonBasis)!; // Get the correct time comparison basis

  const rowCountsMeanTest = calculateRecentMean(indices, panelContext.rowCountsData, windowSize);
  const joinKeysMeansTest = calculateRecentMean(indices, panelContext.joinKeys, windowSize);

  const featureMeans = panelContext.featureMetrics?.features.map((feature) => {
    return {
      name: feature.name,
      value: calculateRecentMeanPercentageForNullValues(indices, feature.data, panelContext.rowCountsData, windowSize),
    };
  });

  return {
    rowCounts: rowCountsMeanTest,
    joinKeys: joinKeysMeansTest,
    features: featureMeans,
  };
};

export const calculatePercentagesByDateForFeatureViews = (
  featureData: MaterializationEvent[],
  rowsData: MaterializationEvent[]
) => {
  return featureData.map((datum, index) => {
    const rowsAtDate = rowsData[index].value;
    const nullsAtDate = _.clone(datum);

    if (rowsAtDate === 0) {
      nullsAtDate.value = undefined;
      return nullsAtDate;
    }

    if (nullsAtDate.status === MaterializationMetricStatus.METRIC_STATUS_AVAILABLE) {
      const percentage = rowsAtDate && nullsAtDate.value ? nullsAtDate.value / rowsAtDate : 0;
      nullsAtDate.value = percentage;
    }

    return nullsAtDate;
  });
};

// Calculates the recent mean for individual batch materialization intervals on hover
export const calculateRecentMean = (
  highlightedIndices: number[],
  allEvents: MaterializationEvent[],
  windowSize: number
) => {
  const startIndex = highlightedIndices[0];
  const backwardsWindow = [startIndex - windowSize + 1 < 0 ? 0 : startIndex - windowSize, startIndex - 1];
  const backwardsEvents = clone(allEvents)
    .slice(backwardsWindow[0], backwardsWindow[1])
    .filter((event) => event.status === MaterializationMetricStatus.METRIC_STATUS_AVAILABLE);

  // As number is required because d3.mean's signature can return undefined
  return mean(backwardsEvents.map((event) => event.value)) as number;
};

export const calculateRecentMeanPercentageForNullValues = (
  highlightedIndices: number[],
  allEvents: MaterializationEvent[],
  rowEvents: MaterializationEvent[],
  windowSize: number
) => {
  const startIndex = highlightedIndices[0];
  const backwardsWindow = [startIndex - windowSize + 1 < 0 ? 0 : startIndex - windowSize, startIndex - 1];

  const backwardsEvents = clone(allEvents)
    .slice(backwardsWindow[0], backwardsWindow[1])
    .filter((event) => event.status === MaterializationMetricStatus.METRIC_STATUS_AVAILABLE);

  const backwardsRows = clone(rowEvents)
    .slice(backwardsWindow[0], backwardsWindow[1])
    .filter((event) => event.status === MaterializationMetricStatus.METRIC_STATUS_AVAILABLE);

  const backwardsPercentages = calculatePercentagesByDateForFeatureViews(backwardsEvents, backwardsRows).filter(
    (event) => event.status === MaterializationMetricStatus.METRIC_STATUS_AVAILABLE
  );

  return mean(backwardsPercentages.map((event) => event.value)) as number;
};

// Returns subsets of the feature view data that match the date range
export const filterFeatureViewDataForTimeRange = (data: MaterializationQueryFeatureResult) => {
  return data.features.map((feature: MaterializationQueryIndividualFeature) => {
    return {
      name: feature.name,
      data: feature.data,
    };
  });
};

export const getIndexForDate = (matchDate: Date, dates: Date[]) => {
  const times: number[] = dates.map((date) => date.getTime());
  return times.indexOf(matchDate.getTime());
};

export const getMatchingEventForDate = (date: Date, events: MaterializationEvent[]) => {
  const search = events.filter((event) => event.date.getTime() === date.getTime());
  if (search.length === 0) {
    return undefined;
  }
  return search[0];
};

export const getNearestEventToDate = (date: Date, dates: Date[]) => {
  const targetTime = date.getTime();
  return dates.sort((a, b) => Math.abs(a.getTime() - targetTime) - Math.abs(b.getTime() - targetTime))[0];
};

export const parseFeatureResponse = (
  results: MaterializationQueryMetricResponse
): MaterializationQueryFeatureResult => {
  const arrayToReturn: { name: string; data: MaterializationEvent[] }[] = [];
  const features = results.column_names.filter((columnName) => columnName !== 'joinKey');

  const featuresToIndexMap = getMapOfFeatureColumnsToFeatureDataOrder(results, features);

  const timeResolution = parseMetricTime(results.metric_data_point_interval);
  const maximumRowsToDisplay = determineMaxNumberOfEventsToDisplayInUi(timeResolution);
  const slicedMetricData = results.metric_data?.slice(-maximumRowsToDisplay); // Limits the number of events passed to the UI to be rendered

  features.forEach((feature: string) => {
    if (results.metric_data) {
      const filteredData = slicedMetricData.map((datum) => {
        const matchingIndex = featuresToIndexMap.get(feature);
        return {
          date: new Date(datum.interval_start_time),
          id: datum.materialization_run_id ? datum.materialization_run_id : undefined,
          value:
            datum.metric_values !== undefined &&
            matchingIndex !== undefined &&
            datum.metric_values[matchingIndex] !== undefined
              ? +datum.metric_values[matchingIndex].value
              : 0,
          status: datum.metric_status,
          url: datum.materialization_task_attempt_url,
        };
      });
      arrayToReturn.push({ name: feature, data: filteredData });
    }
  });

  return { apiResponse: results, features: arrayToReturn };
};

export const parseMetricsAndExpectationsDefinitions = (
  data: GetMetricAndExpectationDefinitionResponse
): FeatureAndMetricMap => {
  const flattenedValues = new Map<string, MaterializationMetricTypes[]>();
  if (data.metrics) {
    const allSplit: { metric: MaterializationMetricTypes; feature: string }[] = [];

    data.metrics.map((metric) => {
      const split = metric.name?.split('__');
      split &&
        split[1] !== 'joinKeys' &&
        allSplit.push({
          metric: split[0].toUpperCase() as MaterializationMetricTypes,
          feature: split[1],
        });
    });

    const groupedValues = group(allSplit, (datum) => datum.feature);

    for (const [feature, rawMetrics] of Array.from(groupedValues)) {
      flattenedValues.set(
        feature,
        rawMetrics.map((metricObject) => metricObject.metric)
      );
    }
  }
  return flattenedValues as FeatureAndMetricMap;
};

const getMapOfFeatureColumnsToFeatureDataOrder = (results: MaterializationQueryMetricResponse, features: string[]) => {
  const featuresToIndexMap = new Map<string, number>();

  if (results.metric_data) {
    const findMatchingDate = results.metric_data.find((dataForDate) => dataForDate.metric_values);
    if (findMatchingDate) {
      const featuresInMatchingMetricData = findMatchingDate.metric_values.map(
        (matchingMetricValues) => matchingMetricValues.feature_name
      );

      features.forEach((feature) => {
        featuresToIndexMap.set(feature, featuresInMatchingMetricData.indexOf(feature));
      });
    }
  }

  return featuresToIndexMap;
};

export const parseFeatureViewResponse = (results: MaterializationQueryMetricResponse): MaterializationQueryResult => {
  let arrayToReturn: MaterializationEvent[] = [];

  if (results.metric_data) {
    const timeResolution = parseMetricTime(results.metric_data_point_interval);
    const maximumRowsToDisplay = determineMaxNumberOfEventsToDisplayInUi(timeResolution);
    const slicedMetricData = results.metric_data.slice(-maximumRowsToDisplay);

    arrayToReturn = slicedMetricData.map((datum) => {
      return {
        date: new Date(datum.interval_start_time),
        id: datum.materialization_run_id,
        url: datum.materialization_task_attempt_url,
        value: datum.metric_values ? +datum.metric_values[0].value : 0,
        status: datum.metric_status,
      };
    });
  }

  return { apiResponse: results, data: arrayToReturn };
};

export const rollUpJobs = (data: MaterializationQueryResult) => {
  const rawJobs = group(data.data, (event: MaterializationEvent) => event.id);
  // eslint-disable-next-line prefer-const
  let jobs: MaterializationJob[] = [];

  const intervalInMs = parseMetricTime(data.apiResponse.metric_data_point_interval);

  // TODO: This is verbose. Make it simpler!
  rawJobs.forEach((job) => {
    if (job[0].id === undefined) {
      return;
    }
    const dateRange = extent(job.map((event) => event.date)) as Date[];

    jobs.push({
      id: job[0].id,
      url: job[0].url,
      events: job,
      range: { start: dateRange[0], end: new Date(dateRange[1].getTime() + intervalInMs) },
      status: MaterializationMetricStatus.METRIC_STATUS_AVAILABLE,
    });
  });

  data.data
    .filter((event) => event.status === MaterializationMetricStatus.METRIC_STATUS_UNAVAILABLE)
    .forEach((event) => {
      jobs.push({
        id: event.id ? event.id : '',
        url: event.url,
        events: [event],
        range: { start: event.date, end: new Date(event.date.getTime() + intervalInMs) },
        status: MaterializationMetricStatus.METRIC_STATUS_UNAVAILABLE,
      });
    });

  data.data
    .filter((event) => event.status === MaterializationMetricStatus.METRIC_STATUS_NO_MATERIALIZATION)
    .forEach((event) => {
      jobs.push({
        id: event.id ? event.id : '',
        url: event.url,
        events: [event],
        range: { start: event.date, end: new Date(event.date.getTime() + intervalInMs) },
        status: MaterializationMetricStatus.METRIC_STATUS_NO_MATERIALIZATION,
      });
    });

  data.data
    .filter((event) => event.status === MaterializationMetricStatus.METRIC_STATUS_ERROR)
    .forEach((event) => {
      jobs.push({
        id: event.id ? event.id : '',
        url: event.url,
        events: [event],
        range: { start: event.date, end: new Date(event.date.getTime() + intervalInMs) },
        status: MaterializationMetricStatus.METRIC_STATUS_ERROR,
      });
    });

  jobs.sort((a, b) => a.range.start.getTime() - b.range.start.getTime());

  return jobs;
};

export const parseMetricTime: (input: string) => number = (input: string) => {
  return +input.replace('s', '') * 1000;
};

export const determineTimeResolution = (timeResolution: number) => {
  if (timeResolution <= 60000) {
    return MaterializationMetricResolutionGroups.TIME_RESOLUTION_FIVE_MINUTES;
  }

  if (timeResolution <= 300000) {
    return MaterializationMetricResolutionGroups.TIME_RESOLUTION_ONE_HOUR;
  }

  if (timeResolution <= 3600000) {
    return MaterializationMetricResolutionGroups.TIME_RESOLUTION_ONE_DAY;
  }

  if (timeResolution <= 86400000) {
    return MaterializationMetricResolutionGroups.TIME_RESOLUTION_ONE_WEEK;
  }

  return MaterializationMetricResolutionGroups.TIME_RESOLUTION_ONE_MONTH;
};

export const getDescriptionStringForTimeComparisonBasis = (basis: MaterializationMetricResolutionGroups) => {
  switch (basis) {
    case MaterializationMetricResolutionGroups.TIME_RESOLUTION_ONE_MINUTE:
      return 'Δ vs. prior minute';
    case MaterializationMetricResolutionGroups.TIME_RESOLUTION_FIVE_MINUTES:
      return 'Δ vs. prior 5 minutes';
    case MaterializationMetricResolutionGroups.TIME_RESOLUTION_ONE_HOUR:
      return 'Δ vs. prior hour';
    case MaterializationMetricResolutionGroups.TIME_RESOLUTION_ONE_DAY:
      return 'Δ vs. prior day';
    case MaterializationMetricResolutionGroups.TIME_RESOLUTION_ONE_WEEK:
      return 'Δ vs. prior week';
    case MaterializationMetricResolutionGroups.TIME_RESOLUTION_ONE_MONTH:
      return 'Δ vs. prior month';
    case MaterializationMetricResolutionGroups.TIME_RESOLUTION_ONE_YEAR:
      return 'Δ vs. prior year';
  }
};

export const getColorForMetricStatus = (status: MaterializationMetricStatus) => {
  let color;

  switch (status) {
    case MaterializationMetricStatus.METRIC_STATUS_AVAILABLE:
      color = '#0fc47e';
      break;
    case MaterializationMetricStatus.METRIC_STATUS_UNAVAILABLE:
      color = '#E1E5EA';
      break;
    case MaterializationMetricStatus.METRIC_STATUS_ERROR:
      color = '#d42915';
      break;
    case MaterializationMetricStatus.METRIC_STATUS_NO_MATERIALIZATION:
      color = '#ffab00';
      break;
  }

  return color;
};

export const materializationPanelDateFormat = utcFormat('%Y-%m-%d');
export const materializationPanelTimeFormat = utcFormat('%H:%M');

export const externalRunLayout = (context: BatchMaterializationMonitoringPanelContextProps) => {
  if (context.usableWidth) {
    if (!context.rowCountsData || !context.allDates) {
      return;
    }

    const correctSize = Math.floor(context.usableWidth / context.bandWidth);

    let endDate;
    if (context.endDate === undefined) {
      const allDates: Date[] = context.rowCountsData.map((datum: MaterializationEvent) => datum.date);

      endDate = allDates[allDates.length - 1];
    } else {
      endDate = context.endDate;
    }

    const allTimes = context.allDates.map((date) => date.getTime());
    const endIndex = allTimes.indexOf(endDate.getTime());
    const getStartIndex = endIndex - correctSize;
    const startIndex = getStartIndex <= 0 ? 0 : getStartIndex;
    const startDate = context.allDates[startIndex];

    context.setWindowStartDate(startDate);
    context.setChartColumnWidth(context.usableWidth);
    context.setWindowSize(Math.floor(context.chartColumnWidth / context.bandWidth));
    const correctedTransform = `translate(${-context.chartScale(startDate) - context.bandWidth},0)`;
    context.setCanvasTranslate(
      -context.chartScale(startDate) - context.bandWidth + context.chartScale.range()[0] + context.chartScale.range()[1]
    );
    context.setTransform(correctedTransform);
    context.setWindowEndDate(endDate);
  }
};

export const externalHandleDrag = (
  attemptedStartDate: Date,
  props: BatchMaterializationMonitoringPanelContextProps
) => {
  if (props.usableWidth) {
    const correctSize = Math.floor(props.usableWidth / props.bandWidth);
    if (!props.rowCountsData) {
      return;
    }

    const dates = props.rowCountsData.map((datum) => datum.date);
    const roundedStart = getNearestEventToDate(attemptedStartDate, dates);
    const roundedStartTime = roundedStart.getTime();
    const allTimes = dates.map((date) => date.getTime()).sort();
    const startIndex = allTimes.indexOf(roundedStartTime);

    if (startIndex + correctSize >= allTimes.length) {
      // Jump to start if we're passed it
      const startDate = props.allDates[props.allDates.length - correctSize - 1];
      externalHandleDrag(startDate, props);

      return;
    }

    props.setWindowStartDate(roundedStart);
    props.setWindowEndDate(new Date(allTimes[startIndex + correctSize]));

    const correctedTransform = `translate(${-props.chartScale(roundedStart) - props.bandWidth},0)`;
    props.setCanvasTranslate(
      -props.chartScale(roundedStart) - props.bandWidth + props.chartScale.range()[0] + props.chartScale.range()[1]
    );
    props.setTransform(correctedTransform);
  }
};

export const handleIndicesSelection = (
  indices: number[] | undefined,
  props: BatchMaterializationMonitoringPanelContextProps
) => {
  // Only update highlighted indices if there's a change
  if (isEqual(indices, props.highlightedIndices)) {
    return;
  }

  props.setHighlightedIndices(indices);
};

export const externalHighlightsWereSelected = (
  events: MaterializationEvent[] | undefined,
  props: BatchMaterializationMonitoringPanelContextProps
) => {
  // Only update highlighted indices if there's a chang
  if (isEqual(events, props.highlightedEvents)) {
    return;
  }

  props.setHighlightedEvents(events);
};

export const tableWidthWasSet = (width: number, context: BatchMaterializationMonitoringPanelContextProps) => {
  context.setUsableWidth(width);
  externalRunLayout(context);
};

export const calculatePositionForFeatureViewCreatedDate = (
  timeScale: ScaleTime<number, number, never>,
  createdDate: Date
) => {
  const minX = 175;
  const maxX = timeScale.range()[1] - 175;

  const actualPositionOfCreatedDate = timeScale(createdDate);
  let offsetPositionOfCreatedDate = actualPositionOfCreatedDate;
  if (actualPositionOfCreatedDate > maxX) {
    offsetPositionOfCreatedDate = maxX;
  }

  if (actualPositionOfCreatedDate < minX) {
    offsetPositionOfCreatedDate = minX;
  }

  return { actualPositionOfCreatedDate, offsetPositionOfCreatedDate };
};

export const getDefaultValues = (
  validJobs: MaterializationJob[],
  rowCountsData: MaterializationEvent[],
  joinKeysData: MaterializationEvent[],
  nullValuesData: MaterializationQueryFeatureResult,
  timeComparisonBasis: MaterializationMetricResolutionGroups
) => {
  const defaultValues: MaterializationMetricsDefaultValuesForPanel = {
    mostRecentJob: undefined,
    rowsValue: undefined,
    rowsDelta: undefined,
    joinKeysValue: undefined,
    joinKeysDelta: undefined,
    features: [],
  };

  if (validJobs.length !== 0) {
    const mostRecentJob = clone(validJobs).reverse()[0];
    defaultValues.mostRecentJob = mostRecentJob;

    const [rowValue, rowDelta] = getValueAndMeanForRow(mostRecentJob, rowCountsData, timeComparisonBasis);
    defaultValues.rowsValue = rowValue;
    defaultValues.rowsDelta = rowDelta;

    const [joinKeyValue, joinKeyDelta] = getValueAndMeanForRow(mostRecentJob, joinKeysData, timeComparisonBasis);
    defaultValues.joinKeysValue = joinKeyValue;
    defaultValues.joinKeysDelta = joinKeyDelta;

    // eslint-disable-next-line prefer-const
    let matchingFeatureRows: { featureName: string; value: number | undefined; delta: number | undefined }[] = [];
    nullValuesData?.features.forEach((feature) => {
      const matchingFeatureValue = getMatchingEventForDate(mostRecentJob.range.start, feature.data);
      if (matchingFeatureValue && matchingFeatureValue?.value !== undefined) {
        const [featureValue, featureMean] = getValueAndMeanForRow(mostRecentJob, feature.data, timeComparisonBasis);
        matchingFeatureRows.push({ featureName: feature.name, value: featureValue, delta: featureMean });
      }
    });

    defaultValues.features = matchingFeatureRows;
  }
  return defaultValues;
};

const getValueAndMeanForRow = (
  mostRecentJob: MaterializationJob,
  events: MaterializationEvent[],
  timeComparisonBasis: MaterializationMetricResolutionGroups
) => {
  const matchingRows = getMatchingEventForDate(mostRecentJob.range.start, events);
  let value, mean;

  if (matchingRows) {
    const matchingIndex = events.indexOf(matchingRows);
    if (matchingIndex !== -1) {
      value = matchingRows.value;
      mean = calculateRecentMean(
        [matchingIndex],
        events,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        timeResolutionWindowMap.get(timeComparisonBasis)!
      );
    }
  }

  return [value, mean];
};

export const getDateTicks = (timeResolution: MaterializationMetricResolutionGroups, allDates: Date[]) => {
  const start = allDates[0];
  const end = allDates[allDates.length - 1];

  switch (timeResolution) {
    case MaterializationMetricResolutionGroups.TIME_RESOLUTION_ONE_MINUTE:
      return utcHours(start, end);
    case MaterializationMetricResolutionGroups.TIME_RESOLUTION_FIVE_MINUTES:
      return utcHours(start, end);
    case MaterializationMetricResolutionGroups.TIME_RESOLUTION_ONE_HOUR:
      return utcHours(start, end);
    case MaterializationMetricResolutionGroups.TIME_RESOLUTION_ONE_DAY:
      return utcDays(start, end);
    case MaterializationMetricResolutionGroups.TIME_RESOLUTION_ONE_WEEK:
      return utcSundays(start, end);
    case MaterializationMetricResolutionGroups.TIME_RESOLUTION_ONE_MONTH:
      return utcMonths(start, end);
    case MaterializationMetricResolutionGroups.TIME_RESOLUTION_ONE_YEAR:
      return utcYears(start, end);
  }
};

export const getDateTickInterval = (timeResolution: MaterializationMetricResolutionGroups) => {
  switch (timeResolution) {
    case MaterializationMetricResolutionGroups.TIME_RESOLUTION_ONE_MINUTE:
      return 6;
    case MaterializationMetricResolutionGroups.TIME_RESOLUTION_FIVE_MINUTES:
      return 6;
    case MaterializationMetricResolutionGroups.TIME_RESOLUTION_ONE_HOUR:
      return 6;
    case MaterializationMetricResolutionGroups.TIME_RESOLUTION_ONE_DAY:
      return 7;
    case MaterializationMetricResolutionGroups.TIME_RESOLUTION_ONE_WEEK:
      return 4;
    case MaterializationMetricResolutionGroups.TIME_RESOLUTION_ONE_MONTH:
      return 3;
    case MaterializationMetricResolutionGroups.TIME_RESOLUTION_ONE_YEAR:
      return 1;
  }
};

export const getPriorValues = (
  data: MaterializationEvent[],
  highlightedIndices: number[] | undefined,
  priorValuesCount: number
) => {
  if (highlightedIndices) {
    const attemptStartIndex = highlightedIndices[0] - priorValuesCount;
    const actualStartIndex = attemptStartIndex <= 0 ? 0 : attemptStartIndex;
    return clone(data)
      .slice(actualStartIndex, highlightedIndices[0] - 1)
      .map((datum) => datum.value)
      .filter((value) => value !== undefined && value !== 0) as number[];
  }
  return [];
};
