export default class OktaUser {
  private proto: any = null;

  constructor(proto: any) {
    this.proto = proto;
  }

  get _proto() {
    return this.proto;
  }

  get name() {
    if (!this._proto.first_name && !this._proto.last_name) {
      return '';
    }
    return this._proto.first_name + ' ' + this._proto.last_name;
  }

  get email() {
    return this._proto.login_email;
  }

  get id() {
    return this._proto.okta_id;
  }

  get status() {
    return this._proto.okta_status;
  }

  get lastLogin() {
    return this._proto.last_login ? new Date(this._proto.last_login) : null;
  }

  get createdAt() {
    return new Date(this._proto.created_at);
  }
}
