import React, { useEffect } from 'react';
import { Link } from '@tecton';
import { css } from '@emotion/css';
import { TECTON_DOCUMENTATION_LINKS } from '../redesign/application-links';

const BottomBanner = () => {
  const bannerHeight = 41;
  const bannerClass = css`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    background-color: #edf8ff;
    color: #022738;
    padding: 10px;
    text-align: center;
    font-weight: 400;
    line-height: 16.34px;
    height: ${bannerHeight}px;
  `;

  useEffect(() => {
    const body = document.querySelector('body');
    if (body) {
      body.setAttribute('style', `padding-bottom:${bannerHeight}px`);
    }
  }, []);

  return (
    <div className={bannerClass}>
      Check out our{' '}
      <Link href={TECTON_DOCUMENTATION_LINKS.QUICK_START} target="_blank">
        Quickstart Tutorial
      </Link>{' '}
      to see how you can productionize an AI application with Tecton.
    </div>
  );
};

export default BottomBanner;
