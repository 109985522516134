import React, { FC } from 'react';
import styled from '@emotion/styled';
import { IconType, Button } from '@tecton/ComponentRedesign';
import { ReactComponent as FilterIcon } from '@svg/filters.svg';
import { ReactComponent as ChevronDown } from '@svg/chevronDown.svg';

interface TectonFilterProps {
  quantity?: number;
  isActive?: boolean;
  onClick?: () => void;
  isDisabled?: boolean;
  hasActiveFilters?: boolean;
  icon?: IconType;
  label?: string;
  notification?: 'string' | number;
}

const StyledButton = styled(Button)<{ isActive: boolean }>(({ theme, isActive }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.padding.xs,
  height: '40px',
  cursor: 'pointer',
  padding: `0px ${theme.padding.s}`,
  color: theme.colors.emptyShade,
  backgroundColor: isActive ? theme.colors.lightestShade : theme.colors.emptyShade,
  fontWeight: isActive ? theme.font.weight.medium : theme.font.weight.regular,
  fontSize: theme.font.fontSizes.xs,
  borderRadius: theme.border.radius.medium,
  border: theme.border.thin,
}));

const Notification = styled.div(({ theme }) => ({
  display: 'inline',
  backgroundColor: theme.colors.fullShade,
  borderRadius: theme.border.radius.large,
  padding: `0px 7px`, // needs to be hard coded so bubble is round
  color: theme.colors.emptyShade,
}));

const ButtonLabel = styled.div(({ theme }) => ({
  display: 'flex',
  gap: theme.padding.s,
  alignItems: 'center',
}));

const TectonFilterButton: FC<TectonFilterProps> = ({
  quantity,
  label = 'Filters',
  isActive = false,
  onClick,
  isDisabled,
  icon,
}) => {
  const labelContent = (
    <ButtonLabel>
      {icon ? React.createElement(icon) : <FilterIcon />}

      <div>{label}</div>
      {isActive && <Notification>{quantity}</Notification>}

      <ChevronDown />
    </ButtonLabel>
  );

  return (
    <StyledButton
      isActive={isActive}
      variant={isDisabled === true ? 'disabledAction' : 'emptyAction'}
      label={labelContent}
      onClick={onClick}
      data-testid="filter-selector-button"
    />
  );
};

export default TectonFilterButton;
