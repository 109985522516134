import React, { FC } from 'react';

import { useNavigate } from 'react-router';
import {
  FlexItem,
  FlexGroupWrapper,
  ButtonEmpty,
  CenterContentLayout,
  DeprecatedText,
} from '@tecton/ComponentRedesign';
import { Routes } from '../../../../core/routes';
import { ReactComponent as PageNotFoundIcon } from '@svg/page-not-found.svg';
import { ReactComponent as ReloadIcon } from '@svg/reload.svg';

interface PageNotFoundProps {
  workspace: string;
}

const PageNotFound: FC<PageNotFoundProps> = ({ workspace }) => {
  const navigation = useNavigate();
  return (
    <CenterContentLayout data-testid="page-not-found">
      <FlexItem grow={0}>
        <FlexGroupWrapper direction="column" alignItems="center" justifyContent="center" gap="l">
          <FlexItem grow={0}>
            <PageNotFoundIcon />
          </FlexItem>

          <FlexItem>
            <FlexGroupWrapper gap="s">
              <FlexItem>
                <DeprecatedText fontSize="m" fontWeight="medium" lineHeight="m">
                  Page Not Found
                </DeprecatedText>
              </FlexItem>
              <FlexItem>
                <DeprecatedText fontSize="xs" fontWeight="regular">
                  What you're looking for doesn't exists.
                </DeprecatedText>
              </FlexItem>
            </FlexGroupWrapper>
          </FlexItem>

          <FlexItem>
            <ButtonEmpty
              onClick={() => {
                navigation(`${Routes.dashboard.replace(`:workspace`, workspace)}`);
              }}
              iconType={ReloadIcon}
            >
              Return Home
            </ButtonEmpty>
          </FlexItem>
        </FlexGroupWrapper>
      </FlexItem>
    </CenterContentLayout>
  );
};

export default PageNotFound;
