import React, { FC } from 'react';
import { useTectonTheme } from './TectonThemeProvider';
import { useEuiFontSize } from '@elastic/eui';
import { DescriptionList } from './_eui_components';
import { css } from '@emotion/css';

export interface AttributeListItem {
  title: NonNullable<React.ReactNode>;
  description: NonNullable<React.ReactNode>;
}

interface AttributesListProps {
  listItems: (AttributeListItem | undefined)[];
  compressed: boolean;
}

const AttributesList: FC<AttributesListProps> = ({ listItems, compressed }) => {
  const { theme } = useTectonTheme();

  const tectonDescriptionList = css`
    .euiDescriptionList__title {
      inline-size: 40%;
      color: ${theme.colors.fullShade};
      ${useEuiFontSize('s')}
      font-weight: 500;
    }

    .euiDescriptionList__description {
      inline-size: 60%;
      word-wrap: break-word;
      color: ${theme.colors.darkestShade};
      ${useEuiFontSize('s')}
    }

    .euiDescriptionList__title:not(:first-of-type),
    .euiDescriptionList__description:not(:first-of-type) {
      margin-block-start: ${compressed ? theme.size.s : theme.size.m};
    }
  `;

  return (
    <DescriptionList
      compressed={compressed}
      className={tectonDescriptionList}
      type="column"
      listItems={listItems.filter((item) => !!item) as AttributeListItem[]}
    />
  );
};

export default AttributesList;
