import React, { FC } from 'react';
import { useTectonTheme } from '@tecton/ComponentRedesign';
import styled from '@emotion/styled';
import { FontStyle, FontWeight, HeadingLevel, TextDecoration, TypeSize, Typeface } from '../ComponentTypes';

// Deprecated 10/3/2024
// Need to align with UX on a few instances of this old text

interface TectonTextProps {
  children: React.ReactNode;
  heading?: HeadingLevel;
  fontSize?: TypeSize;
  fontWeight?: FontWeight;
  lineHeight?: TypeSize;
  textDecoration?: TextDecoration;
  fontStyle?: FontStyle;
  typeface?: Typeface;
}

export const headingFontSizeMap = {
  '1': '34px',
  '2': '23.625px',
  '3': '19.25px',
  '4': '14px',
  '5': '12.25px',
  '6': '10.5px',
};

export const headingLineHeightMap = {
  '1': '40px',
  '2': '28px',
  '3': '24px',
  '4': '20px',
  '5': '16px',
  '6': '16px',
};

export const fontSizeMap: Record<TypeSize, string> = {
  l: '20px',
  m: '16px',
  s: '14px',
  xs: '12px',
  xxs: '10.5px',
  xxxs: '9px',
};

export const lineHeightMap: Record<TypeSize, string> = {
  l: '38.85px',
  m: '24px',
  s: '20px',
  xs: '13.71px',
  xxs: '12px',
  xxxs: '10.285px',
};

export const fontWeightMap: Record<FontWeight, string> = {
  thin: '300',
  regular: '400',
  medium: '500',
  semiBold: '600',
  bold: '700',
};

const TectonHeading: FC<TectonTextProps> = ({ children, heading }) => {
  const { theme } = useTectonTheme();

  const HeadingWrapper = styled.span`
    font-size: ${headingFontSizeMap[heading!]};
    line-height: ${headingLineHeightMap[heading!]};
    color: ${theme.colors.title};
    font-weight: 700;
    text-transform: ${heading === '6' ? 'uppercase' : 'none'};
  `;
  return <HeadingWrapper>{children}</HeadingWrapper>;
};

const DeprecatedText: FC<TectonTextProps> = ({
  children,
  fontSize = 's',
  fontWeight = 'regular',
  lineHeight = 's',
  textDecoration = 'none',
  fontStyle = 'none',
  typeface = 'Inter',
  heading,
}) => {
  const { theme } = useTectonTheme();

  if (heading) {
    return (
      <TectonHeading
        children={children}
        heading={heading}
        fontSize={fontSize}
        fontWeight={fontWeight}
        lineHeight={lineHeight}
      />
    );
  }

  const TextWrapper = styled.span`
    font-size: ${fontSizeMap[fontSize]};
    line-height: ${lineHeight ? lineHeightMap[lineHeight] : lineHeightMap[fontSize]};
    color: ${theme.colors.text};
    font-weight: ${fontWeightMap[fontWeight]};
    text-decoration: ${textDecoration};
    font-style: ${fontStyle};
    font-family: ${typeface};
  `;

  return <TextWrapper>{children}</TextWrapper>;
};

export default DeprecatedText;
