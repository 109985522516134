import { CallOut, useTectonTheme } from '@tecton';
import { css } from '@emotion/css';
import React from 'react';

const TectonCallout = (props: React.ComponentProps<typeof CallOut>) => {
  const { theme } = useTectonTheme();

  const color = theme.colors[props.color || 'primary'];

  const TectonCalloutCss = css`
    border-left: 4px solid ${color};
  `;

  return (
    <CallOut {...props} className={TectonCalloutCss + (props.className !== undefined ? ` ${props.className}` : '')} />
  );
};

export default TectonCallout;
