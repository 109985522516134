import React, { FC } from 'react';
import { Duration } from '../../types/google/protobuf/duration';
import EmptyValue from './EmptyValue';
import Monospace from './Monospace';
import { secondsToHumanFriendlyString } from '../../utils/misc-utils';

interface TimeIntervalDisplayProps {
  interval: Duration | number | undefined;
}

const TimeIntervalDisplay: FC<TimeIntervalDisplayProps> = ({ interval }) => {
  if (!interval) {
    return <EmptyValue />;
  }

  if (typeof interval === 'string') {
    // How is it possible for a Duration object to be a string?
    // TODO 1/4/2024: This is possibly caused by an error in autogenerated Duration protobuf object
    // Ideally, we don't want to check typeof for this, and should change it once we resolve the proto issue
    return <Monospace>{interval}</Monospace>;
  }

  if (!isNaN(interval as number)) {
    return <Monospace>{secondsToHumanFriendlyString(interval)}</Monospace>;
  }

  if (secondsToHumanFriendlyString((interval as Duration).seconds) === '') {
    return <EmptyValue />;
  }

  return <Monospace>{secondsToHumanFriendlyString((interval as Duration).seconds)}</Monospace>;
};

export default TimeIntervalDisplay;
