import { useState } from 'react';

const useItemExpansionState = () => {
  const [itemIdToExpandedRowMap, setMapOfExpandedItems] = useState<Record<string, React.ReactNode>>({});

  const expandItemWithContent = (rowId: string, content: React.ReactNode) => {
    setMapOfExpandedItems({
      ...itemIdToExpandedRowMap,
      [rowId]: content,
    });
  };

  const collapseItem = (rowId: string) => {
    const clone = { ...itemIdToExpandedRowMap };

    delete clone[rowId];

    setMapOfExpandedItems(clone);
  };

  return {
    itemIdToExpandedRowMap,
    expandItemWithContent,
    collapseItem,
  };
};

type UseItemExpansionPropTypes = ReturnType<typeof useItemExpansionState>;

export default useItemExpansionState;
export type { UseItemExpansionPropTypes };
