import React, { useState, useEffect, useRef } from 'react';
import { EuiPopover, EuiFilterGroup, EuiSelectable, EuiSpacer, EuiSelectableOption } from '@elastic/eui';
import { IconType, FilterButton } from '@tecton/ComponentRedesign';
import styled from '@emotion/styled';

export type SelectableOption = EuiSelectableOption;
interface SelectProps<T extends EuiSelectableOption> {
  options: T[];
  onChange: (options: T[], e?: any, option?: T) => void;
  label: string;
  isDisabled?: boolean;
  icon?: IconType;
  singleSelection?: boolean;
  showIcons?: boolean;
}

interface GenericSelectProps<T extends EuiSelectableOption> extends SelectProps<T> {
  isSingle?: boolean;
}

export const SelectWrapper = styled.div`
  display: flex;
  justify-items: flex-start;
  align-items: center;
`;

export const findSelected = (options: EuiSelectableOption[]): string =>
  options.find((option) => option?.checked)?.label || '';

const GenericSelect = <T extends EuiSelectableOption>({
  options,
  onChange,
  icon,
  label,
  isDisabled,
  isSingle,
  showIcons = true,
  singleSelection = false,
}: GenericSelectProps<T>) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [width, setWidth] = useState<number | undefined>(undefined);
  const wrapperRef = useRef<HTMLDivElement>(null); // Ref for the parent container

  const onButtonClick = () => {
    setIsPopoverOpen(!isPopoverOpen);
  };

  const closePopover = () => {
    setIsPopoverOpen(false);
  };

  const onSelected = (options: T[], e: any, option: T) => {
    if (isSingle) {
      closePopover();
    }
    onChange(options, e, option);
  };

  const button = <FilterButton onClick={onButtonClick} icon={icon} label={label} isDisabled={isDisabled} />;

  useEffect(() => {
    if (wrapperRef.current) {
      setWidth(wrapperRef.current.offsetWidth);
    }
  }, [isPopoverOpen]);

  return (
    <SelectWrapper ref={wrapperRef}>
      <EuiSpacer />
      <EuiFilterGroup>
        <EuiPopover
          key={'tecton-selector-popover'}
          button={button}
          isOpen={isPopoverOpen}
          closePopover={closePopover}
          panelPaddingSize="none"
        >
          <EuiSelectable
            allowExclusions={false}
            singleSelection={singleSelection}
            aria-label={'multi-select-list'}
            options={options}
            onChange={(newOptions, e, option) => onSelected(newOptions, e, option)}
            data-testid="multi-select-list"
            style={{ width: width }}
            listProps={{
              showIcons: showIcons,
            }}
          >
            {(list) => <div>{list}</div>}
          </EuiSelectable>
        </EuiPopover>
      </EuiFilterGroup>
    </SelectWrapper>
  );
};

export const MultiSelect = <T extends EuiSelectableOption>(props: SelectProps<T>) => <GenericSelect {...props} />;
export const Select = <T extends EuiSelectableOption>(props: SelectProps<T>) => (
  <GenericSelect {...props} isSingle={true} showIcons={false} />
);

export default MultiSelect;
