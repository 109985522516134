import { EuiLoadingSpinner } from '@elastic/eui';
import React, { FC } from 'react';

interface TectonLoadingProps {
  size?: 'm' | 'l' | 'xl' | 'xxl';
}

const TectonLoading: FC<TectonLoadingProps> = ({ size }) => {
  // TODO: Unless this actually needs to be customized, we can just move this over to importing directly from EUI
  // Leaving this in until review
  return <EuiLoadingSpinner size={size || 's'} />;
};

export default TectonLoading;
