import IdUtils from '../utils/id-utils';
import { secondsToHumanFriendlyString } from '../utils/misc-utils';

export default class MaterializationAttemptStatus {
  #proto = null;

  constructor(proto) {
    this.#proto = proto;
  }

  get _proto() {
    return this.#proto;
  }

  get id() {
    return IdUtils.toStringId(this._proto.materialization_task_id);
  }

  get jobStateForUi() {
    // Assumes this is the latest attempt for the job
    const attemptState = this.attemptState;
    if (
      this.isPermanentFailure ||
      // Special case for old failed stream jobs that won't retry, and are shown as FAILED in the UI
      (this.attemptState === 'ERROR' && this.dataSourceType === 'STREAM' && !this.retryTime)
    )
      return 'FAILED';
    return this.attemptState === 'ERROR' ? 'RETRYING' : attemptState;
  }

  get attemptState() {
    return this._proto.materialization_state.replace('MATERIALIZATION_STATUS_STATE_', '');
  }

  get durationTextInSeconds() {
    return this._proto.duration;
  }

  get durationInSeconds() {
    if (this._proto.duration) {
      const durationText = this._proto.duration.replace('s', '');
      const duration = parseInt(durationText);
      return duration;
    }

    return this._proto.duration;
  }

  get offlineRows() {
    if (this._proto.attempt_consumption) {
      return this._proto.attempt_consumption.offline_rows_written;
    }
    return null;
  }

  get onlineRows() {
    if (this._proto.attempt_consumption) {
      return this._proto.attempt_consumption.online_rows_written;
    }
    return null;
  }

  get durationHumanize() {
    if (this.durationInSeconds) {
      return secondsToHumanFriendlyString(this.durationInSeconds);
    }

    return this.durationInSeconds;
  }

  get progress() {
    return this._proto.progress;
  }

  get attemptNumber() {
    return this._proto.attempt_number;
  }

  get dataSourceType() {
    return this._proto.data_source_type.replace('DATA_SOURCE_TYPE_', '');
  }

  get windowStartTime() {
    return this._proto.window_start_time;
  }

  get windowEndTime() {
    return this._proto.window_end_time;
  }

  get featureStartTime() {
    return this._proto.feature_start_time;
  }

  get featureEndTime() {
    return this._proto.feature_end_time;
  }

  get runPageUrl() {
    return this._proto.run_page_url;
  }

  get tectonManagedAttemptId() {
    return this._proto?.tecton_managed_attempt_id
      ? IdUtils.toStringId(this._proto?.tecton_managed_attempt_id)
      : undefined;
  }

  get hadSpotInstanceFailure() {
    return this._proto.spot_instance_failure;
  }

  get isPermanentFailure() {
    return this._proto.is_permanent_failure;
  }

  get stateMessage() {
    return this._proto.state_message;
  }

  get terminationReason() {
    return this._proto?.termination_reason?.replaceAll('_', ' ');
  }

  get allowCancel() {
    return this._proto.allow_cancel;
  }

  get allowRetry() {
    return this._proto.allow_forced_retry;
  }

  get allowOverwriteRetry() {
    return this._proto.allow_overwrite_retry;
  }

  get isWriteToOnlineStore() {
    return this._proto.write_to_online_feature_store;
  }

  get isWriteToOfflineStore() {
    return this._proto.write_to_offline_feature_store;
  }

  get retryTime() {
    return this._proto.retry_time;
  }

  get createdAt() {
    return this._proto.attempt_created_at ? new Date(this._proto.attempt_created_at) : null;
  }

  get createdAtUtc() {
    return this._proto.attempt_created_at;
  }
}
