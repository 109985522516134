import debounce from 'lodash/debounce';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

const callbacks = new Set<() => void>();

/**
 * Calls all of the listeners that have been registered with `useHandleResize`.
 * Debounces _AND_ wraps then in `unstable_batchedUpdates` so that the UI is updated
 * with a minimal amount of flicker/lag.
 */
const notify = debounce(() => {
  ReactDOM.unstable_batchedUpdates(() => {
    callbacks.forEach((cb) => cb());
  });
}, 200);

export function useHandleResize(callback: () => void): void {
  /**
   * Store the callback in a ref so that we don't re-trigger the useEffect when
   * the callback changes and so that we don't have to be concerned about passing
   * in a callback wrapped in `React.useCallback`.
   */
  const callbackRef = React.useRef(callback);

  if (callbackRef.current !== callback) {
    callbackRef.current = callback;
  }

  useEffect(() => {
    /**
     * Wrap the current value of the ref so that we don't need to re-trigger the useEffect.
     */
    const wrapper = () => callbackRef.current();

    /**
     * Add the callback to the set of callbacks to be notified when the window is resized.
     */
    callbacks.add(wrapper);

    /**
     * If adding the callback was the first one, then start observing for resize events.
     * We only need to observe this once, but we don't want to observe it if there are
     * no callbacks to call because we would waste cycles.
     */
    if (callbacks.size === 1) {
      window.addEventListener('resize', notify);
    }

    return () => {
      callbacks.delete(wrapper);

      /**
       * After cleaning up the callback, if there are no other callbacks left, then stop
       * observing resize. This is just to prevent wasted cycles if the window
       * is resized but there are no callbacks to call.
       */
      if (callbacks.size === 0) {
        window.addEventListener('resize', notify);
      }
    };
  }, []);
}
