import { EuiDatePicker, EuiDatePickerProps } from '@elastic/eui';
import { css } from '@emotion/css';
import { Theme } from '@emotion/react';
import styled from '@emotion/styled';
import { FormRow, useTectonTheme } from '@tecton/ComponentRedesign';
import moment, { Moment } from 'moment-timezone';
import React, { FC } from 'react';

// icons
import CalendarIcon from '@svg/calendar.svg';

interface TectonDatePickerProps extends EuiDatePickerProps {
  label?: React.ReactNode;
  error?: boolean;
  disabled?: boolean;
  placeholder?: string;
  errorText?: React.ReactNode;
  message?: React.ReactNode;
  dateAndTime?: boolean;
  timeOnly?: boolean;
  date?: moment.Moment;
  setDate: (date: Moment) => void;
  noBorder?: boolean;
  minDate?: moment.Moment;
  maxDate?: moment.Moment;
  timezone?: string | undefined;
}

interface DefineBordersProps {
  theme: Theme;
  error: boolean;
  noBorder: boolean;
}

interface BorderProps {
  border: string;
  focusBorder: string;
}

const defineBorders = ({ theme, error, noBorder }: DefineBordersProps): BorderProps => {
  let border = theme.border.thin;
  let focusBorder = theme.border.thin;

  if (error) {
    border = `1px solid ${theme.colors.danger}`;
    focusBorder = `1px solid ${theme.colors.danger}`;
  }

  if (noBorder) {
    border = 'none';
    focusBorder = 'none';
  }

  return { border, focusBorder };
};

const StyledDatePicker = styled(EuiDatePicker)<Omit<TectonDatePickerProps, 'setDate'>>(
  ({ theme, error = false, noBorder = false }) => {
    const { border, focusBorder } = defineBorders({ error, theme, noBorder });
    return {
      fontSize: theme.font.fontSizes.s,
      border: border,
      color: theme.colors.text,
      paddingLeft: '35px',
      ':focus': {
        border: focusBorder,
        outline: 'none',
        transition: 'none',
        boxShadow: 'none',
        backgrounIimage: 'none',
        backgroundSize: 'none',
        backgroundColor: 'none',
      },
      cursor: 'pointer',
    };
  }
);

const TectonDatePicker: FC<TectonDatePickerProps> = ({
  label,
  error,
  disabled,
  placeholder,
  errorText,
  message,
  dateAndTime,
  timeOnly,
  date,
  setDate,
  noBorder,
  minDate,
  maxDate,
  timezone,
}) => {
  const { theme } = useTectonTheme();
  const defaultStyle = `
    background-color: ${theme.colors.emptyShade};
    color: ${theme.colors.fullShade};
  `;
  const hoverStyle = `
    background-color: ${theme.colors.body};
    color: ${theme.colors.fullShade};
  `;

  const activeStyle = `
    background-color: ${theme.colors.fullShade};
    color: ${theme.colors.emptyShade};
    border-radius:${theme.border.radius.medium};

    :hover {
      background-color: ${theme.colors.fullShade} !important;
      color: ${theme.colors.emptyShade} !important;
    }
  `;

  const titleClass = css`
    outline: none;

    .react-datepicker__month-read-view {
      ${defaultStyle};
    }

    .react-datepicker__year-read-view {
      ${defaultStyle};
    }

    .react-datepicker__month-option {
      ${defaultStyle};
      :hover {
        ${hoverStyle}
      }
    }

    .react-datepicker__month-option--preselected {
      ${activeStyle}
    }

    .react-datepicker__year-option {
      :hover {
        ${hoverStyle}
      }
    }

    .react-datepicker__year-option--preselected {
      ${activeStyle}
    }

    .react-datepicker__day {
      ${defaultStyle};

      text-decoration: none !important;

      :hover {
        ${hoverStyle}
      }
    }

    .react-datepicker__day--keyboard-selected {
      ${activeStyle}
    }

    .react-datepicker__time-list-item--selected {
      ${activeStyle}
      color:${theme.colors.emptyShade} !important;
    }

    .react-datepicker__time-list-item--selected:hover {
      background-color: ${theme.colors.body};
    }

    .react-datepicker__month-read-view--selected-month {
      font-weight: ${theme.font.weight.regular};
    }

    .react-datepicker__year-read-view--selected-year {
      font-weight: ${theme.font.weight.regular};
    }

    .react-datepicker__navigation--next {
      border: ${theme.border.thin};
    }

    .react-datepicker__navigation--next:hover {
      border: ${theme.border.thin};
      background-color: ${theme.colors.body};
    }

    .react-datepicker__time-box {
      background-color: ${theme.colors.emptyShade};
      border-left: ${theme.border.thin};
      padding: ${theme.padding.m} ${theme.padding.l};
    }

    .react-datepicker__time-list-item {
      color: ${theme.colors.fullShade};
      border: 1px solid ${theme.colors.lightShade};
      padding: ${theme.padding.m} ${theme.padding.l};
      font-size: ${theme.font.fontSizes.s};
      line-height: ${theme.font.lineHeights.m};
      font-weight: ${theme.font.weight.regular};
      border-radius: 10px;
      display: flex;
      align-items: center;
      text-decoration: none !important;
      transition: 0.2s;

      :hover {
        ${hoverStyle}
      }
    }

    .react-datepicker__time-list-item--preselected {
      ${activeStyle}
    }

    .react-datepicker__day--selected {
      ${activeStyle}
    }

    .react-datepicker__day--today {
      ::after {
        color: ${theme.colors.fullShade};
        content: '.';
        font-weight: ${theme.font.weight.bold};
        font-size: ${theme.font.fontSizes.m};
        transform: translateY(-4px);

        display: block;
        line-height: 0;
        padding: 0;
        margin: 0;
      }
    }

    .react-datepicker__day--disabled {
      color: ${theme.colors.subduedText};

      :hover {
        background-color: ${theme.colors.emptyShade};
        color: ${theme.colors.subduedText};
      }
    }
  `;

  return (
    <FormRow label={label} error={error} errorText={errorText} message={message}>
      <StyledDatePicker
        error={error || false}
        noBorder={noBorder || false}
        iconType={<CalendarIcon />}
        isInvalid={error}
        disabled={disabled}
        placeholder={placeholder}
        showTimeSelect={dateAndTime}
        showTimeSelectOnly={timeOnly}
        selected={date}
        calendarClassName={titleClass}
        utcOffset={timezone ? date?.tz(timezone).utcOffset() : undefined}
        onChange={(date) => {
          date && setDate(date);
        }}
        minDate={minDate}
        maxDate={maxDate}
        data-testid="date-picker"
        showIcon
      />
    </FormRow>
  );
};

export default TectonDatePicker;
