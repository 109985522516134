import { useQuery } from '@tanstack/react-query';
import { Tecton_Graphql } from '..';
import {
  GetRolesResponse,
  GetUserDetailsResponse,
  GetServiceAccountDetailsResponse,
  Group,
  GroupResponse,
  Query,
  ServiceAccount,
  User,
  GetAuthorizedResourcesResponse,
  RoleDefinition,
  AclGroupWorkspaceType,
  MembersCount,
  PermissionGroup,
} from '../gql/graphql';
import { PrincipalType } from '../../types/tecton_proto/auth/principal';

export enum ACLReactQueryKeyEnum {
  getAClUsersTableDataGQL = 'getAClUsersTableDataGQL',
  getACLServiceAccountsTableGQL = 'getACLServiceAccountsTableGQL',
  getACLGroupsTableGQL = 'getACLGroupsTableGQL',
  getACLGroupDetailsGQL = 'getACLGroupDetailsGQL',
  getAClUserDetails = 'getAClUserDetails',
  getAClServiceAccountDetails = 'getAClServiceAccountDetails',
  getPermissions = 'getPermissions',
}

export const useGetACLUserTable = (principalId: string, isAdmin: boolean) => {
  const getAClUserTableData = `
  query getAClUserTableData($principalInput: PrincipalInput) {
    GetUsers {
      users {
        createdAt
        firstName
        lastName
        lastLogin
        loginEmail
        oktaId
        oktaStatus
        accountType
        hasOtherSourcesOfAccountType
        rolesGranted {
          resourceType
          role
          roleAssignmentSources {
            assignmentType
            principalGroupId
            principalGroupName
          }
        }
      }
    }
    GetServiceAccounts {
      serviceAccounts {
        id
      }
    }
    GetGroups (PrincipalInput:$principalInput){
      groups {
        id
      }
    }
  }
`;
  return useQuery<{ users: User[]; serviceAccounts: ServiceAccount[]; groups: Group[] }>(
    [ACLReactQueryKeyEnum.getAClUsersTableDataGQL],
    async () => {
      const response = await Tecton_Graphql.request<Query>({
        document: getAClUserTableData,
        variables: {
          principalInput: {
            includeIsMember: !isAdmin,
            principalId,
          },
        },
      });
      return {
        users: response.GetUsers.users,
        serviceAccounts: response?.GetServiceAccounts.serviceAccounts,
        groups: response?.GetGroups?.groups,
      };
    }
  );
};

export const useGetACLUserDetails = ({ email, isAdmin }: { email: string; isAdmin: boolean }) => {
  const getAClUserDetails = `
  query getAClUserDetails($principalInput: PrincipalInput) {
      GetUserDetails(PrincipalInput: $principalInput) {
      userDetails {
        addNewWorkspaceRoleOptionsById
        editWorkspaceRoleOptionsById
        user {
          loginEmail
          oktaId
          createdAt
          lastLogin
          oktaStatus
        }
        accountTypeWithInheritance {
          effectiveAccountType
          assignedAccountType
          inheritedAccountTypes {
            groupName
            groupId
            accountType
          }
        }
        assignableWorkspaces {
          label
        }
        assignedWorkspacesWithRoles {
          workspaceId
          hasPriority
          role {
            effectiveWorkspaceRole
            roleSources {
              type
              role
              groupId
              groupName
            }
          }
        }
      }
    }
    GetRoles {
      roles {
        id
        name
        description
        permissions {
          description
          isAuthorized
        }
        legacyId
      }
    }
    GetGroups (PrincipalInput:$principalInput){
      assignedGroups {
        id
        name
        isMember
      }
      assignableGroups {
        id
        name
        isMember
      }
    }
  }
`;
  return useQuery<{ GetUserDetails: GetUserDetailsResponse; GetGroups: GroupResponse; GetRoles: GetRolesResponse }>(
    [ACLReactQueryKeyEnum.getAClUserDetails, email],
    async () => {
      const response = await Tecton_Graphql.request<Query>({
        document: getAClUserDetails,
        variables: {
          principalInput: {
            includeIsMember: isAdmin,
            email,
            includePrincipalDetail: true,
            principalType: PrincipalType.PRINCIPAL_TYPE_USER,
          },
        },
      });

      const { GetUserDetails, GetGroups, GetRoles } = response;
      return { GetUserDetails, GetGroups, GetRoles };
    }
  );
};

export const useGetACLServiceAccountDetails = ({
  principalId,
  oktaId,
  isAdmin,
}: {
  principalId: string;
  oktaId: string;
  isAdmin: boolean;
}) => {
  const getAClServiceAccountDetails = `
  query getACLServiceAccountDetails($principalInput: PrincipalInput) {
      GetServiceAccountDetails(PrincipalInput: $principalInput) {
      serviceAccountDetails {
        addNewWorkspaceRoleOptionsById
        editWorkspaceRoleOptionsById

        serviceAccount {
          name
          id
          description
          isActive
          createdAt
          isOwner
        }
        accountTypeWithInheritance {
          effectiveAccountType
          assignedAccountType
          inheritedAccountTypes {
            groupName
            groupId
            accountType
          }
        }
        assignableWorkspaces {
          label
        }
        assignedWorkspacesWithRoles {
          workspaceId
          hasPriority
          role {
            effectiveWorkspaceRole
            roleSources {
              type
              role
              groupId
              groupName
            }
          }
        }
      }
    }
    GetRoles {
      roles {
        id
        name
        description
        permissions {
          description
          isAuthorized
        }
        legacyId
      }
    }
    GetGroups (PrincipalInput:$principalInput){
      assignedGroups {
        id
        name
        isMember
      }
      assignableGroups {
        id
        name
        isMember
      }
    }
    GetAuthorizedResources(PrincipalInput:$principalInput) {
      authorizedResources
    }
  }
`;
  return useQuery<{
    GetServiceAccountDetails: GetServiceAccountDetailsResponse;
    GetGroups: GroupResponse;
    GetRoles: GetRolesResponse;
    GetAuthorizedResources: GetAuthorizedResourcesResponse;
  }>([ACLReactQueryKeyEnum.getAClServiceAccountDetails, principalId], async () => {
    const response = await Tecton_Graphql.request<Query>({
      document: getAClServiceAccountDetails,
      variables: {
        principalInput: {
          includeIsMember: isAdmin,
          principalId,
          oktaId,
          includePrincipalDetail: true,
          principalType: PrincipalType.PRINCIPAL_TYPE_SERVICE_ACCOUNT,
        },
      },
    });

    const { GetServiceAccountDetails, GetGroups, GetRoles, GetAuthorizedResources } = response;
    return { GetServiceAccountDetails, GetGroups, GetRoles, GetAuthorizedResources };
  });
};

export const useGetACLServiceAccountsTable = (userId: string) => {
  const getACLServiceAccountsTable = `
  query getACLServiceAccountsTable($principalInput: PrincipalInput) {
    GetUsers {
      users {
        oktaId
      }
    }
    GetServiceAccounts(PrincipalInput: $principalInput) {
      serviceAccounts {
        accountType
        id
        description
        isActive
        name
        isOwner
        createdAt
        rolesGranted {
          resourceType
          role
          roleAssignmentSources {
            assignmentType
            principalGroupId
            principalGroupName
          }
        }
      }

    }
    GetGroups (PrincipalInput:$principalInput){
      groups {
        id
      }
    }
  }
`;
  return useQuery<{ users: User[]; serviceAccounts: ServiceAccount[]; groups: Group[] }>(
    [ACLReactQueryKeyEnum.getACLServiceAccountsTableGQL, userId],
    async () => {
      const response = await Tecton_Graphql.request<Query>({
        document: getACLServiceAccountsTable,
        variables: {
          principalInput: {
            principalId: userId,
            includeAuthorizedResource: true,
            includeIsMember: false,
            includePrincipalDetail: false,
          },
        },
      });
      return {
        users: response.GetUsers.users,
        serviceAccounts: response?.GetServiceAccounts.serviceAccounts,
        groups: response?.GetGroups.groups,
      };
    }
  );
};

export const useGetACLGroupsTable = (userId: string, isAdmin: boolean) => {
  const getACLGroupsTable = `
  query getACLGroupsTable($principalInput: PrincipalInput) {
    GetUsers {
      users {
        oktaId
      }
    }
    GetServiceAccounts {
      serviceAccounts {
        id
      }
    }
    GetGroups(PrincipalInput: $principalInput){
      groups {
        id
        name
        accountType
        numMembers
        workspaceCount
        ${!isAdmin ? 'isMember' : ''}
      }
    }
  }
`;
  return useQuery<{ users: User[]; serviceAccounts: ServiceAccount[]; groups: Group[] }>(
    [ACLReactQueryKeyEnum.getACLGroupsTableGQL, userId],
    async () => {
      const response = await Tecton_Graphql.request<Query>({
        document: getACLGroupsTable,
        variables: {
          principalInput: {
            principalId: userId,
            includeIsMember: !isAdmin,
            principalType: PrincipalType.PRINCIPAL_TYPE_USER,
          },
        },
      });
      return {
        users: response.GetUsers.users,
        serviceAccounts: response?.GetServiceAccounts.serviceAccounts,
        groups: response?.GetGroups.groups,
      };
    }
  );
};

export const useGetACLGroupsDetails = (principalId: string) => {
  const getACLGroupDetails = `
    query getACLGroupsDetails($principalInput: PrincipalInput) {

    GetGroupDetails (PrincipalInput: $principalInput){
      group {
        createdAt
        accountType
        description
        id
        isMembershipEditable
        name
        numMembers
        idpMappingNames
      }

      assignedWorkspaces {
        hasPriority
        role
        workspaceId
      }
      assignableWorkspaces

      assignedUsers {
        loginEmail
        oktaId
        membershipType
      }
      assignableUsers {
        loginEmail
        oktaId

      }

      assignedServiceAccounts {
        id
        name
      }

      assignableServiceAccounts {
        id
        name
      }

      membersCount {
        user
        serviceAccount
        group
      }
    }

    GetRoles {
      roles {
        id
        name
        description
        permissions {
          description
          isAuthorized
        }
        assignableOnResourceTypes
        assignableToPrincipalTypes
        legacyId
      }
    }
}
`;
  return useQuery<{
    group: Group;
    assignedWorkspaces: AclGroupWorkspaceType[];
    assignableWorkspaces: string[];
    assignedUsers: User[];
    assignableUsers: User[];
    assignedServiceAccounts: ServiceAccount[];
    assignableServiceAccounts: ServiceAccount[];
    roles: RoleDefinition[];
    membersCount: MembersCount;
  }>([ACLReactQueryKeyEnum.getACLGroupDetailsGQL, principalId], async () => {
    const response = await Tecton_Graphql.request<Query>({
      document: getACLGroupDetails,
      variables: {
        principalInput: {
          principalId: `${principalId}`,
        },
      },
    });

    return {
      group: response?.GetGroupDetails?.group ?? '',
      assignedWorkspaces: response?.GetGroupDetails?.assignedWorkspaces,
      assignableWorkspaces: response?.GetGroupDetails?.assignableWorkspaces,
      assignedUsers: response.GetGroupDetails?.assignedUsers,
      assignableUsers: response.GetGroupDetails?.assignableUsers,
      assignedServiceAccounts: response.GetGroupDetails?.assignedServiceAccounts,
      assignableServiceAccounts: response.GetGroupDetails?.assignableServiceAccounts,
      roles: response.GetRoles.roles,
      membersCount: response?.GetGroupDetails.membersCount,
    };
  });
};

export const useGetPermissions = (workspace: string) => {
  const getPermissions = `
      query getPermissions($resourceInput: ResourceInput) {
        GetRoles {
          roles {
            id
            name
            description
            assignableOnResourceTypes
            assignableToPrincipalTypes
            permissions {
              description
              isAuthorized
            }
            legacyId
          }
      }

      GetPermissions(ResourceInput: $resourceInput) {
        users {
        loginEmail
        hasOtherSourcesOfAccountType
        workspaceRole
        oktaId
        rolesGranted {
          role
          roleDisplayName
          resourceType
          roleAssignmentSources {
            assignmentType
            principalGroupId
            principalGroupName
          }
        }
        }

        serviceAccounts {
          name
          id
          hasOtherSourcesOfAccountType
          workspaceRole
          rolesGranted {
          role
          roleDisplayName
          resourceType
          roleAssignmentSources {
            assignmentType
            principalGroupId
            principalGroupName

          }
        }
      }

      assignableUsers {
        loginEmail
        oktaId
      }
      assignableServiceAccounts {
        name
        id
      }

      assignableGroups {
        name
        id
      }

      groups {
        name
        id
        membersCount
        workspaceRole
        rolesGranted {
          roleDisplayName
          resourceType
          role
          roleAssignmentSources {
            assignmentType
            principalGroupId
            principalGroupName
          }
        }
      }
    }
  }
`;
  return useQuery<{
    users: User[];
    serviceAccounts: ServiceAccount[];
    groups: PermissionGroup[];
    roles: RoleDefinition[];
    assignableUsers: User[];
    assignableServiceAccounts: ServiceAccount[];
    assignableGroups: PermissionGroup[];
  }>([ACLReactQueryKeyEnum.getPermissions, workspace], async () => {
    const response = await Tecton_Graphql.request<Query>({
      document: getPermissions,
      variables: {
        resourceInput: {
          resourceId: `${workspace}`,
        },
      },
    });

    return {
      users: response?.GetPermissions?.users ?? [],
      serviceAccounts: response?.GetPermissions?.serviceAccounts ?? [],
      groups: response?.GetPermissions?.groups ?? [],
      roles: response.GetRoles.roles ?? [],
      assignableUsers: response?.GetPermissions?.assignableUsers ?? [],
      assignableServiceAccounts: response?.GetPermissions?.assignableServiceAccounts ?? [],
      assignableGroups: response?.GetPermissions?.assignableGroups ?? [],
    };
  });
};
