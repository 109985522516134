import React from 'react';
import { EuiSelectable, ExclusiveUnion, EuiSpacer } from '@elastic/eui';
import {
  EuiSelectableGroupLabelOption,
  EuiSelectableLIOption,
  EuiSelectableOption,
} from '@elastic/eui/src/components/selectable/selectable_option';
import styled from '@emotion/styled';
import { useTectonTheme, Checkbox, FlexGroup, FlexItem } from '@tecton/ComponentRedesign';
import { css } from '@emotion/css';

export interface CategoricalOptionInterface {
  label: string;
  checked?: string;
  value?: any;
}

interface OrderProperty {
  order?: number;
}

export type FilterOptionType = ExclusiveUnion<
  EuiSelectableGroupLabelOption<CategoricalOptionInterface & OrderProperty>,
  EuiSelectableLIOption<CategoricalOptionInterface & OrderProperty>
>;

export interface CategoricalSelectorProps {
  items: FilterOptionType[];
  setItems: (items: FilterOptionType[], e: unknown, selected: FilterOptionType) => void;
  renderOption?: (option: EuiSelectableOption<CategoricalOptionInterface>, searchValue: string) => React.ReactNode;
  single?: boolean;
  sort?: boolean;
  searchable?: boolean;
  clearAll?: () => void;
  name?: string;
  showSelectAll?: boolean;
  clearTextLabel?: string;
}

const checkedSort = (a: FilterOptionType, b: FilterOptionType) => {
  if (a.checked === 'on' && b.checked !== 'on') {
    return -1;
  } else if (a.checked !== 'on' && b.checked === 'on') {
    return 1;
  } else {
    // If both 'a' and 'b' have the same "checked" state, sort alphabetically by the "label" property
    return a.label.localeCompare(b.label);
  }
};

const StyledSelectable = styled(EuiSelectable)`
  .euiFormControlLayout {
    margin-bottom: ${({ theme }) => theme.padding.l};
  }

  .euiSelectableListItem {
    background-color: ${({ theme }) => theme.colors.emptyShade};
    color: ${({ theme }) => theme.colors.text};

    border-bottom: none;

    :hover {
      background-color: ${({ theme }) => theme.colors.body};

      span {
        color: ${({ theme }) => theme.colors.fullShade};
        text-decoration: none !important;
      }
    }
  }

  .euiSelectableListItem-isFocused {
    background-color: ${({ theme }) => theme.colors.body};
    color: ${({ theme }) => theme.colors.fullShade};
  }
`;

const PanelLayout = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;

  height: 100%;
`;

export const CategoricalSelectorCheckList = ({
  items,
  setItems,
  renderOption,
  searchable = false,
  single = false,
  sort = false,
  name,
}: CategoricalSelectorProps) => {
  const { theme } = useTectonTheme();

  const renderListItem = renderOption;

  const searchStyle = css`
    outline: none !important;
    transition: none;
    box-shadow: none !important;
    background-image: none !important;
    background-size: none !important;
    background-color: none !important;
    border: ${theme.border.thin} !important;
  `;

  const searchProps = searchable
    ? {
        searchable,
        searchProps: {
          placeholder: 'Filter list',
          compressed: true,
          className: searchStyle,
        },
      }
    : {};

  return (
    <StyledSelectable
      {...searchProps}
      aria-label="Composers"
      options={sort ? items.sort(checkedSort) : items}
      data-testid={`selector-list-${name}`}
      onChange={(newOptions, e, selected) => {
        setItems(newOptions, e, selected);
      }}
      loadingMessage="Loading filters"
      emptyMessage="No filters available"
      noMatchesMessage="No filters found"
      singleSelection={single}
      renderOption={(option, searchValue) => {
        const rendered = renderListItem ? renderListItem(option, searchValue) : option.label;

        return (
          <FlexGroup justifyContent="flexStart" alignItems="center">
            <FlexItem grow={0} style={{ pointerEvents: 'none' }}>
              <Checkbox label={false} id={''} checked={option.checked === 'on'} />
            </FlexItem>
            <FlexItem grow={0}>
              <div style={{ pointerEvents: 'none' }}>{rendered}</div>
            </FlexItem>
          </FlexGroup>
        );
      }}
      height="full"
      listProps={{ showIcons: false }} // TODO: The optimal way to handle this would be to allow the full listProps in the interface for the component. However, I'm temporarily doing this until we determine if this is ALWAYS the behavior or if it needs to be configurable on a per-selector basis
    >
      {(list, search) => (
        <PanelLayout>
          {searchable ? <>{search}</> : <EuiSpacer size="xs" />}
          {list}
        </PanelLayout>
      )}
    </StyledSelectable>
  );
};

export default CategoricalSelectorCheckList;
