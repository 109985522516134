import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Button, EmptyPrompt, FlexGroupWrapper, FlexItem, Monospace, ViewLayout } from '@tecton/ComponentRedesign';
import { WorkspacesContent } from './StyledComponent';

interface WorkspaceNotFoundProps {
  workspace: string;
}

const MessageWrapper = styled.div`
  min-width: 500px;
`;

const TitleText = styled.span`
  color: ${({ theme }) => theme.colors.title};
  font-size: ${({ theme }) => theme.font.headingSizes['4']};
  font-weight: ${({ theme }) => theme.font.weight.medium};
  line-height: ${({ theme }) => theme.font.headingLineHeights['4']};
`;

export const WorkspaceNotFound: FC<WorkspaceNotFoundProps> = ({ workspace }) => {
  const body = (
    <>
      <FlexGroupWrapper direction="column" justifyContent="center" alignItems="center" gap="m">
        <FlexItem>
          <MessageWrapper>
            There is no workspace with <TitleText>{workspace}</TitleText>. You can create it with the following CLI
            command.
          </MessageWrapper>
        </FlexItem>
        <FlexItem>
          <Monospace>$ tecton workspace create {workspace}</Monospace>
        </FlexItem>
        <FlexItem>or If you are looking for an existing workspace, you can discover it below</FlexItem>
        <FlexItem>
          <Button variant="primaryAction" label={`See Workspaces`} />
        </FlexItem>
      </FlexGroupWrapper>
    </>
  );

  return (
    <>
      <ViewLayout
        header={<></>}
        body={
          <WorkspacesContent>
            <FlexGroupWrapper direction="column" justifyContent="center" alignItems="center">
              <FlexItem>
                <EmptyPrompt variant="storm" title={'Workspace Unavailable'} body={body} />
              </FlexItem>
            </FlexGroupWrapper>
          </WorkspacesContent>
        }
      />
    </>
  );
};

export default WorkspaceNotFound;
