import styled from '@emotion/styled';
import { Suspense } from 'react';

import InitializeApplication from '../../core/initialization/InitializeApplication';
import ApplicationRoute from './ApplicationRoute';
import FeatureFlagModal from './FeatureFlagModal';

import { GlobalToast } from '@tecton';
import { ErrorBoundary } from '@tecton/ComponentRedesign';
import { TectonThemeProvider } from '@tecton/ComponentRedesign/Theme';
import { ToastContextProvider } from '@tecton/ToastContext';
import { FeatureFlags, useUserSettings } from '../context/UserSettingsContext';

const Container = styled.div`
  /* These values use to live in tecton-pages.scss which ad the background color of blue
   We had to move this down since we didn't want to have the new redesign application
  to have a blue background
  */

  // TODO: Once the redesign is fully rolled out, let's remove this.
  background: #f7f8fc;
  display: flex;
  height: 100%;
  width: 100%;
  font-family: 'Inter', BlinkMacSystemFont, Helvetica, Arial, sans-serif;
  text-size-adjust: 100%;
  font-kerning: normal;
  color: #343741;
`;

const WebUI = () => {
  const { uiTheme, featureEnabled } = useUserSettings();
  const showCustomTheme = featureEnabled(FeatureFlags.USE_UI_THEME);

  return (
    <Container>
      <TectonThemeProvider themeType={showCustomTheme ? uiTheme : 'light'}>
        <ErrorBoundary>
          <ToastContextProvider>
            <GlobalToast />
            <InitializeApplication>
              <Suspense>
                <ApplicationRoute />
              </Suspense>
              <FeatureFlagModal />
            </InitializeApplication>
          </ToastContextProvider>
        </ErrorBoundary>
      </TectonThemeProvider>
    </Container>
  );
};

export default WebUI;
