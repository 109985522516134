import { ContentBodyWrapper, ContentWrapper } from '@tecton/ComponentRedesign';
import React, { FC } from 'react';

interface ViewLayoutProps {
  header: React.ReactNode;
  body: React.ReactNode;
}

const ViewLayout: FC<ViewLayoutProps> = ({ header, body }) => {
  return (
    <ContentWrapper data-testid="view-layout">
      {header}
      <ContentBodyWrapper>{body}</ContentBodyWrapper>
    </ContentWrapper>
  );
};

export default ViewLayout;
