import React from 'react';
import { Button, ButtonEmpty, Text } from '@tecton';

import { TectonBasicModal, CalloutMessageType } from '@shared';

interface DeleteGroupModalProps {
  groupId?: string;
  isLoading: boolean;
  errorMessages: CalloutMessageType[] | undefined;
  confirmChange: () => void;
  onClose: () => void;
}

const DeleteGroupModal = ({ groupId, isLoading, errorMessages, confirmChange, onClose }: DeleteGroupModalProps) => {
  return (
    <TectonBasicModal
      title={<h4>Delete group '{groupId}'?</h4>}
      body={
        <Text>
          <p>
            Are you sure you want to delete <strong>{groupId}</strong>?
          </p>
        </Text>
      }
      actions={
        <>
          <ButtonEmpty onClick={onClose}>Cancel</ButtonEmpty>
          <Button isLoading={isLoading} onClick={confirmChange} fill color="danger">
            Delete
          </Button>
        </>
      }
      calloutMessages={errorMessages}
      onClose={onClose}
    />
  );
};

export default DeleteGroupModal;
