import React, { ChangeEvent, useContext, useState } from 'react';
import {
  EuiBasicTableColumn,
  EuiComboBoxOptionOption,
  Button,
  Highlight,
  Link,
  Panel,
  Spacer,
  LoadingContent,
  DebouncedFieldSearch,
} from '@tecton';

import { ACLTableControlLayout } from '../acl/ACLLayout';
import { TectonBasicTable } from '@shared';
import ModifyWorkspaceRoleModal from '../acl/modals/ModifyWorkspaceRoleModal';
import AddWorkspaceAccessModal from './modals/AddWorkspaceAccessModal';
import TableActionButtonWithDisableMode from '../shared/TableActionButtonWithDisableMode';
import { ACLRoleWithInheritance } from '../acl/types';
import { useNavigate, useParams } from 'react-router';
import { ConfigurationContext } from './ConfigurationContextProvider';
import { ServiceAccessContext } from './ServiceAccountAccessContextProvider';
import { ConfigurationServiceAccountModal } from './configuration-utils';
import { Routes } from '../../core/routes';
import AllRolesPopover from '../acl/AllRolesPopover';
import ColumnNameWithHelp from '../shared/ColumnNameWithHelp';
import { UserSettingsContext } from '../context/UserSettingsContext';

export type ServiceAccountPermissionRow = {
  serviceAccountId: string;
  name: string;
  role: ACLRoleWithInheritance;
};

type ServiceAccountCandidate = {
  serviceAccountId: string;
};

const renderServiceAccountOption = (
  option: EuiComboBoxOptionOption<ServiceAccountCandidate>,
  searchValue: string,
  contentClassName: string
) => {
  const { label, value } = option;
  return (
    <span
      className={contentClassName}
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
      }}
    >
      <Highlight search={searchValue}>{label}</Highlight>
      <span>{value?.serviceAccountId}</span>
    </span>
  );
};

const ServiceAccountsWithPermissions = () => {
  const navigate = useNavigate();
  const configurationContext = useContext(ConfigurationContext);
  const userSettingsContext = useContext(UserSettingsContext);
  const serviceAccessContext = useContext(ServiceAccessContext);
  const { workspace } = useParams();

  const [currentRole, setCurrentRole] = useState<string | undefined>(serviceAccessContext?.selectedRole);

  const hasWorkspaceManagerAccess = userSettingsContext?.hasWorkspaceManagerAccess;
  const isAdmin = userSettingsContext?.isAdmin ?? false;

  let addUserModal;
  if (serviceAccessContext?.modal === ConfigurationServiceAccountModal.ADD_SERVICE_ACCOUNT) {
    const saveChanges = async () => {
      serviceAccessContext?.assignServiceToWorkspace();
    };

    addUserModal = (
      <AddWorkspaceAccessModal
        workspaceId={configurationContext?.workspace ?? ''}
        allRoleRecords={configurationContext?.allServiceAccountRoleRecords ?? []}
        principalType={'Service Account'}
        principalsList={configurationContext?.serviceAccountCandidates ?? []}
        selectedPrincipal={serviceAccessContext?.selectedPrincipal}
        setSelectedPrincipal={serviceAccessContext?.setSelectedPrincipal}
        roleOptions={configurationContext?.newServiceAccountRoleOptions} //newServiceAccountRoleOptions}
        selectedRole={serviceAccessContext?.selectedRole}
        setSelectedRole={serviceAccessContext?.setSelectedRole}
        isFetching={serviceAccessContext?.isModalLoading}
        isLoading={serviceAccessContext?.isModalLoading}
        errorMessages={serviceAccessContext?.errorMessages}
        renderOption={renderServiceAccountOption}
        cancel={() => {
          serviceAccessContext?.setModal(undefined);
          serviceAccessContext?.setSelectedPrincipal([]);
          serviceAccessContext?.setSelectedRole('');
        }}
        saveChanges={saveChanges}
      />
    );
  }

  let modifyUserModal;

  if (serviceAccessContext?.modal === ConfigurationServiceAccountModal.MODIFY_ACCESS) {
    const confirmModifyRole = async () => {
      serviceAccessContext?.updateServiceAccountRole();
    };

    modifyUserModal = (
      <ModifyWorkspaceRoleModal
        workspaceId={configurationContext?.workspace ?? ''}
        allRoleRecords={configurationContext?.allServiceAccountRoleRecords ?? []}
        roleOptions={configurationContext?.existingServiceAccountOptions}
        userId={serviceAccessContext?.selectedServiceAccount?.name}
        cancel={() => {
          serviceAccessContext.setModal(undefined);
          serviceAccessContext?.setSelectedPrincipal([]);
          serviceAccessContext?.setSelectedRole('');
        }}
        confirmChanges={confirmModifyRole}
        currentRole={currentRole}
        //currentSourcesOfRoles={modifyServiceAccountModalState.payload.currentSourceOfRoles}
        selectedRole={serviceAccessContext?.selectedRole}
        setSelectedRole={serviceAccessContext?.setSelectedRole}
        isLoading={serviceAccessContext?.isModalLoading}
        errorMessages={serviceAccessContext?.errorMessages}
      />
    );
  }

  const columnsWithEditAccess: EuiBasicTableColumn<ServiceAccountPermissionRow>[] = [
    {
      field: 'name',
      name: 'Name',
      render: (field: string, row: ServiceAccountPermissionRow) => {
        const nameNode = isAdmin ? (
          <Link
            onClick={() => {
              navigate(`${Routes.accountsAndAccessServiceAccounts}/${row.serviceAccountId}/workspaces`);
            }}
          >
            {field}
          </Link>
        ) : (
          <>{field}</>
        );
        return nameNode;
      },
    },
    {
      field: 'role',
      name: (
        <ColumnNameWithHelp
          name={'Role'}
          helpContent="A service account's role can be directly assigned, inherited from a group, or set via its default workspace role. If there are multiple roles, Tecton will use the highest role as the effective role, as shown in this column."
        />
      ),
      render: (role: ACLRoleWithInheritance) => {
        return <AllRolesPopover roleWithInheritance={role} />;
      },
    },
    {
      name: 'Actions',
      actions: [
        {
          name: 'Edit',
          isPrimary: true,
          onClick: () => {},
          render: (row: ServiceAccountPermissionRow) => {
            return (
              <TableActionButtonWithDisableMode
                isAdmin={isAdmin}
                requiresAdmin
                iconType={'pencil'}
                isDisabled={false}
                enabledMessage={'Edit the role of this service account'}
                disabledMessage={
                  'Service Account is not a direct member of the group, thus their role cannot be modified here.'
                }
                onClick={() => {
                  serviceAccessContext?.setSelectedServiceAccount(row);
                  setCurrentRole(row.role.effectiveWorkspaceRole);
                  serviceAccessContext?.setSelectedRole(row.role.effectiveWorkspaceRole);
                  serviceAccessContext?.setModal(ConfigurationServiceAccountModal.MODIFY_ACCESS);
                }}
              />
            );
          },
        },
      ],
    },
  ];

  const columnsWithOutEditAccess: EuiBasicTableColumn<ServiceAccountPermissionRow>[] = [
    {
      field: 'name',
      name: 'Name',
      render: (field: string, row: ServiceAccountPermissionRow) => {
        const nameNode = isAdmin ? (
          <Link
            onClick={() => {
              navigate(`${Routes.accountsAndAccessServiceAccounts}/${row.serviceAccountId}/workspaces`);
            }}
          >
            {field}
          </Link>
        ) : (
          <>{field}</>
        );
        return nameNode;
      },
    },
    {
      field: 'role',
      name: (
        <ColumnNameWithHelp
          name={'Role'}
          helpContent="If the user doesn’t have a role on a workspace, this is the default role they will assume."
        />
      ),
      render: (role: ACLRoleWithInheritance, row: ServiceAccountPermissionRow) => {
        return role.effectiveWorkspaceRole;
      },
    },
    {
      name: 'Actions',
      actions: [
        {
          name: 'Edit',
          isPrimary: true,
          onClick: () => {},
          render: (row: ServiceAccountPermissionRow) => {
            return (
              <TableActionButtonWithDisableMode
                isAdmin={isAdmin}
                requiresAdmin
                iconType={'pencil'}
                isDisabled={!hasWorkspaceManagerAccess ?? true}
                enabledMessage={'Edit the role of this service account'}
                disabledMessage={'Insufficient permissions: must be admin or a workspace owner or to edit role.'}
                onClick={() => {
                  serviceAccessContext?.setSelectedServiceAccount(row);
                  setCurrentRole(row.role.effectiveWorkspaceRole);
                  serviceAccessContext?.setSelectedRole(row.role.effectiveWorkspaceRole);
                  serviceAccessContext?.setModal(ConfigurationServiceAccountModal.MODIFY_ACCESS);
                }}
              />
            );
          },
        },
      ],
    },
  ];

  const columns = hasWorkspaceManagerAccess ? columnsWithEditAccess : columnsWithOutEditAccess;

  const addServiceAccountButtonTitle = !hasWorkspaceManagerAccess
    ? `You must be an admin or have "owner" role to "${workspace}" workspace.`
    : '';

  const tableAndModals = (
    <>
      <TectonBasicTable
        items={configurationContext?.serviceAccounts ?? []}
        columns={columns}
        pageIndex={0}
        pageSize={0}
        totalItemCount={0}
      />
      {addUserModal}
      {modifyUserModal}
    </>
  );

  return (
    <Panel hasBorder={false} hasShadow={false} paddingSize="xl">
      <ACLTableControlLayout
        search={
          <DebouncedFieldSearch
            fullWidth
            placeholder="Search for Service Account"
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              configurationContext?.setServiceAccountSearch?.call(configurationContext, event?.target?.value);
            }}
          />
        }
        actionButtons={
          <Button
            onClick={() => {
              serviceAccessContext?.setModal(ConfigurationServiceAccountModal.ADD_SERVICE_ACCOUNT);
            }}
            fill
            disabled={!hasWorkspaceManagerAccess}
            title={addServiceAccountButtonTitle}
          >
            Add Service Account
          </Button>
        }
      />
      <Spacer size="l" />
      {configurationContext?.isLoading ? <LoadingContent /> : tableAndModals}
    </Panel>
  );
};

export default ServiceAccountsWithPermissions;
