import { Authorization_Service, Metadata_Service, Principal_Service } from '.';

import {
  AssignmentBasicV2,
  AssignRolesPutRequest,
  AssignRolesPutResponse,
  AssignRolesRequest,
  AssignRolesResponse,
  GetAuthorizedResourcesRequest,
  GetAuthorizedResourcesResponse,
  GetIsAuthorizedResponse,
  GetRolesResponse,
  ListAssignedPrincipalsResponse,
  ListAssignedRolesRequest,
  ListAssignedRolesResponse,
  UnassignRolesRequest,
  UnassignRolesResponse,
} from '../types/tecton_proto/auth/authorization_service';
import { User } from '../types/tecton_proto/data/user';
import {
  ClusterUserActionRequest,
  ClusterUserActionResponse,
  CreateClusterUserRequest,
  CreateClusterUserResponse,
  CreateServiceAccountRequest,
  CreateServiceAccountResponse,
  DeleteClusterUserRequest,
  DeleteClusterUserResponse,
  DeleteServiceAccountRequest,
  DeleteServiceAccountResponse,
  GetClusterAdminInfoResponse,
  GetServiceAccountsRequest,
  GetServiceAccountsResponse,
  UpdateServiceAccountRequest,
  UpdateServiceAccountResponse,
} from '../types/tecton_proto/metadataservice/metadata_service';
import { GetAssignedRolesResponse } from '../types/tecton_proto/auth/authorization_service';
import { transformUsers } from '../components/acl/aclUtils';
import {
  AddPrincipalGroupMembersRequest,
  AddPrincipalGroupMembersResponse,
  CreatePrincipalGroupRequest,
  CreatePrincipalGroupResponse,
  DeletePrincipalGroupsRequest,
  DeletePrincipalGroupsResponse,
  ListPrincipalGroupMembersRequest,
  ListPrincipalGroupMembersResponse,
  ListPrincipalGroupsDetailedRequest,
  ListPrincipalGroupsForPrincipalRequest,
  ListPrincipalGroupsForPrincipalResponse,
  RemovePrincipalGroupMembersRequest,
  RemovePrincipalGroupMembersResponse,
  UpdatePrincipalGroupRequest,
  UpdatePrincipalGroupResponse,
} from '../types/tecton_proto/principal/principal_service';
import { ACLGroupTableItem } from '../components/acl/types';

export const fetchAssignedPrincipals = async (payload: any): Promise<ListAssignedPrincipalsResponse> => {
  const response = await Authorization_Service('list-assigned-principals', { data: payload });
  const responseJson: ListAssignedPrincipalsResponse = response.data;
  if (responseJson.assignments === undefined) {
    responseJson.assignments = [];
  }
  return responseJson;
};

export const fetchGetIsAuthorize = async (payload: any): Promise<GetIsAuthorizedResponse> => {
  const response = await Authorization_Service('get-is-authorized', { data: payload });
  return response.data;
};

export const fetchAssignedPrincipalsUser = async (payload: any): Promise<AssignmentBasicV2[]> => {
  const response = await Authorization_Service('list-assigned-principals', { data: payload });
  const responseJson: ListAssignedPrincipalsResponse = response.data;
  if (responseJson.assignments === undefined) {
    responseJson.assignments = [];
  }
  const users = transformUsers(responseJson);
  return users as AssignmentBasicV2[];
};

export const fetchClusterAdminInfo = async () => {
  const response = await Metadata_Service('get-cluster-admin-info', {});
  const responseJson: GetClusterAdminInfoResponse = response.data;
  const { users: nonAdmins, admins } = responseJson;

  const users = nonAdmins?.map((user: User) => {
    const is_admin = admins?.map((u) => u.okta_id).includes(user.okta_id);
    return {
      ...user,
      is_admin,
    };
  });

  return users;
};

export const fetchInviteUser = async (payload: CreateClusterUserRequest): Promise<CreateClusterUserResponse> => {
  const response = await Metadata_Service.post('create-cluster-user', payload);
  return response.data;
};

export const fetchDeleteUser = async (payload: DeleteClusterUserRequest): Promise<DeleteClusterUserResponse> => {
  const response = await Metadata_Service.post('delete-cluster-user', payload);
  return response.data;
};

export const fetchClusterUserAction = async (payload: ClusterUserActionRequest): Promise<ClusterUserActionResponse> => {
  /**
   Endpoint capabilities
    - resend_activation_email
    - unlock_user
    - grant_admin
    - revoke_admin
   */
  const response = await Metadata_Service.post('cluster-user-action', payload);
  return response.data;
};

export const fetchAssignedRoles = async (payload?: ListAssignedRolesRequest): Promise<ListAssignedRolesResponse> => {
  const response = await Authorization_Service.post('list-assigned-roles', payload);
  const responseJson = response.data;

  // Necessary due to optionals in response
  if (responseJson.assignments === undefined) {
    responseJson.assignments = [];
  }

  return responseJson as GetAssignedRolesResponse;
};

export const fetchServiceAccount = async (payload?: GetServiceAccountsRequest): Promise<GetServiceAccountsResponse> => {
  const response = await Metadata_Service.post('get-service-account', payload);
  const responseJson = response.data;

  // Necessary due to optionals in response
  if (responseJson.service_accounts === undefined) {
    responseJson.service_accounts = [];
  }

  return responseJson as GetServiceAccountsResponse;
};

export const fetchUpdateServiceAccount = async (
  payload?: UpdateServiceAccountRequest
): Promise<UpdateServiceAccountResponse> => {
  const response = await Metadata_Service.post('update-service-account', payload);
  const responseJson = response.data;

  // Necessary due to optionals in response
  if (responseJson.service_accounts === undefined) {
    responseJson.service_accounts = [];
  }

  return responseJson as UpdateServiceAccountResponse;
};

export const fetchDeleteServiceAccount = async (
  payload?: DeleteServiceAccountRequest
): Promise<DeleteServiceAccountResponse> => {
  const response = await Metadata_Service.post('delete-service-account', payload);
  const responseJson = response.data;

  // Necessary due to optionals in response
  if (responseJson.service_accounts === undefined) {
    responseJson.service_accounts = [];
  }

  return responseJson as UpdateServiceAccountResponse;
};

export const fetchAddWorkspaceToServiceAccount = async (
  payload?: AssignRolesPutRequest
): Promise<AssignRolesPutResponse> => {
  const response = await Authorization_Service.put('assign-roles', payload);
  return response;
};

export const fetchAssignRole = async (payload?: AssignRolesRequest): Promise<AssignRolesResponse> => {
  const response = await Authorization_Service.post('assign-roles', payload);
  return response;
};

export const fetchUpdateAccountTypeToRegular = async (
  payload?: UnassignRolesRequest
): Promise<UnassignRolesResponse> => {
  const response = await Authorization_Service.post('unassign-roles', payload);
  return response;
};

export const fetchCreateServiceAccount = async (
  payload?: CreateServiceAccountRequest
): Promise<CreateServiceAccountResponse> => {
  const response = await Metadata_Service.post('create-service-account', payload);
  return response.data;
};

export const fetchGetGroups = async (payload?: ListPrincipalGroupsDetailedRequest): Promise<ACLGroupTableItem[]> => {
  const response = await Principal_Service.post('list-principal-groups-detailed', payload);
  return response.data;
};

export const fetchCreateGroup = async (
  payload?: CreatePrincipalGroupRequest
): Promise<CreatePrincipalGroupResponse> => {
  const response = await Principal_Service.post('create-principal-group', payload);
  return response.data;
};

export const fetchDeleteGroup = async (
  payload?: DeletePrincipalGroupsRequest
): Promise<DeletePrincipalGroupsResponse> => {
  const response = await Principal_Service.post('delete-principal-groups', payload);
  return response.data;
};

export const fetchUpdatePrincipalGroup = async (
  payload?: UpdatePrincipalGroupRequest
): Promise<UpdatePrincipalGroupResponse> => {
  const response = await Principal_Service.post('update-principal-group', payload);
  return response.data;
};

export const fetchListPrincipalGroupMembers = async (
  payload?: ListPrincipalGroupMembersRequest
): Promise<ListPrincipalGroupMembersResponse> => {
  const response = await Principal_Service.post('list-principal-group-members', payload);
  return response.data;
};

export const fetchAddPrincipalGroupMembers = async (
  payload?: AddPrincipalGroupMembersRequest
): Promise<AddPrincipalGroupMembersResponse> => {
  const response = await Principal_Service.post('add-principal-group-members', payload);
  return response.data;
};

export const fetchRemovePrincipalGroupMembers = async (
  payload?: RemovePrincipalGroupMembersRequest
): Promise<RemovePrincipalGroupMembersResponse> => {
  const response = await Principal_Service.post('remove-principal-group-members', payload);
  return response.data;
};

export const fetchGetRoles = async (): Promise<GetRolesResponse> => {
  const response = await Authorization_Service.post('get-roles');
  return response.data;
};

export const fetchListPrincipalGroupsForPrincipal = async (
  payload?: ListPrincipalGroupsForPrincipalRequest
): Promise<ListPrincipalGroupsForPrincipalResponse> => {
  const response = await Principal_Service.post('list-principal-groups-for-principal', payload);
  return response.data;
};

export const fetchGetAuthorizedResources = async (
  payload?: GetAuthorizedResourcesRequest
): Promise<GetAuthorizedResourcesResponse> => {
  const response = await Authorization_Service.post('get-authorized-resources', payload);
  return response.data;
};
