import IdUtils from '../utils/id-utils';
import WorkspaceUtils from '../utils/workspace-utils';
import { FirstClassObjectTypes } from '../components/FirstClassObject';
import _ from 'lodash';
import { dataTypeToString } from '../utils/feature-view/feature-view-util';

export default class VirtualDataSource {
  private proto: any = null;

  constructor(proto: any) {
    this.proto = proto;
  }

  get _proto() {
    return this.proto;
  }

  get fcoType() {
    return FirstClassObjectTypes.DATA_SOURCE;
  }

  get id() {
    return IdUtils.toStringId(this._proto.virtual_data_source_id);
  }

  get name() {
    return this._proto.fco_metadata.name;
  }

  get description() {
    return this._proto.fco_metadata.description;
  }

  get tags() {
    return this._proto.fco_metadata.tags || {};
  }

  get createdAt() {
    return new Date(this._proto.fco_metadata.created_at);
  }

  get owner() {
    return this._proto.fco_metadata.owner;
  }

  get lastModifiedBy() {
    return this._proto.fco_metadata.last_modified_by;
  }

  get workspace() {
    return this._proto.fco_metadata.workspace || WorkspaceUtils.getDefaultWorkspace();
  }

  get sourceFileName() {
    return this._proto.fco_metadata.source_filename;
  }

  get type() {
    if (this._proto.data_source_type === 'STREAM_WITH_BATCH') {
      return 'Stream';
    } else if (this._proto.data_source_type === 'PUSH_NO_BATCH' || this._proto.data_source_type === 'PUSH_WITH_BATCH') {
      return 'Push';
    } else {
      return 'Batch';
    }
  }

  get batchProperties() {
    if (!this._batchDataSource) {
      return [];
    }
    if (this._batchDataSource.hive_table) {
      return [
        {
          name: 'database',
          value: this._batchDataSource.hive_table.database,
        },
        {
          name: 'table',
          value: this._batchDataSource.hive_table.table,
        },
      ];
    } else if (this._batchDataSource.file) {
      return [
        {
          name: 'uri',
          value: this._batchDataSource.file.uri,
        },
      ];
    } else if (this._batchDataSource.redshift_db) {
      return [
        {
          name: 'database',
          value: this._batchDataSource.redshift_db.database,
        },
        {
          name: 'table',
          value: this._batchDataSource.redshift_db.table,
        },
      ];
    } else if (this._batchDataSource.snowflake) {
      return [
        {
          name: 'database',
          value: this._batchDataSource.snowflake.snowflakeArgs.database,
        },
        {
          name: 'schema',
          value: this._batchDataSource.snowflake.snowflakeArgs.schema,
        },
        {
          name: 'table',
          value: this._batchDataSource.snowflake.snowflakeArgs.table,
        },
        {
          name: 'warehouse',
          value: this._batchDataSource.snowflake.snowflakeArgs.warehouse,
        },
      ];
    } else if (this._batchDataSource.spark_data_source_function) {
      return [
        {
          name: 'type',
          value: 'Data Source Function',
        },
      ];
    } else {
      return [
        {
          name: 'type',
          value: 'Unknown',
        },
      ];
    }
  }

  get batchFullyQualifiedName() {
    return _.map(this.batchProperties, 'value').join('.');
  }

  get streamProperties() {
    if (!this._streamDataSource) {
      return [];
    }
    if (this._streamDataSource.kinesis_data_source) {
      return [
        {
          name: 'streamName',
          value: this._streamDataSource.kinesis_data_source.stream_name,
        },
      ];
    } else if (this._streamDataSource.kafka_data_source) {
      return [
        {
          name: 'topics',
          value: this._streamDataSource.kafka_data_source.topics,
        },
      ];
    } else if (this._streamDataSource.spark_data_source_function) {
      return [
        {
          name: 'type',
          value: 'Data Source Function',
        },
      ];
    } else {
      return [
        {
          name: 'type',
          value: 'Unknown',
        },
      ];
    }
  }

  get rawBatchTranslator() {
    return this._batchDataSource ? this._batchDataSource.raw_batch_translator : '';
  }

  get rawStreamTranslator() {
    return this._streamDataSource ? this._streamDataSource.raw_stream_translator : '';
  }

  get batchDataSourceFunction() {
    return this._batchDataSource?.spark_data_source_function
      ? this._batchDataSource.spark_data_source_function.function
      : '';
  }

  get streamDataSourceFunction() {
    return this._streamDataSource?.spark_data_source_function
      ? this._streamDataSource.spark_data_source_function.function
      : '';
  }

  get streamFullyQualifiedName() {
    return _.map(this.streamProperties, 'value').join('.');
  }

  get columns() {
    // Since the stream ds has a subset of columns, return stream's columns if present
    const schema = this._streamDataSource ? this._streamDataSource.spark_schema : this._batchDataSource.spark_schema;
    let columns: any = [];
    if (!schema || Object.keys(schema).length == 0) {
      return columns;
    }
    columns = schema.fields.map((fld: any) => {
      const fldSchema = JSON.parse(fld.structfield_json);
      let type = '-';
      if (typeof fldSchema['type'] === 'string') {
        // simple atomic types (string, long, float) have a schema json similar to
        // {
        //    'name': 'atomic_field',
        //    'type': 'long'
        //  }
        type = fldSchema['type'];
      } else if (typeof fldSchema['type'] === 'object' && typeof fldSchema['type']['type'] === 'string') {
        // complex types (array, struct, map) have a schema json similar to
        // {
        //    'name': 'complex_field',
        //    'type': {'type': 'array', ...}
        //  }
        type = fldSchema['type']['type'];
      }

      return {
        name: fld.name,
        type: type,
      };
    });

    return columns;
  }

  get tectonSchemaColumns() {
    let column: any = [];
    const schemaColumns = this?._proto?.schema?.tecton_schema?.columns;

    if (this.type.toUpperCase().includes('PUSH') && Array.isArray(schemaColumns)) {
      column = schemaColumns.map((field: any) => {
        return {
          name: field.name,
          type: dataTypeToString(field?.offline_data_type)?.toLowerCase(),
        };
      });
    }

    return column;
  }

  get _batchDataSource() {
    return this._proto.batch_data_source;
  }

  get fileFormat() {
    const file = _.get(this, '_batchDataSource.file');
    let format = '';

    if (file) {
      if ('FILE_DATA_SOURCE_FORMAT_JSON' === file.format) {
        format = 'JSON';
      } else if ('FILE_DATA_SOURCE_FORMAT_PARQUET' === file.format) {
        format = 'Parquet';
      } else if ('FILE_DATA_SOURCE_FORMAT_CSV' === file.format) {
        format = 'CSV';
      }
    }

    return format;
  }

  get _streamDataSource() {
    return this._proto.stream_data_source;
  }

  get underlyingBatchDatasourceType() {
    let dataSourceType = 's3';
    if (this._batchDataSource.snowflake) {
      dataSourceType = 'snowflake';
    } else if (this._batchDataSource.redshift_db) {
      dataSourceType = 'redshift';
    } else if (this._batchDataSource.file) {
      dataSourceType = 'file';
    } else if (this._batchDataSource.hive_table) {
      dataSourceType = 'hive';
    } else if (this._batchDataSource.spark_data_source_function) {
      dataSourceType = 'data source function';
    }

    return dataSourceType;
  }

  get underlyingStreamDatasourceType() {
    let dataSourceType = 's3';

    if (this._streamDataSource.kinesis_data_source) {
      dataSourceType = 'kinesis';
    } else if (this._streamDataSource.kafka_data_source) {
      dataSourceType = 'kafka';
    } else if (this._streamDataSource.spark_data_source_function) {
      dataSourceType = 'data source function';
    }

    return dataSourceType;
  }
}
