import React, { ReactNode, createContext, useContext, useState } from 'react';
import { ConfigurationUserModal } from './configuration-utils';

import { EuiComboBoxOptionOption } from '../@tecton';
import { useAssignRolesMutation, useGetRoles } from '../../api/acl';
import { CalloutMessageType } from '@shared';
import { ToastContext, addToast } from '../@tecton/ToastContext';
import { logEvent } from '../../utils/analytics-utils';
import { useParams } from 'react-router';
import { PrincipalType } from '../../types/tecton_proto/auth/principal';
import { ResourceType } from '../../types/tecton_proto/auth/resource';
import { ConfigurationContext } from './ConfigurationContextProvider';
import { ServiceAccount } from '../../types/tecton_proto/metadataservice/metadata_service';
import { ServiceAccountPermissionRow } from './ServiceAccountsWithAccess';
import { getRoles } from '../acl/util';
import { getLegacyRoleId } from '../acl/aclUtils';

export const ServiceAccessContext = createContext<any>(null);

const ServiceAccountAccessContextProvider = ({ children }: { children: ReactNode }) => {
  const toastContext = useContext(ToastContext);
  const configurationContext = useContext(ConfigurationContext);
  const { workspace } = useParams();
  const updateWorkspaceToServiceAccountMutation = useAssignRolesMutation();
  const { data: rolesData } = useGetRoles();

  const [selectedRole, setSelectedRole] = useState<string | ''>('');
  const [selectedPrincipal, setSelectedPrincipal] = useState<EuiComboBoxOptionOption<string>[]>([]);
  const [selectedServiceAccount, setSelectedServiceAccount] = useState<ServiceAccountPermissionRow | undefined>();
  const [modal, setModal] = useState<ConfigurationUserModal | undefined>();
  const [modalError, setModalError] = useState<CalloutMessageType[] | undefined>();

  const serviceAccountRoles = getRoles(rolesData?.roles, PrincipalType.PRINCIPAL_TYPE_SERVICE_ACCOUNT);
  const serviceAccountRoleOptions = serviceAccountRoles.map((saRole) => {
    return saRole.id;
  });

  const onError = (error: any) => {
    const errorMessage = error?.response?.data?.message ?? error?.response?.statusText;

    setModalError([
      {
        title: String(errorMessage),
        color: 'danger',
        iconType: 'alert',
      },
    ]);
  };

  const role = getLegacyRoleId(configurationContext?.serviceAccountRoles ?? [], selectedRole);
  const removingRole = role === 'none';
  const roles = removingRole ? [] : [role];

  const assignServiceToWorkspace = () => {
    const serviceAccount = selectedPrincipal[0]?.label;
    const resource_id = workspace;
    const principal_type = PrincipalType.PRINCIPAL_TYPE_SERVICE_ACCOUNT;
    const resource_type = ResourceType.RESOURCE_TYPE_WORKSPACE;
    const allServiceAccountData = configurationContext?.getAllServiceAccountData?.service_accounts ?? [];

    const selectedServiceAccount = allServiceAccountData?.find((sa: ServiceAccount) => {
      return sa?.name === serviceAccount;
    });

    const principal_id = selectedServiceAccount?.id;

    const payload = {
      principal_id,
      roles,
      resource_id: workspace,
      principal_type,
      resource_type,
    };

    const title = 'Service Account Added to Workspace';
    const text = `Service Account ${serviceAccount} added to workspace ${workspace}  as ${roles.join('')} role.`;

    updateWorkspaceToServiceAccountMutation.mutate(payload, {
      onSuccess: () => {
        // Clean up default values

        setSelectedRole('');

        setModalError(undefined);
        setModal(undefined);

        // Send toaster to the service account
        toastContext?.dispatchToast(
          addToast({
            title,
            text,
            color: 'success',
            iconType: 'lock',
          })
        );

        logEvent('Service Account to Workspace', '', {
          principal_id,
          resource_id,
          principal_type,
          resource_type,
        });
      },
      onError,
    });
  };

  const updateServiceAccountRole = () => {
    const resource_id = workspace;
    const principal_type = PrincipalType.PRINCIPAL_TYPE_SERVICE_ACCOUNT;
    const resource_type = ResourceType.RESOURCE_TYPE_WORKSPACE;

    const principal_id = selectedServiceAccount?.serviceAccountId;

    const payload = {
      principal_id,
      roles,
      resource_id: workspace,
      principal_type,
      resource_type,
    };

    const title = 'Service Account Role Updated';
    const text = `Service Account ${selectedServiceAccount?.name} role updated to ${selectedRole} in workspace ${workspace}.`;

    updateWorkspaceToServiceAccountMutation.mutate(payload, {
      onSuccess: () => {
        // Clean up default values

        setSelectedRole('');
        setModalError(undefined);
        setModal(undefined);
        setSelectedPrincipal([]);

        // Send toaster to the user
        toastContext?.dispatchToast(
          addToast({
            title,
            text,
            color: 'success',
            iconType: 'lock',
          })
        );

        logEvent('Service Account Role Updated', '', {
          principal_id,
          resource_id,
          principal_type,
          resource_type,
        });
      },
      onError,
    });
  };

  const isModalLoading = updateWorkspaceToServiceAccountMutation.isLoading;

  return (
    <ServiceAccessContext.Provider
      value={{
        modal,
        setModal,
        selectedPrincipal,
        setSelectedPrincipal,
        selectedRole,
        setSelectedRole,
        assignServiceToWorkspace,
        updateServiceAccountRole,
        isModalLoading,
        modalError,
        setModalError,
        selectedServiceAccount,
        setSelectedServiceAccount,
        serviceAccountRoleOptions,
      }}
    >
      {children}
    </ServiceAccessContext.Provider>
  );
};

export default ServiceAccountAccessContextProvider;
