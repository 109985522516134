export default () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.83333 5V3.33333C5.83333 2.87333 5.46 2.5 5 2.5H3.33333C2.87333 2.5 2.5 2.87333 2.5 3.33333V5C2.5 5.46 2.87333 5.83333 3.33333 5.83333H5C5.46 5.83333 5.83333 5.46 5.83333 5Z"
        stroke="#525866"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.5385 11.6666V10.1283C6.5385 9.66826 6.16516 9.29492 5.70516 9.29492H4.16683C3.70683 9.29492 3.3335 9.66826 3.3335 10.1283V11.6666C3.3335 12.1266 3.70683 12.4999 4.16683 12.4999H5.70516C6.16516 12.4999 6.5385 12.1266 6.5385 11.6666Z"
        stroke="#525866"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.6668 7.5V5.83333C11.6668 5.37333 11.2935 5 10.8335 5H9.16683C8.70683 5 8.3335 5.37333 8.3335 5.83333V7.5C8.3335 7.96 8.70683 8.33333 9.16683 8.33333H10.8335C11.2935 8.33333 11.6668 7.96 11.6668 7.5Z"
        stroke="#525866"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.8333 14.1667V11.6667C10.8333 11.2067 11.2067 10.8333 11.6667 10.8333H14.1667V8.33333C14.1667 7.87333 14.54 7.5 15 7.5H16.6667C17.1267 7.5 17.5 7.87333 17.5 8.33333V16.6667C17.5 17.1267 17.1267 17.5 16.6667 17.5H8.33333C7.87333 17.5 7.5 17.1267 7.5 16.6667V15C7.5 14.54 7.87333 14.1667 8.33333 14.1667H10.8333Z"
        stroke="#525866"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
