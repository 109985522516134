import React, { FC } from 'react';

interface DataSourceIconProps {
  newVersion?: boolean;
}

const DataSourceIcon: FC<DataSourceIconProps> = ({ newVersion }) => {
  return (
    <g>
      <polyline points={'12,-0.5 24,6 12,25 0,6'} fill="#4096C6" opacity="0.5" />
      <polyline points={'12,3 20,7.5 12,19 4,7.5'} fill="#4096C6" opacity="0.6" />
      <polyline points={'12,3 20,7.5 12,19 12,12.5 4,7.5'} fill="#4096C6" opacity="1" />
      <polyline points={'12,6 14.5,7.5 12,9 9.5,7.5'} fill="#ffffff" opacity="1" />
    </g>
  );
};

export default DataSourceIcon;
