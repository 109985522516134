import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Button } from '@tecton/ComponentRedesign';
import Icons, { IconTypes } from './Icons/Icon';

interface TectonColumnsButtonProps {
  isActive?: boolean;
  isDisabled?: boolean;
  onClick?: () => void;
}

const ButtonLabel = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.padding.s};
  align-items: center;

  &:active,
  &:hover {
    transform: translate-y(-10px);
    -webkit-transform: translate-y(-10px);
  }
`;

const TectonColumnsButton: FC<TectonColumnsButtonProps> = ({ onClick, isDisabled }) => {
  const labelContent = (
    <ButtonLabel>
      {Icons[IconTypes.COLUMNS]}

      <div>Columns</div>
      {Icons[IconTypes.CHEVRON_DOWN]}
    </ButtonLabel>
  );

  return (
    <Button
      variant={isDisabled === true ? 'disabledAction' : 'emptyAction'}
      label={labelContent}
      onClick={onClick}
      data-testid="column-selector-button"
    />
  );
};

export default TectonColumnsButton;
