import { FC } from 'react';
import { Badge, BadgeVariants } from '@tecton/ComponentRedesign';

interface AccountStatusBadgeProps {
  status: string;
}

const AccountStatusBadge: FC<AccountStatusBadgeProps> = ({ status }) => {
  const statusLabelAndVariant: Record<string, { label: string; variant: BadgeVariants }> = {
    ACTIVE: { label: 'Active', variant: 'success' },
    PROVISIONED: { label: 'Provisioned', variant: 'primary' },
    DEPROVISIONED: { label: 'Deprovisioned', variant: 'accent' },
    INACTIVE: { label: 'Inactive', variant: 'default' },
    STAGED: { label: 'Staged', variant: 'accent' },
    RECOVERY: { label: 'Recovery', variant: 'warning' },
    LOCKED_OUT: { label: 'Locked Out', variant: 'danger' },
    UNKNOWN: { label: 'Unknown', variant: 'default' },
  };

  return <Badge {...statusLabelAndVariant[status]} />;
};

export default AccountStatusBadge;
