export default () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.7505 9.23828L4.74805 15.2408"
        stroke="#525866"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.49951 9.23828H10.7504V11.4892"
        stroke="#525866"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.99895 16.863C7.91849 17.2648 8.93225 17.4916 10.0002 17.4916C14.1444 17.4916 17.5033 14.1327 17.5033 9.98848C17.5033 5.84425 14.1444 2.48535 10.0002 2.48535C5.85597 2.48535 2.49707 5.84425 2.49707 9.98848C2.49707 11.0673 2.72883 12.0902 3.139 13.0172"
        stroke="#525866"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.24961 14.4234C9.49388 14.4659 9.74398 14.4901 9.99992 14.4901C12.486 14.4901 14.5018 12.4742 14.5018 9.9882C14.5018 7.50217 12.486 5.48633 9.99992 5.48633C7.51389 5.48633 5.49805 7.50217 5.49805 9.9882C5.49805 10.2441 5.52222 10.4942 5.56474 10.7385"
        stroke="#525866"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
