import React from 'react';
import '@elastic/eui/dist/eui_theme_light.css';
import { FeatureFlags, useUserSettings } from './components/context/UserSettingsContext';
import WebUI from './WebUI';
import { default as WebUIRedesign } from './components/redesign/WebUI';

/**
 * This file is need to do the fork for the redesign since You can't get userSettingContext in the same file you are declaring <UserSettingsContextProvider>
 */

const AppWrapper = () => {
  const { featureEnabled } = useUserSettings();
  const isUIRedesignEnabled = featureEnabled(FeatureFlags.UI_REDESIGN);
  return <>{isUIRedesignEnabled ? <WebUIRedesign /> : <WebUI />}</>;
};

export default AppWrapper;
