/* eslint-disable */

export const protobufPackage = "tecton_proto.data";

/** Guided Onboarding Status protos */
export enum OnboardingStatusEnum {
  ONBOARDING_STATUS_UNSPECIFIED = "ONBOARDING_STATUS_UNSPECIFIED",
  ONBOARDING_STATUS_INCOMPLETE = "ONBOARDING_STATUS_INCOMPLETE",
  ONBOARDING_STATUS_COMPLETED = "ONBOARDING_STATUS_COMPLETED",
}

/** Data Platform Onboarding Status protos */
export enum DataPlatformSetupTaskStatusEnum {
  /** TASK_STATUS_UNKNOWN - protolint:disable:this ENUM_FIELD_NAMES_ZERO_VALUE_END_WITH */
  TASK_STATUS_UNKNOWN = "TASK_STATUS_UNKNOWN",
  TASK_STATUS_NOT_STARTED = "TASK_STATUS_NOT_STARTED",
  TASK_STATUS_RUNNING = "TASK_STATUS_RUNNING",
  TASK_STATUS_SUCCEEDED = "TASK_STATUS_SUCCEEDED",
  TASK_STATUS_FAILED = "TASK_STATUS_FAILED",
}

export interface OnboardingStatus {
  user_id?: string | undefined;
  status?: OnboardingStatusEnum | undefined;
}

export interface DataPlatformSetupTaskStatus {
  task_display_name?: string | undefined;
  task_status?: DataPlatformSetupTaskStatusEnum | undefined;
  error_message?: string | undefined;
  details?: string | undefined;
}
