import React, { FC } from 'react';
import { useMatch, useNavigate, useParams } from 'react-router';
import { Routes } from '../../../core/routes';
import { useUserSettings } from '../../context/UserSettingsContext';

import NoAccessToWorkspace from './NoAccessToWorkspace';
import SelectAWorkspace from './SelectAWorkspace';
import WorkspaceNotFound from './WorkspaceNotFound';
import NoWorkspaceAssigned from './NoWorkspaceAssigned';
import { useGetOnboardingStatus } from '../../../api/onboarding';
import { OnboardingStatusEnum } from '../../../types/tecton_proto/data/onboarding';

const WorkspacesViewSelector: FC = () => {
  // We can't do this in  useUserSettings() since the provider is set at the very high level. 'workspace' will only be set via useParams via workspace routed URLs.
  const { workspace: workspaceParams } = useParams();
  const navigate = useNavigate();

  const urlStartsWithACLPage = !!useMatch({ path: Routes.accountAndAccess, end: false });
  const { isAdmin, allWorkspaces, workspace, hasNoWorkspaceAssigned, hasAccessToProdWorkspace, updateWorkspace } =
    useUserSettings();
  const workspaces = allWorkspaces ?? [];
  const workspaceToTry = workspace ?? workspaceParams ?? undefined;
  const { data: onboardingStatus } = useGetOnboardingStatus(workspaceToTry ?? 'prod');
  const containsWorkspaces = workspaces.length > 0;

  const clusterNeedsToBeConfigured =
    !urlStartsWithACLPage &&
    !(onboardingStatus?.finish_onboarding === OnboardingStatusEnum.ONBOARDING_STATUS_COMPLETED);

  const foundWorkspaceAccess = workspaces?.find((ws) => {
    return ws.name === workspaceToTry;
  });

  if (clusterNeedsToBeConfigured) {
    // The cluster is not yet setup so force the user to configure the cluster.
    // Non-Admins can only see the configuration.
    //return <Navigate to={Routes.dataPlatformConfiguration} />;
    navigate(Routes.dataPlatformConfiguration);
  }

  if (hasNoWorkspaceAssigned) {
    return <NoWorkspaceAssigned />;
  }

  if (hasAccessToProdWorkspace) {
    // they have access to workspace, so let's send them there by default.
    updateWorkspace('prod');
    navigate(Routes.defaultProdHome);
  }

  if (
    workspace !== undefined &&
    foundWorkspaceAccess?.isAccessible === false &&
    containsWorkspaces &&
    !urlStartsWithACLPage
  ) {
    return <NoAccessToWorkspace workspace={workspaceToTry as string} isAdmin={isAdmin ?? false} />;
  }

  if (!hasNoWorkspaceAssigned && workspaceToTry === undefined && containsWorkspaces) {
    return <SelectAWorkspace />;
  }

  if (foundWorkspaceAccess === undefined) {
    return <WorkspaceNotFound workspace={workspaceToTry ?? ''} />;
  }

  return (
    <>
      <WorkspaceNotFound workspace={workspaceToTry ?? ''} />
    </>
  );
};

export default WorkspacesViewSelector;
