import React, { FC, createContext, useMemo, useState } from 'react';
import {
  FilterPropsOptions,
  FilterableSortableTableContextProps,
  FilterableTableColumn,
  FilterableTableSort,
  TableControlsStateProps,
} from './types';

import { group } from 'd3-array';
import {
  applyFilterToAllListItems,
  applySortToItems,
  generateActiveCategoriesMaps,
  generateInitialFilterStateFromColumns,
  reduceNumberOfActiveFilters,
} from './utils';
import { addCountsFilterPseudoColumnsToColumnsArray, defineFilterTypes } from './filterComponents';
import { AnyFCO } from '../../../../core/types/fcoTypes';
import { useColumnsDisplayControlsState, ColumnsOptionType } from '../../../shared/ColumnDisplayControls';
import { FilterSelector } from './ComboFilterDropdown';

interface FCOListContextProviderProps {
  children: React.ReactNode;
  columns: FilterableTableColumn[];
  items: AnyFCO[];
  name: string;
  searchQueryKey: string;
  filtersChangedCallback?: (filters: Record<string, FilterPropsOptions>) => void;
}

const initialSetIsColumnControlsOpen: React.Dispatch<React.SetStateAction<boolean>> = () => {
  return;
};
const initialControlsState: TableControlsStateProps = {
  isColumnControlsOpen: false,
  setIsColumnControlsOpen: initialSetIsColumnControlsOpen,
  columnOptions: [],
  setColumnOptions: () => {
    return;
  },
  numberOfSelectedColumnsOptions: 0,
  activeColumns: [],
};

const initialFilterTypes: Record<string, FilterSelector> = {};
const initialFilterState: Record<string, FilterPropsOptions> = {};
const initialSetFilterState: React.Dispatch<React.SetStateAction<Record<string, FilterPropsOptions>>> = () => {
  return;
};

const defaultValue: FilterableSortableTableContextProps = {
  controlsState: initialControlsState,
  filterTypes: initialFilterTypes,
  filteredItems: [],
  filterState: initialFilterState,
  setFilterState: initialSetFilterState,
  numberOfActiveFilters: 0,
  searchQueryKey: '',
  sortParams: {
    key: undefined,
    order: undefined,
    type: undefined,
  },
  setSortParams: () => {
    return;
  },
  resetAllFilters: () => {
    return;
  },
  resetActiveFilter: () => {
    return;
  },
};

export const FCOListContext = createContext<FilterableSortableTableContextProps>(defaultValue);

const FCOListContextProvider: FC<FCOListContextProviderProps> = ({
  children,
  columns,
  items,
  name,
  searchQueryKey,
  filtersChangedCallback,
}) => {
  // Ignore columns that must be visible (e.g. Name)
  const selectableColumns = useMemo(() => {
    return columns
      .filter((column) => column.isAlwaysActive !== true)
      .map((column) => {
        return {
          label: column.label,
          columnKey: column.key,
          checked: column.isHiddenByDefault ? undefined : 'on',
        };
      });
  }, [columns]);

  const [sortParams, setSortParams] = useState<FilterableTableSort>({
    key: undefined,
    order: undefined,
    type: undefined,
  });

  // Cache the full list of items once the data are available from the API
  const allItems = useMemo(() => {
    return items;
  }, [items]);

  const controlsState = useColumnsDisplayControlsState(
    `fco-list-columns-settings-${name}`,
    selectableColumns as ColumnsOptionType[]
  );

  // Include mock columns that correspond to the Numeric filters for FCOs
  const columnsWitPseudoNumberColumns = addCountsFilterPseudoColumnsToColumnsArray(columns);

  // Filter State
  const initialFilterState = generateInitialFilterStateFromColumns(columns);
  const [filterState, setFilterState] = useState(initialFilterState);
  const resetAllFilters = () => {
    setFilterState(initialFilterState);
  };
  const filterTypes: Record<string, FilterSelector> = defineFilterTypes(
    columnsWitPseudoNumberColumns,
    filterState,
    setFilterState
  );

  const resetActiveFilter = (key: string) => {
    const initialFilterState = generateInitialFilterStateFromColumns(columns);
    setFilterState({
      ...filterState,
      [key]: initialFilterState[key],
    });
  };

  // Group the columns by type because they will need to be handled with different logic
  const columnsByType = group(columnsWitPseudoNumberColumns, (value) => value.filterType);

  // Restructure the active filter values by type in order to run tests
  const activeFilterValuesByType = generateActiveCategoriesMaps(columnsByType, filterState);
  const numberOfActiveFilters = reduceNumberOfActiveFilters(activeFilterValuesByType, columnsByType);

  if (filtersChangedCallback) {
    filtersChangedCallback(filterState);
  }

  // Actually filter the items in the table based on the list
  const sortedItems = applySortToItems(allItems, sortParams);
  const filteredItems = applyFilterToAllListItems(sortedItems, filterState, activeFilterValuesByType, searchQueryKey);

  return (
    <FCOListContext.Provider
      value={{
        filterTypes,
        filteredItems,
        controlsState,
        filterState,
        setFilterState,
        numberOfActiveFilters,
        resetAllFilters,
        resetActiveFilter,
        sortParams,
        setSortParams,
        searchQueryKey,
      }}
    >
      {children}
    </FCOListContext.Provider>
  );
};

export default FCOListContextProvider;
