import React from 'react';
import ReactDiffViewer from 'react-diff-viewer';
import { Link, Monospace, Panel, Spacer, Title, useTectonTheme } from '@tecton';
import { css } from '@emotion/css';
import useItemExpansionState from './useItemExpansionState';
import Ansi from 'ansi-to-react';
import FlyoutInnerScrollLayout from '../../components/shared/FlyoutInnerScrollLayout';
import { TectonBasicTable } from '../../components/@shared';
import { FcoPropertyDiff } from '../../types/tecton_proto/data/state_update';
import { FcoPropertyRenderingType } from '../../types/tecton_proto/args/diff_options';

const ChangeType = ({ row }: { row: FcoPropertyDiff }) => {
  const { theme } = useTectonTheme();

  const textIconStyle = `
  margin-top: 0.3em;
  width: 1.05em;
  height: 1em;
  text-align: center;
  border-radius: 2px;
  line-height: 0.9;
  font-weight: bold;
  color: white;
  margin-right: 0.3em;
  `;

  const additionCss = css`
    background-color: ${theme.colors.success};
    ${textIconStyle}
  `;

  const deletionCss = css`
    background-color: ${theme.colors.danger};
    ${textIconStyle}
  `;

  const changeCss = css`
    background-color: ${theme.colors.warning};
    ${textIconStyle}
  `;

  if (row.val_declared && row.val_existing) {
    return <span className={changeCss}>~</span>;
  } else {
    if (row.val_existing) {
      return <span className={deletionCss}>&minus;</span>;
    } else {
      return <span className={additionCss}>+</span>;
    }
  }
};

const ChangedAttributeFlyoutContent = ({ fcoName, diffs }: { fcoName?: string; diffs: FcoPropertyDiff[] }) => {
  const { theme } = useTectonTheme();

  const { itemIdToExpandedRowMap, expandItemWithContent, collapseItem } = useItemExpansionState();

  const tableItems = diffs.filter((row) => {
    return (
      row.rendering_type !== FcoPropertyRenderingType.FCO_PROPERTY_RENDERING_TYPE_HIDDEN &&
      row.rendering_type !== FcoPropertyRenderingType.FCO_PROPERTY_RENDERING_TYPE_ONLY_DECLARED
    );
  });

  const schemaChanges = diffs.filter((row) => {
    return row.rendering_type === FcoPropertyRenderingType.FCO_PROPERTY_RENDERING_TYPE_ONLY_DECLARED;
  });

  return (
    <FlyoutInnerScrollLayout
      title={
        <Title size="s">
          <h3>Changed Properties</h3>
        </Title>
      }
    >
      {schemaChanges.map((row, i) => {
        return (
          <div key={`schema-change-${i}`} style={{ marginBottom: theme.size.xl }}>
            <p>
              Schema Changes on <strong>{fcoName}</strong>
            </p>
            <Spacer size="s" />
            <Panel hasShadow={false} hasBorder paddingSize="none">
              <pre>
                <Ansi>{row.val_declared}</Ansi>
              </pre>
            </Panel>
          </div>
        );
      })}

      <p>
        {tableItems.length} propert{tableItems.length > 1 ? 'ies' : 'y'} changed on <strong>{fcoName}</strong>.
      </p>
      <Spacer size="s" />
      <TectonBasicTable
        itemId="property_name"
        itemIdToExpandedRowMap={itemIdToExpandedRowMap}
        items={tableItems}
        columns={[
          {
            name: 'Property',
            field: 'property_name',
            render: (field: string, row: FcoPropertyDiff) => {
              return (
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '1em 1fr',
                    gap: '0.5em',
                  }}
                >
                  <ChangeType row={row} />
                  <span>{row.property_name}</span>
                </div>
              );
            },
          },
          {
            name: 'From',
            field: 'val_existing',
            render: (field: string, row: FcoPropertyDiff) => {
              if (row.rendering_type === FcoPropertyRenderingType.FCO_PROPERTY_RENDERING_TYPE_PYTHON) {
                return <></>;
              }

              return field;
            },
          },
          {
            name: 'To',
            field: 'val_declared',
            render: (field: string, row: FcoPropertyDiff) => {
              if (row.rendering_type === FcoPropertyRenderingType.FCO_PROPERTY_RENDERING_TYPE_PYTHON) {
                const rowIsOpen = !!itemIdToExpandedRowMap[row?.property_name ?? ''];

                const callback = !rowIsOpen
                  ? () => {
                      expandItemWithContent(
                        row?.property_name ?? '',
                        <div
                          className={css`
                            pre span {
                              padding: 0;
                            }
                          `}
                        >
                          <span style={{ fontSize: 12 }}>
                            <Monospace>
                              <ReactDiffViewer
                                oldValue={row.val_existing}
                                newValue={row.val_declared}
                                splitView={true}
                              />
                            </Monospace>
                          </span>
                        </div>
                      );
                    }
                  : () => {
                      collapseItem(row?.property_name ?? '');
                    };

                return <Link onClick={callback}>{rowIsOpen ? 'Close' : 'Open'} Diff Viewer</Link>;
              }

              return field;
            },
          },
        ]}
        pageIndex={0}
        pageSize={0}
        totalItemCount={0}
      />
    </FlyoutInnerScrollLayout>
  );
};

export default ChangedAttributeFlyoutContent;
