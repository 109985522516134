import { utcFormat } from 'd3-time-format';
import React, { FC } from 'react';
import EmptyValue from './EmptyValue';

interface DateDisplayProps {
  date: Date | undefined;
  showTime?: boolean;
}

const DateDisplay: FC<DateDisplayProps> = ({ date, showTime }) => {
  if (!date) {
    return <EmptyValue />;
  }

  if (showTime) {
    return <>{utcFormat('%Y-%m-%d %H:%M:%S')(date)}</>;
  }

  return <>{utcFormat('%Y-%m-%d')(date)}</>;
};

export default DateDisplay;
