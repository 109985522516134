import { rest } from 'msw';

const mswUpdateUserDeploymentSettings = rest.post(
  '/v1/metadata-service/update-user-deployment-settings',
  (req, res, ctx) => {
    // return res(ctx.status(200), ctx.json({ success: true }));
    return res(
      ctx.status(403),
      ctx.json({
        success: false,
        message: 'Databricks credentials are invalid',
      })
    );
  }
);

export default mswUpdateUserDeploymentSettings;
