import React from 'react';
import { Button, ButtonEmpty, Text } from '@tecton';

import { TectonBasicModal, CalloutMessageType } from '@shared';

interface DeactivateConfirmationModalProps {
  principalId: string | undefined;
  isLoading: boolean;
  errorMessages: CalloutMessageType[] | undefined;
  confirmChange: () => void;
  onClose: () => void;
}

const DeactivateConfirmationModal = ({
  principalId,
  isLoading,
  errorMessages,
  confirmChange,
  onClose,
}: DeactivateConfirmationModalProps) => {
  return (
    <TectonBasicModal
      title={<h4>Deactivate {principalId}?</h4>}
      body={
        <Text>
          <p>
            Are you sure you want to deactivate <strong>{principalId}</strong>?
          </p>
        </Text>
      }
      actions={
        <>
          <ButtonEmpty onClick={onClose}>Cancel</ButtonEmpty>
          <Button isLoading={isLoading} onClick={confirmChange} fill color="danger">
            Deactivate
          </Button>
        </>
      }
      calloutMessages={errorMessages}
      onClose={onClose}
    />
  );
};

export default DeactivateConfirmationModal;
