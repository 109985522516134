import React from 'react';
import { Button, ButtonEmpty, Text } from '@tecton';
import { TectonBasicModal, CalloutMessageType } from '@shared';

const RemoveFromGroupConfirmationModal = ({
  principalId,
  groupName,
  isLoading,
  errorMessages,
  onClose,
  confirmChanges,
}: {
  principalId: string;
  groupName: string;
  isLoading: boolean;
  errorMessages: CalloutMessageType[] | undefined;
  onClose: () => void;
  confirmChanges: () => void;
}) => {
  return (
    <TectonBasicModal
      maxWidth={'80vw'}
      title={`Remove ${principalId} from ${groupName}`}
      body={
        <Text>
          <p>
            Are you sure you want to remove {principalId} from {groupName}?
          </p>
        </Text>
      }
      actions={
        <>
          <ButtonEmpty onClick={onClose}>Cancel</ButtonEmpty>
          <Button onClick={confirmChanges} fill isLoading={isLoading} color="danger">
            Confirm Removal
          </Button>
        </>
      }
      onClose={onClose}
      calloutMessages={errorMessages}
    />
  );
};

export default RemoveFromGroupConfirmationModal;
