import React from 'react';
import {
  EuiBasicTable,
  EuiBasicTableColumn,
  Criteria,
  EuiEmptyPrompt,
  EuiLoadingChart,
  EuiTableSortingType,
} from '@elastic/eui';
type RowPropsCallback<T> = (row: T) => object;

interface TectonBasicTableProps<T, S> {
  items: T[];
  itemId?: string;
  itemIdToExpandedRowMap?: Record<string, React.ReactNode>;
  columns: EuiBasicTableColumn<T>[];
  sorting?: EuiTableSortingType<S>;
  onChange?: (s: Criteria<T>) => void;
  onChangePage?: () => void;
  pagination?: any;
  loading?: boolean;
  fetching?: boolean;
  isExpandable?: boolean;
  rowProps?: object | RowPropsCallback<T>;
  showPerPageOptions?: boolean | undefined;
  error?: string;
  className?: string;
  layout?: 'fixed' | 'auto';
  loadingText?: string;
  emptyText?: string;
  scrollable?: boolean;
}

export const Table = <T, S>({
  items,
  itemId,
  itemIdToExpandedRowMap,
  columns,
  sorting,
  onChange,
  isExpandable,
  loading,
  fetching,
  rowProps,
  error,
  className,
  layout,
  loadingText = 'Loading',
  emptyText = 'This request returned no results. Try a different time frame or set of filters',
  scrollable = true,
}: TectonBasicTableProps<T, S>) => {
  if (error) {
    return (
      <div>
        <EuiEmptyPrompt iconType="alert" title={<h2>Something happened!</h2>} body={<p>{error}</p>} />
      </div>
    );
  }

  if (loading) {
    return <EuiEmptyPrompt icon={<EuiLoadingChart size="xl" />} title={<h2>{loadingText}</h2>} />;
  }

  if (!loading && !fetching && items && !items.length) {
    return (
      <div>
        <EuiEmptyPrompt title={<h2>No Results</h2>} body={<p>{emptyText}</p>} />
      </div>
    );
  }

  return (
    <div>
      <div className={`${scrollable ? 'eui-xScroll' : ''}`} data-testid="tecton-table-wrapper">
        <EuiBasicTable
          items={items}
          itemId={itemId}
          isExpandable={isExpandable}
          loading={fetching}
          itemIdToExpandedRowMap={itemIdToExpandedRowMap}
          columns={columns}
          className={className}
          sorting={sorting as EuiTableSortingType<T> | undefined}
          onChange={onChange}
          rowProps={rowProps}
          tableLayout={layout ? layout : 'fixed'}
        />
      </div>
    </div>
  );
};

export default Table;
