import React from 'react';
import { EuiIconTip, EuiIconTipProps, EuiToolTipProps } from '@elastic/eui';

type Props = Omit<EuiToolTipProps, 'children' | 'delay' | 'position'> &
  EuiIconTipProps & {
    // This are copied from EuiToolTipProps, but made optional. Defaults
    // are applied below.
    delay?: EuiToolTipProps['delay'];
    position?: EuiToolTipProps['position'];
  };

export default (props: Props) => <EuiIconTip {...props} />;
