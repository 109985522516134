import DemoUtils from '../utils/demo-utils';
import IdUtils from '../utils/id-utils';
import WorkspaceUtils from '../utils/workspace-utils';
import FeatureView from './FeatureView';
import { FirstClassObjectTypes } from '../components/FirstClassObject';

class FeatureSetItem {
  private proto: any = null;

  constructor(proto: any) {
    this.proto = proto;
  }

  get _proto() {
    return this.proto;
  }

  get featureViewId() {
    return IdUtils.toStringId(this._proto.feature_view_id);
  }

  get joinConfigurationItems() {
    return this._proto.join_configuration_items;
  }

  get namespace() {
    return this._proto.namespace;
  }

  get featureColumns() {
    return this._proto.feature_columns;
  }
}

export default class FeatureService {
  private proto: any = null;

  constructor(proto: any) {
    this.proto = proto;
  }

  get _proto() {
    return this.proto;
  }

  get fcoType() {
    return FirstClassObjectTypes.FEATURE_SERVICE;
  }

  get id() {
    return IdUtils.toStringId(this._proto.feature_service_id);
  }

  get name() {
    return this._proto.fco_metadata.name;
  }

  get description() {
    return this._proto.fco_metadata.description;
  }

  get tags() {
    return this._proto.fco_metadata.tags || {};
  }

  get createdAt() {
    return new Date(this._proto.fco_metadata.created_at);
  }

  get owner() {
    return this._proto.fco_metadata.owner;
  }

  get lastModifiedBy() {
    return this._proto.fco_metadata.last_modified_by;
  }

  get workspace() {
    return this._proto.fco_metadata.workspace || WorkspaceUtils.getDefaultWorkspace();
  }

  allFeatureViews(idToFvMap: any) {
    return this._featureSetItems
      .map((item: any) => {
        const fvId = item.featureViewId;
        const fv = idToFvMap[fvId];
        if (!fv) {
          return null;
        }
        // Clone since we are modifying the object
        const fvCloned = new FeatureView(idToFvMap[fvId]._proto);
        fvCloned.setFeatureServiceNamespace(item.namespace);
        return fvCloned;
      })
      .filter((fv: any) => !!fv);
  }

  shouldHideMaterializationStatus(fsFvs: FeatureView[]) {
    //True if fsFvs types are either "Feature Table" or "On Demand"
    return fsFvs.filter((fv) => !fv.isFeatureTable && !fv.isRealtime).length === 0;
  }

  get isEnabled() {
    return DemoUtils.featureServiceIsEnabled(this.id);
  }

  get isOnlineServingEnabled() {
    return this._proto.online_serving_enabled;
  }

  get featureParameters() {
    return [];
  }

  get featureSet() {
    return {};
  }

  get dependentDataSources() {
    return DemoUtils.dependentDataSources();
  }

  get _featureSetItems() {
    return this._proto.feature_set_items.map((fsi: any) => {
      return new FeatureSetItem(fsi);
    });
  }

  get sourceFilename() {
    return this._proto.fco_metadata.source_filename;
  }
}
