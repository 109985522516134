import React from 'react';
import { FlexGroup, FlexItem } from '@tecton';
import { useTectonTheme } from '../../@tecton';

const ACLLayout = ({ mainColumn, sideColumn }: { mainColumn: React.ReactNode; sideColumn: React.ReactNode }) => {
  const { theme } = useTectonTheme();

  return (
    <div style={{ display: 'grid', gridTemplateColumns: '1fr 30em' }}>
      <div style={{ padding: theme.size.xl }}>{mainColumn}</div>
      <div
        style={{
          padding: theme.size.xl,
          borderLeft: `1px solid ${theme.border.color}`,
        }}
      >
        {sideColumn}
      </div>
    </div>
  );
};

const ACLTableControlLayout = ({
  search,
  actionButtons,
}: {
  search: React.ReactNode;
  actionButtons: React.ReactNode;
}) => {
  return (
    <FlexGroup justifyContent="spaceBetween">
      <FlexItem style={{ flexBasis: '35%' }} grow={false}>
        {search}
      </FlexItem>
      <FlexItem grow={false}>{actionButtons}</FlexItem>
    </FlexGroup>
  );
};

export default ACLLayout;
export { ACLTableControlLayout };
