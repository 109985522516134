import { ConfigState, ConfigUpdateAction, ConfigUpdateErrorAction, ConfigUpdateIsOnboardingEnabled } from './types';

export const updateGlobalConfigs = (data: any): ConfigUpdateAction => {
  return {
    type: ConfigState.HAS_REQUESTED_CONFIGURATION,
    data: data,
  };
};

export const updateGlobalConfigsHasErrors = (error: any): ConfigUpdateErrorAction => {
  return {
    type: ConfigState.HAS_REQUEST_ERRORS,
    error: error,
  };
};

export const updateConfigUpdateIsOnboardingEnabled = (
  isOnboardingEnabled: boolean
): ConfigUpdateIsOnboardingEnabled => {
  return {
    type: ConfigState.IS_ONBOARDING_ENABLED,
    isOnboardingEnabled: isOnboardingEnabled,
  };
};
