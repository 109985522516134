import React, { useState } from 'react';
import { Modal, ModalHeader, ModalHeaderTitle, ModalBody, ModalFooter, useTectonTheme } from '@tecton';
import TectonCallout from './TectonCallout';
import useTectonModalStyle from '../shared/useTectonModalStyle';
import { animated, config, useTransition } from '@react-spring/web';

export type CalloutMessageType = {
  title: string;
  color: React.ComponentProps<typeof TectonCallout>['color'];
  iconType: React.ComponentProps<typeof TectonCallout>['iconType'];
};

interface TectonBasicModalProps {
  onClose: () => void;
  title: React.ReactNode;
  body: React.ReactNode;
  actions: React.ReactNode;
  maxWidth?: React.ComponentProps<typeof Modal>['maxWidth'];
  style?: React.CSSProperties | undefined;
  calloutMessages?: CalloutMessageType[];
}

export const CallOutWithTransition = ({ messages }: { messages?: CalloutMessageType[] }) => {
  const { theme } = useTectonTheme();

  const transitions = useTransition(messages || [], {
    from: { opacity: 0, maxHeight: '0px' },
    enter: { opacity: 1, maxHeight: '240px' },
    leave: { opacity: 0, maxHeight: '0px' },
    config: { ...config.stiff },
  });

  return transitions((style: any, item: any) => {
    return (
      <animated.div style={{ ...style, overflow: 'hidden' }}>
        <TectonCallout
          style={{
            paddingLeft: theme.size.l,
            paddingRight: theme.size.l,
          }}
          size="m"
          title={item.title}
          color={item.color}
          iconType={item.iconType}
        />
      </animated.div>
    );
  });
};

export const useModalCalloutState = () => {
  const [errorMessages, setErrorMessage] = useState<CalloutMessageType[] | undefined>();

  return {
    errorMessages,
    setErrorMessage,
  };
};

export const TectonBasicModal = ({
  onClose,
  title,
  body,
  actions,
  style,
  maxWidth,
  calloutMessages,
}: TectonBasicModalProps) => {
  const { theme } = useTectonTheme();

  return (
    <Modal style={style} className={useTectonModalStyle(theme)} onClose={onClose} maxWidth={maxWidth}>
      <ModalHeader>
        {/* <div
          className={css`
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
              font-size: calc(${theme.font.scale.l} * ${theme.base}px);
            }

            font-weight: ${theme.font.weight.semiBold};
          `}
        >
          {title}
        </div> */}

        <ModalHeaderTitle>{title}</ModalHeaderTitle>
      </ModalHeader>
      <ModalBody>{body}</ModalBody>
      <ModalFooter>{actions}</ModalFooter>
      <CallOutWithTransition messages={calloutMessages} />
    </Modal>
  );
};

export default TectonBasicModal;
