import React from 'react';
import { EuiBadge, EuiBadgeProps } from '@elastic/eui';

const Badge = ({ children, ...rest }: EuiBadgeProps) => {
  return (
    <div style={{ pointerEvents: 'none' }}>
      <EuiBadge {...rest}>{children}</EuiBadge>
    </div>
  );
};

export default Badge;
