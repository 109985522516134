import React, { ReactNode } from 'react';

export const replaceStringWithReactNode = (text: string, searchString: string, replacementNode: ReactNode) => {
  const parts = text.split(searchString);
  const replacedElements = parts.map((part, index) => {
    if (index < parts.length - 1) {
      // Add the replacement ReactNode between the parts
      return (
        <React.Fragment key={index}>
          {part}
          {replacementNode}
        </React.Fragment>
      );
    }
    return part;
  });

  return replacedElements;
};
