import { EuiPopover } from '@elastic/eui';
import { css } from '@emotion/css';
import styled from '@emotion/styled';
import { useTectonTheme } from '@tecton/ComponentRedesign';
import React, { FC, ReactNode } from 'react';

interface TectonPopoverProps {
  title?: React.ReactNode;
  body: React.ReactNode;
  buttonContent?: React.ReactNode;
  isOpen?: boolean;
  button?: NonNullable<ReactNode> | undefined;
  anchorPosition?:
    | 'upCenter'
    | 'upLeft'
    | 'upRight'
    | 'downCenter'
    | 'downLeft'
    | 'downRight'
    | 'leftCenter'
    | 'leftUp'
    | 'leftDown'
    | 'rightCenter'
    | 'rightUp'
    | 'rightDown'
    | undefined;
  closePopover: () => void;
  maxWidth?: string;
  panelPaddingSize?: 's' | 'none' | 'xs' | 'm' | 'l' | 'xl' | undefined;
  canScrollBody?: boolean;
}

const PopoverWrapper = styled.div<{ maxWidth: string }>`
  max-width: ${({ maxWidth }) => maxWidth};
`;

const PopoverTitle = styled.div`
  color: ${({ theme }) => theme.colors.title};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  margin-bottom: ${({ theme }) => theme.padding.l};
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightShade};
  padding-bottom: ${({ theme }) => theme.padding.l};
`;

const PopoverBody = styled.div<{ canScrollBody: boolean }>`
  font-size: ${({ theme }) => theme.font.fontSizes.s};
  line-height: ${({ theme }) => theme.font.lineHeights.s};
  color: ${({ theme }) => theme.colors.text};
  max-height: 50vh;
  overflow: ${({ canScrollBody }) => (canScrollBody ? 'auto' : 'hidden')};
`;

const PopoverButton = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 15px;
`;

const TectonPopover: FC<TectonPopoverProps> = ({
  title,
  body,
  buttonContent,
  isOpen,
  button,
  anchorPosition,
  closePopover = () => {
    return;
  },
  maxWidth = '300px',
  panelPaddingSize,
  canScrollBody = true,
}) => {
  const { theme } = useTectonTheme();

  const panelClass = css`
    outline: none !important;
    border: none;

    padding: ${panelPaddingSize === 'none' ? '0px' : theme.padding.l};

    ::focus {
      outline: none !important;
      border: none !important;
    }
  `;

  return (
    <EuiPopover
      button={button}
      isOpen={isOpen}
      anchorPosition={anchorPosition}
      closePopover={closePopover}
      panelPaddingSize={panelPaddingSize}
      panelClassName={panelClass}
      data-testid="popover"
    >
      <PopoverWrapper maxWidth={maxWidth}>
        {title && <PopoverTitle>{title}</PopoverTitle>}
        <PopoverBody canScrollBody={canScrollBody}>{body}</PopoverBody>
        {buttonContent && <PopoverButton>{buttonContent}</PopoverButton>}
      </PopoverWrapper>
    </EuiPopover>
  );
};

export default TectonPopover;
