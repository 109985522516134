import { MaterializationJob } from './types';

// Types from backend for Batch Materialization Monitoring
export enum MaterializationMetricTypes {
  COUNT_DISTINCT = 'COUNT_DISTINCT',
  COUNT_ROWS = 'COUNT_ROWS',
  COUNT_NULLS = 'COUNT_NULLS',
  COUNT_ZEROS = 'COUNT_ZEROS',
  AVG_VALUE = 'AVG_VALUE',
  MAX_VALUE = 'MAX_VALUE',
  MIN_VALUE = 'MIN_VALUE',
  VAR_SAMPLE = 'VAR_SAMPLE',
  STDDEV_SAMPLE = 'STDDEV_SAMPLE',
  AVG_LENGTH = 'AVG_LENGTH',
}

export enum MaterializationMetricStatus {
  METRIC_STATUS_AVAILABLE = 'METRIC_STATUS_AVAILABLE',
  METRIC_STATUS_UNAVAILABLE = 'METRIC_STATUS_UNAVAILABLE',
  METRIC_STATUS_ERROR = 'METRIC_STATUS_ERROR',
  METRIC_STATUS_NO_MATERIALIZATION = 'METRIC_STATUS_NO_MATERIALIZATION',
}

export enum MaterializationMetricResolutionGroups {
  TIME_RESOLUTION_ONE_MINUTE = 'TIME_RESOLUTION_ONE_MINUTE',
  TIME_RESOLUTION_FIVE_MINUTES = 'TIME_RESOLUTION_FIVE_MINUTES',
  TIME_RESOLUTION_ONE_HOUR = 'TIME_RESOLUTION_ONE_HOUR',
  TIME_RESOLUTION_ONE_DAY = 'TIME_RESOLUTION_ONE_DAY',
  TIME_RESOLUTION_ONE_WEEK = 'TIME_RESOLUTION_ONE_WEEK',
  TIME_RESOLUTION_ONE_MONTH = 'TIME_RESOLUTION_ONE_MONTH',
  TIME_RESOLUTION_ONE_YEAR = 'TIME_RESOLUTION_ONE_YEAR',
}

export interface MaterializationMetricsDefaultValuesForPanel {
  rowsValue: number | undefined;
  rowsDelta: number | undefined;
  joinKeysValue: number | undefined;
  joinKeysDelta: number | undefined;
  mostRecentJob: MaterializationJob | undefined;
  features: { featureName: string; value: number | undefined; delta: number | undefined }[];
}

export interface MaterializationQueryFeatureMetricDatum {
  feature_name: string;
  value: string;
}

export interface MaterializationQueryMetricValuesDatum {
  metric_status: MaterializationMetricStatus;
  interval_start_time: string;
  materialization_run_id?: string;
  materialization_task_attempt_url?: string;
  metric_values: MaterializationQueryFeatureMetricDatum[];
}

export interface MaterializationQueryMetricResponse {
  aligned_end_time: string; // UTC-formatted string
  aligned_start_time: string; // UTC-formatted string
  column_names: string[]; // Names of the columns associated with the response?
  feature_view_name: string; // The name of the feature view
  metric_data: MaterializationQueryMetricValuesDatum[]; // The metrics data
  metric_data_point_interval: string; // What is the  format of this?
  metric_type: MaterializationMetricTypes; // This was passed in
  workspace: string;
}
