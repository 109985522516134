import { NetworkActionType } from '../utils/actions-utils';
import { setWorkspaceAction, setPathStateAction, setApplyLogAction } from './actions';
import { handleActions } from 'redux-actions';

export const loadingReducer = (state = {}, action) => {
  const { type } = action;
  const matches = /(.*)\/(REQUEST|SUCCESS|ERROR)/.exec(type);

  if (!matches) return state;

  const [, requestName, requestState] = matches;
  return {
    ...state,
    [requestName]: requestState === NetworkActionType.REQUEST,
  };
};

const initialWorkspaceState = { workspace: null };
export const workspaceReducer = handleActions(
  {
    [setWorkspaceAction]: (state, action) => {
      return { ...state, workspace: action.payload };
    },
  },
  initialWorkspaceState
);

const initialPathState = { path: null, params: {} };
export const pathStateReducer = handleActions(
  {
    [setPathStateAction]: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
  initialPathState
);

const initialApplyLogState = { planId: null };
export const applyLogStateReducer = handleActions(
  {
    [setApplyLogAction]: (state, action) => {
      return { ...state, planId: action.payload };
    },
  },
  initialApplyLogState
);
