import { OktaAuth } from '@okta/okta-auth-js';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../../utils/okta';
import { getCurrentUserAction, setAuthAction } from './actions';
import { useUserSettings } from '../../components/context/UserSettingsContext';

/**
 * Okta hook is responsible for checking to see if a user is logged in the application.
 * If the use is not logged in, we send them to the okta login page.
 */

let authManagerHasSubscribed = false; // prevent from making multiple oktaAuth.getUser()

const useOktaHook = (oktaAuth: OktaAuth) => {
  const dispatch = useDispatch();
  const { updatePrincipal, updateOktaAuth } = useUserSettings();
  /*
    We need to use the a useState instead of a redux dispatch since the subscribe
    function gets call more often and redux take a while for it to set.
   */
  const [hasRequestedOktaUser, setHasRequestedOktaUser] = useState<boolean>(false);

  const authManagerHandler = useCallback((authState: any) => {
    if (authState.isAuthenticated) {
      // Send okta to redux
      // TODO: remove this once we delete redux.
      dispatch(setAuthAction(oktaAuth));

      // We need this for logout.
      updateOktaAuth(oktaAuth);

      // We unsubscribe all handlers
      oktaAuth.authStateManager.unsubscribe();

      // We check to see if we've already requested for Okta user and prevent duplicate calls
      if (!hasRequestedOktaUser) {
        setHasRequestedOktaUser(true);

        // Makes a request to get the Okta User
        oktaAuth.getUser().then((oktaUser) => {
          // Dispatch the okta user to Redux
          const payload = {
            type: getCurrentUserAction.success().type,
            payload: oktaUser,
          };

          if (updatePrincipal) {
            updatePrincipal(oktaUser);
          }

          dispatch(payload);
        });
      }
    } else {
      // Send the user to the okta login page
      logout(oktaAuth);
    }
  }, []);

  if (!authManagerHasSubscribed) {
    authManagerHasSubscribed = true;
    // We use subscribe since it's more accurate of when a user get authenticated
    oktaAuth.authStateManager.subscribe(authManagerHandler);
  }
};

export default useOktaHook;
