import styled from '@emotion/styled';
import React, { FC } from 'react';

interface MonospaceProps {
  children: React.ReactNode;
}

const MonospaceStyle = styled.span`
  font-family: ${({ theme }) => theme.font.familyCode};
`;

const Monospace: FC<MonospaceProps> = ({ children }) => {
  return <MonospaceStyle data-testid="monospace">{children}</MonospaceStyle>;
};

export default Monospace;
