import { arc } from 'd3-shape';
import React, { FC } from 'react';
import { useTectonTheme, Text } from '@tecton/ComponentRedesign';
import { format } from 'd3-format';
import { FlexGroup, FlexItem } from '../NeedsDesign';
import styled from '@emotion/styled';

type ProgressSize = 'xs' | 's' | 'm' | 'l' | 'xl';
type ProgressStatus = 'in progress' | 'success' | 'failed';

interface ProgressProps {
  type?: 'bar' | 'circle';
  size?: ProgressSize;
  state?: ProgressStatus;
  percentage?: number;
  color?: string;
  width?: number;
  label?: string;
  maxLabel?: string;
  showValue?: boolean;
}

const Label = styled(FlexItem)`
  color: ${({ theme }) => theme.colors.subduedText};
`;

const BarProgress: FC<ProgressProps> = ({ size, percentage, color, width, label, maxLabel, showValue }) => {
  const { theme } = useTectonTheme();

  const barSizes: Record<ProgressSize, number> = {
    xs: 2,
    s: 4,
    m: 8,
    l: 10,
    xl: 10,
  };

  return (
    <>
      <FlexGroup justifyContent="spaceBetween" style={{ width: width }}>
        <Label grow={1}>{label && <Text small>Left</Text>}</Label>
        <Label grow={0}>{maxLabel && <Text small>{maxLabel}</Text>}</Label>
      </FlexGroup>
      <FlexGroup alignItems="center" justifyContent="flexStart" direction="row" gutterSize="xs">
        <svg width={width} height={barSizes[size ?? 'm']}>
          <rect
            width={width}
            height={barSizes[size ?? 'm']}
            fill={theme.colors.lightShade}
            rx={barSizes[size ?? 'm'] / 2}
            ry={barSizes[size ?? 'm'] / 2}
          />
          <rect
            width={width! * percentage!}
            height={barSizes[size ?? 'm']}
            fill={color}
            rx={barSizes[size ?? 'm'] / 2}
            ry={barSizes[size ?? 'm'] / 2}
          />
        </svg>
        {showValue && (
          <Label grow={0} style={{ color: color }}>
            {format('.0%')(percentage ?? 0)}
          </Label>
        )}
      </FlexGroup>
    </>
  );
};

const CircleProgress: FC<ProgressProps> = ({ size, percentage, color, showValue }) => {
  const { theme } = useTectonTheme();

  const circleSizes: Record<ProgressSize, number> = {
    xs: 12,
    s: 16,
    m: 24,
    l: 32,
    xl: 40,
  };

  const actualSize = circleSizes[size ?? 'm'];
  const middlePoint = actualSize / 2;

  const arcValue = arc()({
    innerRadius: middlePoint - 2,
    outerRadius: middlePoint,
    startAngle: 0,
    endAngle: 2 * Math.PI * (percentage ?? 0),
  });

  return (
    <svg height={actualSize} width={actualSize}>
      <circle cx={middlePoint} cy={middlePoint} r={middlePoint} fill={theme.colors.lightShade} />
      <circle cx={middlePoint} cy={middlePoint} r={middlePoint - 2} fill={theme.colors.emptyShade} />
      <path fill={color} d={arcValue ?? ''} transform={`translate(${middlePoint},${middlePoint})`} />
      {size === 'xl' && showValue && (
        <text
          x={middlePoint}
          y={middlePoint}
          textAnchor="middle"
          alignmentBaseline="middle"
          fontSize={theme.font.fontSizes.xs}
          fontFamily={theme.font.family}
          fill={color}
        >
          {format('.0%')(percentage ?? 0)}
        </text>
      )}
    </svg>
  );
};

const Progress: FC<ProgressProps> = ({
  type = 'bar',
  size = 's',
  state = 'in progress',
  percentage,
  width = 160,
  label,
  maxLabel,
  showValue,
}) => {
  const { theme } = useTectonTheme();

  // Note: this should be unnecessary and we should be able to directly reference theme tokens
  // However, we're waiting on color primitives before proceeding
  const colorMap: Record<ProgressStatus, string> = {
    'in progress': '#5DA7C4', // Theme Tokens Instead
    success: '#52AF94', // Theme Tokens Instead
    failed: '#F04448', // Theme Tokens Instead
  };

  if (type === 'circle')
    return (
      <CircleProgress size={size} state={state} percentage={percentage} color={colorMap[state]} showValue={showValue} />
    );

  return (
    <BarProgress
      size={size}
      state={state}
      percentage={percentage}
      color={colorMap[state]}
      width={width}
      label={label}
      maxLabel={maxLabel}
      showValue={showValue}
    />
  );
};

export default Progress;
