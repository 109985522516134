import React from 'react';
import WorkspaceSelector from './WorkspaceSelector';
import { ViewLayout } from '@tecton/ComponentRedesign';

export const SelectAWorkspace = () => {
  const rightSideContent = (
    <>
      <ViewLayout
        header={<></>}
        body={
          <>
            <WorkspaceSelector />
          </>
        }
      />
    </>
  );

  return rightSideContent;
};

export default SelectAWorkspace;
