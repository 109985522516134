import React, { FC } from 'react';

import { differenceWith, isEqual } from 'lodash';
import { useTectonTheme, Checkbox } from '@tecton/ComponentRedesign';
import styled from '@emotion/styled';
import { Selectable } from '../../_eui_components';

export interface CategoricalSelectorHierarchicalOption {
  label: string;
  indent: number;
  parent?: string;
  checked?: 'on' | 'off';
  indeterminate?: boolean;
}

interface CategoricalSelectorHierarchicalProps {
  options: CategoricalSelectorHierarchicalOption[];
  showIcons?: boolean;
  changeCallback: (selectedOption: CategoricalSelectorHierarchicalOption) => void;
  resetCallback: () => void;
}

const CategoricalSelectorHierarchical: FC<CategoricalSelectorHierarchicalProps> = ({
  options,
  showIcons = true,
  changeCallback,
  resetCallback,
}) => {
  const { theme } = useTectonTheme();

  const StyledSelectable = styled(Selectable)`
    .euiFormControlLayout {
      margin-bottom: ${theme.padding.l};
    }

    .euiSelectableListItem {
      background-color: ${theme.colors.emptyShade};
      color: ${theme.colors.text};

      border-bottom: none;

      :hover {
        background-color: ${theme.colors.body};

        span {
          color: ${theme.colors.fullShade};
          text-decoration: none !important;
        }
      }
    }

    .euiSelectableListItem-isFocused {
      background-color: ${theme.colors.body};
      color: ${theme.colors.fullShade};
    }
  `;

  return (
    <StyledSelectable
      aria-label=""
      options={options}
      onChange={(optionsReturned) => {
        const selectedOption = differenceWith(optionsReturned, options, isEqual);
        changeCallback(selectedOption[0] as CategoricalSelectorHierarchicalOption);
      }}
      loadingMessage="Loading filters"
      emptyMessage="No filters available"
      noMatchesMessage="No filters found"
      singleSelection={false}
      listProps={{ showIcons: showIcons }}
      renderOption={(option: any) => {
        const indentLevel = option.parent ? theme.size.xl : theme.size.s;
        const fontWeight = option.parent ? theme.font.weight.medium : theme.font.weight.bold;

        return (
          <>
            <div style={{ paddingLeft: indentLevel, fontWeight: fontWeight }}>
              <div style={{ pointerEvents: 'none' }}>
                <Checkbox
                  id={'checkbox_' + option.label}
                  label={option.label}
                  checked={option.checked === 'on' ? true : false}
                  indeterminate={option.indeterminate}
                />
              </div>
            </div>
          </>
        );
      }}
      height="full"
    >
      {(list) => <>{list}</>}
    </StyledSelectable>
  );
};

export default CategoricalSelectorHierarchical;
