import { handleActions } from 'redux-actions';
import { getFeatureFlagsAction } from './actions';

const state = {
  flags: null,
  flagsLoading: true,
};

export const reducer = handleActions(
  {
    [getFeatureFlagsAction.request]: (state, action) => {
      return {
        ...state,
        flagsLoading: true,
      };
    },
    [getFeatureFlagsAction.success]: (state, action) => {
      return {
        ...state,
        flags: action.payload,
        flagsLoading: false,
      };
    },
  },
  state
);
