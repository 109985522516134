import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getUser, getPlanIdAction, getAuth, getConfig, getIsOnboardingEnabled } from './selectors';

/**
 * Redux global state
 */

const useGlobalState = () => {
  const getUserCallback = useCallback((state: any) => {
    return getUser(state);
  }, []);
  const getAuthCallback = useCallback((state: any) => {
    return getAuth(state);
  }, []);

  const currentPlanIdCallback = useCallback((state: any) => {
    return getPlanIdAction(state);
  }, []);

  const getConfigCallback = useCallback((state: any) => {
    return getConfig(state);
  }, []);

  const getIsGuidedOnboardingEnabledCallback = useCallback((state: any) => {
    return getIsOnboardingEnabled(state);
  }, []);

  const loggedInUser = useSelector(getUserCallback);
  const auth = useSelector(getAuthCallback);
  const currentPlanId = useSelector(currentPlanIdCallback);
  const config = useSelector(getConfigCallback);
  const isGuidedOnboardingEnabled = useSelector(getIsGuidedOnboardingEnabledCallback);

  return { currentPlanId, loggedInUser, auth, config, isGuidedOnboardingEnabled };
};

export default useGlobalState;
