import _ from 'lodash';
import IdUtils from '../utils/id-utils';
import Entity from './Entity';
import TransformationNew from './TransformationNew';
import FeatureView from './FeatureView';
import VirtualDataSource from './VirtualDataSource';
import FeatureService from './FeatureService';
import SavedFeatureDataFrame from './SavedFeatureDataFrame';
import MaterializationStatus from './MaterializationStatus';
import OktaUser from './OktaUser';
import store from '../core/store';
import WorkspaceUtils from '../utils/workspace-utils';
import UpdateLogEntry from './UpdateLogEntry';
import FeatureViewMaterializationStatus from '../service/FeatureViewMaterializationStatus';
import UserDeploymentSettings from './UserDeploymentSettings';
import FcoContainer from './FcoContainer';
import DataPlatformSetupStatus from './DataPlatformSetupStatus';
import OnboardingStatus from './OnboardingStatus';
import { logEvent } from '../utils/analytics-utils';
import { ServiceAccount } from './ServiceAccount';
import Workspace from './Workspace';
import { SERVICE_ACCOUNT_NAME_ALREADY_EXIST } from '../feature/access-control/query';

class MDSFetchError extends Error {}

class MDSAuthError extends MDSFetchError {
  constructor() {
    super();
    this.isAuthError = true;
  }
}

class InvalidWorkspaceError extends MDSFetchError {
  constructor() {
    super();
    this.isInvalidWorkspaceError = true;
  }
}

export default class MetadataService {
  constructor() {
    this.serviceURL = window._env_.API_URL;
  }

  static sharedInstance() {
    return instance;
  }

  async cancelMaterializationJob(materializationTaskId, featureViewName, workspaceName) {
    const response = await instance._fetchAndCheckStatus('/v1/jobs/cancel-materialization-job', {
      job_id: materializationTaskId,
      feature_view: featureViewName,
      workspace: workspaceName,
    });
    const responseJson = await response.json();
    return responseJson;
  }

  async getAvailableWorkspaces() {
    const response = await instance._fetchAndCheckStatus('/v1/metadata-service/list-workspaces', {});
    const responseJson = await response.json();

    if (!('workspaces' in responseJson)) {
      // send an empty array if workspace attribute doesn't exist so we don't get null pointer exception
      responseJson.workspaces = [];
    }
    return responseJson;
  }

  async getWorkspace(workspace) {
    const response = await instance._fetchAndCheckStatus('/v1/metadata-service/get-workspace', {
      workspace_name: workspace,
    });
    const responseJson = await response.json();
    return responseJson.workspace;
  }

  async queryMetric(workspace, featureViewName, metricType, startTime, endTime) {
    let request = {};

    request = {
      workspace: workspace,
      feature_view_name: featureViewName,
      end_time: endTime,
      metric_type: metricType,
    };

    // Conditionally add start time because Feature Tables do not have an associate materialization start time
    if (startTime) {
      request.start_time = startTime;
    }

    const response = await instance._fetchAndCheckStatus('/v1/metadata-service/query-metric', request);

    const responseJson = await response.json();
    return responseJson;
  }

  // Only use one of name or id
  async getFeatureView(workspace, name, id) {
    const response = await instance._fetchAndCheckStatus('/v1/metadata-service/get-feature-view', {
      workspace: workspace,
      version_specifier: name,
      id: id ? IdUtils.fromStringId(id) : undefined,
    });
    const responseJson = await response.json();
    const fcoContainer = MetadataService._wrapFcoContainer(responseJson.fco_container);
    const fv = fcoContainer.getSingleRootFco();
    return fv ? fv.innerWrapped : null;
  }

  async getFcoContainer() {
    const response = await instance._fetchAndCheckStatus('/v1/metadata-service/get-fcos', {
      workspace: WorkspaceUtils.getWorkspaceFromStore(),
    });
    const responseJson = await response.json();

    return MetadataService._wrapFcoContainer(responseJson.fco_container);
  }

  async getPrometheusQueryResult(queries, monitoringDateRange) {
    // Prometheus doesn't support batch (graphite does) queries so we have make a request per query.
    const promises = queries.map((query) => {
      const startTime = parseInt(monitoringDateRange.startTime() / 1000);
      const endTime = parseInt(monitoringDateRange.endTime() / 1000);

      return instance._fetchAndCheckStatus(
        `/v1/observability/proxy/prometheus/query_range?query=${query.query}&start=${startTime}&end=${endTime}&step=${monitoringDateRange.step}`
      );
    });
    // We wait for all the promises to return
    const responses = await Promise.all(promises);
    const responseJson = [];
    // doing an old for loop here since .forEach is not respecting await in a callback
    for (let x = 0; responses.length > x; x++) {
      const json = await responses[x].json();
      responseJson.push(json.data);
    }

    return responseJson;
  }

  async getGraphiteQueryResult(param, monitoringDateRange) {
    const startTime = parseInt(Math.round(monitoringDateRange.startTime() / 1000));
    const endTime = parseInt(Math.round(monitoringDateRange.endTime() / 1000));
    const formBody = [...param, ['from', `${startTime}`], ['until', `${endTime}`]];

    const encodedBody = formBody.map((paramAndValue) => {
      return `${encodeURIComponent(paramAndValue[0])}=${encodeURIComponent(paramAndValue[1])}`;
    });
    const formBodyJoined = encodedBody.join('&');

    const response = await instance._fetchAndCheckXWWWForm(`/v1/observability/proxy/graphite/render`, formBodyJoined);
    const responseJson = await response.json();
    return responseJson;
  }

  async getUserDeploymentSettings() {
    const response = await instance._fetchAndCheckStatus('/v1/metadata-service/get-user-deployment-settings', {});
    const responseJson = await response.json();
    return MetadataService._wrapDataPlatformConfiguration(responseJson.user_deployment_settings);
  }

  async getSparkClusterStatus() {
    const response = await instance._fetchAndCheckStatus('/v1/metadata-service/get-internal-spark-cluster-status', {});
    const responseJson = await response.json();
    return responseJson;
  }

  async updateUserDeploymentSettings(userDeploymentSettings) {
    const response = await instance._fetchAndCheckStatus(
      '/v1/metadata-service/update-user-deployment-settings',
      userDeploymentSettings
    );
    const responseJson = await response.json();
    return responseJson;
  }

  async getAllMaterializingFeatureViews() {
    const response = await instance._fetchAndCheckStatus(
      '/v1/metadata-service/get-materializing-features-in-live-workspace',
      {}
    );
    const responseJson = await response.json();
    return MetadataService._wrapFeatureViews(responseJson.feature_views);
  }

  async getFcoWorkspace(fcoId) {
    const response = await instance._fetchAndCheckStatus('/v1/metadata-service/find-fco-workspace', {
      feature_view_id: IdUtils.fromStringId(fcoId),
    });
    const responseJson = await response.json();
    return responseJson;
  }

  // Only use one of name or id
  async getFeatureService(workspace, name, id) {
    const response = await instance._fetchAndCheckStatus('/v1/metadata-service/get-feature-service', {
      workspace: workspace,
      service_reference: name,
      id: id ? IdUtils.fromStringId(id) : undefined,
    });

    const responseJson = await response.json();
    const fcoContainer = MetadataService._wrapFcoContainer(responseJson.fco_container);
    const fs = fcoContainer.getSingleRootFco();
    return fs ? fs.innerWrapped : null;
  }

  async getVirtualDataSourceSummary(name) {
    const response = await instance._fetchAndCheckStatus('/v1/metadata-service/get-virtual-data-source-summary', {
      fco_locator: {
        workspace: WorkspaceUtils.getWorkspaceFromStore(),
        name: name,
      },
    });

    if (response.status === 404) {
      return null;
    }
    const responseJson = await response.json();
    return responseJson.fco_summary;
  }

  async getFeatureServiceSummary(name) {
    const response = await instance._fetchAndCheckStatus('/v1/metadata-service/get-feature-service-summary', {
      workspace: WorkspaceUtils.getWorkspaceFromStore(),
      feature_service_name: name,
    });

    if (response.status === 404) {
      return null;
    }
    const responseJson = await response.json();
    return responseJson;
  }

  async getServingStatus(fvIdStr) {
    const response = await instance._fetchAndCheckStatus('/v1/metadata-service/get-serving-status', {
      workspace: WorkspaceUtils.getWorkspaceFromStore(),
      feature_package_id: IdUtils.fromStringId(fvIdStr),
    });

    if (response.status === 404) {
      return null;
    }

    const responseJson = await response.json();
    return responseJson.serving_status_summary;
  }

  async getFeatureServiceServingStatus(fsIdStr, page, per_page) {
    const response = await instance._fetchAndCheckStatus('/v1/metadata-service/get-fv-serving-status-for-fs', {
      workspace: WorkspaceUtils.getWorkspaceFromStore(),
      feature_service_id: IdUtils.fromStringId(fsIdStr),
      pagination: {
        page: page,
        per_page: per_page,
      },
    });

    if (response.status === 404) {
      return null;
    }

    const responseJson = await response.json();
    return responseJson.full_serving_status_summary;
  }

  async getMaterializationStatus(fvIdStr, workspace, includeDeleted = false) {
    const response = await instance._fetchAndCheckStatus('/v1/metadata-service/get-materialization-status', {
      feature_package_id: IdUtils.fromStringId(fvIdStr),
      workspace: workspace,
      include_deleted: includeDeleted,
    });

    if (response.status === 404) {
      return null;
    }

    const responseJson = await response.json();
    return MetadataService._wrapMaterializationStatus(responseJson.materialization_status);
  }

  async forceRetryMaterializationTask(materializationTaskId, allowOverwrite = false) {
    const response = await instance._fetchAndCheckStatus('/v1/metadata-service/force-retry-materialization-task', {
      materialization_task_id: IdUtils.fromStringId(materializationTaskId),
      allowOverwrite: allowOverwrite,
    });
    const responseJson = await response.json();
    return responseJson;
  }

  async getStateUpdatePlanSummary(plan_id) {
    const response = await instance._fetchAndCheckStatus('/v1/metadata-service/get-state-update-plan-summary', {
      plan_id: IdUtils.fromStringId(plan_id),
    });
    const responseJson = await response.json();
    return MetadataService._wrapStateUpdatePlanSummary(responseJson.plan);
  }

  async getStateUpdateLog(workspace) {
    const response = await instance._fetchAndCheckStatus('/v1/metadata-service/get-state-update-log', {
      workspace: workspace,
      limit: 100,
    });
    const responseJson = await response.json();
    return MetadataService._wrapUpdateLogEntries(responseJson?.entries);
  }

  async getConfigs() {
    const response = await instance._fetchAndCheckStatus('/v1/metadata-service/get-configs');
    const responseJson = await response.json();
    return responseJson;
  }

  async getAllSavedFeatureDataFrames() {
    const response = await instance._fetchAndCheckStatus('/v1/metadata-service/get-all-saved-feature-dataframes', {
      workspace: WorkspaceUtils.getWorkspaceFromStore(),
    });
    const responseJson = await response.json();
    return MetadataService._wrapSavedFeatureDataFrames(responseJson.saved_feature_dataframes);
  }

  async ingestAnalytics(events) {
    const workspace = WorkspaceUtils.getWorkspaceFromStore();
    // Intentionally not using _fetchAndCheckStatus since we want to silently ignore analytics failures
    instance._fetchMDS('/v1/metadata-service/ingest-analytics', {
      workspace: workspace ? workspace : '',
      events: events,
    });
  }

  async getClusterAdminInfo() {
    const response = await instance._fetchAndCheckStatus('/v1/metadata-service/get-cluster-admin-info');
    const responseJson = await response.json();
    if (responseJson == null) {
      return null;
    }
    const result = {
      isAdmin: responseJson.caller_is_admin,
      users: MetadataService._wrapOktaUsers(responseJson.users || []),
      admins: MetadataService._wrapOktaUsers(responseJson.admins || []),
    };
    return result;
  }

  async getServiceAccounts(serviceAccountArg) {
    const response = await instance._fetchAndCheckStatus('/v1/metadata-service/get-service-account', serviceAccountArg);
    const responseJson = await response.json();
    if (responseJson == null) {
      return null;
    }

    //Returns all Service accounts across workspaces
    const allServiceAccounts = MetadataService._wrapServiceAccounts(responseJson.service_accounts);
    return allServiceAccounts;
  }

  async createServiceAccount(serviceAccount) {
    const response = await instance._fetchAndCheckStatus('/v1/metadata-service/create-service-account', {
      ...serviceAccount,
    });

    const responseJson = await response.json();
    return responseJson;
  }

  async updateServiceAccount(serviceAccount) {
    const response = await instance._fetchAndCheckStatus('/v1/metadata-service/update-service-account', {
      ...serviceAccount,
    });

    const responseJson = await response.json();
    return responseJson;
  }

  async deleteServiceAccount(serviceAccount) {
    const response = await instance._fetchAndCheckStatus('/v1/metadata-service/delete-service-account', {
      ...serviceAccount,
    });

    const responseJson = await response.json();
    return responseJson;
  }

  async createUser(email) {
    const response = await instance._fetchAndCheckStatus('/v1/metadata-service/create-cluster-user', {
      login_email: email,
    });

    const responseJson = await response.json();
    return responseJson;
  }

  async deleteUser(okta_id) {
    const response = await instance._fetchAndCheckStatus('/v1/metadata-service/delete-cluster-user', {
      okta_id: okta_id,
    });

    const responseJson = await response.json();
    return responseJson;
  }

  async userAction(payload_map) {
    const response = await instance._fetchAndCheckStatus('/v1/metadata-service/cluster-user-action', {
      okta_id: payload_map['okta_id'],
      unlock_user: payload_map['unlockUser'] || undefined,
      resend_activation_email: payload_map['resendActivationEmail'] || undefined,
      grant_admin: payload_map['grantAdmin'] || undefined,
      revoke_admin: payload_map['revokeAdmin'] || undefined,
    });

    const responseJson = await response.json();
    return responseJson;
  }

  async getHiveMetaData(action) {
    const response = await instance._fetchAndCheckStatus('/v1/metadata-service/get-hive-metadata', action);
    const responseJson = await response.json();
    return responseJson;
  }

  async getDataPlatformSetupStatus() {
    const response = await instance._fetchAndCheckStatus('/v1/metadata-service/get-data-platform-setup-status');
    const responseJson = await response.json();
    return MetadataService._wrapDataPlatformSetupStatus(responseJson);
  }

  async _putFetchMDS(path, params) {
    const url = instance.serviceURL + path;
    const requestId = IdUtils.generateStringId();
    const headers = {
      'x-request-id': requestId,
      'x-workspace': WorkspaceUtils.getWorkspaceFromStore(),
    };

    const auth = store.getState().auth.auth;
    if (auth != null) {
      headers['Authorization'] = 'Bearer ' + auth.getAccessToken();
    }

    const fetchParams = {
      method: 'put',
      mode: 'cors', // no-cors, cors, *same-origin
      headers: headers,
      body: JSON.stringify(params),
    };
    return fetch(url, fetchParams);
  }

  async _fetchMDS(path, params) {
    const url = instance.serviceURL + path;
    const requestId = IdUtils.generateStringId();
    const headers = {
      'x-request-id': requestId,
      'x-workspace': WorkspaceUtils.getWorkspaceFromStore(),
    };

    const auth = store.getState().auth.auth;
    if (auth != null) {
      headers['Authorization'] = 'Bearer ' + auth.getAccessToken();
    }

    const fetchParams = {
      method: 'post',
      mode: 'cors', // no-cors, cors, *same-origin
      headers: headers,
      body: JSON.stringify(params),
    };

    return fetch(url, fetchParams);
  }

  async _fetchMDSXWWWForm(path, formBody) {
    const url = instance.serviceURL + path;
    const requestId = IdUtils.generateStringId();
    const headers = {
      'x-request-id': requestId,
      'x-workspace': WorkspaceUtils.getWorkspaceFromStore(),
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    };

    const auth = store.getState().auth.auth;
    if (auth != null) {
      headers['Authorization'] = 'Bearer ' + auth.getAccessToken();
    }

    const fetchParams = {
      method: 'post',
      mode: 'cors', // no-cors, cors, *same-origin
      headers: headers,
      body: formBody,
    };

    return fetch(url, fetchParams);
  }

  async checkResponse(response) {
    if (!response.ok && response.status !== 404) {
      if (response.status === 401 || response.status === 403) {
        throw new MDSAuthError();
      }

      if (response.status === 400) {
        const errorJson = await response.json();
        if (errorJson.message && errorJson.message.substring(0, 17) === 'INVALID_WORKSPACE') {
          throw new InvalidWorkspaceError();
        } else if (errorJson.message && errorJson.message.match(/^Service Account with name '.+' already exists.$/gm)) {
          throw new Error(SERVICE_ACCOUNT_NAME_ALREADY_EXIST);
        }
      }

      // Send MDS errors to amplitude
      if (response && response.status && response.url) {
        logEvent('Unknown MDS error', '', { status: response.status, url: response.url });
      }
      throw new MDSFetchError();
    }
  }

  async _fetchAndCheckStatus(path, params) {
    const response = await instance._fetchMDS(path, params);
    await instance.checkResponse(response);
    return response;
  }

  async _fetchAndCheckStatusPut(path, params) {
    const response = await instance._putFetchMDS(path, params);
    await instance.checkResponse(response);
    return response;
  }

  static _wrapStateUpdatePlanSummary(proto) {
    return new StateUpdatePlanSummary(proto);
  }

  async _fetchAndCheckXWWWForm(path, formBody) {
    const response = await instance._fetchMDSXWWWForm(path, formBody);
    await instance.checkResponse(response);
    return response;
  }

  static _wrapUpdateLogEntries(protos) {
    if (_.isEmpty(protos)) {
      return [];
    }
    return protos.map((proto) => new UpdateLogEntry(proto));
  }

  static _wrapEntity(proto) {
    return new Entity(proto);
  }

  static _wrapEntities(protos) {
    if (_.isEmpty(protos)) {
      return [];
    }
    return protos.map(function wrap(proto) {
      return MetadataService._wrapEntity(proto);
    });
  }

  static _wrapSavedFeatureDataFrame(proto) {
    return new SavedFeatureDataFrame(proto);
  }

  static _wrapSavedFeatureDataFrames(protos) {
    if (_.isEmpty(protos)) {
      return [];
    }
    return protos.map(function wrap(proto) {
      return MetadataService._wrapSavedFeatureDataFrame(proto);
    });
  }

  static _wrapTransformationNew(proto) {
    return proto == null ? null : new TransformationNew(proto);
  }

  static _wrapTransformationsNew(newProtos) {
    return (newProtos || []).map(MetadataService._wrapTransformationNew);
  }

  static _wrapFeatureView(proto) {
    return new FeatureView(proto);
  }

  static _wrapFcoContainer(proto) {
    return new FcoContainer(proto);
  }

  static _wrapFeatureViews(protos) {
    if (_.isEmpty(protos)) {
      return [];
    }
    return protos.map(function wrapFV(proto) {
      return MetadataService._wrapFeatureView(proto);
    });
  }

  static _wrapVirtualDataSource(proto) {
    return new VirtualDataSource(proto);
  }

  static _wrapVirtualDataSources(protos) {
    if (_.isEmpty(protos)) {
      return [];
    }
    return protos.map(function wrapVDS(proto) {
      return MetadataService._wrapVirtualDataSource(proto);
    });
  }

  static _wrapFeatureService(proto) {
    return new FeatureService(proto);
  }

  static _wrapFeatureServices(protos) {
    if (_.isEmpty(protos)) {
      return [];
    }
    return protos.map(function wrapFS(proto) {
      return MetadataService._wrapFeatureService(proto);
    });
  }

  static _wrapMaterializationStatus(proto) {
    return proto == null ? null : new MaterializationStatus(proto);
  }

  static _wrapFeatureViewMaterializationStatus(protos) {
    if (_.isEmpty(protos)) {
      return [];
    }
    return protos.map(function wrapFS(proto) {
      return new FeatureViewMaterializationStatus(proto);
    });
  }

  static _wrapOktaUsers(protos) {
    if (protos == null || _.isEmpty(protos)) {
      return [];
    }
    return protos.map((p) => new OktaUser(p));
  }

  static _wrapServiceAccounts(protos) {
    if (protos == null || _.isEmpty(protos)) {
      return [];
    }

    return protos.map((p) => new ServiceAccount(p));
  }

  static _wrapDataPlatformConfiguration(proto) {
    return new UserDeploymentSettings(proto);
  }

  static _wrapDataPlatformSetupStatus(proto) {
    return new DataPlatformSetupStatus(proto);
  }

  static _wrapOnboardingState(proto) {
    return new OnboardingStatus(proto);
  }

  static _wrapWorkspace(proto) {
    return new Workspace(proto);
  }

  static _wrapWorkspaces(protos) {
    if (_.isEmpty(protos)) {
      return [];
    }
    return protos.map(function wrapFS(proto) {
      return MetadataService._wrapWorkspace(proto);
    });
  }
}

const instance = new MetadataService();
