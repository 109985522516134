import React from 'react';

const FeatureViewTypeView = ({ featureType }: any) => {
  return (
    <span style={{ color: '#0179d0' }} className="f7 bw-1 b-solid pl1 pr1 nowrap dib mr1">
      {featureType}
    </span>
  );
};

export default FeatureViewTypeView;
