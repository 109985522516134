export default () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="23" height="23" rx="5.5" fill="white" />
      <rect x="0.5" y="0.5" width="23" height="23" rx="5.5" stroke="#EAECF0" />
      <rect x="0.5" y="0.5" width="23" height="23" rx="5.5" stroke="white" stroke-opacity="0.4" />
      <path
        d="M8.6665 8.66675L15.3332 15.3334"
        stroke="#343741"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.6665 15.3334L15.3332 8.66675"
        stroke="#343741"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
