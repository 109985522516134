import { FC } from 'react';
import { Badge } from '@tecton/ComponentRedesign';

interface ActiveInActiveBadgeProps {
  enabled: boolean;
}

export const ActiveInActiveBadge: FC<ActiveInActiveBadgeProps> = ({ enabled }) => {
  if (enabled) {
    return <Badge variant={'success'} label={'Active'} />;
  }

  return <Badge variant={'hollow'} label={'Inactive'} />;
};

export default ActiveInActiveBadge;
