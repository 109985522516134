import React from 'react';

import { EmptyValue, Table, useTectonTheme } from '@tecton';
import PlanChangesColumn from './PlanChangesColumn';
import {
  FcoPropertyDiff,
  FcoTransitionType,
  StateUpdatePlanSummaryDiff,
} from '../../types/tecton_proto/data/state_update';
import { renderPlanActionTypeBadge } from './PlanActionTypeBadge';
import { StateUpdatePlanSummaryType } from './PlanSummaryTransform';
import PlanFeatureViewName from './PlanFeatureViewName';
import PlanApplyFeatureViewBadge from './PlanApplyFeatureViewBadge';
import { useTableStyle } from '@shared';
import {
  TableCommonActionColumn,
  TableCommonNameColumn,
  TableCommonTypeColumn,
  TableCommonChangesColumn,
  planDiffTypeOrderValues,
  TableCommonMaterializationColumn,
  scrollIdMap,
} from './util';
import PlanCategoryAccordion from './PlanCategoryAccordion';

const PlanFVTTable = ({ stateUpdatePlanSummary }: { stateUpdatePlanSummary?: StateUpdatePlanSummaryType }) => {
  const { theme } = useTectonTheme();
  const tableStyleCss = useTableStyle();

  const fvSortFn = (a: StateUpdatePlanSummaryDiff, b: StateUpdatePlanSummaryDiff) => {
    if (a.type !== b.type) {
      const result =
        planDiffTypeOrderValues[a.type ?? FcoTransitionType.UNKNOWN] -
        planDiffTypeOrderValues[b.type ?? FcoTransitionType.UNKNOWN];
      return result;
    } else {
      const result = a?.fco_type?.localeCompare(b?.fco_type ?? '') ?? 0;
      return result;
    }
  };
  const FVKeys = [
    'Feature Table',
    'On-Demand Feature View',
    'Batch Feature View',
    'Stream Feature View',
    'Stream Feature View with Push Source',
  ];

  const featureViewsAndTables =
    stateUpdatePlanSummary?.plan?.diff_items
      ?.filter((item) => {
        return FVKeys.indexOf(item.fco_type ?? '') >= 0;
      })
      ?.sort(fvSortFn) ?? [];

  if (featureViewsAndTables.length === 0) {
    return <></>;
  }

  return (
    <PlanCategoryAccordion id={scrollIdMap['Feature View/Table']} title="Feature Views & Tables">
      <Table
        items={featureViewsAndTables}
        className={tableStyleCss}
        columns={[
          {
            ...TableCommonActionColumn,
            render: renderPlanActionTypeBadge,
          },
          {
            ...TableCommonNameColumn,
            render: (name: string, row: StateUpdatePlanSummaryDiff) => {
              return (
                <PlanFeatureViewName workspace={stateUpdatePlanSummary?.plan?.workspace} stateUpdatePlanDiff={row} />
              );
            },
          },
          {
            ...TableCommonChangesColumn,
            render: (diffs: FcoPropertyDiff[], row: StateUpdatePlanSummaryDiff) => {
              return <PlanChangesColumn diffs={diffs} row={row} />;
            },
          },
          {
            ...TableCommonTypeColumn,
            render: (fco_type: string) => {
              return <PlanApplyFeatureViewBadge type={fco_type} />;
            },
          },
          {
            ...TableCommonMaterializationColumn,
            render: (entry: StateUpdatePlanSummaryDiff) => {
              // No materialization if the item is
              // deleted, or if it is not a materializing
              // type of FV.
              if (
                entry.type === 'DELETE' ||
                (entry.fco_type !== 'Stream Feature View' && entry.fco_type !== 'Batch Feature View')
              ) {
                return <EmptyValue />;
              }

              const summaries = [];
              if (Object.keys(entry?.materialization_info?.batch_task_diff ?? {}).length > 0) {
                summaries.push(`1 recurring batch job`);
              }
              if (Object.keys(entry?.materialization_info?.stream_task_diff ?? {}).length > 0) {
                summaries.push(`1 recurring stream job`);
              }

              if (entry?.materialization_info?.backfill_task_diffs) {
                const backfillCount = entry.materialization_info.backfill_task_diffs.reduce<number>((memo, current) => {
                  return current?.number_of_jobs ? memo + current?.number_of_jobs : memo;
                }, 0);

                summaries.push(`${backfillCount} backfill job${backfillCount > 1 ? 's' : ''}`);
              }

              if (summaries.length === 0) {
                return <span style={{ color: theme.colors.mediumShade }}>no materialization</span>;
              }

              return <>{summaries.join(', ')}</>;
            },
          },
        ]}
      />
    </PlanCategoryAccordion>
  );
};

export default PlanFVTTable;
