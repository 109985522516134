import React, { FC } from 'react';
import { Badge, useTectonTheme } from '@tecton';

import { FcoTransitionType, StateUpdatePlanSummaryDiff } from '../../types/tecton_proto/data/state_update';

interface PlanActionTypeBadgeProps {
  actionType: FcoTransitionType;
}

const PlanActionTypeBadge: FC<PlanActionTypeBadgeProps> = ({ actionType }) => {
  const { theme } = useTectonTheme();
  const planColors = theme.color.light?.planFcoTransitionTypeColorPairs;

  return (
    <Badge color={planColors[actionType].backgroundColor}>
      <span style={{ color: planColors[actionType].textColor }}>{actionType}</span>
    </Badge>
  );
};

const renderPlanActionTypeBadge = (actionType: FcoTransitionType, _row: StateUpdatePlanSummaryDiff) => {
  return <PlanActionTypeBadge actionType={actionType} />;
};

export default PlanActionTypeBadge;
export { renderPlanActionTypeBadge };
