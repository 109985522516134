import _get from 'lodash/get';
import _unionBy from 'lodash/unionBy';
import IdUtils from '../utils/id-utils';

export const PipelineNodeTypes = {
  TRANSFORMATION: 'transformation_node',
  DATA_SOURCE: 'data_source_node',
  CONSTANT: 'constant_node',
  REQUEST_DATA_SOURCE: 'request_data_source_node',
  FEATURE_VIEW: 'feature_view_node',
  MATERIALIZATION_CONTEXT: 'materialization_context_node',
  CONTEXT: 'context_node',
  JOIN_INPUTS: 'join_inputs_node',
};

const NODE_IDS = {
  [PipelineNodeTypes.TRANSFORMATION]: 'transformation_id',
  [PipelineNodeTypes.DATA_SOURCE]: 'virtual_data_source_id',
  [PipelineNodeTypes.FEATURE_VIEW]: 'feature_view_id',
};

export class InputNode {
  #proto = null;

  constructor(proto) {
    this.#proto = proto;
  }

  get _proto() {
    return this.#proto;
  }

  get index() {
    return this._proto.arg_index;
  }

  get argName() {
    return this._proto.arg_name;
  }

  get node() {
    return new PipelineNode(this._proto.node);
  }
}

export class PipelineNode {
  #proto = null;

  constructor(proto) {
    this.#proto = proto;
  }

  get _proto() {
    return this.#proto;
  }

  // Internal nodes are always TransformationNodes, and leaf nodes are everything else.
  get isLeaf() {
    return !this._proto.hasOwnProperty(PipelineNodeTypes.TRANSFORMATION);
  }

  get nodeType() {
    return Object.values(PipelineNodeTypes).find((type) => this._proto.hasOwnProperty(type));
  }

  get node() {
    return this._proto[this.nodeType];
  }

  get type() {
    const nodeType = this.nodeType;
    return nodeType.substring(0, nodeType.length - '_node'.length);
  }

  get inputs() {
    return this.node.inputs ? this.node.inputs.map((input) => new InputNode(input)) : [];
  }

  get constant() {
    const innerNode = this.node;
    return (
      innerNode.string_const ||
      innerNode.int_const ||
      innerNode.float_const ||
      innerNode.bool_const ||
      innerNode.null_const
    );
  }

  get innerNodeId() {
    if (!this.node) {
      return null;
    }
    const nodeId = this.node[NODE_IDS[this.nodeType]];
    return nodeId ? IdUtils.toStringId(nodeId) : null;
  }

  get transformationNode() {
    return {
      inputs: this._proto.transformation_node.inputs,
      transformationId: IdUtils.toStringId(this._proto.transformation_node.transformation_id),
    };
  }
}

const FORMATTED_MODES = {
  PIPELINE_MODE_SPARK: 'Spark',
  PIPELINE_MODE_ON_DEMAND: 'OnDemand',
  PIPELINE_MODE_UNKNOWN: 'Unknown',
};

export default class Pipeline {
  #proto = null;

  constructor(proto) {
    this.#proto = proto;
  }

  get _proto() {
    return this.#proto;
  }

  get mode() {
    const mode = this._proto.mode;
    if (mode == null || !FORMATTED_MODES.hasOwnProperty(mode)) {
      return mode;
    }
    return FORMATTED_MODES[mode];
  }

  // A pipeline is a tree.
  get root() {
    return new PipelineNode(this._proto.root);
  }

  get rootTransformationId() {
    const rootNode = this.root;
    return rootNode && !rootNode.isLeaf ? rootNode.nodeId : null;
  }

  get _getAllNodesInDAG() {
    const nodes = [];

    // Leaf nodes like REQUEST_DATA_SOURCE don't have IDs and won't be added to seenIds
    // By definition, if a node does not have an ID it only appears once
    const seenIds = new Set();

    const fringe = [this.root];
    while (fringe.length > 0) {
      const node = fringe.pop();

      const transformationNode = _get(node, 'innerNodeId.transformationNode');

      // Check for Transformation Bide and innerNode
      if (node.innerNodeId && seenIds.has(transformationNode)) {
        continue;
      }

      nodes.push(node);
      if (node.innerNodeId) {
        seenIds.add(node.innerNodeId);
      }

      node.inputs.forEach((input) => {
        fringe.push(input.node);
      });
    }

    // These nodes can have duplicate entries, but is need to draw the Pipeline correctly.
    return nodes;
  }

  get allLeafNodes() {
    // Take out the duplicate since most likely this will be rendered via table.
    return _unionBy(
      this._getAllNodesInDAG.filter((i) => i.isLeaf),
      'innerNodeId'
    );
  }

  get allInternalNodes() {
    return this._getAllNodesInDAG.filter((i) => !i.isLeaf);
  }

  get allTransformationNodes() {
    // Take out the duplicate since most likely this will be rendered via table.
    const allTransformationNodes = this._getAllNodesInDAG.filter(
      (i) => i.nodeType === PipelineNodeTypes.TRANSFORMATION
    );

    return _unionBy(allTransformationNodes, 'innerNodeId');
  }
}
