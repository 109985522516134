import { UpdateUserDeploymentSettingsRequestInterface } from '../../feature/data-platform-configuration/databricks/setup/DatabricksCredentialsForm';

export const createUserDeploymentSetting = (
  databricksUrl: string,
  databricksApiToken: string,
  instanceProfileArn: string,
  sparkConfig: any,
  fieldMask: string
): UpdateUserDeploymentSettingsRequestInterface => {
  return {
    user_deployment_settings: {
      databricks_config: {
        workspace_url: `${databricksUrl}`,
        api_token: {
          value: `${databricksApiToken}`,
        },
      },
      user_spark_settings: {
        instance_profile_arn: `${instanceProfileArn}`,
        spark_conf: sparkConfig,
      },
    },
    field_mask: fieldMask,
  };
};
