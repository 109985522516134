import React, { FC } from 'react';
import FCOCard from './FCOCard';
import { useTectonTheme } from './TectonThemeProvider';
import { css } from '@emotion/css';

interface EmptyCardProps {
  title: string;
  content: React.ReactNode;
}

const EmptyCard: FC<EmptyCardProps> = ({ title, content }) => {
  const { theme } = useTectonTheme();

  const style = css`
    color: ${theme.color.light.mediumShade};
  `;

  return (
    <FCOCard title={title}>
      <span className={style}>{content}</span>
    </FCOCard>
  );
};

export default EmptyCard;
