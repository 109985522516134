import IdUtils from '../utils/id-utils';
import WorkspaceUtils from '../utils/workspace-utils';
import { FirstClassObjectTypes } from '../components/FirstClassObject';

export default class Entity {
  private proto: any = null;

  constructor(proto: any) {
    this.proto = proto;
  }

  get _proto() {
    return this.proto;
  }

  get fcoType() {
    return FirstClassObjectTypes.ENTITY;
  }

  get id() {
    return IdUtils.toStringId(this._proto.entity_id);
  }

  get name() {
    return this._proto.fco_metadata.name;
  }

  get description() {
    return this._proto.fco_metadata.description;
  }

  get createdAt() {
    return new Date(this._proto.fco_metadata.created_at);
  }

  get tags() {
    return this._proto.fco_metadata.tags || {};
  }

  get owner() {
    return this._proto.fco_metadata.owner;
  }

  get lastModifiedBy() {
    return this._proto.fco_metadata.last_modified_by;
  }

  get workspace() {
    return this._proto.fco_metadata.workspace || WorkspaceUtils.getDefaultWorkspace();
  }

  get sourceFileName() {
    return this._proto.fco_metadata.source_filename;
  }

  get joinKeys() {
    // THIS FIELD IS NOW FOR COLUMN LOOKUP
    // BREAKING CHANGE SO POINTING TO THE LEGACY KEY BELOW
    return this._proto.join_keys_legacy;
  }

  get joinKeysLegacy() {
    return this._proto.join_keys_legacy;
  }
}
