import { useEffect, useState } from 'react';
import { UseQueryResult } from '@tanstack/react-query';
import { hasUserDeploymentConfiguration } from '../../../../utils/onboarding/onboarding-util';

const useHasUserDeploymentConfiguration = (configurationState: UseQueryResult) => {
  const [hasDeploymentConfiguration, setHasDeploymentConfiguration] = useState(false);
  useEffect(() => {
    if (configurationState.isSuccess) {
      setHasDeploymentConfiguration(hasUserDeploymentConfiguration(configurationState.data));
    }
  }, [configurationState]);

  return { hasDeploymentConfiguration, setHasDeploymentConfiguration };
};

export default useHasUserDeploymentConfiguration;
