import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import MetadataService from '../../service/MetadataService';
import { UpdateUserDeploymentSettingsRequestInterface } from './databricks/setup/DatabricksCredentialsForm';

export function useDatabricksConfiguration() {
  const state = useQuery(['dataPlatformConfiguration'], () => {
    return MetadataService.sharedInstance().getUserDeploymentSettings();
  });
  if (state.error) {
    throw state.error;
  }
  return state;
}

export function useMutateUserDeploymentSettings() {
  const queryClient = useQueryClient();
  const state = useMutation(
    (userDeploymentSettings: UpdateUserDeploymentSettingsRequestInterface) => {
      return MetadataService.sharedInstance()
        .updateUserDeploymentSettings(userDeploymentSettings)
        .then((result) => {
          return result;
        });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['dataPlatformConfiguration']);
      },
    }
  );
  if (state.error) {
    throw state.error;
  }
  return state;
}

export function useSparkClusterStatus() {
  const state = useQuery(
    ['sparkClusterStatus'],
    () => {
      return MetadataService.sharedInstance().getSparkClusterStatus();
    },
    {
      // Refetch the data every 10 seconds
      refetchInterval: 10000,
    }
  );
  if (state.error) {
    throw state.error;
  }
  return state;
}
