import { OnboardingUpdateAction, OnboardingStateEnums, OnboardingGlobalState, OnboardingActionTypes } from './types';
import { updateObjectProperties } from '../../utils/object-util';
import { OnboardingSidebarState } from '../../utils/onboarding/onboarding-util';

// The order matters on this list.
export const onBoardingLabelStates: OnboardingSidebarState[] = [
  {
    label: 'Data Platform Setup',
    hasSidebarState: true,
    isCompleted: false,
    isCurrentState: false,
    onBoardingState: OnboardingStateEnums.NEW_ACCOUNT,
  },

  {
    label: 'Completed',
    hasSidebarState: false,
    isCompleted: true,
    isCurrentState: false,
    onBoardingState: OnboardingStateEnums.HAS_COMPLETED_GUIDED_ONBOARDING,
  },
];

export const getSideBarOnboardingStates = (onboardingState: OnboardingStateEnums): OnboardingSidebarState[] => {
  let hasFoundState: boolean = false;

  return onBoardingLabelStates.map((onBoardingLabelState: OnboardingSidebarState) => {
    // We set this to true until we find the current state, since all the state previous to the current state should be completed
    let isCompleted: boolean = true;
    // Boolean for which onboarding state they should be in.
    let isCurrentState: boolean = false;

    if (hasFoundState) {
      isCompleted = false;
      isCurrentState = false;
    }

    if (onboardingState === onBoardingLabelState.onBoardingState) {
      // We found the current state.
      hasFoundState = true;
      isCompleted = false;
      isCurrentState = true;
    }

    if (onboardingState === OnboardingStateEnums.HAS_COMPLETED_GUIDED_ONBOARDING) {
      // This is the last s
      isCompleted = true;
    }

    return {
      label: onBoardingLabelState.label,
      isCompleted: isCompleted,
      isCurrentState: isCurrentState,
      hasSidebarState: onBoardingLabelState.hasSidebarState,
      onBoardingState: onBoardingLabelState.onBoardingState,
    };
  });
};

const onBoardingStateDefaultState: OnboardingSidebarState[] = getSideBarOnboardingStates(
  OnboardingStateEnums.HAS_COMPLETED_GUIDED_ONBOARDING
);

export interface OnBoardingReducerInterface {
  onBoardingStates: OnboardingSidebarState[];
  hasLoadedOnboarding: boolean; // Has loaded from the backend
}

// Default state
const OnboardingInitialState: OnBoardingReducerInterface = {
  onBoardingStates: onBoardingStateDefaultState,
  hasLoadedOnboarding: false,
};

const updateOnboardingState = (
  state: OnBoardingReducerInterface,
  action: OnboardingUpdateAction
): Readonly<OnBoardingReducerInterface> => {
  const onBoardSidebarStates = getSideBarOnboardingStates(action.onBoardingState);

  const updateState: OnBoardingReducerInterface = {
    onBoardingStates: onBoardSidebarStates,
    hasLoadedOnboarding: true,
  };
  return updateObjectProperties(state, updateState);
};

export const onBoardingReducer = (
  state: OnBoardingReducerInterface = OnboardingInitialState,
  action: OnboardingActionTypes
) => {
  switch (action.type) {
    case OnboardingGlobalState.UPDATE_ONBOARDING_STATE: {
      return updateOnboardingState(state, action);
    }
    default: {
      return state;
    }
  }
};
