import React from 'react';
import { EuiBreadcrumbs, FlexItem, FlexGroup, Spacer, useTectonTheme } from '@tecton';
import { css } from '@emotion/css';
import { TectonTabs, TectonBreadcrumbTitles } from '@shared';
interface TectonPageHeaderProps {
  tabs: React.ComponentProps<typeof TectonTabs>['tabs'];
  breadcrumbs: React.ComponentProps<typeof EuiBreadcrumbs>['breadcrumbs'];
  rightSideItems?: React.ReactNode[];
  showDescription?: boolean;
  description?: React.ReactNode;
}

const TectonPageHeader = ({
  tabs,
  breadcrumbs,
  rightSideItems,
  showDescription = false,
  description,
}: TectonPageHeaderProps) => {
  const { theme } = useTectonTheme();

  const TectonPageHeaderCss = css`
    padding: ${theme.size.xl} ${theme.size.xl} 0 ${theme.size.xl};
  `;

  // mediumShade doesn't exist in our redesign theme
  const descriptionCss = theme?.color?.light?.mediumShade
    ? css`
        color: ${theme.color.light.mediumShade};
        font-size: 16px;
      `
    : css`
        font-size: 16px;
      `;

  return (
    <>
      <div className={TectonPageHeaderCss} data-testid="TectonPage__Header">
        <FlexGroup justifyContent="center">
          <FlexItem>
            <TectonBreadcrumbTitles breadcrumbs={breadcrumbs} />
            {/* <EuiBreadcrumbs breadcrumbs={breadcrumbs} truncate={false} /> */}
          </FlexItem>
          {rightSideItems && <FlexItem grow={false}>{rightSideItems.map((item) => item)}</FlexItem>}
        </FlexGroup>
        {showDescription && (
          <>
            <Spacer size="s" />
            <span className={descriptionCss}>
              {description && description !== '' ? description : 'No description provided.'}
            </span>
          </>
        )}

        <Spacer size="m" />
        <TectonTabs tabs={tabs} />
      </div>
    </>
  );
};

export default TectonPageHeader;
