/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, MouseEventHandler, useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  CallOut,
  ChartLoading,
  EmptyTab,
  FlexGroup,
  FlexItem,
  Spacer,
  StyledTooltip,
  useResizeObserver,
  useTectonTheme,
} from '@tecton';
import { DataQualityMonitoringContext, DataQualityMonitoringContextProvider } from './DataQualityMetricsContext';
import styled from '@emotion/styled';
import { CardInnerTitle } from '@shared';
import {
  DataQualityMonitoringDateRangeSlider,
  DataQualityMonitoringJobIndicator,
} from './DataQualityMonitoringTimeControl';
import {
  calculatePercentagesByDateForFeatureViews,
  filterFeatureViewDataForTimeRange,
  getIndexForDate,
  getMatchingEventForDate,
  getNearestEventToDate,
  materializationPanelDateFormat,
  materializationPanelTimeFormat,
} from './DataQualityMetricsUtils';
import { format } from 'd3-format';
import { MaterializationEvent, MaterializationMetricStatus } from './DataQualityMetricsTypes';
import { group } from 'd3-array';
import { DataQualityMonitoringColumnChart } from './DataQualityMonitoringCharts';
import DataQualityMetricSelector from './DataQualityMetricSelector';
import { clone } from 'lodash';
import { FeatureViewFCO, FeatureViewFCOFields, FeatureViewFCOType } from '../../../../core/types/fcoTypes';
import { useWindowSize } from '../../../@tecton/ComponentRedesign/ServingStatus/useWindowSize';
import FCOPanel from '../../../featureViews/FCOPanel';
import { TECTON_DOCUMENTATION_LINKS } from '../../application-links';

interface DataQualityMonitoringPanelProps {
  featureView: FeatureViewFCO;
}

interface DataQualityMonitoringChartRowProps {
  label: React.ReactNode;
  chart: React.ReactNode;
  comparisonCell: React.ReactNode;
}

export const useDataQualityMonitoringThemeColors = () => {
  const { theme } = useTectonTheme();

  const statusColors: Record<MaterializationMetricStatus, string> = {
    [MaterializationMetricStatus.METRIC_STATUS_AVAILABLE]: theme.colors.success,
    [MaterializationMetricStatus.METRIC_STATUS_UNAVAILABLE]: theme.colors.lightShade,
    [MaterializationMetricStatus.METRIC_STATUS_NO_MATERIALIZATION]: theme.colors.warning,
    [MaterializationMetricStatus.METRIC_STATUS_ERROR]: theme.colors.danger,
  };

  return statusColors;
};

const DataQualityMonitoringChartRow: FC<DataQualityMonitoringChartRowProps> = ({ label, chart, comparisonCell }) => {
  const { theme } = useTectonTheme();

  const RowDiv = styled.div`
    display: grid;
    grid-template-columns: 12.5em 1fr 17em;
    border-bottom: ${theme.border.thin};
    align-items: end;
  `;

  const CellDiv = styled.div`
    display: column;
  `;

  return (
    <RowDiv>
      <CellDiv>{label}</CellDiv>
      <CellDiv>{chart}</CellDiv>
      <CellDiv style={{ paddingLeft: '1em' }}>{comparisonCell}</CellDiv>
    </RowDiv>
  );
};

export const DataQualityMonitoringStatusRow: FC = () => {
  const context = useContext(DataQualityMonitoringContext);

  const materializedPeriods = useMemo(() => {
    return context.rowCountsData.filter((datum) => datum.status === MaterializationMetricStatus.METRIC_STATUS_AVAILABLE)
      .length;
  }, [context.rowCountsData]);

  if (!context.isReady) {
    return <></>;
  }

  const totalPeriods = context.rowCountsData.length;

  const coverage = format('.2%')(materializedPeriods / totalPeriods);

  const semiBold = (input: string | number) => {
    return <span style={{ fontWeight: '600' }}>{input}</span>;
  };

  const formatNumberOfResults = (value: number) => semiBold(format(',')(value));

  const startTimeString = semiBold(
    materializationPanelDateFormat(context.rowCountsData[0].date) +
      ' ' +
      materializationPanelTimeFormat(context.rowCountsData[0].date) +
      ' (UTC+0)'
  );

  const endTimeString = semiBold(
    materializationPanelDateFormat(context.rowCountsData[context.rowCountsData.length - 1].date) +
      ' ' +
      materializationPanelTimeFormat(context.rowCountsData[context.rowCountsData.length - 1].date) +
      ' (UTC+0)'
  );

  return (
    <div>
      <div>
        Materialization available for {formatNumberOfResults(materializedPeriods)} of{' '}
        {formatNumberOfResults(totalPeriods)} periods between {startTimeString} and {endTimeString} (
        {semiBold(coverage)} coverage).
      </div>

      {context.dataAreTruncated && (
        <>
          <Spacer size={'xs'} />

          <CallOut
            size={'s'}
            title={
              <>
                {' '}
                Note: Available metric data may not cover entire Feature View duration.{' '}
                <a href={TECTON_DOCUMENTATION_LINKS.DATA_QUALITY_METRICS} target="_blank">
                  Learn more.
                </a>
              </>
            }
            iconType={'warning'}
          ></CallOut>
          <Spacer size={'xs'} />
        </>
      )}
    </div>
  );
};

interface DataQualityMonitoringLegendIndicatorProps {
  status: MaterializationMetricStatus;
}

const DataQualityMonitoringLegendIndicator: FC<DataQualityMonitoringLegendIndicatorProps> = ({ status }) => {
  const colors = useDataQualityMonitoringThemeColors();

  return (
    <svg width={'1em'} height="1em">
      <rect width={'1em'} height="1em" fill={colors[status]} />
    </svg>
  );
};

export const DataQualityMonitoringLegend: FC = () => {
  const { theme } = useTectonTheme();
  const context = useContext(DataQualityMonitoringContext);

  const jobsByType = group(context.jobs, (job) => job.status);

  return (
    <div style={{ display: 'column', border: theme.border.thin, padding: theme.size.m, marginLeft: theme.size.s }}>
      Legend - Job Statuses
      <Spacer size={'xs'} />
      <FlexGroup gutterSize="s" alignItems="center">
        <FlexItem grow={0}>
          <DataQualityMonitoringLegendIndicator status={MaterializationMetricStatus.METRIC_STATUS_AVAILABLE} />
        </FlexItem>
        <FlexItem grow={0}>
          Available ({jobsByType.get(MaterializationMetricStatus.METRIC_STATUS_AVAILABLE)?.length ?? '-'})
        </FlexItem>
      </FlexGroup>
      <FlexGroup gutterSize="s" alignItems="center">
        <FlexItem grow={0}>
          <DataQualityMonitoringLegendIndicator status={MaterializationMetricStatus.METRIC_STATUS_NO_MATERIALIZATION} />
        </FlexItem>
        <FlexItem grow={0}>
          Pending ({jobsByType.get(MaterializationMetricStatus.METRIC_STATUS_NO_MATERIALIZATION)?.length ?? '-'})
        </FlexItem>
      </FlexGroup>
      <FlexGroup gutterSize="s" alignItems="center">
        <FlexItem grow={0}>
          <DataQualityMonitoringLegendIndicator status={MaterializationMetricStatus.METRIC_STATUS_ERROR} />
        </FlexItem>
        <FlexItem grow={0}>
          Error ({jobsByType.get(MaterializationMetricStatus.METRIC_STATUS_ERROR)?.length ?? '-'})
        </FlexItem>
      </FlexGroup>
      <FlexGroup gutterSize="s" alignItems="center">
        <FlexItem grow={0}>
          <DataQualityMonitoringLegendIndicator status={MaterializationMetricStatus.METRIC_STATUS_UNAVAILABLE} />
        </FlexItem>
        <FlexItem grow={0}>
          Not Available ({jobsByType.get(MaterializationMetricStatus.METRIC_STATUS_UNAVAILABLE)?.length ?? '-'})
        </FlexItem>
      </FlexGroup>
    </div>
  );
};

export const DataQualityMonitoringScrubberDiv: FC = () => {
  const rowRef = useRef<HTMLDivElement>(null);
  const { theme } = useTectonTheme();

  const size = useResizeObserver(rowRef?.current);

  const [width, setWidth] = useState<number>(0);

  useEffect(() => {
    if (rowRef.current) {
      setWidth(rowRef.current.getBoundingClientRect().width);
    }
  }, [rowRef, size]);

  return (
    <div style={{ display: 'column', width: '100%', paddingRight: theme.size.m }}>
      <div style={{ width: '100%' }} ref={rowRef}>
        <DataQualityMonitoringDateRangeSlider width={width} />
      </div>
    </div>
  );
};

const DataQualityMonitoringJobsRow: FC = () => {
  const context = useContext(DataQualityMonitoringContext);
  const rowRef = useRef<HTMLDivElement>(null);
  const windowSize = useWindowSize();

  const row = useMemo(() => {
    const data = context.jobs;
    return (
      <div ref={rowRef} style={{ margin: 0, padding: 0, height: '50px' }}>
        {!context.isReady && <ChartLoading />}
        {context.isReady && <DataQualityMonitoringJobIndicator data={data} width={rowRef?.current?.offsetWidth} />}
      </div>
    );
  }, [context.isReady, context.jobs, rowRef?.current?.offsetWidth]);

  useEffect(() => {
    if (rowRef.current && context.isReady) {
      context.tableWidthWasSet(rowRef.current.getBoundingClientRect().width);
    }
  }, [windowSize, rowRef, context.isReady]);

  return (
    <DataQualityMonitoringChartRow
      label={
        <StyledTooltip
          colorMode="light"
          label={'Jobs'}
          tooltipContent={'The batch materialization job associated with each interval.'}
        />
      }
      chart={row}
      comparisonCell={<DataQualityMonitoringJobsDateCell />}
    />
  );
};

const DataQualityMonitoringJobsDateCell: FC = () => {
  const context = useContext(DataQualityMonitoringContext);

  const defaultState = useMemo(() => {
    const validDates = context.rowCountsData.filter(
      (materializationEvent) => materializationEvent.status === MaterializationMetricStatus.METRIC_STATUS_AVAILABLE
    );

    if (validDates.length > 0) {
      return (
        <>
          <span style={{ fontWeight: 'bold' }}>
            {materializationPanelDateFormat(validDates[validDates.length - 1].date)}
          </span>{' '}
        </>
      );
    }

    return <></>;
  }, []);

  if (context.highlightedIndices && context.highlightedIndices[0] !== -1) {
    const matchingDatum = context.rowCountsData[context.highlightedIndices[0]];

    if (!matchingDatum) {
      return <></>;
    }

    return (
      <>
        <span style={{ fontWeight: 'bold' }}>{materializationPanelDateFormat(matchingDatum.date)}</span>{' '}
      </>
    );
  }

  return defaultState;
};

const DataQualityMonitoringRowCountsRow: FC = () => {
  const context = useContext(DataQualityMonitoringContext);
  const { theme } = useTectonTheme();

  const row = useMemo(() => {
    const chart = (
      <DataQualityMonitoringColumnChart data={context.rowCountsData} height={55} color={theme.colors.mediumShade} />
    );

    const label = (
      <StyledTooltip
        colorMode="light"
        label={'Row Counts'}
        tooltipContent={'The number of rows materialized per job interval (before any feature aggregations).'}
      />
    );
    return (
      <DataQualityMonitoringChartRow
        label={label}
        chart={chart}
        comparisonCell={<DataQualityMonitoringComparisonCell data={context.rowCountsData} />}
      />
    );
  }, [context.isReady]);

  if (!context.isReady) {
    return <></>;
  }

  return row;
};

const DataQualityMonitoringJoinKeysRow: FC = () => {
  const context = useContext(DataQualityMonitoringContext);
  const { theme } = useTectonTheme();

  const row = useMemo(() => {
    const chart = (
      <DataQualityMonitoringColumnChart data={context.joinKeys} height={55} color={theme.colors.mediumShade} />
    );

    const label = (
      <StyledTooltip
        colorMode="light"
        label={'Est. Join Keys'}
        tooltipContent={
          'The number of unique join keys output for the schedule interval. Join keys are calculated using an approximate count distinct algorithm and may have some error, typically <5%.'
        }
      />
    );
    return (
      <DataQualityMonitoringChartRow
        label={label}
        chart={chart}
        comparisonCell={<DataQualityMonitoringComparisonCell data={context.joinKeys} />}
      />
    );
  }, [context.isReady]);

  if (!context.isReady) {
    return <></>;
  }
  return row;
};

export const DataQualityMonitoringFeatureRows: FC = () => {
  const context = useContext(DataQualityMonitoringContext);
  const { theme } = useTectonTheme();

  const rows = useMemo(() => {
    if (context.featureMetrics) {
      return filterFeatureViewDataForTimeRange(context.featureMetrics!).map((feature) => {
        if (context.featureMetricsTypes?.get(feature.name)?.includes(context.selectedMetric)) {
          const data = calculatePercentagesByDateForFeatureViews(feature.data, context.rowCountsData);

          const chart = <DataQualityMonitoringColumnChart data={data} height={40} color={theme.colors.darkShade} />;

          const rowsNormalizedToRowCountData = clone(feature.data).map((datum, index) => {
            const normalizedValue =
              datum.value && context.rowCountsData[index].value ? datum.value / context.rowCountsData[index].value! : 0;

            return {
              ...datum,
              value: normalizedValue,
            };
          });

          return (
            <DataQualityMonitoringChartRow
              label={<StyledTooltip label={feature.name} tooltipContent={feature.name} colorMode={'light'} />}
              chart={chart}
              comparisonCell={
                <DataQualityMonitoringComparisonCell data={rowsNormalizedToRowCountData} formatAsPercent={true} />
              }
            />
          );
        }
      });
    } else {
      return [<></>];
    }
  }, [context.isReady, context.selectedMetric]);

  return (
    <div style={{ position: 'relative' }}>
      <DataQualityMetricsHotspot />
      {rows.map((row) => row ?? <></>)}
    </div>
  );
};

interface DataQualityMonitoringComparisonCellProps {
  data: MaterializationEvent[];
  formatAsPercent?: boolean;
}

const DataQualityMonitoringComparisonCell: FC<DataQualityMonitoringComparisonCellProps> = ({
  data,
  formatAsPercent,
}) => {
  const context = useContext(DataQualityMonitoringContext);

  const numberFormatter = formatAsPercent ? format('.2%') : format(',');

  if (!context.highlightedIndices) {
    const startIndex = clone(data)
      .map((datum) => datum.status)
      .lastIndexOf(MaterializationMetricStatus.METRIC_STATUS_AVAILABLE);

    if (startIndex && startIndex !== -1 && data[startIndex].value) {
      return <>{data[startIndex].value ? numberFormatter(data[startIndex].value!) : 0}</>;
    } else {
      return <>-</>;
    }
  }

  const endIndex = context.highlightedIndices[context.highlightedIndices.length - 1];
  if (endIndex === -1) {
    return <></>;
  }

  if (data[endIndex].status === MaterializationMetricStatus.METRIC_STATUS_NO_MATERIALIZATION) {
    return <>-</>;
  }

  return <>{data[endIndex].value ? numberFormatter(data[endIndex].value!) : 0}</>;
};

const DataQualityMonitoringHasNoDataWrapper = ({
  featureView,
  children,
}: {
  featureView: FeatureViewFCO;
  children: React.ReactNode;
}) => {
  const context = useContext(DataQualityMonitoringContext);

  if (
    featureView[FeatureViewFCOFields.FEATURE_VIEW_TYPE] === FeatureViewFCOType.BATCH &&
    featureView[FeatureViewFCOFields.IS_MATERIALIZATION_ENABLED] !== true
  ) {
    return (
      <EmptyTab
        title={'Data Quality Metrics Not Available'}
        message={
          <p>
            Data Quality Metrics are only available for Batch Feature Views where materialization is enabled.{' '}
            {featureView.name} does not have materialization enabled, so Data Quality Metrics aren't calculated.
          </p>
        }
      />
    );
  }

  if (
    featureView[FeatureViewFCOFields.FEATURE_VIEW_TYPE] === FeatureViewFCOType.STREAM &&
    featureView[FeatureViewFCOFields.IS_OFFLINE_MATERIALIZATION_ENABLED] !== true
  ) {
    return (
      <EmptyTab
        title={'Data Quality Metrics Not Available'}
        message={
          <>
            {' '}
            Data Quality Metrics are only available for Streaming Feature Views where offline materialization is
            enabled. {featureView.name} does not have offline materialization enabled, so Data Quality Metrics aren't
            calculated.
          </>
        }
      />
    );
  }

  return <>{children}</>;
};

const DataQualityMetricsHotspot = () => {
  const context = useContext(DataQualityMonitoringContext);

  const hotspotMouseMove: MouseEventHandler<HTMLDivElement> = (event) => {
    const relativeXCoordinate = event.nativeEvent.offsetX - context.bandWidth;

    const distanceFromStart = -(context.canvasTranslate - relativeXCoordinate);
    const relativeCoordinate = context.chartScale.range()[0] + distanceFromStart;
    const roughDate = context.chartScale.invert(relativeCoordinate);

    const matchingDate = getNearestEventToDate(
      roughDate,
      context.rowCountsData.map((datum) => datum.date)
    );

    const matchingIndex = getIndexForDate(
      matchingDate,
      context.rowCountsData.map((datum) => datum.date)
    );

    if (matchingIndex) {
      context.handleIndicesSelection([matchingIndex]);
    }

    const matchingEvent = getMatchingEventForDate(matchingDate, context.rowCountsData);
    if (matchingEvent) {
      context.externalHighlightsWereSelected([matchingEvent]);
    }
  };

  const hostpotMouseOut = () => {
    context.externalHighlightsWereSelected(undefined);
    context.handleIndicesSelection(undefined);
  };

  const Hotspot = styled.div`
    position: absolute;
    z-index: 5;
    background-color: black;
    opacity: 0;
    height: 125px;
    left: 12.5em;
    width: ${context.chartColumnWidth}px;
    height: 100%;
  `;

  if (context.isReady) return <Hotspot onMouseMove={hotspotMouseMove} onMouseOut={hostpotMouseOut} />;
  return <></>;
};

export const DataQualityMonitoringPanelViewLevelMetrics = () => {
  return (
    <div style={{ position: 'relative' }}>
      <DataQualityMetricsHotspot />
      <DataQualityMonitoringJobsRow />
      <DataQualityMonitoringRowCountsRow />
      <DataQualityMonitoringJoinKeysRow />
    </div>
  );
};

const DataQualityMonitoringMetrics: FC<DataQualityMonitoringPanelProps> = ({ featureView }) => {
  return (
    <DataQualityMonitoringHasNoDataWrapper featureView={featureView}>
      <FCOPanel title={<h3>Materialization Coverage</h3>}>
        <CardInnerTitle>
          <h4>Coverage Overview</h4>
        </CardInnerTitle>
        <DataQualityMonitoringStatusRow />
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 17em', alignItems: 'center' }}>
          <DataQualityMonitoringScrubberDiv />
          <DataQualityMonitoringLegend />
        </div>
        <Spacer size={'m'} />
        <CardInnerTitle>
          <h4>View-Level Metrics</h4>
        </CardInnerTitle>
        <DataQualityMonitoringPanelViewLevelMetrics />
      </FCOPanel>
      <Spacer size={'xl'} />
      <FCOPanel title={<h3>Feature View-Level Metrics</h3>}>
        <DataQualityMetricSelector />
        <Spacer size={'s'} />
        <DataQualityMonitoringFeatureRows />
      </FCOPanel>
    </DataQualityMonitoringHasNoDataWrapper>
  );
};

export default DataQualityMonitoringMetrics;
