import React, { FC, useState } from 'react';
import { Popover } from '@tecton';
import { css } from '@emotion/css';

interface StyledTooltipProps {
  label: React.ReactNode;
  tooltipContent: React.ReactNode;
  colorMode: 'light' | 'dark';
  anchorPosition?:
    | 'upCenter'
    | 'upLeft'
    | 'upRight'
    | 'downCenter'
    | 'downLeft'
    | 'downRight'
    | 'leftCenter'
    | 'leftUp'
    | 'leftDown'
    | 'rightCenter'
    | 'rightUp'
    | 'rightDown';
}

const StyledTooltip: FC<StyledTooltipProps> = ({
  label,
  tooltipContent,
  colorMode = 'light',
  anchorPosition = 'upCenter',
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const popoverStyle = css`
    background-color: ${colorMode === 'light' ? 'white' : '#343741'};
    color: ${colorMode === 'light' ? '#343741' : 'white'};
    border-radius: 6px;
    line-height: 13.31px;
    padding: 11px;

    .euiPopover__arrow::before {
      border-top-color: ${colorMode === 'light' ? 'white' : '#343741'};
    }
  `;

  return (
    <Popover
      isOpen={isOpen}
      button={<span style={{ cursor: 'pointer' }}>{label}</span>}
      onMouseOver={() => {
        setIsOpen(true);
      }}
      anchorPosition={anchorPosition}
      onMouseOut={() => {
        setIsOpen(false);
      }}
      closePopover={() => {
        return;
      }}
      panelClassName={popoverStyle}
    >
      <div style={{ maxWidth: '24em' }}>{tooltipContent}</div>
    </Popover>
  );
};

export default StyledTooltip;
