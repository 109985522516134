import React from 'react';
import { HorizontalRule, useTectonTheme } from '@tecton';

const ConfirmationButtonGroup = ({
  children,
  helpText,
  feedback,
}: {
  children: React.ReactNode;
  helpText?: React.ReactNode;
  feedback?: React.ReactNode;
}) => {
  const { theme } = useTectonTheme();

  return (
    <div
      style={{
        padding: `${theme.size.l} 0 ${theme.size.xxxl}`,
      }}
    >
      <HorizontalRule margin="s" />
      {helpText && (
        <div className="euiFormHelpText" style={{ textAlign: 'right', marginBottom: '16px' }}>
          {helpText}
        </div>
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row-reverse',
          gap: theme.size.m,
          marginBottom: theme.size.m,
        }}
      >
        {children}
      </div>
      {feedback}
    </div>
  );
};

export default ConfirmationButtonGroup;
