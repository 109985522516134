import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useClusterAdminInfo } from '../../feature/access-control/query';
import { setCallerIsAdmin } from '../../feature/auth/actions';

const useCallerIsAdmin = () => {
  const clusterAdminInfoState = useClusterAdminInfo();
  const dispatch = useDispatch();

  useEffect(() => {
    if (clusterAdminInfoState.isSuccess && clusterAdminInfoState.data) {
      const payload = {
        type: setCallerIsAdmin.success().type,
        payload: { callerIsAdmin: clusterAdminInfoState.data.isAdmin },
      };

      dispatch(payload);
    }
  }, [clusterAdminInfoState.isSuccess, clusterAdminInfoState.data]);

  return { clusterAdminInfoState };
};

export default useCallerIsAdmin;
