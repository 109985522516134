import React from 'react';
import { Title, useTectonTheme } from '@tecton';
import { css } from '@emotion/css';

const CardInnerTitle = ({ children }: { children: React.ComponentProps<typeof Title>['children'] }) => {
  const { theme } = useTectonTheme();

  const titleClass = css`
    margin-bottom: ${theme.size.m};
    border-bottom: 1px solid ${theme.border.color};
    color: ${theme.colors.darkShade};
    font-weight: 500;
  `;

  return (
    <Title size="xxs" className={titleClass}>
      {children}
    </Title>
  );
};

export default CardInnerTitle;
