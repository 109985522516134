import {
  mockUpdateUserDeploymentSettings,
  mockGetUserDeploymentSettings,
  mockGetInteralSparkClusterStatus,
  mockGetOnboardingStatus,
} from './DPO';

export const mockHandlers = [
  mockUpdateUserDeploymentSettings,
  mockGetUserDeploymentSettings,
  mockGetInteralSparkClusterStatus,
  mockGetOnboardingStatus,
];
