import { syntheticData } from './static/synthetic-data';

const OWNER_EMAILS = [
  'james@tecton.ai',
  'bob@tecton.ai',
  'alice@tecton.ai',
  'sid@tecton.ai',
  'karen@tecton.ai',
  'tracy@tecton.ai',
];
const VDS_TYPES = ['s3', 'mysql'];

export default class DemoUtils {
  static owner(seedStr) {
    return DemoUtils._randomElement(OWNER_EMAILS, seedStr);
  }

  static statsSummaryFromName(name) {
    if (syntheticData[name] && syntheticData[name]['stats']) {
      return syntheticData[name]['stats'];
    }
    const random_number = DemoUtils._random(DemoUtils._hashString(name));
    const mean =
      random_number < 0.5 ? DemoUtils._randInt(random_number, 10000) : DemoUtils._randInt(random_number, 1000);
    return {
      mean: mean,
      stddev: random_number < 0.2 ? mean * 0.23 : mean * random_number,
      min: random_number < 0.4 ? 0 : mean * 0.1 * DemoUtils._randInt(random_number, 5),
      max: random_number < 0.7 ? mean * 2.13 * random_number : mean * 2 * random_number,
      nulls: random_number < 0.2 ? DemoUtils._randInt(random_number, 10) : 0,
    };
  }

  /**Returns [data, ticks]. When DiscreteHistogramChart is refactored,
   * ticks will be passed from upstream instead.
   */
  static histogramDataFromName(name) {
    if (syntheticData[name] && syntheticData[name]['histogram']) {
      return [syntheticData[name]['histogram'], true];
    }
    const type = DemoUtils._random(DemoUtils._hashString(name)) < 0.5 ? 'uniform' : 'normal';
    const random_number = DemoUtils._randInt(DemoUtils._hashString(name), 100);
    var data = [];
    for (var i = 0; i < 2000; i++) {
      data.push(DemoUtils._random(DemoUtils._hashString(name) + i));
    }

    switch (type) {
      case 'uniform':
        data = DemoUtils._histogram(data, 0.05);
        break;
      case 'normal':
        data = data.map((value) => {
          return DemoUtils._NormSInv(value);
        });
        data = DemoUtils._histogram(data, 0.3);
        break;
      default:
    }

    data = data.map((value) => {
      return { y: random_number * value };
    });
    return [data, false];
  }

  static vdsType(seedStr) {
    return DemoUtils._randomElement(VDS_TYPES, seedStr);
  }

  static featureServiceIsEnabled(seedStr) {
    return DemoUtils._random(DemoUtils._hashString(seedStr)) < 0.3;
  }

  static dependentDataSources() {
    return ['content_rec', 'content_display', 'page_view', 'content', 'questions'];
  }

  static _randomElement(arr, seedStr) {
    return arr[DemoUtils._randInt(DemoUtils._hashString(seedStr), arr.length)];
  }

  static _hashString(str) {
    var hash = 0;
    if (str.length === 0) return hash;
    for (let i = 0; i < str.length; i++) {
      let chr = str.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  }

  static _random(seed) {
    let x = Math.sin(seed) * 10000;
    return x - Math.floor(x);
  }

  static _randInt(seed, range) {
    return Math.floor(DemoUtils._random(seed) * range);
  }

  static _histogram(data, size) {
    var length = data.length;

    var min = data[0];
    var max = data[1];

    for (var i = 0; i < length; i++) {
      var item = data[i];
      if (item < min) min = item;
      else if (item > max) max = item;
    }

    var bins = Math.ceil((max - min) / size);

    var histogram = new Array(bins);

    for (var z = 0; z < bins; z++) histogram[z] = 0;

    for (var x = 0; x < length; x++) histogram[Math.floor((data[x] - min) / size)]++;

    return histogram;
  }

  static _NormSInv(p) {
    var a1 = -39.6968302866538,
      a2 = 220.946098424521,
      a3 = -275.928510446969;
    var a4 = 138.357751867269,
      a5 = -30.6647980661472,
      a6 = 2.50662827745924;
    var b1 = -54.4760987982241,
      b2 = 161.585836858041,
      b3 = -155.698979859887;
    var b4 = 66.8013118877197,
      b5 = -13.2806815528857,
      c1 = -7.78489400243029e-3;
    var c2 = -0.322396458041136,
      c3 = -2.40075827716184,
      c4 = -2.54973253934373;
    var c5 = 4.37466414146497,
      c6 = 2.93816398269878,
      d1 = 7.78469570904146e-3;
    var d2 = 0.32246712907004,
      d3 = 2.445134137143,
      d4 = 3.75440866190742;
    var p_low = 0.02425,
      p_high = 1 - p_low;
    var q, r;
    var retVal;

    if (p < 0 || p > 1) {
      alert('NormSInv: Argument out of range.');
      retVal = 0;
    } else if (p < p_low) {
      q = Math.sqrt(-2 * Math.log(p));
      retVal =
        (((((c1 * q + c2) * q + c3) * q + c4) * q + c5) * q + c6) / ((((d1 * q + d2) * q + d3) * q + d4) * q + 1);
    } else if (p <= p_high) {
      q = p - 0.5;
      r = q * q;
      retVal =
        ((((((a1 * r + a2) * r + a3) * r + a4) * r + a5) * r + a6) * q) /
        (((((b1 * r + b2) * r + b3) * r + b4) * r + b5) * r + 1);
    } else {
      q = Math.sqrt(-2 * Math.log(1 - p));
      retVal =
        -(((((c1 * q + c2) * q + c3) * q + c4) * q + c5) * q + c6) / ((((d1 * q + d2) * q + d3) * q + d4) * q + 1);
    }

    return retVal;
  }

  static getFSResponseValue(key) {
    if (key.includes('similarity') || key.includes('distance') || key.includes('_rate')) {
      return DemoUtils._random(DemoUtils._hashString(key));
    } else if (key.includes('word_length') || key.includes('typo_count') || key.includes('content_topics')) {
      return DemoUtils._randInt(DemoUtils._hashString(key), 5) + 4;
    } else if (key === 'timestamp') {
      const random_date = new Date() - DemoUtils._randInt(DemoUtils._hashString(key), 10000);
      return random_date.toString();
    } else {
      return DemoUtils._randInt(DemoUtils._hashString(key), 10000);
    }
  }
}
