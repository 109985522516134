import React, { FC } from 'react';
import { ServingState } from '../../../../types/tecton_proto/data/serving_status';
import { useTectonTheme } from '@tecton/ComponentRedesign';

interface ServingStatusBarProps {
  x: number;
  width: number;
  state: ServingState;
  deemphasized?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

const ServingStatusBar: FC<ServingStatusBarProps> = ({ x, width, state, deemphasized, onMouseEnter, onMouseLeave }) => {
  const { theme } = useTectonTheme();
  let barColor;
  let label;
  let textColor = theme.colors.emptyShade;

  switch (state) {
    case ServingState.SERVING_STATE_DISABLED:
      barColor = theme.categoryColorPairs[2].behindText;
      textColor = theme.categoryColorPairs[2].text;
      label = 'Disabled';
      break;
    case ServingState.SERVING_STATE_ERROR:
      barColor = theme.colors.warning;
      label = 'Error';
      break;
    case ServingState.SERVING_STATE_NOT_ENOUGH_DATA:
      barColor = theme.colors.mediumShade;
      label = 'Not Enough Data';
      break;
    case ServingState.SERVING_STATE_OK:
      barColor = theme.categoryColorPairs[0].behindText;
      textColor = theme.categoryColorPairs[0].text;
      label = 'Success';
      break;
    case ServingState.SERVING_STATE_PENDING:
      barColor = theme.colors.lightestShade;
      textColor = theme.colors.text;
      label = 'Pending';
      break;
    case ServingState.SERVING_STATE_RUNNING:
      barColor = theme.categoryColorPairs[1].behindText;
      textColor = theme.categoryColorPairs[1].text;
      label = 'Running';
      break;
    case ServingState.SERVING_STATE_UNSPECIFIED:
      barColor = theme.colors.lightShade;
      textColor = theme.colors.subduedText;
      label = 'Unspecified';
      break;
  }

  return (
    <g
      style={{ cursor: 'pointer' }}
      opacity={deemphasized ? 0.5 : 1}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <rect height={20} x={x} y={2} width={width} fill={barColor} rx={6} ry={6} clipPath="url(#clipFrame)" />
      <text x={x + 4} y={12} fill={textColor} dominantBaseline="middle" fontSize={'11px'} clipPath="url(#clipFrame)">
        {label}
      </text>
    </g>
  );
};

export default ServingStatusBar;
