import React, { FC } from 'react';
import { useTectonTheme, DatePicker } from '@tecton/ComponentRedesign';
import styled from '@emotion/styled';
import moment from 'moment-timezone';

interface DateSelectorFilterProps {
  startDate: Date;
  endDate: Date;
  setStartDate: (date: Date) => void;
  setEndDate: (date: Date) => void;
}

const DateSelectorFilter: FC<DateSelectorFilterProps> = ({ startDate, endDate, setStartDate, setEndDate }) => {
  const { theme } = useTectonTheme();

  const PanelLayout = styled.div`
    display: grid;
    grid-template-rows: 1fr auto;
    height: 100%;
  `;

  const PanelInterior = styled.div`
    display: flex;
    gap: ${theme.padding.l};
    flex-direction: column;
  `;

  return (
    <PanelLayout>
      <PanelInterior>
        <DatePicker
          label="Start"
          date={moment(startDate)}
          setDate={(date) => {
            setStartDate(date.toDate());
          }}
          dateAndTime
        />
        <DatePicker
          label="End"
          date={moment(endDate)}
          setDate={(date) => {
            setEndDate(date.toDate());
          }}
          dateAndTime
        />
      </PanelInterior>
    </PanelLayout>
  );
};

export default DateSelectorFilter;
