import React, { FC } from 'react';
import { FCO } from '../../core/types/fcoTypes';
import { EmptyValue } from '../@tecton';

interface FCOOwnerBadgeProps {
  fco?: FCO;
  option?: string;
}

const FCOOwnerBadge: FC<FCOOwnerBadgeProps> = ({ fco, option }) => {
  if (fco && (fco.owner === undefined || fco.owner === '' || fco.owner === 'n/a')) {
    return <EmptyValue />;
  }

  if (fco && fco.owner) {
    return <>{fco.owner}</>;
  }

  if (option === 'n/a') {
    return <EmptyValue />;
  }

  return <>{option}</>;
};

export default FCOOwnerBadge;
