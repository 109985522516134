export const syntheticData = {
  gender: {
    histogram: [
      {
        x: 'male',
        y: 2559,
      },
      {
        x: 'female',
        y: 4038,
      },
      {
        x: 'not specified',
        y: 292,
      },
    ],
    stats: {
      unique: 3,
      nulls: 0,
    },
  },
  birthdate: {
    histogram: [
      {
        x: '01',
        y: 25 * 40,
      },
      {
        x: '02',
        y: 30 * 40,
      },
      {
        x: '03',
        y: 24 * 40,
      },
      {
        x: '04',
        y: 29 * 40,
      },
      {
        x: '05',
        y: 31 * 40,
      },
      {
        x: '06',
        y: 27 * 40,
      },
      {
        x: '07',
        y: 26 * 40,
      },
      {
        x: '08',
        y: 23 * 40,
      },
      {
        x: '09',
        y: 29 * 40,
      },
      {
        x: '10',
        y: 30 * 40,
      },
      {
        x: '11',
        y: 25 * 40,
      },
      {
        x: '12',
        y: 23 * 40,
      },
    ],
    stats: {
      unique: 12,
      nulls: 0,
    },
  },
  language: {
    histogram: [
      {
        x: 'English',
        y: 2690,
      },
      {
        x: 'Spanish',
        y: 430,
      },
      {
        x: 'French',
        y: 210,
      },
      {
        x: 'German',
        y: 200,
      },
      {
        x: 'Italian',
        y: 190,
      },
      {
        x: 'Swedish',
        y: 160,
      },
      {
        x: 'Chinese',
        y: 10,
      },
    ],
    stats: {
      unique: 7,
      nulls: 0,
    },
  },
  avg_word_length: {
    stats: {
      mean: 5,
      stddev: 1.4127,
      min: 1,
      max: 9,
      nulls: 290,
    },
  },
};
