import React, { FC } from 'react';
import { Badge, useTectonTheme } from '.';
import { FeatureViewFCO, FeatureViewFCOType } from '../../core/types/fcoTypes';
import { ColorPair } from './TectonThemeProvider';
import { typeLabelMap } from '../redesign/jobs/FeatureViewTypeBadge';

interface FeatureViewTypeBadgeProps {
  featureView?: FeatureViewFCO;
  type?: FeatureViewFCOType;
}

const FeatureViewTypeBadge: FC<FeatureViewTypeBadgeProps> = ({ featureView, type }) => {
  const { theme } = useTectonTheme();
  const categoryColors = theme.color.light.semanticColorPair;

  const dataSourceTypeColorPairs: Record<FeatureViewFCOType, ColorPair> = {
    [FeatureViewFCOType.BATCH]: categoryColors.NEUTRAL,
    [FeatureViewFCOType.BATCH_WINDOW_AGGREGATE]: categoryColors.PENDING,
    [FeatureViewFCOType.STREAM]: categoryColors.WARNING,
    [FeatureViewFCOType.STREAM_WINDOW_AGGREGATE]: categoryColors.SUCCESS,
    [FeatureViewFCOType.REALTIME]: categoryColors.DANGER,
    [FeatureViewFCOType.FEATURE_TABLE]: categoryColors.PENDING,
    [FeatureViewFCOType.UNKNOWN]: categoryColors.NEUTRAL,
    [FeatureViewFCOType.PUSH_WITH_BATCH]: categoryColors.WARNING,
    [FeatureViewFCOType.PUSH_NO_BATCH]: categoryColors.WARNING,
  };

  if (featureView) {
    const colorPair = dataSourceTypeColorPairs[featureView.featureViewType];
    return (
      <Badge color={colorPair.background}>
        {<span style={{ color: colorPair.foreground }}>{typeLabelMap[featureView.featureViewType]}</span>}
      </Badge>
    );
  }

  if (type) {
    const colorPair = dataSourceTypeColorPairs[type];
    return (
      <div style={{ pointerEvents: 'none' }}>
        <Badge color={colorPair.background}>
          {<span style={{ color: colorPair.foreground }}>{typeLabelMap[type]}</span>}
        </Badge>
      </div>
    );
  }

  return <></>;
};

export default FeatureViewTypeBadge;
