import React, { FC } from 'react';
import { EuiPopover } from '@elastic/eui';
import { css } from '@emotion/css';

import styled from '@emotion/styled';
import { FilterButton } from '..';

export type FilterSelector = {
  hidden?: boolean;
  label: string;
  numFilters: number | undefined;
  hasActiveFilters: boolean;
  numActiveFilters: number | undefined;
  filterElement: React.ReactNode;
};

interface ComboFilterDropdownProps {
  buttonLabel: string;
  filterTypes: Record<string, FilterSelector>;
  isOpen: boolean | undefined;
  selectedFilter: string | undefined;
  setIsOpen: (isOpen: boolean) => void;
  setSelectedFilter: (selectedFilter: string | undefined) => void;
  numberOfActiveFilters: number | undefined;
  clearAllFilterCallback?: () => void;
  clearAllText?: string;
  hideEmpty?: boolean;
  clearAllIsDisabled?: boolean;
  resetActiveFilter: (key: string) => void;
}

const arrowSvg = (
  <svg width="4" height="8" viewBox="0 0 4 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.666504 6.66665L3.33317 3.99998L0.666504 1.33331"
      stroke="#525866"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const ItemWrapper = styled.div<{ isActive: boolean }>(({ theme, isActive }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '6px 12px 6px 16px',
  cursor: 'pointer',
  backgroundColor: isActive ? theme.colors.body : theme.colors.emptyShade,
  color: isActive ? theme.colors.fullShade : theme.colors.text,
  fontWeight: theme.font.weight.regular,
  borderRadius: theme.border.radius.medium,
  lineHeight: '20px', // 142.857%
  ':hover': {
    backgroundColor: theme.colors.body,
    color: theme.colors.title,
  },
}));

const Notification = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.fullShade,
  color: theme.colors.emptyShade,
  borderRadius: '32px', // One-off design specification
  padding: '2px 7px', // One-off design specification
  fontSize: theme.font.fontSizes.xs,
}));

const FilterCategoryListItem: FC<{
  option: FilterSelector;
  onClick: () => void;
  isActive?: boolean;
}> = ({ option, onClick, isActive = false }) => {
  return (
    <ItemWrapper onClick={onClick} isActive={isActive}>
      <div>{option.label}</div>
      <div>{option.hasActiveFilters ? <Notification>{option.numActiveFilters}</Notification> : arrowSvg}</div>
    </ItemWrapper>
  );
};

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 220px;
  padding: 6px 12px 6px 16px;
  min-height: 360px;
`;

const FiltersListWrapper: FC<{ children: React.ReactNode }> = ({ children }) => {
  return <BodyWrapper>{children}</BodyWrapper>;
};

const PopoverContentWrapper = styled.div<{ isPanelOpen: boolean }>`
  display: grid;
  transition: width 0.2s ease-in-out;
  width: ${({ isPanelOpen }) => (isPanelOpen ? '640px' : '240px')};
  grid-template-rows: ${({ isPanelOpen }) => (isPanelOpen ? '1fr auto' : 'auto')};
`;

const ActivePanelWrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  padding: 16px;
  border-left: ${({ theme }) => theme.border.thin};
`;

const FiltersFooterWrapper = styled.div`
  padding: 12px 16px 8px 16px;

  display: flex;
  padding: 12px 16px 8px 16px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  border-top: ${({ theme }) => theme.border.thin};
`;

const FiltersContent = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
`;

const Reset = styled.div<{ filterCount: number }>(({ theme, filterCount }) => ({
  color: filterCount === 0 ? theme.colors.subduedText : theme.colors.darkestShade,
  fontSize: theme.font.fontSizes.xs,
  fontWeight: theme.font.weight.semiBold,
  lineHeight: '20px', // 166.667%
  cursor: filterCount === 0 ? 'not-allowed' : 'pointer', // Use 'not-allowed' for disabled cursor
  height: '32px',
  display: 'flex',
  alignItems: 'center',
}));

const FiltersFooter: FC<{ filterCount?: number; resetCallback?: () => void }> = ({
  filterCount = 0,
  resetCallback,
}) => {
  const linkText = filterCount === 0 ? 'Reset All Filters' : `Reset All Filters (${filterCount})`;

  return (
    <Reset
      filterCount={filterCount}
      onClick={() => {
        resetCallback && resetCallback();
      }}
    >
      {linkText}
    </Reset>
  );
};

const SelectedFilterFooter: FC<{ filterCount?: number; resetCallback?: () => void }> = ({
  filterCount,
  resetCallback,
}) => {
  return (
    <Reset
      filterCount={filterCount || 0}
      onClick={() => {
        filterCount && filterCount > 0 && resetCallback && resetCallback();
      }}
    >
      Reset
    </Reset>
  );
};

const ComboFilterDropdown = ({
  filterTypes,
  numberOfActiveFilters,
  clearAllFilterCallback,
  isOpen,
  selectedFilter,
  setIsOpen,
  setSelectedFilter,
  resetActiveFilter,
}: ComboFilterDropdownProps) => {
  const isPanelOpen = !!selectedFilter;

  const filterKeys = Object.keys(filterTypes);
  const displayedFilterKeys = filterKeys.filter((key) => !filterTypes[key]?.hidden);

  const hasActiveFilters = numberOfActiveFilters && numberOfActiveFilters > 0 ? true : false;

  const popoverStyle = css`
    transition: none !important;
  `;
  // UseComponentDidMountLogger('combofilterdropdown');
  return (
    <EuiPopover
      button={
        <FilterButton
          isDisabled={displayedFilterKeys.length === 0}
          isActive={hasActiveFilters}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          quantity={hasActiveFilters ? numberOfActiveFilters : filterKeys.length}
          hasActiveFilters={hasActiveFilters}
        />
      }
      data-testid="combo-filter-dropdown"
      isOpen={isOpen}
      closePopover={() => setIsOpen(false)}
      panelPaddingSize="none"
      panelProps={{ css: popoverStyle }}
    >
      <PopoverContentWrapper isPanelOpen={isPanelOpen} key={'filtersDiv'}>
        <FiltersContent>
          <FiltersListWrapper>
            {displayedFilterKeys.map((key) => {
              const option = filterTypes[key];

              return (
                <FilterCategoryListItem
                  option={option}
                  onClick={() => {
                    setSelectedFilter(key);
                  }}
                  key={key}
                  isActive={key === selectedFilter}
                />
              );
            })}
          </FiltersListWrapper>
          {isPanelOpen && selectedFilter && (
            <ActivePanelWrapper>
              <div></div>
              <div>{filterTypes[selectedFilter].filterElement}</div>
            </ActivePanelWrapper>
          )}
        </FiltersContent>
        <FiltersFooterWrapper>
          <FiltersFooter filterCount={numberOfActiveFilters ?? 0} resetCallback={clearAllFilterCallback} />
          {selectedFilter && (
            <SelectedFilterFooter
              filterCount={
                selectedFilter && filterTypes[selectedFilter] ? filterTypes[selectedFilter].numActiveFilters : 0
              }
              resetCallback={() => {
                selectedFilter && resetActiveFilter(selectedFilter);
              }}
            />
          )}
        </FiltersFooterWrapper>
      </PopoverContentWrapper>
    </EuiPopover>
  );
};

export default ComboFilterDropdown;
