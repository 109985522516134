import React, { FC } from 'react';
import styled from '@emotion/styled';

interface HeaderLayoutProps {
  breadcrumbs: React.ReactNode;
  subtitle?: React.ReactNode;
  tabs?: React.ReactNode;
}

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 24px;
  border-radius: ${(props) => props.theme.border.radius.viewBoundaryTop};
  background-color: ${(props) => props.theme.colors.emptyShade};
  padding-bottom: 16px;
`;

const BreadcrumbWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.emptyShade};
  padding-top: 20px;
  padding-bottom: 20px;
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  background-color: ${(props) => props.theme.colors.emptyShade};
`;

const HeaderLayout: FC<HeaderLayoutProps> = ({ breadcrumbs, subtitle, tabs }) => {
  return (
    <HeaderWrapper>
      <BreadcrumbWrapper>{breadcrumbs}</BreadcrumbWrapper>
      <FlexWrapper>
        {subtitle && <div>{subtitle}</div>}
        {tabs && <div>{tabs}</div>}
      </FlexWrapper>
    </HeaderWrapper>
  );
};

export default HeaderLayout;
