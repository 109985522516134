import React from 'react';
import { EuiProvider, useEuiFontSize, useEuiTheme } from '@elastic/eui';
import { TectonGlobalStyles } from './_eui_components';
import { Global, css } from '@emotion/react';
import { FcoTransitionType } from '../../types/tecton_proto/data/state_update';

export interface ColorPair {
  background: string;
  foreground: string;
}

export interface ThemeExtensions {
  categoryColors: ColorPair[];
}

// https://www.figma.com/file/qb8jZTnOd2bAJgpVcRzX0b/Tecton-Theme---Final-2023?type=design&node-id=60-2&mode=design&t=xvhfjfHpmRHXoSqL-0

export enum SematicTextColor {
  DEFAULT_LIGHT_BLUE = '#063F60',
  NEUTRAL_LIGHT_GRAY = '#343741',
  PENDING_LIGHT_PURPLE = '#11174E',
  SUCCESS_LIGHT_GREEN = '#1D4F4F',
  WARNING_LIGHT_ORANGE = '#662408',
  DANGER_LIGHT_PINK = '#7d0d28',
}

export enum SematicBackgroundColor {
  DEFAULT_LIGHT_BLUE = '#A1DDFF',
  NEUTRAL_LIGHT_GRAY = '#D3DAE6',
  PENDING_LIGHT_PURPLE = '#CBCAFF',
  SUCCESS_LIGHT_GREEN = '#BBF3E1',
  WARNING_LIGHT_ORANGE = '#FFDDAA',
  DANGER_LIGHT_PINK = '#FFBDC9',
}

type SemanticColor = 'DEFAULT' | 'NEUTRAL' | 'PENDING' | 'SUCCESS' | 'WARNING' | 'DANGER';

// used in multiple places
export const semanticColorPair: Record<SemanticColor, ColorPair> = {
  DEFAULT: {
    background: SematicBackgroundColor.DEFAULT_LIGHT_BLUE,
    foreground: SematicTextColor.DEFAULT_LIGHT_BLUE,
  },
  NEUTRAL: {
    background: SematicBackgroundColor.NEUTRAL_LIGHT_GRAY,
    foreground: SematicTextColor.NEUTRAL_LIGHT_GRAY,
  },
  PENDING: {
    background: SematicBackgroundColor.PENDING_LIGHT_PURPLE,
    foreground: SematicTextColor.PENDING_LIGHT_PURPLE,
  },
  SUCCESS: {
    background: SematicBackgroundColor.SUCCESS_LIGHT_GREEN,
    foreground: SematicTextColor.SUCCESS_LIGHT_GREEN,
  },
  WARNING: {
    background: SematicBackgroundColor.WARNING_LIGHT_ORANGE,
    foreground: SematicTextColor.WARNING_LIGHT_ORANGE,
  },
  DANGER: {
    background: SematicBackgroundColor.DANGER_LIGHT_PINK,
    foreground: SematicTextColor.DANGER_LIGHT_PINK,
  },
};

const colorTextCategories = [
  '#103755',
  '#102B65',
  '#15268d',
  '#200039',
  '#1d0032',
  '#0C2B99',
  '#7A1D00',
  '#073171',
  '#001B43',
  '#500026',
  '#012E34',
  '#0D1509',
  '#430162',
  '#0E2420',
  '#d9df91',
  '#a24514',
];

const colorBackgroundCategories = [
  '#A2DDFF',
  '#c2e8f3',
  '#D1D9FF',
  '#EFDAFF',
  '#FFCABE',
  '#D1D9FF',
  '#FFE9B0',
  '#c2e8f3',
  '#BADEFF',
  '#FFDAF0',
  '#C4F2F9',
  '#D0F8BD',
  '#FCD8FF',
  '#ABDAD1',
  '#DEE39A',
  '#a24514',
];

// TODO: Once we've settled on actual color scheme, we can use this enum to define specific colors for Tecton
enum NamedColors {
  // Brand and UI Colors
  PRIMARY_BLUE = '#0077cc',
  TECTON_RED = '#bc1039',
  // Any other named / UI colors we want to add

  // Colors by outcome
  WARNING = '#ffa666',
  ERROR = '#bc1039',
  SUCCESS = '#00bfb3',
  UNKNOWN = '#d3dae6',

  // Colors by action
  ENABLED = '',
  DISABLED = '',

  // Colors by process state
  PROGRESS_HEALTHY = '',
  PROGRESS_PENDING = '',
  PROGRESS_IN_PROGRESS = '',
  PROGRESS_ERROR = '',

  // Colors by FCO
  FCO_DATA_SOURCE = '',
  FCO_ENTITY = '',
  FCO_TRANSFORMATION = '',
  FCO_FEATURE_VIEW = '',
  FCO_FEATURE_SERVICE = '',
  FCO_FEATURE = '',
  FCO_DATASET = '',

  // Colors by category
  CATEGORY_ONE = '',
  CATEGORY_TWO = '',
  CATEGORY_THREE = '',
  CATEGORY_FOUR = '',
  CATEGORY_FIVE = '',
  CATEGORY_SIX = '',
  CATEGORY_SEVEN = '',
  CATEGORY_EIGHT = '',
  CATEGORY_NINE = '',
  CATEGORY_TEN = '',

  // Data visualization colors
  DATA_VISUALIZATION_ONE = '',
  DATA_VISUALIZATION_TWO = '',
  DATA_VISUALIZATION_THREE = '',
  DATA_VISUALIZATION_FOUR = '',
  DATA_VISUALIZATION_FIVE = '',
  DATA_VISUALIZATION_SIX = '',
  DATA_VISUALIZATION_SEVEN = '',
  DATA_VISUALIZATION_EIGHT = '',
  DATA_VISUALIZATION_NINE = '',
  DATA_VISUALIZATION_TEN = '',
}

const planFcoTransitionTypeColorPairs: Record<FcoTransitionType, { textColor: string; backgroundColor: string }> = {
  CREATE: {
    backgroundColor: SematicBackgroundColor.SUCCESS_LIGHT_GREEN,
    textColor: SematicTextColor.SUCCESS_LIGHT_GREEN,
  },
  RECREATE: {
    backgroundColor: SematicBackgroundColor.DANGER_LIGHT_PINK,
    textColor: SematicTextColor.DANGER_LIGHT_PINK,
  },
  UPDATE: {
    backgroundColor: SematicBackgroundColor.WARNING_LIGHT_ORANGE,
    textColor: SematicTextColor.WARNING_LIGHT_ORANGE,
  },
  UPGRADE: {
    backgroundColor: SematicBackgroundColor.WARNING_LIGHT_ORANGE,
    textColor: SematicTextColor.WARNING_LIGHT_ORANGE,
  },
  DELETE: {
    backgroundColor: SematicBackgroundColor.DANGER_LIGHT_PINK,
    textColor: SematicTextColor.DANGER_LIGHT_PINK,
  },
  UNKNOWN: {
    backgroundColor: SematicBackgroundColor.NEUTRAL_LIGHT_GRAY,
    textColor: SematicTextColor.NEUTRAL_LIGHT_GRAY,
  },
  UNCHANGED: {
    backgroundColor: SematicBackgroundColor.NEUTRAL_LIGHT_GRAY,
    textColor: SematicTextColor.NEUTRAL_LIGHT_GRAY,
  },
};

const categoryPairs: { background: string; foreground: string }[] = colorTextCategories.map((color, index) => {
  return { foreground: color, background: colorBackgroundCategories[index] };
});

export const LIGHT_COLOR_SCHEME_OVERRIDES = {
  primary: NamedColors.PRIMARY_BLUE,
  primaryText: `#026EAC`,
  primaryLink: `#006BB8`,
  accent: `#375069`,
  accentText: `#00BEFF`,
  tectonRed: `#DA4B35`,
  warningDark: NamedColors.WARNING,
  progressDark: `#6233C2`,
  mediumShade: '#98A2B3',
  lightestShade: '#F1F3FA',
  uiHeadingShade: '#EEF2F7',
  lightShade: '#D3DAE6',
  categoryText: colorTextCategories,
  categoryBackground: colorBackgroundCategories,
  categoryColorPairs: categoryPairs,
  semanticColorPair,
  planFcoTransitionTypeColorPairs, // used in multiple places
  navigationText: '#b6c5cf', // TODO: Jul-28-2023: These should be standardized with the overall theme once the nav is redesigned
  navigationCategoryBackground: '#f3f5f8',
  chromeBackground: '#263549',
  darkShade: '#69707D',
  darkestShade: '#343741',
  fullShade: '#000000',
  sideNavigationBackground: '#f6f7f9',

  core: {
    danger: '#880625',
    neutral: '#343741',
  },
  secondary: '#E1E5EA',
  success: '#00BFB3',
  warning: '#FFA666',
  danger: '#BC1039',
  featureViewType: {
    batch: 'rgb(186, 222, 255)',
    stream: 'rgb(239, 218, 255)',
    unknown: 'rgb(255, 202, 190)',
    'stream window aggregate': 'rgb(255, 202, 190)',
    'batch window aggregate': 'rgb(255, 202, 190)',
    'on demand': 'rgb(255, 202, 190)',
    'feature table': 'rgb(255, 218, 240)',
    ingest: 'rgb(196, 242, 249)',
  },
  positiveText: '#006D67',
  negativeText: '#BC1039',
  subtleHighlight: '#fafbfd',
};

const BORDER_OVERRIDES = {
  radius: {
    small: '1px',
    medium: '2px',
  },
};

const FONT_OVERRIDES = {
  scale: {
    l: 1.125,
    xl: 1.5,
    s: 1,
  },
};

const DARK_COLOR_SCHEME_OVERRIDES = {};

const COLOR_OVERRIDES = {
  light: LIGHT_COLOR_SCHEME_OVERRIDES,
  dark: DARK_COLOR_SCHEME_OVERRIDES,
};

export const ALL_OVERRIDES = {
  themeName: 'TECTON_DEFAULT_THEME',
  border: BORDER_OVERRIDES,
  font: FONT_OVERRIDES,
  color: COLOR_OVERRIDES,
};

export type TectonThemeType = typeof ALL_OVERRIDES;

export const useTectonTheme = () => {
  const { euiTheme: theme } = useEuiTheme<TectonThemeType>();
  const fontSize = useEuiFontSize;

  return { theme, fontSize };
};

const TectonThemeProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <EuiProvider
      modify={ALL_OVERRIDES}
      globalStyles={() => {
        return (
          <>
            <TectonGlobalStyles />
            <Global
              styles={css`
                input,
                button,
                .euiButtonIcon,
                .euiFieldSearch,
                .euiFilterGroup,
                .euiDatePickerRange,
                .euiTextArea,
                .euiSelect,
                .euiFieldText,
                .euiComboBox .euiComboBox__inputWrap,
                .euiSelectableList-bordered {
                  border-radius: 2px;
                }

                .euiLink {
                  &:hover {
                    color: ${LIGHT_COLOR_SCHEME_OVERRIDES.primaryLink};
                  }
                }

                .euiFormLabel.euiFormLabel-isFocused.euiFormLabel-isInvalid {
                  color: #bd271e;
                }

                :focus {
                  outline: none !important;
                }
              `}
            />
          </>
        );
      }}
    >
      {children}
    </EuiProvider>
  );
};

export default TectonThemeProvider;
