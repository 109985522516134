import React, { FC } from 'react';
import { useTectonTheme, Text, FlexItem, Icon, FlexGroup } from '@tecton/ComponentRedesign';

import Checkmark from '@svg/checkmark.svg'; // success
import Information from '@svg/information.svg';
import Warning from '@svg/warning.svg';
import Danger from '@svg/danger.svg';
import Support from '@svg/support.svg';

type ToastType = 'success' | 'warning' | 'danger' | 'neutral' | 'info';

interface ToastProps {
  variant?: ToastType;
  color?: string;
  button?: React.ReactNode;
  title: React.ReactNode;
  children: React.ReactNode;
  showIcon?: boolean;
}

const Toast: FC<ToastProps> = ({ variant, color, title, button, children, showIcon }) => {
  const { theme } = useTectonTheme();

  let iconToRender = Checkmark;

  switch (variant) {
    case 'success':
      color = theme.colors.successText;
      iconToRender = Checkmark;
      break;
    case 'info':
      color = theme.colors.title;
      iconToRender = Information;
      break;
    case 'warning':
      color = theme.colors.warningText;
      iconToRender = Warning;
      break;
    case 'danger':
      color = theme.colors.dangerText;
      iconToRender = Danger;
      break;
    case 'neutral':
      color = theme.colors.text;
      iconToRender = Support;
      break;
  }

  return (
    <span data-testid="toast">
      <FlexGroup direction="row" gutterSize="xs">
        <FlexItem grow={false}>
          <div style={{ justifyContent: 'center' }}>{showIcon && <Icon type={iconToRender} />}</div>
        </FlexItem>
        <FlexItem grow={false}>
          <span style={{ color: color }}>{title}</span>
        </FlexItem>
      </FlexGroup>
      <Text>{children}</Text>
      <FlexGroup direction="rowReverse">
        <FlexItem grow={false}>{button && button}</FlexItem>
      </FlexGroup>
    </span>
  );
};

export const getToastComponent = ({ title, variant, children, showIcon }: ToastProps): React.ReactChild | undefined => {
  return (
    <>
      <Toast title={title} variant={variant} showIcon={showIcon}>
        {children}
      </Toast>
    </>
  );
};

export default Toast;
