import {
  MaterializationEvent,
  MaterializationJob,
  MaterializationMetricResolutionGroups,
  MaterializationMetricStatus,
  MaterializationQueryFeatureResult,
  MaterializationQueryIndividualFeature,
  MaterializationQueryResult,
} from './DataQualityMetricsTypes';
import _ from 'lodash';
import { extent, group } from 'd3-array';
import { utcFormat } from 'd3-time-format';
import { ScaleTime } from 'd3-scale';
import { utcDays, utcHours, utcMonths, utcSundays, utcYears } from 'd3-time';

export const calculatePercentagesByDateForFeatureViews = (
  featureData: MaterializationEvent[],
  rowsData: MaterializationEvent[]
) => {
  const data = featureData.map((datum, index) => {
    const rowsAtDate = rowsData[index].value;
    const nullsAtDate = _.clone(datum);

    if (rowsAtDate === 0) {
      nullsAtDate.value = undefined;
      return nullsAtDate;
    }

    if (nullsAtDate.status === MaterializationMetricStatus.METRIC_STATUS_AVAILABLE) {
      const percentage = rowsAtDate && nullsAtDate.value ? nullsAtDate.value / rowsAtDate : 0;
      nullsAtDate.value = percentage;
    }

    return nullsAtDate;
  });

  return data;
};

// Returns subsets of the feature view data that match the date range
export const filterFeatureViewDataForTimeRange = (data: MaterializationQueryFeatureResult) => {
  return data.features.map((feature: MaterializationQueryIndividualFeature) => {
    return {
      name: feature.name,
      data: feature.data,
    };
  });
};

export const getIndexForDate = (matchDate: Date, dates: Date[]) => {
  const times: number[] = dates.map((date) => date.getTime());
  return times.indexOf(matchDate.getTime());
};

export const getMatchingEventForDate = (date: Date, events: MaterializationEvent[]) => {
  const search = events.filter((event) => event.date.getTime() === date.getTime());
  if (search.length === 0) {
    return undefined;
  }
  return search[0];
};

export const getNearestEventToDate = (date: Date, dates: Date[]) => {
  const targetTime = date.getTime();
  return dates.sort((a, b) => Math.abs(a.getTime() - targetTime) - Math.abs(b.getTime() - targetTime))[0];
};

export const rollUpJobs = (data: MaterializationQueryResult) => {
  const rawJobs = group(data.data, (event: MaterializationEvent) => event.id);
  const jobs: MaterializationJob[] = [];

  const intervalInMs = parseMetricTime(data.apiResponse.metric_data_point_interval);

  rawJobs.forEach((job) => {
    if (job[0].id === undefined) {
      return;
    }
    const dateRange = extent(job.map((event) => event.date)) as Date[];

    const singleJob = {
      id: job[0].id,
      url: job[0].url,
      events: job,
      range: { start: dateRange[0], end: new Date(dateRange[1].getTime() + intervalInMs) },
      status: MaterializationMetricStatus.METRIC_STATUS_AVAILABLE,
    };

    jobs.push(singleJob);
  });

  data.data
    .filter((event) => event.status === MaterializationMetricStatus.METRIC_STATUS_UNAVAILABLE)
    .forEach((event) => {
      jobs.push({
        id: event.id ? event.id : '',
        url: event.url,
        events: [event],
        range: { start: event.date, end: new Date(event.date.getTime() + intervalInMs) },
        status: MaterializationMetricStatus.METRIC_STATUS_UNAVAILABLE,
      });
    });

  data.data
    .filter((event) => event.status === MaterializationMetricStatus.METRIC_STATUS_NO_MATERIALIZATION)
    .forEach((event) => {
      jobs.push({
        id: event.id ? event.id : '',
        url: event.url,
        events: [event],
        range: { start: event.date, end: new Date(event.date.getTime() + intervalInMs) },
        status: MaterializationMetricStatus.METRIC_STATUS_NO_MATERIALIZATION,
      });
    });

  data.data
    .filter((event) => event.status === MaterializationMetricStatus.METRIC_STATUS_ERROR)
    .forEach((event) => {
      jobs.push({
        id: event.id ? event.id : '',
        url: event.url,
        events: [event],
        range: { start: event.date, end: new Date(event.date.getTime() + intervalInMs) },
        status: MaterializationMetricStatus.METRIC_STATUS_ERROR,
      });
    });

  jobs.sort((a, b) => a.range.start.getTime() - b.range.start.getTime());

  return jobs;
};

export const parseMetricTime: (input: string) => number = (input: string) => {
  return +input.replace('s', '') * 1000;
};

export const determineTimeResolution = (timeResolution: number) => {
  if (timeResolution <= 60000) {
    return MaterializationMetricResolutionGroups.TIME_RESOLUTION_FIVE_MINUTES;
  }

  if (timeResolution <= 300000) {
    return MaterializationMetricResolutionGroups.TIME_RESOLUTION_ONE_HOUR;
  }

  if (timeResolution <= 3600000) {
    return MaterializationMetricResolutionGroups.TIME_RESOLUTION_ONE_DAY;
  }

  if (timeResolution <= 86400000) {
    return MaterializationMetricResolutionGroups.TIME_RESOLUTION_ONE_WEEK;
  }

  return MaterializationMetricResolutionGroups.TIME_RESOLUTION_ONE_MONTH;
};

export const materializationPanelDateFormat = utcFormat('%Y-%m-%d');
export const materializationPanelTimeFormat = utcFormat('%H:%M');

export const calculatePositionForFeatureViewCreatedDate = (
  timeScale: ScaleTime<number, number, never>,
  createdDate: Date
) => {
  const minX = 175;
  const maxX = timeScale.range()[1] - 175;

  const actualPositionOfCreatedDate = timeScale(createdDate);
  let offsetPositionOfCreatedDate = actualPositionOfCreatedDate;
  if (actualPositionOfCreatedDate > maxX) {
    offsetPositionOfCreatedDate = maxX;
  }

  if (actualPositionOfCreatedDate < minX) {
    offsetPositionOfCreatedDate = minX;
  }

  return { actualPositionOfCreatedDate, offsetPositionOfCreatedDate };
};

export const getDateTicks = (timeResolution: MaterializationMetricResolutionGroups, allDates: Date[]) => {
  const start = allDates[0];
  const end = allDates[allDates.length - 1];

  switch (timeResolution) {
    case MaterializationMetricResolutionGroups.TIME_RESOLUTION_ONE_MINUTE:
      return utcHours(start, end);
    case MaterializationMetricResolutionGroups.TIME_RESOLUTION_FIVE_MINUTES:
      return utcHours(start, end);
    case MaterializationMetricResolutionGroups.TIME_RESOLUTION_ONE_HOUR:
      return utcHours(start, end);
    case MaterializationMetricResolutionGroups.TIME_RESOLUTION_ONE_DAY:
      return utcDays(start, end);
    case MaterializationMetricResolutionGroups.TIME_RESOLUTION_ONE_WEEK:
      return utcSundays(start, end);
    case MaterializationMetricResolutionGroups.TIME_RESOLUTION_ONE_MONTH:
      return utcMonths(start, end);
    case MaterializationMetricResolutionGroups.TIME_RESOLUTION_ONE_YEAR:
      return utcYears(start, end);
  }
};
