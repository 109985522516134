import { useDispatch, useSelector } from 'react-redux';
import { updateGlobalConfigs, updateGlobalConfigsHasErrors } from './actions';
import { configStateSelector } from '../../utils/config-utils';
import { useGetGlobalsForWebUi } from '../../api/user';

// TODO: remove this file once we get rid of Redux
const useGlobalConfigs = () => {
  const dispatch = useDispatch();
  const configState = useSelector(configStateSelector);

  const state = useGetGlobalsForWebUi();

  if (state.isSuccess && state.data && !configState.hasRequestedConfiguration) {
    dispatch(updateGlobalConfigs(state.data.key_values));
  } else if (state.isError) {
    dispatch(updateGlobalConfigsHasErrors(state.error));
  }

  return state;
};

export { useGlobalConfigs };
