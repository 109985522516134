import { GetGlobalsForWebUIResponse } from '../types/tecton_proto/metadataservice/metadata_service';
import { Metadata_Service } from './';
import { useQuery } from '@tanstack/react-query';

const fetchGlobalsForWebUI = async (): Promise<GetGlobalsForWebUIResponse> => {
  const response = await Metadata_Service('get-globals-for-web-ui');
  return response.data;
};

export const useGetGlobalsForWebUi = (enabled = true) => {
  return useQuery(['globals-for-web-ui'], () => fetchGlobalsForWebUI(), {
    enabled,
    cacheTime: 30 * 60 * 1000, // Cache for 30 minutes
  });
};
