/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useCallback, useContext, useMemo } from 'react';
import { ScaleTime, scaleTime } from 'd3-scale';
import Draggable from 'react-draggable';
import { DataQualityMonitoringContext } from './DataQualityMetricsContext';
import { MaterializationJob, MaterializationMetricStatus } from './DataQualityMetricsTypes';
import {
  calculatePositionForFeatureViewCreatedDate,
  materializationPanelDateFormat,
  materializationPanelTimeFormat,
} from './DataQualityMetricsUtils';

import { useDataQualityMonitoringThemeColors } from './DataQualityMetrics';
import { cloneDeep } from 'lodash';
import { useTectonTheme } from '../../../@tecton/ComponentRedesign/Theme/ThemeProvider';

interface DataQualityMonitoringJobIndicatorProps {
  data: MaterializationJob[];
  width?: number | undefined;
}

// Root component for materialization monitoring job svg display
export const DataQualityMonitoringJobIndicator: FC<DataQualityMonitoringJobIndicatorProps> = ({ data, width }) => {
  const { theme } = useTectonTheme();
  const context = useContext(DataQualityMonitoringContext);

  const highlightJob = useCallback(
    (job: MaterializationJob) => {
      const findMatchingIndices = job.events.map((jobEvent) => {
        return context.rowCountsData.indexOf(jobEvent);
      });
      context.handleIndicesSelection(findMatchingIndices);
      context.externalHighlightsWereSelected(job.events);
    },
    [context]
  );

  const unHighlightJob = useCallback(() => {
    context.handleIndicesSelection(undefined);
    context.externalHighlightsWereSelected(undefined);
  }, [context]);

  const memoizedDates = useMemo(() => {
    const validDateIndicators = data.map((job) => {
      const xCoordinate = context.chartScale(job.range.start);
      const barWidth = context.chartScale(job.range.end) - context.chartScale(job.range.start);

      return (
        <DataQualityMonitoringValidJobIndicator
          job={job}
          key={`base_indicator_${job.id + Math.random()}`}
          x={xCoordinate}
          width={barWidth ?? 0}
          highlightCallback={() => highlightJob(job)}
          unHighlightCallback={unHighlightJob}
          isHighlighted={false}
        />
      );
    });

    return <>{validDateIndicators}</>;
  }, []);

  if (!context.isReady) {
    return <></>;
  }

  const height = 50;

  let highlights = <></>;
  let labelHighlight = <></>;

  if (context.highlightedEvents) {
    const jobHighlightId = context.highlightedEvents[0].id;
    const matchingJob = data.find((job: MaterializationJob) => job.id === jobHighlightId);
    const highlightedDate = context.highlightedEvents[0].date;

    if (matchingJob) {
      const width = context.chartScale(matchingJob.range.end) - context.chartScale(matchingJob.range.start);

      highlights = (
        <DataQualityMonitoringValidJobIndicator
          job={matchingJob}
          key={matchingJob.id}
          x={context.chartScale(matchingJob.range.start)}
          width={width}
          isHighlighted={true}
        />
      );

      const jobPosition = context.chartScale(matchingJob.range.start);
      const minValue = context.chartScale.range()[0];
      const currentWindowStart = minValue - context.canvasTranslate;
      const jobPositionCorrected = jobPosition < currentWindowStart ? currentWindowStart : jobPosition;

      const jobString = `Job ID: ${matchingJob.id}`;
      const dateString = `${materializationPanelDateFormat(matchingJob.range.start)} ${materializationPanelTimeFormat(
        matchingJob.range.start
      )} — ${materializationPanelDateFormat(matchingJob.range.end)} ${materializationPanelTimeFormat(
        matchingJob.range.end
      )}`;
      labelHighlight = (
        <g transform={`translate(${jobPositionCorrected},-5)`}>
          <text fill={'white'} stroke={'white'} strokeWidth={5}>
            {jobString}
          </text>

          <text>{jobString}</text>

          <text fontSize={'12px'} fill="white" stroke="white" strokeWidth={5} y={13}>
            {dateString}
          </text>

          <text fontSize={'12px'} fill={theme.colors.mediumShade} y={13}>
            {dateString}
          </text>
        </g>
      );
    } else {
      labelHighlight = (
        <DataQualityMonitoringMissingJobIndicator
          date={highlightedDate}
          x={context.chartScale(highlightedDate)}
          width={context.bandWidth}
        />
      );
    }
  }

  return (
    <svg height={height} overflow="visible" onMouseOut={unHighlightJob}>
      {/* The clip-path here ensures that the highlighted jobs are never cut off, while at the same time ensuring that the dates don't extend beyond the appropriate chart area. */}
      <defs>
        <clipPath id="INDICATOR_CLIP_PATH">
          <rect width={width} height={55} y={-5} fill="black" />
        </clipPath>
      </defs>

      <g clipPath="url(#INDICATOR_CLIP_PATH)" data-name="MEMOS">
        <g transform={context.transform}>
          {context.dateTicks.map((date, index) => {
            return (
              <DataQualityMonitoringDateTick
                date={date}
                height={height}
                index={index}
                key={`date_tick_${date.toDateString()}`}
              />
            );
          })}
        </g>
        <g transform={`translate(0,20)`}>
          <g transform={context.transform}>{memoizedDates}</g>
        </g>
      </g>

      <g clipPath="url(#INDICATOR_CLIP_PATH)">
        <g transform={`translate(0,20)`}>
          <g transform={context.transform}>{highlights}</g>
        </g>
      </g>

      <g>
        <g transform={`translate(0,20)`}>
          <g transform={context.transform}>{labelHighlight}</g>
        </g>
      </g>
    </svg>
  );
};

interface DataQualityMonitoringValidJobIndicatorProps {
  job: MaterializationJob;
  x: number;
  width: number;
  highlightCallback?: () => void;
  unHighlightCallback?: () => void;
  isHighlighted: boolean;
}

// Indicator for valid job period
export const DataQualityMonitoringValidJobIndicator: FC<DataQualityMonitoringValidJobIndicatorProps> = ({
  job,
  x,
  width,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  highlightCallback = () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  unHighlightCallback = () => {},
  isHighlighted,
}) => {
  const statusColors = useDataQualityMonitoringThemeColors();
  const fill = statusColors[job.status];
  const context = useContext(DataQualityMonitoringContext);

  return (
    <g transform={`translate(${x},13)`} style={{ pointerEvents: isHighlighted ? 'none' : 'auto' }}>
      <rect
        opacity={!context.highlightedIndices || isHighlighted ? 1 : 0.5}
        cursor="pointer"
        height={15}
        y={2}
        width={width}
        fill={fill}
        stroke="white"
        strokeWidth={0.125}
        onClick={() => {
          job.url && window.open(job.url, '_BLANK');
        }}
        onMouseOver={highlightCallback}
        onMouseOut={unHighlightCallback}
        x={0}
      />
    </g>
  );
};

interface DataQualityMonitoringMissingJobIndicatorProps {
  x: number;
  width: number;
  date: Date;
}

// Indicator to show that the individual job has no data
const DataQualityMonitoringMissingJobIndicator: FC<DataQualityMonitoringMissingJobIndicatorProps> = ({
  x,
  date,
  width,
}) => {
  const colors = useDataQualityMonitoringThemeColors();
  const { theme } = useTectonTheme();

  const dateString = `${materializationPanelDateFormat(date)} ${materializationPanelTimeFormat(date)}`;

  return (
    <g transform={`translate(${x},-5)`}>
      <text>Job Pending</text>
      <text fontSize={'12px'} fill={theme.colors.mediumShade} y={13}>
        {dateString}
      </text>

      <rect
        cursor="pointer"
        height={15}
        y={20}
        width={width}
        fill={colors.METRIC_STATUS_NO_MATERIALIZATION}
        pointerEvents={'none'}
      />
    </g>
  );
};

interface DataQualityMonitoringDateTickProps {
  date: Date;
  height: number;
  index: number;
}

// Date ticks extended down from the timelines for materialization column charts
const DataQualityMonitoringDateTick: FC<DataQualityMonitoringDateTickProps> = ({ date, height, index }) => {
  const context = useContext(DataQualityMonitoringContext);
  const { theme } = useTectonTheme();

  const xCoordinate = context?.chartScale(date);
  let opacity = 1;

  if (context.highlightedIndices) {
    opacity = 0;
  }

  const stroke = index % context.dateLabelInterval ? '#ccc' : '#aaa';
  const strokeWidth = index % context.dateLabelInterval ? 0.5 : 1;

  if (!context.isReady) {
    return <></>;
  }

  let dateTimeIndicator = <></>;
  {
    context.majorTicks.includes(date) &&
      !context.showTime &&
      (dateTimeIndicator = (
        <text
          fontSize={'12px'}
          y={0}
          dx={-4}
          fill={theme.colors.mediumShade}
          textAnchor="end"
          dominantBaseline={'hanging'}
        >
          {materializationPanelDateFormat(date)}
        </text>
      ));
  }

  {
    context.majorTicks.includes(date) &&
      context.showTime &&
      (dateTimeIndicator = (
        <>
          <text
            fontSize={'12px'}
            y={0}
            dx={-4}
            fill={theme.colors.mediumShade}
            textAnchor="end"
            dominantBaseline={'hanging'}
          >
            {materializationPanelDateFormat(date)}
          </text>
          <text
            fontSize={'12px'}
            y={0}
            dx={-4}
            fill={theme.colors.mediumShade}
            textAnchor="end"
            dominantBaseline={'hanging'}
          >
            {materializationPanelTimeFormat(date)}
          </text>
        </>
      ));
  }

  return (
    <g transform={`translate(${xCoordinate},0)`} opacity={opacity}>
      <line key={'axis_line_ ' + date} y1={0} y2={height} stroke={stroke} strokeWidth={strokeWidth} />
      {dateTimeIndicator}
    </g>
  );
};

interface DataQualityMonitoringDateAnnotationProps {
  date: Date;
  label: string;
  x: number;
  y: number;
  onClick: () => void;
  textAnchor?: string;
}

// Text component for start and end dates in the date scrubber
const DataQualityMonitoringDateAnnotation: FC<DataQualityMonitoringDateAnnotationProps> = ({
  date,
  label,
  x,
  y,
  onClick,
  textAnchor = 'start',
}) => {
  const { theme } = useTectonTheme();
  const color = '#022738';
  let xPosition = 4;
  let dx = 8;

  if (textAnchor !== 'start') {
    xPosition = -4;
    dx = -8;
  }

  return (
    <g transform={`translate(${x},${y})`} cursor={'pointer'} onClick={onClick}>
      <line y1={45} y2={18} stroke={color} strokeWidth={1} />
      <line y1={18} y2={18} x1={0} x2={xPosition} stroke={color} strokeWidth={1} />
      <circle cx={xPosition} cy={18} r={2} fill={color} />
      <text
        y={16}
        dominantBaseline={'baseline'}
        fontSize={'10px'}
        fill={theme.colors.mediumShade}
        dy={-1}
        textAnchor={textAnchor}
        dx={dx}
      >
        {label}
      </text>
      <text
        y={18}
        dominantBaseline={'hanging'}
        fontSize={'14px'}
        fill={theme.colors.darkShade}
        fontWeight={'600'}
        dx={dx}
        textAnchor={textAnchor}
        letterSpacing={'1.5em'}
      >
        {materializationPanelDateFormat(date)}
      </text>
    </g>
  );
};

interface DataQualityMonitoringDateRangeSliderProps {
  width?: number;
}

// Date range scrubber / slider for batch materialization monitoring panel
export const DataQualityMonitoringDateRangeSlider: FC<DataQualityMonitoringDateRangeSliderProps> = ({ width }) => {
  const context = useContext(DataQualityMonitoringContext);
  const statusColors = useDataQualityMonitoringThemeColors();

  const reducedJobs = useMemo(() => {
    // Simplify the jobs array to reduce number of svg rect elements to be drawn
    let lastJobStatus: MaterializationMetricStatus;
    const reducedJobs = cloneDeep(context.jobs).filter((job) => {
      if (job.status === lastJobStatus) {
        return false;
      }

      lastJobStatus = job.status;
      return true;
    });

    if (reducedJobs.length === 1) {
      reducedJobs[0].range.end = context.jobs[context.jobs.length - 1].range.end;
    } else {
      reducedJobs.forEach((job, index) => {
        if (index === 0) {
          job.range.end = reducedJobs[1].range.start;
          return;
        }

        if (index === reducedJobs.length - 1) {
          job.range.end = context.jobs[context.jobs.length - 1].range.end;
          return;
        }

        job.range.end = reducedJobs[index + 1].range.start;
      });
    }

    return reducedJobs;
  }, [context.jobs]);

  if (!context.isReady) {
    return <></>;
  }

  const firstDate = context.jobs[0].range.start;

  const lastDate = context.jobs[context.jobs.length - 1].range.end;

  const leftMargin = 0;

  const jumpToStart = () => {
    const startDate = context.allDates[0];
    context.externalHandleDrag(startDate);
  };

  const jumpToEnd = () => {
    if (context.usableWidth) {
      const correctSize = Math.floor(context.usableWidth / context.bandWidth);
      const startDate = context.allDates[context.allDates.length - correctSize - 1];
      context.externalHandleDrag(startDate);
    }
  };

  const timeScale = scaleTime<number, number>()
    .domain([firstDate, lastDate])
    .range([leftMargin, width ?? 0]);

  const axis = context.dateTicks.map((tick, index) => {
    const coordinate = timeScale(tick);

    if (index % context.dateLabelInterval === 0) {
      return (
        <line key={tick.getTime()} x1={coordinate} x2={coordinate} y1={30} y2={42.5} stroke="#aaa" strokeWidth={0.5} />
      );
    } else {
      return (
        <line
          key={tick.getTime()}
          x1={coordinate}
          x2={coordinate}
          y1={37.5}
          y2={42.5}
          stroke="#aaa"
          strokeWidth={0.5}
        />
      );
    }
  });

  return (
    <>
      <svg height={75} overflow="visible">
        {axis}
        <g transform={`translate(0,0)`}>
          <DataQualityMonitoringDateAnnotation
            date={firstDate}
            label={'EARLIEST METRIC DATA*'}
            x={timeScale(firstDate)}
            y={-2}
            onClick={jumpToStart}
          />

          <DataQualityMonitoringDateAnnotation
            date={context.jobs[context.jobs.length - 1].range.start}
            label={'MOST RECENT DATA'}
            x={timeScale(lastDate)}
            y={-2}
            onClick={jumpToEnd}
            textAnchor="end"
          />
          <DataQualityMonitoringFeatureViewAnnotation timeScale={timeScale} />

          {reducedJobs.map((job, index) => {
            const fill = statusColors[job.status];
            const width = timeScale(job.range.end) - timeScale(job.range.start);

            return (
              <rect
                key={`reduced_job_${index}`}
                x={timeScale(job.range.start)}
                height={12.5}
                y={40.25}
                opacity={1}
                width={width}
                fill={fill}
                cursor="pointer"
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onClick={(event: any) => {
                  const svgBoundingRect = event.target.ownerSVGElement.getBoundingClientRect();
                  const relativeClickCoordinate = event.pageX - svgBoundingRect.left;
                  const attemptedStartDate = timeScale.invert(relativeClickCoordinate);
                  context.externalHandleDrag(attemptedStartDate);
                }}
              />
            );
          })}

          <DataQualityMonitoringDateScrubber timeScale={timeScale} />
        </g>
      </svg>
    </>
  );
};

interface DataQualityMonitoringFeatureViewAnnotationProps {
  timeScale: ScaleTime<number, number, never>;
}

// Group component to show the Feature View created date in the scrubber
const DataQualityMonitoringFeatureViewAnnotation: FC<DataQualityMonitoringFeatureViewAnnotationProps> = ({
  timeScale,
}) => {
  const context = useContext(DataQualityMonitoringContext);
  const { theme } = useTectonTheme();

  const { actualPositionOfCreatedDate, offsetPositionOfCreatedDate } = calculatePositionForFeatureViewCreatedDate(
    timeScale,
    context.createdDate
  );

  const color = theme.colors.darkShade;

  if (context.jobs[0].range.start.getTime() > context.createdDate.getTime()) {
    return <></>; // Hide the Feature View annotation if created date is outside of rendered range
  }

  return (
    <g>
      <text
        y={14}
        dominantBaseline={'baseline'}
        fontSize={'10px'}
        fill={theme.colors.mediumShade}
        dy={-1}
        textAnchor={'middle'}
        x={offsetPositionOfCreatedDate}
      >
        FEATURE CREATED
      </text>
      <text
        x={offsetPositionOfCreatedDate}
        y={16}
        dominantBaseline={'hanging'}
        fontSize={'14px'}
        fill={color}
        fontWeight={'600'}
        textAnchor={'middle'}
        letterSpacing={'1.5em'}
      >
        {materializationPanelDateFormat(context.createdDate as Date)}
      </text>

      <circle cy={30} fill={color} r={2} cx={offsetPositionOfCreatedDate} />

      <line
        x1={offsetPositionOfCreatedDate}
        x2={offsetPositionOfCreatedDate}
        y1={35}
        y2={30}
        stroke={color}
        strokeWidth={1}
      />
      <line
        x1={actualPositionOfCreatedDate}
        x2={actualPositionOfCreatedDate}
        y1={35}
        y2={40}
        stroke={color}
        strokeWidth={1}
      />

      <line
        x1={offsetPositionOfCreatedDate}
        x2={actualPositionOfCreatedDate}
        y1={35}
        y2={35}
        stroke={color}
        strokeWidth={1}
      />
    </g>
  );
};

interface DataQualityMonitoringDateScrubberProps {
  timeScale: ScaleTime<number, number, never>;
}

// Root component for the date scrubber
const DataQualityMonitoringDateScrubber: FC<DataQualityMonitoringDateScrubberProps> = ({ timeScale }) => {
  const context = useContext(DataQualityMonitoringContext);

  if (!context.isReady || context.startDate === undefined || context.endDate === undefined) {
    return <></>;
  }

  const endDate: Date = context.timeResolution
    ? new Date(context.endDate.getTime() + context.timeResolution)
    : context.endDate;
  const startDate = context.startDate;
  const startDateCoordinate = timeScale(startDate);
  const endDateCoordinate = timeScale(endDate);
  const rectWidth = endDateCoordinate - startDateCoordinate;

  const textAnchor = startDateCoordinate / context.chartColumnWidth >= 0.5 ? 'end' : 'start';
  const xCoordinate = startDateCoordinate / context.chartColumnWidth >= 0.5 ? endDateCoordinate : startDateCoordinate;

  // Have to use any here because of weirdness with React-Draggable. This may be fixable.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDrag = (event: any) => {
    const attemptedStartDate = timeScale.invert(event.offsetX);
    context.externalHandleDrag(attemptedStartDate);
  };

  return (
    <g>
      <Draggable axis="x" onDrag={handleDrag} bounds={{ left: 0, right: 0 }}>
        <g cursor="grab">
          <rect
            x={startDateCoordinate}
            y={38.75}
            height={15}
            width={rectWidth}
            fill="#41566B33"
            cursor="grab"
            stroke="#41566B"
            strokeWidth={0.25}
          />

          <text
            x={xCoordinate}
            dominantBaseline={'hanging'}
            y={60}
            textAnchor={textAnchor}
            fontSize="14px"
            fontWeight={'bold'}
          >
            <>
              <tspan dominantBaseline={'hanging'}>{materializationPanelDateFormat(startDate)} </tspan>
              <tspan fontWeight="normal" dominantBaseline={'hanging'}>
                to{' '}
              </tspan>
              <tspan dominantBaseline={'hanging'}>{materializationPanelDateFormat(endDate)}</tspan>
            </>
          </text>
        </g>
      </Draggable>
    </g>
  );
};
