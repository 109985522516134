import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import MetadataService from '../../../service/MetadataService';
import { useDispatch } from 'react-redux';
import { updateOnboarding } from '../actions';
import { OnboardingStateEnums } from '../types';
import { DataPlatformSetupTaskStatusEnum } from '../../../service/DataPlatformSetupStatus';
import useLogOnce from '../../data-platform-configuration/databricks/setup/useLogOnce';

export function useGetDataPlatformSetupStatus(enabled: boolean) {
  const dispatch = useDispatch();

  const [hasMadeRequest, setHasMadeRequest] = useState<boolean>(false);
  const [shouldDoPolling, setShouldDoPolling] = useState<boolean>(false);
  const logSomeDpoTaskFailed = useLogOnce();
  const logAllTaskCompleted = useLogOnce();

  // When the form gets updated, we need to re-poll again.
  useEffect(() => {
    if (hasMadeRequest) {
      setHasMadeRequest(false);
    }
  }, [enabled]);

  const state = useQuery(
    ['getDataPlatformSetupStatus'],
    () => {
      setHasMadeRequest(true);
      return MetadataService.sharedInstance().getDataPlatformSetupStatus();
    },
    {
      enabled: (enabled && !hasMadeRequest) || shouldDoPolling,
      onSuccess: (response) => {
        // Let's do polling if it's not completed
        setShouldDoPolling(!response.setupCompleted);

        if (response.setupCompleted) {
          // Update Redux state to reflect that Data Platform setup is complete
          dispatch(updateOnboarding(OnboardingStateEnums.HAS_COMPLETED_GUIDED_ONBOARDING));

          // Log to Amplitude all task completed.
          logAllTaskCompleted('DPO: all tasks completed');
        } else {
          const hasFailure = response.tasks.some((task) => {
            // checking to see if some task are TASK_STATUS_FAILED
            return task.task_status === DataPlatformSetupTaskStatusEnum.TASK_STATUS_FAILED;
          });

          if (hasFailure) {
            let logMessage = 'DPO: some task failed. \n';
            let counter = 1;
            response.tasks.forEach((task) => {
              if (task.task_status === DataPlatformSetupTaskStatusEnum.TASK_STATUS_FAILED) {
                logMessage =
                  logMessage +
                  `${counter}. ${task.task_display_name} (${task.task_status}): ${
                    task.error_message ? task.error_message : ''
                  } \n`;

                counter++;
              }
            });

            logSomeDpoTaskFailed(logMessage);
          }
        }
      },
      refetchInterval: 5000,
    }
  );

  if (state.error) {
    throw state.error;
  }
  return state;
}
