import React, { useState } from 'react';
import { Criteria, EuiBasicTableColumn } from '@tecton';
import { BasicTable, TablePagination } from '@tecton';

type RowPropsCallback<T> = (row: T) => object;

interface TectonBasicTableProps<T> {
  items: T[];
  itemId?: string;
  itemIdToExpandedRowMap?: Record<string, React.ReactNode>;
  columns: EuiBasicTableColumn<T>[];
  pageIndex: number;
  pageSize: number;
  totalItemCount: number;
  sortAndPagination?: Criteria<T>;
  onChange?: (s: Criteria<T>) => void;
  isExpandable?: boolean;
  rowProps?: object | RowPropsCallback<T>;
  showPerPageOptions?: boolean | undefined;
}

export const useTableSortAndPaginationState = <T,>(
  initialPageIndex: number,
  initialPageSize: number,
  initialSortField: keyof T,
  initialSortDirection: 'asc' | 'desc'
) => {
  const [tableCriteriaState, setTableCriteriaState] = useState<Criteria<T>>({
    page: {
      index: initialPageIndex,
      size: initialPageSize,
    },
    sort: {
      field: initialSortField,
      direction: initialSortDirection,
    },
  });

  const resetPageIndexToZero = () => {
    setTableCriteriaState({
      ...tableCriteriaState,
      page: {
        size: tableCriteriaState.page?.size || initialPageSize,
        index: 0,
      },
    });
  };

  return {
    tableCriteriaState,
    setTableCriteriaState,
    resetPageIndexToZero,
  };
};

export const useTableRowExpansionState = <T,>(
  rowIdAccessor: (row: T) => string,
  expansionRenderer: (row: T) => React.ReactNode
) => {
  const [itemIdToExpandedRowMap, setMapOfExpandedItems] = useState<Record<string, React.ReactNode>>({});

  const expandItemWithContent = (row: T) => {
    setMapOfExpandedItems({
      ...itemIdToExpandedRowMap,
      [rowIdAccessor(row)]: expansionRenderer(row),
    });
  };

  const collapseItem = (row: T) => {
    const updatedItems: Record<string, React.ReactNode> = {};
    Object.keys(itemIdToExpandedRowMap)
      .filter((key) => key !== rowIdAccessor(row))
      .forEach((key) => {
        updatedItems[`${key}`] = itemIdToExpandedRowMap[`key`];
      });

    setMapOfExpandedItems(updatedItems);
  };

  const collapseAllItems = () => {
    setMapOfExpandedItems({});
  };

  const toggleRowExpansion = (row: T) => {
    if (itemIdToExpandedRowMap[rowIdAccessor(row)]) {
      collapseItem(row);
    } else {
      expandItemWithContent(row);
    }
  };

  return {
    itemIdToExpandedRowMap,
    expandItemWithContent,
    collapseItem,
    collapseAllItems,
    toggleRowExpansion,
  };
};

export const TectonBasicTable = <T,>({
  items,
  itemId,
  itemIdToExpandedRowMap,
  columns,
  pageIndex,
  pageSize,
  totalItemCount,
  sortAndPagination,
  onChange,
  isExpandable,
  rowProps,
  showPerPageOptions,
}: TectonBasicTableProps<T>) => {
  // Don't sort if field is not provided.
  // ... this is here mostly to make Typescript happy
  const sort =
    sortAndPagination && !!sortAndPagination.sort?.field
      ? {
          field: sortAndPagination.sort?.field,
          direction: sortAndPagination.sort?.direction || 'desc',
        }
      : undefined;

  return (
    <div>
      <div className="eui-xScroll">
        <BasicTable
          items={items}
          itemId={itemId}
          isExpandable={isExpandable}
          itemIdToExpandedRowMap={itemIdToExpandedRowMap}
          columns={columns}
          sorting={{
            sort: sort,
            enableAllColumns: true,
          }}
          onChange={onChange}
          rowProps={rowProps}
        />
      </div>
      <TablePagination
        activePage={pageIndex}
        itemsPerPage={pageSize}
        pageCount={Math.ceil(totalItemCount / pageSize)}
        showPerPageOptions={showPerPageOptions || false}
        onChangePage={
          onChange
            ? (index: number) => {
                onChange({
                  ...sortAndPagination,
                  page: {
                    size: pageSize,
                    index,
                  },
                });
              }
            : undefined
        }
      />
    </div>
  );
};

export default TectonBasicTable;
