import styled from '@emotion/styled';
import React, { useCallback, useEffect, useRef } from 'react';

export interface Props {
  className?: string;
  children: React.ReactNode;
  capture?: boolean;
  excludeRefs?: React.RefObject<HTMLElement>[];
  onClick(event: React.MouseEvent<HTMLElement>): void;
}

const ClickAwayWrapper = styled.div`
  display: flex;
  width: calc(100% - 30px);
`;

export const ClickAway: React.FC<Props> = ({ className, onClick, children, capture = false, excludeRefs = [] }) => {
  const ref = useRef<HTMLDivElement>(null);

  const onClickOutside = useCallback(
    (event: MouseEvent): void => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        // Check if the event target is within any of the excluded refs
        const isExcluded = excludeRefs.some(
          (excludeRef) => excludeRef.current && excludeRef.current.contains(event.target as Node)
        );
        if (!isExcluded) {
          const reactMouseEvent = event as unknown as React.MouseEvent<HTMLElement>;
          onClick(reactMouseEvent);
        }
      }
    },
    [excludeRefs, onClick]
  );

  useEffect(() => {
    document.addEventListener('click', onClickOutside, capture);

    return () => {
      document.removeEventListener('click', onClickOutside, capture);
    };
  }, [capture, onClickOutside]);

  return (
    <ClickAwayWrapper className={className} ref={ref}>
      {children}
    </ClickAwayWrapper>
  );
};

export default ClickAway;
