import _ from 'lodash';

export function getIsLoading(state, name) {
  return _.get(state, `loading.${name}`, false) === true;
}

export function isLoadingAnyActions(state) {
  let isLoading = false;
  _.forOwn(state.loading, function (value) {
    if (value === true) {
      isLoading = true;
      return false;
    }
  });
  return isLoading;
}

export function getPathStateAction(state) {
  return _.get(state, 'pathState');
}

export function getPlanIdAction(state) {
  return _.get(state, 'applyLog.planId');
}

export function getUser(state) {
  return _.get(state, 'auth.user');
}

export function getAuth(state) {
  return _.get(state, 'auth.auth');
}

export function getConfig(state) {
  return _.get(state, 'config.config');
}

export function getIsOnboardingEnabled(state) {
  return _.get(state, 'config.isOnboardingEnabled');
}
