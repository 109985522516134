export default () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.9992 14.8067L13.3417 16.5634C13.9925 16.905 14.7525 16.3525 14.6284 15.6284L13.99 11.9067L16.695 9.27253C17.2217 8.75919 16.9317 7.86503 16.2034 7.75919L12.4667 7.21586L10.7959 3.82836C10.4709 3.16919 9.53003 3.16919 9.20503 3.82836L7.53336 7.21586L3.7967 7.75919C3.0692 7.86503 2.77836 8.75919 3.30503 9.27253L6.01003 11.9067L5.3717 15.6284C5.24753 16.3525 6.00753 16.9059 6.65836 16.5634L10.0009 14.8067H9.9992Z"
        stroke="#525866"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
