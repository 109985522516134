import React, { MouseEvent } from 'react';
import { Link, useTectonTheme } from '@tecton';
import { css } from '@emotion/css';

const EditLink = ({
  onClick,
  className,
  dataTestId,
}: {
  onClick: (e: MouseEvent) => void;
  className?: string;
  dataTestId?: string;
}) => {
  const { theme } = useTectonTheme();

  return (
    <Link
      onClick={onClick}
      className={
        css`
          color: ${theme.colors.mediumShade};
          font-size: 12px;
          font-weight: 700;
          letter-spacing: 0.1em;
          text-decoration: underline;
          text-decoration-style: dotted;
        ` + (className ? ` ${className}` : '')
      }
    >
      <span data-testid={dataTestId}>EDIT</span>
    </Link>
  );
};

export default EditLink;
