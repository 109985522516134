export default () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.3033 4.6967C18.2322 7.62563 18.2322 12.3744 15.3033 15.3033C12.3744 18.2322 7.62562 18.2322 4.6967 15.3033C1.76777 12.3744 1.76777 7.62562 4.6967 4.6967C7.62563 1.76777 12.3744 1.76777 15.3033 4.6967"
        stroke="#525866"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.9462 7.0537C14.5734 8.68088 14.5734 11.3191 12.9462 12.9463C11.319 14.5734 8.68082 14.5734 7.05364 12.9463C5.42646 11.3191 5.42646 8.68088 7.05364 7.0537C8.68082 5.42652 11.319 5.42652 12.9462 7.0537"
        stroke="#525866"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.04995 12.95L4.69995 15.3"
        stroke="#525866"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.9417 7.05001L15.3 4.70001"
        stroke="#525866"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.69995 4.70001L7.04995 7.05001"
        stroke="#525866"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.95 12.95L15.3 15.3"
        stroke="#525866"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
