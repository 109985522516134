import { FC } from 'react';
import { MaterializationStatusState } from '../../../api/gql/graphql';
import { Badge, BadgeVariants } from '@tecton/ComponentRedesign';

interface JobsStatusBadgeProps {
  status: MaterializationStatusState;
}

const JobStatusBadge: FC<JobsStatusBadgeProps> = ({ status }) => {
  const variantAndLabel: Record<MaterializationStatusState, { label: string; variant: BadgeVariants }> = {
    [MaterializationStatusState.MaterializationStatusStateDrained]: {
      label: 'Drained',
      variant: 'hollow',
    },
    [MaterializationStatusState.MaterializationStatusStateError]: {
      label: 'Error',
      variant: 'danger',
    },
    [MaterializationStatusState.MaterializationStatusStateManuallyCancelled]: {
      label: 'Manually Cancelled',
      variant: 'warning',
    },
    [MaterializationStatusState.MaterializationStatusStateManualCancellationRequested]: {
      label: 'Cancellation Requested',
      variant: 'accent',
    },
    [MaterializationStatusState.MaterializationStatusStatePending]: {
      label: 'Pending',
      variant: 'accent',
    },
    [MaterializationStatusState.MaterializationStatusStateRunning]: {
      label: 'Running',
      variant: 'primary',
    },
    [MaterializationStatusState.MaterializationStatusStateScheduled]: {
      label: 'Scheduled',
      variant: 'primary',
    },
    [MaterializationStatusState.MaterializationStatusStateSuccess]: {
      label: 'Success',
      variant: 'success',
    },
    [MaterializationStatusState.MaterializationStatusStateUnspecified]: {
      label: 'Unspecified',
      variant: 'disabled',
    },
  };

  return <Badge {...variantAndLabel[status]} />;
};

export default JobStatusBadge;
