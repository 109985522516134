import React, { FC, useState } from 'react';
import { TimeComboSelectorButton, Popover, Selectable } from '@tecton/ComponentRedesign';

export interface TimePeriodSelectorOptions {
  label: string;
  value: number | undefined;
  checked?: 'on' | 'off';
}

const possibleTimePeriodOptions: TimePeriodSelectorOptions[] = [
  {
    label: 'All Time',
    value: undefined,
  },
  {
    label: 'Past Five Minutes',
    value: 60 * 5,
  },
  {
    label: 'Past Fifteen Minutes',
    value: 60 * 15,
  },
  {
    label: 'Past Hour',
    value: 60 * 60,
  },
  {
    label: 'Past Six Hours',
    value: 60 * 60 * 6,
  },
  {
    label: 'Past Day',
    value: 60 * 60 * 24,
  },
  {
    label: 'Past Week',
    value: 60 * 60 * 24 * 7,
  },
  {
    label: 'Past Month',
    value: 60 * 60 * 24 * 30,
  },
  {
    label: 'Past Year',
    value: 60 * 60 * 24 * 365,
  },
  {
    label: 'Past Two Years',
    value: 60 * 60 * 24 * 365 * 2,
  },
  {
    label: 'Past Three Years',
    value: 60 * 60 * 24 * 365 * 2,
  },
  {
    label: 'Past Five Years',
    value: 60 * 60 * 24 * 365 * 5,
  },
];

interface TimePeriodSelectorProps {
  value: TimePeriodSelectorOptions;
  onChange?: (option: TimePeriodSelectorOptions) => void;
  disabled?: boolean;
  maxValue?: number;
}

export const TimePeriodSelector: FC<TimePeriodSelectorProps> = ({ value, onChange, maxValue = 0, disabled }) => {
  // eslint-disable-next-line no-undef

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedOption, setSelectOption] = useState<TimePeriodSelectorOptions>(value);
  const actualOptions = possibleTimePeriodOptions
    .filter((option) => (option.value ? option.value <= maxValue : true))
    .map((option) => {
      return {
        ...option,
        checked: option.label === selectedOption.label ? 'on' : undefined,
      };
    });

  if (disabled) {
    return (
      <TimeComboSelectorButton
        label={selectedOption.label}
        onClick={() => {
          return;
        }}
        disabled={disabled}
      />
    );
  }

  return (
    <Popover
      data-testid="time-period-selector"
      button={
        <TimeComboSelectorButton
          label={selectedOption.label}
          onClick={() => {
            setIsOpen(true);
          }}
        />
      }
      body={
        <div style={{ height: '150px', width: '200px' }}>
          <Selectable
            options={actualOptions as TimePeriodSelectorOptions[]}
            key={'timePeriodSelector'}
            singleSelect
            changeCallback={(options) => {
              const newValue = options.filter((option) => option.checked === 'on')[0];
              setSelectOption(newValue as TimePeriodSelectorOptions);
              setIsOpen(false);
              onChange && onChange(newValue as TimePeriodSelectorOptions);
            }}
            data-testid="time-period-selector-selectable"
          />
        </div>
      }
      isOpen={isOpen}
      closePopover={() => {
        setIsOpen(false);
      }}
    />
  );
};

export default TimePeriodSelector;
