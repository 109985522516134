import { rest } from 'msw';

let statusCounter = 0;
const counterOffset = 1;
const SHOW_ERR = false;

const mswGetDataPlatformSetupStatus = rest.post(
  '/v1/metadata-service/get-data-platform-setup-status',
  (req, res, ctx) => {
    if (typeof req.body !== 'string') {
      return res(
        ctx.status(500),
        ctx.json({
          success: false,
          message: 'No request body',
        })
      );
    }

    if (SHOW_ERR) {
      return res(
        ctx.status(500),
        ctx.json({
          error:
            'java.lang.IllegalStateException: Databricks validation cluster workflow has not yet run, please wait for it to complete.',
          code: 13,
          message:
            'java.lang.IllegalStateException: Databricks validation cluster workflow has not yet run, please wait for it to complete.',
        })
      );
    }
    const statusMap: any = {
      status1: {
        setupCompleted: false,
        tasks: [
          {
            task_display_name: 'Mocked 2 Verify Databricks workspace URL and API key',
            task_status: 'TASK_STATUS_SUCCEEDED',
          },
          { task_display_name: 'Verify instance profile ARN', task_status: 'TASK_STATUS_SUCCEEDED' },
          { task_display_name: 'Create internal validation cluster', task_status: 'TASK_STATUS_RUNNING' },
          { task_display_name: 'Create development notebook cluster', task_status: 'TASK_STATUS_RUNNING' },
        ],
      },
      status2: {
        setupCompleted: false,
        tasks: [
          {
            task_display_name: 'Mocked 2 Verify Databricks workspace URL and API key',
            task_status: 'TASK_STATUS_SUCCEEDED',
          },
          { task_display_name: 'Verify instance profile ARN', task_status: 'TASK_STATUS_SUCCEEDED' },
          {
            task_display_name: 'Create internal validation cluster',
            error_message: 'we have a serious problem here... please fix me',
            task_status: 'TASK_STATUS_FAILED',
          },
          { task_display_name: 'Create development notebook cluster', task_status: 'TASK_STATUS_SUCCEEDED' },
        ],
      },
      status3: {
        setupCompleted: false,
        tasks: [
          {
            task_display_name: 'Mocked 1Verify Databricks workspace URL and API key',
            task_status: 'TASK_STATUS_RUNNING',
          },
          { task_display_name: 'Verify instance profile ARN', task_status: 'TASK_STATUS_RUNNING' },
          { task_display_name: 'Create internal validation cluster', task_status: 'TASK_STATUS_NOT_STARTED' },
          { task_display_name: 'Create development notebook cluster', task_status: 'TASK_STATUS_NOT_STARTED' },
        ],
      },
      status4: {
        setupCompleted: false,
        tasks: [
          {
            task_display_name: 'Mocked 2 Verify Databricks workspace URL and API key',
            task_status: 'TASK_STATUS_SUCCEEDED',
          },
          { task_display_name: 'Verify instance profile ARN', task_status: 'TASK_STATUS_SUCCEEDED' },
          { task_display_name: 'Create internal validation cluster', task_status: 'TASK_STATUS_RUNNING' },
          { task_display_name: 'Create development notebook cluster', task_status: 'TASK_STATUS_RUNNING' },
        ],
      },
      status5: {
        setupCompleted: false,
        tasks: [
          {
            task_display_name: 'Mocked 3 Verify Databricks workspace URL and API key',
            task_status: 'TASK_STATUS_SUCCEEDED',
          },
          { task_display_name: 'Verify instance profile ARN', task_status: 'TASK_STATUS_SUCCEEDED' },
          { task_display_name: 'Create internal validation cluster', task_status: 'TASK_STATUS_RUNNING' },
          { task_display_name: 'Create development notebook cluster', task_status: 'TASK_STATUS_SUCCEEDED' },
        ],
      },
      status6: {
        setupCompleted: false,
        tasks: [
          {
            task_display_name: 'Mocked 4 Verify Databricks workspace URL and API key',
            task_status: 'TASK_STATUS_SUCCEEDED',
          },
          { task_display_name: 'Verify instance profile ARN', task_status: 'TASK_STATUS_SUCCEEDED' },
          { task_display_name: 'Create internal validation cluster', task_status: 'TASK_STATUS_RUNNING' },
          { task_display_name: 'Create development notebook cluster', task_status: 'TASK_STATUS_SUCCEEDED' },
        ],
      },
      status7: {
        setupCompleted: true,
        tasks: [
          {
            task_display_name: 'Mocked 5 Verify Databricks workspace URL and API key',
            task_status: 'TASK_STATUS_SUCCEEDED',
          },
          { task_display_name: 'Verify instance profile ARN', task_status: 'TASK_STATUS_SUCCEEDED' },
          { task_display_name: 'Create internal validation cluster', task_status: 'TASK_STATUS_SUCCEEDED' },
          { task_display_name: 'Create development notebook cluster', task_status: 'TASK_STATUS_SUCCEEDED' },
        ],
      },
    };

    statusCounter = statusCounter + 1;
    const key = Math.ceil(statusCounter / counterOffset);
    const responseJson = statusMap[`status${key}`];
    if (statusCounter === 7 * counterOffset) {
      statusCounter = 0;
    }
    return res(ctx.status(200), ctx.json(responseJson));
  }
);

export default mswGetDataPlatformSetupStatus;
