import { useMutation, useQuery, useQueryClient, QueryClient } from '@tanstack/react-query';

import {
  AssignRolesPutRequest,
  AssignRolesRequest,
  GetAuthorizedResourcesRequest,
  GetIsAuthorizedRequest,
  GetIsAuthorizedResponse,
  ListAssignedPrincipalsRequest,
} from '../types/tecton_proto/auth/authorization_service';
import { PrincipalType } from '../types/tecton_proto/auth/principal';
import { ResourceType } from '../types/tecton_proto/auth/resource';
import { User } from '../types/tecton_proto/data/user';
import {
  ClusterUserActionRequest,
  CreateClusterUserRequest,
  CreateServiceAccountRequest,
  DeleteClusterUserRequest,
  DeleteServiceAccountRequest,
  GetServiceAccountsRequest,
  ServiceAccount,
} from '../types/tecton_proto/metadataservice/metadata_service';
import {
  fetchAddWorkspaceToServiceAccount,
  fetchAssignedPrincipals,
  fetchAssignedRoles,
  fetchClusterAdminInfo,
  fetchDeleteServiceAccount,
  fetchServiceAccount,
  fetchUpdateServiceAccount,
  fetchCreateServiceAccount,
  fetchInviteUser,
  fetchDeleteUser,
  fetchClusterUserAction,
  fetchAssignedPrincipalsUser,
  fetchAssignRole,
  fetchUpdateAccountTypeToRegular,
  fetchGetGroups,
  fetchDeleteGroup,
  fetchCreateGroup,
  fetchGetIsAuthorize,
  fetchUpdatePrincipalGroup,
  fetchListPrincipalGroupMembers,
  fetchAddPrincipalGroupMembers,
  fetchRemovePrincipalGroupMembers,
  fetchGetRoles,
  fetchListPrincipalGroupsForPrincipal,
  fetchGetAuthorizedResources,
} from './aclFetch';
import {
  AddPrincipalGroupMembersRequest,
  CreatePrincipalGroupRequest,
  DeletePrincipalGroupsRequest,
  ListPrincipalGroupMembersRequest,
  ListPrincipalGroupsDetailedRequest,
  ListPrincipalGroupsDetailedResponse,
  PrincipalGroupDetailed,
  RemovePrincipalGroupMembersRequest,
  UpdatePrincipalGroupRequest,
} from '../types/tecton_proto/principal/principal_service';
import { ACLGroupWorkspaceType } from '../components/acl/types';
import { ACLReactQueryKeyEnum } from './queries/aclGraphql';

// Account & Access
export function useServiceAccountsAssignedPrincipals(enabled: boolean = true) {
  const payload: ListAssignedPrincipalsRequest = {
    resource_type: ResourceType.RESOURCE_TYPE_ORGANIZATION,
    principal_types: [PrincipalType.PRINCIPAL_TYPE_SERVICE_ACCOUNT],
  };
  const payloadOptions = {
    enabled,
  };

  return useGetServiceAccountAssignedPrincipals(['getServiceAccountsAssignedPrincipals'], payload, payloadOptions);
}

export const useGetIsAuthorize = (enabled: boolean, payload: GetIsAuthorizedRequest) => {
  return useQuery(
    [
      'useGetIsAuthorize',
      payload.principal_id,
      payload.principal_type,
      payload?.permissions
        ?.map((i) => {
          const { resource_id, resource_type, action } = i;
          return [resource_id, resource_type, action].join(',');
        })
        .join(',') ?? '',
    ],
    () => {
      return fetchGetIsAuthorize(payload);
    },
    {
      select: (response: GetIsAuthorizedResponse) => {
        return response?.permissions?.map((i) => i.action);
      },
      enabled,
      cacheTime: Infinity,
    }
  );
};

export function useAccessControlGetPermissionsForWorkspace(workspace: string) {
  const payload: ListAssignedPrincipalsRequest = {
    resource_type: ResourceType.RESOURCE_TYPE_WORKSPACE,
    resource_id: workspace,
    principal_types: [PrincipalType.PRINCIPAL_TYPE_USER],
  };

  const options = {
    enabled: workspace !== '',
  };

  const state = useGetUserPrincipals(
    [
      'accessControlGetPermissionsForWorkspace',
      workspace,
      ResourceType.RESOURCE_TYPE_WORKSPACE,
      PrincipalType.PRINCIPAL_TYPE_USER,
    ],
    payload,
    options
  );
  return state;
}

export const useGetAllAssignedPrincipals = (workspace: string, isACLGroupsEnabled: boolean) => {
  const principal_types = [PrincipalType.PRINCIPAL_TYPE_USER, PrincipalType.PRINCIPAL_TYPE_SERVICE_ACCOUNT];

  if (isACLGroupsEnabled) {
    principal_types.push(PrincipalType.PRINCIPAL_TYPE_GROUP);
  }
  const payload = {
    resource_type: ResourceType.RESOURCE_TYPE_WORKSPACE,
    principal_types,
    resource_id: workspace,
  };

  return useQuery(
    ['getAllAssignedPrincipals', `${workspace}`],
    () => {
      return fetchAssignedPrincipals(payload);
    },
    { enabled: !!workspace }
  );
};

export const useGetUserAssignedPrincipals = () => {
  const payload = {
    resource_type: ResourceType.RESOURCE_TYPE_ORGANIZATION,
    principal_types: [PrincipalType.PRINCIPAL_TYPE_USER],
  };

  const state = useGetUserPrincipals(
    ['userAssignedPrincipals', ResourceType.RESOURCE_TYPE_ORGANIZATION, PrincipalType.PRINCIPAL_TYPE_USER],
    payload
  );
  return state;
};

// options are only enabled
export const useGetUserPrincipals = (
  keys: string[],
  payload: ListAssignedPrincipalsRequest,
  options: { enabled: boolean } = { enabled: true }
) => {
  return useQuery(
    keys,
    () => {
      return fetchAssignedPrincipalsUser(payload);
    },
    options
  );
};

export const useGetServiceAccountAssignedPrincipals = (
  keys: string[],
  payload: ListAssignedPrincipalsRequest,
  options: any = {}
) => {
  return useQuery(
    keys,
    () => {
      return fetchAssignedPrincipals(payload);
    },
    options
  );
};

export const useClusterAdminInfo = (select: any = (data: User[]) => data) => {
  return useQuery(
    ['getAdminInfo'],
    () => {
      return fetchClusterAdminInfo();
    },
    {
      select,
    }
  );
};

export const useInviteUserMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (assignRole: CreateClusterUserRequest) => {
      return await fetchInviteUser(assignRole);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['getAdminInfo']);
        queryClient.invalidateQueries([ACLReactQueryKeyEnum.getAClUsersTableDataGQL]);
      },
    }
  );
};

export const useDeleteUserMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (user: DeleteClusterUserRequest) => {
      return await fetchDeleteUser(user);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['getAdminInfo']);
        queryClient.invalidateQueries(['getUserAssignedWorkspace']);
        queryClient.invalidateQueries([ACLReactQueryKeyEnum.getAClUsersTableDataGQL]);
        queryClient.invalidateQueries([ACLReactQueryKeyEnum.getAClUserDetails]);
      },
    }
  );
};

export const useClusterUserActionMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (user: ClusterUserActionRequest) => {
      return await fetchClusterUserAction(user);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['getAdminInfo']);
        queryClient.invalidateQueries(['getUserAssignedWorkspace']);
        queryClient.invalidateQueries(['userAssignedPrincipals']);
        queryClient.invalidateQueries([ACLReactQueryKeyEnum.getAClUserDetails]);
        queryClient.invalidateQueries([ACLReactQueryKeyEnum.getAClUsersTableDataGQL]);
      },
    }
  );
};

export const useGetUserAssignedWorkspace = (principal_id: string | undefined) => {
  const payload = {
    principal_id,
    principal_type: PrincipalType.PRINCIPAL_TYPE_USER,
    resource_type: ResourceType.RESOURCE_TYPE_WORKSPACE,
  };
  const state = useQuery(
    ['getUserAssignedWorkspace', principal_id],
    () => {
      return fetchAssignedRoles(payload);
    },
    {
      enabled: !!principal_id, // No principal ID, don't send out a request
    }
  );
  return state;
};

export const useGetServiceAccountAssignedWorkspace = (principal_id: string | undefined) => {
  const payload = {
    principal_id,
    principal_type: PrincipalType.PRINCIPAL_TYPE_SERVICE_ACCOUNT,
    resource_type: ResourceType.RESOURCE_TYPE_WORKSPACE,
  };
  return useQuery(
    ['getServiceAccountAssignedWorkspace', principal_id],
    () => {
      return fetchAssignedRoles(payload);
    },
    {
      enabled: !!principal_id, // No principal ID, don't send out a request
    }
  );
};

export const useGetServiceAccountByIds = (serviceAccountIds: string[] | undefined) => {
  const payload = { ids: serviceAccountIds } as GetServiceAccountsRequest;

  return useQuery(['getServiceAccountById', serviceAccountIds?.join('')], () => {
    return fetchServiceAccount(payload);
  });
};

export const useGetAllServiceAccounts = () => {
  const payload = {} as GetServiceAccountsRequest;

  return useQuery(['getAllServiceAccounts'], () => {
    return fetchServiceAccount(payload);
  });
};

export const useMutateServiceAccount = () => {
  const queryClient = useQueryClient();

  const serviceAccountMutation = useMutation(
    async (serviceAccount: ServiceAccount) => {
      const result = await fetchUpdateServiceAccount(serviceAccount);
      return result;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['serviceAccounts']);
        queryClient.invalidateQueries(['getAppPermissions']);
        queryClient.invalidateQueries(['getAllServiceAccounts']);
        queryClient.invalidateQueries(['getServiceAccountById']);
        queryClient.invalidateQueries(['getServiceAccountsAssignedPrincipals']);
        queryClient.invalidateQueries([ACLReactQueryKeyEnum.getACLServiceAccountsTableGQL]);
        queryClient.invalidateQueries([ACLReactQueryKeyEnum.getAClServiceAccountDetails]);
      },
    }
  );

  return serviceAccountMutation;
};

export const useDeleteServiceAccount = () => {
  const queryClient = useQueryClient();

  const deleteServiceAccount = useMutation(
    async (serviceAccountId: DeleteServiceAccountRequest) => {
      const result = await fetchDeleteServiceAccount(serviceAccountId);
      return result;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['serviceAccounts']);
        queryClient.invalidateQueries(['getAllServiceAccounts']);
        queryClient.invalidateQueries(['getServiceAccountsAssignedPrincipals']);
        queryClient.invalidateQueries([ACLReactQueryKeyEnum.getACLServiceAccountsTableGQL]);
      },
    }
  );

  return deleteServiceAccount;
};

const invalidateServiceAccountCache = (queryClient: QueryClient) => {
  queryClient.invalidateQueries(['serviceAccounts']);
  queryClient.invalidateQueries(['getAllServiceAccounts']);
  queryClient.invalidateQueries(['getServiceAccountsAssignedPrincipals']);
  queryClient.invalidateQueries(['getServiceAccountAssignedWorkspace']);
  queryClient.invalidateQueries(['list-workspaces']);
  queryClient.invalidateQueries(['getUserAssignedWorkspace']);
  queryClient.invalidateQueries(['getGroups']);
  queryClient.invalidateQueries(['listPrincipalGroupsForPrincipal']);
  queryClient.invalidateQueries(['userAssignedPrincipals']);
  queryClient.invalidateQueries(['getAllAssignedPrincipals']);
  queryClient.invalidateQueries([ACLReactQueryKeyEnum.getACLServiceAccountsTableGQL]);
  queryClient.invalidateQueries([ACLReactQueryKeyEnum.getAClUserDetails]);
  queryClient.invalidateQueries([ACLReactQueryKeyEnum.getAClServiceAccountDetails]);
  queryClient.invalidateQueries([ACLReactQueryKeyEnum.getACLGroupDetailsGQL]);
  queryClient.invalidateQueries([ACLReactQueryKeyEnum.getACLGroupsTableGQL]);
  queryClient.invalidateQueries([ACLReactQueryKeyEnum.getPermissions]);
};

export const useAssignRolesMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (assignRole: AssignRolesPutRequest) => {
      return await fetchAddWorkspaceToServiceAccount(assignRole);
    },
    {
      onSuccess: () => {
        invalidateServiceAccountCache(queryClient);
      },
    }
  );
};

export const useAssignRoleMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (assignRole: AssignRolesRequest) => {
      return await fetchAssignRole(assignRole);
    },
    {
      onSuccess: () => {
        invalidateServiceAccountCache(queryClient);
      },
    }
  );
};

export const useUpdateAccountTypeMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (assignRole: AssignRolesRequest) => {
      return await fetchUpdateAccountTypeToRegular(assignRole);
    },
    {
      onSuccess: () => {
        invalidateServiceAccountCache(queryClient);
      },
    }
  );
};

export const useCreateServiceAccountMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (assignRole: CreateServiceAccountRequest) => {
      return await fetchCreateServiceAccount(assignRole);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['getAllServiceAccounts']);
        queryClient.invalidateQueries(['getAuthorizedResources']);
        queryClient.invalidateQueries([ACLReactQueryKeyEnum.getACLServiceAccountsTableGQL]);
      },
    }
  );
};

export const useGetGroups = (payload: ListPrincipalGroupsDetailedRequest, options: any = {}) => {
  const completeOptions = {
    ...options,
    select: (listPrincipalGroupsDetailedResponse: ListPrincipalGroupsDetailedResponse) => {
      // Transform data
      const groups =
        listPrincipalGroupsDetailedResponse?.groups?.map((principalGroupDetailed: PrincipalGroupDetailed) => {
          const {
            id,
            name,
            account_type,
            num_members,
            workspaces,
            description,
            created_at,
            idp_mapping_names,
            is_membership_editable,
          } = principalGroupDetailed;

          const transformedWorkspace =
            workspaces?.map((ws) => {
              const hasPriority = ws.resource_type === ResourceType.RESOURCE_TYPE_ORGANIZATION;
              const role = ws?.roles_granted?.[0]?.role ?? 'none';
              const workspaceId = hasPriority ? 'All Workspaces' : ws?.resource_id;

              return {
                workspaceId,
                role,
                hasPriority: hasPriority,
              } as ACLGroupWorkspaceType;
            }) ?? [];

          if (!workspaces?.find((i) => i.resource_type === ResourceType.RESOURCE_TYPE_ORGANIZATION)) {
            // We didn't find "All Workspaces" so we need to create one since it's a special type of workspace
            transformedWorkspace.push({
              workspaceId: 'All Workspaces',
              role: 'none',
              hasPriority: true,
            });
          }

          return {
            groupId: id ?? undefined,
            name: name ?? undefined,
            description,
            created_at,
            accountType: account_type === 'Admin' ? 'Admin' : 'Regular',
            members_count: num_members ?? 0,
            workspace_count: workspaces?.length ?? 0,
            workspaces: transformedWorkspace,
            idp_mapping_names,
            is_membership_editable,
          };
        }) ?? [];

      return groups;
    },
  };
  return useQuery(
    ['getGroups', payload],
    () => {
      return fetchGetGroups(payload);
    },
    completeOptions
  );
};

export const useCreateGroupMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (createPrincipalGroupRequest: CreatePrincipalGroupRequest) => {
      return await fetchCreateGroup(createPrincipalGroupRequest);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['getGroups']);
        queryClient.invalidateQueries([ACLReactQueryKeyEnum.getACLGroupsTableGQL]);
        queryClient.invalidateQueries([ACLReactQueryKeyEnum.getAClUserDetails]);
        queryClient.invalidateQueries([ACLReactQueryKeyEnum.getAClServiceAccountDetails]);
      },
    }
  );
};

export const useDeleteGroupMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (createPrincipalGroupRequest: DeletePrincipalGroupsRequest) => {
      return await fetchDeleteGroup(createPrincipalGroupRequest);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['getGroups']);
        queryClient.invalidateQueries([ACLReactQueryKeyEnum.getACLGroupsTableGQL]);
        queryClient.invalidateQueries([ACLReactQueryKeyEnum.getACLGroupDetailsGQL]);
        queryClient.invalidateQueries([ACLReactQueryKeyEnum.getAClUserDetails]);
        queryClient.invalidateQueries([ACLReactQueryKeyEnum.getAClServiceAccountDetails]);
      },
    }
  );
};

export const useUpdatePrincipalGroupMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (updatePrincipalGroupRequest: UpdatePrincipalGroupRequest) => {
      return await fetchUpdatePrincipalGroup(updatePrincipalGroupRequest);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['getGroups']);
        queryClient.invalidateQueries([ACLReactQueryKeyEnum.getACLGroupsTableGQL]);
        queryClient.invalidateQueries([ACLReactQueryKeyEnum.getACLGroupDetailsGQL]);
      },
    }
  );
};

export const useListGroupMembers = (payload: ListPrincipalGroupMembersRequest, options: any = {}) => {
  const completeOptions = {
    ...options,
  };
  return useQuery(
    ['listPrincipalGroupMembers', `${payload?.id ?? ''}`],
    () => {
      return fetchListPrincipalGroupMembers(payload);
    },
    completeOptions
  );
};

export const useAddPrincipalGroupMembersMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (addPrincipalGroupMembers: AddPrincipalGroupMembersRequest) => {
      return await fetchAddPrincipalGroupMembers(addPrincipalGroupMembers);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['listPrincipalGroupMembers']);
        queryClient.invalidateQueries(['listPrincipalGroupsForPrincipal']);
        queryClient.invalidateQueries(['getAdminInfo']);
        queryClient.invalidateQueries(['userAssignedPrincipals']);
        queryClient.invalidateQueries(['getAllAssignedPrincipals']);
        queryClient.invalidateQueries(['getServiceAccountsAssignedPrincipals']);
        queryClient.invalidateQueries(['getGroups']);
        queryClient.invalidateQueries([ACLReactQueryKeyEnum.getAClUserDetails]);
        queryClient.invalidateQueries([ACLReactQueryKeyEnum.getAClUsersTableDataGQL]);
        queryClient.invalidateQueries([ACLReactQueryKeyEnum.getAClServiceAccountDetails]);
        queryClient.invalidateQueries([ACLReactQueryKeyEnum.getACLGroupDetailsGQL]);
      },
    }
  );
};

export const useRemovePrincipalGroupMembersMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (addPrincipalGroupMembers: RemovePrincipalGroupMembersRequest) => {
      return await fetchRemovePrincipalGroupMembers(addPrincipalGroupMembers);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['listPrincipalGroupMembers']);
        queryClient.invalidateQueries(['listPrincipalGroupsForPrincipal']);
        queryClient.invalidateQueries(['getAdminInfo']);
        queryClient.invalidateQueries(['userAssignedPrincipals']);
        queryClient.invalidateQueries(['getServiceAccountsAssignedPrincipals']);
        queryClient.invalidateQueries(['getGroups']);
        queryClient.invalidateQueries([ACLReactQueryKeyEnum.getAClUserDetails]);
        queryClient.invalidateQueries([ACLReactQueryKeyEnum.getAClUsersTableDataGQL]);
        queryClient.invalidateQueries([ACLReactQueryKeyEnum.getAClServiceAccountDetails]);
        queryClient.invalidateQueries([ACLReactQueryKeyEnum.getACLGroupDetailsGQL]);
      },
    }
  );
};

export const useGetRoles = () => {
  return useQuery(
    ['getRoles'],
    () => {
      return fetchGetRoles();
    },
    {
      // We only need to call this once per session since roles don't change that often
      cacheTime: Infinity,
    }
  );
};

export const useListPrincipalGroupsForPrincipal = (
  principal_id: string,
  principal_type: PrincipalType,
  isEnabled: boolean
) => {
  const payload = {
    principal_id,
    principal_type,
  };

  return useQuery(
    ['listPrincipalGroupsForPrincipal', principal_id],
    () => {
      return fetchListPrincipalGroupsForPrincipal(payload);
    },
    {
      // We only need to call this once per session since roles don't change that often
      enabled: !!principal_id && isEnabled,
    }
  );
};

export const useGetAuthorizedResources = (payload: GetAuthorizedResourcesRequest) => {
  const values = Object.values(payload).join(`-`);
  return useQuery(
    ['getAuthorizedResources', values],
    () => {
      return fetchGetAuthorizedResources(payload);
    },
    {
      enabled: !!payload?.principal_id,
    }
  );
};
