import React, { FC } from 'react';

import { ReactComponent as AWS } from './Icons/Partner/AWS.svg';
import { ReactComponent as BigQuery } from './Icons/Partner/BigQuery.svg';
import { ReactComponent as Databricks } from './Icons/Partner/Databricks.svg';
import { ReactComponent as Drools } from './Icons/Partner/Drools.svg';
import { ReactComponent as Hive } from './Icons/Partner/Hive.svg';
import { ReactComponent as Kafka } from './Icons/Partner/Kafka.svg';
import { ReactComponent as Kinesis } from './Icons/Partner/Kinesis.svg';
import { ReactComponent as Python } from './Icons/Partner/Python.svg';
import { ReactComponent as Pytorch } from './Icons/Partner/Pytorch.svg';
import { ReactComponent as Redshift } from './Icons/Partner/Redshift.svg';
import { ReactComponent as S3 } from './Icons/Partner/S3.svg';
import { ReactComponent as Snowflake } from './Icons/Partner/Snowflake.svg';
import { ReactComponent as SqlServer } from './Icons/Partner/SqlServer.svg';
import { ReactComponent as Tecton } from './Icons/Partner/Tecton.svg';
import { ReactComponent as TensorFlow } from './Icons/Partner/TensorFlow.svg';
import { ReactComponent as VSCode } from './Icons/Partner/VSCode.svg';
import { ReactComponent as XGBoost } from './Icons/Partner/XGBoost.svg';
import { IconSize, IconSizeMap } from './Icon';
import styled from '@emotion/styled';

type PartnerIconType =
  | 'AWS'
  | 'BigQuery'
  | 'Databricks'
  | 'Drools'
  | 'Hive'
  | 'Kafka'
  | 'Kinesis'
  | 'Python'
  | 'Pytorch'
  | 'Redshift'
  | 'S3'
  | 'Snowflake'
  | 'SqlServer'
  | 'Tecton'
  | 'TensorFlow'
  | 'VSCode'
  | 'XGBoost';

interface PartnerIconProps {
  icon: PartnerIconType;
  size?: IconSize;
}

const IconWrapper = styled.div<{ size: IconSize }>`
  display: inline-block;
  line-height: 0;

  width: ${({ size }) => {
    return IconSizeMap[size];
  }};
  height: ${({ size }) => {
    return IconSizeMap[size];
  }};

  svg {
    width: 100%;
    height: 100%;
  }
`;

const Icons: Record<PartnerIconType, React.ReactNode> = {
  AWS: <AWS />,
  BigQuery: <BigQuery />,
  Databricks: <Databricks />,
  Drools: <Drools />,
  Hive: <Hive />,
  Kafka: <Kafka />,
  Kinesis: <Kinesis />,
  Python: <Python />,
  Pytorch: <Pytorch />,
  Redshift: <Redshift />,
  S3: <S3 />,
  Snowflake: <Snowflake />,
  SqlServer: <SqlServer />,
  Tecton: <Tecton />,
  TensorFlow: <TensorFlow />,
  VSCode: <VSCode />,
  XGBoost: <XGBoost />,
};

const PartnerIcon: FC<PartnerIconProps> = ({ icon, size = 'm' }) => {
  return <IconWrapper size={size}>{Icons[icon]}</IconWrapper>;
};

export default PartnerIcon;
