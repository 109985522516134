import { RoleDefinition } from '../../types/tecton_proto/auth/authorization_service';
import { PrincipalType } from '../../types/tecton_proto/auth/principal';
import { ResourceType } from '../../types/tecton_proto/auth/resource';
import { OptionsInfo } from '../shared/RowSelectorFormRow';
import { Text } from '@tecton';
import { AccessTable } from '../workspace/WorkspaceRoleSelectorFormRow';
import _uniqBy from 'lodash/uniqBy';
import React from 'react';
import { RoleDefinition as RoleDefinitionGQL } from '../../api/gql/graphql';
import { AccessTableGQL } from '../workspace/WorkspaceRoleSelectorFormRowGQL';

export const getRoles = (roles?: RoleDefinition[], principalType?: PrincipalType): RoleDefinition[] => {
  const serviceAccountRoles =
    roles?.filter((role) => {
      // We want a PrincipalType of PRINCIPAL_TYPE_SERVICE_ACCOUNT
      const assignableToPrincipalTypes = role?.assignable_to_principal_types?.find((type: PrincipalType) => {
        return type === principalType;
      });
      // Also want ResourceType of RESOURCE_TYPE_WORKSPACE
      const assignableOnResourceTypes = role?.assignable_on_resource_types?.find((resourceType: ResourceType) => {
        return resourceType === ResourceType.RESOURCE_TYPE_WORKSPACE;
      });

      return assignableToPrincipalTypes && assignableOnResourceTypes;
    }) ?? [];

  return serviceAccountRoles;
};

export const getRolesGQL = (roles?: RoleDefinitionGQL[], principalType?: PrincipalType): RoleDefinitionGQL[] => {
  const serviceAccountRoles =
    roles?.filter((role) => {
      // We want a PrincipalType of PRINCIPAL_TYPE_SERVICE_ACCOUNT
      const assignableToPrincipalTypes = role?.assignableToPrincipalTypes?.find((type) => {
        return type == principalType;
      });
      // Also want ResourceType of RESOURCE_TYPE_WORKSPACE
      const assignableOnResourceTypes = role?.assignableOnResourceTypes?.find((resourceType) => {
        return resourceType == ResourceType.RESOURCE_TYPE_WORKSPACE;
      });

      return assignableToPrincipalTypes && assignableOnResourceTypes;
    }) ?? [];

  return serviceAccountRoles;
};

export const getAllRolesRecords = (roles: RoleDefinition[]): OptionsInfo<string>[] => {
  // Get all permissions
  const allPermission = roles
    ?.map((role) => {
      return [...(role?.permissions ?? [])];
    })
    .flat();

  // Get only unique permission so we can display it on the None option
  const uniqPermissions = _uniqBy(allPermission, 'description').map((p) => {
    const copyPermissions = { ...p };
    copyPermissions.is_authorized = false;
    return copyPermissions;
  });

  const allRoles: OptionsInfo<string>[] =
    roles?.map((role) => {
      const { id, name, description } = role;
      return {
        id: id ?? '',
        name: name!,
        description: description!,
        detailDescription: () => {
          return (
            <Text>
              <h4>As {name}, they will be able to:</h4>
              <AccessTable permissions={role?.permissions ?? []} />
            </Text>
          );
        },
      };
    }) ?? [];

  // Add the None option and add all the permissions with no access
  allRoles.push({
    id: 'none',
    name: 'None',
    description: 'No access',
    detailDescription: (userId: string) => (
      <Text>
        <h4>{userId} will have no access.</h4>
        <AccessTable permissions={uniqPermissions} />
      </Text>
    ),
  });
  return allRoles;
};

// Can't do this in GQL since it has react stuff in it.
export const getAllRolesRecordsGQL = (roles: RoleDefinitionGQL[]): OptionsInfo<string>[] => {
  // Get all permissions
  const allPermission = roles?.flatMap((role) => {
    return [...(role?.permissions ?? [])];
  });

  // Get only unique permission so we can display it on the None option
  const uniqPermissions = _uniqBy(allPermission, 'description').map((p) => {
    const copyPermissions = { ...p };
    copyPermissions.isAuthorized = false;
    return copyPermissions;
  });

  const allRoles: OptionsInfo<string>[] =
    roles?.map((role) => {
      const { id, name, description } = role;
      return {
        id: id ?? '',
        name: name!,
        description: description!,
        detailDescription: () => {
          return (
            <Text>
              <h4>As {name}, they will be able to:</h4>
              <AccessTableGQL permissions={role?.permissions ?? []} />
            </Text>
          );
        },
      };
    }) ?? [];

  // Add the None option and add all the permissions with no access
  allRoles.push({
    id: 'none',
    name: 'None',
    description: 'No access',
    detailDescription: (userId: string) => (
      <Text>
        <h4>{userId} will have no access.</h4>
        <AccessTableGQL permissions={uniqPermissions} />
      </Text>
    ),
  });
  return allRoles;
};
