/* eslint-disable */
import { Duration } from "../../google/protobuf/duration";
import { CustomComparator, FcoPropertyRenderingType } from "../args/diff_options";
import { FcoArgs } from "../args/fco_args";
import { FeatureRepoSourceInfo } from "../args/repo_metadata";
import { Principal, PrincipalBasic } from "../auth/principal";
import { Id } from "../common/id";

export const protobufPackage = "tecton_proto.data";

export enum FcoTransitionType {
  /** UNKNOWN - protolint:disable:this ENUM_FIELD_NAMES_ZERO_VALUE_END_WITH */
  UNKNOWN = "UNKNOWN",
  CREATE = "CREATE",
  DELETE = "DELETE",
  /**
   * UPGRADE - UPGRADE represents Tecton internal changes/migrations. Can be though of as
   * a special case of UPDATE
   */
  UPGRADE = "UPGRADE",
  /** UPDATE - See FcoTransitionSideEffects for special cases */
  UPDATE = "UPDATE",
  RECREATE = "RECREATE",
  UNCHANGED = "UNCHANGED",
}

export enum FcoTransitionSideEffectStreamRestartType {
  /** RESTART_STREAM_NONE - protolint:disable:this ENUM_FIELD_NAMES_ZERO_VALUE_END_WITH */
  RESTART_STREAM_NONE = "RESTART_STREAM_NONE",
  /**
   * RESTART_STREAM_REUSE_CHECKPOINTS - RESTART_STREAM_REUSE_CHECKPOINTS is a special case of UPDATE that is only
   * used for stream FVs, and only happens when recreates are suppressed, but we
   * still need to restart the current stream job to pick up the new feature
   * definition.
   */
  RESTART_STREAM_REUSE_CHECKPOINTS = "RESTART_STREAM_REUSE_CHECKPOINTS",
  /**
   * RESTART_STREAM_CHECKPOINTS_INVALIDATED - Same as RESTART_STREAM but checkpoints for the stream FV are no longer
   * valid
   */
  RESTART_STREAM_CHECKPOINTS_INVALIDATED = "RESTART_STREAM_CHECKPOINTS_INVALIDATED",
}

export enum MaterializationTaskDiffDestination {
  MATERIALIZATION_TASK_DIFF_DESTINATION_UNSPECIFIED = "MATERIALIZATION_TASK_DIFF_DESTINATION_UNSPECIFIED",
  MATERIALIZATION_TASK_DIFF_DESTINATION_OFFLINE = "MATERIALIZATION_TASK_DIFF_DESTINATION_OFFLINE",
  MATERIALIZATION_TASK_DIFF_DESTINATION_ONLINE = "MATERIALIZATION_TASK_DIFF_DESTINATION_ONLINE",
  MATERIALIZATION_TASK_DIFF_DESTINATION_ONLINE_AND_OFFLINE = "MATERIALIZATION_TASK_DIFF_DESTINATION_ONLINE_AND_OFFLINE",
  MATERIALIZATION_TASK_DIFF_DESTINATION_BULK_LOAD_ONLINE = "MATERIALIZATION_TASK_DIFF_DESTINATION_BULK_LOAD_ONLINE",
}

export enum IntegrationTestJobStatus {
  JOB_STATUS_UNSPECIFIED = "JOB_STATUS_UNSPECIFIED",
  JOB_STATUS_NOT_STARTED = "JOB_STATUS_NOT_STARTED",
  JOB_STATUS_RUNNING = "JOB_STATUS_RUNNING",
  JOB_STATUS_CANCELLED = "JOB_STATUS_CANCELLED",
  JOB_STATUS_SUCCEED = "JOB_STATUS_SUCCEED",
  JOB_STATUS_FAILED = "JOB_STATUS_FAILED",
}

export enum PlanIntegrationTestSelectType {
  UNSPECIFIED = "UNSPECIFIED",
  AUTO = "AUTO",
  NONE = "NONE",
  /** SELECTED_FEATURE_VIEWS - can be exact FV names, prefixes(pre1*), prefix+suffix (pre*suf) */
  SELECTED_FEATURE_VIEWS = "SELECTED_FEATURE_VIEWS",
}

export enum PlanStatusType {
  PLAN_UNSPECIFIED = "PLAN_UNSPECIFIED",
  PLAN_CREATED = "PLAN_CREATED",
  PLAN_INTEGRATION_TESTS_SKIPPED = "PLAN_INTEGRATION_TESTS_SKIPPED",
  PLAN_INTEGRATION_TESTS_NOT_STARTED = "PLAN_INTEGRATION_TESTS_NOT_STARTED",
  PLAN_INTEGRATION_TESTS_RUNNING = "PLAN_INTEGRATION_TESTS_RUNNING",
  PLAN_INTEGRATION_TESTS_CANCELLED = "PLAN_INTEGRATION_TESTS_CANCELLED",
  PLAN_INTEGRATION_TESTS_SUCCEED = "PLAN_INTEGRATION_TESTS_SUCCEED",
  PLAN_INTEGRATION_TESTS_FAILED = "PLAN_INTEGRATION_TESTS_FAILED",
  PLAN_APPLIED = "PLAN_APPLIED",
  PLAN_APPLY_FAILED = "PLAN_APPLY_FAILED",
}

export interface FcoPropertyDiff {
  property_name?: string | undefined;
  val_existing?: string | undefined;
  val_declared?: string | undefined;
  rendering_type?: FcoPropertyRenderingType | undefined;
  custom_comparator?: CustomComparator | undefined;
}

/**
 * Special case options for FCO transitions, e.g. an UPDATE transition may need
 * to trigger a stream restart. Can be extended in the future, e.g. by adding
 * fields such as RecreateOfflineStore etc.
 */
export interface FcoTransitionSideEffects {
  /**
   * Set if this transition should trigger a stream restart that it does not
   * normally. Only set for UPDATE transitions. Not set for RECREATE
   * transitions, which always trigger stream restarts.
   */
  stream_restart_type?: FcoTransitionSideEffectStreamRestartType | undefined;
}

export interface BatchMaterializationTaskDiff {
  display_string?: string | undefined;
  schedule_interval?: Duration | undefined;
  destination?: MaterializationTaskDiffDestination | undefined;
}

export interface StreamMaterializationTaskDiff {
  display_string?: string | undefined;
}

export interface BackfillMaterializationTaskDiff {
  display_string?: string | undefined;
  feature_start_time?: string | undefined;
  feature_end_time?: string | undefined;
  number_of_jobs?: number | undefined;
  destination?: MaterializationTaskDiffDestination | undefined;
}

export interface PlanIntegrationTestTaskDiff {
  display_string?: string | undefined;
  feature_view_name?: string | undefined;
}

export interface MaterializationInfo {
  backfill_task_diffs?: BackfillMaterializationTaskDiff[] | undefined;
  batch_task_diff?: BatchMaterializationTaskDiff | undefined;
  stream_task_diff?: StreamMaterializationTaskDiff | undefined;
  backfill_publish_task_diffs?: BackfillFeaturePublishTaskDiff[] | undefined;
  integration_test_task_diffs?: PlanIntegrationTestTaskDiff[] | undefined;
}

export interface BackfillFeaturePublishTaskDiff {
  display_string?:
    | string
    | undefined;
  /** feature_start/end_time defines start and end time of feature data to be published */
  feature_start_time?: string | undefined;
  feature_end_time?: string | undefined;
  number_of_jobs?: number | undefined;
}

export interface FcoDiff {
  type?: FcoTransitionType | undefined;
  transition_side_effects?: FcoTransitionSideEffects | undefined;
  existing_args?: FcoArgs | undefined;
  declared_args?:
    | FcoArgs
    | undefined;
  /** protolint:disable:this REPEATED_FIELD_NAMES_PLURALIZED */
  diff?: FcoPropertyDiff[] | undefined;
  materialization_info?: MaterializationInfo | undefined;
}

/** Reference to a FCO definition or a specific field in the definition */
export interface FcoFieldRef {
  fco_id?: Id | undefined;
}

/**
 * Errors include references to FCOs so that CLI can render all kinds of helpful
 * diagnostics
 */
export interface ValidationMessage {
  message?: string | undefined;
  fco_refs?: FcoFieldRef[] | undefined;
}

export interface ValidationResult {
  errors?: ValidationMessage[] | undefined;
  warnings?: ValidationMessage[] | undefined;
}

export interface SuccessfulPlanOutput {
  string_output?: string | undefined;
  json_output?:
    | string
    | undefined;
  /**
   * At the time of NewStateUpdate execution, the server doesn't know if it was
   * tecton plan or apply was run, so leaves it up to the thin client to decide
   * whether to print these warnings about applying to a live workspace
   */
  apply_warnings?:
    | string
    | undefined;
  /**
   * To emit amplitude metrics from the client (this should eventually be moved
   * to the server side)
   */
  num_fcos_changed?: number | undefined;
  num_warnings?: number | undefined;
  test_summaries?: PlanIntegrationTestSummary[] | undefined;
  plan_url?: string | undefined;
}

export interface IntegrationTestJobSummary {
  /** TODO add more, such as num_materialized_rows, job_start_time, job_end_time */
  status?: IntegrationTestJobStatus | undefined;
}

export interface PlanIntegrationTestSummary {
  feature_view_id?: Id | undefined;
  job_summaries?: IntegrationTestJobSummary[] | undefined;
  feature_view_name?: string | undefined;
}

export interface PlanIntegrationTestConfig {
  auto_apply_upon_test_success?: boolean | undefined;
  feature_view_names?: string[] | undefined;
}

export interface StateUpdateRequest {
  workspace?: string | undefined;
  fco_args?: FcoArgs[] | undefined;
  repo_source_info?: FeatureRepoSourceInfo | undefined;
  suppress_recreates?: boolean | undefined;
  upgrade_all?:
    | boolean
    | undefined;
  /**
   * Both of these exist because we can't deprecate the first one in the case of
   * old State Updates without requested_by_principal
   */
  requested_by?: string | undefined;
  requested_by_principal?: Principal | undefined;
  sdk_version?: string | undefined;
  plan_integration_type?: PlanIntegrationTestSelectType | undefined;
  plan_integration_config?: PlanIntegrationTestConfig | undefined;
}

export interface StateUpdateEntry {
  commit_id?:
    | string
    | undefined;
  /**
   * Both of these exist because we can't deprecate the first one in the case of
   * old State Updates without applied_by_principal
   */
  applied_by?: string | undefined;
  applied_by_principal?: PrincipalBasic | undefined;
  applied_at?: string | undefined;
  workspace?: string | undefined;
  sdk_version?: string | undefined;
  created_at?: string | undefined;
  status_type?: PlanStatusType | undefined;
  error?: string | undefined;
  successful_plan_output?: SuccessfulPlanOutput | undefined;
  created_by?: string | undefined;
}

export interface StateUpdatePlanSummary {
  diff_items?:
    | StateUpdatePlanSummaryDiff[]
    | undefined;
  /**
   * Both of these exist because we can't deprecate the first one in the case of
   * old State Updates without applied_by_principal
   */
  applied_by?: string | undefined;
  applied_by_principal?: PrincipalBasic | undefined;
  created_by?: string | undefined;
  applied_at?: string | undefined;
  created_at?: string | undefined;
  workspace?: string | undefined;
  sdk_version?: string | undefined;
}

export interface StateUpdatePlanSummaryDiff {
  /** Human readable FCO type, e.g. "Batch Feature View" or "Data Source" */
  fco_type?:
    | string
    | undefined;
  /** Only CREATE, UPDATE, DELETE are allowed now */
  type?: FcoTransitionType | undefined;
  transition_side_effects?: FcoTransitionSideEffects | undefined;
  diffs?:
    | FcoPropertyDiff[]
    | undefined;
  /** Fields from BasicInfo */
  name?: string | undefined;
  description?: string | undefined;
  materialization_info?: MaterializationInfo | undefined;
}
