import React, { FC } from 'react';
import { CommandLineOutput } from '@shared';
import { useParams } from 'react-router';
import { useGetQueryStateUpdateResponse, useGetStateUpdatePlanSummary } from '../../api/plan-apply-log';
import { EmptyPrompt, LoadingContent } from '@tecton';

const CliOutput = () => {
  const { planId } = useParams();

  const { data, isLoading } = useGetStateUpdatePlanSummary(planId);

  const {
    data: queryStateUpdateData,
    isLoading: queryStateUpdateIsLoading,
    isError,
  } = useGetQueryStateUpdateResponse(planId ?? '', data?.plan?.workspace ?? '');

  if (queryStateUpdateIsLoading || isLoading) {
    return <LoadingContent />;
  }

  if (isError) {
    return (
      <>
        <EmptyPrompt
          title={<h2>No Results</h2>}
          body={
            <p>
              Could not find plan with id {planId} in workspace {data?.plan?.workspace}.
            </p>
          }
        />
      </>
    );
  }

  return <CommandLineOutput planOutputString={queryStateUpdateData?.successful_plan_output?.string_output ?? ''} />;
};

export default CliOutput;
