export default () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.66662 17.4999V17.4999C7.58995 17.4999 8.33912 16.7516 8.33995 15.8282L8.34328 12.4582C9.14662 12.1066 9.87245 11.5182 10.3525 10.5966C11.1141 9.13489 10.9683 7.29823 9.93245 6.01656C8.24078 3.92406 5.07578 3.92906 3.39162 6.03156C2.36078 7.31739 2.21995 9.15489 2.98912 10.6124C3.47078 11.5257 4.19495 12.1124 4.99662 12.4616L4.99328 15.8241C4.99245 16.7491 5.74162 17.4999 6.66662 17.4999V17.4999Z"
        stroke="#525866"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.89409 8.62157C6.89409 8.48574 6.78409 8.37657 6.64909 8.37657C6.51326 8.37741 6.40409 8.48741 6.40409 8.62241C6.40409 8.75824 6.51409 8.86741 6.64909 8.86741C6.78409 8.86741 6.89409 8.75741 6.89409 8.62157"
        stroke="#525866"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.0523 4.69333C11.0523 3.48167 10.0707 2.5 8.859 2.5V2.5C7.64817 2.5 6.6665 3.48167 6.6665 4.69333V8.37667"
        stroke="#525866"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.49854 5.55502L9.87354 5.18002C10.5244 4.52919 11.5794 4.52919 12.2302 5.18002L17.011 9.96085C17.6619 10.6117 17.6619 11.6667 17.011 12.3175L14.8052 14.5234C14.1544 15.1742 13.0994 15.1742 12.4485 14.5234L9.57104 11.6459"
        stroke="#525866"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
