import React from 'react';

import ConfigurationContextProvider from './ConfigurationContextProvider';
import ConfigurationIndex from './ConfigurationIndex';

const ConfigurationContainer = () => {
  return (
    <>
      <ConfigurationContextProvider>
        <ConfigurationIndex />
      </ConfigurationContextProvider>
    </>
  );
};

export default ConfigurationContainer;
