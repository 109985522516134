import { useQuery } from '@tanstack/react-query';
import MetadataService from '../../service/MetadataService';
import AuthorizationService from '../../service/AuthorizationService';
import _get from 'lodash/get';
import { GetAssignedPrincipalsRequest } from '../../types/tecton_proto/auth/authorization_service';
import { ResourceType } from '../../types/tecton_proto/auth/resource';
import { PrincipalType } from '../../types/tecton_proto/auth/principal';

export const SERVICE_ACCOUNT_NAME_ALREADY_EXIST = 'SERVICE_ACCOUNT_NAME_ALREADY_EXIST';

export function useDeleteUser(id: string) {
  const state = useQuery(['deleteUser', id], () => {
    return MetadataService.sharedInstance().deleteUser(id);
  });

  if (state.error) {
    throw state.error;
  }

  return state;
}

export function useClusterAdminInfo() {
  const state = useQuery(['adminInfo'], () => {
    const data = MetadataService.sharedInstance().getClusterAdminInfo();
    return data;
  });
  if (state.error) {
    throw state.error;
  }
  return state;
}

export function useServiceAccounts(arg?: { ids: string[] }) {
  const serviceAccountArgs = {
    ...(arg && arg),
  };

  const state = useQuery(['serviceAccounts', serviceAccountArgs], () => {
    const data = MetadataService.sharedInstance().getServiceAccounts(serviceAccountArgs);
    return data;
  });
  if (state.error) {
    throw state.error;
  }
  return state;
}

export function useAccessControlGetPermissionsForWorkspace(workspace: string) {
  const payload: GetAssignedPrincipalsRequest = {
    resource_type: ResourceType.RESOURCE_TYPE_WORKSPACE,
    resource_id: workspace,
    principal_types: [PrincipalType.PRINCIPAL_TYPE_USER, PrincipalType.PRINCIPAL_TYPE_SERVICE_ACCOUNT],
  };

  const state = useGetAssignedPrincipals(['getAppPermissions', workspace], payload);
  return state;
}

export const useGetAssignedPrincipals = (keys: any[], payload: GetAssignedPrincipalsRequest, options: any = {}) => {
  const state = useQuery(
    keys,
    () => {
      return AuthorizationService.sharedService().getAssignedPrincipals(payload);
    },
    options
  );

  if (state.error) {
    throw state.error;
  }
  return state;
};
