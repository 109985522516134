import React, { FC, ReactNode } from 'react';
import { EuiCodeBlock, EuiCodeBlockProps } from '@elastic/eui';
import { Card, useTectonTheme } from '.';
import styled from '@emotion/styled';

export enum CODE_BLOCK_LANGUAGE {
  PYTHON = 'python',
  JAVASCRIPT = 'javascript',
  SQL = 'sql',
  JSON = 'json',
  BASH = 'bash',
  DIFF = 'diff',
}

interface CodePanelProps {
  title?: NonNullable<ReactNode>;
  code: string;
  mode: CODE_BLOCK_LANGUAGE;
  description?: NonNullable<ReactNode>;
  height: number | string;
  isVirtualized?: boolean;
}

const CodeBlock = (props: EuiCodeBlockProps) => {
  const { theme } = useTectonTheme();

  const CodeBlockWrapper = styled(EuiCodeBlock)`
    background: #fff;

    .euiCodeBlock__lineText {
      color: #2a00ff;
      font-size: 12px;
    }

    .euiCodeBlock__lineNumber {
      background-color: #f7f7f7;
    }

    .euiCodeBlock__controls {
      background: none;
    }

    // Color deleted for text
    .euiCodeBlock__lineText:has(> .deleted) {
      color: ${theme.colors.danger};
    }

    // Color inserted for text
    .euiCodeBlock__lineText:has(> .inserted) {
      color: ${theme.colors.success};
    }

    .token.prefix.inserted {
      box-shadow: -4px 0 ${theme.colors.success};
      color: ${theme.colors.success};
    }

    .token.prefix.deleted {
      box-shadow: -4px 0 ${theme.colors.danger};
      color: ${theme.colors.danger};
    }
  `;
  return <CodeBlockWrapper {...props}>{props.children}</CodeBlockWrapper>;
};

export const CodePanel: FC<CodePanelProps> = ({
  title = '',
  code,
  mode,
  height = 400,
  description,
  isVirtualized = true,
}) => {
  const CodeBlockWrapper = styled(Card)`
    border-radius: 0px;
    border: 0px;
  `;

  return (
    <CodeBlockWrapper
      className="noRoundedCorners"
      layout="horizontal"
      titleSize="xs"
      title={title}
      description={description}
      hasBorder={true}
    >
      <CodeBlock
        language={mode}
        fontSize="s"
        paddingSize="s"
        overflowHeight={height}
        isCopyable
        lineNumbers
        isVirtualized={isVirtualized}
      >
        {code}
      </CodeBlock>
    </CodeBlockWrapper>
  );
};

export default CodePanel;
