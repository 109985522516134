export default () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.1667 3.33325H5.83333C3.9925 3.33325 2.5 4.82575 2.5 6.66659V6.66659C2.5 8.50742 3.9925 9.99992 5.83333 9.99992H14.1667C16.0075 9.99992 17.5 8.50742 17.5 6.66659V6.66659C17.5 4.82575 16.0075 3.33325 14.1667 3.33325Z"
        stroke="#525866"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.1665 6.66667H14.1665"
        stroke="#525866"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.1667 10H5.83333C3.9925 10 2.5 11.4925 2.5 13.3333V13.3333C2.5 15.1742 3.9925 16.6667 5.83333 16.6667H14.1667C16.0075 16.6667 17.5 15.1742 17.5 13.3333V13.3333C17.5 11.4925 16.0075 10 14.1667 10Z"
        stroke="#525866"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.86263 6.6372C5.87891 6.65348 5.87891 6.67986 5.86263 6.69613C5.84636 6.7124 5.81998 6.7124 5.80371 6.69613C5.78744 6.67986 5.78744 6.65348 5.80371 6.6372C5.81998 6.62093 5.84636 6.62093 5.86263 6.6372"
        stroke="#525866"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.1665 13.3332H14.1665"
        stroke="#525866"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.86263 13.3037C5.87891 13.32 5.87891 13.3464 5.86263 13.3626C5.84636 13.3789 5.81998 13.3789 5.80371 13.3626C5.78744 13.3464 5.78744 13.32 5.80371 13.3037C5.81998 13.2874 5.84636 13.2874 5.86263 13.3037"
        stroke="#525866"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
