import React from 'react';
import { LoadingSpinner } from './_eui_components';
import styled from '@emotion/styled';

const PageLoading = () => {
  const CenteredDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  `;
  return (
    <CenteredDiv>
      <LoadingSpinner size="xl" />
    </CenteredDiv>
  );
};

export default PageLoading;
