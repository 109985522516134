export default () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.16667 15.8317V6.25249C9.16667 5.67749 8.87083 5.14332 8.38333 4.83915L5.05 2.75582C3.94 2.06248 2.5 2.85998 2.5 4.16915V13.7475C2.5 14.3225 2.79583 14.8567 3.28333 15.1608L6.61667 17.2442C7.72667 17.9383 9.16667 17.14 9.16667 15.8317Z"
        stroke="#525866"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M17.5 9.16667H12.5" stroke="#525866" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M14.1667 7.5L12.5 9.16667L14.1667 10.8333"
        stroke="#525866"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.16675 15.8334H12.5001C13.4209 15.8334 14.1667 15.0875 14.1667 14.1667V13.3334"
        stroke="#525866"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.1667 5V4.16667C14.1667 3.24583 13.4209 2.5 12.5001 2.5H4.16675"
        stroke="#525866"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
