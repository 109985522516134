import React, { FC, ReactNode, ReactElement } from 'react';
import { EuiDatePickerRange, IconType } from '@elastic/eui';
import styled from '@emotion/styled';
import moment, { Moment } from 'moment-timezone';
import { FormRow, DatePicker } from '@tecton/ComponentRedesign';

interface DatePickerRangeSharedProps {
  label?: ReactNode;
  error?: boolean;
  disabled?: boolean;
  errorText?: ReactNode;
  message?: ReactNode;
}

interface DatePickerRangeProps extends DatePickerRangeSharedProps {
  startDateControl: ReactElement;
  endDateControl: ReactElement;
  iconType?: IconType;
}

interface FullRangePickerProps extends DatePickerRangeSharedProps {
  dateAndTime?: boolean;
  timeOnly?: boolean;
  startDate: moment.Moment;
  setStartDate: React.Dispatch<React.SetStateAction<moment.Moment>> | ((date: Moment) => void);
  endDate: moment.Moment;
  setEndDate: React.Dispatch<React.SetStateAction<moment.Moment>> | ((date: Moment) => void);
}

const StyledDatePickerRange = styled(EuiDatePickerRange)`
  &.euiFormRow__fieldWrapper {
    flex: center;
  }
  &.euiFormControlLayoutDelimited {
    background-color: transparent;
    box-shadow: none;
    padding: 0;
    border: ${({ theme }) => `1px solid ${theme.colors.inputBorder}`};
    &.euiFormControlLayout {
      height: 32px;

      .euiFormControlLayoutCustomIcon {
        .euiIcon.euiFormControlLayoutCustomIcon__icon{
          fill: none
        }
    }
    .euiDatePicker {
      height: 32px;
      box-shadow: none;
      background-color: transparent;
      color: ${({ theme }) => theme.colors.text};
    }

    .euiFormControlLayoutDelimited__delimiter {
      background-color: transparent;
      box-shadow: none;
    }
  }
`;

export const DatePickerRange: FC<DatePickerRangeProps> = ({
  label,
  error,
  disabled,
  errorText,
  message,
  startDateControl,
  endDateControl,
  iconType = false,
}) => {
  return (
    <>
      <FormRow label={label} error={error} errorText={errorText} message={message}>
        <StyledDatePickerRange
          data-testid="date-picker-range"
          disabled={disabled}
          startDateControl={startDateControl}
          endDateControl={endDateControl}
          iconType={iconType}
        />
      </FormRow>
    </>
  );
};

export const FullRangePicker: FC<FullRangePickerProps> = ({
  label,
  error,
  disabled,
  errorText,
  message,
  dateAndTime,
  timeOnly,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}) => {
  return (
    <>
      <FormRow label={label} error={error} errorText={errorText} message={message}>
        <StyledDatePickerRange
          data-testid="date-picker-range"
          disabled={disabled}
          iconType={false}
          startDateControl={
            <DatePicker
              date={startDate}
              setDate={(date) => setStartDate(date)}
              aria-label="Start date"
              dateAndTime={dateAndTime}
              timeOnly={timeOnly}
              noBorder
              maxDate={endDate}
              data-testid="date-picker-range-start"
            />
          }
          endDateControl={
            <DatePicker
              date={endDate}
              setDate={(date) => setEndDate(date)}
              aria-label="End date"
              dateAndTime={dateAndTime}
              timeOnly={timeOnly}
              noBorder
              minDate={startDate}
              data-testid="date-picker-range-end"
            />
          }
        />
      </FormRow>
    </>
  );
};
export default DatePickerRange;
