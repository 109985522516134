import _ from 'lodash';
import { FieldMaskValues } from '../../feature/data-platform-configuration/databricks/setup/DatabricksCredentialsForm';
import UserDeploymentSettings from '../../service/UserDeploymentSettings';

export const getFieldMask = (formValues: any, userDeploymentSettings: UserDeploymentSettings): string => {
  // We place the logic here since I"ll write unit test for this.
  const paths = [];

  if (_.isEmpty(_.get(userDeploymentSettings, 'databricksWorkspaceUrl'))) {
    paths.push(FieldMaskValues.workspace_url);
  }

  if (
    !_.isEqual(formValues.glueCatalogId, userDeploymentSettings.glueCatalogId) ||
    !_.isEqual(formValues.glueCatalogEnabled, userDeploymentSettings.glueCatalogEnabled)
  ) {
    paths.push(FieldMaskValues.spark_conf);
  }

  if (!_.isEqual(formValues.instanceProfileArn, userDeploymentSettings.databricksInstanceProfileArn)) {
    paths.push(FieldMaskValues.instance_profile);
  }

  if (!_.isEqual(formValues.databricksApiToken, userDeploymentSettings.databricksApiToken)) {
    paths.push(FieldMaskValues.api_token);
  }

  return paths.join(',');
};
