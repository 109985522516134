import WorkspaceUtils from '../utils/workspace-utils';

export default class UpdateLogEntry {
  private proto: any = null;

  constructor(proto: any) {
    this.proto = proto;
  }

  get _proto() {
    return this.proto;
  }

  get owner() {
    return this._proto.applied_by;
  }

  get appliedAt() {
    return new Date(this._proto.applied_at);
  }

  get applyId() {
    return this._proto.commit_id;
  }

  get sdkVersion() {
    return this._proto.sdk_version;
  }

  get workspace() {
    return this._proto.workspace || WorkspaceUtils.getDefaultWorkspace();
  }
}
