/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
  BigInt: { input: any; output: any };
  /** Large numbers that cannot be represented by JavaScript's Int type */
  BigNumber: { input: any; output: any };
  /** Custom timestamp scalar type */
  CustomTimestamp: { input: any; output: any };
  /** Date custom scalar type */
  Date: { input: any; output: any };
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: { input: any; output: any };
  /**
   *
   *     A string representing a duration conforming to the ISO8601 standard,
   *     such as: P1W1DT13H23M34S
   *     P is the duration designator (for period) placed at the start of the duration representation.
   *     Y is the year designator that follows the value for the number of years.
   *     M is the month designator that follows the value for the number of months.
   *     W is the week designator that follows the value for the number of weeks.
   *     D is the day designator that follows the value for the number of days.
   *     T is the time designator that precedes the time components of the representation.
   *     H is the hour designator that follows the value for the number of hours.
   *     M is the minute designator that follows the value for the number of minutes.
   *     S is the second designator that follows the value for the number of seconds.
   *
   *     Note the time designator, T, that precedes the time value.
   *
   *     Matches moment.js, Luxon and DateFns implementations
   *     ,/. is valid for decimal places and +/- is a valid prefix
   *
   */
  Duration: { input: any; output: any };
  /** Empty Response Handler */
  EmptyObject: { input: any; output: any };
  /** Google Protobuf Timestamp custom scalar type */
  GoogleProtobufTimestamp: { input: any; output: any };
  JSON: { input: any; output: any };
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: { input: any; output: any };
  /** Custom scalar converting a map type to an array of key, value pairs */
  KeyValuePairs: { input: any; output: any };
  /** proto time that cannot be represented by JavaScript's Time type */
  Time: { input: any; output: any };
  /** The javascript `Date` as integer. Type represents date and time as number of milliseconds from start of UNIX epoch. */
  Timestamp: { input: any; output: any };
};

export type AclGroupWorkspaceType = {
  __typename?: 'ACLGroupWorkspaceType';
  hasPriority: Scalars['Boolean']['output'];
  role?: Maybe<Scalars['String']['output']>;
  workspaceId: Scalars['String']['output'];
};

export type AclRoleSource = {
  __typename?: 'ACLRoleSource';
  groupId?: Maybe<Scalars['String']['output']>;
  groupName?: Maybe<Scalars['String']['output']>;
  role: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type AclRoleWithInheritance = {
  __typename?: 'ACLRoleWithInheritance';
  effectiveWorkspaceRole: Scalars['String']['output'];
  roleSources: Array<AclRoleSource>;
};

export type AclWorkspaceMembershipType = {
  __typename?: 'ACLWorkspaceMembershipType';
  effectiveWorkspaceRole: Scalars['String']['output'];
  roleSources: Array<AclRoleSource>;
};

export type AclWorkspaceServiceRelationship = {
  __typename?: 'ACLWorkspaceServiceRelationship';
  hasPriority: Scalars['Boolean']['output'];
  role?: Maybe<AclRoleWithInheritance>;
  workspaceId: Scalars['String']['output'];
};

export enum AccountType {
  Admin = 'Admin',
  Regular = 'Regular',
}

export type AccountTypeWithInheritance = {
  __typename?: 'AccountTypeWithInheritance';
  assignedAccountType: Scalars['String']['output'];
  effectiveAccountType: Scalars['String']['output'];
  inheritedAccountTypes: Array<InheritedAccountType>;
};

export type AssignableRole = {
  __typename?: 'AssignableRole';
  label?: Maybe<Scalars['String']['output']>;
};

export type AttemptConsumption = {
  __typename?: 'AttemptConsumption';
  jobDuration?: Maybe<Scalars['String']['output']>;
  offlineRowsWritten?: Maybe<Scalars['String']['output']>;
  onlineRowsWritten?: Maybe<Scalars['String']['output']>;
};

export type ConsumptionInfo = {
  __typename?: 'ConsumptionInfo';
  details?: Maybe<Details>;
  featureViewId?: Maybe<Id>;
  featureViewName?: Maybe<Scalars['String']['output']>;
  metric?: Maybe<Scalars['String']['output']>;
  sourceId?: Maybe<Scalars['String']['output']>;
  timeBucketStart?: Maybe<Scalars['String']['output']>;
  unitsConsumed?: Maybe<Scalars['String']['output']>;
  workspace?: Maybe<Scalars['String']['output']>;
};

export type CountRange = {
  end?: InputMaybe<Scalars['Int']['input']>;
  start?: InputMaybe<Scalars['Int']['input']>;
};

export enum DataSourceType {
  DataSourceTypeBatch = 'DATA_SOURCE_TYPE_BATCH',
  DataSourceTypeDatasetGeneration = 'DATA_SOURCE_TYPE_DATASET_GENERATION',
  DataSourceTypeDeletion = 'DATA_SOURCE_TYPE_DELETION',
  DataSourceTypeFeatureExport = 'DATA_SOURCE_TYPE_FEATURE_EXPORT',
  DataSourceTypeIngest = 'DATA_SOURCE_TYPE_INGEST',
  DataSourceTypeStream = 'DATA_SOURCE_TYPE_STREAM',
  DataSourceTypeUnspecified = 'DATA_SOURCE_TYPE_UNSPECIFIED',
}

export type DatasetFcoSchemaItem = {
  __typename?: 'DatasetFCOSchemaItem';
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export enum DatasetFcoType {
  Logged = 'LOGGED',
  Saved = 'SAVED',
  Unknown = 'UNKNOWN',
}

export type Details = {
  __typename?: 'Details';
  offlineStoreType?: Maybe<Scalars['String']['output']>;
};

export type Fco = {
  __typename?: 'FCO';
  alertEmail?: Maybe<Scalars['String']['output']>;
  allEntities?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  allFeatureViews?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  allTransformations?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  archived?: Maybe<Scalars['Boolean']['output']>;
  batchClusterConfig?: Maybe<Scalars['String']['output']>;
  batchDataDelay?: Maybe<Scalars['String']['output']>;
  batchSource?: Maybe<Scalars['String']['output']>;
  canShowSparkJson?: Maybe<Scalars['Boolean']['output']>;
  canShowStreamSparkJson?: Maybe<Scalars['Boolean']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  datasetId?: Maybe<Id>;
  datasourceId?: Maybe<Id>;
  description?: Maybe<Scalars['String']['output']>;
  downstreamFcos?: Maybe<Array<Maybe<Fco>>>;
  entityIds?: Maybe<Array<Maybe<Id>>>;
  entityNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  expectedFreshness?: Maybe<Scalars['Int']['output']>;
  fcoType?: Maybe<Scalars['String']['output']>;
  featureMap?: Maybe<Scalars['String']['output']>;
  featureParameters?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  featureServiceId?: Maybe<Id>;
  featureServiceName?: Maybe<Scalars['String']['output']>;
  featureSet?: Maybe<Scalars['String']['output']>;
  featureSetItems?: Maybe<Array<Maybe<FeatureSetItem>>>;
  featureViewId?: Maybe<Id>;
  featureViewName?: Maybe<Scalars['String']['output']>;
  featureViewType?: Maybe<Scalars['String']['output']>;
  features?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  frameworkVersion?: Maybe<Scalars['String']['output']>;
  fwVersion?: Maybe<Scalars['String']['output']>;
  hasIncrementalBackfill?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isCachingEnabled?: Maybe<Scalars['Boolean']['output']>;
  isContinuous?: Maybe<Scalars['Boolean']['output']>;
  isEnabled?: Maybe<Scalars['Boolean']['output']>;
  isFeatureTable?: Maybe<Scalars['Boolean']['output']>;
  isMaterializationEnabled?: Maybe<Scalars['Boolean']['output']>;
  isMonitoringEnabled?: Maybe<Scalars['Boolean']['output']>;
  isOnDemand?: Maybe<Scalars['Boolean']['output']>;
  isOnlineServingEnabled?: Maybe<Scalars['Boolean']['output']>;
  isPush?: Maybe<Scalars['Boolean']['output']>;
  isStream?: Maybe<Scalars['Boolean']['output']>;
  isStreamIngest?: Maybe<Scalars['Boolean']['output']>;
  isTemporal?: Maybe<Scalars['Boolean']['output']>;
  isTemporalAggregate?: Maybe<Scalars['Boolean']['output']>;
  joinKeys?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  lastModifiedBy?: Maybe<Scalars['String']['output']>;
  lastModifiedDate?: Maybe<Scalars['String']['output']>;
  materializationIntervalInSeconds?: Maybe<Scalars['Int']['output']>;
  materializationMaxSourceDataDelaySeconds?: Maybe<Scalars['Int']['output']>;
  materializationParams?: Maybe<MaterializationParam>;
  materializationStartTimestamp?: Maybe<Scalars['String']['output']>;
  maxAggregationIntervalInSeconds?: Maybe<Scalars['Int']['output']>;
  mode?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  onDemandEnvironment?: Maybe<Scalars['String']['output']>;
  onlineMaterializationIntervalInSeconds?: Maybe<Scalars['Int']['output']>;
  owner?: Maybe<Scalars['String']['output']>;
  preventDestroy?: Maybe<Scalars['Boolean']['output']>;
  schema?: Maybe<Array<Maybe<DatasetFcoSchemaItem>>>;
  servingTtl?: Maybe<Scalars['Int']['output']>;
  shouldHideMaterializationStatus?: Maybe<Scalars['Boolean']['output']>;
  sourceFileName?: Maybe<Scalars['String']['output']>;
  sourceType?: Maybe<Scalars['String']['output']>;
  stateUpdateEntryCommitId?: Maybe<Scalars['String']['output']>;
  streamClusterConfig?: Maybe<Scalars['String']['output']>;
  streamSource?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Scalars['JSONObject']['output']>;
  timeKey?: Maybe<Scalars['String']['output']>;
  transformationId?: Maybe<Id>;
  truncatedDescription?: Maybe<Scalars['String']['output']>;
  type?: Maybe<DatasetFcoType>;
  upstreamFcos?: Maybe<Array<Maybe<Fco>>>;
  workspace?: Maybe<Scalars['String']['output']>;
};

export type FcoEdge = {
  __typename?: 'FCOEdge';
  source?: Maybe<Scalars['ID']['output']>;
  target?: Maybe<Scalars['ID']['output']>;
};

export enum FcoType {
  Dataset = 'DATASET',
  DataSource = 'DATA_SOURCE',
  Entity = 'ENTITY',
  FeatureService = 'FEATURE_SERVICE',
  FeatureView = 'FEATURE_VIEW',
  Transformation = 'TRANSFORMATION',
  Unknown = 'UNKNOWN',
}

export type FcoLocator = {
  __typename?: 'FcoLocator';
  name?: Maybe<Scalars['String']['output']>;
  workspace?: Maybe<Scalars['String']['output']>;
};

export type FeatureSetItem = {
  __typename?: 'FeatureSetItem';
  featureColumns?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  featureViewId?: Maybe<Id>;
  joinConfigurationItems?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  namespace?: Maybe<Scalars['String']['output']>;
};

export type GetAuthorizedResourcesResponse = {
  __typename?: 'GetAuthorizedResourcesResponse';
  authorizedResources: Array<Scalars['String']['output']>;
};

export type GetFcosRequest = {
  applyHash?: InputMaybe<Scalars['String']['input']>;
  workspace?: InputMaybe<Scalars['String']['input']>;
};

export type GetFcosResponse = {
  __typename?: 'GetFcosResponse';
  dataSources?: Maybe<Array<Maybe<Fco>>>;
  datasets?: Maybe<Array<Maybe<Fco>>>;
  edges?: Maybe<Array<Maybe<FcoEdge>>>;
  entities?: Maybe<Array<Maybe<Fco>>>;
  featureServices?: Maybe<Array<Maybe<Fco>>>;
  featureViews?: Maybe<Array<Maybe<Fco>>>;
  transformations?: Maybe<Array<Maybe<Fco>>>;
};

export type GetJobsRequest = {
  duration?: InputMaybe<GoogleProtubufDurationRangeRequest>;
  featureViewEnd?: InputMaybe<GoogleProtobufTimestampDurationRequest>;
  featureViewStart?: InputMaybe<GoogleProtobufTimestampDurationRequest>;
  featureViews?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  includeUpdateMaterializationFlags?: InputMaybe<Scalars['Boolean']['input']>;
  lastTaskStateChange?: InputMaybe<GoogleProtobufTimestampDurationRequest>;
  manuallyTriggered?: InputMaybe<Scalars['Boolean']['input']>;
  numAttempts?: InputMaybe<CountRange>;
  pagination?: InputMaybe<PaginationRequest>;
  statuses?: InputMaybe<Array<InputMaybe<MaterializationStatusState>>>;
  taskType?: InputMaybe<Array<InputMaybe<TaskType>>>;
  workspaces?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  writesOffline?: InputMaybe<Scalars['Boolean']['input']>;
  writesOnline?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GetJobsResponse = {
  __typename?: 'GetJobsResponse';
  jobs?: Maybe<Array<Maybe<Job>>>;
  pagination?: Maybe<Pagination>;
};

export type GetPermissionsResponse = {
  __typename?: 'GetPermissionsResponse';
  assignableGroups: Array<PermissionGroup>;
  assignableServiceAccounts: Array<ServiceAccount>;
  assignableUsers: Array<User>;
  groups: Array<PermissionGroup>;
  serviceAccounts: Array<ServiceAccount>;
  users: Array<User>;
};

export type GetRecentHashRequest = {
  workspace?: InputMaybe<Scalars['String']['input']>;
};

export type GetRolesResponse = {
  __typename?: 'GetRolesResponse';
  roles: Array<RoleDefinition>;
};

export type GetServiceAccountDetailsResponse = {
  __typename?: 'GetServiceAccountDetailsResponse';
  serviceAccountDetails: ServiceAccountDetail;
};

export type GetUserDetailsResponse = {
  __typename?: 'GetUserDetailsResponse';
  userDetails: UserDetail;
};

export type GoogleProtobufTimestampDurationRequest = {
  end?: InputMaybe<Scalars['GoogleProtobufTimestamp']['input']>;
  start?: InputMaybe<Scalars['GoogleProtobufTimestamp']['input']>;
};

export type GoogleProtubufDurationRangeRequest = {
  end?: InputMaybe<Scalars['CustomTimestamp']['input']>;
  start?: InputMaybe<Scalars['CustomTimestamp']['input']>;
};

export type Group = {
  __typename?: 'Group';
  accountType: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['Date']['output']>;
  createdBy?: Maybe<User>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  idpMappingNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  isMember?: Maybe<Scalars['Boolean']['output']>;
  isMembershipEditable?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  numMember?: Maybe<Scalars['String']['output']>;
  numMembers: Scalars['Int']['output'];
  workspaceCount: Scalars['String']['output'];
  workspaces: Array<Workspace>;
};

export type GroupDetailsResponse = {
  __typename?: 'GroupDetailsResponse';
  assignableServiceAccounts: Array<ServiceAccount>;
  assignableUsers: Array<User>;
  assignableWorkspaces: Array<Scalars['String']['output']>;
  assignedServiceAccounts: Array<ServiceAccount>;
  assignedUsers: Array<User>;
  assignedWorkspaces: Array<AclGroupWorkspaceType>;
  group: Group;
  membersCount: MembersCount;
};

export type GroupResponse = {
  __typename?: 'GroupResponse';
  assignableGroups: Array<Group>;
  assignedGroups: Array<Group>;
  groups: Array<Group>;
};

export type Id = {
  __typename?: 'Id';
  leastSignificantBits?: Maybe<Scalars['BigNumber']['output']>;
  mostSignificantBits?: Maybe<Scalars['BigNumber']['output']>;
};

export type InheritedAccountType = {
  __typename?: 'InheritedAccountType';
  accountType: Scalars['String']['output'];
  groupId?: Maybe<Scalars['String']['output']>;
  groupName?: Maybe<Scalars['String']['output']>;
};

export type Job = {
  __typename?: 'Job';
  allowCancel?: Maybe<Scalars['Boolean']['output']>;
  allowOverwrite?: Maybe<Scalars['Boolean']['output']>;
  allowRetry?: Maybe<Scalars['Boolean']['output']>;
  attempts?: Maybe<Array<Maybe<MaterializationAttempt>>>;
  duration?: Maybe<Scalars['String']['output']>;
  featureEndTime?: Maybe<Scalars['Date']['output']>;
  featureStartTime?: Maybe<Scalars['Date']['output']>;
  featureView?: Maybe<Scalars['String']['output']>;
  fullId?: Maybe<Id>;
  fvType?: Maybe<JobsTaskTypeValue>;
  id?: Maybe<Scalars['String']['output']>;
  lastTaskStateChange?: Maybe<Scalars['Date']['output']>;
  latestStatus?: Maybe<JobStatusValue>;
  manuallyTriggered?: Maybe<Scalars['String']['output']>;
  materializationState?: Maybe<MaterializationStatusState>;
  numAttempts?: Maybe<Scalars['Int']['output']>;
  planId?: Maybe<Id>;
  scheduleErrorMessage?: Maybe<Scalars['String']['output']>;
  workspace?: Maybe<Scalars['String']['output']>;
  writesOffline?: Maybe<Scalars['Boolean']['output']>;
  writesOnline?: Maybe<Scalars['Boolean']['output']>;
};

export type JobStatusValue = {
  __typename?: 'JobStatusValue';
  backgroundColor?: Maybe<Scalars['String']['output']>;
  checked?: Maybe<Scalars['String']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type JobsTaskTypeValue = {
  __typename?: 'JobsTaskTypeValue';
  backgroundColor?: Maybe<Scalars['String']['output']>;
  checked?: Maybe<Scalars['String']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type MaterializationAttempt = {
  __typename?: 'MaterializationAttempt';
  allowCancel?: Maybe<Scalars['Boolean']['output']>;
  allowForcedRetry?: Maybe<Scalars['Boolean']['output']>;
  allowOverwriteRetry?: Maybe<Scalars['Boolean']['output']>;
  allowRestart?: Maybe<Scalars['Boolean']['output']>;
  attemptConsumption?: Maybe<AttemptConsumption>;
  attemptCreatedAt?: Maybe<Scalars['Date']['output']>;
  attemptNumber?: Maybe<Scalars['Int']['output']>;
  consumptionInfo?: Maybe<Array<Maybe<ConsumptionInfo>>>;
  dataSourceType?: Maybe<DataSourceType>;
  duration?: Maybe<Scalars['String']['output']>;
  featureEndTime?: Maybe<Scalars['Date']['output']>;
  featureStartTime?: Maybe<Scalars['Date']['output']>;
  materializationAttemptId?: Maybe<Scalars['String']['output']>;
  materializationState?: Maybe<Scalars['String']['output']>;
  materializationTaskCreatedAt?: Maybe<Scalars['Date']['output']>;
  materializationTaskId?: Maybe<Id>;
  runPageUrl?: Maybe<Scalars['String']['output']>;
  sparkClusterEnvironmentVersion?: Maybe<Scalars['Int']['output']>;
  spotInstanceFailure?: Maybe<Scalars['Boolean']['output']>;
  stateMessage?: Maybe<Scalars['String']['output']>;
  tectonManagedAttemptId?: Maybe<Scalars['String']['output']>;
  terminationReason?: Maybe<Scalars['String']['output']>;
  windowEndTime?: Maybe<Scalars['Date']['output']>;
  windowStartTime?: Maybe<Scalars['Date']['output']>;
  writeToOfflineFeatureStore?: Maybe<Scalars['Boolean']['output']>;
  writeToOnlineFeatureStore?: Maybe<Scalars['Boolean']['output']>;
};

export type MaterializationParam = {
  __typename?: 'MaterializationParam';
  batch_compaction_enabled?: Maybe<Scalars['Boolean']['output']>;
  environment?: Maybe<Scalars['String']['output']>;
  feature_start_timestamp?: Maybe<Scalars['Date']['output']>;
  manual_trigger_backfill_end_timestamp?: Maybe<Scalars['Date']['output']>;
  materialization_start_timestamp?: Maybe<Scalars['Date']['output']>;
  max_backfill_interval?: Maybe<Scalars['Duration']['output']>;
  max_source_data_delay?: Maybe<Scalars['Duration']['output']>;
  schedule_interval?: Maybe<Scalars['Duration']['output']>;
  tecton_materialization_runtime?: Maybe<Scalars['String']['output']>;
  writes_to_offline_store?: Maybe<Scalars['Boolean']['output']>;
  writes_to_online_store?: Maybe<Scalars['Boolean']['output']>;
};

export type MaterializationStatus = {
  __typename?: 'MaterializationStatus';
  featurePackageId?: Maybe<Id>;
  materializationAttempts?: Maybe<Array<Maybe<MaterializationAttempt>>>;
  scheduleErrorMessage?: Maybe<Scalars['String']['output']>;
};

export enum MaterializationStatusState {
  MaterializationStatusStateDrained = 'MATERIALIZATION_STATUS_STATE_DRAINED',
  MaterializationStatusStateError = 'MATERIALIZATION_STATUS_STATE_ERROR',
  MaterializationStatusStateManuallyCancelled = 'MATERIALIZATION_STATUS_STATE_MANUALLY_CANCELLED',
  MaterializationStatusStateManualCancellationRequested = 'MATERIALIZATION_STATUS_STATE_MANUAL_CANCELLATION_REQUESTED',
  MaterializationStatusStatePending = 'MATERIALIZATION_STATUS_STATE_PENDING',
  MaterializationStatusStateRunning = 'MATERIALIZATION_STATUS_STATE_RUNNING',
  MaterializationStatusStateScheduled = 'MATERIALIZATION_STATUS_STATE_SCHEDULED',
  MaterializationStatusStateSuccess = 'MATERIALIZATION_STATUS_STATE_SUCCESS',
  MaterializationStatusStateUnspecified = 'MATERIALIZATION_STATUS_STATE_UNSPECIFIED',
}

export type MembersCount = {
  __typename?: 'MembersCount';
  group: Scalars['Int']['output'];
  serviceAccount: Scalars['Int']['output'];
  user: Scalars['Int']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  test?: Maybe<Scalars['String']['output']>;
};

export type Option = {
  __typename?: 'Option';
  label: Scalars['String']['output'];
};

export type Pagination = {
  __typename?: 'Pagination';
  nextPageToken?: Maybe<Scalars['String']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  perPage?: Maybe<Scalars['Int']['output']>;
  sortDirection?: Maybe<Scalars['String']['output']>;
  sortKey?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type PaginationRequest = {
  pageToken?: InputMaybe<Scalars['String']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  sortDirection?: InputMaybe<SortDirection>;
  sortKey?: InputMaybe<Scalars['String']['input']>;
};

export type Permission = {
  __typename?: 'Permission';
  description: Scalars['String']['output'];
  isAuthorized: Scalars['Boolean']['output'];
};

export type PermissionGroup = {
  __typename?: 'PermissionGroup';
  id: Scalars['String']['output'];
  membersCount: Scalars['String']['output'];
  name: Scalars['String']['output'];
  rolesGranted: Array<RoleGranted>;
  workspaceRole: Scalars['String']['output'];
};

export type PrincipalBasic = {
  __typename?: 'PrincipalBasic';
  group?: Maybe<Group>;
  serviceAccount?: Maybe<ServiceAccount>;
  user?: Maybe<User>;
};

export type PrincipalGroupMember = {
  __typename?: 'PrincipalGroupMember';
  isFromIdpMapping: Scalars['Boolean']['output'];
  principal: PrincipalBasic;
};

export type PrincipalInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  includeAuthorizedResource?: InputMaybe<Scalars['Boolean']['input']>;
  includeIsMember?: InputMaybe<Scalars['Boolean']['input']>;
  includePrincipalDetail?: InputMaybe<Scalars['Boolean']['input']>;
  oktaId?: InputMaybe<Scalars['String']['input']>;
  principalId?: InputMaybe<Scalars['String']['input']>;
  principalType?: InputMaybe<Scalars['String']['input']>;
};

export type Query = {
  __typename?: 'Query';
  GetAuthorizedResources: GetAuthorizedResourcesResponse;
  GetFcos?: Maybe<GetFcosResponse>;
  GetGroupDetails: GroupDetailsResponse;
  GetGroups: GroupResponse;
  GetJobs?: Maybe<GetJobsResponse>;
  GetPermissions: GetPermissionsResponse;
  GetRecentHash?: Maybe<Scalars['String']['output']>;
  GetRoles: GetRolesResponse;
  GetServiceAccountDetails: GetServiceAccountDetailsResponse;
  GetServiceAccounts: ServiceAccountResponse;
  GetUserDetails: GetUserDetailsResponse;
  GetUsers: UserResponse;
  /** query for getting server status */
  ping?: Maybe<ServerStatus>;
};

export type QueryGetAuthorizedResourcesArgs = {
  PrincipalInput?: InputMaybe<PrincipalInput>;
};

export type QueryGetFcosArgs = {
  getFcosRequest?: InputMaybe<GetFcosRequest>;
};

export type QueryGetGroupDetailsArgs = {
  PrincipalInput?: InputMaybe<PrincipalInput>;
};

export type QueryGetGroupsArgs = {
  PrincipalInput?: InputMaybe<PrincipalInput>;
};

export type QueryGetJobsArgs = {
  GetJobsRequest?: InputMaybe<GetJobsRequest>;
};

export type QueryGetPermissionsArgs = {
  ResourceInput?: InputMaybe<ResourceInput>;
};

export type QueryGetRecentHashArgs = {
  getRecentHashRequest?: InputMaybe<GetRecentHashRequest>;
};

export type QueryGetServiceAccountDetailsArgs = {
  PrincipalInput?: InputMaybe<PrincipalInput>;
};

export type QueryGetServiceAccountsArgs = {
  PrincipalInput?: InputMaybe<PrincipalInput>;
};

export type QueryGetUserDetailsArgs = {
  PrincipalInput?: InputMaybe<PrincipalInput>;
};

export type ResourceInput = {
  resourceId?: InputMaybe<Scalars['String']['input']>;
};

export type RoleAssignmentSource = {
  __typename?: 'RoleAssignmentSource';
  assignmentType?: Maybe<Scalars['String']['output']>;
  principalGroupId?: Maybe<Scalars['String']['output']>;
  principalGroupName?: Maybe<Scalars['String']['output']>;
};

export type RoleDefinition = {
  __typename?: 'RoleDefinition';
  assignableOnResourceTypes: Array<Scalars['String']['output']>;
  assignableToPrincipalTypes: Array<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  legacyId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  permissions: Array<Permission>;
};

export type RoleGranted = {
  __typename?: 'RoleGranted';
  resourceType?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  roleAssignmentSources: Array<RoleAssignmentSource>;
  roleDisplayName?: Maybe<Scalars['String']['output']>;
};

export type ServerStatus = {
  __typename?: 'ServerStatus';
  status?: Maybe<Scalars['String']['output']>;
};

export type ServiceAccount = {
  __typename?: 'ServiceAccount';
  accountType: AccountType;
  createdAt?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  hasOtherSourcesOfAccountType?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isOwner?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  rolesGranted: Array<RoleGranted>;
  workspaceRole?: Maybe<Scalars['String']['output']>;
};

export type ServiceAccountDetail = {
  __typename?: 'ServiceAccountDetail';
  accountTypeWithInheritance: AccountTypeWithInheritance;
  addNewWorkspaceRoleOptionsById: Array<Scalars['String']['output']>;
  assignableRoles: Array<AssignableRole>;
  assignableWorkspaces: Array<Option>;
  assignedWorkspacesWithRoles: Array<AclWorkspaceServiceRelationship>;
  editWorkspaceRoleOptionsById: Array<Scalars['String']['output']>;
  roles: Array<RoleDefinition>;
  serviceAccount: ServiceAccount;
};

export type ServiceAccountResponse = {
  __typename?: 'ServiceAccountResponse';
  serviceAccounts: Array<ServiceAccount>;
};

export enum SortDirection {
  SortAsc = 'SORT_ASC',
  SortDesc = 'SORT_DESC',
}

export enum TaskType {
  Batch = 'BATCH',
  DatasetGeneration = 'DATASET_GENERATION',
  Deletion = 'DELETION',
  DeltaMaintenance = 'DELTA_MAINTENANCE',
  FeatureExport = 'FEATURE_EXPORT',
  Ingest = 'INGEST',
  PlanIntegrationTestBatch = 'PLAN_INTEGRATION_TEST_BATCH',
  PlanIntegrationTestStream = 'PLAN_INTEGRATION_TEST_STREAM',
  Streaming = 'STREAMING',
  Unknown = 'UNKNOWN',
}

export type TasksWithAttempts = {
  __typename?: 'TasksWithAttempts';
  fcoLocator?: Maybe<FcoLocator>;
  featureEndTime?: Maybe<Scalars['Date']['output']>;
  featureStartTime?: Maybe<Scalars['Date']['output']>;
  featureViewName?: Maybe<Scalars['String']['output']>;
  lastTaskStateChange?: Maybe<Scalars['Date']['output']>;
  manuallyTriggered?: Maybe<Scalars['Boolean']['output']>;
  materializationStatus?: Maybe<MaterializationStatus>;
  taskId?: Maybe<Id>;
  taskState?: Maybe<Scalars['String']['output']>;
  taskType?: Maybe<Scalars['String']['output']>;
};

export type User = {
  __typename?: 'User';
  accountType: AccountType;
  createdAt?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  hasOtherSourcesOfAccountType: Scalars['Boolean']['output'];
  lastLogin?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  loginEmail?: Maybe<Scalars['String']['output']>;
  membershipType: Scalars['String']['output'];
  oktaId: Scalars['ID']['output'];
  oktaStatus?: Maybe<Scalars['String']['output']>;
  rolesGranted: Array<RoleGranted>;
  workspaceRole?: Maybe<Scalars['String']['output']>;
};

export type UserDetail = {
  __typename?: 'UserDetail';
  accountTypeWithInheritance: AccountTypeWithInheritance;
  addNewWorkspaceRoleOptionsById: Array<Scalars['String']['output']>;
  assignableRoles: Array<AssignableRole>;
  assignableWorkspaces: Array<Option>;
  assignedWorkspacesWithRoles: Array<AclWorkspaceServiceRelationship>;
  editWorkspaceRoleOptionsById: Array<Scalars['String']['output']>;
  roles: Array<RoleDefinition>;
  user: User;
};

export type UserResponse = {
  __typename?: 'UserResponse';
  users: Array<User>;
};

export type Workspace = {
  __typename?: 'Workspace';
  resourceId: Scalars['String']['output'];
  resourceType?: Maybe<Scalars['String']['output']>;
  rolesGranted: Array<RoleGranted>;
};

export type GetJobsQueryVariables = Exact<{
  getJobsRequest?: InputMaybe<GetJobsRequest>;
}>;

export type GetJobsQuery = {
  __typename?: 'Query';
  GetJobs?: {
    __typename?: 'GetJobsResponse';
    jobs?: Array<{
      __typename?: 'Job';
      id?: string | null;
      workspace?: string | null;
      featureView?: string | null;
      featureStartTime?: any | null;
      featureEndTime?: any | null;
      lastTaskStateChange?: any | null;
      duration?: string | null;
      numAttempts?: number | null;
      manuallyTriggered?: string | null;
      allowRetry?: boolean | null;
      allowCancel?: boolean | null;
      allowOverwrite?: boolean | null;
      writesOnline?: boolean | null;
      writesOffline?: boolean | null;
      scheduleErrorMessage?: string | null;
      fullId?: { __typename?: 'Id'; mostSignificantBits?: any | null; leastSignificantBits?: any | null } | null;
      fvType?: {
        __typename?: 'JobsTaskTypeValue';
        label?: string | null;
        color?: string | null;
        backgroundColor?: string | null;
        value?: string | null;
        checked?: string | null;
      } | null;
      latestStatus?: {
        __typename?: 'JobStatusValue';
        label?: string | null;
        color?: string | null;
        backgroundColor?: string | null;
        value?: string | null;
        checked?: string | null;
      } | null;
      attempts?: Array<{
        __typename?: 'MaterializationAttempt';
        dataSourceType?: DataSourceType | null;
        windowStartTime?: any | null;
        windowEndTime?: any | null;
        featureStartTime?: any | null;
        featureEndTime?: any | null;
        materializationState?: string | null;
        stateMessage?: string | null;
        terminationReason?: string | null;
        spotInstanceFailure?: boolean | null;
        materializationTaskCreatedAt?: any | null;
        attemptNumber?: number | null;
        sparkClusterEnvironmentVersion?: number | null;
        runPageUrl?: string | null;
        attemptCreatedAt?: any | null;
        duration?: string | null;
        allowForcedRetry?: boolean | null;
        allowOverwriteRetry?: boolean | null;
        allowCancel?: boolean | null;
        allowRestart?: boolean | null;
        writeToOnlineFeatureStore?: boolean | null;
        writeToOfflineFeatureStore?: boolean | null;
        tectonManagedAttemptId?: string | null;
        materializationAttemptId?: string | null;
        attemptConsumption?: {
          __typename?: 'AttemptConsumption';
          onlineRowsWritten?: string | null;
          offlineRowsWritten?: string | null;
          jobDuration?: string | null;
        } | null;
        consumptionInfo?: Array<{
          __typename?: 'ConsumptionInfo';
          timeBucketStart?: string | null;
          unitsConsumed?: string | null;
          metric?: string | null;
          sourceId?: string | null;
          featureViewName?: string | null;
          workspace?: string | null;
          featureViewId?: {
            __typename?: 'Id';
            mostSignificantBits?: any | null;
            leastSignificantBits?: any | null;
          } | null;
          details?: { __typename?: 'Details'; offlineStoreType?: string | null } | null;
        } | null> | null;
        materializationTaskId?: {
          __typename?: 'Id';
          mostSignificantBits?: any | null;
          leastSignificantBits?: any | null;
        } | null;
      } | null> | null;
      planId?: { __typename?: 'Id'; mostSignificantBits?: any | null; leastSignificantBits?: any | null } | null;
    } | null> | null;
    pagination?: {
      __typename?: 'Pagination';
      page?: number | null;
      perPage?: number | null;
      nextPageToken?: string | null;
      sortKey?: string | null;
      sortDirection?: string | null;
      total?: number | null;
    } | null;
  } | null;
};

export const GetJobsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetJobs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'getJobsRequest' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'GetJobsRequest' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'GetJobs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'GetJobsRequest' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'getJobsRequest' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fullId' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'mostSignificantBits' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'leastSignificantBits' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'workspace' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'featureView' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'featureStartTime' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'featureEndTime' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fvType' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'checked' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latestStatus' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'checked' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastTaskStateChange' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attempts' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'dataSourceType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'windowStartTime' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'windowEndTime' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'featureStartTime' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'featureEndTime' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'materializationState' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'stateMessage' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'terminationReason' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'spotInstanceFailure' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'materializationTaskCreatedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'attemptNumber' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sparkClusterEnvironmentVersion' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'runPageUrl' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'attemptCreatedAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'allowForcedRetry' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'allowOverwriteRetry' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'allowCancel' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'allowRestart' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'writeToOnlineFeatureStore' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'writeToOfflineFeatureStore' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'tectonManagedAttemptId' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'attemptConsumption' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'onlineRowsWritten' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'offlineRowsWritten' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'jobDuration' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'consumptionInfo' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'timeBucketStart' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'unitsConsumed' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'metric' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'sourceId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'featureViewName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'workspace' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'featureViewId' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'mostSignificantBits' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'leastSignificantBits' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'details' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'offlineStoreType' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'materializationTaskId' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'mostSignificantBits' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'leastSignificantBits' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'materializationAttemptId' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'numAttempts' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'manuallyTriggered' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'planId' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'mostSignificantBits' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'leastSignificantBits' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'allowRetry' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'allowCancel' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'allowOverwrite' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'writesOnline' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'writesOffline' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'scheduleErrorMessage' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pagination' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'page' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'perPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'nextPageToken' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sortKey' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sortDirection' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetJobsQuery, GetJobsQueryVariables>;
