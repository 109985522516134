import { useState } from 'react';
import { logEvent } from '../../../../utils/analytics-utils';

const useConfirmationPrompt = () => {
  const [canShowConfirmationPrompt, setCanShowConfirmationPrompt] = useState(false);
  const onCloseModalClicked = () => {
    setCanShowConfirmationPrompt(false);
    logEvent('Cluster Configuration Modal Closed Clicked');
  };

  return { canShowConfirmationPrompt, setCanShowConfirmationPrompt, onCloseModalClicked };
};

export default useConfirmationPrompt;
