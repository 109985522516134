import React from 'react';
import { DescriptionList, useEuiFontSize } from '@tecton';
import { css } from '@emotion/css';
import EditLink from '../shared/EditLink';

interface ACLProfileDescriptionListProps {
  listItems: React.ComponentProps<typeof DescriptionList>['listItems'];
}

const ListTitleWithEditTrigger = ({
  title,
  isEditing,
  toggleEditMode,
  isAdmin,
}: {
  title: NonNullable<React.ReactNode>;
  isEditing: boolean;
  toggleEditMode: () => void;
  isAdmin: boolean;
}) => {
  const canShowEditButton = isAdmin && !isEditing;

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        alignItems: 'center',
      }}
    >
      <div>{title}</div>
      {canShowEditButton && <EditLink onClick={toggleEditMode} />}
    </div>
  );
};

const editableItem = ({
  title,
  viewMode,
  editMode,
  editState,
  isAdmin,
}: {
  title: NonNullable<React.ReactNode>;
  viewMode: NonNullable<React.ReactNode>;
  editMode: NonNullable<React.ReactNode>;
  editState: any;
  isAdmin: boolean;
}) => {
  const [isEditing, setIsEditing] = editState;
  const toggleEditMode = () => setIsEditing(true);

  return {
    title: (
      <ListTitleWithEditTrigger
        title={title}
        isEditing={!!isEditing}
        toggleEditMode={toggleEditMode}
        isAdmin={isAdmin}
      />
    ),
    description: !isEditing ? viewMode : editMode,
  };
};

const ACLProfileDescriptionList = ({ listItems }: ACLProfileDescriptionListProps) => {
  const descriptionListCss = css`
    dt {
      ${useEuiFontSize('s')}
    }
  `;

  return (
    <div>
      <DescriptionList className={descriptionListCss} listItems={listItems} />
    </div>
  );
};

export default ACLProfileDescriptionList;
export { editableItem, ListTitleWithEditTrigger };
