import { useMemo } from 'react';
import { EuiThemeComputed } from '@tecton';
import { css } from '@emotion/css';

const useTectonModalStyle = (euiTheme: EuiThemeComputed<{}>) => {
  return useMemo(() => {
    return css`
      .euiModalHeader {
        padding-bottom: ${euiTheme.size.xs};
      }

      .euiModalHeader__title {
        font-weight: 500;
        font-size: calc(${euiTheme.font.scale.l} * ${euiTheme.base}px);
      }
    `;
  }, [euiTheme]);
};

export default useTectonModalStyle;
