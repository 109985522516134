import React from 'react';

const EmptyValue = () => {
  // This exists only to ensure that we have consistent displays for n/a empty values
  // It may be unnecessary, but is certainly not useful in its current form

  return <>n/a</>;
};

export default EmptyValue;
