export default () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.41452 6.5865C10.1952 7.36717 10.1952 8.6345 9.41452 9.4165C8.63386 10.1972 7.36652 10.1972 6.58452 9.4165C5.80386 8.63583 5.80386 7.3685 6.58452 6.5865C7.36652 5.8045 8.63319 5.8045 9.41452 6.5865"
        stroke="#343741"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2 8.00016C2 7.56083 2.10133 7.12616 2.29733 6.7255V6.7255C3.30733 4.66083 5.53933 3.3335 8 3.3335C10.4607 3.3335 12.6927 4.66083 13.7027 6.7255V6.7255C13.8987 7.12616 14 7.56083 14 8.00016C14 8.4395 13.8987 8.87416 13.7027 9.27483V9.27483C12.6927 11.3395 10.4607 12.6668 8 12.6668C5.53933 12.6668 3.30733 11.3395 2.29733 9.27483V9.27483C2.10133 8.87416 2 8.4395 2 8.00016Z"
        stroke="#343741"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
