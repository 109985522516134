import React, { ChangeEvent, useContext } from 'react';
import { EuiBasicTableColumn, Button, Link, Spacer, DebouncedFieldSearch } from '@tecton';

import { useNavigate, useParams } from 'react-router-dom';
import { TectonBasicTable } from '@shared';
import { ACLTableControlLayout } from './ACLLayout';
import ColumnNameWithHelp from '../shared/ColumnNameWithHelp';
import TableActionButtonWithDisableMode from '../shared/TableActionButtonWithDisableMode';
import GroupAddUsersModal from './modals/GroupAddUsersModal';
import RemoveFromGroupConfirmationModal from './modals/RemoveFromGroupConfirmationModal';
import { ACLGroupModal } from './aclUtils';
import { CandidateMemberOption } from './types';
import { useUserSettings } from '../context/UserSettingsContext';
import { ACLGroupProfileContextGQL } from './ACLGroupProfileContextGQL';
import { User } from '../../api/gql/graphql';

const ACLGroupMembersGQL = () => {
  const navigate = useNavigate();
  const groupProfileContext = useContext(ACLGroupProfileContextGQL);
  const { isAdmin } = useUserSettings();
  const { group } = useParams();

  if (!group) {
    throw new Error('Route has no "group" parameter.');
  }

  let addMembersModal;

  if (groupProfileContext?.modal === ACLGroupModal.ADD_MEMBER_TO_GROUP) {
    addMembersModal = (
      <GroupAddUsersModal
        principalType={'Users'}
        groupId={groupProfileContext?.group?.name ?? ''}
        options={groupProfileContext?.assignableUsers ?? []}
        setOptions={(options: CandidateMemberOption[]) => {
          groupProfileContext?.updateCandidateMembers?.call(groupProfileContext, options);
        }}
        optionsAreLoading={false}
        onClose={() => {
          groupProfileContext?.closeModal?.call(groupProfileContext);
        }}
        confirmChanges={() => {
          groupProfileContext?.confirmAddMembers?.call(groupProfileContext);
        }}
        isLoading={groupProfileContext?.isModalLoading ?? false}
        errorMessages={groupProfileContext?.errorMessages}
      />
    );
  }

  // Remove User Confirmation

  let removeUserFromGroupModal;

  if (groupProfileContext?.modal === ACLGroupModal.REMOVE_MEMBER_FROM_GROUP) {
    removeUserFromGroupModal = (
      <RemoveFromGroupConfirmationModal
        principalId={groupProfileContext?.selectedUserToDelete?.loginEmail ?? ''}
        groupName={groupProfileContext?.group?.name ?? ''}
        isLoading={groupProfileContext?.isModalLoading ?? false}
        errorMessages={groupProfileContext?.errorMessages}
        onClose={() => {
          groupProfileContext?.closeModal?.call(groupProfileContext);
        }}
        confirmChanges={() => {
          groupProfileContext?.confirmRemoveUserFromGroup?.call(groupProfileContext);
        }}
      />
    );
  }

  const columns: EuiBasicTableColumn<User>[] = [
    {
      field: 'loginEmail',
      name: 'User',
      render: (field: string) => {
        return (
          <Link
            onClick={() => {
              navigate(`/settings/accounts-and-access/users/${field}`);
            }}
          >
            {field}
          </Link>
        );
      },
    },
    {
      field: 'membershipType',
      name: (
        <ColumnNameWithHelp
          name={'Membership Type'}
          helpContent="How the user was assigned to the group. Either manually, or via Identity Provider attributes."
        />
      ),
    },
    {
      name: 'Actions',
      width: '5em',
      actions: [
        {
          name: 'Remove from Group',
          isPrimary: true,
          description: 'Remove this user from group',
          'data-test-subj': 'action-delete',
          render: (row: User) => {
            return (
              <TableActionButtonWithDisableMode
                isAdmin={isAdmin ?? false}
                requiresAdmin
                iconType={'trash'}
                color="danger"
                isDisabled={false}
                enabledMessage={'Remove this user from group'}
                disabledMessage={'You must be an admin to edit group membership.'}
                onClick={() => {
                  groupProfileContext?.showSelectedUserToDeleteModal?.call(groupProfileContext, row);
                }}
              />
            );
          },
        },
      ],
    },
  ];

  return (
    <div>
      <ACLTableControlLayout
        search={
          <DebouncedFieldSearch
            fullWidth
            placeholder="Search for Member"
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              groupProfileContext?.updateUserSearch?.call(groupProfileContext, event?.target?.value);
            }}
          />
        }
        actionButtons={
          <Button
            onClick={() => {
              groupProfileContext?.showAddMemberModal?.call(groupProfileContext);
            }}
            fill
            isDisabled={false}
            data-testid="add-user-to-group-button"
            title={isAdmin ? 'Add User to New Group' : 'Administrator Privileges Required'}
            disabled={!isAdmin}
          >
            Add User to Group
          </Button>
        }
      />
      <Spacer size="l" />
      <span data-testid="user-table">
        <TectonBasicTable
          items={groupProfileContext?.assignedUsers ?? []}
          columns={columns}
          pageIndex={0}
          pageSize={0}
          totalItemCount={0}
        />
      </span>
      {addMembersModal}
      {removeUserFromGroupModal}
    </div>
  );
};

export default ACLGroupMembersGQL;
