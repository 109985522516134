import React from 'react';

import { useTectonTheme } from '@tecton';
import { css } from '@emotion/css';

const SmallKeyValueTable = ({
  attributes,
  style,
}: {
  attributes: {
    key: React.ReactNode;
    value: React.ReactNode;
  }[];
  style?: React.CSSProperties;
}) => {
  const { theme } = useTectonTheme();

  return (
    <table
      style={style}
      className={
        css`
          margin: 0;

          th,
          td {
            text-align: left;
            padding-top: ${theme.size.s};
            padding-bottom: ${theme.size.s};
          }

          th {
            font-weight: 600;
            padding-right: ${theme.size.m};
          }
        ` + ' tecton-key-values-table'
      }
    >
      <tbody>
        {attributes.map(({ key, value }, i) => {
          return (
            <tr key={i}>
              <th>{key}</th>
              <td>{value}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default SmallKeyValueTable;
