import WarningIcon from '@mui/icons-material/Warning';
import {
  Alert,
  Box,
  Button,
  FormControlLabel,
  Modal,
  Stack,
  Switch,
  SxProps,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import _ from 'lodash';
import { ChangeEvent, useEffect, useRef, useState, VFC } from 'react';
import * as yup from 'yup';
import { DatabricksRequestInterface } from '../../../../components';
import { LoadingIndicator } from '../../../../components/LoadingIndicator';
import DatabricksConfigureStatusProgression from '../../../../components/shared/components/ConfigureDatabricks/DatabricksConfigureStatusProgression';
import useHasUserDeploymentConfiguration from '../../../../components/shared/components/ConfigureDatabricks/hasUserDeploymentConfiguration';
import UserDeploymentSettings from '../../../../service/UserDeploymentSettings';
import { getFieldMask } from '../../../../utils/databricks-confi/databricks-config-utils';
import { canEnabledDpoClusterButton, canShowDPOAccordion } from '../../../../utils/onboarding/onboarding-util';
import { createUserDeploymentSetting } from '../../../../utils/user-deployment-settings/user-deployment-setting';
import { useGetDataPlatformSetupStatus } from '../../../onboarding/configure/query';
import useAutoscroll from '../../../onboarding/useAutoscroll';
import { useDatabricksConfiguration } from '../../query';
import useConfirmationPrompt from './useConfirmationPrompt';

// Bring this back when we release GO DPO V2
import { useNavigate } from 'react-router';
import { DATABRICKS_LINKS, TECTON_DOCUMENTATION_LINKS } from '../../../../components/redesign/application-links';
import { Routes } from '../../../../core/routes';
import useValidateGlueIdIfNeeded from './useValidateGlueIdIfNeeded';

export enum SparkConfigurationMapKey {
  GLUE_CATALOG_ID = 'spark.hadoop.hive.metastore.glue.catalogid',
  GLUE_CATALOG_ID_ENABLED = 'spark.databricks.hive.metastore.glueCatalog.enabled',
}

export enum FieldMaskValues {
  workspace_url = 'databricksConfig.workspaceUrl',
  api_token = 'databricksConfig.apiToken.value',
  instance_profile = 'userSparkSettings.instanceProfileArn',
  spark_conf = 'userSparkSettings.sparkConf',
}

interface DatabricksConfigInterface {
  workspace_url?: string;
  api_token?: { value: string };
}

export interface KeyPairInterface {
  [key: string]: string;
}

interface UserSparkSettingsInterface {
  instance_profile_arn?: string;
  spark_conf?: KeyPairInterface;
}

interface UserDeploymentSettingInterface {
  databricks_config: DatabricksConfigInterface;
  user_spark_settings?: UserSparkSettingsInterface;
}

export interface UpdateUserDeploymentSettingsRequestInterface {
  user_deployment_settings?: UserDeploymentSettingInterface;
  field_mask: string;
}

interface SubmissionInterface {
  databricksApiToken: string;
  databricksUrl: string;
  glueCatalogId: string;
  instanceProfileArn: string;
  glueCatalogEnabled: boolean;
}

interface DatabricksCredentialsFormInterface {
  userDeploymentSettings: UserDeploymentSettings; // We pass this in so that use don't have to use an extra state for initialValues Formik
  validateDatabricksConnectionRequest: DatabricksRequestInterface;
  onCancel?: () => void; // Used for stand alone DPO
  isGuidedOnboarding: boolean;
  onUpdateUserDeploymentSuccess?: (response: any) => void; // Used for stand alone DPO
}

const sectionHeadingStyle: SxProps<Theme> = {
  mb: 1,
};

const DatabricksCredentialsForm: VFC<DatabricksCredentialsFormInterface> = ({
  userDeploymentSettings,
  validateDatabricksConnectionRequest,
  onCancel, // Used for stand alone DPO
  isGuidedOnboarding,
  onUpdateUserDeploymentSuccess, // Used for stand alone DPO
}) => {
  const navigate = useNavigate();
  const configurationState = useDatabricksConfiguration();

  // Ref for Auto scrolling
  const registerADataSourceRef = useRef(null);
  const databricksConfigureStatusProgressionRef = useRef(null);

  // Local Component States
  const [formValues, setFormValues] = useState({});
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isCredentialsFormDirty, setIsCredentialsFormDirty] = useState<boolean | undefined>(undefined);
  const [isDataPlatformSetupComplete, setIsDataPlatformSetupComplete] = useState(false);

  // Handles user refresh and makes a decision to show/hide the accordion
  const { hasDeploymentConfiguration, setHasDeploymentConfiguration } =
    useHasUserDeploymentConfiguration(configurationState);

  const dataPlatformSetupStatus = useGetDataPlatformSetupStatus(hasDeploymentConfiguration);

  const { dpoStatuses } = useValidateGlueIdIfNeeded(
    setIsDataPlatformSetupComplete,
    userDeploymentSettings,
    dataPlatformSetupStatus
  );

  const { canShowConfirmationPrompt, setCanShowConfirmationPrompt, onCloseModalClicked } = useConfirmationPrompt();

  const databricksTextfieldPlaceholder = `${window.location.protocol}//tecton-${window.location.hostname}.cloud.databricks.com`;

  // Formik dependencies
  const notRequiredDatabricksApiTokenRequirement = yup.string().notRequired();
  const requireDatabricksApiTokenRequirement = yup
    .string()
    .matches(new RegExp('^dapi'), 'API tokens must start with "dapi"')
    .matches(new RegExp('^[a-zA-Z0-9]*$'), 'API tokens should only contains letters and numbers')
    .min(36, 'API tokens are expected to be 36 characters long.')
    .max(36, 'API tokens are expected to be 36 characters long.')
    .required('API token is required.');

  let databricksApiTokenRequirementDefault: any = requireDatabricksApiTokenRequirement;

  // We check which validation we need to use for Formik.
  if (!_.isEmpty(userDeploymentSettings.databricksApiToken)) {
    // The user has already filled out the form before so we don't need a string requirement for the form.
    databricksApiTokenRequirementDefault = notRequiredDatabricksApiTokenRequirement;
  }

  let initialValues: SubmissionInterface = {
    databricksUrl: `${userDeploymentSettings.databricksWorkspaceUrl}`,
    databricksApiToken: `${userDeploymentSettings.databricksApiToken}`,
    instanceProfileArn: `${userDeploymentSettings.databricksInstanceProfileArn}`,
    glueCatalogEnabled: userDeploymentSettings.glueCatalogEnabled,
    glueCatalogId: `${userDeploymentSettings.glueCatalogId}`,
  };

  const [databricksApiTokenRequired, setDatabricksApiTokenRequired] = useState<boolean>(
    !_.isEmpty(userDeploymentSettings.databricksApiToken)
  );

  if (!onUpdateUserDeploymentSuccess) {
    onUpdateUserDeploymentSuccess = (response: any) => {
      // Setting this state allows the user to go ahead on to the next section of the guided onBoarding
      setHasDeploymentConfiguration(response.success);
    };
  }

  let submitButtonText = 'UPDATE CLUSTER CONFIGURATION';
  let updatePlatformLaterText = '';

  const [databricksApiTokenRequirement, setDatabricksApiTokenRequirement] = useState<any>(
    databricksApiTokenRequirementDefault
  );

  const validationSchema = yup.object({
    databricksUrl: yup
      .string()
      .matches(new RegExp(/^https:\/\//), 'Workspace url must start with https://')
      .matches(
        new RegExp(/(.gcp.databricks.com)$|(.cloud.databricks.com)$|(.azuredatabricks.net)$/),
        'Workspace url must end in .databricks.com or .azuredatabricks.net'
      )
      .url('Must be a valid URL.')
      .required(`Databricks URL is required. Example: ${databricksTextfieldPlaceholder}`),
    databricksApiToken: databricksApiTokenRequirement,
    instanceProfileArn: yup
      .string()
      .notRequired()
      .matches(
        new RegExp(/^arn:aws:iam::[0-9]{12}:instance-profile\/[a-zA-Z0-9-_+=,.@]+$/gm),
        'Instance profile ARN should use the format "arn:aws:iam::<account-ID>:instance-profile/<profile-name>"'
      ),
    glueCatalogId: yup
      .string()
      .notRequired()
      .matches(/^[0-9]{12}$/, 'Glue Catalog ID must be exactly 12 digits.'),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values: SubmissionInterface, actions: FormikHelpers<SubmissionInterface>) => {
      // Set errorMessage to it's initial state so past error goes away.
      setErrorMessage('');
      if (isGuidedOnboarding) {
        updateSettings(values);
      } else {
        setCanShowConfirmationPrompt(true);
      }
      setFormValues(values);
      // We reset the form here with the original values so the form is not marked dirty
      actions.resetForm({ values: { ...values } });
      // We mark the form clean
      setIsCredentialsFormDirty(false);
      // We set the DPO back to false since they resubmitted the form
      setIsDataPlatformSetupComplete(false);
    },
  });

  useEffect(() => {
    // Initial page load
    formik.validateForm();
  }, []);

  useEffect(() => {
    if (formik.dirty) {
      // Once the user edits the form, we mark it dirty right away and
      // keep it dirty even though they place back the original values.
      setIsCredentialsFormDirty(true);
      setIsDataPlatformSetupComplete(false);
    }
  }, [formik.dirty]);

  useAutoscroll(registerADataSourceRef, isDataPlatformSetupComplete);

  if (configurationState.isLoading) {
    return <LoadingIndicator title="Loading Configuration" />;
  }

  if (isGuidedOnboarding) {
    submitButtonText = 'CREATE DATABRICKS CLUSTER';
    updatePlatformLaterText = 'Data platform settings can be updated after setup in the Compute tab.';
  }

  const updateSettings = (values: any) => {
    let sparkConfig: KeyPairInterface = {};
    if (values.glueCatalogEnabled) {
      sparkConfig[`${SparkConfigurationMapKey.GLUE_CATALOG_ID_ENABLED}`] = 'true';
      if (!_.isEmpty(values.glueCatalogId)) {
        // Add glueCatalog if it exist tp the spark config map
        sparkConfig[`${SparkConfigurationMapKey.GLUE_CATALOG_ID}`] = values.glueCatalogId;
      }
    }

    const fieldMask = getFieldMask(values, userDeploymentSettings);
    const userDeploymentSetting = createUserDeploymentSetting(
      values.databricksUrl,
      values.databricksApiToken,
      values.instanceProfileArn,
      sparkConfig,
      fieldMask
    );

    if (validateDatabricksConnectionRequest && validateDatabricksConnectionRequest.sendRequest) {
      validateDatabricksConnectionRequest.sendRequest(userDeploymentSetting, (response: any) => {
        // Only Data platform flow get the modal prompt
        if (!isGuidedOnboarding) {
          setCanShowConfirmationPrompt(false);
        }

        if (!response.success) {
          // We show the error that is returned from the backend
          setErrorMessage(response.error_message);
        }

        // We call this function so data platform flow and onboarding can behave differently
        // Data platform redirects to the read version of the form
        // Onboarding flow continues on to the next flow.
        if (onUpdateUserDeploymentSuccess) {
          // use in standalone DPO
          onUpdateUserDeploymentSuccess(response);
        }

        // Refetch status Data platform status
        dataPlatformSetupStatus.refetch();
      });
    }
  };

  const onConfirmClicked = () => {
    updateSettings(formValues);
  };

  const isDatabricksUrlDisabled = (userDeploymentSettings: UserDeploymentSettings) => {
    if (isGuidedOnboarding) {
      return false;
    }
    return !_.isEmpty(_.get(userDeploymentSettings, 'databricksWorkspaceUrl'));
  };

  const onDatabricksApiTokenChange = (event: ChangeEvent) => {
    // We check to see if they've modified the API token
    if (_.isEqual(userDeploymentSettings.databricksApiToken, _.get(event, 'target.value'))) {
      // If they haven't modified it, we still show i on the form as the redacted version.
      setDatabricksApiTokenRequirement(notRequiredDatabricksApiTokenRequirement);
      setDatabricksApiTokenRequired(false);
    } else {
      // They have modified it so we apply the requirements to the form since they want to update the API token
      setDatabricksApiTokenRequirement(requireDatabricksApiTokenRequirement);
      setDatabricksApiTokenRequired(true);
    }

    // We pass the event to formik.
    formik.handleChange(event);
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        {isGuidedOnboarding && (
          <Stack spacing={2} sx={{ mb: 2 }}>
            {hasDeploymentConfiguration ? (
              <Alert severity="info">
                Databricks clusters are being provisioned. Progress can be tracked in the steps below
              </Alert>
            ) : (
              <Typography variant="body1">
                To connect Tecton to your Databricks account, fill in the fields below. Your account must have access to
                your data sources.
              </Typography>
            )}
          </Stack>
        )}
        {!canShowDPOAccordion(isGuidedOnboarding, dpoStatuses, isCredentialsFormDirty, errorMessage) && (
          <Stack spacing={6} sx={{ pt: 4 }}>
            <Stack spacing={2}>
              <Box>
                <Typography variant="h5" sx={sectionHeadingStyle}>
                  Databricks Workspace URL
                </Typography>
                <Typography variant="body2">
                  The Databricks workspace connected to Tecton is pre-configured with your Tecton instance. The URL must
                  start with &ldquo;https://&rdquo; and end with &ldquo;cloud.databricks.com&rdquo;. You can only set
                  this once. If you would like to change your Databricks workspace once it&rsquo;s set, please contact
                  Tecton Support.
                </Typography>
              </Box>
              <TextField
                required
                id="databricksUrl"
                label="Databricks URL"
                disabled={isDatabricksUrlDisabled(userDeploymentSettings)}
                fullWidth
                onBlur={formik.handleBlur}
                value={formik.values.databricksUrl}
                error={formik.touched.databricksUrl && Boolean(formik.errors.databricksUrl)}
                onChange={formik.handleChange}
                helperText={formik.touched.databricksUrl && formik.errors.databricksUrl}
                placeholder={databricksTextfieldPlaceholder}
              />
            </Stack>
            <Stack spacing={2}>
              <Box>
                <Typography variant="h5" sx={sectionHeadingStyle}>
                  Databricks API Token
                </Typography>
                <Typography variant="body2">
                  The Databricks API token with cluster creation privileges for your Databricks workspace. The token
                  must be associated with a user or service principal which has <b>workspace access</b> and{' '}
                  <b>allow unrestricted cluster creation</b> entitlements, along with access to the AWS instance profile
                  below.
                  <a
                    target="_blank"
                    href={
                      'https://docs.databricks.com/administration-guide/access-control/cluster-acl.html#configure-cluster-creation-entitlement'
                    }
                    rel="noreferrer"
                  >
                    <span> See here for more info.</span>
                  </a>
                </Typography>
              </Box>
              <TextField
                required={databricksApiTokenRequired}
                id="databricksApiToken"
                type="password"
                label="Databricks API Token"
                placeholder={`${userDeploymentSettings.databricksApiToken}`}
                fullWidth
                onBlur={formik.handleBlur}
                value={formik.values.databricksApiToken}
                error={formik.touched.databricksApiToken && Boolean(formik.errors.databricksApiToken)}
                onChange={onDatabricksApiTokenChange}
                helperText={formik.touched.databricksApiToken && formik.errors.databricksApiToken}
              />
            </Stack>
            <Stack spacing={2}>
              <Box>
                <Typography variant="h5" sx={sectionHeadingStyle}>
                  Instance Profile
                </Typography>
                <Typography variant="body2">
                  Optional: An AWS IAM instance profile used by EC2 for data access. Instance profiles are used to
                  provide role information to EC2 instances managed by Databricks. These allow Tecton-managed Databricks
                  clusters to access data without requiring AWS keys. This instance profile should already be added to
                  your Databricks workspace. This field is required in order for Tecton to read data from AWS.
                  <a href={DATABRICKS_LINKS.AWS_GLUE_METASTORE_STEP_5} target="_blank" rel="noreferrer">
                    <span> More info about using instance profiles with Databricks.</span>
                  </a>
                </Typography>
              </Box>
              <TextField
                id="instanceProfileArn"
                label="Instance Profile ARN"
                fullWidth
                onBlur={formik.handleBlur}
                value={formik.values.instanceProfileArn}
                error={formik.touched.instanceProfileArn && Boolean(formik.errors.instanceProfileArn)}
                onChange={formik.handleChange}
                helperText={
                  formik.touched.instanceProfileArn &&
                  (formik.errors.instanceProfileArn ||
                    (formik.values.instanceProfileArn === '' &&
                      'Warning: no instance profile is provided. Tecton will not be able to access data in AWS.'))
                }
              />
            </Stack>
            <Stack spacing={2}>
              <Box>
                <Typography variant="h5" sx={sectionHeadingStyle}>
                  AWS Glue Catalog Access
                </Typography>
                <Typography variant="body2">
                  Optional: Enable access to your AWS Glue Catalog to create Hive data sources with much faster data
                  access. This sets &apos;spark.databricks.hive.metastore.glueCatalog.enabled&apos; to true in your
                  Spark configuration.
                  <a href={DATABRICKS_LINKS.AWS_GLUE_METASTORE} target="_blank" rel="noreferrer">
                    <span> More info about Databricks Glue metastore support.</span>
                  </a>
                </Typography>
              </Box>
              <Box style={{ border: '1px solid lightgrey', borderRadius: '8px' }}>
                <FormControlLabel
                  labelPlacement="start"
                  style={{ marginTop: 4, marginBottom: 4 }}
                  control={
                    <Switch
                      id="glueCatalogEnabled"
                      onChange={() =>
                        formik.values.glueCatalogEnabled
                          ? formik.setFieldValue('glueCatalogEnabled', false)
                          : formik.setFieldValue('glueCatalogEnabled', true)
                      }
                      checked={formik.values.glueCatalogEnabled}
                    />
                  }
                  label={formik.values.glueCatalogEnabled ? 'Glue Access Enabled' : 'Glue Access Disabled'}
                />
              </Box>
            </Stack>
            {formik.values.glueCatalogEnabled ? (
              <Stack spacing={2}>
                <Box>
                  <Typography variant="h5" sx={sectionHeadingStyle}>
                    AWS Glue Catalog ID
                  </Typography>
                  <Typography variant="body2">
                    Optional: Provide the AWS Glue Catalog ID if the Glue Data Catalog is in a different AWS account
                    than this Databricks workspace. Tecton will set
                    &apos;spark.hadoop.hive.metastore.glue.catalogid&apos; value in your Spark configuration.
                    <a href={TECTON_DOCUMENTATION_LINKS.CONNECTING_DATA_SOURCES_HIVE} target="_blank" rel="noreferrer">
                      <span> More info about permissions for connecting a Glue metastore.</span>
                    </a>
                  </Typography>
                </Box>
                <TextField
                  id="glueCatalogId"
                  label="AWS Glue Catalog ID"
                  fullWidth
                  onBlur={formik.handleBlur}
                  value={formik.values.glueCatalogId}
                  error={formik.touched.glueCatalogId && Boolean(formik.errors.glueCatalogId)}
                  onChange={formik.handleChange}
                  helperText={formik.touched.glueCatalogId && formik.errors.glueCatalogId}
                />
              </Stack>
            ) : (
              <div />
            )}
            {/* Todo: Replace this with <ValidationFeedback/> component*/}
            {errorMessage && <Alert severity="error">{errorMessage}</Alert>}

            <div className="mt4">
              <div className="mb2">{updatePlatformLaterText}</div>
              <span>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={
                    !canEnabledDpoClusterButton(
                      formik,
                      isCredentialsFormDirty,
                      configurationState,
                      validateDatabricksConnectionRequest.isLoading,
                      isDataPlatformSetupComplete,
                      isGuidedOnboarding
                    )
                  }
                >
                  {submitButtonText}
                </Button>
              </span>
              {onCancel && (
                <span className="ml4">
                  <Button
                    variant="contained"
                    className="ml4"
                    onClick={() => {
                      // Use for Standalone DPO
                      onCancel();
                    }}
                  >
                    CANCEL
                  </Button>
                </span>
              )}
            </div>
          </Stack>
        )}
      </form>
      {canShowDPOAccordion(isGuidedOnboarding, dpoStatuses, isCredentialsFormDirty, errorMessage) && (
        <Stack spacing={4}>
          <DatabricksConfigureStatusProgression
            dpoStatuses={dpoStatuses}
            databricksConfigureStatusProgressionRef={databricksConfigureStatusProgressionRef}
          />

          {isDataPlatformSetupComplete && (
            <Stack spacing={2}>
              <>
                <Typography variant="h3">Databricks Setup Complete</Typography>
                <Typography variant="subtitle1">
                  With Databricks connected, your feature platform is ready for use. We have created your first
                  Workspace, where you can begin developing features.
                </Typography>
                <Box>
                  <Button
                    ref={registerADataSourceRef}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      navigate(`${Routes.defaultProdHome}?onboarding=success`);
                    }}
                  >
                    Go to prod workspace dashboard
                  </Button>
                </Box>
              </>
            </Stack>
          )}
        </Stack>
      )}
      <Modal
        open={canShowConfirmationPrompt}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className="pa4 bg-color-ui-white col-4 mt7 center modal">
          <div className="f3 fw6 mb3">Update Cluster Configuration?</div>
          <div className="pa4" style={{ maxHeight: 400, overflow: 'auto' }}>
            <WarningIcon style={{ position: 'relative', top: 5, color: '#FFAB00' }} />
            Warning: making changes to the data platform configuration will cause Tecton to restart streaming
            materialization jobs to pick up the latest changes. This will not cause data loss but will temporarily
            impact feature freshness. Additionally, <span style={{ color: '#FFAB00' }}> tecton plan/apply </span> may
            run slowly while the changes take effect (typically less than ten minutes).
          </div>
          <div className="mt5 flex items-end">
            <div className="mr3">
              <button onClick={onConfirmClicked} className="button button--primary button--small">
                Update
              </button>
              <button
                onClick={onCloseModalClicked}
                className="button button--secondary button--small"
                style={{ marginLeft: 10 }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DatabricksCredentialsForm;
