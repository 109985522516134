import React, { FC } from 'react';
import {
  Loading,
  FlexGroup,
  FlexItem,
  StormCloud,
  SearchIllustration,
  DataflowIllustration,
} from '@tecton/ComponentRedesign';
import { ReactComponent as Popout } from '@tecton/ComponentRedesign/svg/popout.svg';
import styled from '@emotion/styled';
import { tint } from '@elastic/eui';

export type TectonEmptyVariant = 'search' | 'storm' | 'loading' | 'dataflow';
export type TectonEmptyMode = 'default' | 'warning' | 'danger' | 'success' | 'neutral';

interface TectonEmptyPromptProps {
  variant?: TectonEmptyVariant;
  orientation?: 'horizontal' | 'vertical';
  title: React.ReactNode;
  body: React.ReactNode;
  actions?: React.ReactNode;
  banner?: boolean;
  mode?: TectonEmptyMode;
  shadow?: boolean;
  customIcon?: React.ReactNode;
}

const EmptyPromptWrapper = styled.div<{ mode: TectonEmptyMode; shadow: boolean }>`
  display: flex;
  flex-direction: column;
  // width: 805px;
  // height: 400px;
  background-color: ${({ mode, theme }) => {
    switch (mode) {
      case 'default':
        return theme.colors.emptyShade;
      case 'danger':
        return theme.colors.background.danger;
      case 'neutral':
        return theme.colors.background.subdued;
      case 'success':
        return theme.colors.background.success;
      case 'warning':
        return theme.colors.background.warning;
    }
  }};
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.border.radius.small};
  box-shadow: ${({ shadow, theme }) => (shadow ? theme.shadow.l : 'none')};
`;

const HorizontalEmptyPrompt = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  gap: ${({ theme }) => theme.padding.xl};
  grid-template-columns: 1fr auto;
  padding: ${({ theme }) => theme.padding.xl};
`;

const LeftColumn = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.padding.xl} 0px;
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: ${({ theme }) => theme.padding.l};
  overflow: hidden;
`;

const TitleText = styled.div`
  color: ${({ theme }) => theme.colors.title};
  font-size: ${({ theme }) => theme.font.headingSizes['3']};
  font-weight: ${({ theme }) => theme.font.weight.medium};
  line-height: ${({ theme }) => theme.font.headingLineHeights['3']}; /* 120% */
`;

const BodyText = styled.div`
  word-wrap: break-word;
  text-align: left;
  max-width: 800px;

  color: ${({ theme }) => theme.colors.subduedText};
  font-size: ${({ theme }) => theme.font.fontSizes.s};
  p {
    white-space: wrap !important;
    white-space-collapse: break-spaces !important;

    line-height: 20px; /* 142.857% */
    margin-bottom: 20px;
  }
`;

const Buttons = styled.div`
  display: flex;
  align-items: flex-start;
  gap: ${({ theme }) => theme.padding.s};
`;

const RightColumn = styled.div`
  display: flex;
  width: 350px;
  height: 350px;
  justify-content: center;
  align-items: center;
`;

const FooterBanner = styled.div`
  padding: var(--spacing-3xl, 24px);

  align-self: stretch;
  background-color: ${({ theme }) => theme.colors.body};

  font-weight: ${({ theme }) => theme.font.weight.medium};

  a {
    display: inline;
    font-weight: ${({ theme }) => theme.font.weight.regular};
  }
`;

const HorizontalTectonEmpty: FC<TectonEmptyPromptProps> = ({
  title,
  body,
  variant = 'search',
  actions,
  banner,
  mode = 'default',
  shadow,
  customIcon = undefined,
}) => {
  const iconMap: Record<TectonEmptyVariant, React.ReactNode> = {
    search: <SearchIllustration width={351} height={351} />,
    storm: <StormCloud width={351} height={351} />,
    loading: <Loading size="xxl" />,
    dataflow: <DataflowIllustration width={351} height={351} />,
  };
  const icon = iconMap[variant];

  return (
    <>
      <EmptyPromptWrapper data-testid="horizontal-empty-prompt" mode={mode} shadow={shadow ?? false}>
        <HorizontalEmptyPrompt>
          <LeftColumn>
            <TitleText>{title}</TitleText>
            <BodyText>{body}</BodyText>
            <Buttons>{actions}</Buttons>
          </LeftColumn>
          <RightColumn>{customIcon ?? icon}</RightColumn>
        </HorizontalEmptyPrompt>
        {banner && (
          <FooterBanner>
            Want to learn more?{' '}
            <a href="http://docs.tecton.ai" target={'_blank'}>
              Read Documentation
              <Popout width={14} height={14} style={{ display: 'inline' }} />
            </a>
          </FooterBanner>
        )}
      </EmptyPromptWrapper>
    </>
  );
};

const VerticalEmptyShadowBox = styled.div<{ shadow: boolean; mode: TectonEmptyMode }>`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: center;
  background-color: ${({ mode, theme }) => {
    switch (mode) {
      case 'default':
        return theme.colors.emptyShade;
      case 'danger':
        return theme.colors.background.danger;
      case 'neutral':
        return theme.colors.background.subdued;
      case 'success':
        return theme.colors.background.success;
      case 'warning':
        return theme.colors.background.warning;
    }
  }};
  border-radius: ${({ theme }) => theme.border.radius.small};
  box-shadow: ${({ shadow, theme }) => (shadow ? theme.shadow.l : 'none')};
`;

const WrapVerticalEmptyContent = styled.div`
  // height: 400px;
  align-self: stretch;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const VerticalIcon = styled.div`
  text-align: center;
  overflow: hidden;
`;

const FlexVerticalButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.padding.m};
`;

const VerticalFooter = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.padding.l};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  background-color: ${({ theme }) => theme.colors.body};
`;

const VerticalTectonEmpty: FC<TectonEmptyPromptProps> = ({
  title,
  body,
  variant = 'search',
  actions,
  mode = 'default',
  banner,
  shadow,
  customIcon,
}) => {
  const iconMap: Record<TectonEmptyVariant, React.ReactNode> = {
    search: <SearchIllustration width={161} height={161} />,
    storm: <StormCloud width={161} height={161} />,
    loading: <Loading size="xxl" />,
    dataflow: <DataflowIllustration width={161} height={161} />,
  };
  const icon = iconMap[variant];

  return (
    <VerticalEmptyShadowBox mode={mode} shadow={shadow ?? false}>
      <WrapVerticalEmptyContent data-testid="vertical-empty-prompt">
        <div>
          <FlexGroup direction="column" justifyContent="center" alignItems="center">
            <FlexItem>
              <VerticalIcon>{customIcon ?? icon}</VerticalIcon>
            </FlexItem>
            <FlexItem>
              <FlexGroup direction="column" gutterSize="m" alignItems="center">
                <FlexItem>
                  <TitleText>{title}</TitleText>
                </FlexItem>
                <FlexItem>
                  <BodyText>{body}</BodyText>
                </FlexItem>
                <FlexItem>
                  <FlexVerticalButtons>{actions}</FlexVerticalButtons>
                </FlexItem>
              </FlexGroup>
            </FlexItem>
          </FlexGroup>
        </div>
      </WrapVerticalEmptyContent>

      <div>
        {banner && (
          <VerticalFooter>
            Want to learn more?{' '}
            <a href="http://docs.tecton.ai" target={'_blank'}>
              Read Documentation
              <Popout width={14} height={14} style={{ display: 'inline' }} />
            </a>
          </VerticalFooter>
        )}
      </div>
    </VerticalEmptyShadowBox>
  );
};

const TectonEmptyPrompt: FC<TectonEmptyPromptProps> = ({
  title,
  body,
  variant = 'search',
  orientation = 'vertical',
  actions,
  banner,
  mode = 'default',
  shadow,
  customIcon,
}) => {
  if (orientation === 'vertical') {
    return (
      <VerticalTectonEmpty
        title={title}
        body={body}
        actions={actions}
        variant={variant}
        banner={banner}
        mode={mode}
        shadow={shadow}
        customIcon={customIcon}
      />
    );
  }

  return (
    <HorizontalTectonEmpty
      title={title}
      body={body}
      actions={actions}
      variant={variant}
      banner={banner}
      mode={mode}
      shadow={shadow}
      customIcon={customIcon}
    />
  );
};

export default TectonEmptyPrompt;
