import React, { FC } from 'react';
import { useTectonTheme } from './TectonThemeProvider';
import { css } from '@emotion/css';

interface EmptyTabProps {
  title: React.ReactNode;
  message: React.ReactNode;
}

const EmptyTab: FC<EmptyTabProps> = ({ title, message }) => {
  const { theme } = useTectonTheme();

  const containerStyle = css`
    padding: ${theme.size.xxxl};
    text-align: center;
    border: ${theme.border.thin};
  `;

  const titleStyle = css`
    font-size: 18px;
    color: ${theme.color.light.darkShade};
    font-weight: ${theme.font.weight.bold};
  `;

  const messageStyle = css`
    color: ${theme.color.light.mediumShade};
  `;

  return (
    <div className={containerStyle}>
      <div className={titleStyle}>{title}</div>
      <div className={messageStyle}>{message}</div>
    </div>
  );
};

export default EmptyTab;
