import React from 'react';
import { EuiPopoverTitle, ExclusiveUnion } from '@elastic/eui';
import { Spacer, ButtonEmpty, Selectable, FlexGroup, FlexItem, Checkbox } from '.';
import {
  EuiSelectableGroupLabelOption,
  EuiSelectableLIOption,
  EuiSelectableOption,
} from '@elastic/eui/src/components/selectable/selectable_option';
import PopoverSelectorFooter from './PopoverSelectorFooter';

export interface CategoricalOptionInterface {
  label: string;
  checked?: string;
  value?: any;
}

interface OrderProperty {
  order?: number;
}

export type FilterOptionType = ExclusiveUnion<
  EuiSelectableGroupLabelOption<CategoricalOptionInterface & OrderProperty>,
  EuiSelectableLIOption<CategoricalOptionInterface & OrderProperty>
>;

export interface CategoricalSelectorProps {
  items: FilterOptionType[];
  setItems: (items: FilterOptionType[]) => void;
  renderOption?: (option: EuiSelectableOption<CategoricalOptionInterface>, searchValue: string) => React.ReactNode;
  single?: boolean;
  sort?: boolean;
  searchable?: boolean;
  clearAll?: () => void;
  name?: string;
  showSelectAll?: boolean;
  clearTextLabel?: string;
}

const checkedSort = (a: FilterOptionType, b: FilterOptionType) => {
  if (a.checked === 'on' && b.checked !== 'on') {
    return -1;
  } else if (a.checked !== 'on' && b.checked === 'on') {
    return 1;
  } else {
    // If both 'a' and 'b' have the same "checked" state, sort alphabetically by the "label" property
    return a.label.localeCompare(b.label);
  }
};

const CategoricalSelectorCheckList = ({
  items,
  setItems,
  renderOption,
  searchable = false,
  single = false,
  sort = false,
  clearAll,
  name,
  showSelectAll = true,
  clearTextLabel = 'Clear',
}: CategoricalSelectorProps) => {
  const renderListItem = renderOption;
  const clearFilters = () => {
    setItems(
      items.map((item) => {
        delete item.checked;

        return item;
      })
    );
  };

  const selectAll = () => {
    setItems(
      items.map((item) => ({
        ...item,
        checked: 'on',
      }))
    );
  };

  const searchProps = searchable
    ? {
        searchable,
        searchProps: {
          placeholder: 'Filter list',
          compressed: true,
        },
      }
    : {};

  return (
    <Selectable
      {...searchProps}
      aria-label="Composers"
      options={sort ? items.sort(checkedSort) : items}
      data-testid={`selector-list-${name}`}
      onChange={(newOptions) => {
        setItems(newOptions);
      }}
      loadingMessage="Loading filters"
      emptyMessage="No filters available"
      noMatchesMessage="No filters found"
      singleSelection={single}
      renderOption={(option, searchValue) => {
        const rendered = renderListItem ? renderListItem(option, searchValue) : option.label;

        return (
          <FlexGroup justifyContent="flexStart" alignItems="center">
            <FlexItem grow={0} style={{ pointerEvents: 'none' }}>
              <Checkbox
                id={''}
                label={false}
                checked={option.checked === 'on'}
                onClick={(e) => {
                  e.preventDefault();
                }}
                onChange={(e) => {
                  e.preventDefault();
                  return;
                }}
                aria-label={''}
              />
            </FlexItem>
            <FlexItem grow={0}>{rendered}</FlexItem>
          </FlexGroup>
        );
      }}
      height="full"
      listProps={{ showIcons: false }} // TODO: The optimal way to handle this would be to allow the full listProps in the interface for the component. However, I'm temporarily doing this until we determine if this is ALWAYS the behavior or if it needs to be configurable on a per-selector basis
    >
      {(list, search) => (
        <>
          {searchable && (
            <EuiPopoverTitle paddingSize="s" style={{ margin: '0' }}>
              {search}
            </EuiPopoverTitle>
          )}
          {!searchable && <Spacer size="s" />}
          {list}
          <PopoverSelectorFooter>
            <ButtonEmpty size="s" onClick={clearAll ?? clearFilters}>
              {clearTextLabel}
            </ButtonEmpty>
            {!single && showSelectAll && (
              <ButtonEmpty size="s" onClick={selectAll}>
                Select All
              </ButtonEmpty>
            )}
          </PopoverSelectorFooter>
        </>
      )}
    </Selectable>
  );
};

export default CategoricalSelectorCheckList;
