import React from 'react';

export default function Tags({ tags, showPlaceholderWhenEmpty = true, emptyTextValue = `-` }) {
  const entries = Object.entries(tags || {});

  if (entries.length === 0 && showPlaceholderWhenEmpty) {
    return <>{emptyTextValue}</>;
  }

  return (
    <>
      {entries.map((entry) => {
        const tag = entry[0] + ':' + entry[1];
        return (
          <span key={tag} className="f7 bw-1 b-solid pl1 pr1 color-text-600 dib mr1 mb1">
            {tag}
          </span>
        );
      })}
    </>
  );
}
