import React from 'react';
import { IconTip, Link } from '@tecton';
import { useNavigate } from 'react-router-dom';

const GroupNameColumn = ({ groupId, groupName }: { groupId: string; groupName: string }) => {
  const navigate = useNavigate();

  return (
    <Link
      onClick={() => {
        navigate(`/settings/accounts-and-access/groups/${groupId}/`);
      }}
      style={{
        fontWeight: 'inherit',
      }}
    >
      {groupName}{' '}
      {groupId === 'all-principals' && (
        <IconTip
          content={`All users and services accounts are in this group by default.`}
          position="top"
          type="iInCircle"
        />
      )}
    </Link>
  );
};

export default GroupNameColumn;
