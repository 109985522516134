import { EuiSelectableOption } from '../@tecton';
import { ServiceAccount } from '../../types/tecton_proto/metadataservice/metadata_service';
import { OptionsInfo } from '../shared/RowSelectorFormRow';
import {
  AccountTypeWithInheritance as AccountTypeWithInheritanceGQL,
  AclWorkspaceServiceRelationship,
  Group,
} from '../../api/gql/graphql';
import { EuiComboBoxOptionOption } from '@tecton';
import { SelectableGroupOptionGQL } from './modals/UserAddToGroupModalGQL';
import { CalloutMessageType } from '../@shared';
import { Dispatch, SetStateAction, Context } from 'react';
import { ACLPrincipalModal } from './aclUtils';
import { PrincipalType } from '../../types/tecton_proto/auth/principal';

type ACLWorkspaceMembershipType = 'direct' | 'via group' | 'via all workspaces role';

type ACLResourceRoles = {
  resource_id?: string;
  roles: ACLRoleWithInheritance;
};

export enum ACLInheritanceEnum {
  SERVICE,
  USER,
}

type ACLRoleSource = {
  type: ACLWorkspaceMembershipType;
  role: string;
  groupId?: string;
  groupName?: string;
};

type ACLRoleWithInheritance = {
  effectiveWorkspaceRole: string | undefined;
  roleSources: ACLRoleSource[];
};

const isDirectMember = (roles: ACLRoleWithInheritance) => {
  return !!roles.roleSources.find((r) => {
    return r.type === 'direct';
  });
};

const getAssignedRole = (roles: ACLRoleWithInheritance): string => {
  return (
    roles.roleSources.find((r) => {
      return r.type === 'direct';
    })?.role || 'none'
  );
};

type AccountType = 'Admin' | 'Regular';

type InheritedAccountType = {
  groupName: string;
  groupId: string;
  accountType: AccountType;
};

interface ACLSimpleGroup {
  name?: string;
  groupId?: string;
}

type AccountTypeWithInheritance = {
  effectiveAccountType: AccountType;
  assignedAccountType: AccountType;
  inheritedAccountType?: InheritedAccountType[];
};

interface ACLServiceAccountTableItem extends ServiceAccount {
  accountType: AccountTypeWithInheritance;
  isOwner?: boolean; // is the logged in user the one that created this server account?
}

interface ACLUserMembershipGroup extends ACLSimpleGroup {
  groupId: string;
  hasPriority?: boolean;
  name: string;
  membersCount: number;
}

type ACLAccountStatuses =
  | 'active'
  | 'provisioned'
  | 'deprovisioned'
  | 'inactive'
  | 'staged'
  | 'recovery'
  | 'locked_out';

const aclPriorityRowColor = '#e6f1f7';

export { isDirectMember, getAssignedRole, aclPriorityRowColor };

type ACLGroupWorkspaceType = {
  workspaceId: string;
  hasPriority?: boolean;
  role: string;
};

/** Don't forget to delete this type when we migration fully to GQL. It's a duplicate from a protos.*/
type ACLGroupTableItem = {
  groupId: string;
  name: string;
  accountType: AccountType;
  members_count: number;
  workspace_count?: number | string;
  description?: string;
  created_at?: string;
  workspaces: ACLGroupWorkspaceType[];
  idp_mapping_names?: string[];
  is_membership_editable?: boolean;
  isMember: boolean;
};

type CandidateMemberOption = EuiSelectableOption<{
  data: {
    name: string;
    id: string;
  };
}>;

type ACLGroupMemberType = {
  id: string;
  membershipType: 'manual' | 'via IdP';
  oktId?: string;
};

type ACLGroupServiceAccountType = {
  serviceAccountId: string;
  name: string;
};

interface PrincipalProfileContextProp {
  // account type
  accountType?: AccountTypeWithInheritanceGQL;
  modal?: ACLPrincipalModal;
  // Roles
  allRoles?: OptionsInfo<string>[];
  selectedRole?: string;
  assignedWorkspacesWithRoles?: AclWorkspaceServiceRelationship[];

  addNewWorkspaceRoleOptionsById?: string[];
  editWorkspaceRoleOptionsById?: string[];
  // workspace
  selectedWorkspace?: EuiComboBoxOptionOption<string>[];
  assignableWorkspaces?: EuiComboBoxOptionOption<string>[];
  workspaceCount?: string;
  // groups
  selectedAssignableGroups?: SelectableGroupOptionGQL[];
  assignedGroups?: Group[];
  assignableGroups?: SelectableGroupOptionGQL[];
  assignedGroupCount: number;
  // general state
  modalError?: CalloutMessageType[];
  isLoading?: boolean;
  isModalLoading?: boolean;
  errorMessages?: CalloutMessageType[];
  error?: any;
  updateGroupFilter?: (searchText: string) => void;
  updateSelectedAssignableGroups?: (options: SelectableGroupOptionGQL[]) => void;
  updateSelectedWorkspace?: (selectedWorkspace: EuiComboBoxOptionOption<string>[]) => void;
  updateSelectedRole?: (role?: string) => void;
  updateAccountType?: (accountType?: AccountType) => void;
  updateModal?: (userModal: ACLPrincipalModal) => void;
  editAssignedWorkspace?: () => void;
  setErrorMessages?: Dispatch<SetStateAction<CalloutMessageType[] | undefined>>;
  setPrincipalWorkspaceAssignRoleSearchFilter?: Dispatch<SetStateAction<string | undefined>>;
  setModalError?: Dispatch<SetStateAction<CalloutMessageType[] | undefined>>;
  confirmRemoveGroup?: (groupId: string, groupName: string) => void;
  closeModal?: () => void;
  confirmAddGroup?: () => void;
  clearSelectedWorkspaces?: () => void;
}

interface ACLContentParams<T> {
  principalId: string;
  context: Context<T>;
  principalName: string;
  principalType: PrincipalType;
}

export type {
  ACLRoleWithInheritance,
  ACLRoleSource,
  ACLGroupMemberType,
  AccountType,
  AccountTypeWithInheritance,
  InheritedAccountType,
  ACLGroupServiceAccountType,
  ACLUserMembershipGroup,
  ACLWorkspaceMembershipType,
  ACLAccountStatuses,
  ACLResourceRoles,
  ACLGroupTableItem,
  ACLGroupWorkspaceType,
  CandidateMemberOption,
  ACLSimpleGroup,
  ACLServiceAccountTableItem,
  AccountTypeWithInheritanceGQL,
  PrincipalProfileContextProp,
  ACLContentParams,
};
