import React, { FC } from 'react';

interface FeatureServiceIconProps {
  newVersion?: boolean;
}

const FeatureServiceIcon: FC<FeatureServiceIconProps> = ({ newVersion }) => {
  return (
    <g>
      <path opacity="1" d="M0 16 L 12 0 L 24 16 L 12 24 Z" fill="#FFFFFF" />

      <path opacity="0.4" d="M0 16L12 0V24L0 16Z" fill="#FFAB00" />
      <path opacity="0.34" d="M23.9999 16L11.9999 0V24L23.9999 16Z" fill="#FFAB00" />
      <path d="M12.0001 10L17.077 6.76923L12.0001 0V10Z" fill="#FFAB00" />
      <path d="M11.9998 10L6.92285 6.7692L11.9992 0.000793147L11.9998 0V10Z" fill="#FFAB00" />
      <path
        opacity="0.4"
        d="M12.0001 17.0001L20.6519 11.5358L17.0894 6.78583L12.0001 10.0001V17.0001Z"
        fill="#FFAB00"
      />
      <path
        opacity="0.4"
        d="M11.9995 17.0002L3.34766 11.5358L6.91016 6.78583L11.9995 10.0002V17.0002Z"
        fill="#FFAB00"
      />
    </g>
  );
};

export default FeatureServiceIcon;
