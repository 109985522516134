import { createTheme } from '@mui/material';

const MuiApplicationTheme = createTheme({
  typography: {
    fontFamily: 'Inter',
    h1: {
      fontSize: '35px',
      fontWeight: 'bold',
    },
    h2: {
      fontSize: '28px',
      fontWeight: 'bold',
    },
    h3: {
      fontSize: '24px',
      fontWeight: 'bold',
    },
    h4: {
      fontSize: '22px',
      fontWeight: 'bold',
    },
    h5: {
      fontSize: '18px',
      fontWeight: 'bold',
    },
    h6: {
      fontSize: '14px',
      fontWeight: 'bold',
      lineHeight: '21.78px',
    },
    subtitle1: {
      fontSize: '18px',
      fontWeight: 'normal',
      lineHeight: '1.3em',
    },
    subtitle2: {
      fontSize: '16px',
      fontWeight: 400,
    },
    body1: {
      fontSize: '16px',
      lineHeight: '1.35em',
    },
    body2: {
      fontSize: '12px',
    },
  },
  palette: {
    mode: 'light',
    secondary: {
      main: '#dddddd',
    },
    primary: {
      light: '#0179d0',
      main: '#0179d0',
      dark: '#0179d0',
      contrastText: '#ffffff',
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: 8,
        },
        elevation0: {
          borderRadius: 0,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          height: '52px',
          '&.Mui-focused': {
            height: '52px',
          },
        },
        input: {
          padding: '8px 14px',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          fontFamily: 'inherit',
          backgroundColor: '#ffffff',
          color: 'inherit',
          boxShadow: 'none',
          borderBottom: '2px solid #f2f7fa',
        },
        colorPrimary: {
          backgroundColor: '#ffffff',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 4,
        },
        contained: {
          color: '#ffffff',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          border: '0px solid #dbe3eb',
        },
        listbox: {
          fontFamily: 'inherit',
          fontSize: 13,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontFamily: 'inherit',
          fontSize: 13,
        },
        label: {
          fontFamily: 'inherit',
          fontSize: 13,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: '#f50057',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: '#6c7682',
          '&.Mui-selected': {
            color: '#6c7682',
          },
        },
        textColorPrimary: {
          color: '#6c7682',
        },
        textColorSecondary: {
          color: 'red',
        },
        textColorInherit: {
          color: 'red',
        },
      },
    },
  },
});

export { MuiApplicationTheme };
