import { generatePath } from 'react-router';

export default class UrlUtils {
  static escape(str) {
    return encodeURIComponent(str);
  }

  static unescape(str) {
    return decodeURIComponent(str);
  }

  static buildPath(path, params) {
    // url encode the values.
    if (params && Array.isArray(Object.keys(params))) {
      Object.keys(params).forEach((key) => {
        return (params[key] = encodeURIComponent(params[key]));
      });
    }

    // Safety check to see if path is passed a null, we just send them to the home page instead of erroring out.
    const destinationPath = path ?? `/repo/${params.workspace}/home`;
    return generatePath(destinationPath, params);
  }

  // Don't hate me for this! I had to copy this same logic from WorkspaceSelector.js
  // TODO: move this to a typescript file when we figure out how deal with generatePath function above.
  static getRedirectWorkspaceUrl = (fromWorkspace, toWorkspace) => {
    // Build a new workspace URL when using the workspace selector
    const locationPathname = window.location.pathname;
    const computedMatchPath = locationPathname.replace('/app', '').replace(fromWorkspace, ':workspace');
    const pathState = { path: computedMatchPath, params: fromWorkspace ?? {} };

    return computedMatchPath.indexOf(':workspace') >= 0
      ? UrlUtils.buildPath(pathState.path, { ...pathState.params, workspace: toWorkspace })
      : UrlUtils.buildPath(`/repo/${toWorkspace}/home`, { ...pathState.params, workspace: toWorkspace });
  };
}
