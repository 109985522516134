import React from 'react';

import { Button, ButtonEmpty, Selectable, EuiSelectableOption, useTectonTheme } from '@tecton';

import { TectonBasicModal, CalloutMessageType } from '@shared';
import { css } from '@emotion/css';

const GroupAddUsersModal = <T,>({
  principalType,
  groupId,
  options,
  setOptions,
  optionsAreLoading,
  onClose,
  confirmChanges,
  isLoading,
  errorMessages,
}: {
  principalType: 'Users' | 'Service Accounts';
  groupId: string;
  options: EuiSelectableOption<T>[];
  setOptions: (options: EuiSelectableOption<T>[]) => void;
  optionsAreLoading: boolean;
  onClose: () => void;
  confirmChanges: () => void;
  isLoading: boolean;
  errorMessages: CalloutMessageType[] | undefined;
}) => {
  const { theme } = useTectonTheme();

  const title = `Add ${principalType} to ${groupId}`;

  const selectedOptions = options.filter((o) => {
    return o.checked === 'on';
  });

  return (
    <TectonBasicModal
      style={{ width: `80em` }}
      maxWidth={'80vw'}
      title={title}
      onClose={onClose}
      body={
        <div>
          <Selectable
            className={css`
              .euiFormControlLayout {
                margin-bottom: ${theme.size.m};
              }

              .euiSelectableList {
                min-height: 180px;
              }
            `}
            listProps={{ bordered: true }}
            searchable
            options={options}
            searchProps={{
              placeholder: `Search for ${principalType}`,
              'data-test-subj': 'selectableSearchHere',
            }}
            onChange={(newOptions) => {
              setOptions(newOptions);
            }}
            isLoading={optionsAreLoading}
          >
            {(list, search) => (
              <>
                {search}
                {list}
              </>
            )}
          </Selectable>
        </div>
      }
      actions={
        <>
          <ButtonEmpty key="cancel" onClick={onClose}>
            Cancel
          </ButtonEmpty>
          <Button
            key="confirm"
            fill
            isDisabled={selectedOptions.length < 1}
            isLoading={isLoading}
            onClick={confirmChanges}
          >
            Add Selected {principalType}
          </Button>
        </>
      }
      calloutMessages={errorMessages}
    />
  );
};

export default GroupAddUsersModal;
