import IdUtils from '../utils/id-utils';
import WorkspaceUtils from '../utils/workspace-utils';
import _ from 'lodash';
import { secondsToHumanFriendlyString, durationToSeconds } from '../utils/misc-utils';
import Pipeline from './Pipeline';
import { PipelineNodeTypes } from '../service/Pipeline';
import { FirstClassObjectTypes } from '../components/FirstClassObject';
import { getClusterConfiguration, dataTypeToString } from '../utils/feature-view/feature-view-util';
import { Schema as SCHEMA_PROPS } from '../types/tecton_proto/common/schema';

export default class FeatureView {
  private proto: any = null;
  private namespace: any = null;

  constructor(proto: any) {
    this.proto = proto;
  }

  get _proto() {
    return this.proto;
  }

  get fcoType() {
    return FirstClassObjectTypes.FEATURE_VIEW;
  }

  get id() {
    return IdUtils.toStringId(this._proto.feature_view_id);
  }

  get createdAt() {
    return new Date(this._proto.fco_metadata.created_at);
  }

  get description() {
    return this._proto.fco_metadata.description;
  }

  get archived() {
    return this._proto.fco_metadata.is_archived;
  }

  get name() {
    return this._proto.fco_metadata.name;
  }

  get owner() {
    return this._proto.fco_metadata.owner;
  }

  get lastModifiedBy() {
    return this._proto.fco_metadata.last_modified_by;
  }

  get workspace() {
    return this._proto.fco_metadata.workspace || WorkspaceUtils.getDefaultWorkspace();
  }

  get sourceFileName() {
    return this._proto.fco_metadata.source_filename;
  }

  entity_names(idToEntityMap: any) {
    return this.entities(idToEntityMap).map((entity: any) => {
      return entity.name;
    });
  }

  entities(idToEntityMap: any): string[] {
    const entityIds = _.get(this._proto, 'entity_ids') || [];
    return entityIds
      .map((id: any) => {
        return idToEntityMap[IdUtils.toStringId(id)];
      })
      .filter((e: any) => !!e);
  }

  get tags() {
    return this._proto.fco_metadata.tags || {};
  }

  get joinKeys() {
    let result = this._proto.join_keys || [];
    return _.clone(result);
  }

  get timeKey() {
    return this._proto.timestamp_key;
  }

  get featureType() {
    if (this.incrementalBackfills) {
      return 'Batch';
    } else if (this.isTemporal) {
      return this.isStream ? 'Stream' : 'Batch';
    } else if (this.isTemporalAggregate) {
      if (this.frameworkVersion >= 4) {
        return this.isStream ? 'Stream' : 'Batch';
      } else {
        return this.isStream ? 'Stream Window Aggregate' : 'Batch Window Aggregate';
      }
    } else if (this.isRealtime) {
      return 'On Demand';
    } else if (this.isFeatureTable) {
      return 'Feature Table';
    }
    return 'Unknown';
  }

  get _temporal() {
    return this._proto.temporal;
  }

  get isTemporal() {
    return !!this._temporal;
  }

  get frameworkVersion() {
    // Deprecate function once we are migrated to FWV4 or greater
    return this._proto.framework_version;
  }

  get fwVersion() {
    return this._proto.fw_version;
  }

  get incrementalBackfills() {
    const temporal = _.get(this, '_temporal');
    return temporal && temporal.incremental_backfills && this.frameworkVersion >= 4;
  }

  get isStream() {
    const stream_data_source_types = ['PUSH_WITH_BATCH', 'PUSH_NO_BATCH', 'STREAM_WITH_BATCH'];
    if (this.isTemporal) {
      return stream_data_source_types.includes(this._temporal.data_source_type);
    } else if (this.isTemporalAggregate) {
      return stream_data_source_types.includes(this._temporalAggregate.data_source_type);
    } else {
      return false;
    }
  }

  get isContinuous() {
    return (
      (this.isTemporalAggregate && this._temporalAggregate.is_continuous) ||
      (this.isTemporal && this._temporal.is_continuous)
    );
  }

  get _temporalAggregate() {
    return this._proto.temporal_aggregate;
  }

  get isTemporalAggregate() {
    return !!this._temporalAggregate;
  }

  get isPush() {
    return (
      this?._proto.temporal?.data_source_type === 'PUSH_NO_BATCH' ||
      this?._proto.temporal?.data_source_type === 'PUSH_WITH_BATCH' ||
      this?._proto.temporal_aggregate?.data_source_type === 'PUSH_NO_BATCH' ||
      this?._proto.temporal_aggregate?.data_source_type === 'PUSH_WITH_BATCH'
    );
  }

  get _realtimeFV() {
    return this._proto.realtime_feature_view;
  }

  get isRealtime() {
    return !!this._realtimeFV;
  }

  get _featureTable() {
    return this._proto.feature_table;
  }

  get isFeatureTable() {
    return !!this._featureTable;
  }

  get _materializationParams() {
    return this._proto.materialization_params;
  }

  get batchClusterConfiguration() {
    const batchClusterConfiguration = _.get(this._materializationParams, 'batch_materialization');

    if (
      _.isEmpty(batchClusterConfiguration) ||
      'json_emr' in batchClusterConfiguration ||
      'json_databricks' in batchClusterConfiguration
    ) {
      return [];
    }
    return getClusterConfiguration(batchClusterConfiguration);
  }

  get canShowClusterConfiguration() {
    const batchClusterConfiguration = _.get(this._materializationParams, 'batch_materialization');
    if (batchClusterConfiguration) {
      if (
        'json_emr' in batchClusterConfiguration ||
        'json_databricks' in batchClusterConfiguration ||
        'json_dataproc' in batchClusterConfiguration
      ) {
        return true;
      }
    }

    return getClusterConfiguration(batchClusterConfiguration ?? []).length > 0;
  }

  get canShowSparkJson() {
    const batchClusterConfiguration = _.get(this._materializationParams, 'batch_materialization');
    if (batchClusterConfiguration) {
      if (
        'json_emr' in batchClusterConfiguration ||
        'json_databricks' in batchClusterConfiguration ||
        'json_dataproc' in batchClusterConfiguration
      ) {
        return true;
      }
    }

    return false;
  }

  get streamClusterConfiguration() {
    const streamClusterConfiguration = _.get(this._materializationParams, 'stream_materialization');
    if (
      _.isEmpty(streamClusterConfiguration) ||
      'json_emr' in streamClusterConfiguration ||
      'json_databricks' in streamClusterConfiguration ||
      'json_dataproc' in streamClusterConfiguration
    ) {
      return [];
    }

    return getClusterConfiguration(streamClusterConfiguration);
  }

  get canShowStreamClusterConfiguration() {
    const streamClusterConfiguration = _.get(this._materializationParams, 'stream_materialization');
    if (streamClusterConfiguration) {
      if (
        'json_emr' in streamClusterConfiguration ||
        'json_databricks' in streamClusterConfiguration ||
        'json_dataproc' in streamClusterConfiguration
      ) {
        return true;
      }
    }
    return getClusterConfiguration(streamClusterConfiguration ?? []).length > 0;
  }

  get canShowStreamSparkJson() {
    const streamClusterConfiguration = _.get(this._materializationParams, 'stream_materialization');
    if (streamClusterConfiguration) {
      if (
        'json_emr' in streamClusterConfiguration ||
        'json_databricks' in streamClusterConfiguration ||
        'json_dataproc' in streamClusterConfiguration
      ) {
        return true;
      }
    }
    return false;
  }

  get _monitoringParams() {
    return this._proto.monitoring_params;
  }

  get materializationScheduleIntervalSeconds() {
    const intervalDuration = _.get(this._materializationParams, 'schedule_interval') || '0';
    return durationToSeconds(intervalDuration);
  }

  get onlineMaterializationIntervalSeconds() {
    if (this.isTemporalAggregate) {
      return durationToSeconds(this._temporalAggregate.slide_interval);
    } else if (this._temporal) {
      // We don't have a separate slide period in this case, so we just default to the offline store cadence
      return this.materializationScheduleIntervalSeconds;
    }
  }

  get servingTtl() {
    if (this._temporal?.serving_ttl) {
      return durationToSeconds(this._temporal.serving_ttl);
    } else if (this.isFeatureTable && this._featureTable?.serving_ttl) {
      return durationToSeconds(this._featureTable.serving_ttl);
    }
  }

  get materializationMaxSourceDataDelaySeconds() {
    const maxSourceDataDelay = _.get(this._materializationParams, 'max_source_data_delay') || '0';
    return durationToSeconds(maxSourceDataDelay);
  }

  get materializationStartTimestamp() {
    return _.get(this._materializationParams, 'materialization_start_timestamp');
  }

  allTransformations(idToTransformMap: any) {
    const pipeline = this.pipeline;
    if (!pipeline) {
      return [];
    }
    return pipeline.allTransformationNodes.map((node: any) => {
      return idToTransformMap[node.innerNodeId];
    });
  }

  allEntities(idToEntityMap: any) {
    if (Array.isArray(this.proto.entity_ids)) {
      const entityIds = this.proto.entity_ids.map((id: string) => {
        return IdUtils.toStringId(id);
      });
      return entityIds.filter((id: string) => {
        return Object.keys(idToEntityMap).includes(id);
      });
    }

    return [];
  }

  get features() {
    const features: any = [];

    const joinKeys = this.joinKeys;
    const schemas = this._proto.schemas;
    if (!schemas) {
      return features;
    }
    const materialization_schema: SCHEMA_PROPS = schemas.materialization_schema;
    if (!materialization_schema) {
      return features;
    }

    if (this.isTemporalAggregate) {
      this._temporalAggregate.features.forEach((feature: any) => {
        features.push({
          name: feature.output_feature_name,
          type: 'aggregation',
        });
      });
      this._temporalAggregate.secondary_key_output_columns?.forEach((feature: any) => {
        features.push({
          name: feature.name,
          type: 'secondary keys',
        });
      });
    } else {
      const columns = materialization_schema.columns;
      (columns || []).forEach((column: any) => {
        if (joinKeys.includes(column.name)) {
          return;
        }
        if (column.name === this.timeKey) {
          return;
        }
        features.push({
          name: column.name,
          type: dataTypeToString(column.offline_data_type),
        });
      });
    }

    return features;
  }

  get maxAggregationIntervalSeconds() {
    if (this._temporalAggregate) {
      return _.max(
        _.map(this._temporalAggregate.features, (f) => {
          return durationToSeconds(f.window);
        })
      );
    } else {
      // Temporal Case
      return undefined;
    }
  }

  get featureMap() {
    return _.keyBy(this.features, 'name');
  }

  get isMaterializationEnabled() {
    return this._proto.materialization_enabled;
  }

  get expectedFreshness() {
    if (this?._monitoringParams?.expected_feature_freshness) {
      return this.isMaterializationEnabled
        ? durationToSeconds(this._monitoringParams.expected_feature_freshness)
        : null;
    }
    return null;
  }

  get isMonitoringEnabled() {
    return this.isMaterializationEnabled && this._monitoringParams && this._monitoringParams.monitor_freshness;
  }

  get isMonitoringUserSpecified() {
    return this.isMonitoringEnabled && this._monitoringParams.user_specified;
  }

  get alertEmail() {
    return this.isMaterializationEnabled && this._monitoringParams ? this._monitoringParams.alert_email : null;
  }

  static _aggregation_function_name_from_proto(protoName: any) {
    return protoName.replace('AGGREGATION_FUNCTION_', '').toLowerCase();
  }

  get temporalAggregateFeatures() {
    var features: any = [];
    var proto = this._temporalAggregate;
    if (!proto) {
      return null;
    }
    _.map(proto.features, (f) => {
      features.push({
        name: f.output_feature_name,
        aggregation: FeatureView._aggregation_function_name_from_proto(f.function) + '(' + f.input_feature_name + ')',
        aggregation_window: secondsToHumanFriendlyString(durationToSeconds(f.window)),
      });
    });

    _.map(proto.secondary_key_output_columns, (secondaryKeyOutputColumn) => {
      const durationInSeconds =
        durationToSeconds(secondaryKeyOutputColumn.time_window?.window_end) -
        durationToSeconds(secondaryKeyOutputColumn.time_window?.window_start);

      features.push({
        name: secondaryKeyOutputColumn.name ?? '',
        aggregation: 'collect_list(' + proto.aggregation_secondary_key + ')',
        aggregation_window: secondsToHumanFriendlyString(durationInSeconds),
      });
    });

    return features;
  }

  get isOnlineMaterializationEnabled() {
    return this.isMaterializationEnabled && this._proto.materialization_params.writes_to_online_store;
  }

  get isOfflineMaterializationEnabled() {
    return this.isMaterializationEnabled && this._proto.materialization_params.writes_to_offline_store;
  }

  get dataSourceIds() {
    if (!this.pipeline) {
      return [];
    }
    return this.pipeline.allLeafNodes
      .map((node: any) => (node.nodeType === PipelineNodeTypes.DATA_SOURCE ? node.innerNodeId : null))
      .filter((id) => !!id);
  }

  setFeatureServiceNamespace(namespace: any) {
    this.namespace = namespace;
  }

  get featureServiceNamespace() {
    return this.namespace;
  }

  get pipeline() {
    // Doesn't exist for some FV types, e.g., FeatureTable
    if (!this._proto.pipeline) {
      return null;
    }
    return new Pipeline(this._proto.pipeline);
  }

  get viewSql() {
    return this._proto.view_sql;
  }

  get hasDependentFvs() {
    if (this.pipeline) {
      return (
        this.isRealtime &&
        !_.isEmpty(
          this.pipeline.allInternalNodes.filter((transformNode: any) => {
            return !_.isEmpty(
              transformNode.inputs.filter((input: any) => {
                return input.node.type === 'feature_view';
              })
            );
          })
        )
      );
    }
    return false;
  }

  get dependentFvs() {
    const dependentFvs: any = [];
    if (this.pipeline) {
      this.pipeline.allInternalNodes.filter((transformNode: any) => {
        transformNode.inputs.filter((input: any) => {
          if (input.node.type === 'feature_view') {
            dependentFvs.push(input);
          }
        });
      });
    }
    return dependentFvs;
  }

  get batchTrigger() {
    return this._proto.batch_trigger.toLowerCase().replace('batch_trigger_type_', '');
  }
}
