import React from 'react';
import { TectonGlobalStyles } from '../../_eui_components';
import { Theme, ThemeProvider, useTheme } from '@emotion/react';
import DEFAULT_THEME from './ThemeDefault';
import GlobalStyles from './GlobalStyles';
import TECTON_THEME from './ThemeTecton';
import _merge from 'lodash/merge';
import { clone } from 'lodash';
import { themeV1 } from './ThemeV1';

export const useTectonTheme = () => {
  const theme = useTheme();

  return { theme };
};

const TectonThemeProvider = ({
  themeType = 'light',
  children,
}: {
  themeType?: 'light' | 'dark';
  children: React.ReactNode;
}) => {
  const v1Theme = themeV1(themeType);
  const theme = _merge({}, clone(DEFAULT_THEME), clone(TECTON_THEME), v1Theme) as Theme;

  return (
    <>
      <ThemeProvider theme={theme}>
        <TectonGlobalStyles />
        <GlobalStyles />
        {children}
      </ThemeProvider>
    </>
  );
};

export default TectonThemeProvider;
