import React, { MouseEvent } from 'react';

import { css } from '@emotion/css';
import EditLink from './EditLink';

const hiddenEditLinkClass = 'tecton-hidden-edit-link';

const hiddenEditLinkRowCss = css`
  &:hover .${hiddenEditLinkClass} {
    opacity: 1;
    visibility: visible;
  }
`;

const HiddenEditCell = ({
  value,
  requiresAdmin,
  editCallback,
  dataTestId,
  isAdmin,
}: {
  value: NonNullable<React.ReactNode>;
  isAdmin: boolean;
  requiresAdmin?: boolean;
  editCallback: () => void;
  dataTestId?: string;
}) => {
  const cellCss = css`
    width: 100%;
    align-items: center;

    .${hiddenEditLinkClass} {
      margin-left: 5px;
      opacity: 0;
      transition: opacity 0.3s;
      visibility: hidden;

      svg {
        transform: translate(0, -1px);
      }
    }

    &:hover {
      .euiLink {
        opacity: 1;
        visibility: visible;
      }
    }
  `;

  return (
    <span className={cellCss}>
      {value}{' '}
      {(isAdmin || !requiresAdmin) && (
        <EditLink
          className={hiddenEditLinkClass}
          onClick={(e: MouseEvent) => {
            e.preventDefault();
            editCallback();
          }}
          dataTestId={`edit-type-${dataTestId}`}
        />
      )}
    </span>
  );
};

export default HiddenEditCell;
export { hiddenEditLinkRowCss };
