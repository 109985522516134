import { HealthVariants } from '@tecton/ComponentRedesign';
import { InternalSparkClusterStatusEnum } from '../../types/tecton_proto/data/internal_spark_cluster_status';
import { ResultMood } from '../@tecton/ComponentRedesign/lib/Callout';

export enum ConfigurationType {
  DATABRICKS = 'DATABRICKS',
  SNOWFLAKE = 'SNOWFLAKE',
}

// TODO: delete this once redesign UI is fully rolled out
export enum ConfigurationStatusType {
  INTERNAL_SPARK_CLUSTER_STATUS_UNSPECIFIED = 'UNKNOWN',
  INTERNAL_SPARK_CLUSTER_STATUS_HEALTHY = 'HEALTHY',
  INTERNAL_SPARK_CLUSTER_STATUS_UNHEALTHY = 'UNHEALTHY',
  INTERNAL_SPARK_CLUSTER_STATUS_CREATING_CLUSTER = 'CREATING_CLUSTER',
  INTERNAL_SPARK_CLUSTER_STATUS_WAITING_FOR_CLUSTER_TO_START = 'WAITING_FOR_CLUSTER_TO_START',
  INTERNAL_SPARK_CLUSTER_STATUS_NO_CLUSTER = 'NO_CLUSTER',
}

export const sparkClusterStatusToHealthMap: Record<InternalSparkClusterStatusEnum, HealthVariants> = {
  [InternalSparkClusterStatusEnum.INTERNAL_SPARK_CLUSTER_STATUS_UNSPECIFIED]: 'failure',
  [InternalSparkClusterStatusEnum.INTERNAL_SPARK_CLUSTER_STATUS_NO_CLUSTER]: 'failure',
  [InternalSparkClusterStatusEnum.INTERNAL_SPARK_CLUSTER_STATUS_CREATING_CLUSTER]: 'active',
  [InternalSparkClusterStatusEnum.INTERNAL_SPARK_CLUSTER_STATUS_WAITING_FOR_CLUSTER_TO_START]: 'active',
  [InternalSparkClusterStatusEnum.INTERNAL_SPARK_CLUSTER_STATUS_HEALTHY]: 'healthy',
  [InternalSparkClusterStatusEnum.INTERNAL_SPARK_CLUSTER_STATUS_UNHEALTHY]: 'failure',
};

export const sparkClusterStatusToResultMood: Record<InternalSparkClusterStatusEnum, ResultMood> = {
  [InternalSparkClusterStatusEnum.INTERNAL_SPARK_CLUSTER_STATUS_UNSPECIFIED]: 'neutral',
  [InternalSparkClusterStatusEnum.INTERNAL_SPARK_CLUSTER_STATUS_NO_CLUSTER]: 'danger',
  [InternalSparkClusterStatusEnum.INTERNAL_SPARK_CLUSTER_STATUS_CREATING_CLUSTER]: 'success',
  [InternalSparkClusterStatusEnum.INTERNAL_SPARK_CLUSTER_STATUS_WAITING_FOR_CLUSTER_TO_START]: 'success',
  [InternalSparkClusterStatusEnum.INTERNAL_SPARK_CLUSTER_STATUS_HEALTHY]: 'success',
  [InternalSparkClusterStatusEnum.INTERNAL_SPARK_CLUSTER_STATUS_UNHEALTHY]: 'danger',
};
