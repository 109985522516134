import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { BASE_ROOT_PATH } from '../..';

const RedirectToBasePath = () => {
  const location = useLocation();

  useEffect(() => {
    const basePath = `/${BASE_ROOT_PATH}`;
    /*
    If the user doesn't land on a page that doesn't start with a "/app" we need to redirect them to /app.
    For example:
    URL is /some-random will be redirect to /app
    or
    /apppppp will be redirect to /app
  */

    if (location && location.pathname) {
      const rootpath = location.pathname.split('/');
      if (rootpath[1] !== BASE_ROOT_PATH) {
        /*
      We redirect user to /app
      we don't use <Navigate /> here since it caused an infinite loop.
     */
        window.location.assign(basePath);
      }
    }
  }, [location]);

  return <></>;
};

export default RedirectToBasePath;
