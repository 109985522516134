import React from 'react';
import { FlyoutHeader, Panel } from '@tecton';

const FlyoutInnerScrollLayout = ({ title, children }: { title: React.ReactNode; children: React.ReactNode }) => {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateRows: `auto 1fr`,
        height: `100%`,
      }}
    >
      <FlyoutHeader hasBorder>{title}</FlyoutHeader>

      <Panel
        hasShadow={false}
        paddingSize="l"
        style={{
          height: '100%',
          overflow: 'auto',
        }}
        className={`eui-scrollBar`}
      >
        {children}
      </Panel>
    </div>
  );
};

export default FlyoutInnerScrollLayout;
