import { EuiFieldSearch, EuiFormRow } from '@elastic/eui';
import styled from '@emotion/styled';
import { FlexGroupWrapper, FlexItem, IconTypes, Icons, Loading } from '@tecton/ComponentRedesign';
import React, { ChangeEventHandler, FC } from 'react';

export type SearchVariant = 'standard' | 'large';

interface TectonFieldTextProps {
  label?: React.ReactNode;
  variant?: SearchVariant;
  inputRef?: React.RefObject<HTMLInputElement>;
  isLoading?: boolean;
  disabled?: boolean;
  message?: React.ReactNode;
  placeholder?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  value?: string;
  fullWidth?: boolean;
  autoFocus?: boolean;
  canUseFormRow?: boolean;
}

const StyledFieldSearch = styled(EuiFieldSearch)<{ marginBottom?: string }>`
  border-radius: ${({ theme }) => theme.border.radius.medium};
  outline: none;
  font-size: ${({ theme }) => theme.font.fontSizes.s};
  padding: ${({ theme }) => theme.padding.m} 36px;
  margin: ${({ theme, marginBottom }) => marginBottom ?? theme.padding.xs} 0px;
  border: ${({ theme }) => theme.border.thin};

  height: 32px;
  outline: none !important;
  transition: none;
  box-shadow: none !important;
  background-image: none !important;
  background-size: none !important;
  background-color: none !important;
  border: ${({ theme }) => theme.border.thin} !important;
  color: ${({ theme }) => theme.colors.subduedText};
`;

const LabelWrapper = styled.div`
  font-size: ${({ theme }) => theme.font.fontSizes.xs};
  color: ${({ theme }) => theme.colors.title};
  font-weight: ${({ theme }) => theme.font.weight.regular};
`;

const MessageWrapper = styled.div`
  font-size: ${({ theme }) => theme.font.fontSizes.xs};
  color: ${({ theme }) => theme.colors.subduedText};
`;

const OverallWrapper = styled.div`
  padding: 0;
  margin: 0;

  .euiFormControlLayoutIcons--right {
    display: none;
  }

  .euiFormControlLayoutIcons--left {
    display: none;
  }
`;

const IconInsert = styled.div`
  position: absolute;
  top: 10px;
  left: 12px;

  circle,
  path {
    stroke: ${({ theme }) => theme.colors.subduedText};
    fill: none;
  }
`;

const SearchIcon = styled.div`
  position: absolute;
  top: 6px;
  left: 6px;

  circle,
  path {
    stroke: ${({ theme }) => theme.colors.subduedText};
    fill: none;
  }
`;

const SearchWrapper = styled.div`
  position: relative;

  .euiFormControlLayout {
    height: unset;
  }
`;

const SearchInputWrapper = styled.input`
  border: none;
  outline: none;
  font-size: ${({ theme }) => theme.font.fontSizes.s};
  padding: ${({ theme }) => theme.padding.s};
  width: 100%;
  input:focus {
    outline: none;
  }
`;

const IconAndWorkspace = styled.div`
  display: inline-flex;
  align-items: center;
  width: 16px;
`;

const SearchLayout = styled.div`
  display: flex;
  width: 100%;
  align-items: center;

  *:focus:focus-visible,
  *:focus {
    outline: none;
    outline-style: none;
  }
`;

const StandardSearchInput: FC<TectonFieldTextProps> = ({
  disabled,
  label,
  message,
  placeholder,
  onChange,
  value,
  fullWidth,
  autoFocus = false,
  canUseFormRow = true,
}) => {
  return (
    <OverallWrapper>
      {canUseFormRow ? (
        <>
          <EuiFormRow
            label={<LabelWrapper>{label}</LabelWrapper>}
            helpText={<MessageWrapper>{message}</MessageWrapper>}
          >
            <div style={{ position: 'relative' }}>
              <StyledFieldSearch
                data-testid="form-search-input"
                value={value}
                disabled={disabled}
                placeholder={placeholder}
                onInput={onChange}
                fullWidth={fullWidth}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus={autoFocus}
              />
              <IconInsert>{Icons[IconTypes.MAGNIFYING_GLASS]}</IconInsert>
            </div>
          </EuiFormRow>
        </>
      ) : (
        <>
          <SearchWrapper>
            <StyledFieldSearch
              data-testid="form-search-input"
              value={value}
              disabled={disabled}
              placeholder={placeholder}
              onInput={onChange}
              fullWidth={fullWidth}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus={autoFocus}
              marginBottom={'0px'}
            />
            <SearchIcon>{Icons[IconTypes.MAGNIFYING_GLASS]}</SearchIcon>
          </SearchWrapper>
        </>
      )}
    </OverallWrapper>
  );
};

// TODO: Tim add this variant to storybook
const LargeSearchInput: FC<TectonFieldTextProps> = ({
  isLoading,
  autoFocus,
  placeholder,
  inputRef,
  disabled,
  value,
  onChange,
  onKeyDown,
}) => {
  return (
    <SearchLayout>
      <FlexGroupWrapper gap="s" direction="row" alignItems="center" justifyContent="spaceBetween" responsive={false}>
        <FlexItem grow={false}>
          <>
            {isLoading ? (
              <IconAndWorkspace>
                <Loading />
              </IconAndWorkspace>
            ) : (
              <IconAndWorkspace>{Icons[IconTypes.MAGNIFYING_GLASS]}</IconAndWorkspace>
            )}
          </>
        </FlexItem>
        <FlexItem>
          <SearchInputWrapper
            autoFocus={autoFocus}
            placeholder={placeholder}
            ref={inputRef}
            disabled={disabled}
            value={value}
            onChange={(e) => {
              onChange?.call(this, e);
            }}
            onKeyDown={onKeyDown}
          />
        </FlexItem>
      </FlexGroupWrapper>
    </SearchLayout>
  );
};

const SearchInput: FC<TectonFieldTextProps> = ({
  variant = 'standard',
  inputRef,
  disabled,
  label,
  message,
  placeholder,
  onChange,
  onKeyDown,
  value,
  fullWidth,
  autoFocus = false,
  canUseFormRow = true,
  isLoading = false,
}) => {
  if (variant === 'large') {
    return (
      <LargeSearchInput
        isLoading={isLoading}
        autoFocus={autoFocus}
        placeholder={placeholder}
        inputRef={inputRef}
        disabled={disabled}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
    );
  }
  return (
    <StandardSearchInput
      disabled={disabled}
      label={label}
      message={message}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      fullWidth={fullWidth}
      autoFocus={autoFocus}
      canUseFormRow={canUseFormRow}
    />
  );
};

export default SearchInput;
