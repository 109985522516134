import React, { FC } from 'react';
import { AnyFCO, FCOTypeHumanReadablePlural } from '../../../core/types/fcoTypes';
import styled from '@emotion/styled';
import { Card, EmptyPrompt, FCOTagBadge, FlexGroup, FlexItem } from '@tecton/ComponentRedesign';

interface FCOMetadataCardProps {
  fco: AnyFCO;
}

const Tags = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: ${({ theme }) => theme.padding.m};
  align-self: stretch;
  flex-wrap: wrap;
`;

const FCOTagsCard: FC<FCOMetadataCardProps> = ({ fco }) => {
  return (
    <Card title={'Tags'} data-testid="fco-tags-card">
      {fco.tags.length === 0 && (
        <FlexGroup direction="column" alignItems="center">
          <FlexItem>
            <EmptyPrompt
              variant="storm"
              title={<>No Related Tags</>}
              body={
                <>
                  {`Tags help you categorize and find your ${
                    fco?.fcoType ? FCOTypeHumanReadablePlural[fco.fcoType] : 'resources'
                  } with ease. To improve manageability and
                  searchability, consider adding relevant tags.`}
                </>
              }
            />
          </FlexItem>
        </FlexGroup>
      )}
      <Tags>
        {fco.tags.map((tag) => {
          return <FCOTagBadge tag={tag} />;
        })}
      </Tags>
    </Card>
  );
};

export default FCOTagsCard;
