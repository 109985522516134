import { useState } from 'react';
import { logEvent as log } from '../../../../utils/analytics-utils';

const useLogOnce = () => {
  const [hasLogged, setHasLogged] = useState(false);

  const logEvent = (message: string) => {
    if (!hasLogged) {
      log(message);
      setHasLogged(true);
    }
  };
  return logEvent;
};

export default useLogOnce;
