import React, { FC } from 'react';
import { FCOType } from '../../../core/types/fcoTypes';

import { Link } from 'react-router-dom';
import { ItemSetPopover, IconTypes, Icons, useIconColors, FCOIconAndName } from '@tecton/ComponentRedesign';
import styled from '@emotion/styled';

type RelatedFCOType = {
  name: string;
  description?: string;
};

interface RelatedFCOLink extends RelatedFCOType {
  type: FCOType;
  workspace: string;
}

interface RelatedFCOsProps {
  fcos: RelatedFCOType[];
  type: FCOType;
  workspace: string;
}

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.title};
  :hover {
    color: ${({ theme }) => theme.colors.title};
  }
`;

const RelatedFCOs: FC<RelatedFCOsProps> = ({ fcos, type, workspace }) => {
  const iconColors = useIconColors();

  const typeMap: Record<FCOType, IconTypes> = {
    [FCOType.DATA_SOURCE]: IconTypes.SOURCES,
    [FCOType.ENTITY]: IconTypes.ENTITIES,
    [FCOType.FEATURE_VIEW]: IconTypes.FEATURE_VIEWS,
    [FCOType.FEATURE_SERVICE]: IconTypes.FEATURE_SERVICE,
    [FCOType.TRANSFORMATION]: IconTypes.TRANSFORMATIONS,
    [FCOType.DATASET]: IconTypes.DATASET,
    [FCOType.UNKNOWN]: IconTypes.ACCOUNTS_AND_ACCESS,
  };

  const itemFcos = fcos.map((fco) => (
    <RelatedFCOLink name={fco.name} description={fco.description} type={type} workspace={workspace} />
  ));

  return <ItemSetPopover items={itemFcos} icon={Icons[typeMap[type]]} highlightColor={iconColors[typeMap[type]]} />;
};

export const RelatedFCOLink: FC<RelatedFCOLink> = ({ name, description, type, workspace }) => {
  const linkToMap: Record<FCOType, string> = {
    [FCOType.DATA_SOURCE]: `/repo/${workspace}/data-sources/`,
    [FCOType.ENTITY]: `/repo/${workspace}/entities/`,
    [FCOType.FEATURE_VIEW]: `/repo/${workspace}/features/`,
    [FCOType.FEATURE_SERVICE]: `/repo/${workspace}/feature-services/`,
    [FCOType.TRANSFORMATION]: `/repo/${workspace}/transformations/`,
    [FCOType.DATASET]: `/repo/${workspace}/datasets/`,
    [FCOType.UNKNOWN]: `/repo/${workspace}/home/`,
  };
  return (
    <StyledLink to={`${linkToMap[type]}/${name}`}>
      <FCOIconAndName type={type} name={name} description={description} />
    </StyledLink>
  );
};

export default RelatedFCOs;
