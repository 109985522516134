import React from 'react';
import { FlexGroup } from '@tecton';
import PlanFVTTable from './PlanFVTTable';
import useItemExpansionState from './useItemExpansionState';
import PlanFSTable from './PlanFSTable';
import PlanDataSourceTable from './PlanDataSourceTable';
import PlanEntitiesTable from './PlanEntityTable';
import PlanTransformationTable from './PlanTransformationTable';
import { useGetStateUpdatePlanSummary } from '../../api/plan-apply-log';
import { useParams } from 'react-router';

const PlanSummary = () => {
  const { planId } = useParams();
  const { data, isSuccess } = useGetStateUpdatePlanSummary(planId);

  if (!isSuccess) {
    <></>;
  }

  const stateUpdatePlanSummary = data;
  const featureViewsAndTablesRowExpansion = useItemExpansionState();

  return (
    <>
      <FlexGroup direction="column" gutterSize="none">
        <PlanFVTTable {...featureViewsAndTablesRowExpansion} stateUpdatePlanSummary={stateUpdatePlanSummary} />
        <PlanFSTable stateUpdatePlanSummary={stateUpdatePlanSummary} />
        <PlanDataSourceTable stateUpdatePlanSummary={stateUpdatePlanSummary} />
        <PlanEntitiesTable stateUpdatePlanSummary={stateUpdatePlanSummary} />
        <PlanTransformationTable stateUpdatePlanSummary={stateUpdatePlanSummary} />
      </FlexGroup>
    </>
  );
};

export default PlanSummary;
