import { groupBy } from 'lodash';

import { FcoTransitionType, StateUpdatePlanSummaryDiff } from '../../types/tecton_proto/data/state_update';

const planApplyFcoDisplayNames = ['Feature View/Table', 'Feature Service', 'Data Source', 'Entity', 'Transformation'];

type DiffCountSummary = {
  fco_type: string;
  items: StateUpdatePlanSummaryDiff[];
  items_by_status: {
    status: FcoTransitionType;
    items: StateUpdatePlanSummaryDiff[];
  }[];
}[];

export const processDiffItems = (diff_items?: StateUpdatePlanSummaryDiff[]) => {
  const g = groupBy(diff_items, (item) => {
    let fcoKey = item.fco_type;

    if (planApplyFcoTypes[item?.fco_type ?? 'UNKNOWN']) {
      fcoKey = planApplyFcoTypes[item.fco_type ?? 'UNKNOWN'];
    }

    return fcoKey;
  });

  const nestedGroup: DiffCountSummary = Object.keys(g)
    .map((fco_type) => {
      const groupedByStatus = groupBy(g[fco_type], (item) => {
        return item.type;
      });

      return {
        fco_type,
        items: g[fco_type],
        items_by_status: Object.keys(groupedByStatus).map((status) => {
          return { status: status as FcoTransitionType, items: groupedByStatus[status] };
        }),
      };
    })
    .sort((a, b) => {
      return planApplyFcoDisplayNames.indexOf(a.fco_type) - planApplyFcoDisplayNames.indexOf(b.fco_type);
    });

  return nestedGroup;
};

// Used to scroll to content
export const scrollIdMap: Record<string, string> = {
  'Feature View/Table': 'plan-feature-views-and-tables-table',
  'Feature Service': 'plan-feature-services-table',
  'Data Source': 'plan-data-sources-table',
  Entity: 'plan-entities-table',
  Transformation: 'plan-transformation-table',
};

// This file reflects FCOStrings.kt on the backend
export const planApplyFcoTypes: Record<string, string> = {
  'Feature Table': planApplyFcoDisplayNames[0],
  'On-Demand Feature View': planApplyFcoDisplayNames[0],
  'Batch Feature View': planApplyFcoDisplayNames[0],
  'Batch Window Aggregate Feature View': planApplyFcoDisplayNames[0],
  'Stream Window Aggregate Feature View': planApplyFcoDisplayNames[0],
  'Stream Window Aggregate Feature View with Push Source': planApplyFcoDisplayNames[0],
  'Stream Feature View': planApplyFcoDisplayNames[0],
  'Stream Feature View with Push Source': planApplyFcoDisplayNames[0],
  'Feature Service': planApplyFcoDisplayNames[1],
  'Stream Data Source': planApplyFcoDisplayNames[2],
  'Batch Data Source': planApplyFcoDisplayNames[2],
  'Push Source': planApplyFcoDisplayNames[2],
  Entity: planApplyFcoDisplayNames[3],
  Transformation: planApplyFcoDisplayNames[4],
  UNKNOWN: 'Unknown',
};

// display label
export const fcoTransitionTypes: Record<FcoTransitionType, string> = {
  CREATE: 'CREATE',
  RECREATE: 'RECREATE',
  UPDATE: 'UPDATE',
  UPGRADE: 'UPGRADE',
  DELETE: 'DELETE',
  UNKNOWN: 'UNKNOWN',
  UNCHANGED: 'UNCHANGED',
};

// Sort order for diff types
export const planDiffTypeOrderValues: Record<FcoTransitionType, number> = {
  CREATE: 1,
  UPDATE: 2,
  UPGRADE: 3,
  RECREATE: 4,
  DELETE: 5,
  UNCHANGED: 6,
  UNKNOWN: 7,
};

export const sortByActionType = (a: StateUpdatePlanSummaryDiff, b: StateUpdatePlanSummaryDiff) => {
  const actionComparison =
    planDiffTypeOrderValues[a.type ?? FcoTransitionType.UNKNOWN] -
    planDiffTypeOrderValues[b.type ?? FcoTransitionType.UNKNOWN];

  if (actionComparison !== 0) {
    return actionComparison;
  }
  return a?.fco_type?.localeCompare(b?.fco_type ?? '') ?? 0;
};

export const TableCommonActionColumn = {
  name: 'Action',
  field: 'type',
  width: '6em',
};

export const TableCommonNameColumn = {
  name: 'Name',
  field: 'name',
  width: '24em',
  truncateText: true,
};

export const TableCommonTypeColumn = {
  name: 'Type',
  field: 'fco_type',
  width: '7em',
  truncateText: true,
};

export const TableCommonChangesColumn = {
  name: 'Changes',
  field: 'diffs',
  width: '15em',
};

export const TableCommonMaterializationColumn = {
  name: 'Materialization',
  width: '12em',
};

export const pluralizeFcoTitle = (key: string) => {
  switch (key) {
    case `Feature View/Table`:
      return `Feature Views & Tables`;
    case `Entity`:
      return `Entities`;
    default:
      return `${key}s`;
  }
};
