import React, { FC } from 'react';

import { useTectonTheme, Button } from '@tecton/ComponentRedesign';
import styled from '@emotion/styled';

const ErrorPromptButtons: FC = () => {
  const { theme } = useTectonTheme();

  const Container = styled.div`
    display: flex;
    gap: ${theme.padding.l};
    align-items: center;
    justify-content: center;
  `;

  return (
    <Container>
      <Button
        variant="primaryAction"
        label={<>Try Again</>}
        data-testid="error-try-again-button"
        onClick={() => {
          window.location.reload();
        }}
      />
      <a href={'https://tecton.atlassian.net/servicedesk/customer/portal/4'} target={'_blank'}>
        <Button variant="emptyAction" label={<>Contact Support</>} data-testid="error-empty-action-button" />
      </a>
    </Container>
  );
};

export default ErrorPromptButtons;
