import React, { FC } from 'react';

interface TransformationIconProps {
  newVersion?: boolean;
}

const TransformationIcon: FC<TransformationIconProps> = ({ newVersion }) => {
  return (
    <g>
      <rect opacity="0.4" x="1.00002" y="1.00006" width="16" height="10" fill="#7C4DFF" />
      <rect opacity="0.6" x="4" y="6.99994" width="16" height="10" rx="4.99784" fill="#7C4DFF" />
      <rect x="13" y="12.9999" width="10" height="10" rx="5" fill="#7C4DFF" />
    </g>
  );
};

export default TransformationIcon;
