import store from '../core/store';
import IdUtils from '../utils/id-utils';
import WorkspaceUtils from '../utils/workspace-utils';

declare global {
  interface Window {
    _env_: any;
  }
}

export enum RestVerbs {
  POST = 'post',
  GET = 'get',
  PUT = 'put',
  DELETE = 'delete',
}

export class ApiService {
  private serviceURL: string;

  constructor() {
    this.serviceURL = window._env_.API_URL;
  }

  async _fetchAndCheckStatus(path: string, params: any) {
    const response = await this._fetchAuthorization(path, params);
    await this.checkResponse(response);
    return response;
  }

  async _fetchAndCheckStatusPut(path: string, params: any) {
    const response = await this._fetchAuthorizationPut(path, params);
    await this.checkResponse(response);
    return response;
  }

  async checkResponse(response: any) {
    if (!response.ok && response.status !== 404) {
      if (response.status === 401 || response.status === 403) {
        throw new Error();
      }

      if (response.status === 400) {
        const errorJson = await response.json();
        if (errorJson.message && errorJson.message.substring(0, 17) === 'INVALID_WORKSPACE') {
          throw new Error();
        }
      }

      if (response && response.status && response.url) {
        // TODO: AMPLITUDE LOGGING
      }
      throw new Error();
    }
  }

  async _fetchAuthorization(path: string, params: any) {
    const url = this.serviceURL + path;
    const requestID = IdUtils.generateStringId();
    const headers: any = {
      'x-request-id': requestID,
      'x-workspace': WorkspaceUtils.getWorkspaceFromStore(),
    };

    // TODO: SIMPLIFY THIS
    const state = store.getState();
    const getAuth: any = state.auth;
    const auth: any = getAuth.auth;

    if (auth != null) {
      headers['Authorization'] = 'Bearer ' + auth.getAccessToken();
    }

    const fetchParameters: RequestInit = {
      method: 'post',
      mode: 'cors',
      headers: headers,
      body: JSON.stringify(params),
    };

    return fetch(url, fetchParameters);
  }

  async _fetchAuthorizationPut(path: string, params: any) {
    const url = this.serviceURL + path;
    const requestID = IdUtils.generateStringId();
    const headers: any = {
      'x-request-id': requestID,
      'x-workspace': WorkspaceUtils.getWorkspaceFromStore(),
    };

    // TODO: SIMPLIFY THIS
    const state = store.getState();
    const getAuth: any = state.auth;
    const auth: any = getAuth.auth;

    if (auth != null) {
      headers['Authorization'] = 'Bearer ' + auth.getAccessToken();
    }

    const fetchParameters: RequestInit = {
      method: 'put',
      mode: 'cors',
      headers: headers,
      body: JSON.stringify(params),
    };

    return fetch(url, fetchParameters);
  }
}

export const service = new ApiService();
