import _ from 'lodash';
import moment from 'moment-timezone';
import { SparkConfigurationMapKey } from '../feature/data-platform-configuration/databricks/setup/DatabricksCredentialsForm';
import { ConfigurationType } from '../components/data-platform-configuration/DataPlatformTypes';

export default class UserDeploymentSettings {
  private proto = null;

  constructor(proto: any) {
    this.proto = proto;
  }

  get _proto() {
    return this.proto;
  }

  get type() {
    // TODO: Stop hardcoding this.
    return ConfigurationType.DATABRICKS;
  }

  get status() {
    return 'HEALTHY';
    // TODO: Return this information from MDS or remove from UI.
    // return this._proto.status;
  }

  get statusDescription() {
    return 'Healthy';
    // TODO: Return this information from MDS or remove from UI.
    // return this._proto.statusDescription;
  }

  get lastCheckedTime() {
    return moment();
    // TODO: Return this information from MDS or remove from UI.
    // return this._proto.last_check_time;
  }

  get lastCheckedTimeLocalFromNow() {
    return moment().fromNow();
    // TODO: Return this information from MDS or remove from UI.
    // return moment(this._proto.last_check_time).fromNow();
  }

  get lastCheckedTimeUtcFromNow() {
    return moment.utc().fromNow();
    // TODO: Return this information from MDS or remove from UI.
    // return moment(this._proto.last_check_time).fromNow();
  }

  get databricksWorkspaceUrl() {
    const databricksWorkspaceUrl = _.get(this._proto, 'databricks_config.workspace_url');
    return databricksWorkspaceUrl ? databricksWorkspaceUrl : '';
  }

  get databricksApiToken() {
    const apiToken: any = _.get(this._proto, 'databricks_config.api_token');
    return apiToken ? apiToken.redacted_value : '';
  }

  get databricksInstanceProfileArn() {
    const databricksInstanceProfileArn = _.get(this._proto, 'user_spark_settings.instance_profile_arn');
    return databricksInstanceProfileArn ? databricksInstanceProfileArn : '';
  }

  get userDeploymentSettingsVersion() {
    const userDeploymentSettingsVersion = _.get(this._proto, 'user_deployment_settings_version');
    return userDeploymentSettingsVersion ? userDeploymentSettingsVersion : '';
  }

  get glueCatalogId() {
    const sparkConfig = _.get(this._proto, 'user_spark_settings.spark_conf');
    let glueCatalogId = '';

    if (sparkConfig) {
      if (sparkConfig[`${SparkConfigurationMapKey.GLUE_CATALOG_ID}`]) {
        glueCatalogId = sparkConfig[`${SparkConfigurationMapKey.GLUE_CATALOG_ID}`];
      }
    }
    return glueCatalogId;
  }

  get glueCatalogEnabled() {
    const sparkConfig = _.get(this._proto, 'user_spark_settings.spark_conf');
    if (sparkConfig) {
      if (sparkConfig[`${SparkConfigurationMapKey.GLUE_CATALOG_ID_ENABLED}`]) {
        return true;
      }
    }
    return false;
  }
}
